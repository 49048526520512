const productList = [
{ name: 'Tupperware Plastic Steamer Purple', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tupperware-plastic-steamer-purple/611686547677003ab08d3290' },
{ name: 'Corioliss Glamour Wand Marble Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/corioliss-glamour-wand-marble-black/611686527677003ab08d328e' },
{ name: 'Tang Mango Low Sugar Drink Mix Pink Blue 350 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tang-mango-low-sugar-drink-mix-pink-blue-350-g/611686517677003ab08d328c' },
{ name: 'Yogabar Crunchy Peanut Butter 1kg Dark Chocolate Peanut Butter With High Protein Anti Oxidants Creamy Crunchy Chocolatey Non Gmo Vegan Peanut Butter Contains No Palm Oil Or Preservatives', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-crunchy-peanut-butter-1kg-dark-chocolate-peanut-butter-with-high-protein-anti-oxidants-creamy-crunchy-chocolatey-non-gmo-vegan-peanut-butter-contains-no-palm-oil-or-preservatives/611686517677003ab08d328d' },
{ name: 'Leevo Starbuds Truly Wireless In Ear Luxuriously Designed Buds Immersive Hd Sound', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/leevo-starbuds-truly-wireless-in-ear-luxuriously-designed-buds-immersive-hd-sound/611686537677003ab08d328f' },
{ name: 'Wonderchef Duo Bot Stainless Steel Single Wall Water Bottle Gold 750ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-duo-bot-stainless-steel-single-wall-water-bottle-gold-750ml/611686507677003ab08d328b' },
{ name: 'Webby Statue Of Unity Wooden Jigsaw Puzzle 108 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/webby-statue-of-unity-wooden-jigsaw-puzzle-108-pieces/611686507677003ab08d3289' },
{ name: 'Facescanada Ultime Pro Hd Runway Ready Foundation Ivory 01 Beige 30 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/facescanada-ultime-pro-hd-runway-ready-foundation-ivory-01-beige-30-ml/6116864f7677003ab08d3287' },
{ name: 'Santoor Perfumed Talc With Sandalwood Extracts 150g Buy 1 Get 1 Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/santoor-perfumed-talc-with-sandalwood-extracts-150g-buy-1-get-1-free/6116864e7677003ab08d3284' },
{ name: 'Tygot Gorilla Tripodmini 13 Inch Tripod For Mobile Phone With Phone Mount Remote Flexible Gorilla Stand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tygot-gorilla-tripodmini-13-inch-tripod-for-mobile-phone-with-phone-mount-remote-flexible-gorilla-stand/6116864e7677003ab08d3286' },
{ name: 'Evoto World Mens Cotton Super Ankle Socks Multicolor Free Size Combo Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evoto-world-mens-cotton-super-ankle-socks-multicolor-free-size-combo-pack-of-3/6116864e7677003ab08d3285' },
{ name: 'Deodap Fancy Spoons Set Of 10 Units Plastic Spoons Colourful Spoons Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deodap-fancy-spoons-set-of-10-units-plastic-spoons-colourful-spoons-green/6116864f7677003ab08d3288' },
{ name: 'Cello Clear Blue Ball Pen Jar Of 60 Units Ball Pens Blue Jar Of 60 Units Ball Pens Set For Students Pens For Office Use Ball Pens For Writing Pens Best Pen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-clear-blue-ball-pen-jar-of-60-units-ball-pens-blue-jar-of-60-units-ball-pens-set-for-students-pens-for-office-use-ball-pens-for-writing-pens-best-pen/65f083bddcad1ca07b28559b' },
{ name: 'Lino Perros Womens Shoulder Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lino-perros-womens-shoulder-bag/65ee07dacee3cf65f43c56f9' },
{ name: 'Flomaster Wsr01114 Waterproof Led Day Time Running Lights Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flomaster-wsr01114-waterproof-led-day-time-running-lights-set-of-2/65ee0456cee3cf40193c56f0' },
{ name: 'Gear Voyager 15l Small Casual Standard Backpackdaypackhiking Daypackdrawstring Bag For Menwomen Black Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-voyager-15l-small-casual-standard-backpackdaypackhiking-daypackdrawstring-bag-for-menwomen-black-yellow/65ee0b5ecee3cf45d93c56fe' },
{ name: 'Jivo Cold Pressed Kachi Ghani Chemical Free Mustard Daily Cooking Oil 1 Litre', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jivo-cold-pressed-kachi-ghani-chemical-free-mustard-daily-cooking-oil-1-litre-/65ee00d1cee3cf4c033c56eb' },
{ name: 'Cortina Spandex Polyester Fabric Super Stretchable Two Seater Sofa Cover Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-spandex-polyester-fabric-super-stretchable-two-seater-sofa-cover-blue/65edf9c9cee3cf2f3a3c5684' },
{ name: 'User Specific Steadfast Nutrition Steadlytesinstant Hydration Energy Drinklow Sugar Added Vitamins C B12 5 Essential Electrolytes Energy Drink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/user-specific-steadfast-nutrition-steadlytesinstant-hydration-energy-drinklow-sugar-added-vitamins-c-b12-5-essential-electrolytes-energy-drink/65edef3bcee3cf63503c5675' },
{ name: 'Size 2 Years 3 Years Naughty Ninos Girls Rayon Trousers Suit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-2-years-3-years-naughty-ninos-girls-rayon-trousers-suit/65edf644cee3cf09fe3c567f' },
{ name: 'Fastrack Fs1 Pro Smartwatchworlds First 196 Super Amoled Arched Display With Highest Resolution Of 410x502singlesync Bt Callingnitrofast Charging110 Sports Modes200 Watchfaces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-fs1-pro-smartwatchworlds-first-196-super-amoled-arched-display-with-highest-resolution-of-410x502singlesync-bt-callingnitrofast-charging110-sports-modes200-watchfaces/65edfd4dcee3cf284a3c5689' },
{ name: 'Amazon Brand Solimo Water Bottle Spill Proof Ergonomic Safe For Refrigerator Freezer And Dishwasher Stainless Steel With Pp Lid Set Of 2 Blue 1 Liter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-water-bottle-spill-proof-ergonomic-safe-for-refrigerator-freezer-and-dishwasher-stainless-steel-with-pp-lid-set-of-2-blue-1-liter/65ede833cee3cf1fe33c566b' },
{ name: 'Amazon Brand Symactive Leakproof Unbreakable Fruit Infuser Sports Sipper Water Bottle With Dust Cover Carry Loop Bpa Free Color Transparentblue 700 Mlplastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symactive-leakproof-unbreakable-fruit-infuser-sports-sipper-water-bottle-with-dust-cover-carry-loop-bpa-free-color-transparentblue-700-mlplastic/65edf2c0cee3cfad293c567a' },
{ name: 'User Specific Oui Rich Plum Cake I Gift Pack I Eggless Loaded With Dry Fruits And Nuts I 250g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/user-specific-oui-rich-plum-cake-i-gift-pack-i-eggless-loaded-with-dry-fruits-and-nuts-i-250g/65edebb7cee3cff8d93c5670' },
{ name: 'Amfin Pink Blue Baby Shower Balloons With Curling Ribbon Its A Boy And Its A Girl Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amfin-pink-blue-baby-shower-balloons-with-curling-ribbon-its-a-boy-and-its-a-girl-combo/65ede4aecee3cfedb03c5666' },
{ name: 'Tukzer 16a Wifi Smart Plug Work With Alexa Google Home Assistant Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tukzer-16a-wifi-smart-plug-work-with-alexa-google-home-assistant-suitable/65edd69ac3f7bb290b4905ae' },
{ name: 'Amfin Holi Magic Water Tap Balloons', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amfin-holi-magic-water-tap-balloons/65ede12acee3cf5d323c5661' },
{ name: 'Nivea Cherry Shine 48g Lip Balm24 H Melt In Moisture Formulanatural Oilsglossy Finish48 G Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-cherry-shine-48g-lip-balm24-h-melt-in-moisture-formulanatural-oilsglossy-finish48-g-pack-of-1/65edcf91c3f7bb01a549059e' },
{ name: 'Lacto Calamine Ubtan Face Wash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lacto-calamine-ubtan-face-wash/65edcc0dc3f7bb7f6f490599' },
{ name: 'Wow Skin Science Sunscreen Spf 55 Pa Matte Look Ultra Light Broad Spectrum Uvauvb Protection All Skin Types For Women Men 100ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-sunscreen-spf-55-pa-matte-look-ultra-light-broad-spectrum-uvauvb-protection-all-skin-types-for-women-men-100ml-pack-of-2/65edc888c3f7bb460e490593' },
{ name: 'Aristocrat Commander 79cms Premium Polyester With Pvc Coating Soft Sided Check In 4 Wheels Large Green Suitcase', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristocrat-commander-79cms-premium-polyester-with-pvc-coating-soft-sided-check-in-4-wheels-large-green-suitcase/65ec0a199b0f67a93454469e' },
{ name: 'Tp Link Tapo T300 Smart Water Leak Sensor With Dripping Detection Up To 90 Db Alarm With One Touch Instant App Notification Ip67 Waterproof Tapo Hub Required Sold Separetly', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tapo-t300-smart-water-leak-sensor-with-dripping-detection-up-to-90-db-alarm-with-one-touch-instant-app-notification-ip67-waterproof-tapo-hub-required-sold-separetly/65edda1ec3f7bb28604905b3' },
{ name: 'Aircase Office Sling Messenger Bag Fits Upto 156 Laptopmacbook Detachable Shoulder Strap Water Resistant Spacious Pockets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aircase-office-sling-messenger-bag-fits-upto-156-laptopmacbook-detachable-shoulder-strap-water-resistant-spacious-pockets/65ec03109b0f674590544694' },
{ name: 'Philips Astra Line 20 Watt 4 Feet Led Tubelight Batten Natural White Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-line-20-watt-4-feet-led-tubelight-batten-natural-white-pack-of-3/65ebff8b9b0f67341e54468f' },
{ name: 'Yns Crafts Stock Happy Birthday Decoration Set Of 53 Balloon 13 Happy Birthday Foil Balloon 2 Rose Gold Star 2 Rose Gold Heart 18 1 Light Balloon 5 Rose Gold Conffeti Balloon 30 Rose Gold Metallic Balloon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yns-crafts-stock-happy-birthday-decoration-set-of-53-balloon-13-happy-birthday-foil-balloon-2-rose-gold-star-2-rose-gold-heart-18-1-light-balloon-5-rose-gold-conffeti-balloon-30-rose-gold-metallic-balloon/65eddda5cee3cfa27d3c565b' },
{ name: 'Tp Link Tapo S200b Smart Button Works With Tapo Devices Smart Home Control Flexible Placement Battery Powered Tapo Hub Required', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tapo-s200b-smart-button-works-with-tapo-devices-smart-home-control-flexible-placement-battery-powered-tapo-hub-required/65edd316c3f7bb64c24905a3' },
{ name: 'Philips 3mp Wi Fi Cctv Hsp3500 Indoor 360 Security Camera 2k1296p Resolution Pan Tilt Zoom 2 Way Talk Motion Sound Detect 2 Year Brand Replacement Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-3mp-wi-fi-cctv-hsp3500-indoor-360-security-camera-2k1296p-resolution-pan-tilt-zoom-2-way-talk-motion-sound-detect-2-year-brand-replacement-warranty/65ec06949b0f67f6fe544699' },
{ name: 'Ptron Newly Launched Bassbuds B91 Tws Earbuds With 38ms Gaming Low Latency Ai Enc Stereo Calls 40hrs Playtime Dual Hd Mic In Ear Bluetooth 53 Headphones Fast Type C Charging Ipx5 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-bassbuds-b91-tws-earbuds-with-38ms-gaming-low-latency-ai-enc-stereo-calls-40hrs-playtime-dual-hd-mic-in-ear-bluetooth-53-headphones-fast-type-c-charging-ipx5-black/65ebdfe49b0f67de6c544662' },
{ name: 'Hummel Evy Womens Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hummel-evy-womens-shorts/65ebdc609b0f67e0c354465d' },
{ name: 'French Connection Analog Dial Unisexs Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-dial-unisexs-watch/65ebe6ed9b0f67575b54466c' },
{ name: 'Kuber Industries Nylon Mesh Laundry Basketsturdy Material Durable Handlesnetted Lightweight Laundry Bag Size 36 X 36 X 58 Capacity 30 Ltr Assorted Color', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-nylon-mesh-laundry-basketsturdy-material-durable-handlesnetted-lightweight-laundry-bag-size-36-x-36-x-58-capacity-30-ltr-assorted-color/65ebf8839b0f6768b0544685' },
{ name: 'Rubix Home Red Color 270 Gsm Mink Blanket Double Bed Soft Cozy Embossed Mink Blanket Great Size 220 X 210 Cm And 1400 Grams Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rubix-home-red-color-270-gsm-mink-blanket-double-bed-soft-cozy-embossed-mink-blanket-great-size-220-x-210-cm-and-1400-grams-ideal/65ebf4fe9b0f67f6a8544680' },
{ name: 'Wipro Garnet 2w Led Integrated Spotlight Blue Color Compact Design Ceiling Spot Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-2w-led-integrated-spotlight-blue-color-compact-design-ceiling-spot-light/65ebd8dc9b0f67d3d3544658' },
{ name: 'Intimify Lip Balm For Dark Lips To Lighten Lip Balm For Winter Dry And Chapped Lips Lips Nourishment Balm For Women Men 8g Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/intimify-lip-balm-for-dark-lips-to-lighten-lip-balm-for-winter-dry-and-chapped-lips-lips-nourishment-balm-for-women-men-8g-pack-of-1/65ebd5579b0f6725ba544653' },
{ name: 'Lakme 9to5 Primer Matte Lip Color Mp5 Maroon Mix 36 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-9to5-primer-matte-lip-color-mp5-maroon-mix-36-g/65ebaea5d383009de8c89730' },
{ name: 'And Dainty Glam Body Mist Perfume 200ml Long Lasting Scent Spray Gift For Women Crafted By Ajmal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/and-dainty-glam-body-mist-perfume-200ml-long-lasting-scent-spray-gift-for-women-crafted-by-ajmal/65ebc746e0499c2b0652513e' },
{ name: 'Gear Casual Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-casual-backpack/65ebb932d3830060a5c8973f' },
{ name: 'Be Active Mens Solid Slim Fit Cotton Formal Shirts Men Stylish Shirt Spread Collar Full Sleeves Timeless Style Comfort', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/be-active-mens-solid-slim-fit-cotton-formal-shirts-men-stylish-shirt-spread-collar-full-sleeves-timeless-style-comfort/65ebab21d3830090b6c8972b' },
{ name: 'Vastramay Mens Chiku Cotton Silk Blend Kurta And Pyjama Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vastramay-mens-chiku-cotton-silk-blend-kurta-and-pyjama-set/65ebb229d38300e83dc89735' },
{ name: 'Urbn Usb Type C 34a Fast Charging Cable 5ft Unbreakable Nylon Braided Quick Charge Compatible With Samsung Oneplus And All C Type Devices Charge Data Transfer Rugged Cable Camo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-usb-type-c-34a-fast-charging-cable-5ft-unbreakable-nylon-braided-quick-charge-compatible-with-samsung-oneplus-and-all-c-type-devices-charge-data-transfer-rugged-cable-camo/65eb8efed383009948c89703' },
{ name: '7threads Kurta With Pant Set For Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/7threads-kurta-with-pant-set-for-women/65eba094d383000a80c8971c' },
{ name: 'Alc Creation Womens Crepe Straight Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/alc-creation-womens-crepe-straight-kurta/65eb9607d38300ddd5c8970d' },
{ name: 'Nivia Wristlock Weightlifting Gloves Genuine Leather With 3 Designed For Weight Liftingfitness Gymhook And Loop Closure', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivia-wristlock-weightlifting-gloves-genuine-leather-with-3-designed-for-weight-liftingfitness-gymhook-and-loop-closure/65eb87f5d383003f91c896f9' },
{ name: 'Audio Array Am C39 Xlr To 35mm Condenser Microphone Kit Tripod 2m Cable Foam Filter Compatible With Audio Interface Mixer Recording Podcast Streaming Home Studio Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/audio-array-am-c39-xlr-to-35mm-condenser-microphone-kit-tripod-2m-cable-foam-filter-compatible-with-audio-interface-mixer-recording-podcast-streaming-home-studio-black/65eb8471d383006d9bc896f4' },
{ name: 'Butterfly Stainless Steel Matchless Multi Kettle 12 Liter With Egg Rack Ss Rack 600 Watts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/butterfly-stainless-steel-matchless-multi-kettle-12-liter-with-egg-rack-ss-rack-600-watts/65eb80edd383006ea7c896ef' },
{ name: 'Blaupunkt Newly Launched Sba20 Pro 25w Bluetooth Soundbar With 2000mah Battery I Rgb Lights I Honeycomb Design I Aux Bluetooth Usb Tws I Bt Speaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blaupunkt-newly-launched-sba20-pro-25w-bluetooth-soundbar-with-2000mah-battery-i-rgb-lights-i-honeycomb-design-i-aux-bluetooth-usb-tws-i-bt-speaker/65eba79cd383001b4ac89726' },
{ name: 'Sugar Uptown Curl Mini Lengthening Mascara Lasts Upto 8 Hrs Lightweight And Smudgeproof With Lash Growth Formula 01 Black Beauty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-uptown-curl-mini-lengthening-mascara-lasts-upto-8-hrs-lightweight-and-smudgeproof-with-lash-growth-formula-01-black-beauty/65eb9d0fd3830013f2c89717' },
{ name: 'Nokia All New 105 Single Sim Keypad Phone With Built In Upi Payments Long Lasting Battery Wireless Fm Radio Charcoal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nokia-all-new-105-single-sim-keypad-phone-with-built-in-upi-payments-long-lasting-battery-wireless-fm-radio-charcoal/65eb8b7ad383000445c896fe' },
{ name: 'Ishin Womens Brown Printed Jewel Neck Anarkali Kurta Set With Dupatta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ishin-womens-brown-printed-jewel-neck-anarkali-kurta-set-with-dupatta/65eb72dbd383003cafc896da' },
{ name: 'Globus Naturals Revitalizing Rice Water Shampoo 250 Ml Enriched With Kokum Butter Olive Oil Reduces Split Ends Breakage Helps Hair Grow Long Lustrous Strong For Oily Hair Scalp', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/globus-naturals-revitalizing-rice-water-shampoo-250-ml-enriched-with-kokum-butter-olive-oil-reduces-split-ends-breakage-helps-hair-grow-long-lustrous-strong-for-oily-hair-scalp/65eb6f55c5f4146c81444450' },
{ name: 'Ultrasonic Pest Repeller Silent Electronic Pest Repellent Plug In Indoor Pest Control Insect Mosquito Killer Machine Mosquito Repellent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ultrasonic-pest-repeller-silent-electronic-pest-repellent-plug-in-indoor-pest-control-insect-mosquito-killer-machine-mosquito-repellent/65eb684cc5f414acf5444446' },
{ name: 'Lifelong Smart Wifi Smoke Detector Plug Play', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-smart-wifi-smoke-detector-plug-play/65eb6bd0c5f41464e044444b' },
{ name: 'Samsung Galaxy M14 5g Icy Silver4gb128gb50mp Triple Camsegments Only 6000 Mah 5g Sp5nm Processor2 Gen Os Upgrade 4 Year Security Update12gb Ram With Ram Plusandroid 13without Charger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m14-5g-icy-silver4gb128gb50mp-triple-camsegments-only-6000-mah-5g-sp5nm-processor2-gen-os-upgrade-4-year-security-update12gb-ram-with-ram-plusandroid-13without-charger/65eb79e4d3830014fac896e5' },
{ name: 'Amazon Brand Presto Total Wash Detergent Powder 4 Kg Value Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-total-wash-detergent-powder-4-kg-value-pack/65eb6143c5f414c7ea44442a' },
{ name: 'Caresmith Revive Tens Machine For Physiotherapy Dual Channels 25 Modes 50 Intensity Levels Rechargeable Electric Massager Therapy Machine Muscle Stimulator', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/caresmith-revive-tens-machine-for-physiotherapy-dual-channels-25-modes-50-intensity-levels-rechargeable-electric-massager-therapy-machine-muscle-stimulator/65eb5dbfc5f4145b26444423' },
{ name: 'Gomechanic Car Vacuum Cleaner Portable 2 In 1 Wet Dry Cleaner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gomechanic-car-vacuum-cleaner-portable-2-in-1-wet-dry-cleaner/65eb64c8c5f41451fd444441' },
{ name: 'Amazon Brand Inkast Mens Carrot Fit Stretch Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-inkast-mens-carrot-fit-stretch-jeans/65eb7d68d38300bb13c896ea' },
{ name: 'Nutri Binge Pistachios Pista 500 Grams Pack Of 1 Premium California Roasted And Salted Pista Dry Fruits Fresh Nutrient Rich Vegan Healthy Snacks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutri-binge-pistachios-pista-500-grams-pack-of-1-premium-california-roasted-and-salted-pista-dry-fruits-fresh-nutrient-rich-vegan-healthy-snacks/65eb5a3bc5f414e6eb44441e' },
{ name: 'Beardo Whisky Smoke Bourbon Perfume 50ml Mariner Perfume 50ml Combo Valentines Day Giftset', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-whisky-smoke-bourbon-perfume-50ml-mariner-perfume-50ml-combo-valentines-day-giftset/65eb7660d3830085bec896e0' },
{ name: 'Makeup Revolution Mousse Shadow Emerald Green Creamy Whipped Mousse Formula', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-mousse-shadow-emerald-green-creamy-whipped-mousse-formula/65eb56b611464ff9643daebc' },
{ name: 'Beatxp Buzz Electric Toothbrush For Adults With 2 Brush Heads 3 Cleaning Modesrechargeable Electric Toothbrush With 2 Minute Timer Quadpacer19000 Strokesmin With Long Battery Life Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-buzz-electric-toothbrush-for-adults-with-2-brush-heads-3-cleaning-modesrechargeable-electric-toothbrush-with-2-minute-timer-quadpacer19000-strokesmin-with-long-battery-life-blue/65eb419cc5f414d4124443a2' },
{ name: 'Nivia 8012 Polyester Air Bra Top Xs Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivia-8012-polyester-air-bra-top-xs-black/65eb3e17c5f414185b444369' },
{ name: 'Pigeon Magmag Drinking Cupbpa Freebps Freefor 8 Month Babiesleak Proofspill Proofdesigned To Train To Drinkwhite And Organe180 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-magmag-drinking-cupbpa-freebps-freefor-8-month-babiesleak-proofspill-proofdesigned-to-train-to-drinkwhite-and-organe180-ml/65eb3a93c5f41487a7444364' },
{ name: 'Amazon Brand Symbol Mens Slim Dress Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-slim-dress-pants/65eb4fadc5f4143f2744440e' },
{ name: 'Escort Analog Brown Dial Unisexs Watch E 2700 7248 Sm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/escort-analog-brown-dial-unisexs-watch-e-2700-7248-sm/65eb338bc5f414a78544435a' },
{ name: 'Skechers Women Beach Bingo Dog House Party Sneakers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skechers-women-beach-bingo-dog-house-party-sneakers/65eb3006c5f414b5c3444355' },
{ name: 'Lux Rose Aloevera Exfoliating Soap 5x125g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lux-rose-aloevera-exfoliating-soap-5x125g/65eb2c82c5f4141e3f444350' },
{ name: 'Tricster Shower Cap Set Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tricster-shower-cap-set-of-6/65eb4520c5f41434484443b5' },
{ name: 'French Connection Analog Black Dial Mens Watch Fk0012c', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-black-dial-mens-watch-fk0012c/65eb370fc5f414118044435f' },
{ name: 'Zebronics Dc Black Adam Edition Rocket 500 Bluetooth 50 Portable Speaker 20w Rms Tws 10 Hour Backup Built In Rechargeable Battery Rgb Lights Detachable Handle Wired Mic Port Type C', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-dc-black-adam-edition-rocket-500-bluetooth-50-portable-speaker-20w-rms-tws-10-hour-backup-built-in-rechargeable-battery-rgb-lights-detachable-handle-wired-mic-port-type-c/65eb5332c5f4149d5c444413' },
{ name: 'The Indian Garage Co Mens Slim Fit Chinos Chino01btan36', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-indian-garage-co-mens-slim-fit-chinos-chino01btan36/65eb1766afa2a3c5682ba1a7' },
{ name: 'Lux Even Toned Glowbuy 4 Get 1 Offerrose Vitamin E Bathing Soapfor Glowing Skin Beauty Soaps150 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lux-even-toned-glowbuy-4-get-1-offerrose-vitamin-e-bathing-soapfor-glowing-skin-beauty-soaps150-g/65eb257ac5f4147d5d444346' },
{ name: 'Gooyo Gy 138a Battery Operated 3d Dynamic Flash Speed Train Toy With Musical Sound', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gooyo-gy-138a-battery-operated-3d-dynamic-flash-speed-train-toy-with-musical-sound/65eb105eafa2a302352ba19d' },
{ name: 'Msi Pro Mp273p Business Productivity Monitor 27 Inch Fullhd 1920 X 1080 75hz Refresh Rate Ips Panel With Eye Friendly Technology Vesa Mount Supported Tiltswivelheightpivot Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/msi-pro-mp273p-business-productivity-monitor-27-inch-fullhd-1920-x-1080-75hz-refresh-rate-ips-panel-with-eye-friendly-technology-vesa-mount-supported-tiltswivelheightpivot-black/65eb0cd9afa2a39b2f2ba198' },
{ name: 'Zotac Gaming Geforce Rtx 3060 Twin Edge Oc Gddr6 12gb 192bit Pcie 40 Graphics Cards With Icestorm 20 Cooling 1807 Mhz Boost Clock 5 Years Warranty 3 Years Warranty 2 Years Extended Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zotac-gaming-geforce-rtx-3060-twin-edge-oc-gddr6-12gb-192bit-pcie-40-graphics-cards-with-icestorm-20-cooling-1807-mhz-boost-clock-5-years-warranty-3-years-warranty-2-years-extended-warranty/65eb13e2afa2a33d9a2ba1a2' },
{ name: 'Vibez By Lifelong Haile Smartwatch For Men With Hd Display24x7 Heart Rate Blood Oxygen Tracking24 Sports Modesleep Monitorip67 Waterproof7 Days Battery Backup Vbswm1008 1 Year Manufacturer Warranty Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vibez-by-lifelong-haile-smartwatch-for-men-with-hd-display24x7-heart-rate-blood-oxygen-tracking24-sports-modesleep-monitorip67-waterproof7-days-battery-backup-vbswm1008-1-year-manufacturer-warranty-grey/65eb05d1afa2a374e92ba18e' },
{ name: 'Lakme Absolute 3d Lipstick Plum Spell 36 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-absolute-3d-lipstick-plum-spell-36-g/65eb024cafa2a381d82ba189' },
{ name: 'The Indian Garage Co Men Chinos', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-indian-garage-co-men-chinos/65eb21f5c5f414b3cf444340' },
{ name: 'Neelam Stainless Steel Premium 101 Pc Wedding Dinner Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-premium-101-pc-wedding-dinner-set/65eb1aebafa2a3c74d2ba1ac' },
{ name: 'Panca South Indian Filter Coffee Maker 200 Ml 2 4 Cup Mug Madras Kaapi Kappi Drip Decoction Maker Brewer Dripper Stainless Steel Medium Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-south-indian-filter-coffee-maker-200-ml-2-4-cup-mug-madras-kaapi-kappi-drip-decoction-maker-brewer-dripper-stainless-steel-medium-size/65eb0955afa2a39d1b2ba193' },
{ name: 'Urban Terrain Rio Cyclebicycle City Bike 275t Single Speed Bike With Complete Accessories Cycle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urban-terrain-rio-cyclebicycle-city-bike-275t-single-speed-bike-with-complete-accessories-cycle/65eaed2f15f781028f31014f' },
{ name: 'Ambrane Wired In Ear Earphones With In Line Mic For Clear Calling 14mm Dynamic Drivers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-wired-in-ear-earphones-with-in-line-mic-for-clear-calling-14mm-dynamic-drivers/65eaf7bfafa2a327dd2ba17a' },
{ name: 'Mosquito Lamp International Eco Friendly Bug Zapper Electric Mosquito Lamp Dual Mosquito Zapper Lamp Indoor Insect Trap Portable Killer Lamp22', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mosquito-lamp-international-eco-friendly-bug-zapper-electric-mosquito-lamp-dual-mosquito-zapper-lamp-indoor-insect-trap-portable-killer-lamp22/65eae62615f7810bfe310145' },
{ name: 'Pigeon By Stovekraft Favourite Induction Base Aluminium Pressure Cooker With Outer Lid 3 Litres Silver Stainless Steel Kitchen Knives Set 3 Pieces Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-favourite-induction-base-aluminium-pressure-cooker-with-outer-lid-3-litres-silver-stainless-steel-kitchen-knives-set-3-pieces-multicolour/65eae2a215f7815a6f310140' },
{ name: 'French Connection Analog Dial Mens Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-dial-mens-watch/65eadb9915f78133b1310121' },
{ name: 'French Connection Analog Silver Dial Womens Watch Fcs003b', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-silver-dial-womens-watch-fcs003b/65ead81515f781a15e31011c' },
{ name: 'Wipro Garnet 6w Square Led Wave Infinity Panel Neutral White Light 4000krimless Panel With Ultra Slim Designrecessed Down Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-6w-square-led-wave-infinity-panel-neutral-white-light-4000krimless-panel-with-ultra-slim-designrecessed-down-light/65eadf1d15f78108ed31013b' },
{ name: 'Keri Perry Womens Mustard Lycra Solid Straight Western Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/keri-perry-womens-mustard-lycra-solid-straight-western-dress/65eae9aa15f7810caf31014a' },
{ name: 'Everyuth Naturals Body Lotion Soothings Citrus Aloe 200ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/everyuth-naturals-body-lotion-soothings-citrus-aloe-200ml/65eafec8afa2a3575b2ba184' },
{ name: 'Cello Plastic Super Bucket Delux 4001 Red 21 Litre', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-plastic-super-bucket-delux-4001-red-21-litre/65eaf0b6afa2a383e22ba16f' },
{ name: 'Samsung 990 Pro Ssd 2tb Pcie 40 M2 Internal Solid State Drive Fastest Speed For Gaming Heat Control Direct Storage And Memory Expansion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-990-pro-ssd-2tb-pcie-40-m2-internal-solid-state-drive-fastest-speed-for-gaming-heat-control-direct-storage-and-memory-expansion/65eaca0415f781ae9e310108' },
{ name: 'Hometales Microfiber Printed Bath Wrap Beach Towel Cream', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hometales-microfiber-printed-bath-wrap-beach-towel-cream/65eacd8815f781845f31010d' },
{ name: 'Vibez By Lifelong Fusion Smart Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vibez-by-lifelong-fusion-smart-watch/65eac2fb15f781f0893100fe' },
{ name: 'Amazon Brand Symactive Eva Weightlifting Belt Medium', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symactive-eva-weightlifting-belt-medium/65eac67f15f7816fb8310103' },
{ name: 'Pigeon By Stovekraft 12381 9 Litre Otg Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-12381-9-litre-otg-black/65eabf7715f7817b9e3100ef' },
{ name: 'Pink Root Sensual Perfumed Body Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pink-root-sensual-perfumed-body-spray/65eabbf215f781c4b23100ea' },
{ name: 'French Connection Analog Womens Watch Dial Colored Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-womens-watch-dial-colored-strap/65ead49015f7816ee1310117' },
{ name: 'Crompton Fabriautotech 2200 Watt Steam Ironfeather Touch Digital Led Fabric Temperature Control3 Ways Auto Shut Offscratch Resistant Ceramic Coating 400 Ml Water Tankanti Dripself Clean Function', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-fabriautotech-2200-watt-steam-ironfeather-touch-digital-led-fabric-temperature-control3-ways-auto-shut-offscratch-resistant-ceramic-coating-400-ml-water-tankanti-dripself-clean-function/65eab86e15f781890f3100e5' },
{ name: 'Kuber Industries Hanging Saree Cover Non Woven Brush Painting Pattern Saree Cover Saree Covers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-hanging-saree-cover-non-woven-brush-painting-pattern-saree-cover-saree-covers/65eaade115f781bf843100d7' },
{ name: 'Orient Enamour Classic Pro10l Storage Water Heaterhpe Technology High Pressure Epoxy Coated Tank 5 Star Energy Saving Rating 8 Bar Pressure Compatibilitysuitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-enamour-classic-pro10l-storage-water-heaterhpe-technology-high-pressure-epoxy-coated-tank-5-star-energy-saving-rating-8-bar-pressure-compatibilitysuitable/65eab4ea15f78154bd3100e0' },
{ name: 'Bajaj Compagno 2000 W 25 Litre Vertical Storage Water Heater Star Rated Geyserwater Heating With Titanium Armour Swirl Flow Technology Child Safety Mode2 Yr Warranty By Bajaj White Blue Wall', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-compagno-2000-w-25-litre-vertical-storage-water-heater-star-rated-geyserwater-heating-with-titanium-armour-swirl-flow-technology-child-safety-mode2-yr-warranty-by-bajaj-white-blue-wall/65eab16615f7818c353100dc' },
{ name: 'Nutri Desire Mixed Nutsseeds And Berries 1 Kg L Mix Dry Fruit Nutmix With Seeds', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutri-desire-mixed-nutsseeds-and-berries-1-kg-l-mix-dry-fruit-nutmix-with-seeds/65ea8ab615f7814b9d3100a5' },
{ name: 'Milton Prime 1000 Pet Water Bottle Set Of 5 1 Litre Each Blue Bpa Free 100 Leak Proof Office Bottle Gym Bottle Home Kitchen Travel Bottle Hiking Treking Bottle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-prime-1000-pet-water-bottle-set-of-5-1-litre-each-blue-bpa-free-100-leak-proof-office-bottle-gym-bottle-home-kitchen-travel-bottle-hiking-treking-bottle/65ea9fd015f781d8683100c3' },
{ name: 'Amazon Brand Symactive Badminton Racquet Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symactive-badminton-racquet-set/65ea954315f7816d213100b4' },
{ name: 'Nutri Desire Combo Pack Of Healthy Mix Dry Fruits 500 Grams Each Total Weight 1 Kg Jar Pack Fresh', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutri-desire-combo-pack-of-healthy-mix-dry-fruits-500-grams-each-total-weight-1-kg-jar-pack-fresh/65ea83ae15f78160dc31009b' },
{ name: 'Drone Car Mini Drone Car Push And Go Car Without Battery This Innovative Toy Is Designed To Captivate The Imaginations Of Kids', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/drone-car-mini-drone-car-push-and-go-car-without-battery-this-innovative-toy-is-designed-to-captivate-the-imaginations-of-kids/65eaa6d915f78186113100cd' },
{ name: 'Mivi Duopods A550 Truly Wireless In Ear Earbuds With Quad Mic Encenvironmental Noise Cancellation 13mm Powerful Bass Drivers 50 Hours Of Playtime High Audio Quality Metallic Design Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mivi-duopods-a550-truly-wireless-in-ear-earbuds-with-quad-mic-encenvironmental-noise-cancellation-13mm-powerful-bass-drivers-50-hours-of-playtime-high-audio-quality-metallic-design-green/65ea8e3b15f781a8553100aa' },
{ name: 'Boat Watch Mystiq With Stress Monitoring Hiit Breath Training Modes 17 Sports Modes With Automatic Sports Recognitionsomber Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-watch-mystiq-with-stress-monitoring-hiit-breath-training-modes-17-sports-modes-with-automatic-sports-recognitionsomber-grey/65ea873215f7814e053100a0' },
{ name: 'Faber Castell Acrylic 40 Ml Tube Warm Grey Iv 273', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faber-castell-acrylic-40-ml-tube-warm-grey-iv-273/65ea7ca515f781150c310091' },
{ name: 'Jbl Endurance Run 2 Sports In Ear Wired Earphones With Mic Pure Bass Sweatproof Flexsoft Eartips Magnetic Earbuds Fliphook Twistlock Technology With Voice Assistant Support', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jbl-endurance-run-2-sports-in-ear-wired-earphones-with-mic-pure-bass-sweatproof-flexsoft-eartips-magnetic-earbuds-fliphook-twistlock-technology-with-voice-assistant-support/65ea6e9415f7810a70310079' },
{ name: 'Waaree Solar Panel 550 Watt Pack Of 4 550 X 4 Halfcut 24 Volts 144 Cells Dual Glass Mono Perc Bifacial Solar Panels', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/waaree-solar-panel-550-watt-pack-of-4-550-x-4-halfcut-24-volts-144-cells-dual-glass-mono-perc-bifacial-solar-panels/65ea721815f78168e731007e' },
{ name: 'More More Unisex Adult Cotton Hooded Neck Printed Hoodie', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/more-more-unisex-adult-cotton-hooded-neck-printed-hoodie/65ea6b1015f781f0c8310074' },
{ name: 'Star X Hexa Pvc Dumbbell Set For Home Gym Workout Dumbells', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/star-x-hexa-pvc-dumbbell-set-for-home-gym-workout-dumbells/65ea640715f7811c5f31006a' },
{ name: 'Status Contract Shital Blankets Single Bed Mink Blanket Single Ply', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-shital-blankets-single-bed-mink-blanket-single-ply/65ea608315f7814d9b310065' },
{ name: 'Chhedas Diet Poha Chivda Crispy Poha Chivda With Sev 350g Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chhedas-diet-poha-chivda-crispy-poha-chivda-with-sev-350g-pack-of-1/65ea5cff15f78170ab310060' },
{ name: 'Osaka Ni Mh Hr06 4xaa 3000mah Enelong Rechargeable Battery Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/osaka-ni-mh-hr06-4xaa-3000mah-enelong-rechargeable-battery-set/65ea597b15f781e7ce31005b' },
{ name: 'Tantra Sound Tank 20w Bluetooth Speaker With 4400mah Battery', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tantra-sound-tank-20w-bluetooth-speaker-with-4400mah-battery/65ea802a15f7810ed4310096' },
{ name: 'Portronics Konnect L 12m Por 1401 Fast Charging 3a 8 Pin Usb Cable With Charge Sync Function White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-konnect-l-12m-por-1401-fast-charging-3a-8-pin-usb-cable-with-charge-sync-function-white/65ea759d15f7810d86310083' },
{ name: 'Niyamax Usb Rechargeable Motion Sensor Night Light Pack 2 With Self Adhesive Design For Home Use Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/niyamax-usb-rechargeable-motion-sensor-night-light-pack-2-with-self-adhesive-design-for-home-use-ideal/65ea678b15f7810bf631006f' },
{ name: 'Amazon Brand Solimo Majestico Melamine Floral Dinnerware Set White18 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-majestico-melamine-floral-dinnerware-set-white18-pieces/65ea55f615f781ae63310056' },
{ name: 'Fastrack Road Trip Analog Multi Colour Dial Womens Watch Nn6188nm01nn6188nm01 Stainless Steel Black Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-road-trip-analog-multi-colour-dial-womens-watch-nn6188nm01nn6188nm01-stainless-steel-black-strap/65ea4b6915f7815028310047' },
{ name: 'Aart Store 72x35x5 Hard Soft Foam 5 Inches Bed Single Size Mattress Medium Firm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aart-store-72x35x5-hard-soft-foam-5-inches-bed-single-size-mattress-medium-firm/65ea527215f7817345310051' },
{ name: 'Keri Perry Womens White Imported Lycra Tie Dye Bodycon Western Dress Dress For Women A Line Dress Summer Dresswestern Dress Latest Women Dress Trendy Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/keri-perry-womens-white-imported-lycra-tie-dye-bodycon-western-dress-dress-for-women-a-line-dress-summer-dresswestern-dress-latest-women-dress-trendy-dress/65ea4eee15f781ff2031004c' },
{ name: 'Greciilooks Men Solid Regular Fit Casual Shirt Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/greciilooks-men-solid-regular-fit-casual-shirt-pack-of-1/65ea47e515f7815d25310042' },
{ name: 'Styfun Women Soft Satin Floral Lace V Neck Criss Cross Slit Design Adjustable Straps Above Knee Babydoll Lingerie Set Nightwear Dress Pack Of 2 Red White Size Xl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/styfun-women-soft-satin-floral-lace-v-neck-criss-cross-slit-design-adjustable-straps-above-knee-babydoll-lingerie-set-nightwear-dress-pack-of-2-red-white-size-xl/65ea40dd15f781884f31002e' },
{ name: 'La Verne140 Tc 100 Cotton King Size Jaipuri Rjasathani Print Bedsheet With 2 Pillow Covers Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-verne140-tc-100-cotton-king-size-jaipuri-rjasathani-print-bedsheet-with-2-pillow-covers-blue/65ea446115f7812138310033' },
{ name: 'Ferro Spezia Analog Black Dial Mens Watch F11198a A2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ferro-spezia-analog-black-dial-mens-watch-f11198a-a2/65ea365015f7819d7431001e' },
{ name: 'Clovia Womens Cotton Printed Button Me Up Shirt Pyjama Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-printed-button-me-up-shirt-pyjama-set/65ea3d5815f781324a310029' },
{ name: 'Luvyh Underarms Body Scrub For Dark Backarms Elbow For Tan Removal Scrub 100g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luvyh-underarms-body-scrub-for-dark-backarms-elbow-for-tan-removal-scrub-100g/65ea39d415f7816d04310024' },
{ name: 'Disano Peanut Butter All Natural Creamy Unsweetened 30 Protein Gluten Free Non Gmo 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/disano-peanut-butter-all-natural-creamy-unsweetened-30-protein-gluten-free-non-gmo-1kg/65ea32c9a27abf6c7116a5d1' },
{ name: 'Bajaj Flashy Radiant Room Heater For Homestainless Steel Heat Reflectornickel Chrome Meshadjustable Thermostat1000w Ceramic Heater For Winterelectric Heater For Room2 Yr Warranty By Bajajblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-flashy-radiant-room-heater-for-homestainless-steel-heat-reflectornickel-chrome-meshadjustable-thermostat1000w-ceramic-heater-for-winterelectric-heater-for-room2-yr-warranty-by-bajajblack/65ea2f45a27abf8b0a16a5cc' },
{ name: 'La Shield Probiotic Moisturizing Lotion Intense Body Lotion 72 Hr Hydration Soft Supple Skin For Dry Skin Normal Skin Oily Skin Sensitive Skin Quick Absorption I Repairs Uv Damage Dermatologically Tested I 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-shield-probiotic-moisturizing-lotion-intense-body-lotion-72-hr-hydration-soft-supple-skin-for-dry-skin-normal-skin-oily-skin-sensitive-skin-quick-absorption-i-repairs-uv-damage-dermatologically-tested-i-100ml/65ea2bc1a27abf4d3616a5c7' },
{ name: 'Add 2 Qty Nestle Munch Crunchilicious Cereal Get Set Crunch Breakfast Cereal 300g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/add-2-qty-nestle-munch-crunchilicious-cereal-get-set-crunch-breakfast-cereal-300g/65ea24b8a27abf38a316a5bd' },
{ name: 'Zebronics Zeb Crystal Clear Web Camera With 3p Lensbuilt In Microphoneauto White Balancenight Vision And Manual Switch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-crystal-clear-web-camera-with-3p-lensbuilt-in-microphoneauto-white-balancenight-vision-and-manual-switch/65ea16a7a27abf4cfb16a5a9' },
{ name: 'Giftstown Water Floating Penmarkermagical Water Painting For Kids In 8 Colors Floating Marker Pen Easy To Wipe Water Floating Pen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/giftstown-water-floating-penmarkermagical-water-painting-for-kids-in-8-colors-floating-marker-pen-easy-to-wipe-water-floating-pen/65ea1dafa27abf258516a5b3' },
{ name: 'Bralcon Organic Henna Powder Indigo Powder Combo 200g100g X 2 Pack Hennamehendi Powder Indigo Powder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bralcon-organic-henna-powder-indigo-powder-combo-200g100g-x-2-pack-hennamehendi-powder-indigo-powder/65ea1a2ba27abf2bc916a5ae' },
{ name: 'Dabur Herbl Activated Charcoal Toothpaste 120gblack Gel Toothpastewhitening Toothpastefluoride Freefights Plaqueextrinsic Stainswith Power Of Charcoalmintcoolrefreshing Mouth Experience', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-herbl-activated-charcoal-toothpaste-120gblack-gel-toothpastewhitening-toothpastefluoride-freefights-plaqueextrinsic-stainswith-power-of-charcoalmintcoolrefreshing-mouth-experience/65ea0f9ea27abf264516a59f' },
{ name: 'Boat Wave Call Smart Watch Smart Talk With Advanced Dedicated Bluetooth Calling Chip 169 Hd Display With 550 Nits 70 Color Gamut 150 Watch Faces Multi Sport Modeshrspo2caribbean Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-wave-call-smart-watch-smart-talk-with-advanced-dedicated-bluetooth-calling-chip-169-hd-display-with-550-nits-70-color-gamut-150-watch-faces-multi-sport-modeshrspo2caribbean-green/65ea0c1aa27abf5e0916a59a' },
{ name: 'Philips Ace Saver 85w B22 Led Bulb Crystal White Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ace-saver-85w-b22-led-bulb-crystal-white-pack-of-6/65ea0896a27abf5be116a595' },
{ name: 'Beco Matic Natural Laundry Liquid Front Load 2l Super Value Refill Natural Formula', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-matic-natural-laundry-liquid-front-load-2l-super-value-refill-natural-formula/65ea1323a27abf766116a5a4' },
{ name: 'Wonderland Foods Plain Seedless Green Raisin Kishmish Dried Grapes 1kg 500g X 2 Jar Nutritious Rich In Iron Vitamin B Healthy Sweet Treats', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderland-foods-plain-seedless-green-raisin-kishmish-dried-grapes-1kg-500g-x-2-jar-nutritious-rich-in-iron-vitamin-b-healthy-sweet-treats/65ea0511a27abfca5316a590' },
{ name: 'Faber 750w Dlx Sandwich Grill Toaster Grill Toast Heat 2 Slice Slots Power Ready Lights Auto Shut Off Cord Storage Antiskid Feet Non Stick Coated Plates', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faber-750w-dlx-sandwich-grill-toaster-grill-toast-heat-2-slice-slots-power-ready-lights-auto-shut-off-cord-storage-antiskid-feet-non-stick-coated-plates/65e9fa85a27abf882e16a581' },
{ name: 'Zebronics Zeb Sound Feast 500 Bluetooth 50 Portable Speaker With 70w 9h Backup Tws Ipx5 Waterproof Call Function Rgb Lights Aux Msd Voice Assistant Type C And Grill Finish', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-sound-feast-500-bluetooth-50-portable-speaker-with-70w-9h-backup-tws-ipx5-waterproof-call-function-rgb-lights-aux-msd-voice-assistant-type-c-and-grill-finish/65e9e8efa27abf3dfa16a568' },
{ name: 'Boat Aavante Bar Rhythm Soundbar With 60w Rms Signature Sound 20 Channel Multi Connectivity Modes Bt V53 Eq Modes Bass Treble Controls Remote Controlcarbon Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-aavante-bar-rhythm-soundbar-with-60w-rms-signature-sound-20-channel-multi-connectivity-modes-bt-v53-eq-modes-bass-treble-controls-remote-controlcarbon-black/65e9ec74a27abf41d216a56d' },
{ name: 'Halonix Astron Plus Base E27 9 Watt Led Bulb Pack Of 4 Cool White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-astron-plus-base-e27-9-watt-led-bulb-pack-of-4-cool-white/65e9eff8a27abf06f116a572' },
{ name: 'Qubo Smart Wifi Wireless Video Doorbell White From Hero Group Instant Visitor Video Call Intruder Alarm System Easy Plug Play Ac Chime 2 Way Talk 1080p Fhd Camera Night Vision', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qubo-smart-wifi-wireless-video-doorbell-white-from-hero-group-instant-visitor-video-call-intruder-alarm-system-easy-plug-play-ac-chime-2-way-talk-1080p-fhd-camera-night-vision/65e9e1e7a27abfbc8d16a55e' },
{ name: 'Portronics Swipe 2 Screen Cleaner Duster With Refillable Spray Bottle Chemical Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-swipe-2-screen-cleaner-duster-with-refillable-spray-bottle-chemical-free/65e9f700a27abf1cbc16a57c' },
{ name: 'Urbn 10000 Mah Lithiumpolymer 225w Super Fast Charging Ultra Compact Power Bank With Quick Charge Power Delivery Type C Inputoutput Made In India Type C Cable Included Camo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-10000-mah-lithiumpolymer-225w-super-fast-charging-ultra-compact-power-bank-with-quick-charge-power-delivery-type-c-inputoutput-made-in-india-type-c-cable-included-camo/65e9de62a27abf657116a558' },
{ name: 'Dove Mencare Thick Strong 2in1 Shampooconditioner 650 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-mencare-thick-strong-2in1-shampooconditioner-650-ml/65e9dadb3ccd2078c1286134' },
{ name: 'Boat Xtend Smart Watch With Alexa Built In 169 Hd Display Multiple Watch Faces Stress Monitor Hr Spo2 Monitoring 14 Sports Modes Sleep Monitor 5 Atm 7 Days Battery Lifeolive Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-xtend-smart-watch-with-alexa-built-in-169-hd-display-multiple-watch-faces-stress-monitor-hr-spo2-monitoring-14-sports-modes-sleep-monitor-5-atm-7-days-battery-lifeolive-green/65e9f37ca27abf1fb816a577' },
{ name: 'Boat Dual Port Qc Pd 24w Fast Car Charger With 24w Fast Pd Charging 18w Qc Charging Compatible With All Smartphones Tablets Laptops Free Type C To Type C Cable Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-dual-port-qc-pd-24w-fast-car-charger-with-24w-fast-pd-charging-18w-qc-charging-compatible-with-all-smartphones-tablets-laptops-free-type-c-to-type-c-cable-black/65e9e56ba27abfdc0e16a563' },
{ name: 'Portronics Mport 3a Usb Hub 3 In 1 Multiport Adapter With Usb 30 2 Port Usb 20 Upto 5 Gbps High Data Transfer Speed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-mport-3a-usb-hub-3-in-1-multiport-adapter-with-usb-30-2-port-usb-20-upto-5-gbps-high-data-transfer-speed/65e9d3d33ccd205d9728612a' },
{ name: 'Beatxp Vector 130 Hd Display Bluetooth Calling Smart Watch Rotary Crown 320 320px 60hz Refresh Rate 100 Sports Modes 247 Health Tracking Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-vector-130-hd-display-bluetooth-calling-smart-watch-rotary-crown-320-320px-60hz-refresh-rate-100-sports-modes-247-health-tracking-silver/65e9c5c23ccd20e70b286116' },
{ name: 'Zebronics Zeb Sp110 Smart Wi Fi Plug Compatible With Google Assistant Alexa Supports Upto 10a And Comes With A Dedicated App That Features Scheduled Control', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-sp110-smart-wi-fi-plug-compatible-with-google-assistant-alexa-supports-upto-10a-and-comes-with-a-dedicated-app-that-features-scheduled-control/65e9ccca3ccd2027fb286120' },
{ name: 'Chiptronex Nxt2 Silent Grey Mid Tower Atx Gaming Computer Case1x120 Mm Argb Auto Running Fan Metal Body Tempered Glass Computer Case Pc Cabinet Rgb Light Matx Mitx Support Case Without Smps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chiptronex-nxt2-silent-grey-mid-tower-atx-gaming-computer-case1x120-mm-argb-auto-running-fan-metal-body-tempered-glass-computer-case-pc-cabinet-rgb-light-matx-mitx-support-case-without-smps/65e9c23d3ccd20152b286108' },
{ name: 'Nutraj 100 Pure Premium Whole Cashew Nuts W320 1kg 500g X 2 Nutritious Delicious Crunchy Kaju Rich In Magnesium Copper Phosphorus', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutraj-100-pure-premium-whole-cashew-nuts-w320-1kg-500g-x-2-nutritious-delicious-crunchy-kaju-rich-in-magnesium-copper-phosphorus/65e9beb93ccd20805e286103' },
{ name: 'Paper Mate Inkjoy 100st Ball Pen Xf Capped 50x Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paper-mate-inkjoy-100st-ball-pen-xf-capped-50x-blue/65e9b42c3ccd2083362860f4' },
{ name: 'Park Avenue Luxury Grooming Collection 8 In 1 Combo Grooming Kit For Men Valentines Day Gift Set For Men Gift Hamper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-luxury-grooming-collection-8-in-1-combo-grooming-kit-for-men-valentines-day-gift-set-for-men-gift-hamper/65e9d04e3ccd202e0f286125' },
{ name: 'Prakriti Naturals Healthy Trail Mix Almonds Cashew Raisins Black Raisins Pumpkin Sunflower Roasted Flax Seeds Healthy Snack Nuts And Dry Fruits Jar Pack 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prakriti-naturals-healthy-trail-mix-almonds-cashew-raisins-black-raisins-pumpkin-sunflower-roasted-flax-seeds-healthy-snack-nuts-and-dry-fruits-jar-pack-1kg/65e9c9463ccd20156628611b' },
{ name: 'Havells Glaze 1200mm Decorative Finish Ceiling Fan Sapphire Blue Chrome', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-glaze-1200mm-decorative-finish-ceiling-fan-sapphire-blue-chrome/65e9bb343ccd2044cf2860fe' },
{ name: 'Havells Lhldeueeml8r009 9w Led Bulb White B22 Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-lhldeueeml8r009-9w-led-bulb-white-b22-pack-of-4/65e9b0a83ccd2041082860ef' },
{ name: 'Paper Mate Inkjoy 300rt Retractable Ball Pen 12x Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paper-mate-inkjoy-300rt-retractable-ball-pen-12x-blue/65e9b7b03ccd20047b2860f9' },
{ name: 'Dabur Vatika Neelibhringa 21 Hair Growth Oil 50ml25ml Extra Free 14 Ayurvedic Herbs Ayurvedic Medicine 4x Less Hairfall 3x Thicker Hair 21 Hair Benefits With Ergnomic Comb Applicator', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-neelibhringa-21-hair-growth-oil-50ml25ml-extra-free-14-ayurvedic-herbs-ayurvedic-medicine-4x-less-hairfall-3x-thicker-hair-21-hair-benefits-with-ergnomic-comb-applicator/65e9a2963ccd2069e12860dc' },
{ name: 'Wow Life Science Omega 3 Fish Oil 1300mg Triple 3x Strength 60 Capsules 550 Mg Epa 350 Mg Dha', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-life-science-omega-3-fish-oil-1300mg-triple-3x-strength-60-capsules-550-mg-epa-350-mg-dha/65e99f123ccd20f2232860d7' },
{ name: 'Evereve Shave Shine 5 Blade Razor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evereve-shave-shine-5-blade-razor/65e991003ccd2062bc2860c2' },
{ name: 'Dabur Herbl Activated Charcoal Toothpaste 240g 120gx2pack Of 2black Gel Toothpastewhitening Fluoride Freefights Plaqueextrinsic Stainswith Power Of Mintcoolrefreshing Mouth Experience', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-herbl-activated-charcoal-toothpaste-240g-120gx2pack-of-2black-gel-toothpastewhitening-fluoride-freefights-plaqueextrinsic-stainswith-power-of-mintcoolrefreshing-mouth-experience/65e98d79758a001726e88742' },
{ name: 'Bombay Shaving Company Face Razor For Women For Easy Safe Facial Hair Removal 3 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bombay-shaving-company-face-razor-for-women-for-easy-safe-facial-hair-removal-3-count/65e989f5758a0039c1e8873d' },
{ name: 'Kuber Industries Disney Team Mickey Print Square Plastic Bathroom Stool Adults Simple Style Stool Anti Slip With Strong Bearing Stool', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-disney-team-mickey-print-square-plastic-bathroom-stool-adults-simple-style-stool-anti-slip-with-strong-bearing-stool/65e98671758a00d04fe88738' },
{ name: 'Bold Care Ashwagandha Effervescent Tablets 20 Count Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bold-care-ashwagandha-effervescent-tablets-20-count-pack-of-1/65e9785f758a00623ee88724' },
{ name: 'Paras Homeware Hot N Hot Casserole Bpa Free Food Grade Easy To Carry Easy To Store For Rice Gravy Curry Black 1800ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paras-homeware-hot-n-hot-casserole-bpa-free-food-grade-easy-to-carry-easy-to-store-for-rice-gravy-curry-black-1800ml/65e982ec758a00f8bce88733' },
{ name: 'Amazon Basics 5w Bluetooth 50 Speaker Upto 36 Hrs Playtime True Wireless Technology Built In Mic Multiple Connectivity Modes Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-5w-bluetooth-50-speaker-upto-36-hrs-playtime-true-wireless-technology-built-in-mic-multiple-connectivity-modes-grey/65e97f68758a0033afe8872e' },
{ name: 'Biotique Bio Carrot Face Body Sun Lotion Spf 40 Uvauvb Sunscreen Prevents Ageing And Soothes Dry Skin 100 Botanical Extracts Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-bio-carrot-face-body-sun-lotion-spf-40-uvauvb-sunscreen-prevents-ageing-and-soothes-dry-skin-100-botanical-extracts-suitable/65e96dd2758a00a8dbe88715' },
{ name: 'Fitspire Fitt Energy Bar 100 Vegan Provide Instant Energy Essential Nutrients Boosts Athletic Performance Improves Muscle Recovery Chocolate Banana Walnut Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-fitt-energy-bar-100-vegan-provide-instant-energy-essential-nutrients-boosts-athletic-performance-improves-muscle-recovery-chocolate-banana-walnut-pack-of-6/65e97be3758a005ab1e88729' },
{ name: 'Boat Rockerz 245 Pro Bluetooth In Ear Neckband With Beast Modesuper Low Latency For Gaming Enx Tech', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-245-pro-bluetooth-in-ear-neckband-with-beast-modesuper-low-latency-for-gaming-enx-tech/65e974db758a001b77e8871f' },
{ name: 'Biotique Sandalwood Sunscreen Ultra Soothing Face Lotion Spf 50 Ultra Protective Lotion Keeps Skin Soft Fair And Moisturized Water Resistant For All Skin Types 50ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-sandalwood-sunscreen-ultra-soothing-face-lotion-spf-50-ultra-protective-lotion-keeps-skin-soft-fair-and-moisturized-water-resistant-for-all-skin-types-50ml/65e97157758a00a98de8871a' },
{ name: 'Lenovo Thinkbook 15 G5 Laptop Amd Ryzen 7 7730u16gb Ddr4 Ram 512gb Ssdwindows 11ms Officefingerprint Readerprivacy Camera 156 Inch Fhd Displaybacklit Keyboardamd Radeon 8 Graphicstype C', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-thinkbook-15-g5-laptop-amd-ryzen-7-7730u16gb-ddr4-ram-512gb-ssdwindows-11ms-officefingerprint-readerprivacy-camera-156-inch-fhd-displaybacklit-keyboardamd-radeon-8-graphicstype-c/65e966ca758a003867e8870b' },
{ name: 'Protecta Urban Camo 156 Laptop Backpack Ideal For Schoolcollegeofficework Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/protecta-urban-camo-156-laptop-backpack-ideal-for-schoolcollegeofficework-red/65e96a4e758a00e030e88710' },
{ name: 'Havells Leganza 4b 1200mm 1 Star Energy Saving Ceiling Fan Pearl White Silver Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-leganza-4b-1200mm-1-star-energy-saving-ceiling-fan-pearl-white-silver-pack-of-1/65e96345758a007600e88706' },
{ name: 'Flyloons Birthday Decoration Items', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flyloons-birthday-decoration-items/65e95fc1758a006b14e88701' },
{ name: 'Longway Creta P2 1200 Mm48 Inch Ultra High Speed 3 Blade Anti Dust Decorative Star Rated Ceiling Fan Ivory Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-creta-p2-1200-mm48-inch-ultra-high-speed-3-blade-anti-dust-decorative-star-rated-ceiling-fan-ivory-pack-of-2/65e95c3d758a004451e886fc' },
{ name: 'Polycab Optima Mini 400 Mm Pedestal Fan With Superior Air Delivery 100 Copper Motor And 2 Years Warranty Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-optima-mini-400-mm-pedestal-fan-with-superior-air-delivery-100-copper-motor-and-2-years-warranty-blue/65e932076001ed4d97b17b37' },
{ name: 'Reynolds Matte Pro Pencil 25 Ct Jar I Break Resistant Lead With Comfortable Grip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-matte-pro-pencil-25-ct-jar-i-break-resistant-lead-with-comfortable-grip/65e951b0758a004c4ce886e8' },
{ name: 'Nivea Men Dark Spot Reduction Face Wash 100 G With Ginko And Ginseng Extracts For Clean Healthy Clear Skin In Summer 10 X Vitamin C Effect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-men-dark-spot-reduction-face-wash-100-g-with-ginko-and-ginseng-extracts-for-clean-healthy-clear-skin-in-summer-10-x-vitamin-c-effect/65e92e826001ed7debb17b31' },
{ name: 'Biotique Tea Tree Skin Clearing Face Scrub', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-tea-tree-skin-clearing-face-scrub/65e91cebd20e6bdba0e46117' },
{ name: 'Kikibix Black Currant Natural Peanut Butter No Added Sugar Omega 3 Fatty Acids Vegan No Transfat High Protein Non Gmo No Added Oil Or Emulsifiers 340 Gm X Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kikibix-black-currant-natural-peanut-butter-no-added-sugar-omega-3-fatty-acids-vegan-no-transfat-high-protein-non-gmo-no-added-oil-or-emulsifiers-340-gm-x-pack-of-1/65e923f4d20e6b99bde46121' },
{ name: 'Maxima Attivo Steel Analog Black Dial Mens Watch 26750pmgt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxima-attivo-steel-analog-black-dial-mens-watch-26750pmgt/65e91967d20e6baa50e46112' },
{ name: 'Luminous Jetta 1200 Mm Designer High Speed Ceiling Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-jetta-1200-mm-designer-high-speed-ceiling-fan/65e915e3d20e6b311be4610d' },
{ name: 'Xtrim Wings 6 Inches Unisex Weightlifting Gym Belt Ultra Light Foam Core For Fitness Workout Double Belt Contoured Design Reinforced Stitching', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/xtrim-wings-6-inches-unisex-weightlifting-gym-belt-ultra-light-foam-core-for-fitness-workout-double-belt-contoured-design-reinforced-stitching/65e92778d20e6b432ae46127' },
{ name: 'Sonata Analog Watch Ep10005sp01', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-analog-watch-ep10005sp01/65e90b56d20e6bfed6e460fe' },
{ name: 'Winston Cordless Waterproof Mini Face Cleansing Brush For Deep Exfoliation 1 Year Warranty 2 In 1 Electric Face Cleanser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/winston-cordless-waterproof-mini-face-cleansing-brush-for-deep-exfoliation-1-year-warranty-2-in-1-electric-face-cleanser/65e90edad20e6b6728e46103' },
{ name: 'Orient Electric Moonlite 6w Led Down Light Cool White Light Round Pack Of 4 Recessed Ceiling Led Light Suited', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-moonlite-6w-led-down-light-cool-white-light-round-pack-of-4-recessed-ceiling-led-light-suited/65e907d1d20e6b13fbe460f9' },
{ name: 'Stag Fitness Professional Gym Training Home Gym Set 30 Mm Rubber Weight Plates Two Dumbbell Rods 25mm One 3 Feet Curl Rod 25mm Home Gym Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stag-fitness-professional-gym-training-home-gym-set-30-mm-rubber-weight-plates-two-dumbbell-rods-25mm-one-3-feet-curl-rod-25mm-home-gym-combo/65e92afcd20e6b7dcee4612c' },
{ name: 'Cleanfly Facial Brush Set With 4 Interchangeable Face Scrubber', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cleanfly-facial-brush-set-with-4-interchangeable-face-scrubber/65e9125ed20e6b26ade46108' },
{ name: 'Skybags One Size Brat Black 46 Cms Casual Standard Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skybags-one-size-brat-black-46-cms-casual-standard-backpack/65e92070d20e6b0dffe4611c' },
{ name: 'Noise Arc 138 Advanced Bluetooth Calling Smart Watch 550 Nits Brightness 100 Sports Modes 100 Watch Faces 7 Day Battery Ip68 Midnight Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-arc-138-advanced-bluetooth-calling-smart-watch-550-nits-brightness-100-sports-modes-100-watch-faces-7-day-battery-ip68-midnight-blue/65e8f9c0d20e6b64dbe460e5' },
{ name: 'Sizes 5 Uk 7 Uk 9 Uk 10 Uk Bxxy Tan Height Increasing British Full Brogue Shoes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-5-uk-7-uk-9-uk-10-uk-bxxy-tan-height-increasing-british-full-brogue-shoes/65e9044dd20e6b7ffee460f4' },
{ name: 'Clovia Womens Snug Fit Ankle Length High Rise Active Tights In Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-snug-fit-ankle-length-high-rise-active-tights-in-green/65e8f63cd20e6b0b44e460d5' },
{ name: 'Elv Aluminum Stylus Pen With Inbuilt Ball Point Pen Compatible With All Smartphonestabletsiphoneamazon Kindle Silversilver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/elv-aluminum-stylus-pen-with-inbuilt-ball-point-pen-compatible-with-all-smartphonestabletsiphoneamazon-kindle-silversilver/65e900c9d20e6b1107e460ef' },
{ name: ' Sbi Credit Card Asus Tuf Gaming F17 2022 1734394 Cms Fhd 144hz Intel Core I5 12500h 12th Gen Rtx 3050 4gb Gpu Gaming Laptop 16gb512gb Ssd90whr Batterywindows 11gray26 Kg Fx707zc4 Hx067w', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/-sbi-credit-card-asus-tuf-gaming-f17-2022-1734394-cms-fhd-144hz-intel-core-i5-12500h-12th-gen-rtx-3050-4gb-gpu-gaming-laptop-16gb512gb-ssd90whr-batterywindows-11gray26-kg-fx707zc4-hx067w/65e8ebafd20e6be0a2e460c6' },
{ name: 'Amazon Basics Compact Size Casual Backpack Unisex', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-compact-size-casual-backpack-unisex/65e8fd45d20e6b0df7e460ea' },
{ name: 'Lexar 240gb 25 Sata Iii Internal Ssd Up To 550mbs Read Lnq100x240g Rnnng', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lexar-240gb-25-sata-iii-internal-ssd-up-to-550mbs-read-lnq100x240g-rnnng/65e8e4a6d20e6b9e60e460bc' },
{ name: 'Amazon Brand Solimo Plastic Storage Containers With Sliding Mouth Set Of 12 750ml White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-plastic-storage-containers-with-sliding-mouth-set-of-12-750ml-white/65e8f2b8d20e6b8b26e460d0' },
{ name: 'Urbn 20000 Mah 20w Metal Power Bank Type C Pd Input Output Dual Usb Output Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-20000-mah-20w-metal-power-bank-type-c-pd-input-output-dual-usb-output-black/65e8e122d20e6b3dc0e460b7' },
{ name: 'Wonderchef Royal Velvet Non Stick 5 Piece Cookware Set Fry Pan With Lid Wok Dosa Tawa Mini Fry Pan Induction Bottom Soft Touch Handles Virgin Grade Aluminium Pfoaheavy Metals Free 3 Mm 2 Years Warranty Aqua', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-royal-velvet-non-stick-5-piece-cookware-set-fry-pan-with-lid-wok-dosa-tawa-mini-fry-pan-induction-bottom-soft-touch-handles-virgin-grade-aluminium-pfoaheavy-metals-free-3-mm-2-years-warranty-aqua/65e8ef33d20e6b7393e460cb' },
{ name: 'Myglamm Popxo Fresh Start Deep Moisturising Cucumber Sheet Mask 30gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/myglamm-popxo-fresh-start-deep-moisturising-cucumber-sheet-mask-30gm/65e8dd9ed20e6b8195e460b2' },
{ name: 'Mother Dairy Rasgulla 1000 Grams Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mother-dairy-rasgulla-1000-grams-box/65e8e82bd20e6bd6e4e460c1' },
{ name: 'Boyo Premium Nuts Combo 450g Roasted And Salted Cashew Nuts 200g And Salted Raisins 250g Himalayan Pink Salted Combo Healthy Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boyo-premium-nuts-combo-450g-roasted-and-salted-cashew-nuts-200g-and-salted-raisins-250g-himalayan-pink-salted-combo-healthy-pack/65e8cf8cd20e6b4e53e4609d' },
{ name: 'Samsung 465 L Optimal Fresh Digital Inverter 2 Star Frost Free Double Door Wifi Embedded Refrigerator Rt51cg662bb1tl Black Matt 2023', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-465-l-optimal-fresh-digital-inverter-2-star-frost-free-double-door-wifi-embedded-refrigerator-rt51cg662bb1tl-black-matt-2023/65e8cc067b999ae204f37e44' },
{ name: 'Bumtum Paraben Free Baby Soap 50gram Pack Of 1 Baby Talc Powder 200 Gram Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-50gram-pack-of-1-baby-talc-powder-200-gram-combo/65e8c8827b999a0600f37e3f' },
{ name: 'Bumtum Paraben Free Baby Soap 50gram Pack Of 1 Baby Gentle Shampoo 200 Ml Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-50gram-pack-of-1-baby-gentle-shampoo-200-ml-combo/65e8c4fe7b999a325af37e3a' },
{ name: 'Zebronics K24 Usb Keyboard With Long Life 8 Million Keystrokes Silent Comfortable Use Slim Design Retractable Stand 13 Meter Textured Cable Chiclet Keys And Uv Coated Keycaps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-k24-usb-keyboard-with-long-life-8-million-keystrokes-silent-comfortable-use-slim-design-retractable-stand-13-meter-textured-cable-chiclet-keys-and-uv-coated-keycaps/65e8d695d20e6bfa44e460a8' },
{ name: 'Dr Morepen Pain Relief Combo Pain X Spray 50 Gm Pain X Gel 30 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dr-morepen-pain-relief-combo-pain-x-spray-50-gm-pain-x-gel-30-gm/65e8d311d20e6b6218e460a3' },
{ name: 'Dzert Sport 26 Litres Printed School Bag Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dzert-sport-26-litres-printed-school-bag-backpack/65e8da1ad20e6badd5e460ad' },
{ name: 'Lenovo Tab M10 5g 106 Inch 269cm 6 Gb 128 Gb Expandablewi Fi 5g 90 Hz 2k Display 2000x1200dual Speakers With Dolby Atmos Android 13 Octa Core Processor Abyss Blue Zact0030in', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-tab-m10-5g-106-inch-269cm-6-gb-128-gb-expandablewi-fi-5g-90-hz-2k-display-2000x1200dual-speakers-with-dolby-atmos-android-13-octa-core-processor-abyss-blue-zact0030in/65e8c1797b999a150bf37e35' },
{ name: 'Aircase Archer Tech Lab Outemu Blue Keys Gaming Mechanical Keyboard With Anti Ghosting Keys 21rgb Modes Foldable Stand Detachable Braided Usb C Type Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aircase-archer-tech-lab-outemu-blue-keys-gaming-mechanical-keyboard-with-anti-ghosting-keys-21rgb-modes-foldable-stand-detachable-braided-usb-c-type-cable/65e8bdf57b999a894df37e30' },
{ name: 'Kuber Industries Disney Print Waterproof Canvas Laundry Bagmickey Mouse Printtoy Storagelaundry Basket Organizer Capicity 45 L Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-disney-print-waterproof-canvas-laundry-bagmickey-mouse-printtoy-storagelaundry-basket-organizer-capicity-45-l-black/65e8ba717b999a9e1cf37e2b' },
{ name: 'Toyzone Farm Animals Birds Coloring Mat Reusable And Washable Colouring Mat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toyzone-farm-animals-birds-coloring-mat-reusable-and-washable-colouring-mat/65e8b3687b999a816df37e21' },
{ name: 'Fruit Of The Loom Mens Hip Brief', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fruit-of-the-loom-mens-hip-brief/65e8b6ed7b999a87fff37e26' },
{ name: 'Vissco Functional Knee Support Moderate Support Knee Support For Pain Relief Knee Injury Braces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vissco-functional-knee-support-moderate-support-knee-support-for-pain-relief-knee-injury-braces/65e8ac607b999a426af37e17' },
{ name: 'Ecraftindia Black Golden Polyresin Handcrafted Meditating Lord Buddha Statue Perfect For Home And Office Decor Ideal For Meditation Yoga Enthusiasts Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-black-golden-polyresin-handcrafted-meditating-lord-buddha-statue-perfect-for-home-and-office-decor-ideal-for-meditation-yoga-enthusiasts-gift/65e8afe47b999a60a1f37e1c' },
{ name: 'Wireless Doorbell Himster Waterproof Door Bell Chime Kit Alarm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wireless-doorbell-himster-waterproof-door-bell-chime-kit-alarm/65e8a8db7b999a542df37e12' },
{ name: 'Dabur Glucoplus C Instant Energy Glucose Juicy Tasty Orange Flavour 500g With Sipper Free Glucose Replenishes Energy 25 More Glucose In Every Sip Vitamin C Helps Boosts Immunity Calcium Supports Bone Health', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-glucoplus-c-instant-energy-glucose-juicy-tasty-orange-flavour-500g-with-sipper-free-glucose-replenishes-energy-25-more-glucose-in-every-sip-vitamin-c-helps-boosts-immunity-calcium-supports-bone-health/65e89e4f7b999a63f8f37e01' },
{ name: 'Verbatim Type C To C 20 E Mark Kevlar Cable 120cm Grey 422 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/verbatim-type-c-to-c-20-e-mark-kevlar-cable-120cm-grey-422-inch/65e89aca7b999a81c7f37dfc' },
{ name: 'Autofy Sporto 2 31l Free Rain Cover Laptop Bag Office Bag Laptop Backpack For Men Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/autofy-sporto-2-31l-free-rain-cover-laptop-bag-office-bag-laptop-backpack-for-men-backpack/65e8903d7b999a297ef37dbd' },
{ name: 'Archies Mother Day Scented Aromatherapy Incense Sticksagarbatti Fragranced Ambient Atmosphere Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-mother-day-scented-aromatherapy-incense-sticksagarbatti-fragranced-ambient-atmosphere-gift/65e88cb97b999a5ab5f37db8' },
{ name: 'Bajaj Bahar Fresh 225 Mm Air Fan Mettalic Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-bahar-fresh-225-mm-air-fan-mettalic-grey/65e893c17b999a5b5bf37dcb' },
{ name: 'Craftvatika Rakhi Gift Items For Bhaiya Brother Rakhi For Brother And Bhabhi Kids With Gift Adiyogi Shiva Murti Statuerakshabandhan Gifts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/craftvatika-rakhi-gift-items-for-bhaiya-brother-rakhi-for-brother-and-bhabhi-kids-with-gift-adiyogi-shiva-murti-statuerakshabandhan-gifts/65e889347b999ae605f37db3' },
{ name: 'Fitness Mantra 10kg To 60kg Weight Adjustable Hight Quality Hand Gripper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitness-mantra-10kg-to-60kg-weight-adjustable-hight-quality-hand-gripper/65e8a1d37b999a027ff37e06' },
{ name: 'Reynolds Vista Rt Bp 25 Ct Jar 20 Blue 5 Black Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-vista-rt-bp-25-ct-jar-20-blue-5-black-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/65e897467b999a46e6f37dd0' },
{ name: 'Activa Heat Max 2000 Watts With 2 Heating Mode Full Abs Body Electric Fan Room Heater Come With 1 Year Warranty White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/activa-heat-max-2000-watts-with-2-heating-mode-full-abs-body-electric-fan-room-heater-come-with-1-year-warranty-white/65e87b237b999a69d2f37d98' },
{ name: 'Nusyl Infants Wild Flower Printed Tie Dye Tshirt Nuitdtsh0224', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nusyl-infants-wild-flower-printed-tie-dye-tshirt-nuitdtsh0224/65e885b07b999a114bf37da7' },
{ name: 'Eclet Paint Brush Combo 6 Flat Or 6 Round Wood Nylon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eclet-paint-brush-combo-6-flat-or-6-round-wood-nylon/65e87ea87b999a35baf37d9d' },
{ name: 'Skybags Klik Daypack 01 Navy Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skybags-klik-daypack-01-navy-blue/65e87094bb8f94331c896dd6' },
{ name: 'Boat Partypal 200208 70w Rms Stereo Party Speaker With Stunning Leds Multi Compatibility Modes7hrs Playtime Bluetooth Tws Featuremic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-partypal-200208-70w-rms-stereo-party-speaker-with-stunning-leds-multi-compatibility-modes7hrs-playtime-bluetooth-tws-featuremic/65e87418bb8f94235d896ddb' },
{ name: 'Di Kraft Leather Cover Handmade Deckle Edge Paper Vintage Engraved Diary With Metal Lock Daily Journal Planner Writing Notebook Office Diaries Design 22100 Pages 5x7 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/di-kraft-leather-cover-handmade-deckle-edge-paper-vintage-engraved-diary-with-metal-lock-daily-journal-planner-writing-notebook-office-diaries-design-22100-pages-5x7-inch/65e86d10bb8f941afb896dd1' },
{ name: 'Amazon Brand Inkast Men Stretchable Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-inkast-men-stretchable-jeans/65e8698bbb8f94b845896dcc' },
{ name: 'Maybelline New York Tattoo Eye Liner Gel Color Pencil Sharpenable Eyeliner Matte Finish Waterproof Eyeliner 36h Wear Deep Teal Blue 12g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maybelline-new-york-tattoo-eye-liner-gel-color-pencil-sharpenable-eyeliner-matte-finish-waterproof-eyeliner-36h-wear-deep-teal-blue-12g/65e86283bb8f94e33a896dc2' },
{ name: 'Kuber Industries Disney Print Round Laundry Bagbinwater Proof Material 3d Disney Minnie Printsize 37 X 37 X 46 Cm Capicity 45 L Pinknon Woven', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-disney-print-round-laundry-bagbinwater-proof-material-3d-disney-minnie-printsize-37-x-37-x-46-cm-capicity-45-l-pinknon-woven/65e8779f7b999a477bf37d92' },
{ name: 'Amazon Brand Symbol Mens Formal Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-formal-shirt/65e86607bb8f94c8c9896dc7' },
{ name: 'Lexton Photo Clips String Light Usb Operated Diwali Christmas Wedding Festival Decoration Light 10 Clipswarm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lexton-photo-clips-string-light-usb-operated-diwali-christmas-wedding-festival-decoration-light-10-clipswarm-white/65e8822c7b999af6fef37da2' },
{ name: 'Kazima Frankincense Essential Oil 100 Pure Natural Undiluted For Skin Care Hair Care 30ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kazima-frankincense-essential-oil-100-pure-natural-undiluted-for-skin-care-hair-care-30ml/65e85efebb8f94e7f0896dbd' },
{ name: 'Lenovo Ideapad 3 11th Gen Intel Core I3 156 Fhd Thin Light Laptop8gb512gb Ssdwindows 11office20212yr Warranty3months Xbox Game Passplatinum Grey17kg 81x800lgin 1000 Dpi Wireless Mouse', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-ideapad-3-11th-gen-intel-core-i3-156-fhd-thin-light-laptop8gb512gb-ssdwindows-11office20212yr-warranty3months-xbox-game-passplatinum-grey17kg-81x800lgin-1000-dpi-wireless-mouse/65e857f6bb8f9430a3896db3' },
{ name: 'Rjkart Leather A5 Embossed Aunty Happy Birthday Handmade Paper Diary With Lock', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rjkart-leather-a5-embossed-aunty-happy-birthday-handmade-paper-diary-with-lock/65e85471bb8f94b6e2896dae' },
{ name: 'Crompton 5 Meter Strip Light Red 300 Leds Pack Of 6 Without Driver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-5-meter-strip-light-red-300-leds-pack-of-6-without-driver/65e84d69bb8f94ee3e896da4' },
{ name: 'Cherry Crumble California Kids Girls Greet Nightsuit Pajama Set Ctws2 Nsuit 3318navy Blue9 12 Months', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cherry-crumble-california-kids-girls-greet-nightsuit-pajama-set-ctws2-nsuit-3318navy-blue9-12-months/65e849e4bb8f9409fd896d9f' },
{ name: 'Truke Buds Btg1 True Wireless Earbuds With Environmental Noise Cancellationenc Quad Mems Mic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/truke-buds-btg1-true-wireless-earbuds-with-environmental-noise-cancellationenc-quad-mems-mic/65e850edbb8f943411896da9' },
{ name: 'Royalica Women Regular Fit Self Design Casual Shirt Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/royalica-women-regular-fit-self-design-casual-shirt-pack-of-1/65e842dcbb8f94213a896d95' },
{ name: 'Cello Opalware Dazzle Series Tree Of Life Dinner Set 18 Units Light Weight Daily Use Crockery Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-series-tree-of-life-dinner-set-18-units-light-weight-daily-use-crockery-set/65e83f57bb8f9431e4896d90' },
{ name: 'Athom Living Cotton Bath Towel 350 Gsm 1 Pc Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athom-living-cotton-bath-towel-350-gsm-1-pc-multicolour/65e84660bb8f94564d896d9a' },
{ name: 'Godrej Professional Probio Avocado Nourish Hair Mask 200g For Fragile Hair No Sulphate With Avocado Almond Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-professional-probio-avocado-nourish-hair-mask-200g-for-fragile-hair-no-sulphate-with-avocado-almond-oil/65e83bd3bb8f94725e896d8b' },
{ name: 'Global Desi 2 Magical Maze 200ml Each Long Lasting Scent Spray Gift For Women Perfume Crafted By Ajmal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/global-desi-2-magical-maze-200ml-each-long-lasting-scent-spray-gift-for-women-perfume-crafted-by-ajmal/65e8384fbb8f94dc82896d86' },
{ name: 'Nusyl Elephant Printed Infants Polo T Shirt Nuicpth0165', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nusyl-elephant-printed-infants-polo-t-shirt-nuicpth0165/65e834cbbb8f944d2c896d81' },
{ name: 'Dorall Collection Red Secret Eau De Toilette 100ml For Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dorall-collection-red-secret-eau-de-toilette-100ml-for-men/65e81fb1bb8f9448af896d63' },
{ name: 'Dorall Collection Green Secret Eau De Toilette For Men 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dorall-collection-green-secret-eau-de-toilette-for-men-100ml/65e82a3ebb8f94817b896d72' },
{ name: 'Foxwheel 4mm Thick Light Weight With Anti Slip Dual Side Embossed Yoga Mat With Carrying Strap For Men Aand Women 4mm Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/foxwheel-4mm-thick-light-weight-with-anti-slip-dual-side-embossed-yoga-mat-with-carrying-strap-for-men-aand-women-4mm-grey/65e80e1b17a7484be1348656' },
{ name: 'Urbano Fashion Mens Cotton Color Block Regular Shorts Stretchable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbano-fashion-mens-cotton-color-block-regular-shorts-stretchable/65e80a9617a748abc4348651' },
{ name: 'Reebok Mens Energy Runner 30 M Running Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reebok-mens-energy-runner-30-m-running-shoe/65e81523bb8f946220896d54' },
{ name: 'Syska Hs100 Salonfinish Hair Straightener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/syska-hs100-salonfinish-hair-straightener/65e8119f17a748d3f734865b' },
{ name: 'Zebronics Ace Plus Wireless Soundbar With 30w Output Dual Passive Radiator Rgb Lightsbuilt In Rechargeable Battery Bluetooth 51coaxial Input Aux Fm Radio Usb Msd Tws Function', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-ace-plus-wireless-soundbar-with-30w-output-dual-passive-radiator-rgb-lightsbuilt-in-rechargeable-battery-bluetooth-51coaxial-input-aux-fm-radio-usb-msd-tws-function/65e82335bb8f9434b2896d68' },
{ name: 'Dorall Collection Angelic Delight For Women 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dorall-collection-angelic-delight-for-women-100ml/65e818a8bb8f945b74896d59' },
{ name: 'Ptron Bassbuds Plus In Ear Tws Earbuds With Hd Mics Bluetooth 50 Headphones With Immersive Sound Stereo Calls 28hrs Playtime Voice Assist Ready Ipx4 Water Resistant Fast Charge Pearl Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-plus-in-ear-tws-earbuds-with-hd-mics-bluetooth-50-headphones-with-immersive-sound-stereo-calls-28hrs-playtime-voice-assist-ready-ipx4-water-resistant-fast-charge-pearl-black/65e81c2cbb8f942f76896d5e' },
{ name: 'Qq Analog Silver Dial Mens Watch Qz68j202y', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qq-analog-silver-dial-mens-watch-qz68j202y/65e7f57cdf367aff4f3e0ffd' },
{ name: 'Halonix 20 Watt Led Battentubelight Streak Square 4 Ft Led Batten', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-20-watt-led-battentubelight-streak-square-4-ft-led-batten/65e7fc84df367a7b973e1007' },
{ name: 'Honest Enterprise Usb Sound Card External Stereo Sound Card With 35 Mm Headphone And Microphone Socket 71 Channel Audio Adapter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/honest-enterprise-usb-sound-card-external-stereo-sound-card-with-35-mm-headphone-and-microphone-socket-71-channel-audio-adapter/65e7f900df367af7163e1002' },
{ name: 'Portronics Luxcell 10k 10000 Mah Designer Power Bank With 225w Max Output Led Indicator Mach Usb A Output Type C Pd Output Type C Input Wake Up Buttonivory White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-luxcell-10k-10000-mah-designer-power-bank-with-225w-max-output-led-indicator-mach-usb-a-output-type-c-pd-output-type-c-input-wake-up-buttonivory-white/65e7ee73df367a3fb13e0ff3' },
{ name: 'Cockatoo Sports Headband For Men Head Band', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-sports-headband-for-men-head-band/65e7f1f7df367a58d93e0ff8' },
{ name: 'Clotth Theory Girls Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clotth-theory-girls-dress/65e8038d17a7482a77348647' },
{ name: 'Amazon Brand Solimo Two Tier Multipurpose Plastic Rack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-two-tier-multipurpose-plastic-rack/65e8000917a74889c4348642' },
{ name: 'Oral B Cavity Defense 123 Black Manual Toothbrush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oral-b-cavity-defense-123-black-manual-toothbrush/65e7eaefdf367a08613e0fee' },
{ name: 'Faballey Women Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faballey-women-casual-pants/65e7e3e6df367a39b83e0fe4' },
{ name: 'La Verne Cooling Gel Infused Breathable Pincore Technology Countour Memory Foam Pillow Standard White 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-verne-cooling-gel-infused-breathable-pincore-technology-countour-memory-foam-pillow-standard-white-1/65e7e76adf367ab7213e0fe9' },
{ name: 'Pink Root Milk Protein Conditioner 20050 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pink-root-milk-protein-conditioner-20050-ml/65e7e062df367a91da3e0fdf' },
{ name: 'Eufy By Anker Homevac S11 Go Cordless Stick Vacuum Cleaner Lightweight Cordless 120aw Suction Power Detachable Battery Deep Clean Carpet To Hard Floor Black Disk Filter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eufy-by-anker-homevac-s11-go-cordless-stick-vacuum-cleaner-lightweight-cordless-120aw-suction-power-detachable-battery-deep-clean-carpet-to-hard-floor-black-disk-filter/65e8071217a7483ec234864c' },
{ name: 'Smiley Soft Ball For Kids And Adults A Stress Relief Toy Non Toxic Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/smiley-soft-ball-for-kids-and-adults-a-stress-relief-toy-non-toxic-toy/65e7cb48df367a80e23e0fc1' },
{ name: 'Ponds Serum Boost Sunscreen Prevent And Fade Dark Patches With The Power Of Spf 55 And Niacinamide C Serum 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-serum-boost-sunscreen-prevent-and-fade-dark-patches-with-the-power-of-spf-55-and-niacinamide-c-serum-50g/65e7d959df367a43b83e0fd5' },
{ name: 'Cello Style Knit Basket Without Lid Set Of 4 Brownplastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-style-knit-basket-without-lid-set-of-4-brownplastic/65e7b9b0d66bb29bee2b46b5' },
{ name: 'Dettol Body Wash And Shower Gel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-body-wash-and-shower-gel/65e7c7c4df367a3dda3e0fbc' },
{ name: 'Lg Electronics 60 Cm24 Inches Full Hd Ips 1920 X 1080 Pixels Lcd Monitor Inbuilt Speaker Hdmi X 2 Vga Port 75 Hz Refresh Rate Amd Freesync 3 Side Borderless Slim Design 24ml600s W White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-electronics-60-cm24-inches-full-hd-ips-1920-x-1080-pixels-lcd-monitor-inbuilt-speaker-hdmi-x-2-vga-port-75-hz-refresh-rate-amd-freesync-3-side-borderless-slim-design-24ml600s-w-white/65e7ceccdf367a2e6d3e0fc6' },
{ name: 'Nm Nylon Cable Tie White Pack Of 100 4 Inches', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nm-nylon-cable-tie-white-pack-of-100-4-inches/65e7bd37df367a4dad3e0fac' },
{ name: 'Vgr V 071 Cordless Professional Hair Clipper Runtime 120 Min Trimmer For Men With 3 Guide Combs Silver Standardbattery Powered 1 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vgr-v-071-cordless-professional-hair-clipper-runtime-120-min-trimmer-for-men-with-3-guide-combs-silver-standardbattery-powered-1-count/65e7b62cd66bb2de052b46b0' },
{ name: 'Pixora 4 Way Oval Up Down Led Outdoor Waterproof Ip65 Exterior Wall Step Light Fixture Lamp Warm White Black Body 8 Wattsaluminium', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pixora-4-way-oval-up-down-led-outdoor-waterproof-ip65-exterior-wall-step-light-fixture-lamp-warm-white-black-body-8-wattsaluminium/65e7af23d66bb204b92b46a6' },
{ name: 'Unleash Storm 6 Inch150mm Cut Out Size Exhaust Fan For Kitchen Exhaust Fan For Bathroom Kitchen Exhaust Fan Bathroom Exhaust Fan Ventilation Fan Axial Fan With 2 Years Warranty Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unleash-storm-6-inch150mm-cut-out-size-exhaust-fan-for-kitchen-exhaust-fan-for-bathroom-kitchen-exhaust-fan-bathroom-exhaust-fan-ventilation-fan-axial-fan-with-2-years-warranty-ivory/65e79d8ed66bb284682b468d' },
{ name: 'Dmc Nylon Industrial Home Safety Cut Resistant Hand Gloves White Grey 10 Pairs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dmc-nylon-industrial-home-safety-cut-resistant-hand-gloves-white-grey-10-pairs/65e7b2a8d66bb2fab42b46ab' },
{ name: 'Havells Lhebhep7iz1w009 Octane Square 9 Watt Led Panel Light White 138 Mm X 138 Mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-lhebhep7iz1w009-octane-square-9-watt-led-panel-light-white-138-mm-x-138-mm/65e7ab9fd66bb2365f2b46a1' },
{ name: 'Captain Zack 2 In 1 Moisturizing Paw Butter Elbow Protector Pawsitively Smooth 100gm For All Pets Cream Wax Balm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/captain-zack-2-in-1-moisturizing-paw-butter-elbow-protector-pawsitively-smooth-100gm-for-all-pets-cream-wax-balm/65e7a497d66bb22a6e2b4697' },
{ name: 'Chromozome Men Modal Striper Trunk Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-men-modal-striper-trunk-pack-of-3/65e7a112d66bb227452b4692' },
{ name: 'Philips Ace Saver 9 Watt Led Bulb B22 Cool Day Light Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ace-saver-9-watt-led-bulb-b22-cool-day-light-pack-of-3/65e7a81bd66bb267b82b469c' },
{ name: 'Amazon Brand Inkast Men Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-inkast-men-casual-pants/65e79685d66bb260b52b4683' },
{ name: 'Reynolds Gluestick 6 Gm 12 Ct Jar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-gluestick-6-gm-12-ct-jar/65e79301d66bb219d82b467e' },
{ name: 'Hot Muggs Tweets Mug With Teddy Card315 Ml Sweet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hot-muggs-tweets-mug-with-teddy-card315-ml-sweet/65e78f7dd66bb234b82b4679' },
{ name: 'Converse Mens 167008c Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/converse-mens-167008c-sneaker/65e79a0ad66bb24a952b4688' },
{ name: 'Appulse Womens Short Sleeve Vneck 3 Pcs Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/appulse-womens-short-sleeve-vneck-3-pcs-combo/65e78bf8d66bb258f92b4674' },
{ name: 'Amazon Brand Symbol Mens Regular Track Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-regular-track-pants/65e7816bd66bb278052b4665' },
{ name: 'Oscar Blush Mini Pocket Perfume For Women 8 Ml Musk Sandalwood Notes Mini Perfume Travel Size Perfume Floral Fragrance Edp Women Girl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oscar-blush-mini-pocket-perfume-for-women-8-ml-musk-sandalwood-notes-mini-perfume-travel-size-perfume-floral-fragrance-edp-women-girl/65e77de7d66bb287542b4660' },
{ name: 'Lenovo Tab M10 Hd Tablet 101 Inch2565 Cm 2gb 32gb Wi Fi Only Slate Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-tab-m10-hd-tablet-101-inch2565-cm-2gb-32gb-wi-fi-only-slate-black/65e7735ad66bb2bcd92b4651' },
{ name: 'Lakme Perfect Radiance Brightening Night Cream With Niacinamide Brightening Face Cream For Women 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-perfect-radiance-brightening-night-cream-with-niacinamide-brightening-face-cream-for-women-50g/65e76fd6d66bb2aca82b464c' },
{ name: 'Blofumes Men Perfume Ocean Rush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blofumes-men-perfume-ocean-rush/65e784f0d66bb25cbb2b466a' },
{ name: 'Tex Ro Chest Of Drawers Versatile Drawer Storage Organizer Organisers Storage Box For Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tex-ro-chest-of-drawers-versatile-drawer-storage-organizer-organisers-storage-box-for-ideal/65e76c52d66bb23bfe2b4646' },
{ name: 'Shifuni Smart Phone Cordless Mobile Phone Toys Funny Light And Sound Flap Musical Mobile Cell Phone Toy For Kids Musical Cartoon Mini Phone With Flap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/shifuni-smart-phone-cordless-mobile-phone-toys-funny-light-and-sound-flap-musical-mobile-cell-phone-toy-for-kids-musical-cartoon-mini-phone-with-flap/65e77a63d66bb28b342b465b' },
{ name: 'Palmolive Anti Acne Purifying Face Masque With Tulsi And Lemongrass Essential Oil Suits All Skin Types 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/palmolive-anti-acne-purifying-face-masque-with-tulsi-and-lemongrass-essential-oil-suits-all-skin-types-100ml/65e761c2455f34e433523c79' },
{ name: 'Fitspire Keto Fit Weight Management 60 Capsules With Green Tea Green Coffee Extracts Including Apple Cider Vinegar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-keto-fit-weight-management-60-capsules-with-green-tea-green-coffee-extracts-including-apple-cider-vinegar/65e768cb455f345970523c83' },
{ name: 'Bumtum Paraben Free Baby Soap 50gram Pack Of 1 Baby Baby Body Lotion 200 Ml Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-50gram-pack-of-1-baby-baby-body-lotion-200-ml-combo/65e76547455f3418a7523c7e' },
{ name: 'Webby Christ The Reedemer Wooden Jigsaw Puzzle 108 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/webby-christ-the-reedemer-wooden-jigsaw-puzzle-108-pieces/65e75e3e455f3453dc523c74' },
{ name: 'Jack Jones Mens Regular Fit Mid Rise Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jack-jones-mens-regular-fit-mid-rise-jeans/65e75aba455f347fcf523c6f' },
{ name: 'Size Xl Chromozome Men Cotton Rib Brief Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-xl-chromozome-men-cotton-rib-brief-pack-of-2/65e7459e354eee15188d6c8b' },
{ name: 'Open Secret Green Raisin 100 Natural Kismis Fresh High In Fiber Gluten Free Raisins 500 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-green-raisin-100-natural-kismis-fresh-high-in-fiber-gluten-free-raisins-500-g/65e753b1455f34db95523c64' },
{ name: 'Greenfinity Premium Seedless Green Raisins Value Pack 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/greenfinity-premium-seedless-green-raisins-value-pack-1kg/65e7502bbcb45c0871ebd815' },
{ name: 'Nippo Aaa 4dg 15v Battery Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nippo-aaa-4dg-15v-battery-pack-of-10/65e75735455f3497a3523c6a' },
{ name: 'Agro Tech Foods Sundrop Cocoanut Almond Choco Spread 350 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agro-tech-foods-sundrop-cocoanut-almond-choco-spread-350-g/65e74922354eeec21c8d6c90' },
{ name: 'Amazon Brand Symbol Analog Silver Dial Mens Watch Az Sym Ss21a 04b', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-analog-silver-dial-mens-watch-az-sym-ss21a-04b/65e7421a354eeef16e8d6c86' },
{ name: 'Furniture Cafe Wooden Wall Shelves For Living Room For Home Decor Items Floating Book Rack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/furniture-cafe-wooden-wall-shelves-for-living-room-for-home-decor-items-floating-book-rack/65e73e95354eee7adf8d6c81' },
{ name: 'Fitspire Super Gold Bcaa Supplement 250gm With Watermelon Flavour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-super-gold-bcaa-supplement-250gm-with-watermelon-flavour/65e74ca7bcb45c054aebd810' },
{ name: 'Home Sparkle Engineered Wood Corner Shelf 2 Set Wall Mounted Decorative Wall Shelve Floating Corner Shelves', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/home-sparkle-engineered-wood-corner-shelf-2-set-wall-mounted-decorative-wall-shelve-floating-corner-shelves/65e73b11354eee08308d6c7c' },
{ name: 'Polycab Silencio Mini 1200mm Advanced Bldc 5 Star Rated Ceiling Fan With Remote25 Speed Setting 31 Year Warranty Get Free Installation Matt Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-silencio-mini-1200mm-advanced-bldc-5-star-rated-ceiling-fan-with-remote25-speed-setting-31-year-warranty-get-free-installation-matt-black/65e7378d354eee6a248d6c77' },
{ name: 'Produman Hub Home 8 In 1 Gym Bench Model No 333', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/produman-hub-home-8-in-1-gym-bench-model-no-333/65e72d00354eee134f8d6c68' },
{ name: 'Sonata Sleek Analog Blue Dial Mens Watch 7128wl05nn7128wl05', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-sleek-analog-blue-dial-mens-watch-7128wl05nn7128wl05/65e73084354eee706e8d6c6d' },
{ name: 'Ambrane Sliq Wireless Optical Mouse With 24ghz Usb Nano Dongle 3 Keys With Silent Clicks 1200 Dpi Comfortable Grip Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-sliq-wireless-optical-mouse-with-24ghz-usb-nano-dongle-3-keys-with-silent-clicks-1200-dpi-comfortable-grip-green/65e725f7354eee776b8d6c5e' },
{ name: 'Pashabache Saxon White Wine Glass Set 195ml 2 Pieces Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pashabache-saxon-white-wine-glass-set-195ml-2-pieces-transparent/65e7297c354eee22538d6c63' },
{ name: 'Nicka K Nicka K Baked Terracotta Eyeshadow Sparkle Bronze Multicolor 7 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nicka-k-nicka-k-baked-terracotta-eyeshadow-sparkle-bronze-multicolor-7-g/65e71eef354eee94768d6c54' },
{ name: 'Makeup Revolution Decadence 130 Matte Lip Coral 3 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-decadence-130-matte-lip-coral-3-ml/65e71b6a354eee36fa8d6c4f' },
{ name: 'Assembly Inflatable Travel Neck Pillow Neck Support Rest Pillow Headrest Cloude Grown Up Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/assembly-inflatable-travel-neck-pillow-neck-support-rest-pillow-headrest-cloude-grown-up-grey/65e717e6354eee509a8d6c4a' },
{ name: 'Himalaya Clear Complexion Brightening Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-clear-complexion-brightening-body-lotion/65e71462354eee81a28d6c45' },
{ name: 'Nicka K Radiant Liquid Shadow Gold Lava Gold 5 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nicka-k-radiant-liquid-shadow-gold-lava-gold-5-g/65e72273354eeef4d08d6c59' },
{ name: 'Size 4 Uk Power Womens Orion Slipper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-4-uk-power-womens-orion-slipper/65e70d59354eee70328d6c3b' },
{ name: 'Sizes S M L Xl Jack Jones Mens Cotton Briefs Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-s-m-l-xl-jack-jones-mens-cotton-briefs-pack-of-1/65e710dd354eee6e188d6c40' },
{ name: 'Sizes M Xl Style Quotient Men Grey Washed Denim Jacket With Embroidered Patch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-m-xl-style-quotient-men-grey-washed-denim-jacket-with-embroidered-patch/65e6f137354eee0b108d6c09' },
{ name: 'Tantra Car Sun Shade', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tantra-car-sun-shade/65e6ea2e354eee1a408d6bff' },
{ name: 'Neelam Non Stick Dosa Tawa Induction Friendly Red Color 29cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-non-stick-dosa-tawa-induction-friendly-red-color-29cm/65e6e6aa354eeeaacc8d6bf8' },
{ name: 'For Icici Bank Credit Card Oneplus Nord Ce 3 5g Grey Shimmer 8gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-icici-bank-credit-card-oneplus-nord-ce-3-5g-grey-shimmer-8gb-ram-128gb-storage/65e6e325354eee41718d6beb' },
{ name: 'Nhr Blaze Storm Hot Fire Soft Bullet Gun Toy With 10 Safe Soft Foam Bullets Fun Target Shooting Battle Fight Game For Kids Boys Gun', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nhr-blaze-storm-hot-fire-soft-bullet-gun-toy-with-10-safe-soft-foam-bullets-fun-target-shooting-battle-fight-game-for-kids-boys-gun/65e6dfa1354eee7dd78d6be6' },
{ name: 'Go Vegan Chamomile Flower Tea 30 Gram 30 Cups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-vegan-chamomile-flower-tea-30-gram-30-cups/65e6d190354eee26ab8d6bd2' },
{ name: 'Lenovo 156 3962cm Slim Everyday Backpack Made In India Compact Water Resistant Organized Storagelaptop Sleevetablet Pocketfront Workstation2 Side Pocketspadded Adjustable Shoulder Straps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-156-3962cm-slim-everyday-backpack-made-in-india-compact-water-resistant-organized-storagelaptop-sleevetablet-pocketfront-workstation2-side-pocketspadded-adjustable-shoulder-straps/65e6ce0b354eeedaab8d6bcd' },
{ name: 'Sunsilk Super Shine Hair Serum For Dry Frizzy Hair Vitamin E Nourishment 48 Hour Frizz Free Non Sticky 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sunsilk-super-shine-hair-serum-for-dry-frizzy-hair-vitamin-e-nourishment-48-hour-frizz-free-non-sticky-100ml/65e6ca87354eee73928d6bc8' },
{ name: 'Kadio Analog 20 Cm X 20 Cm Wall Clock Orange With Glass Standard', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kadio-analog-20-cm-x-20-cm-wall-clock-orange-with-glass-standard/65e6dc1d354eee36ce8d6be1' },
{ name: 'Visko Garden Tools Set 662 Gardening Hand Tool Kit 3 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/visko-garden-tools-set-662-gardening-hand-tool-kit-3-pieces/65e6d898354eee2e888d6bdc' },
{ name: 'Philips Ujjwal 20 Watt Led Batten Cool Day Light 4 Feet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ujjwal-20-watt-led-batten-cool-day-light-4-feet/65e6c703354eee0c9f8d6bc3' },
{ name: 'Set Wet Styling Gift Kit For Men Set Wet Extreme Hold Spray200ml Set Wet Global Edition No Gas Perfume New York Night No Gas Deo 120ml Wallet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/set-wet-styling-gift-kit-for-men-set-wet-extreme-hold-spray200ml-set-wet-global-edition-no-gas-perfume-new-york-night-no-gas-deo-120ml-wallet/65e6d514354eeedeed8d6bd7' },
{ name: 'Chik Protein Solution Thick And Glossy Shampoofor Gorgeous Shiny Hair With The Goodness Of Badam Protein Bhringraj Oil And Black Tea 650ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chik-protein-solution-thick-and-glossy-shampoofor-gorgeous-shiny-hair-with-the-goodness-of-badam-protein-bhringraj-oil-and-black-tea-650ml/65e6c37f354eee75b48d6bbe' },
{ name: 'Noise Diva And Mettalix Special Valentines Day Gifting Edition Gold Link And Elite Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-diva-and-mettalix-special-valentines-day-gifting-edition-gold-link-and-elite-black/65e6bffa354eee49768d6bb9' },
{ name: 'Go Desi Jaggery Powder 1 Kg Gur Gud Pure And Natural', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-jaggery-powder-1-kg-gur-gud-pure-and-natural/65e6bc76354eee4b178d6bb4' },
{ name: 'Nivea Men Dark Spot Reduction Face Wash 100 G Pack Of 3 With Ginko And Ginseng Extracts For Clean Healthy Clear Skin In Summer 10 X Vitamin C Effect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-men-dark-spot-reduction-face-wash-100-g-pack-of-3-with-ginko-and-ginseng-extracts-for-clean-healthy-clear-skin-in-summer-10-x-vitamin-c-effect/65e6b8f2354eee6ae98d6baf' },
{ name: 'Hammer Active 20 195 Display Bluetooth Calling Smart Watch With Metal Body In Built Games Wireless Charging Aod 600 Nits Brightness Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hammer-active-20-195-display-bluetooth-calling-smart-watch-with-metal-body-in-built-games-wireless-charging-aod-600-nits-brightness-black/65e69ccf354eeed38e8d6b85' },
{ name: 'E Cosmos 5v 12w Portable Flexible Usb Led Light Colours May Vary Small Ec Pof1 Plastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-5v-12w-portable-flexible-usb-led-light-colours-may-vary-small-ec-pof1-plastic/65e6a054354eee79208d6b8a' },
{ name: 'Kohler Complementary Single Mode Hand Shower', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kohler-complementary-single-mode-hand-shower/65e6a75c354eee7fdf8d6b96' },
{ name: 'Pritt Glue Stick Safechild Friendly Glue For Artscrafts Activities Strong Hold Adhesive', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pritt-glue-stick-safechild-friendly-glue-for-artscrafts-activities-strong-hold-adhesive/65e6a3d8354eee3b0b8d6b8f' },
{ name: 'Hammer Active 20 Ultra 195 Display Bluetooth Calling Smart Watch With Metal Body In Bulit Games Wireless Charging Aod 600 Nits Brightness Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hammer-active-20-ultra-195-display-bluetooth-calling-smart-watch-with-metal-body-in-bulit-games-wireless-charging-aod-600-nits-brightness-orange/65e6994b354eee3fc18d6b80' },
{ name: 'Natures Essence 5 Aha Daily Exfoliating Serum Tanned Skin Removal Face Serum For Women 30ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/natures-essence-5-aha-daily-exfoliating-serum-tanned-skin-removal-face-serum-for-women-30ml/65e69242354eee00da8d6b76' },
{ name: 'Zebronics Zeb Smart Cam 101 Smart Wifi Ptz Indoor Camera 1080p Remote Monitoring Plug Play Night Vision Motion Tracking Msd Card 2way Audio Works With Androidios Smartphones', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-smart-cam-101-smart-wifi-ptz-indoor-camera-1080p-remote-monitoring-plug-play-night-vision-motion-tracking-msd-card-2way-audio-works-with-androidios-smartphones/65e695c7354eee54fc8d6b7b' },
{ name: 'Noise Buds Vs401 In Ear Truly Wireless Earbuds With 50h Of Playtime Low Latencyup To 50ms Quad Mic With Enc Instacharge10 Min200 Min10mm Driver Bt V53jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-buds-vs401-in-ear-truly-wireless-earbuds-with-50h-of-playtime-low-latencyup-to-50ms-quad-mic-with-enc-instacharge10-min200-min10mm-driver-bt-v53jet-black/65e68ebe354eee22db8d6b71' },
{ name: 'Nhr Blaze Storm Manual Soft Bullet Gun Toy With 10 Safe Soft Foam Bullets Fun Target Shooting Battle Fight Game', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nhr-blaze-storm-manual-soft-bullet-gun-toy-with-10-safe-soft-foam-bullets-fun-target-shooting-battle-fight-game/65e687b6354eee46798d6b67' },
{ name: 'Wonder Homeware Sigma Snacker Microwave Safe Bowl Set 4 Pc 500 Ml Pink Color Red Black Yellow Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-sigma-snacker-microwave-safe-bowl-set-4-pc-500-ml-pink-color-red-black-yellow-green/65e68b3a354eee17388d6b6c' },
{ name: 'For Sbi Credit Card Dell 14 Laptop 12th Gen Intel Core I3 1215u Processor8gb512gb Ssdintel Uhd Graphics1403556cm Fhdwindows 11 Mso2115 Month Mcafeespill Resistant Keyboardgreythin Light 148kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-dell-14-laptop-12th-gen-intel-core-i3-1215u-processor8gb512gb-ssdintel-uhd-graphics1403556cm-fhdwindows-11-mso2115-month-mcafeespill-resistant-keyboardgreythin-light-148kg/65e68431354eee228c8d6b5f' },
{ name: 'Tecno Megabook T1 Intel Core 11th Gen I5 Processor 16gb Ram512gb Ssd Storage156 Inch 3962 Cm Eye Comfort Disply 148mm Ultra Slim70 Wh Large Batterywindows 11 Moonshine Silver156 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-megabook-t1-intel-core-11th-gen-i5-processor-16gb-ram512gb-ssd-storage156-inch-3962-cm-eye-comfort-disply-148mm-ultra-slim70-wh-large-batterywindows-11-moonshine-silver156-kg/65e66f169c891070b6ea0aec' },
{ name: 'Tp Link Deco M4 Whole Home Mesh Wi Fi System Seamless Roaming And Speedy Ac1200 Work With Amazon Echoalexa Router And Wi Fi Booster Parent Control Router Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-deco-m4-whole-home-mesh-wi-fi-system-seamless-roaming-and-speedy-ac1200-work-with-amazon-echoalexa-router-and-wi-fi-booster-parent-control-router-pack-of-3/65e6729a9c891085a5ea0af1' },
{ name: 'Polycab 15w Led Panel Light Scintillate Edge Slim Round Smart Offers Bright Lumination Long Lifespan No Harmful Radiation Cool White 6500k 1 Pc Cut Out 59 Inches', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-15w-led-panel-light-scintillate-edge-slim-round-smart-offers-bright-lumination-long-lifespan-no-harmful-radiation-cool-white-6500k-1-pc-cut-out-59-inches/65e6761f9c891096ffea0af6' },
{ name: 'Kamasutra Longlast Condom For Men Dotted Climax Delay', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kamasutra-longlast-condom-for-men-dotted-climax-delay/65e6680e9c89101af0ea0ae2' },
{ name: 'Oyo Baby Baby Wipes Offers Combo Wet Wipes With Lidwater Wipes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oyo-baby-baby-wipes-offers-combo-wet-wipes-with-lidwater-wipes/65e67d279c89100ee0ea0b00' },
{ name: 'Gizga Essentials 25cm X 21cm Gaming Mouse Pad Laptop Desk Mat Computer Mouse Pad With Smooth Mouse Control Mercerized Surface Antifray Stitched Embroidery Edges Anti Slip Rubber Base', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-25cm-x-21cm-gaming-mouse-pad-laptop-desk-mat-computer-mouse-pad-with-smooth-mouse-control-mercerized-surface-antifray-stitched-embroidery-edges-anti-slip-rubber-base/65e664899c8910fc9bea0add' },
{ name: 'Dove Refreshing Body Wash With Refreshing Cucumber And Green Tea Scent For All Skin Type Smoother Skin 250 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-refreshing-body-wash-with-refreshing-cucumber-and-green-tea-scent-for-all-skin-type-smoother-skin-250-ml/65e66b929c89102ee2ea0ae7' },
{ name: 'Maybelline New York Brow Pencil With Spoolie Long Lasting And Natural Looking Results Define Blend Brow Pencil Natural Brown 016 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maybelline-new-york-brow-pencil-with-spoolie-long-lasting-and-natural-looking-results-define-blend-brow-pencil-natural-brown-016-g/65e679a39c8910a9e5ea0afb' },
{ name: 'Vaseline Healthy Bright Daily Brightening Daily Moisturizer 400 Ml For Glowing Skin With Vitamin B3 Visibly Radiant Skin In 2 Weeks Lightweight Non Sticky Non Oily Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-healthy-bright-daily-brightening-daily-moisturizer-400-ml-for-glowing-skin-with-vitamin-b3-visibly-radiant-skin-in-2-weeks-lightweight-non-sticky-non-oily-body-lotion/65e659fc9c891047fbea0ace' },
{ name: 'Skore Notout Climax Delay Condoms 1500 Dots Coloured 10 Pieces Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skore-notout-climax-delay-condoms-1500-dots-coloured-10-pieces-pack-of-1/65e652f49c89108215ea0ac4' },
{ name: 'Ptron Tangent Duo Bluetooth 52 Wireless In Ear Earphones With Mic 24hrs Playback 13mm Driver Deep Bass Fast Charging Type C Neckband Dual Pairing Voice Assistant Ipx4 Water Resistant Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-tangent-duo-bluetooth-52-wireless-in-ear-earphones-with-mic-24hrs-playback-13mm-driver-deep-bass-fast-charging-type-c-neckband-dual-pairing-voice-assistant-ipx4-water-resistant-black/65e661059c8910044bea0ad8' },
{ name: 'Dove Renewing Raspberry Body Wash With Lime Pump Bottle Go Fresh Nourishing Shower Gel Gentle Mild Body Cleanser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-renewing-raspberry-body-wash-with-lime-pump-bottle-go-fresh-nourishing-shower-gel-gentle-mild-body-cleanser/65e64beb9c8910167cea0aba' },
{ name: 'Boat Stone 1200 14w Bluetooth Speaker With Upto 9 Hours Battery Rgb Leds Ipx7 And Tws Featureblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-stone-1200-14w-bluetooth-speaker-with-upto-9-hours-battery-rgb-leds-ipx7-and-tws-featureblack/65e65d809c89108f2aea0ad3' },
{ name: 'Hp 150 Wired Mouse Elegant Ergonomic Design 1600 Dpi Optical Tracking Usb Plug Play 3 Years Warranty 240j6aa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-150-wired-mouse-elegant-ergonomic-design-1600-dpi-optical-tracking-usb-plug-play-3-years-warranty-240j6aa-black/65e656789c89103521ea0ac9' },
{ name: 'Sonata Analog Watch Nm8152wl02', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-analog-watch-nm8152wl02/65e64f6f9c8910ba12ea0abf' },
{ name: 'Savlon Moisturizing Glycerin Soap Bar With Germ Protection 625g 125g Combo Pack Of 5 Soap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savlon-moisturizing-glycerin-soap-bar-with-germ-protection-625g-125g-combo-pack-of-5-soap/65e6415e9c89106325ea0aab' },
{ name: 'Macroman M Series Mens Cotton Vest', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/macroman-m-series-mens-cotton-vest/65e644e39c8910b3abea0ab0' },
{ name: 'Bath Body Works Black Cherry Merlot Hand Cream 29 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bath-body-works-black-cherry-merlot-hand-cream-29-ml/65e648679c89100e91ea0ab5' },
{ name: 'V Guard Zio Pro Instant Geyser 3 Litre With Advanced 4 Layer Safety 3000 W Powerful Heating Superior Energy Efficiency Stainless Steel Tank White Metallic Rose Goldwall', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/v-guard-zio-pro-instant-geyser-3-litre-with-advanced-4-layer-safety-3000-w-powerful-heating-superior-energy-efficiency-stainless-steel-tank-white-metallic-rose-goldwall/65e63dda9c89100c93ea0aa6' },
{ name: 'Axe Signature Champion No Gas Body Deodorant Bodyspray For Men 154 Ml Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/axe-signature-champion-no-gas-body-deodorant-bodyspray-for-men-154-ml-pack-of-1/65e63a569c89104c5aea0a9d' },
{ name: 'Luminous 1202mini Upsdual Band Router Upsreliable Power Backup During Power Cut', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-1202mini-upsdual-band-router-upsreliable-power-backup-during-power-cut/65e62c459c8910381dea0a81' },
{ name: 'Yns Crafts Stock Happy Birthday Decoration Set Of 49 Balloon 13 Happy Birthday Foil Balloon 1 Pink Star 1 Blue Star 18 2 Silver Curtain 2 Unicorn Foil Balloon 30 Pink Blue Metallic Balloon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yns-crafts-stock-happy-birthday-decoration-set-of-49-balloon-13-happy-birthday-foil-balloon-1-pink-star-1-blue-star-18-2-silver-curtain-2-unicorn-foil-balloon-30-pink-blue-metallic-balloon/65e62fc99c8910ea61ea0a86' },
{ name: 'Kesh King Emami Kesh King Scalp And Hair Medicine Ayurvedic Hairfall Expert Anti Dandruff Shampoo1000 Grams', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesh-king-emami-kesh-king-scalp-and-hair-medicine-ayurvedic-hairfall-expert-anti-dandruff-shampoo1000-grams/65e621b89c8910ea04ea0a72' },
{ name: 'Biotique Face Glo Advance Brightening Fruit Cream Visibly Flawless Skin Lightens Skin Tone 100 Botanical Extracts Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-face-glo-advance-brightening-fruit-cream-visibly-flawless-skin-lightens-skin-tone-100-botanical-extracts-suitable/65e628c09c8910dbb1ea0a7c' },
{ name: 'Beardo Perfume For Men Mafia 50ml Musky Woody Perfume For Men Long Lasting Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-perfume-for-men-mafia-50ml-musky-woody-perfume-for-men-long-lasting-gift/65e61e339c89104fb1ea0a6c' },
{ name: 'Milton Mop Stick Spin Mop Stick Rod With 1 Microfiber Refill Standing Magic Pocha With Easy Grip Handle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-mop-stick-spin-mop-stick-rod-with-1-microfiber-refill-standing-magic-pocha-with-easy-grip-handle/65e636d29c8910013fea0a90' },
{ name: 'Solara Blendeasy Nutri Blender For Smoothies And Juices 400 Watts Bullet Juicer Mixer Grinder Coffee Blender', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/solara-blendeasy-nutri-blender-for-smoothies-and-juices-400-watts-bullet-juicer-mixer-grinder-coffee-blender/65e613a57a7938c8a15eecf6' },
{ name: 'Nasher Miles Nicobar Hard Sided Polypropylene Luggage Set Of 2 Plum And White Trolley Bags 55 65 Cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nasher-miles-nicobar-hard-sided-polypropylene-luggage-set-of-2-plum-and-white-trolley-bags-55-65-cm/65e6253c9c89105095ea0a77' },
{ name: 'Size Sm Chromozome Men Cotton Rib Trunk', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-sm-chromozome-men-cotton-rib-trunk/65e617297a793862725eecfb' },
{ name: 'Maybelline New York Brow Pencil Creamy Texture Long Lasting Fashion Brow Cream Pencil Brown 11g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maybelline-new-york-brow-pencil-creamy-texture-long-lasting-fashion-brow-cream-pencil-brown-11g/65e61aad7a79381f1f5eed00' },
{ name: 'Size L Carlton London Women Casual Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-carlton-london-women-casual-dress/65e610207a79384c905eecf1' },
{ name: 'Evolve Raagi Bhujia Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evolve-raagi-bhujia-pack-of-2/65e5e9717a7938616e5eecba' },
{ name: 'Kesh King Organics Fermented Rice Water Shampoo Nourishes Repairs For Frizz Free Bouncy Hair Certified Organic No Artificial Colours Parabens Phthalates Or Harmful Chemicals 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesh-king-organics-fermented-rice-water-shampoo-nourishes-repairs-for-frizz-free-bouncy-hair-certified-organic-no-artificial-colours-parabens-phthalates-or-harmful-chemicals-300ml/65e5f0797a7938ec645eecc4' },
{ name: 'Amazfit Active Smart Watch With Ai Fitness Exercise Coach Gps Bluetooth Calling Music 14 Day Battery 175 Amoled Display Alexa Enabled Fitness Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazfit-active-smart-watch-with-ai-fitness-exercise-coach-gps-bluetooth-calling-music-14-day-battery-175-amoled-display-alexa-enabled-fitness-watch/65e5ecf57a793821c05eecbf' },
{ name: 'Amazon Basics Origami Paper 5 Colors 200 Sheets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-origami-paper-5-colors-200-sheets/65e5e5ec7a79384e395eecb5' },
{ name: 'Bambalio Sn 30 Pack Of 6 Sticky Note Pad 3x1 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bambalio-sn-30-pack-of-6-sticky-note-pad-3x1-inch/65e18a5fb65f07e80afa70d4' },
{ name: 'Sharpie Retractable Black Permanent Marker With Fine Tip For Precise Writing Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sharpie-retractable-black-permanent-marker-with-fine-tip-for-precise-writing-suitable/65e186dbb65f076a7dfa70cf' },
{ name: 'Visko Plastic Watering Can For House Plants Garden Plants Watering Can', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/visko-plastic-watering-can-for-house-plants-garden-plants-watering-can/65e17c4deb2cca7ffce2651b' },
{ name: 'Maped Creativ Kit For Black White Boards', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maped-creativ-kit-for-black-white-boards/65e171be42325d4c98f8deea' },
{ name: 'Axe Dark Temptation Mens Deodorant 215 Ml Long Lasting Deodorant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/axe-dark-temptation-mens-deodorant-215-ml-long-lasting-deodorant/65e178c642325d13aaf8def4' },
{ name: 'Beatxp Buzz Electric Toothbrush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-buzz-electric-toothbrush/65e1754242325da2eaf8deef' },
{ name: 'Colorbar Cosmetics Sindoor Maroon 38 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/colorbar-cosmetics-sindoor-maroon-38-ml/65e16e3942325d8883f8dee5' },
{ name: 'Buildskill 3m Measuring Tape Durable Auto Lock Belt Clip Clear Markings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buildskill-3m-measuring-tape-durable-auto-lock-belt-clip-clear-markings/65e1602842325d407ff8dec9' },
{ name: 'Inalsa Premium Otg Oven With Double Glass Door Capacity 30 Liters 1500w Rotisserie Convention Function Temperature Timer Selection 5 Cooking Modes Chefs Club 30bkrc', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/inalsa-premium-otg-oven-with-double-glass-door-capacity-30-liters-1500w-rotisserie-convention-function-temperature-timer-selection-5-cooking-modes-chefs-club-30bkrc/65e1521742325de684f8deb5' },
{ name: 'Chromozome Men Modal Printed Brief', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-men-modal-printed-brief/65e14b0e42325dcc36f8de9f' },
{ name: 'Storio Toys Blaze Storm Manual Soft Suction Bullet Gun Toy With 10 Safe Soft Foam Bullets Fun Target Shooting Battle Fight Game', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/storio-toys-blaze-storm-manual-soft-suction-bullet-gun-toy-with-10-safe-soft-foam-bullets-fun-target-shooting-battle-fight-game/65e1440642325d702ff8de94' },
{ name: 'Chromozome Men Cotton Brief Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-men-cotton-brief-pack-of-2/65e1478a42325d31e4f8de99' },
{ name: 'Cello Kleeno Compacto Spin Mop With 2 Refill Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-kleeno-compacto-spin-mop-with-2-refill-blue/65e13cfd42325da0ebf8de8a' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round 1000 Ml Set Of 6 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-1000-ml-set-of-6-blue/65e1327042325d5437f8de7b' },
{ name: 'Cello Opalware Green Orchard Mixing Bowls With Lid 500ml 1000ml 1500ml 3 Units White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-green-orchard-mixing-bowls-with-lid-500ml-1000ml-1500ml-3-units-white/65e1397942325d1852f8de85' },
{ name: 'Storio Soft Foam Darts Bullets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/storio-soft-foam-darts-bullets/65e1408142325d98fbf8de8f' },
{ name: 'Orient Electric Eternal Shine Led Strip 24w Blue Colour Without Driver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-eternal-shine-led-strip-24w-blue-colour-without-driver/65e12eec42325d3276f8de60' },
{ name: 'Amazon Brand Arthur Harvey Men Dress Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-arthur-harvey-men-dress-pants/65e12b6842325db8cbf8de5b' },
{ name: 'Unibic Foods Cashew Badam Cookies 500 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-foods-cashew-badam-cookies-500-g/65e135f542325d4865f8de80' },
{ name: 'Patriot Memory Viper Steel Ddr4 2666mhz Cl18 Laptop Memory 32gb 1x32gb 12v Pvs432g266c8s', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/patriot-memory-viper-steel-ddr4-2666mhz-cl18-laptop-memory-32gb-1x32gb-12v-pvs432g266c8s/65e127e242325d15d3f8de55' },
{ name: 'Appulse Women Regular Fit Printed Long Sleeve Thumbopen Round Neck Tshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/appulse-women-regular-fit-printed-long-sleeve-thumbopen-round-neck-tshirt/65e11d531875de36719c4369' },
{ name: 'Perpetual Radha Krishna Paintings For Wall Decoration Set Of Five 3d Scenery Vastu Painting For Living Room Large Size With Frames', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/perpetual-radha-krishna-paintings-for-wall-decoration-set-of-five-3d-scenery-vastu-painting-for-living-room-large-size-with-frames/65e119cf1875de4ab19c4364' },
{ name: 'L A Girl Glide Gel Liner Frosted Taupe 12g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/l-a-girl-glide-gel-liner-frosted-taupe-12g/65e1245c1875de6c6c9c4373' },
{ name: 'Clovia Womens Chic Basic Top In Royal Blue Halloween Print Joggers In Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-chic-basic-top-in-royal-blue-halloween-print-joggers-in-blue/65e120d81875de5cb59c436e' },
{ name: 'Figaro Baby Lotion With Goodness Of Natural Olive Oil Enriched With Vitamin E Dermatologically Tested 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/figaro-baby-lotion-with-goodness-of-natural-olive-oil-enriched-with-vitamin-e-dermatologically-tested-100-ml/65e10bbe1875de73199c4350' },
{ name: 'Park Avenue Beer Shampoo For Damaged Hair 650ml Paraben Free For Damage Fee Hair Crafted With Natural Beer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-beer-shampoo-for-damaged-hair-650ml-paraben-free-for-damage-fee-hair-crafted-with-natural-beer/65e10f421875de25b59c4355' },
{ name: 'Zebronics Zeb Jukebar 3820a Pro With Alexa Built In Englishhindi Bt Powerful Soundbar 80w Rms Smart App Control Music Streaming Advanced Dual Far Field Mics Inbuilt Dual Sub Hdmi Arc Optical', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-jukebar-3820a-pro-with-alexa-built-in-englishhindi-bt-powerful-soundbar-80w-rms-smart-app-control-music-streaming-advanced-dual-far-field-mics-inbuilt-dual-sub-hdmi-arc-optical/65e104b51875de79ec9c4346' },
{ name: 'Wild Stone Stone Long Lasting Perfume Body Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wild-stone-stone-long-lasting-perfume-body-spray/65e101311875def1f29c4341' },
{ name: 'Rylan Bathroom Mat Door Mat Floor Mat Bath Mat Doormat Bathroom Carpet Non Slip Mat For Bathroom Cushion Mat Super Absorbent Soft Carpet Quick Dry Dirt Barrier', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rylan-bathroom-mat-door-mat-floor-mat-bath-mat-doormat-bathroom-carpet-non-slip-mat-for-bathroom-cushion-mat-super-absorbent-soft-carpet-quick-dry-dirt-barrier/65e0fdad1875de59c09c433c' },
{ name: 'Dove Body Love Cooling Gel Crme Paraben Free 48hrs Moisturisation With Plan Based Moisturiser Non Oily Feel Refreshed Hydrated Skin 145g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-body-love-cooling-gel-crme-paraben-free-48hrs-moisturisation-with-plan-based-moisturiser-non-oily-feel-refreshed-hydrated-skin-145g/65e1083a1875de0d579c434b' },
{ name: 'Boat Rockerz 330 Pro In Ear Bluetooth Neckband With 60hrs Playtime Asap Charge Enx Tech Signature Sound Bt V52 Dual Pairing Ipx5 With Mic Teal Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-330-pro-in-ear-bluetooth-neckband-with-60hrs-playtime-asap-charge-enx-tech-signature-sound-bt-v52-dual-pairing-ipx5-with-mic-teal-green/65e112c71875de23889c435a' },
{ name: 'Aurelia Womens Skinny Tights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aurelia-womens-skinny-tights/65e1164b1875de4e4d9c435f' },
{ name: 'Dhruvi Trendz Womens T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dhruvi-trendz-womens-t-shirt/65e0f6a41875deb8659c4332' },
{ name: 'The Indian Garage Co Mens Slim Fit Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-indian-garage-co-mens-slim-fit-shirt/65e0fa281875de95999c4337' },
{ name: 'Soopii Desktop Premium A Quality Mobile Phone Stand Tablet Stand Tab Stand Mobile Holder Adjustable Foldable Mobile Stand Stand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/soopii-desktop-premium-a-quality-mobile-phone-stand-tablet-stand-tab-stand-mobile-holder-adjustable-foldable-mobile-stand-stand/65e0ef9b1875de7a939c4328' },
{ name: 'Size 6 Months 12 Months Us Polo Assn Girls Red Crew Neck Embroidered Logo T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-months-12-months-us-polo-assn-girls-red-crew-neck-embroidered-logo-t-shirt/65e0f3201875de22269c432d' },
{ name: 'Amazon Brand House Shields Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-house-shields-mens-regular-fit-t-shirt/65e0e18a1875de13779c4314' },
{ name: 'Neelam Stainless Steel 7 22g Diamond Royal Glass 300 Ml Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-7-22g-diamond-royal-glass-300-ml-silver/65e0da821875deec2c9c430a' },
{ name: 'Ascension Mushroom Lamp Automatic Light Multi Color Changing Best Night Avatar Bulbs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ascension-mushroom-lamp-automatic-light-multi-color-changing-best-night-avatar-bulbs/65e0d6fd1875de231c9c4305' },
{ name: 'Playr X Chennai Super Kings Mens Regular Fit T Shirt Prcsk23 111pyellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/playr-x-chennai-super-kings-mens-regular-fit-t-shirt-prcsk23-111pyellow/65e0d3791875de5e8a9c4300' },
{ name: 'Park Avenue Original Collection Deodorant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-original-collection-deodorant/65e0cff51875de1d389c42fb' },
{ name: 'Royal Enfield Open Face Helmet With Clear Visor Gloss Black Size M 57 58cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/royal-enfield-open-face-helmet-with-clear-visor-gloss-black-size-m-57-58cm/65e0cc701875de4eb59c42f6' },
{ name: 'Vania Skin Polishing Spa Serum 25 Ml For Face Body Unisex For Glowing Face Whiteningnight Serumdark Spotsspotless Glowenriched With Vitamin Cgeraniumjajobakesarlpatouchlirosehip Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vania-skin-polishing-spa-serum-25-ml-for-face-body-unisex-for-glowing-face-whiteningnight-serumdark-spotsspotless-glowenriched-with-vitamin-cgeraniumjajobakesarlpatouchlirosehip-oil/65e0de061875deb6f49c430f' },
{ name: 'Bergner Walking 720 Ml Thermo Steel Bottleflask Hotcold Vacuum Insulated For Watercoffeeteasoupjuice Use In Officecommutesportstravel Screw On Top Lid Metallic Lilacpurple Rustleak Proof', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bergner-walking-720-ml-thermo-steel-bottleflask-hotcold-vacuum-insulated-for-watercoffeeteasoupjuice-use-in-officecommutesportstravel-screw-on-top-lid-metallic-lilacpurple-rustleak-proof/65e0e50e1875de0ea89c4319' },
{ name: 'Lotus Botanicals Vitamin C Matte Fluid Sunscreen Sun Protect Skin Brightening Spf 50 Vitamin C For All Skin Types 75g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-botanicals-vitamin-c-matte-fluid-sunscreen-sun-protect-skin-brightening-spf-50-vitamin-c-for-all-skin-types-75g/65e0ec171875de84b99c4323' },
{ name: 'Bergner Walking 530 Ml Thermo Steel Bottleflask Hotcold Vacuum Insulated For Watercoffeeteasoupjuice Use In Officecommutesportstravel Flip Top Lid With Strap Metallic Olivegreen Rustleak Proof', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bergner-walking-530-ml-thermo-steel-bottleflask-hotcold-vacuum-insulated-for-watercoffeeteasoupjuice-use-in-officecommutesportstravel-flip-top-lid-with-strap-metallic-olivegreen-rustleak-proof/65e0e8931875de71d09c431e' },
{ name: 'Cello Strong Plastic Step On Pedal Garbage Dustbin Orange 60 Ltr', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-strong-plastic-step-on-pedal-garbage-dustbin-orange-60-ltr/65e0c5681875de544a9c42ec' },
{ name: 'Open Secret Gift Hamper With Mix Dry Fruit And Nuts 3 Item Gift Combo Flavoured Nuts Trail Mix Gift Hamper With Card And Decoration Lights Gift Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-gift-hamper-with-mix-dry-fruit-and-nuts-3-item-gift-combo-flavoured-nuts-trail-mix-gift-hamper-with-card-and-decoration-lights-gift-box/65e0c8ec1875de05729c42f1' },
{ name: 'Panasonic 05w B22 Led Bulb Pack Of 4 92853yw Pk4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panasonic-05w-b22-led-bulb-pack-of-4-92853yw-pk4/65e0c1e41875de59e09c42e7' },
{ name: 'Fiama Body Wash Shower Gel Lemongrass Jojoba 900ml Family Pack Body Wash For Women And Men With Skin Conditioners For Smooth Moisurised Skin Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-body-wash-shower-gel-lemongrass-jojoba-900ml-family-pack-body-wash-for-women-and-men-with-skin-conditioners-for-smooth-moisurised-skin-suitable/65e0b3d21875debe069c42ad' },
{ name: 'Fiama Shower Gel Patchouli Macadamia Body Wash With Skin Conditioners For Soft Glowing Skin 250ml Bottle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-shower-gel-patchouli-macadamia-body-wash-with-skin-conditioners-for-soft-glowing-skin-250ml-bottle/65e0b04e1875de34f19c42a8' },
{ name: 'Nusyl Infants Mummys Cutie Printed Tie Dye Tshirt Nuitdtsh0059', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nusyl-infants-mummys-cutie-printed-tie-dye-tshirt-nuitdtsh0059/65e0acc91875de37919c42a3' },
{ name: 'Havells Sprint 400mm Energy Saving With Remote Control Bldc Pedestal Fan White Yellow Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-sprint-400mm-energy-saving-with-remote-control-bldc-pedestal-fan-white-yellow-pack-of-2/65e0a5c152b53b4826c84a5a' },
{ name: 'Icc Blue Army Sling Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/icc-blue-army-sling-bag/65e0a23d52b53b68c3c84a55' },
{ name: 'Truke Buds S2 Lite True Wireless Made In India Earbuds With Mems Quad Mic Enc 48h Playtime 10mm Real Titanium Speaker Gaming Mode Type C Fast Charge Aac Codec Bt 51 Ipx4 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/truke-buds-s2-lite-true-wireless-made-in-india-earbuds-with-mems-quad-mic-enc-48h-playtime-10mm-real-titanium-speaker-gaming-mode-type-c-fast-charge-aac-codec-bt-51-ipx4-black/65e0a94552b53bd547c84a69' },
{ name: 'Ptron Bassbuds Duo In Ear Wireless Earbuds With Immersive Sound32hrs Playtimeclear Calls Tws Earbudsbluetooth V51 Headphonetype C Fast Chargingvoice Assistantipx4 Water Resistant Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-duo-in-ear-wireless-earbuds-with-immersive-sound32hrs-playtimeclear-calls-tws-earbudsbluetooth-v51-headphonetype-c-fast-chargingvoice-assistantipx4-water-resistant-brown/65e09eb852b53bca31c84a50' },
{ name: 'Spot It Marvel Emojis', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/spot-it-marvel-emojis/65e09b3452b53bc6f9c84a4b' },
{ name: 'Firefox Bikes Flytron 16t Kids Light Weight Frame Cycle With Anti Skid Pedal Bmx Handlebar Non Toxic Paint Power Brake Seat Height Adjustment Frame Size 85 Inch 5 7 Years Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/firefox-bikes-flytron-16t-kids-light-weight-frame-cycle-with-anti-skid-pedal-bmx-handlebar-non-toxic-paint-power-brake-seat-height-adjustment-frame-size-85-inch-5-7-years-red/65e0861a52b53b9c7cc84a1b' },
{ name: 'Rubix Home 200 Gsm All Season Brown Fleece Blanket Single Bed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rubix-home-200-gsm-all-season-brown-fleece-blanket-single-bed/65e0942b52b53babd5c84a41' },
{ name: 'Nayasa Bucket 18 Liter With Stoolpatla And Mug Set Of 3 Blue By Krishna Enterprises', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-bucket-18-liter-with-stoolpatla-and-mug-set-of-3-blue-by-krishna-enterprises/65e0899e52b53b1ecdc84a32' },
{ name: 'Usha Onio Lambda 1200mm Bldc 5 Star Energy Efflicient Dust Oil Resistant Ceiling Fan With Remote White Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/usha-onio-lambda-1200mm-bldc-5-star-energy-efflicient-dust-oil-resistant-ceiling-fan-with-remote-white-pack-of-1/65e07b8d52b53b1bb2c84a0c' },
{ name: 'Urbn 5000 Mah Premium Black Edition Magsafe Power Bank 15w Fast Wireless Charging Strong Magnetic Hold Type C Inputoutput Pass Through Charging Magtag Ring Included Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-5000-mah-premium-black-edition-magsafe-power-bank-15w-fast-wireless-charging-strong-magnetic-hold-type-c-inputoutput-pass-through-charging-magtag-ring-included-black/65e090a752b53baf5cc84a3c' },
{ name: 'Aka Chic Womens Flare Fit Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aka-chic-womens-flare-fit-jeans/65e07f1152b53baacbc84a11' },
{ name: 'Dell Professional 24 Inches 1920 X 1080 Pixels Full Hd Monitor Wall Mountable Height Adjustable Ips Panel With Hdmi Vga Dp Usb Ports P2422h Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-professional-24-inches-1920-x-1080-pixels-full-hd-monitor-wall-mountable-height-adjustable-ips-panel-with-hdmi-vga-dp-usb-ports-p2422h-black/65e08d2352b53b19fec84a37' },
{ name: 'Rubix Home 200 Gsm All Season Camel Fleece Blanket Single Bed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rubix-home-200-gsm-all-season-camel-fleece-blanket-single-bed/65e097b052b53b21cbc84a46' },
{ name: 'Clovia Womens Cotton Floral Print Short Nightdress In Purple', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-floral-print-short-nightdress-in-purple/65e0829652b53be417c84a16' },
{ name: 'Dell 24 6096 Cm Fhd Monitor 1920 X 1080 60hzips Panelbuilt In Dual Speaker Wireless Keyboard And Mouse Km3322w Anti Fade Spill Resistant Keys', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-24-6096-cm-fhd-monitor-1920-x-1080-60hzips-panelbuilt-in-dual-speaker-wireless-keyboard-and-mouse-km3322w-anti-fade-spill-resistant-keys/65e0748452b53b3145c84a02' },
{ name: 'Fiddly Wooden Jigsaw Puzzle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiddly-wooden-jigsaw-puzzle/65e0710052b53b2d7dc849fd' },
{ name: 'Zebronics Newly Launched Astro Premium Gaming Mouse With Upto 7200 Dpi 7 Buttons Braided Cable High Resolution Sensor Windows Software With Rgb Led Light Modes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-newly-launched-astro-premium-gaming-mouse-with-upto-7200-dpi-7-buttons-braided-cable-high-resolution-sensor-windows-software-with-rgb-led-light-modes/65e0780952b53bd7dec84a07' },
{ name: 'Lakme Absolute Luminuous Skin Tint Foundation Cool Walnut 23ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-absolute-luminuous-skin-tint-foundation-cool-walnut-23ml/65e05be49e03dccdc2a38c6f' },
{ name: 'Rubix Home Mesmerize 144 Tc Microfiber Multicolor Double Bedsheet With 2 Pillow Covers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rubix-home-mesmerize-144-tc-microfiber-multicolor-double-bedsheet-with-2-pillow-covers/65e069f852b53babb1c849f3' },
{ name: 'Santoor Refreshing Skin Body Wash Enriched With Tangy Orange Oil Neroli Extracts Soap Free Paraben Free Ph Balanced Shower Gel 230ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/santoor-refreshing-skin-body-wash-enriched-with-tangy-orange-oil-neroli-extracts-soap-free-paraben-free-ph-balanced-shower-gel-230ml/65e062ef52b53bfec4c849e8' },
{ name: 'Lakm Sun Expert Tinted Pa Spf50 Spray Ultra Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-sun-expert-tinted-pa-spf50-spray-ultra-light/65e0585f9e03dc732fa38c6a' },
{ name: 'Lakme Sun Expert De Tan Scrub 50 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-sun-expert-de-tan-scrub-50-g/65e05f689e03dc8592a38c74' },
{ name: 'Ganesh Stainless Steel Potato Crusher Vegetable Smasher Pav Bhaji Masher With Handle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-stainless-steel-potato-crusher-vegetable-smasher-pav-bhaji-masher-with-handle/65e051569e03dc24e4a38c60' },
{ name: 'Dyazo Water Resistant Laptop Sleeve With Handle Compatible', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dyazo-water-resistant-laptop-sleeve-with-handle-compatible/65e06d7c52b53bc18dc849f8' },
{ name: 'Dabur Vatika Enriched Coconut Hair Oil 300ml Controls Hair Fall Fights Dandruff Prevents Damage For Strong Thick Shiny Hair', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-enriched-coconut-hair-oil-300ml-controls-hair-fall-fights-dandruff-prevents-damage-for-strong-thick-shiny-hair/65e0667352b53be7cac849ee' },
{ name: 'Hp V222w 32gb Usb 20 Pen Drive Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-v222w-32gb-usb-20-pen-drive-silver/65e054db9e03dc9d59a38c65' },
{ name: 'Fastrack Road Trip Analog Grey Dial Womens Watch Nl6112sm03nn6112sm03', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-road-trip-analog-grey-dial-womens-watch-nl6112sm03nn6112sm03/65e04dd29e03dcfafea38c5b' },
{ name: 'Portronics Sounddrum 10w Portable Bluetooth Stereo Speaker With Powerful Bass 35mm Aux Usb Music In Built Mic In Built Fm 6 7 Hrs Playtimeblue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-sounddrum-10w-portable-bluetooth-stereo-speaker-with-powerful-bass-35mm-aux-usb-music-in-built-mic-in-built-fm-6-7-hrs-playtimeblue/65e046c99e03dcfcb5a38c51' },
{ name: 'Boat Rockerz 245v2 Bluetooth Wireless In Ear Earphones With Upto 8 Hours Playback 12mm Drivers Ipx5 Magnetic Eartips Integrated Controls And Lightweight Design With Mic Navy Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-245v2-bluetooth-wireless-in-ear-earphones-with-upto-8-hours-playback-12mm-drivers-ipx5-magnetic-eartips-integrated-controls-and-lightweight-design-with-mic-navy-blue/65e04a4e9e03dc2015a38c56' },
{ name: 'Crompton Galaxy Decoration Copper Usb Powered String Fairy Lights With 100 Led Light 10 Meters Pink Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-galaxy-decoration-copper-usb-powered-string-fairy-lights-with-100-led-light-10-meters-pink-pack-of-1/65e038b89e03dc37e6a38c3d' },
{ name: 'Rubix Home 220 Gsm All Season Green Fleece Blanket Double Bed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rubix-home-220-gsm-all-season-green-fleece-blanket-double-bed/65e03c3c9e03dc00b6a38c42' },
{ name: 'Lakm 9 To 5 Primer Matte Lip Color Pink Rose 36g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-9-to-5-primer-matte-lip-color-pink-rose-36g/65e02e2b9e03dc2624a38c2e' },
{ name: 'Marwar Dry Golgappapani Puri Papadpanipuri Papad Ready To Fry Whole Wheat Golgappe 400g Value Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/marwar-dry-golgappapani-puri-papadpanipuri-papad-ready-to-fry-whole-wheat-golgappe-400g-value-pack/65e031af9e03dced47a38c33' },
{ name: 'Amazon Brand Symbol Mens Cotton Solid Round Neck Regular Fit T Shirt Pack Of 2 Available In Plus Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-cotton-solid-round-neck-regular-fit-t-shirt-pack-of-2-available-in-plus-size/65e0239e9e03dc059ca38c1f' },
{ name: 'Vega Mini Nail File Multicolor Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vega-mini-nail-file-multicolor-pack-of-2/65e027239e03dc6169a38c24' },
{ name: 'Tecno Spark Go 2023 Uyuni Blue 3gb Ram 32gb Storage 66 Display 13mp Ai Dual Camera 5mp Front Camera With Dual Flash Trendy Design Upto 6gb Dynamic Ram 5000mah Type C Back Fingerprint', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-spark-go-2023-uyuni-blue-3gb-ram-32gb-storage-66-display-13mp-ai-dual-camera-5mp-front-camera-with-dual-flash-trendy-design-upto-6gb-dynamic-ram-5000mah-type-c-back-fingerprint/65e0201a9e03dc4d71a38c1a' },
{ name: 'Zebronics Knock Out Portable Bluetooth V53 Speaker With 10w Output Rgb Led Lights Tws Function Up To 10h Backup Usb Msd Passive Radiator Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-knock-out-portable-bluetooth-v53-speaker-with-10w-output-rgb-led-lights-tws-function-up-to-10h-backup-usb-msd-passive-radiator-blue/65e01c969e03dc5a60a38c15' },
{ name: 'Oral B Sensitive Ultrathin Manual Toothbrush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oral-b-sensitive-ultrathin-manual-toothbrush/65dffced0d5b98bc34168564' },
{ name: 'Dove Refreshing Body Wash With Cucumber Green Tea Scent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-refreshing-body-wash-with-cucumber-green-tea-scent/65dff9680d5b983813168557' },
{ name: 'Oppo Enco Air 3 True Wireless In Ear Earbuds With 25hrs Playtime Fast Charging134mm Driver Bt V53 Glaze White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oppo-enco-air-3-true-wireless-in-ear-earbuds-with-25hrs-playtime-fast-charging134mm-driver-bt-v53-glaze-white/65dff2600d5b98618116854d' },
{ name: 'Aristocrat Coral Lp Bp E Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristocrat-coral-lp-bp-e-grey/65dff5e40d5b9825d1168552' },
{ name: 'Frontech 19 Inch High Performance Hd Led Monitor Refresh Rate 75 Hz 1440x900 Pixels Slim Stylish Design With 250 Nits Brightness Hdmi Vga Ports Built In Power Supply Mon 0001black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/frontech-19-inch-high-performance-hd-led-monitor-refresh-rate-75-hz-1440x900-pixels-slim-stylish-design-with-250-nits-brightness-hdmi-vga-ports-built-in-power-supply-mon-0001black/65dfeedbaa1b097431f4ad4e' },
{ name: 'Lakme Absolute Fresh Life Day Cream 50 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-absolute-fresh-life-day-cream-50-g/65dfe44eaa1b091348f4ad35' },
{ name: 'Chromozome Men Cotton Rich T Shirt Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-men-cotton-rich-t-shirt-pack-of-1/65dfe0caaa1b090e9af4ad30' },
{ name: 'Sonata Digital Grey Dial Mens Watch Nl77080pp04', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-digital-grey-dial-mens-watch-nl77080pp04/65dfeb57aa1b09b8bcf4ad49' },
{ name: 'Lakm Absolute Youth Infinity Skin Sculpting Face Serum With Niacinamide Collagen Booster And Vitamin A', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-absolute-youth-infinity-skin-sculpting-face-serum-with-niacinamide-collagen-booster-and-vitamin-a/65dfe7d3aa1b09326ef4ad44' },
{ name: 'Oomph Womens Mini A Line Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oomph-womens-mini-a-line-dress/65dfdd46aa1b09a5faf4ad2a' },
{ name: 'Zebronics War K Gaming Keyboard With 104 Keysmulti Color Led18m Braided Cable Gold Plated Usblaser Keycaps Anti Ghosting 19 Keys2 Step Stand12 Integrated Multimedia Keys White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-war-k-gaming-keyboard-with-104-keysmulti-color-led18m-braided-cable-gold-plated-usblaser-keycaps-anti-ghosting-19-keys2-step-stand12-integrated-multimedia-keys-white/65dfd9bf8ea2fd4981413323' },
{ name: 'Acer Nitro Qg221q 215 Inch 5461 Cm Full Hd Gaming Lcd Monitor With Led Backlight I Va Panel I 1 Ms Vrb 100 Hz Refresh I Hdmi Vga I Stereo Speakers I 250 Nits I Amd Free Sync I Eye Care Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-nitro-qg221q-215-inch-5461-cm-full-hd-gaming-lcd-monitor-with-led-backlight-i-va-panel-i-1-ms-vrb-100-hz-refresh-i-hdmi-vga-i-stereo-speakers-i-250-nits-i-amd-free-sync-i-eye-care-black/65dfd63b8ea2fd2bae41331e' },
{ name: 'Havells Octane Gl Led Panel 6 W Round 4000k Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-octane-gl-led-panel-6-w-round-4000k-led-ceiling-light/65dfd2b78ea2fdd3e9413319' },
{ name: 'Expertomind Heating Pad For Muscle Pain Period Cramps Electric Heating Towel For Back Neck Leg Shoulder Pain Relief Orthopaedic Pain Reliever', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/expertomind-heating-pad-for-muscle-pain-period-cramps-electric-heating-towel-for-back-neck-leg-shoulder-pain-relief-orthopaedic-pain-reliever/65dfcf338ea2fd1d5f413314' },
{ name: 'Gizga Essentials 2 In 1 Laptopnotebookmacbook Tabletop Stand 8 Adjustable Angles Mobile Tabletop Stand 12 156 Laptops Heat Dissipation Anti Slip Base Portable And Lightweight Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-2-in-1-laptopnotebookmacbook-tabletop-stand-8-adjustable-angles-mobile-tabletop-stand-12-156-laptops-heat-dissipation-anti-slip-base-portable-and-lightweight-black/65dfcbae8ea2fd9e6041330f' },
{ name: 'Wonder Homeware Microwave Safe Unbreakable Plates Set 6 Pcs Half Plate Black Color Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-microwave-safe-unbreakable-plates-set-6-pcs-half-plate-black-color-brown/65dfc82a8ea2fd93fa41330a' },
{ name: 'Qq Analog Black Dial Mens Watch Qb14j105y', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qq-analog-black-dial-mens-watch-qb14j105y/65dfaf8c8ea2fddd274132e7' },
{ name: 'The Indian Garage Co Mens Jacket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-indian-garage-co-mens-jacket/65dfac088ea2fd9efd4132e2' },
{ name: 'Biotique Diva Shine Lip Gloss Paprika Madness Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-diva-shine-lip-gloss-paprika-madness-red/65dfc4a68ea2fd4ab6413305' },
{ name: 'Moto One Black 464 Gb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/moto-one-black-464-gb/65dfa8848ea2fd8f504132dd' },
{ name: 'Nutty Gritties Premium Kalmi Dates 350g Juicy Delicious Khajoor Khajur No Artificial Colours Naturally Sweet Instant Energy Resealable Pouch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutty-gritties-premium-kalmi-dates-350g-juicy-delicious-khajoor-khajur-no-artificial-colours-naturally-sweet-instant-energy-resealable-pouch/65dfb3108ea2fd31cb4132ec' },
{ name: 'Parachute Advansed Curry Leaves Hair Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-curry-leaves-hair-oil/65dfbd9d8ea2fdf6d14132fb' },
{ name: 'American Tourister Kamiliant Harrier 56 Cms Small Cabin Polypropylene Hard Sided 4 Wheeler Spinner Wheels Carry On Luggage Carrot Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/american-tourister-kamiliant-harrier-56-cms-small-cabin-polypropylene-hard-sided-4-wheeler-spinner-wheels-carry-on-luggage-carrot-orange/65dfc1218ea2fd68e2413300' },
{ name: 'Elleven Womens Regular Churidar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/elleven-womens-regular-churidar/65dfba198ea2fd1d014132f6' },
{ name: 'Blanko By King Dusk Luxury Perfume For Men 100ml With Patchouli Amber Musk Scent Solid Long Lasting Smell Eau De Parfum Gift Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blanko-by-king-dusk-luxury-perfume-for-men-100ml-with-patchouli-amber-musk-scent-solid-long-lasting-smell-eau-de-parfum-gift-set/65dfb6958ea2fd69c24132f1' },
{ name: 'Fruit Of The Loom Mens Cotton Brief Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fruit-of-the-loom-mens-cotton-brief-pack-of-1/65dfa4ff8ea2fd22df4132d8' },
{ name: 'Amazon Brand House Shields Mens Regular Stretch Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-house-shields-mens-regular-stretch-pants/65df9df78ea2fd87ba4132ce' },
{ name: 'Cadbury Bournvita Chocolate Nutrition Drink 15 Kg Container 2 X 750 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-bournvita-chocolate-nutrition-drink-15-kg-container-2-x-750-g/65dfa17b8ea2fd37264132d3' },
{ name: 'Portronics Harmonics Twins S3 Smart Tws Bluetooth 52 In Ear Earbuds With 20 Hrs Playtime 8mm Drivers Type C Charging Ipx4 Water Resistant Low Latency Lightweight Designblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-harmonics-twins-s3-smart-tws-bluetooth-52-in-ear-earbuds-with-20-hrs-playtime-8mm-drivers-type-c-charging-ipx4-water-resistant-low-latency-lightweight-designblack/65df96ee8ea2fd42464132c4' },
{ name: 'Lakm Lip Love Gelato Chapstick Berry Mint 45 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-lip-love-gelato-chapstick-berry-mint-45-g/65df8c6164de97d49ad648bd' },
{ name: 'Lakm Lip Love Gelato Chapstick Bubblegum 45 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-lip-love-gelato-chapstick-bubblegum-45-g/65df88dd64de97ecfbd648b8' },
{ name: 'Toyzone Mickey On Scooter Bump N Go Electronic Toy Bump N Go Movement Battery Operated Toy Light Music Bump N Go Action Scooter Toy For Kids', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toyzone-mickey-on-scooter-bump-n-go-electronic-toy-bump-n-go-movement-battery-operated-toy-light-music-bump-n-go-action-scooter-toy-for-kids-/65df855864de978a1fd648b3' },
{ name: 'Hindware Smart Appliances Caeli Bianco Star Rated Ceiling Fan 1200mm 425 Rpm Energy Efficient Silent Air Delivery Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-smart-appliances-caeli-bianco-star-rated-ceiling-fan-1200mm-425-rpm-energy-efficient-silent-air-delivery-fan/65df81d464de978309d648ae' },
{ name: 'Neelam Stainless Steel 65 22g Diamond Prem Vati 300 Ml Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-65-22g-diamond-prem-vati-300-ml-silver/65df7e5064de974fe3d648a9' },
{ name: 'Boat Rockerz 255 Pro Bluetooth In Ear Earphones With Upto 60 Hours Playback Asap Charge Ipx7 Dual Pairing And Bluetooth V50moon White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-255-pro-bluetooth-in-ear-earphones-with-upto-60-hours-playback-asap-charge-ipx7-dual-pairing-and-bluetooth-v50moon-white/65df936964de973d7bd648c7' },
{ name: 'Sara Light Hydrating Eye Gel For Reducing Dark Cirles Skin Ageing For Women Men Suitable For All Skin Type 25 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sara-light-hydrating-eye-gel-for-reducing-dark-cirles-skin-ageing-for-women-men-suitable-for-all-skin-type-25-gm/65df8fe564de97c38ed648c2' },
{ name: 'Oomph Minishort Sheath Orange Dress In Crepe Fabric With Square Neck And No Sleeves', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oomph-minishort-sheath-orange-dress-in-crepe-fabric-with-square-neck-and-no-sleeves/65df9a728ea2fd65584132c9' },
{ name: 'Everyuth Brightening Lemon Cherry Face Wash 150 Gm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/everyuth-brightening-lemon-cherry-face-wash-150-gm-pack-of-2/65df774764de97f86ad6489f' },
{ name: 'Activa Elena 1200mm Dust Resistant High Power Antique Copper Premium Series Ceiling Fan With 5 Years Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/activa-elena-1200mm-dust-resistant-high-power-antique-copper-premium-series-ceiling-fan-with-5-years-warranty/65df7acb64de979437d648a4' },
{ name: 'Hammer Pulse X 183 Display Smart Watch With Bluetooth Calling Voice Assistant 60 Sports Modes Spo2 Heart Rate Tracking Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hammer-pulse-x-183-display-smart-watch-with-bluetooth-calling-voice-assistant-60-sports-modes-spo2-heart-rate-tracking-black/65df73c364de972e4bd6489a' },
{ name: 'Cadbury Dairy Milk Chocolate Home Treats 119 G Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-dairy-milk-chocolate-home-treats-119-g-pack-of-3/65df6cba64de97b393d64890' },
{ name: 'Amazon Brand Umi Over The Door Hook Hanger Hanging Organizer Rack Steel Door Hook Hanger Wall Hook Rack 6 Hooks Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-umi-over-the-door-hook-hanger-hanging-organizer-rack-steel-door-hook-hanger-wall-hook-rack-6-hooks-black/65df65b11e3fc46db5987de4' },
{ name: 'Cello Maxfresh Glassy Seal O Fresh Glass Lunch Box Set Of 3 Containers 400ml Each Brown Microwavable Borosilicate Glass Leak Proof Design Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-maxfresh-glassy-seal-o-fresh-glass-lunch-box-set-of-3-containers-400ml-each-brown-microwavable-borosilicate-glass-leak-proof-design-ideal/65df4d1303a9ee4e4f2cb634' },
{ name: 'Attro Food Time Stainless Steel Lunch Box Airtight Leak Proof 2 Containers With Spoon Big 750 Ml Small 250 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/attro-food-time-stainless-steel-lunch-box-airtight-leak-proof-2-containers-with-spoon-big-750-ml-small-250-ml/65df498e03a9ee51012cb62f' },
{ name: 'Zebronics Sound Bomb X1 3 In 1 Wireless Bluetooth V50 In Ear Earbuds Speaker Combo With 30 Hour Backup Built In Led Torch Call Function Voice Asst Type C And Splash Proof Portable Design Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-sound-bomb-x1-3-in-1-wireless-bluetooth-v50-in-ear-earbuds-speaker-combo-with-30-hour-backup-built-in-led-torch-call-function-voice-asst-type-c-and-splash-proof-portable-design-black/65df509703a9ee0c802cb639' },
{ name: 'Amazon Brand Solimo Storage Basket Set Of 4 Small Blue Plastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-storage-basket-set-of-4-small-blue-plastic/65df3b7d03a9ee39702cb61b' },
{ name: 'Vim Dishwash Liquid Gel Lemon Refill Pouch 2 Ltr Dishwash Gel Infused With The Power Of Lemons Leaves No Residue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vim-dishwash-liquid-gel-lemon-refill-pouch-2-ltr-dishwash-gel-infused-with-the-power-of-lemons-leaves-no-residue/65df37f903a9ee4a8a2cb616' },
{ name: 'Cello Max Fresh Click Lunch Box Set With Bag 3 Containers 300ml Black Outer Plastic Inner Stainless Steel Tiffin Box With Jacket Leakproof Lunch Boxes Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-max-fresh-click-lunch-box-set-with-bag-3-containers-300ml-black-outer-plastic-inner-stainless-steel-tiffin-box-with-jacket-leakproof-lunch-boxes-ideal/65df428603a9ee80de2cb625' },
{ name: 'Snickers Almond Filled Chocolates 22 Grams Bar Pack Of 24', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/snickers-almond-filled-chocolates-22-grams-bar-pack-of-24/65df30f003a9ee5d452cb60c' },
{ name: 'Paree Dry Feel Sanitary Pads For Women Xl 40 Padsheavy Flow Championdouble Feathers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paree-dry-feel-sanitary-pads-for-women-xl-40-padsheavy-flow-championdouble-feathers/65df2d6c03a9ee23732cb607' },
{ name: 'Yogabar Dark Chocolate Peanut Butter Creamy Chocolatey Slow Roasted Non Gmo Premium Peanuts 400gm Jar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-dark-chocolate-peanut-butter-creamy-chocolatey-slow-roasted-non-gmo-premium-peanuts-400gm-jar/65df29e803a9ee8dd72cb602' },
{ name: 'Slovic Fabric Resistance Bands Anti Slip Mini Loop Resistance Bands For Calisthenics With Workout Guide', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/slovic-fabric-resistance-bands-anti-slip-mini-loop-resistance-bands-for-calisthenics-with-workout-guide/65df460a03a9eeb2122cb62a' },
{ name: 'Redgear Toad With Super Low Latency40ms In Ear Enc Mic Solution 40 Hrs Playback Fast Charge10 Mins 180 Mins Instant Connectblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redgear-toad-with-super-low-latency40ms-in-ear-enc-mic-solution-40-hrs-playback-fast-charge10-mins-180-mins-instant-connectblack/65df347503a9ee575a2cb611' },
{ name: 'Cello Wheel Garbage Waste Dustbin 240 Ltr Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-wheel-garbage-waste-dustbin-240-ltr-red/65df3f0203a9ee5d272cb620' },
{ name: 'Halonix 24w 5 Meter 300 Led Strip Light Without Driverblue Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-24w-5-meter-300-led-strip-light-without-driverblue-pack-of-1/65df266403a9ee59632cb5fe' },
{ name: 'Attro Frosty Sleek Compatible Square 900ml Water Bottlesquarebottlefreezer Safe Break Proofoccupies Less Space Food Grade Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/attro-frosty-sleek-compatible-square-900ml-water-bottlesquarebottlefreezer-safe-break-proofoccupies-less-space-food-grade-perfect/65df22df03a9ee1edf2cb5f9' },
{ name: 'Orient Electric Moonlite 6w Led Down Light Cool White Light Round Pack Of 2 Recessed Ceiling Led Light Suited', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-moonlite-6w-led-down-light-cool-white-light-round-pack-of-2-recessed-ceiling-led-light-suited/65df1f5b03a9eefb272cb5f4' },
{ name: 'Oneplus 24 In Full Hd Lcd Monitor Led Backlit Ips Panel With 3 Side Bezel Lessusb Type C Connectivity With 18w Charging8 Mm Slimflicker Free Monitor Response Time 5 Ms75 Hz Refresh Rateblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-24-in-full-hd-lcd-monitor-led-backlit-ips-panel-with-3-side-bezel-lessusb-type-c-connectivity-with-18w-charging8-mm-slimflicker-free-monitor-response-time-5-ms75-hz-refresh-rateblack/65df0a4103a9ee5c062cb5c9' },
{ name: 'Luusa Tft Rx 500 Plug N Play Trike Baby Tricycle With Parental Control Cushion Seat And Seat Belt For 12 Months To 48 Months Boysgirlscarrying Capacity Upto 30kgs Orange Proudly Made In India', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luusa-tft-rx-500-plug-n-play-trike-baby-tricycle-with-parental-control-cushion-seat-and-seat-belt-for-12-months-to-48-months-boysgirlscarrying-capacity-upto-30kgs-orange-proudly-made-in-india/65df1bd603a9ee490b2cb5ef' },
{ name: 'Asian Plastowares Super Seal Plastic Container Set Set Of 5 Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asian-plastowares-super-seal-plastic-container-set-set-of-5-transparent/65def8ab03a9eec17b2cb5b0' },
{ name: 'Boat Newly Launched Ultima Select Smart Watch With 201 Amoled Display Advanced Bt Calling Functional Crown Always On Display 100 Sports Mode Ip68 Hr Blood Oxygen Monitoringdeep Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-newly-launched-ultima-select-smart-watch-with-201-amoled-display-advanced-bt-calling-functional-crown-always-on-display-100-sports-mode-ip68-hr-blood-oxygen-monitoringdeep-blue/65defc3003a9ee2a8e2cb5b5' },
{ name: 'Nayasa Set Of 6 Hangers Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-set-of-6-hangers-yellow/65def52703a9ee3e272cb5ab' },
{ name: 'Vnj Accessories Premium Keyboard Cover Protector', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vnj-accessories-premium-keyboard-cover-protector/65deee1e03a9ee1f3a2cb5a1' },
{ name: 'Vedapure Fish Oil Omega 3 Fatty Acid Capsules', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vedapure-fish-oil-omega-3-fatty-acid-capsules/65deea9a03a9eeb4f72cb59c' },
{ name: 'Himalaya Herbals Strawberry Shine Lip Care 45g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-herbals-strawberry-shine-lip-care-45g/65ded1fc03a9ee1b4d2cb579' },
{ name: 'Amazon Brand Jam Honey Duck Plushsoft Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-jam-honey-duck-plushsoft-toy/65dece7703a9ee55d62cb574' },
{ name: 'Cello Endura Tuff Plastic Seat Stool Small Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-endura-tuff-plastic-seat-stool-small-red/65decaf303a9ee728e2cb56f' },
{ name: 'Wipro Safelite N10002 B22 05 Watt Led Night Lamp Warm Whitegolden Yellow Pack Of 3 Glass', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-safelite-n10002-b22-05-watt-led-night-lamp-warm-whitegolden-yellow-pack-of-3-glass/65deb5d903a9ee6ad22cb549' },
{ name: 'Real Frappe Milkshake French Vanilla Pouch 180 Ml Frappe Milkshake Belgian Chocolate Pouch 180 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/real-frappe-milkshake-french-vanilla-pouch-180-ml-frappe-milkshake-belgian-chocolate-pouch-180-ml/65deab4c03a9eeef602cb52a' },
{ name: 'Pink Root Tutti Frutti Face Wash 100 Ml Helps In Oil Balancing Oil Control Tan Removes Dark Spot Removal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pink-root-tutti-frutti-face-wash-100-ml-helps-in-oil-balancing-oil-control-tan-removes-dark-spot-removal/65dea7c803a9ee989d2cb51d' },
{ name: 'Milton Smart Egg Boiler 360 Watt Transparent And Silver Grey Boil Up To 7 Eggs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-smart-egg-boiler-360-watt-transparent-and-silver-grey-boil-up-to-7-eggs/65dea44403a9ee58982cb518' },
{ name: 'Boat Airdopes 200 Plus Tws Earbuds W 100 Hours Playback Quad Mics Enx Technology 13mm Drivers Beast Mode50ms Low Latency Asap Charge5 Mins60 Mins Iwp Tech Wbt V53 Ipx5carbon Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-airdopes-200-plus-tws-earbuds-w-100-hours-playback-quad-mics-enx-technology-13mm-drivers-beast-mode50ms-low-latency-asap-charge5-mins60-mins-iwp-tech-wbt-v53-ipx5carbon-black/65dea0bf03a9ee38612cb512' },
{ name: 'Glun Waterproof Unisex Kitchen Apron With Center Pocket And Adjustable Neck Belt With Metal Buckle Pack Of 1 Red Check', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/glun-waterproof-unisex-kitchen-apron-with-center-pocket-and-adjustable-neck-belt-with-metal-buckle-pack-of-1-red-check/65de8f28bc29749e6c215f68' },
{ name: 'Biotique Watercress Nourishing Conditioner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-watercress-nourishing-conditioner/65de99b5bc29747603215f77' },
{ name: 'Homebasics By Home Puff Collapsible Basket Bpa Free Washing Bowl For Fruits Vegetable Noodles Pasta Washing Bowl Multipurpose Strainer Colander Kitchen Sieve Diy Organizer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/homebasics-by-home-puff-collapsible-basket-bpa-free-washing-bowl-for-fruits-vegetable-noodles-pasta-washing-bowl-multipurpose-strainer-colander-kitchen-sieve-diy-organizer/65de7d93bc2974bb74215f4f' },
{ name: 'Sugar Free Dlite The Dark Collection Assorted Chocolate Gift Pack 120gm With Set Of 3 Premium Scented Candle Jars', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-free-dlite-the-dark-collection-assorted-chocolate-gift-pack-120gm-with-set-of-3-premium-scented-candle-jars/65de768abc2974f432215f45' },
{ name: 'Amazon Fresh Park Avenue Beer Shampoo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-fresh-park-avenue-beer-shampoo/65de7a0ebc2974f173215f4a' },
{ name: 'Boat Ultima Call Smart Watch With 183 Hd Displayadvanced Bt Callingdiy Watch Face Studio Coins700active Modes10 Days Battery Lifehrspo2 Monitorenergysleep Scoreactive Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-ultima-call-smart-watch-with-183-hd-displayadvanced-bt-callingdiy-watch-face-studio-coins700active-modes10-days-battery-lifehrspo2-monitorenergysleep-scoreactive-black/65de6bfdbc29747836215f36' },
{ name: 'Spotzero By Milton Sterling Flat Mop With Bucket Cleaning Mop Dry Compartments Sturdy Basket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/spotzero-by-milton-sterling-flat-mop-with-bucket-cleaning-mop-dry-compartments-sturdy-basket/65de64f5bc29742c54215f2c' },
{ name: 'Neelam Stainless Steel 8 22g Lazer Etching Buffet Plate 175 Cm Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-8-22g-lazer-etching-buffet-plate-175-cm-silver/65de6170bc29741f3c215f27' },
{ name: 'Van Heusen Men Briefs Cotton Spandex Anti Bacterial Colour Fresh Moisture Wicking', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/van-heusen-men-briefs-cotton-spandex-anti-bacterial-colour-fresh-moisture-wicking/65de6f82bc29743a5c215f3b' },
{ name: 'Miyuki 2in 1 Long Lasting Transfer Proof Matte Gloss Liquid And Lipstick Indian Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/miyuki-2in-1-long-lasting-transfer-proof-matte-gloss-liquid-and-lipstick-indian-red/65de5a68bc297497a5215f1d' },
{ name: 'Livon Shake Spray Serum', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/livon-shake-spray-serum/65de535fbc297424df215f13' },
{ name: 'Anchor By Panasonic Grestar Personal Fan Grey 225 Mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anchor-by-panasonic-grestar-personal-fan-grey-225-mm/65de7306bc2974fffb215f40' },
{ name: 'Unibic Cake Choco Fudge 220 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-cake-choco-fudge-220-gm/65de6879bc2974314a215f31' },
{ name: 'Natures Essence Face Cream For Daily Useanti Ageing Face Cream For Womenage Renewal Cream With 03 Pro Retinol45gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/natures-essence-face-cream-for-daily-useanti-ageing-face-cream-for-womenage-renewal-cream-with-03-pro-retinol45gm/65de5decbc297401ba215f22' },
{ name: 'Dovemencarets 2in1shmpcond 340ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dovemencarets-2in1shmpcond-340ml/65de4fdbbc2974d264215f0e' },
{ name: 'For Hdfcsbi Bank Credit Card Redmi 13c 5g Startrail Green 4gb Ram 128gb Storage Mediatek Dimensity 6100 5g 90hz Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-hdfcsbi-bank-credit-card-redmi-13c-5g-startrail-green-4gb-ram-128gb-storage-mediatek-dimensity-6100-5g-90hz-display/65de56e4bc2974e9de215f18' },
{ name: 'Amazon Basics Multi Angle Foldable Desktop Tabletmobile Stand Holder For Iphone Samsung Oneplus Xiaomi Smartphones Portable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-multi-angle-foldable-desktop-tabletmobile-stand-holder-for-iphone-samsung-oneplus-xiaomi-smartphones-portable/65de4c57bc29745bf5215f09' },
{ name: 'Boat Newly Launched Ultima Select Smart Watch With 201 Amoled Display Advanced Bt Calling Functional Crown Always On Display 100 Sports Mode Ip68 Hr Blood Oxygen Monitoringactive Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-newly-launched-ultima-select-smart-watch-with-201-amoled-display-advanced-bt-calling-functional-crown-always-on-display-100-sports-mode-ip68-hr-blood-oxygen-monitoringactive-black/65de48d2bc29747465215f04' },
{ name: 'Philips Starfit 7 Watt Concealed Jb Led Downlighter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-starfit-7-watt-concealed-jb-led-downlighter/65de3abff383c34dce350f54' },
{ name: 'Wooum Sling Cross Body Travel Office Business Messenger One Side Shoulder Pouch Bag Money Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wooum-sling-cross-body-travel-office-business-messenger-one-side-shoulder-pouch-bag-money-bag/65de3733f383c3f09b350f4f' },
{ name: 'Ergonomic Mouse Lekvey Vertical Wireless Mouse Rechargeable 24ghz Optical Vertical Mice 3 Adjustable Dpi 80012001600 Levels 6 Buttons', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ergonomic-mouse-lekvey-vertical-wireless-mouse-rechargeable-24ghz-optical-vertical-mice-3-adjustable-dpi-80012001600-levels-6-buttons/65de33aff383c3e20b350f4a' },
{ name: 'E Cosmos Keychain Led Light 2 Hours Battery Life With Bottle Opener Magnetic Base And Folding Bracket Mini Cob 1000 Lumens Rechargeable Emergency Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-keychain-led-light-2-hours-battery-life-with-bottle-opener-magnetic-base-and-folding-bracket-mini-cob-1000-lumens-rechargeable-emergency-light/65de302bf383c3676f350f45' },
{ name: 'Tommy Hilfiger Spring Field 55cm Plastic Hard Luggage Unisex Trolley Bag Blackgrey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tommy-hilfiger-spring-field-55cm-plastic-hard-luggage-unisex-trolley-bag-blackgrey/65de2ca6f383c332ab350f40' },
{ name: 'Rixtec Face Cleansing Sponge Puff Makeup Washing Pad Deep Cleansing Exfoliating Facial Sponge Assorted Color Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rixtec-face-cleansing-sponge-puff-makeup-washing-pad-deep-cleansing-exfoliating-facial-sponge-assorted-color-pack-of-4/65de3e43f383c3c5a4350f59' },
{ name: 'Myglamm Presents Beauty Hi Shine Lipstick Glossy Finish Cocoa Love 4gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/myglamm-presents-beauty-hi-shine-lipstick-glossy-finish-cocoa-love-4gm/65de454ebc2974623a215eff' },
{ name: 'Lifelong Water Heater For Home Water Geyser 25 Ltr With 3 Star Bee Rating 2000w Electric Geyser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-water-heater-for-home-water-geyser-25-ltr-with-3-star-bee-rating-2000w-electric-geyser/65de41c9aeba0ca0ba3fc0d7' },
{ name: 'Amfin Pack Of 2 24 Inch Baby Boy Cubic Foil Balloons Baby Boy Balloons', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amfin-pack-of-2-24-inch-baby-boy-cubic-foil-balloons-baby-boy-balloons/65de2922f383c31442350f3b' },
{ name: 'Wondercut 12v Li Ion Cordless Drill With Reversible Function Wc Cd12v1006b Cordless Drill', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wondercut-12v-li-ion-cordless-drill-with-reversible-function-wc-cd12v1006b-cordless-drill/65de259ef383c33b7e350f36' },
{ name: 'Unibic Cookies Choco Nut Cookies 500g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-cookies-choco-nut-cookies-500g/65de221af383c34355350f31' },
{ name: 'Icici Bank Credit Card Samsung 108 Cm 43 Inches Crystal 4k Neo Series Ultra Hd Smart Led Tv Ua43aue65akxxl Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/icici-bank-credit-card-samsung-108-cm-43-inches-crystal-4k-neo-series-ultra-hd-smart-led-tv-ua43aue65akxxl-black/65de14081a754b39e9f90cd0' },
{ name: 'Size L Vector X Oks 140 Kids Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-vector-x-oks-140-kids-shorts/65de178df383c31fdf350f22' },
{ name: 'Portronics Toad 26 Wired Optical Mouse With 1500 Dpi Optical Orientation Click Wheel 135m Cable Lengthblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-toad-26-wired-optical-mouse-with-1500-dpi-optical-orientation-click-wheel-135m-cable-lengthblack/65de10841a754b7023f90ccb' },
{ name: 'Wondercut Wc Ed 10 B Cmb Drill Machine 10mm With Free 13pcs Hss Drill Bits 1pc Masonary Bit Combo Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wondercut-wc-ed-10-b-cmb-drill-machine-10mm-with-free-13pcs-hss-drill-bits-1pc-masonary-bit-combo-blue/65de1e95f383c31010350f2c' },
{ name: 'For Idfc First Bank Credit Card Realme Narzo 60 5g Mars Orange8gb128gb 90hz Super Amoled Display Ultra Premium Vegan Leather Design With 33w Supervooc Charger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-idfc-first-bank-credit-card-realme-narzo-60-5g-mars-orange8gb128gb-90hz-super-amoled-display-ultra-premium-vegan-leather-design-with-33w-supervooc-charger/65de0d001a754b37a6f90cc6' },
{ name: 'Tagg Liberty Buds Truly Wireless In Ear Earbuds With Punchy Bass And Fast Charge Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tagg-liberty-buds-truly-wireless-in-ear-earbuds-with-punchy-bass-and-fast-charge-black/65de097b1a754b4cbaf90cc1' },
{ name: 'Solara Automatic Rice Cooker Automatic Electric Cooker With Food Steamer Electric Rice Cooker And Grain Cooker 500 Watts Rice Cooker 15 Litre With Steam Rinse Basket Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/solara-automatic-rice-cooker-automatic-electric-cooker-with-food-steamer-electric-rice-cooker-and-grain-cooker-500-watts-rice-cooker-15-litre-with-steam-rinse-basket-red/65de05f71a754b4f22f90cbc' },
{ name: 'Amazon Brand Symbol Mens Cotton Rich Stretch Jeans Available In Relaxedskinnyslimcarrot Fits', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-cotton-rich-stretch-jeans-available-in-relaxedskinnyslimcarrot-fits/65de02731a754b1739f90cb7' },
{ name: 'Razer Mouse Bungee V3 Chroma Mouse Cable Holder With Chroma Rgb Underglow Lighting Black Rc21 01520100 R3m1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/razer-mouse-bungee-v3-chroma-mouse-cable-holder-with-chroma-rgb-underglow-lighting-black-rc21-01520100-r3m1/65ddfeef1a754b630af90cb2' },
{ name: 'Bajaj 2200 Tmss Oven Toaster Griller Otg With Motorised Rotisserie And Stainless Steel Body Black Silver 22 Liter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-2200-tmss-oven-toaster-griller-otg-with-motorised-rotisserie-and-stainless-steel-body-black-silver-22-liter/65ddf7e61a754b46ecf90ca8' },
{ name: 'French Connection Womens Analog Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-womens-analog-watch/65ddfb6a1a754b5beaf90cad' },
{ name: 'Maate Lip Butter Packed With Precious Butters 100 Natural 8 Gm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maate-lip-butter-packed-with-precious-butters-100-natural-8-gm-pack-of-2/65dde9d40299de08fe5b71d4' },
{ name: 'Toyzone Bird Sanctuary Coloring Mat Reusable And Washable Colouring Mat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toyzone-bird-sanctuary-coloring-mat-reusable-and-washable-colouring-mat/65dde6500299de12a75b71cf' },
{ name: 'Simply Home 160 Plastic Ice Tray Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/simply-home-160-plastic-ice-tray-set/65ddf4621a754bfd87f90ca3' },
{ name: 'Cello Tokyo Plastic Storage Shelf Ivory Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-tokyo-plastic-storage-shelf-ivory-brown/65dddf470299de3b125b71c5' },
{ name: 'Gizga Essentials Foldable Laptop And Study Table', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-foldable-laptop-and-study-table/65dddbc20299dea9e95b71c0' },
{ name: 'Wiggleboo Chicken Pumpkin Hamster Treats Irresistible Flavor Fusion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wiggleboo-chicken-pumpkin-hamster-treats-irresistible-flavor-fusion/65dde2cb0299de58da5b71ca' },
{ name: 'Amazon Brand House Shields Men Hooded Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-house-shields-men-hooded-sweatshirt/65ddf0dd1a754bda9df90c99' },
{ name: 'Amazon Brand Arthur Harvey Mens Slim Elasticated Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-arthur-harvey-mens-slim-elasticated-casual-pants/65dded580299dec9885b71d9' },
{ name: 'Prestige Ss Water Bottle Pswbc 12 750 Ml Stainless Steel Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prestige-ss-water-bottle-pswbc-12-750-ml-stainless-steel-blue/65ddd83e39f73f03bec112e6' },
{ name: 'Murphy 30w B22 Led Globe Cool White Bulb Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/murphy-30w-b22-led-globe-cool-white-bulb-pack-of-2/65ddd4b77c8daf827fcaab51' },
{ name: 'Bajaj Veloce Pedestal 400 Mm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-veloce-pedestal-400-mm-white/65ddd1337c8daf8ea0caab4c' },
{ name: 'Leotude Half Sleeve Oversized Cottonblend Tshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/leotude-half-sleeve-oversized-cottonblend-tshirt/65ddcdae7c8daf099dcaab47' },
{ name: 'Wondercut Wc Cdk1007b Cordless Screw Driver With Soft Gripdrill12v18 Torque Setting 2 Modes Variable Speed Forward Reverse27 Accessories2 Battery Adapter Home Diyprofessional Blueblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wondercut-wc-cdk1007b-cordless-screw-driver-with-soft-gripdrill12v18-torque-setting-2-modes-variable-speed-forward-reverse27-accessories2-battery-adapter-home-diyprofessional-blueblack/65ddbc197c8daf5db1caab1f' },
{ name: 'Saf Sanfjm31964 Radhe Krishna Uv Textured Self Adeshive Painting 18 Inch X 12 Inch Set Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saf-sanfjm31964-radhe-krishna-uv-textured-self-adeshive-painting-18-inch-x-12-inch-set-of-3/65ddb8947c8daf813ecaab1a' },
{ name: 'Robocraze 170 Tie Point Mini Self Adhesive Solderless Breadboard', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/robocraze-170-tie-point-mini-self-adhesive-solderless-breadboard/65ddb5107c8daf78accaab15' },
{ name: 'Kuber Industries Pvc 45 Mm Artificial Grass Door Mat 24x 152 Green Grass0107standard Large Rectangle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-pvc-45-mm-artificial-grass-door-mat-24x-152-green-grass0107standard-large-rectangle/65ddb18c7c8daf6b65caab10' },
{ name: 'Suzec Compact Stainless Steel Airtight Lunch Box Set 4 Pcs 3 Leakproof Containers And 1 Bottle 1 Milliliter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/suzec-compact-stainless-steel-airtight-lunch-box-set-4-pcs-3-leakproof-containers-and-1-bottle-1-milliliter/65ddaa837c8daf80a9caab06' },
{ name: 'Hawkins Kitchen Gift Pack 2023 23sgp 3 Pieces Set Of 15 Litre 2 Litre And 25 Litre Triply Stainless Steel Patila Tope Bhagona Tapeli Saucepans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hawkins-kitchen-gift-pack-2023-23sgp-3-pieces-set-of-15-litre-2-litre-and-25-litre-triply-stainless-steel-patila-tope-bhagona-tapeli-saucepans/65ddc6a67c8daf5979caab3d' },
{ name: 'Gizga Essentials Laptop Bag Sleeve Case Cover Pouch For 141 Inch Laptopmacbook Officecollege Laptop Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-laptop-bag-sleeve-case-cover-pouch-for-141-inch-laptopmacbook-officecollege-laptop-bag/65ddca2a7c8dafe8c8caab42' },
{ name: 'All Bank Cards Vida Powered By Hero V1 Pro Electric Scooter 110 Km Range In One Charge Top Speed 80 Kmph Matte Abrax Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/all-bank-cards-vida-powered-by-hero-v1-pro-electric-scooter-110-km-range-in-one-charge-top-speed-80-kmph-matte-abrax-orange/65ddbf9d7c8dafc331caab28' },
{ name: 'Size S Macroman M Series Mens Regular Track Pant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-macroman-m-series-mens-regular-track-pant/65ddae077c8daf1fd9caab0b' },
{ name: 'Tucasa Dw 268 Led Diya Pack Of 6 Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tucasa-dw-268-led-diya-pack-of-6-brown/65dda6ff7c8daf05c6caab01' },
{ name: 'Stanley 70 936e High Grade Steel Combination Spanner With Matte Finish 6mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stanley-70-936e-high-grade-steel-combination-spanner-with-matte-finish-6mm/65dda37b7c8daf7423caaaee' },
{ name: 'Fruit Of The Loom Mens Cotton Trunks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fruit-of-the-loom-mens-cotton-trunks/65dd7ccb7c8daf59b6caaab7' },
{ name: 'Lakme Bi Phasic Remover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-bi-phasic-remover/65dd804f7c8daf179ecaaabc' },
{ name: 'Wesley Milestone 156 Inch 25 L Casual Waterproof Laptop Backpackoffice Bagschool Bagcollege Bagbusiness Bagunisex Travel Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wesley-milestone-156-inch-25-l-casual-waterproof-laptop-backpackoffice-bagschool-bagcollege-bagbusiness-bagunisex-travel-backpack/65dd79477c8daf9d42caaab2' },
{ name: 'Rellon Industries Wall Mounted Shelf For Living Room Stylish Mdf Floating Shelf Freestanding', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rellon-industries-wall-mounted-shelf-for-living-room-stylish-mdf-floating-shelf-freestanding/65dd59a07c8dafb5eecaaa6e' },
{ name: 'Clovia Womens Cotton Pretty Floral Button Down Top Shorts Set In Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-pretty-floral-button-down-top-shorts-set-in-blue/65dd561c7c8daf785dcaaa69' },
{ name: 'Amscope Etxwj04 Iqcrew 100x 1200x Led Kids Beginner Microscope Toy Set Slides Preparation Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amscope-etxwj04-iqcrew-100x-1200x-led-kids-beginner-microscope-toy-set-slides-preparation-kit/65dd52987c8daf5c72caaa64' },
{ name: 'Agaro Hs1119 3 In1 Hair Styler Straightner Crimper Curler For Women Variable Style Settings Keratin Infused Ceramic Coated Plate Colour Black Rose Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-hs1119-3-in1-hair-styler-straightner-crimper-curler-for-women-variable-style-settings-keratin-infused-ceramic-coated-plate-colour-black-rose-gold/65dd4f137c8daf0311caaa5f' },
{ name: 'Unibic Double Chocochip Cookiespack Of 3225gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-double-chocochip-cookiespack-of-3225gm/65dd480b7c8daf3d0ccaaa4b' },
{ name: 'Samsung Galaxy M04 Dark Blue 4gb Ram 128gb Storage Upto 8gb Ram With Ram Plus Mediatek Helio P35 Octa Core Processor 5000 Mah Battery 13mp Dual Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m04-dark-blue-4gb-ram-128gb-storage-upto-8gb-ram-with-ram-plus-mediatek-helio-p35-octa-core-processor-5000-mah-battery-13mp-dual-camera/65dd2be87c8daf4b69caaa22' },
{ name: 'Apis Fruitblast Mixed Fruit Jam 1kg Jar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apis-fruitblast-mixed-fruit-jam-1kg-jar/65dd28647c8daf04aacaaa1d' },
{ name: 'Status Multipurpose Pooja Prayer Mat 18x18 Inch Pooja Assan Mehroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-multipurpose-pooja-prayer-mat-18x18-inch-pooja-assan-mehroon/65dd24e07c8daf69c3caaa18' },
{ name: 'Impulse Diggy 30l Laptop Backpackoffice Bagschool Bagcollege Bagbusiness Bagtravel Backpack Water Resistant Fits Up To 16 Inch Laptop With 1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/impulse-diggy-30l-laptop-backpackoffice-bagschool-bagcollege-bagbusiness-bagtravel-backpack-water-resistant-fits-up-to-16-inch-laptop-with-1-year-warranty/65dd1dd87c8daf6230caaa0e' },
{ name: 'Amardeep And Co Baby Tricycle Brendon Blue With Cushion Seat 2 5 Years With Large Basket Arm Rest And Safety Belt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amardeep-and-co-baby-tricycle-brendon-blue-with-cushion-seat-2-5-years-with-large-basket-arm-rest-and-safety-belt/65dd1a537c8dafe6d8caaa09' },
{ name: 'Usha Onio Phi Beta 1200mm Bldc 5 Star Energy Efflicient Dust Oil Resistant Ceiling Fan With Remote Graphite Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/usha-onio-phi-beta-1200mm-bldc-5-star-energy-efflicient-dust-oil-resistant-ceiling-fan-with-remote-graphite-grey/65dd0fc67c8daf5a00caa9fa' },
{ name: 'Portronics Konnect L 12m Fast Charging 3a 8 Pin Usb Cable With Charge Sync Function', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-konnect-l-12m-fast-charging-3a-8-pin-usb-cable-with-charge-sync-function/65dd0c427c8dafbbcbcaa9f5' },
{ name: 'Samsung Galaxy S23 5g Cream 8gb 256gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-s23-5g-cream-8gb-256gb-storage/65dd08be7c8daf4877caa9f0' },
{ name: 'Fastrack Limitless Fs1 Smartwatchbiggest 201 Ultravu Display950 Nits Brightnesssinglesync Bt Callingnitro Fast Charging110 Sports Modes200 Watchfacesupto 7 Day Battery', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-limitless-fs1-smartwatchbiggest-201-ultravu-display950-nits-brightnesssinglesync-bt-callingnitro-fast-charging110-sports-modes200-watchfacesupto-7-day-battery/65dd05397c8dafdb23caa9ea' },
{ name: 'Nivea Oil Control Face Wash 100ml Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-oil-control-face-wash-100ml-pack-of-3/65dd16cf7c8daf4694caaa04' },
{ name: 'Size 6 Uk Liberty Womens Lpmxt 800 Clog', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-uk-liberty-womens-lpmxt-800-clog/65dd215c7c8daf5691caaa13' },
{ name: 'Nivea Fresh Active Deodorant Roll On', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-fresh-active-deodorant-roll-on/65dd134b7c8daffad2caa9ff' },
{ name: 'Puma Women Trackpant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puma-women-trackpant/65dd01b396f9942bf841192e' },
{ name: 'Lenovo Ideapad Gaming 3 Amd Ryzen 5 5500h 156 3962cm Fhd Ips 300nits 144hz Gaming Laptop 8gb512gb Ssdwindows 11nvidia Rtx 2050 4gbalexa3 Month Game Passonyx Grey232kg 82k20289in', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-ideapad-gaming-3-amd-ryzen-5-5500h-156-3962cm-fhd-ips-300nits-144hz-gaming-laptop-8gb512gb-ssdwindows-11nvidia-rtx-2050-4gbalexa3-month-game-passonyx-grey232kg-82k20289in/65dcfaaa96f994646e411924' },
{ name: 'Amazon Brand Solimo Four Tier Multipurpose Plastic Rack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-four-tier-multipurpose-plastic-rack/65dcfe2e96f9945770411929' },
{ name: 'Size 5 Years 6 Years T2f Unisex Child Cotton Hooded Neck Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-5-years-6-years-t2f-unisex-child-cotton-hooded-neck-sweatshirt/65dcd3fb96f99425f24118ed' },
{ name: 'Size L Chkokko Men Winter Sports Running Zipper Stylish Jacket Navy L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-chkokko-men-winter-sports-running-zipper-stylish-jacket-navy-l/65dcd77f96f99426f54118f2' },
{ name: 'Size M Carlton London Women Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-carlton-london-women-shirt/65dcd07796f9940d3d4118e6' },
{ name: 'Size Xl Vector X Omt 249 Mens Sleeveless Regular Fit Tank Top', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-xl-vector-x-omt-249-mens-sleeveless-regular-fit-tank-top/65dcccf296f99439584118d7' },
{ name: 'Greenfinity Dried Amla Candy 250gpack Of 2 Organic Dry Indian Gooseberry Fruit Mouth Freshener Without Added Sugar Or Preservatives', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/greenfinity-dried-amla-candy-250gpack-of-2-organic-dry-indian-gooseberry-fruit-mouth-freshener-without-added-sugar-or-preservatives/65dcbee196f994135d4118c3' },
{ name: 'Tac The Ayurveda Co Luxury Eau De Parfum Gift Set 2x15 Ml Unisex Spicy Truth Forbidden Romance Floral Fruity Long Lasting Perfume Paraben Alcohol Free For All Skin Types', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tac-the-ayurveda-co-luxury-eau-de-parfum-gift-set-2x15-ml-unisex-spicy-truth-forbidden-romance-floral-fruity-long-lasting-perfume-paraben-alcohol-free-for-all-skin-types/65dcbb5d96f99448b24118be' },
{ name: 'Pink Root Aloevera Soap 100gm Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pink-root-aloevera-soap-100gm-pack-of-3/65dcb7d996f9946da44118b9' },
{ name: 'E Cosmos 4 Section Container For Vegetablesdryfruitsspicesgrocerypicklesand Multipurpose 4 In 1 Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-4-section-container-for-vegetablesdryfruitsspicesgrocerypicklesand-multipurpose-4-in-1-box/65dcb45496f9946e2c4118b4' },
{ name: 'Spartan Portable Foldaway Water Bucket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/spartan-portable-foldaway-water-bucket/65dcc5ea96f994865e4118cd' },
{ name: 'Boroplus Antiseptic And Moisturising Bathing Soap With Aloe Vera Neem And Tulsi 999 Germ And Virus Protection For Smooth Soft Nourished Skin 125g Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-antiseptic-and-moisturising-bathing-soap-with-aloe-vera-neem-and-tulsi-999-germ-and-virus-protection-for-smooth-soft-nourished-skin-125g-pack-of-6/65dcc26596f99410774118c8' },
{ name: 'Size L Clovia Womens Cotton Animal Print Camisole', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-clovia-womens-cotton-animal-print-camisole/65dcc96e96f9941df04118d2' },
{ name: 'Wow Skin Science Beach Walk Aqua Eau De Parfum Premium Valentines Day Gift For Women Long Lasting Luxury Perfume For Her 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-beach-walk-aqua-eau-de-parfum-premium-valentines-day-gift-for-women-long-lasting-luxury-perfume-for-her-100ml/65dcb0cfaaaa428b20877337' },
{ name: 'Del Monte Southwest Chipotle Dressing 275g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/del-monte-southwest-chipotle-dressing-275g/65dcad49904bea410fbc92bb' },
{ name: 'Sample 99 Skin Elements Intimate Wash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sample-99-skin-elements-intimate-wash/65dca9c5904beacfc2bc92b6' },
{ name: 'Reynolds D Fine Ballpen Blue Pack Of 50 Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-d-fine-ballpen-blue-pack-of-50-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/65dca640904bea9cf7bc92b1' },
{ name: 'Gizga Essentials Extended Gaming Mouse Pad Laptop Desk Mat Computer Mouse Pad With Smooth Mouse Control Mercerized Surface Antifray Stitched Embroidery Edges Anti Slip Rubber Base 29 24cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-extended-gaming-mouse-pad-laptop-desk-mat-computer-mouse-pad-with-smooth-mouse-control-mercerized-surface-antifray-stitched-embroidery-edges-anti-slip-rubber-base-29-24cm/65dc9bb3904bea23f0bc92a0' },
{ name: 'Cosco Premier Leather Basketball Size 3 Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cosco-premier-leather-basketball-size-3-multicolour/65dc94ab904bea2b87bc9295' },
{ name: 'Larah By Borosil Stargazer Fluted Series Opalware Dinner Set 28 Pieces For Family Of 6 Microwave Dishwasher Safe Bone Ash Free Crockery Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/larah-by-borosil-stargazer-fluted-series-opalware-dinner-set-28-pieces-for-family-of-6-microwave-dishwasher-safe-bone-ash-free-crockery-set/65dc9124f16553a3270c3b90' },
{ name: 'Blackdecker Kr5010v 550w 10mm 2800 Rpm Corded Variable Speed Hammer Drill Driver Machine For Home Diy Use', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blackdecker-kr5010v-550w-10mm-2800-rpm-corded-variable-speed-hammer-drill-driver-machine-for-home-diy-use/65dc8da0f165532e410c3b8b' },
{ name: 'Samsung Galaxy A13 Light Blue 4gb Ram 128gb Storage Without Offers Sm A135flbhins', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-a13-light-blue-4gb-ram-128gb-storage-without-offers-sm-a135flbhins/65dc8a1cf16553ab4e0c3b86' },
{ name: 'He Mens Innovator Perfume Body Spray 150 Ml Pack Of 2 150 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/he-mens-innovator-perfume-body-spray-150-ml-pack-of-2-150-ml/65dc8313f1655320a50c3b7c' },
{ name: 'Gooyo Gy3716 Mini Portable Piano Keyboard Musical Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gooyo-gy3716-mini-portable-piano-keyboard-musical-toy/65dc982f904beafa3fbc929b' },
{ name: 'My Fav Water Resistant Polyester Mini Backpack Small Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/my-fav-water-resistant-polyester-mini-backpack-small-size-/65dca2bc904bea174abc92ac' },
{ name: 'Paree Dry Feel Xxl Sanitary Pad Pack Of 40', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paree-dry-feel-xxl-sanitary-pad-pack-of-40/65dc9f38904beac252bc92a5' },
{ name: 'Bajaj 20w Cool Day Light Led Tubelight Pack Of 2 830412 Pk2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-20w-cool-day-light-led-tubelight-pack-of-2-830412-pk2/65dc8697f165539e770c3b81' },
{ name: 'Usha Onio Upsilonplus 1200mm Bldc 5 Star Energy Efflicient Dust Oil Resistant Ceiling Fan With Remote Terra Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/usha-onio-upsilonplus-1200mm-bldc-5-star-energy-efflicient-dust-oil-resistant-ceiling-fan-with-remote-terra-brown/65dc7c0af1655366790c3b72' },
{ name: 'Gizga Essentials Portable Tabletop Mobile And Tablet Standipad Smartphone Kindle E Reader Foldable Adjustable Angle Anti Slip Pads Rubber Protection Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-portable-tabletop-mobile-and-tablet-standipad-smartphone-kindle-e-reader-foldable-adjustable-angle-anti-slip-pads-rubber-protection-perfect/65dc7f8ff165537db90c3b77' },
{ name: 'Cruise 15 Ton 5 Star Inverter Split Ac With 7 Stage Air Filtration 100 Copper Convertible 4 In 1 Pm 25 Filter 4 Way Swing Anti Rust Technology 2024 Model Cwcvbk Vq3d185 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cruise-15-ton-5-star-inverter-split-ac-with-7-stage-air-filtration-100-copper-convertible-4-in-1-pm-25-filter-4-way-swing-anti-rust-technology-2024-model-cwcvbk-vq3d185-white/65dc717df165530cef0c3b63' },
{ name: 'Wipro Northwest 6a Four Way Extension Board White Nwe0200', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-northwest-6a-four-way-extension-board-white-nwe0200/65dc7886f16553989e0c3b6d' },
{ name: 'Go Desi Coconut Ladoo 200 Grams Laddu Laddoo Indian Sweets Mithai Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-coconut-ladoo-200-grams-laddu-laddoo-indian-sweets-mithai-gift/65dc6df9f165533ae20c3b5e' },
{ name: 'Engage Spirit For Her Deodorant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-spirit-for-her-deodorant/65dc6a75f165535ac40c3b59' },
{ name: 'Godrej Aer Spray Room Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-aer-spray-room-freshener/65dc66f1f1655337940c3b54' },
{ name: 'Sandisk Ultra Luxe Usb 31 Flash Drive 64gb Upto 150mbs All Metal Metallic Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sandisk-ultra-luxe-usb-31-flash-drive-64gb-upto-150mbs-all-metal-metallic-silver/65dc5fe8f1655399f80c3b4a' },
{ name: 'Zebronics Cpu Cooling Fan 775115011551156 Socket 90mm Cpu Fan Thermal Paste Included', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-cpu-cooling-fan-775115011551156-socket-90mm-cpu-fan-thermal-paste-included/65dc5c64f16553336b0c3b45' },
{ name: 'Havells Hs4101 Ceramic Plates Fast Heat Up Hair Straightener Straightens Curls Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-hs4101-ceramic-plates-fast-heat-up-hair-straightener-straightens-curls-suitable/65dc58dff165536e590c3b3f' },
{ name: 'Dove Energising Body Wash With Energising Lemon Scent And Nourishing Vitamin C 100 Gentle Cleansers Paraben Freesulphate Free Cleansers 100 Plant Based Moisturisers 800ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-energising-body-wash-with-energising-lemon-scent-and-nourishing-vitamin-c-100-gentle-cleansers-paraben-freesulphate-free-cleansers-100-plant-based-moisturisers-800ml/65dc555bf1655399f90c3b3a' },
{ name: 'Hindware Atlantic Xceed 5l 3kw Instant Water Heater With Copper Heating Element And High Grade Stainless Steel Tank Wall Mounting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-atlantic-xceed-5l-3kw-instant-water-heater-with-copper-heating-element-and-high-grade-stainless-steel-tank-wall-mounting/65dc636cf1655379e50c3b4f' },
{ name: 'Lifelong Fabric Resistance Band Loop Hip Band For Women Men For Hip Legs Stretching Toning Workoutmini Loop Bands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-fabric-resistance-band-loop-hip-band-for-women-men-for-hip-legs-stretching-toning-workoutmini-loop-bands/65dc51d7f165533cb30c3b35' },
{ name: 'Bombay Shaving Company Activated Charcoal Peel Off Mask With 5x Detoxifying Power Fights Pollution And De Tans Skin For Men And Women 100g Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bombay-shaving-company-activated-charcoal-peel-off-mask-with-5x-detoxifying-power-fights-pollution-and-de-tans-skin-for-men-and-women-100g-pack-of-1/65dc474af16553fc610c3b26' },
{ name: 'Lg Electronics Spk8 S 20 Channel Sound Bar Wireless Rear Speaker Kit Compatibel With Sn10yg Sn9yg Sn8yg Gx Sn7y Sn6y Sn5y Sp9ya Sp8ya G1 Spd7y Sp7y Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-electronics-spk8-s-20-channel-sound-bar-wireless-rear-speaker-kit-compatibel-with-sn10yg-sn9yg-sn8yg-gx-sn7y-sn6y-sn5y-sp9ya-sp8ya-g1-spd7y-sp7y-black/65dc3cbdf16553cf110c3b17' },
{ name: 'Axe Signature Intense Long Lasting No Gas Body Deodorant For Men 200 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/axe-signature-intense-long-lasting-no-gas-body-deodorant-for-men-200-ml/65dc3939f16553ec1e0c3b12' },
{ name: 'Eveready 12 Watt B22d Base Inverter Emergency Led Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-12-watt-b22d-base-inverter-emergency-led-bulb/65dc4041f165537f4e0c3b1c' },
{ name: 'Philips 9 Watts B22 Led Cool Day White Led Bulb Pack Of 10 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-b22-led-cool-day-white-led-bulb-pack-of-10-ace-saver/65dc35b5f1655302be0c3b0d' },
{ name: 'Hp Wired On Ear Headphones With Mic With 35 Mm Drivers In Built Noise Cancelling Foldable And Adjustable For Laptoppcofficehome 1 Year Warranty B4b09pa', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-wired-on-ear-headphones-with-mic-with-35-mm-drivers-in-built-noise-cancelling-foldable-and-adjustable-for-laptoppcofficehome-1-year-warranty-b4b09pa/65dc43c6f16553a0f30c3b21' },
{ name: 'Eveready 5w Led Batten Highly Efficient 100lmw Fire Retardant Pc Body Pack Of 2 Lasts Upto 25000 Hours 4kv Surge Protection Bis Approved Made In India White Cool Day Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-5w-led-batten-highly-efficient-100lmw-fire-retardant-pc-body-pack-of-2-lasts-upto-25000-hours-4kv-surge-protection-bis-approved-made-in-india-white-cool-day-light/65dc4e53f1655372ca0c3b30' },
{ name: 'Lexton 20 Piece Photo Clips String Light Battery Powered Battery Not Included', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lexton-20-piece-photo-clips-string-light-battery-powered-battery-not-included/65dc4acef1655366310c3b2b' },
{ name: 'Hp Z3700 Dual Silver Mouse24 Ghz Wireless Connection1600 Dpi3 Buttonssilent Clicks Multi Device Cross Operating Systems3 Years Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-z3700-dual-silver-mouse24-ghz-wireless-connection1600-dpi3-buttonssilent-clicks-multi-device-cross-operating-systems3-years-warranty/65dc3230f16553b5360c3b08' },
{ name: 'Spotzero By Milton Dry Mop Cotton Aqua Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/spotzero-by-milton-dry-mop-cotton-aqua-green/65dc2eacf16553f7580c3b03' },
{ name: 'Dove Body Love Supple Bounce Body Butter Paraben Free 48hrs Moisturisation With Plant Based Moisturiser Supple And Healthy Skin 145g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-body-love-supple-bounce-body-butter-paraben-free-48hrs-moisturisation-with-plant-based-moisturiser-supple-and-healthy-skin-145g/65dc2b27f1655345930c3afe' },
{ name: 'Fiama Happy Naturals Celebrations Body Wash Shower Gel Perfume Mist Gift Pack Yuzu And Bergamot Perfume Mist 120ml And Bodywash 250ml With Long Lasting Floral Fragrance Gift Set Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-happy-naturals-celebrations-body-wash-shower-gel-perfume-mist-gift-pack-yuzu-and-bergamot-perfume-mist-120ml-and-bodywash-250ml-with-long-lasting-floral-fragrance-gift-set-gift/65dc27a3f1655305670c3af9' },
{ name: 'Wecool Unbreakable Soft 5a Type C Cable Supports Fast Charging Quick Charging Dash Charging And Warp Charging 1 Mtr Charging Cable Compatible With Oneplus Oppo And Vivo Smartphones', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wecool-unbreakable-soft-5a-type-c-cable-supports-fast-charging-quick-charging-dash-charging-and-warp-charging-1-mtr-charging-cable-compatible-with-oneplus-oppo-and-vivo-smartphones/65dc160ef16553717b0c3adf' },
{ name: 'Kamiliant By American Tourister Harrier 2 Pc Set 55 Cms 68 Cms Small Medium Polypropylene Pp Hard Sided 4 Wheels Spinner Luggage Setsuitcase Settrolley Bag Set Iron Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kamiliant-by-american-tourister-harrier-2-pc-set-55-cms-68-cms-small-medium-polypropylene-pp-hard-sided-4-wheels-spinner-luggage-setsuitcase-settrolley-bag-set-iron-grey/65dc1289f1655312b60c3ada' },
{ name: 'Dove Mencare Extra Fresh Dry Spray Antiperspirant Aerosol Deodorant Up To 48 Hrs Protection From Sweat Odour Soothes Moisturises Skin Long Lasting Refreshing Citrus Scent 250ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-mencare-extra-fresh-dry-spray-antiperspirant-aerosol-deodorant-up-to-48-hrs-protection-from-sweat-odour-soothes-moisturises-skin-long-lasting-refreshing-citrus-scent-250ml/65dc209bf165533c780c3aef' },
{ name: 'Bumtum Baby Bed Mosquito Net Neck Pillow Baby Gadda Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-baby-bed-mosquito-net-neck-pillow-baby-gadda-set/65dc0b81f16553b19d0c3ad0' },
{ name: 'Nilkamal Cntbl2cg Contemporary Center Coffee Tabletea Tableteapoy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nilkamal-cntbl2cg-contemporary-center-coffee-tabletea-tableteapoy/65dc0f05f16553e32c0c3ad5' },
{ name: 'Fiama Men Shower Gel Deep Clean Body Wash With Skin Conditioners And Charcoal Grapefruit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-men-shower-gel-deep-clean-body-wash-with-skin-conditioners-and-charcoal-grapefruit/65dc1d16f16553af640c3aea' },
{ name: 'Fiama Celebration Pack With 5 Unique Body Wash Shower Gels 625ml 125ml Pack Of 5 Body Wash For Women Men With Skin Conditioners For Moisturised Skin Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-celebration-pack-with-5-unique-body-wash-shower-gels-625ml-125ml-pack-of-5-body-wash-for-women-men-with-skin-conditioners-for-moisturised-skin-gift/65dc241ff16553836a0c3af4' },
{ name: 'Humming Bird Building Blocks Toys For Kids Educatinal And Learning Puzzle Games', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/humming-bird-building-blocks-toys-for-kids-educatinal-and-learning-puzzle-games/65dc07fdf1655354d50c3aca' },
{ name: 'Wipro Garnet 10w Led Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-10w-led-bulb/65dbfd6ea19558dc000882db' },
{ name: 'Luminous Audie Spartan Red Fan 1200mm 2 Star Rated Energy Saving High Speed 50 Watt High Air Delivery Fan With 3 Blade Inverter Compatible And Low Power Consumption Metallic Body Ceiling Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-audie-spartan-red-fan-1200mm-2-star-rated-energy-saving-high-speed-50-watt-high-air-delivery-fan-with-3-blade-inverter-compatible-and-low-power-consumption-metallic-body-ceiling-fan/65dc00f2a19558fce10882e0' },
{ name: 'Havells Sprint 400mm Energy Saving With Remote Control Bldc Pedestal Fan White Yellow Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-sprint-400mm-energy-saving-with-remote-control-bldc-pedestal-fan-white-yellow-pack-of-1/65dc0476a195588bb30882e5' },
{ name: 'Pears Naturale Brightening Pomegranate Bodywash With Glycerine Paraben Free Soap Free Eco Friendly Dermatologically Tested 750 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pears-naturale-brightening-pomegranate-bodywash-with-glycerine-paraben-free-soap-free-eco-friendly-dermatologically-tested-750-ml/65dbe854a19558025d0882bc' },
{ name: 'Surya 6w Moon Pro Led Downlighter Recessed Led Downlight For Ceiling Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surya-6w-moon-pro-led-downlighter-recessed-led-downlight-for-ceiling-led-ceiling-light/65dbef5da195586ebd0882c6' },
{ name: 'Ecolink Polycarbonate 3 Watt Striker Bluepack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-polycarbonate-3-watt-striker-bluepack-of-4/65dbebd8a19558e6e40882c1' },
{ name: 'Philips T Bulb Rangoli B22 5 Watt Led Bulb Pack Of 4 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-t-bulb-rangoli-b22-5-watt-led-bulb-pack-of-4-red/65dbe4d0a195584ff10882b7' },
{ name: 'Oshotto Air Fabric Lumbar Support Memory Foam Orthopedic Cushion With Headrest Combo Set Of 2 Compatible With Hyundai Creta 2020 2023 Beige', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oshotto-air-fabric-lumbar-support-memory-foam-orthopedic-cushion-with-headrest-combo-set-of-2-compatible-with-hyundai-creta-2020-2023-beige/65dbf9e9a195587b400882d6' },
{ name: 'Fogg Royal Body Spray For Men 150ml Marco Body Spray For Men 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fogg-royal-body-spray-for-men-150ml-marco-body-spray-for-men-150ml/65dbf2e1a195581d500882cb' },
{ name: 'Ecolink 10w Square Ceiling Downlighter Warm Whitepack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-10w-square-ceiling-downlighter-warm-whitepack-of-4/65dbe14ca19558d0330882b2' },
{ name: 'Hometales Cotton Ethnic Printed Bedsheet With 2 Pillow Coverseagreen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hometales-cotton-ethnic-printed-bedsheet-with-2-pillow-coverseagreen/65dbf665a1955852e80882d0' },
{ name: 'Parachute Advansed Deep Nourish Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-deep-nourish-body-lotion/65dbddc7a19558407e0882ad' },
{ name: 'Halonix 5w 1ft Streak Square Cool White Pack Of 2 Cool Day Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-5w-1ft-streak-square-cool-white-pack-of-2-cool-day-light/65dbd33ba19558c23608829e' },
{ name: 'Philips Astra Line 9 Watt 2 Feet Led Tubelight Batten Cool White Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-line-9-watt-2-feet-led-tubelight-batten-cool-white-pack-of-4/65dbd6bfa195583cef0882a3' },
{ name: 'Crompton Immensa 9 Watt E27 Wifi Smart Led Bulb With Music Sync 16 Million Colors Compatible With Amazon Alexa And Google Assistantpack Of 8', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-immensa-9-watt-e27-wifi-smart-led-bulb-with-music-sync-16-million-colors-compatible-with-amazon-alexa-and-google-assistantpack-of-8/65dbda43a195586a640882a8' },
{ name: 'Cadbury Oreo Vanilla Flavour Crme Sandwich Biscuit 28875 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-oreo-vanilla-flavour-crme-sandwich-biscuit-28875-g/65dbc8aea1955822db08828f' },
{ name: 'Eveready 14w Led Light Bulb High Efficiency Glare Free Light 4kv Surge Protection Long Life Low Maintenance Uniform Light Distribution Cool Day Light 6500k Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-14w-led-light-bulb-high-efficiency-glare-free-light-4kv-surge-protection-long-life-low-maintenance-uniform-light-distribution-cool-day-light-6500k-pack-of-4/65dbc1a5a19558e676088285' },
{ name: 'Philips Full Glow Energy Saver Filament Glass E27 Led Bulb 9 Watt 825 Lumen Full Glow Frosted Diffused Led Bulb Cool Daylight Pack Of 5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-full-glow-energy-saver-filament-glass-e27-led-bulb-9-watt-825-lumen-full-glow-frosted-diffused-led-bulb-cool-daylight-pack-of-5/65dbbe21a195589f81088280' },
{ name: 'Philips Ace Saver 10w B22 Led Bulb900lm Cool Day Light Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ace-saver-10w-b22-led-bulb900lm-cool-day-light-pack-of-4/65dbba9da195587999088275' },
{ name: 'For Hdfcciti Credit Card Emi Samsung 653 L 3 Star Frost Free Convertible 5 In 1 Digital Inverter Side By Side Wifi Embedded Refrigerator Rs76cg8003s9hl Silver Refined Inox 2023 Model', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-hdfcciti-credit-card-emi-samsung-653-l-3-star-frost-free-convertible-5-in-1-digital-inverter-side-by-side-wifi-embedded-refrigerator-rs76cg8003s9hl-silver-refined-inox-2023-model/65dbb718a195583fa3088270' },
{ name: 'Wonder Homeware Varum Prime 1000 Lt Bathroom Mug Strong Plastic Materialfor Use In Bathroom Kitchen Laundry Garagepack Of 2 1 Liter Orange Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-varum-prime-1000-lt-bathroom-mug-strong-plastic-materialfor-use-in-bathroom-kitchen-laundry-garagepack-of-2-1-liter-orange-pink/65dbb394a19558e42408826b' },
{ name: 'Fitspire Fit Women Multivitamin Tablets 60 Tablets Daily Multivitamin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-fit-women-multivitamin-tablets-60-tablets-daily-multivitamin/65dbc529a195581b6308828a' },
{ name: 'Slovic Dual Color Heavy Resistance Band Yellow Grey For Workout Set Exercise Stretching Pull Up Bands For Home Exercise Toning Loop Bands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/slovic-dual-color-heavy-resistance-band-yellow-grey-for-workout-set-exercise-stretching-pull-up-bands-for-home-exercise-toning-loop-bands/65dbcc32a195580b76088294' },
{ name: 'Vaseline Gluta Hya Flawless Glow 200ml Serum In Lotion Boosted With Glutaglow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-gluta-hya-flawless-glow-200ml-serum-in-lotion-boosted-with-glutaglow/65dbcfb6a19558468b088299' },
{ name: 'Small Black Sling Crossbody Backpack Shoulder Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/small-black-sling-crossbody-backpack-shoulder-bag/65dbb010a195581172088266' },
{ name: 'Wonder Homeware Prime Santro 44 Plastic Container', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-prime-santro-44-plastic-container/65dbac8ba195587082088261' },
{ name: 'Wonder Duo Plastic Bucket Mug Combo Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-duo-plastic-bucket-mug-combo-set/65dba907a19558922a08825c' },
{ name: 'Auric Lab Certified Cow Ghee 1l 100 Pure And Natural Desi Ghee Highly Nutritious Helps Keep Your Heart Healthy Boost Immunity Energy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/auric-lab-certified-cow-ghee-1l-100-pure-and-natural-desi-ghee-highly-nutritious-helps-keep-your-heart-healthy-boost-immunity-energy/65db9af4ee2c09832350cbfb' },
{ name: 'Ecolink 20 Watt Polycarbonate Led Batten Cool Whitepack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-20-watt-polycarbonate-led-batten-cool-whitepack-of-2/65dba1ffa1955879b508824b' },
{ name: 'Athrz Camping Tent Portable Foldable Tent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athrz-camping-tent-portable-foldable-tent/65db8256ee2c0902e050cbd8' },
{ name: 'Zebronics Zeb Companion 107 Usb Wireless Keyboard And Mouse Set With Nano Receiver Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-companion-107-usb-wireless-keyboard-and-mouse-set-with-nano-receiver-black/65db7ed2ee2c09d92b50cbd3' },
{ name: 'Levis Mens Cotton Brief', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/levis-mens-cotton-brief/65db7b4eee2c094a9e50cbce' },
{ name: 'Amazon Brand Solimo Catmint Engineered Wood Wall Mounted Tventertainment Unit Wenge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-catmint-engineered-wood-wall-mounted-tventertainment-unit-wenge/65db70c1ee2c092fc950cbbf' },
{ name: 'Lg 7 Kg 5 Star Inverter Turbodrum Fully Automatic Top Loading Washing Machine T70sksf1z Waterfall Circulation Smart Motion Middle Free Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-7-kg-5-star-inverter-turbodrum-fully-automatic-top-loading-washing-machine-t70sksf1z-waterfall-circulation-smart-motion-middle-free-silver/65db77c9ee2c094e2650cbc9' },
{ name: 'Sonata Black Dial Analog Watch For Men Nm77068pp02', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-black-dial-analog-watch-for-men-nm77068pp02/65db7445ee2c095bde50cbc4' },
{ name: 'Sandisk Ultra Dual 64 Gb Usb 30 Otg Pen Drive Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sandisk-ultra-dual-64-gb-usb-30-otg-pen-drive-black/65db6d3dee2c091e8550cbba' },
{ name: 'Size 4 Years 5 Years Kidsville Boys Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-4-years-5-years-kidsville-boys-shorts/65db69b8ee2c096e2b50cbb5' },
{ name: 'Go Desi Sesame Chikki Jar Til Chikki Nutritious Bar No Added Preservatives And Colours Gajak Sweets Gazak 50 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-sesame-chikki-jar-til-chikki-nutritious-bar-no-added-preservatives-and-colours-gajak-sweets-gazak-50-pieces/65db6634ee2c09639a50cbb0' },
{ name: 'Amazon Fresh Parachute Advansed Cocoa Repair Body Lotion Intense Moisture 250ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-fresh-parachute-advansed-cocoa-repair-body-lotion-intense-moisture-250ml/65db62b0ee2c098cf750cbab' },
{ name: 'Zebronics Zeb Sound Feast 50 Wireless Bluetooth 14w Rugged Finish Portable Speaker With Supporting Dual Drivers Handy Strap Mobile Holder Usb Sd Card Aux Fm Tws Call Function Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-sound-feast-50-wireless-bluetooth-14w-rugged-finish-portable-speaker-with-supporting-dual-drivers-handy-strap-mobile-holder-usb-sd-card-aux-fm-tws-call-function-green/65db5f2bee2c0913ed50cba6' },
{ name: 'Bumtum Baby Dry Sheet Waterproof Soft Microfleece Baby Bed Protector Anti Bacterial Odour Free Extra Absorbant Reuseable Washable Grape Small Size 50 70cm Pack Of 2 Microfleece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-baby-dry-sheet-waterproof-soft-microfleece-baby-bed-protector-anti-bacterial-odour-free-extra-absorbant-reuseable-washable-grape-small-size-50-70cm-pack-of-2-microfleece/65db5823ee2c094a1350cb9c' },
{ name: 'Paree Super Ultra Dry Feel Xxl Tri Fold Sanitary Pad Pack Of 40', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paree-super-ultra-dry-feel-xxl-tri-fold-sanitary-pad-pack-of-40/65db5ba7ee2c099aaa50cba1' },
{ name: 'Specific Users Introducing Amazon Smart Plug Works With Alexa 6a Easy Set Up', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/specific-users-introducing-amazon-smart-plug-works-with-alexa-6a-easy-set-up/65db549eee2c09041450cb97' },
{ name: 'Crackles Steam Toys Diy 3d Paper Model Automata Bot With Animated Movements Indian Mythology Series Ram And Ravan Duel Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crackles-steam-toys-diy-3d-paper-model-automata-bot-with-animated-movements-indian-mythology-series-ram-and-ravan-duel-set-of-2/65db3f85ee2c09e07350cb79' },
{ name: 'Nestle Coffee Mate 400 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nestle-coffee-mate-400-g/65db511aee2c0917fb50cb92' },
{ name: 'Philips Led Deco Green 05w Glass Candle Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-led-deco-green-05w-glass-candle-pack-of-4/65db387cee2c097a2e50cb6f' },
{ name: 'Popo Toys Finger Paints Set Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/popo-toys-finger-paints-set-multicolor/65db34f8ee2c0934a950cb6a' },
{ name: 'Echo Dot 4th Gen Blue With Clock Combo With Wipro 9w Led Smart Color Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/echo-dot-4th-gen-blue-with-clock-combo-with-wipro-9w-led-smart-color-bulb/65db3c00ee2c091f5750cb74' },
{ name: 'Origami 2 Ply Kitchen Tissue Paper Roll 6 In 1 60 Pulls Per Roll 360 Sheets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/origami-2-ply-kitchen-tissue-paper-roll-6-in-1-60-pulls-per-roll-360-sheets/65db4a11ee2c09095150cb88' },
{ name: 'Mister Maker Wild Animal Masks Multi Color', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mister-maker-wild-animal-masks-multi-color/65db4309ee2c09fee150cb7e' },
{ name: 'Jack Royal Kitchen Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jack-royal-kitchen-set/65db3173ee2c0932d850cb65' },
{ name: 'Homemate Wifi Smart Plug Socket 16a Pack Of 1 With Energy Monitoring Works With Amazon Alexa Google Assistant And Siri Type D 16a Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/homemate-wifi-smart-plug-socket-16a-pack-of-1-with-energy-monitoring-works-with-amazon-alexa-google-assistant-and-siri-type-d-16a-pack-of-1/65db2a6bee2c0991ac50cb5b' },
{ name: 'Glun Waterproof Double Layered Round Laundry Bag With Handles Foldable For Cloth Toys Storage Bag Pack Of 1 Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/glun-waterproof-double-layered-round-laundry-bag-with-handles-foldable-for-cloth-toys-storage-bag-pack-of-1-green/65db2defee2c093ba950cb60' },
{ name: 'Tu Casa Hg 25 Metal Wire Pendent Light Iron Holder Type E 27 Size 12x8x32 Corded Electricbulb Not Included', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tu-casa-hg-25-metal-wire-pendent-light-iron-holder-type-e-27-size-12x8x32-corded-electricbulb-not-included/65db2362ee2c09c76250cb51' },
{ name: 'Supercart 63a 2p 220v Two Phase Toggle Switch Home Dual Power Automatic Transfer Switch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/supercart-63a-2p-220v-two-phase-toggle-switch-home-dual-power-automatic-transfer-switch/65db18d5ee2c0946a150cb41' },
{ name: 'Ved Tatva Organic Certified Green Tea Combo Of Basiltulsi Green Tea Mint Tea Loose Leaf With Natural Leavesdetox Green Teaherbal Tea 100gms 50cups Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ved-tatva-organic-certified-green-tea-combo-of-basiltulsi-green-tea-mint-tea-loose-leaf-with-natural-leavesdetox-green-teaherbal-tea-100gms-50cups-pack-of-2/65d96fd11b2fd210fd9c34f7' },
{ name: 'Hp Ink Advantage 2878 Printer Copy Scan Wifi Bluetooth Usb Simple Setup Smart App Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-ink-advantage-2878-printer-copy-scan-wifi-bluetooth-usb-simple-setup-smart-app-ideal/65d96c4d1b2fd2ca489c34f2' },
{ name: 'Funskool Giggles Wibbly Wobbly Train Pull Along Toy Encourages Walkingfunny Walking Style 12 Months Above Infant And Preschool Toys', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/funskool-giggles-wibbly-wobbly-train-pull-along-toy-encourages-walkingfunny-walking-style-12-months-above-infant-and-preschool-toys/65db1c59ee2c09fe6550cb47' },
{ name: 'Biotique Natural Makeup Wakeup Makeup Everyday Essentials Makeup Gift Kit With Attractive Pouch Lipstick Mascara Cc Cream Foundation Lip Balm Beginners Makeup Kit Premium Gift Set Free Makeup Bag Makeup Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-natural-makeup-wakeup-makeup-everyday-essentials-makeup-gift-kit-with-attractive-pouch-lipstick-mascara-cc-cream-foundation-lip-balm-beginners-makeup-kit-premium-gift-set-free-makeup-bag-makeup-kit/65d965441b2fd222db9c34e7' },
{ name: 'Escort Analog Blue Dial Mens Watch E 1750 7153 Bl5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/escort-analog-blue-dial-mens-watch-e-1750-7153-bl5/65d968c81b2fd298a29c34ed' },
{ name: 'Barbie Polyester 21 Cms Pink School Backpack Mbe Mat536', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/barbie-polyester-21-cms-pink-school-backpack-mbe-mat536/65db26e7ee2c0909e550cb56' },
{ name: 'Butterfly Premium Vegetable Chopper 900 Ml Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/butterfly-premium-vegetable-chopper-900-ml-blue/65db1fdeee2c0985bc50cb4c' },
{ name: 'Echo Pop Purple And Echo Dot 4th Gen With Clock Blue Combo With Wipro 9w Led Smart Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/echo-pop-purple-and-echo-dot-4th-gen-with-clock-blue-combo-with-wipro-9w-led-smart-bulb/65d95e390758a652206ac770' },
{ name: 'Colors Queen Creamy Matte Lipstick Highly Pigmented Lightweight Ultra Matte Long Lasting Non Transfer Lipstick Smudge Proof Waterproof Lipstick', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/colors-queen-creamy-matte-lipstick-highly-pigmented-lightweight-ultra-matte-long-lasting-non-transfer-lipstick-smudge-proof-waterproof-lipstick/65d95ab50758a63f1d6ac76b' },
{ name: 'Crompton Highflo Wave Plus 400 Mm 16 Inch 60w Oscillating Wall Fan Kd White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-highflo-wave-plus-400-mm-16-inch-60w-oscillating-wall-fan-kd-white/65d961be0758a644686ac775' },
{ name: 'Blue Heaven Flawless Makeup Base Primer For Face Makeup 30gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blue-heaven-flawless-makeup-base-primer-for-face-makeup-30gm/65d9378a0758a648736ac739' },
{ name: 'Bajaj Minor Radiant Room Heater For Home1000w Pedestal Heater For Winterelectric Heater For Roomstainless Steel Heat Reflectornickel Chrome Plated Mesh Guardtilted Legs2 Year Warranty By Bajaj', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-minor-radiant-room-heater-for-home1000w-pedestal-heater-for-winterelectric-heater-for-roomstainless-steel-heat-reflectornickel-chrome-plated-mesh-guardtilted-legs2-year-warranty-by-bajaj/65d93e930758a63f476ac743' },
{ name: 'Ptron Bassbuds Tango In Ear Tws Earbuds Trutalk Ai Enc Calls Movie Mode 40hrs Playtime Bluetooth 51 Headphone With Hd Mics Touch Control Ipx4 Water Resistant Type C Fast Charging Royal Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-tango-in-ear-tws-earbuds-trutalk-ai-enc-calls-movie-mode-40hrs-playtime-bluetooth-51-headphone-with-hd-mics-touch-control-ipx4-water-resistant-type-c-fast-charging-royal-blue/65d94ca40758a6da076ac757' },
{ name: 'Redmi Buds 4 Active Bass Black 12mm Driverspremium Sound Quality Up To 30 Hours Battery Life Google Fast Pair Ipx4 Bluetooth 53 Enc Up To 60ms Low Latency Mode App Support', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-buds-4-active-bass-black-12mm-driverspremium-sound-quality-up-to-30-hours-battery-life-google-fast-pair-ipx4-bluetooth-53-enc-up-to-60ms-low-latency-mode-app-support/65d953ad0758a619706ac761' },
{ name: 'Firbon A4 Paper Cutter 12 Inch Titanium Paper Trimmer Scrapbooking Tool With Automatic Security Safeguard And Side Ruler', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/firbon-a4-paper-cutter-12-inch-titanium-paper-trimmer-scrapbooking-tool-with-automatic-security-safeguard-and-side-ruler/65d93b0e0758a6a3af6ac73e' },
{ name: 'Bumtum Paraben Free Baby Soap 4n X 50 Gram Baby Bubble Bath 200 Ml Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-4n-x-50-gram-baby-bubble-bath-200-ml-combo/65d934060758a609bf6ac734' },
{ name: 'Bumtum Paraben Free Baby Soap 4n X 50 Gram Baby Baby Body Lotion 200 Ml Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-4n-x-50-gram-baby-baby-body-lotion-200-ml-combo/65d930820758a63af06ac72f' },
{ name: 'Lakme Absolute Matte Melt Mini Liquid Lip Color Nude Umbrella Long Lasting Velvet Matte Liquid Lipstick Lightweight Non Transferable 24 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-absolute-matte-melt-mini-liquid-lip-color-nude-umbrella-long-lasting-velvet-matte-liquid-lipstick-lightweight-non-transferable-24-ml/65d929790758a6df696ac725' },
{ name: 'Kalpatru Enterprises Colorfull And Extremely Light Weight Indoor And Outdoor Kids Play Tent House', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kalpatru-enterprises-colorfull-and-extremely-light-weight-indoor-and-outdoor-kids-play-tent-house/65d91eec0758a696e36ac716' },
{ name: 'Shasmi Womens Satin Full Length Night Suitnightynightdressnight Gown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/shasmi-womens-satin-full-length-night-suitnightynightdressnight-gown/65d91b670758a67efa6ac711' },
{ name: 'Patriot Memory Ddr4 2666mhz Cl19 Desktop Memory 8gb 1x8gb 12v Psd48g266681', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/patriot-memory-ddr4-2666mhz-cl19-desktop-memory-8gb-1x8gb-12v-psd48g266681/65d917e30758a658ec6ac70c' },
{ name: 'Puresense Aloe Vera Cucumber Nourishing Sheet Mask Natural Organic For Nourished Deeply Hydrated Glowing Skin Makers Of Parachute Advansed 15g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puresense-aloe-vera-cucumber-nourishing-sheet-mask-natural-organic-for-nourished-deeply-hydrated-glowing-skin-makers-of-parachute-advansed-15g/65d9145f0758a69c6f6ac707' },
{ name: 'Puma Mens Bold Extremerunning Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puma-mens-bold-extremerunning-shoe/65d925f50758a67ca26ac720' },
{ name: 'Revolution Eye Bright Under Eye Corrector Medium To Deep Light To Medium', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/revolution-eye-bright-under-eye-corrector-medium-to-deep-light-to-medium/65d92cfd0758a6b2a66ac72a' },
{ name: 'Cello Capri Without Cushion Chair Plasticbeige', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-capri-without-cushion-chair-plasticbeige/65d922700758a62e336ac71b' },
{ name: 'Dell Ms116 Wired Optical Mouse 1000dpi Led Tracking Scrolling Wheel Plug And Play', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-ms116-wired-optical-mouse-1000dpi-led-tracking-scrolling-wheel-plug-and-play/65d910db0758a678a46ac702' },
{ name: 'Cello Frosty Bucket Dlx 16 Litres Pink Clofrstybckt16lpnk', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-frosty-bucket-dlx-16-litres-pink-clofrstybckt16lpnk/65d90d560758a676126ac6fd' },
{ name: 'Hp Laserjet Tank Mfp 1005w Wireless Print Copy Scan Hi Speed Usb 20 Bluetooth Le Up To 22 Ppm 150 Sheet Input Tray 100 Sheet Output Tray 1 Year Warranty Black And White 381u4a', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-laserjet-tank-mfp-1005w-wireless-print-copy-scan-hi-speed-usb-20-bluetooth-le-up-to-22-ppm-150-sheet-input-tray-100-sheet-output-tray-1-year-warranty-black-and-white-381u4a/65d9064c33f76d55b00722b6' },
{ name: 'Facescanada Nail Enamel Remover Transparent 01 30 Ml Enriched With Vitamin E Non Drying Formula Gentle Nail Polish Remover Acetone Free Cruelty Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/facescanada-nail-enamel-remover-transparent-01-30-ml-enriched-with-vitamin-e-non-drying-formula-gentle-nail-polish-remover-acetone-free-cruelty-free/65d909d20758a6db546ac6f7' },
{ name: 'Philips Astra Sleek 10 Watt Round Led Downlighter Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-sleek-10-watt-round-led-downlighter-led-ceiling-light/65d8f83b33f76d2fad0722a2' },
{ name: 'Cello Flipstyle Stainless Steel Vacuum Insulated Flask 1000ml Hot And Cold Water Bottle With Flip Lid Double Walled Silver Bottle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-flipstyle-stainless-steel-vacuum-insulated-flask-1000ml-hot-and-cold-water-bottle-with-flip-lid-double-walled-silver-bottle/65d8ff4333f76d76e20722ac' },
{ name: 'Tecno Phantom V Flip 5g Mystic Dawn 16gb Ram 256gb Storage 45 Watts Fast Charging 32 Mp Selfie 64 Rear Camera 69 Flexible 132 Secondrary Amoled Lavender', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-phantom-v-flip-5g-mystic-dawn-16gb-ram-256gb-storage-45-watts-fast-charging-32-mp-selfie-64-rear-camera-69-flexible-132-secondrary-amoled-lavender/65d8f13233f76d877e072298' },
{ name: 'Ganesh Floor Cleaning Wiper Stainless Steel Pipe 16 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-floor-cleaning-wiper-stainless-steel-pipe-16-inch/65d8edae33f76debfc072293' },
{ name: 'Beardo Natural Hemp Facewash Scrub For Men With Soft Silicon Scrub', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-natural-hemp-facewash-scrub-for-men-with-soft-silicon-scrub/65d8ea2a33f76dc73807228c' },
{ name: 'Ecraftindia Ram Mandir Ayodhya Model Wooden Mdf Craftsmanship Authentic Designer Temple With Protective Gift Box Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-ram-mandir-ayodhya-model-wooden-mdf-craftsmanship-authentic-designer-temple-with-protective-gift-box-ideal/65d8e6a533f76d66f2072283' },
{ name: 'Environmanly Face Body Cream For Men 100 Gm Spf 20 Shea Butter Brown Rice Wheat Germ Intense Hydration Sun Protection Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/environmanly-face-body-cream-for-men-100-gm-spf-20-shea-butter-brown-rice-wheat-germ-intense-hydration-sun-protection-suitable/65d8f4b733f76de81e07229d' },
{ name: 'Panasonic 9w Led Bulb Led Bulb 9 Watt With B22 Base 4kv Surge Protection 9 Watt Bulb Cool Day Light Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panasonic-9w-led-bulb-led-bulb-9-watt-with-b22-base-4kv-surge-protection-9-watt-bulb-cool-day-light-pack-of-10/65d8fbbf33f76d32300722a7' },
{ name: 'Origami Kitchen Roll Pack Of 4 60 Pulls Each 2 Ply Total 4 Rolls 240 Sheets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/origami-kitchen-roll-pack-of-4-60-pulls-each-2-ply-total-4-rolls-240-sheets/65d902c833f76daa6b0722b1' },
{ name: 'Black Olive Toy Guitar For Kids 17 In Music Toy Guitar Instrument Acoustic Learning Educational Toy For Child Best', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/black-olive-toy-guitar-for-kids-17-in-music-toy-guitar-instrument-acoustic-learning-educational-toy-for-child-best/65d8e32133f76daebc072277' },
{ name: 'Djokr On The Rocks Perfume For Men 8 Ml Eau De Parfum Premium Luxury Long Lasting Fragrance', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/djokr-on-the-rocks-perfume-for-men-8-ml-eau-de-parfum-premium-luxury-long-lasting-fragrance/65d56394f1463f3461d2ef04' },
{ name: 'Sizes S M Xl Mrutbaa Western Dresses', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-s-m-xl-mrutbaa-western-dresses/65d48f832ee2d635fdae7c15' },
{ name: 'Godrej Smart Locks I Catus Touch I Smart Digital Lock', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-smart-locks-i-catus-touch-i-smart-digital-lock/65d47dee2ee2d65f5dae7bfc' },
{ name: 'Oblivion Designer Multicoloured Oven Mitt And Pot Holder Heat Resistant Pot Stand And Kitchen Gloves Heat Proof Free Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oblivion-designer-multicoloured-oven-mitt-and-pot-holder-heat-resistant-pot-stand-and-kitchen-gloves-heat-proof-free-size/65d481722ee2d681fdae7c01' },
{ name: 'Kuber Industries Country Modern Door Matfloor Matsolid Print Cotton Materialwater Absorbent Easy To Washsize Large 71 X 47 Cm Brown Large Rectangle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-country-modern-door-matfloor-matsolid-print-cotton-materialwater-absorbent-easy-to-washsize-large-71-x-47-cm-brown-large-rectangle/65d484f62ee2d6a527ae7c06' },
{ name: 'Westido Cyrus Leatherette 3 Seater Sofa Finish Color Creame Brown Diydo It Yourself 1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/westido-cyrus-leatherette-3-seater-sofa-finish-color-creame-brown-diydo-it-yourself-1-year-warranty/65d4887b2ee2d6630cae7c0b' },
{ name: 'Lakm Lipstick 16 Nude Bae Matte', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-lipstick-16-nude-bae-matte/65d48bff2ee2d60c4aae7c10' },
{ name: 'Beatxp Gravity Weighing Machine With Backlit Lcd Panel 6mm Thick Tempered Glass Weight Machine', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-gravity-weighing-machine-with-backlit-lcd-panel-6mm-thick-tempered-glass-weight-machine/65d47a692ee2d633a4ae7bf7' },
{ name: 'Lifelong Llrc02 Electric Rice Cooker 18 Litres700 Watt Aluminium Cooking Pan One Touch Operation Keep Warm Function Cool Touch Outer Body', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llrc02-electric-rice-cooker-18-litres700-watt-aluminium-cooking-pan-one-touch-operation-keep-warm-function-cool-touch-outer-body/65d476e52ee2d621e7ae7bf2' },
{ name: 'Lifelong Infinia Plus 1500 W Immersion Rod Water Heater Shock Proof With Copper Heating Element Electric Rod 1 Year Warranty Black Isi Certified', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-infinia-plus-1500-w-immersion-rod-water-heater-shock-proof-with-copper-heating-element-electric-rod-1-year-warranty-black-isi-certified/65d473602ee2d63b43ae7bec' },
{ name: 'Urban Magic 100 Microfiber Printed Red Double Bedsheet With 2 Pillow Covers Size 215 X 210 Cm Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urban-magic-100-microfiber-printed-red-double-bedsheet-with-2-pillow-covers-size-215-x-210-cm-pack-of-1/65d46fdad906d45c53d482a1' },
{ name: 'Crompton Laser Ray Neo 28w Led Batten Cool Daylight Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-laser-ray-neo-28w-led-batten-cool-daylight-pack-of-10/65d46c56d906d46663d4829c' },
{ name: 'Auric Kadak Moringa Masala Tea Black Tea From Assam Darjeeling Tea Blended With 7 Real Spices Cardamom Cinnamon Ginger Black Pepper Tulsi Star Anise Clove 1 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/auric-kadak-moringa-masala-tea-black-tea-from-assam-darjeeling-tea-blended-with-7-real-spices-cardamom-cinnamon-ginger-black-pepper-tulsi-star-anise-clove-1-kg/65d468d2d906d47dc2d48297' },
{ name: 'For Hdfcicici Credit Card Iqoo 12 5g Legend 12gb Ram 256gb Storage Indias 1st Snapdragon 8 Gen 3 Mobile Platform Indias Only Flagship With 50mp 50mp 64mp Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-hdfcicici-credit-card-iqoo-12-5g-legend-12gb-ram-256gb-storage-indias-1st-snapdragon-8-gen-3-mobile-platform-indias-only-flagship-with-50mp-50mp-64mp-camera/65d4654dd906d48db8d48292' },
{ name: 'Azah Period Pain Relief Heat Patches Pack Of 3 For Period Pain And Cramps Heating Patches', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/azah-period-pain-relief-heat-patches-pack-of-3-for-period-pain-and-cramps-heating-patches/65d461c9d906d454bdd4828d' },
{ name: 'Mahganya Industries Designer Home Decorative Rectangular Led Wall Lamp Plastic Warm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mahganya-industries-designer-home-decorative-rectangular-led-wall-lamp-plastic-warm-white/65d4573cd906d46b78d4827e' },
{ name: 'Del Monte Smoky Barbecue Sauce 325g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/del-monte-smoky-barbecue-sauce-325g/65d445a7d906d4ff6fd48265' },
{ name: 'Man Arden Dandruff Control Soothing Shampoo Helps Eliminate Dandruff Itchiness And Scalp Dryness No Sls Paraben Or Silicone 250 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/man-arden-dandruff-control-soothing-shampoo-helps-eliminate-dandruff-itchiness-and-scalp-dryness-no-sls-paraben-or-silicone-250-ml/65d44222d906d4a708d4825e' },
{ name: 'Ambrane 15w Dual Port Mobile Charger Dual Usb Port Compatible With Iphone Ipad Samsung Galaxy Note Redmi Mi Oneplus Oppo Vivo Smartphones Made In India Compact Durable Raap S21 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-15w-dual-port-mobile-charger-dual-usb-port-compatible-with-iphone-ipad-samsung-galaxy-note-redmi-mi-oneplus-oppo-vivo-smartphones-made-in-india-compact-durable-raap-s21-white/65d43b19cee6b53d06e97f13' },
{ name: 'Nayasa Lacy 2 Piece Plastic Tall Basket Set Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-lacy-2-piece-plastic-tall-basket-set-green/65d43e9ecee6b55f1ce97f18' },
{ name: 'Cadbury Fuse Peanut Caramel Filled Chocolate Home Treats 1085g Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-fuse-peanut-caramel-filled-chocolate-home-treats-1085g-pack-of-4/65d425ffcee6b51743e97eed' },
{ name: 'Vector 91 Justice League 14t Single Speed Kids Cycles Pink White Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vector-91-justice-league-14t-single-speed-kids-cycles-pink-white-ideal/65d41b73cee6b52e62e97edf' },
{ name: 'Amazon Brand Solimo Diamond Water Bottles Spill Proof 32 Cm Neck Firm Grip Set Of 6 1 L Each 3 Smoke And 3 Sea Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-diamond-water-bottles-spill-proof-32-cm-neck-firm-grip-set-of-6-1-l-each-3-smoke-and-3-sea-green/65d417eecee6b521e0e97ed9' },
{ name: 'Amazon Brand Solimo Premium Plastic Multipurpose Modular Drawer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-premium-plastic-multipurpose-modular-drawer/65d414687712661a611cb2b7' },
{ name: 'Dabur Gulabari Rose Oil Tea Tree Face Toner Mist With Salicylic Acid 100ml Treats Breakouts Blackheads And Whiteheads Tightens And Refines Pores Alcohol Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-gulabari-rose-oil-tea-tree-face-toner-mist-with-salicylic-acid-100ml-treats-breakouts-blackheads-and-whiteheads-tightens-and-refines-pores-alcohol-free/65d410e4771266864e1cb2b2' },
{ name: 'Axe Intense Long Lasting Deodorant Bodyspray For Men 215 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/axe-intense-long-lasting-deodorant-bodyspray-for-men-215-ml/65d4065777126632381cb2a2' },
{ name: 'Halonix B22d 10 Watt Led Astron Plus Pack Of 4 White Round', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-b22d-10-watt-led-astron-plus-pack-of-4-white-round/65d3ff4e7712667db41cb298' },
{ name: 'Beatxp Gravity Flora Digital Weight Machine For Body Weight With Thick Tempered Glass Best Bathroom Weighing Scale With Lcd Display 2 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-gravity-flora-digital-weight-machine-for-body-weight-with-thick-tempered-glass-best-bathroom-weighing-scale-with-lcd-display-2-year-warranty/65d3fbca771266d4581cb293' },
{ name: 'Hometales Multi Purpose Container 1100ml 700ml 450ml 300ml 150mlpink 5u', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hometales-multi-purpose-container-1100ml-700ml-450ml-300ml-150mlpink-5u/65d3f4c27712663bdd1cb289' },
{ name: 'Eveready 9w Led Light Bulb Cool Day Light 6500k Pack Of 10 Energy Efficient 4kv Surge Protection 100 Lumens Per Watt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-9w-led-light-bulb-cool-day-light-6500k-pack-of-10-energy-efficient-4kv-surge-protection-100-lumens-per-watt/65d3f84677126634bf1cb28e' },
{ name: 'Halonix 20w Led Batten Tubelight Pack Of 2 Cool Day White All Rounder Base B22 15w8w05w Multi Wattage Led Bulb Pack Of 1 White Yellow Adjustable Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-20w-led-batten-tubelight-pack-of-2-cool-day-white-all-rounder-base-b22-15w8w05w-multi-wattage-led-bulb-pack-of-1-white-yellow-adjustable-light/65d3f13d77126617091cb284' },
{ name: 'Resistance Loop Bands By Slovic 100 Natural Latex Exercise Bands With 1 Year Warranty Mini Resistance Loop Band', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/resistance-loop-bands-by-slovic-100-natural-latex-exercise-bands-with-1-year-warranty-mini-resistance-loop-band/65d402d3771266233e1cb29d' },
{ name: 'Boat Micro Usb 500 Tangle Free Sturdy Micro Usb Cable With 24a Fast Charging 480mbps Data Transmission 10000 Bends Lifespan Extended 15m Length Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-micro-usb-500-tangle-free-sturdy-micro-usb-cable-with-24a-fast-charging-480mbps-data-transmission-10000-bends-lifespan-extended-15m-length-black/65d409db771266aabf1cb2a7' },
{ name: 'Boat Deuce Usb 300 2 In 1 Type C Micro Usb Stress Resistant Sturdy Cable With 3a Fast Charging 480mbps Data Transmission 10000 Bends Lifespan And Extended 15m Lengthmercurial Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-deuce-usb-300-2-in-1-type-c-micro-usb-stress-resistant-sturdy-cable-with-3a-fast-charging-480mbps-data-transmission-10000-bends-lifespan-and-extended-15m-lengthmercurial-black/65d40d60771266794d1cb2ad' },
{ name: 'Ant Esports 690 Neo Vs 8gb 1 8gb Ddr3 1600 Mhz Cl 11 11 11 28 U Dimm Desktop Memory Ae8gd3u16m16c', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-690-neo-vs-8gb-1-8gb-ddr3-1600-mhz-cl-11-11-11-28-u-dimm-desktop-memory-ae8gd3u16m16c/65d3ea35771266a3281cb27a' },
{ name: 'Halonix All Rounder Multi Wattage Bulb 05w 8w 15w Blue B22 Led', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-all-rounder-multi-wattage-bulb-05w-8w-15w-blue-b22-led/65d3edb977126647671cb27f' },
{ name: 'Halonix 20w Led Batten Tubelight Pack Of 2 Cool Day White Philips 9 Watts Multipack B22 Led Cool Day White Led Bulb Pack Of 4 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-20w-led-batten-tubelight-pack-of-2-cool-day-white-philips-9-watts-multipack-b22-led-cool-day-white-led-bulb-pack-of-4-ace-saver/65d3e6b0771266192b1cb275' },
{ name: 'Yogabar 100 Pure Peanut Butter Creamy Yummy Unsweetened Slow Roasted Non Gmo Premium Peanuts No Added Sugar 400gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-100-pure-peanut-butter-creamy-yummy-unsweetened-slow-roasted-non-gmo-premium-peanuts-no-added-sugar-400gm/65d3d19677126660781cb257' },
{ name: 'Techvor 25w Type C Fast Charger For Android And', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/techvor-25w-type-c-fast-charger-for-android-and/65d3dfa8771266092c1cb26b' },
{ name: 'Cello Plastic Frosty Deluxe Bucket 16 Litres Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-plastic-frosty-deluxe-bucket-16-litres-red/65d3ca8e77126689171cb24b' },
{ name: 'Reynolds Geometry Box Instrument Box Pencil Box Rust Resistant Instruments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-geometry-box-instrument-box-pencil-box-rust-resistant-instruments/65d3c70977126612291cb246' },
{ name: 'Cp Plus 2mp Full Hd Smart Wi Fi Cctv Home Security Camera 2way Talk Motion Detect Human Detection Supports Onvif Night Vision Supports Sd Card Up To 256 Gb Cb21', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cp-plus-2mp-full-hd-smart-wi-fi-cctv-home-security-camera-2way-talk-motion-detect-human-detection-supports-onvif-night-vision-supports-sd-card-up-to-256-gb-cb21/65d3ce1277126630091cb252' },
{ name: 'Philips Astra Line 9 Watt 2 Feet Led Tubelight Batten Natural White Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-line-9-watt-2-feet-led-tubelight-batten-natural-white-pack-of-6/65d3dc237712661abf1cb266' },
{ name: 'Go Desi Jaggery Powder 500g Gur Gud Pure And Natural', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-jaggery-powder-500g-gur-gud-pure-and-natural/65d3d51b7712668c801cb25c' },
{ name: 'Cello Ozone Plastic Water Bottle Set 1 Litre Set Of 6 Assorted', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-ozone-plastic-water-bottle-set-1-litre-set-of-6-assorted/65d3c3857712664fa01cb241' },
{ name: 'Zebronics Zeb Action Wireless 10w Portable Speaker Supporting Bluetooth V51 Tws Rgb Lights Fabric Finish Auxusbfmmsd Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-action-wireless-10w-portable-speaker-supporting-bluetooth-v51-tws-rgb-lights-fabric-finish-auxusbfmmsd-red/65d3c000771266282f1cb23b' },
{ name: 'For Citibank Card Hindware Italian Collection Italian Collection Edge Rectangular Shaped Table Top Wash Basin Star White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-citibank-card-hindware-italian-collection-italian-collection-edge-rectangular-shaped-table-top-wash-basin-star-white/65d3bc7ab61cca96689d2b11' },
{ name: 'Bikaji Tana Bana Khatta Meetha 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-tana-bana-khatta-meetha-1kg/65d3aae5b61cca84879d2af8' },
{ name: 'For Sbi Credit Card Acer Aspire Lite Amd Ryzen 5 5500u Premium Metal Laptop 8gb Ram512gb Ssdwindows 11 Home Al15 41 3962cm 156 Full Hd Display Metal Body Steel Gray 159 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-acer-aspire-lite-amd-ryzen-5-5500u-premium-metal-laptop-8gb-ram512gb-ssdwindows-11-home-al15-41-3962cm-156-full-hd-display-metal-body-steel-gray-159-kg/65d3ae69b61cca6d209d2afd' },
{ name: 'Amazon Basics Gorilla Tripodmini Tripod', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-gorilla-tripodmini-tripod-/65d3b572b61cca6b969d2b07' },
{ name: 'Dabur Herbl Neem 300g 200g 100g Germ Protection Toothpaste With No Added Fluoride And Parabens', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-herbl-neem-300g-200g-100g-germ-protection-toothpaste-with-no-added-fluoride-and-parabens/65d3a3dcb61ccafd2e9d2aee' },
{ name: 'Fair And Handsome Instant Radiance Face Wash Pro Peptide Instant Radiance Washes Of Fine Pollutants Cooling Freshness 150g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fair-and-handsome-instant-radiance-face-wash-pro-peptide-instant-radiance-washes-of-fine-pollutants-cooling-freshness-150g/65d3b8f6b61cca14239d2b0c' },
{ name: 'For Citibank Card Bosch Gsb 600 Corded Electric Impact Drill Kit 600 W 13 Mm 17 Kg 3000 Rpm 14 Nm Variable Speed Forwardreverse Rotation Double Insulation Improved Carbon Brush 100 Pc Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-citibank-card-bosch-gsb-600-corded-electric-impact-drill-kit-600-w-13-mm-17-kg-3000-rpm-14-nm-variable-speed-forwardreverse-rotation-double-insulation-improved-carbon-brush-100-pc-kit/65d39cd3b61ccae6ee9d2ae4' },
{ name: 'For Onecard Credit Card Emi Fujitsu Ch 13th Gen Intel Core I5 133 Inch337cm Fhd Thin Light Laptop 16gb512 Gb Ssdwindows11office 2021iris Xe Graphicsbacklitfingerprint Readerblack998gm 4zr1l82433 Wsleeve', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-onecard-credit-card-emi-fujitsu-ch-13th-gen-intel-core-i5-133-inch337cm-fhd-thin-light-laptop-16gb512-gb-ssdwindows11office-2021iris-xe-graphicsbacklitfingerprint-readerblack998gm-4zr1l82433-wsleeve/65d3b1edb61cca584b9d2b02' },
{ name: 'Open Secret Gift Hamper Item Snacks Combo Box 3 Item Gift Combo Assorted Cookies Card Decorative Lights Healthy Unjuncked Food Gift Box Or Family Friends Corporate', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-gift-hamper-item-snacks-combo-box-3-item-gift-combo-assorted-cookies-card-decorative-lights-healthy-unjuncked-food-gift-box-or-family-friends-corporate/65d3a058b61cca30fb9d2ae9' },
{ name: 'Bikaji Sub Kuch Navratna Mix 1kg Authentic Indian Tea Snack Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-sub-kuch-navratna-mix-1kg-authentic-indian-tea-snack-perfect/65d3a760b61cca19f49d2af3' },
{ name: 'E Cosmos Soap Dispenser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-soap-dispenser/65d395cbb61cca61cb9d2ad5' },
{ name: 'Park Avenue Pure Luxury Soap Pack Of 4 500gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-pure-luxury-soap-pack-of-4-500gm/65d3994fb61cca8da69d2adb' },
{ name: 'Fogg Scen Mens Xpressio 50ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fogg-scen-mens-xpressio-50ml/65d392449bea58787d6be145' },
{ name: 'Ptron Newly Launched Bassbuds Nx Tws Earbuds Trutalk Ai Enc Calls 32hrs Playtime 13mm Drivers Bluetooth 53 Wireless Headphone Voice Assistant Type C Fast Charging Ipx5 Water Resistantblue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-bassbuds-nx-tws-earbuds-trutalk-ai-enc-calls-32hrs-playtime-13mm-drivers-bluetooth-53-wireless-headphone-voice-assistant-type-c-fast-charging-ipx5-water-resistantblue/65d38b3c9bea58cd096be13b' },
{ name: 'Ajmal Carbon Perfume Deodorant 200ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ajmal-carbon-perfume-deodorant-200ml/65ced03064a65cb12c92e6d4' },
{ name: 'For Icici Credit Card Redmi Pad Mediatek Helio G99 2695cm 1061 Inch 2k Resolution 90hz Refresh Rate Display 4gb Ram 128gb Storage Expandable Up To 1tb Quad Speaker Dolby Atmos Wi Fi Graphite Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-icici-credit-card-redmi-pad-mediatek-helio-g99-2695cm-1061-inch-2k-resolution-90hz-refresh-rate-display-4gb-ram-128gb-storage-expandable-up-to-1tb-quad-speaker-dolby-atmos-wi-fi-graphite-gray/65d387b79bea585c476be136' },
{ name: 'Milton Hexa Plastic Pet Storage Jar Set Of 12 4 Pcs X 270 Ml Each 4 Pcs X 665 Ml Each 4 Pcs X 124 Ltrs Each Blue Air Tight Container Kitchen Organiser Bpa Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-hexa-plastic-pet-storage-jar-set-of-12-4-pcs-x-270-ml-each-4-pcs-x-665-ml-each-4-pcs-x-124-ltrs-each-blue-air-tight-container-kitchen-organiser-bpa-free/65d38ec09bea587d536be140' },
{ name: 'Bumtum Paraben Free Baby Soap 4n X 50 Gram Baby Talc Powder 200 Gram Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-paraben-free-baby-soap-4n-x-50-gram-baby-talc-powder-200-gram-combo/65ced3b464a65c16fc92e6d9' },
{ name: 'For Citibank Card Samsung 80 Cm 32 Inches Hd Ready Smart Led Tv Ua32t4380akxxl Glossy Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-citibank-card-samsung-80-cm-32-inches-hd-ready-smart-led-tv-ua32t4380akxxl-glossy-black/65cec92764a65cc31f92e6ca' },
{ name: 'Toy Imagine 85 Inch Lcd Writing Tablet For Children 3 8 Years Digital Magic Slate Electronic Notepad Scribble Doodle Drawing Rough Pad Best Birthday Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toy-imagine-85-inch-lcd-writing-tablet-for-children-3-8-years-digital-magic-slate-electronic-notepad-scribble-doodle-drawing-rough-pad-best-birthday-gift/65ceccac64a65ca7fb92e6cf' },
{ name: 'Tagg Verve Max Ii 183 Biggest Display Smartwatch With Metal Body Smart Qr Codes To Receive Payments 120 Sports Mode 7 Days Long Battery Water Resistant In Build Calculator And Games', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tagg-verve-max-ii-183-biggest-display-smartwatch-with-metal-body-smart-qr-codes-to-receive-payments-120-sports-mode-7-days-long-battery-water-resistant-in-build-calculator-and-games/65cec5a364a65ce66f92e6c4' },
{ name: 'Everyuth Naturals Body Lotion Rejuvenating Flora 500ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/everyuth-naturals-body-lotion-rejuvenating-flora-500ml/65cebe989b675659c59017ef' },
{ name: 'Set Wet Deodorant For Men Swag Avatar Citrus Intense 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/set-wet-deodorant-for-men-swag-avatar-citrus-intense-150ml/65cec21c9b675609279017f4' },
{ name: 'Reynolds Champ Bp 30 Count Jar Blue I Lightweight Ball Pen With Comfortable Grip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-champ-bp-30-count-jar-blue-i-lightweight-ball-pen-with-comfortable-grip/65cebb149b6756d9d09017ea' },
{ name: 'For Specific Users Rivona Naturals Ha Aqua Moisturizing Face Cream For Dryness With Hyaluronic Acid Hydrating And Nourishing For Men Women All Skin Types 20 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-specific-users-rivona-naturals-ha-aqua-moisturizing-face-cream-for-dryness-with-hyaluronic-acid-hydrating-and-nourishing-for-men-women-all-skin-types-20-gm/65ceb78f9b6756fabb9017e5' },
{ name: 'Kesh King Emami No Paraben No Silicon 600 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesh-king-emami-no-paraben-no-silicon-600-ml/65cea97e9b67566d659017d1' },
{ name: 'Graphene Barbie Play Cell Phone Toy For Kids Toddlers With Music Ringtones Lights Birthday Party Favors And Gifts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/graphene-barbie-play-cell-phone-toy-for-kids-toddlers-with-music-ringtones-lights-birthday-party-favors-and-gifts/65cead039b675649069017d6' },
{ name: 'Philips Polycarbonate Astra Spot 2 Watt Led Spotlight Led Spotlight', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-polycarbonate-astra-spot-2-watt-led-spotlight-led-spotlight/65ce9b6d9b675657119017bd' },
{ name: 'Bergner Master Pro Air Fryer 1300 W 48 Liter With 360 Degree Airflow Technology Multi Preset Cooking Menu Basket With Nonstick Coating Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bergner-master-pro-air-fryer-1300-w-48-liter-with-360-degree-airflow-technology-multi-preset-cooking-menu-basket-with-nonstick-coating-black/65ce9ef29b675649029017c2' },
{ name: 'Cadbury Dairy Milk Silk Valentines Heart Blush Chocolate Bar 150 G Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-dairy-milk-silk-valentines-heart-blush-chocolate-bar-150-g-pack-of-3/65ce97e99b675643819017b8' },
{ name: 'Aasons Bicycle Chain Pin Remover Bike Link Breaker Splitter Mtb Cycle Repair Tool Bicycle Chain Puller Link Extractor Cycling Repair Universal Motorcyclecycle Chain Cleaner Brush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aasons-bicycle-chain-pin-remover-bike-link-breaker-splitter-mtb-cycle-repair-tool-bicycle-chain-puller-link-extractor-cycling-repair-universal-motorcyclecycle-chain-cleaner-brush/65ce8d5c9b6756508b90179d' },
{ name: 'Philips Astra Glow 5 Watt Round Led Downlighter Recessed Led Downlight For False Ceiling Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-glow-5-watt-round-led-downlighter-recessed-led-downlight-for-false-ceiling-led-ceiling-light/65ce94659b6756c0f29017b3' },
{ name: 'Envy Speed Deodorant 120ml Long Lasting Deo Fragrance For Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/envy-speed-deodorant-120ml-long-lasting-deo-fragrance-for-men/65ce90e09b6756cafa9017ae' },
{ name: 'Gleva Silicon Makeup Brush Cleaner Mat Cosmetic Cleaning Pads Portable Washing Tool', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gleva-silicon-makeup-brush-cleaner-mat-cosmetic-cleaning-pads-portable-washing-tool-/65ce7f4b9b675614b7901789' },
{ name: 'Loral Paris Conditioner Nourishes Repair Shine For Long And Lifeless Hair Dream Lengths 180ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loral-paris-conditioner-nourishes-repair-shine-for-long-and-lifeless-hair-dream-lengths-180ml/65ce7bc69b6756dac4901784' },
{ name: 'Pink Root Neem Tulsi Purifying Face Wash 100ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pink-root-neem-tulsi-purifying-face-wash-100ml-pack-of-2/65ce78429b6756a96690177f' },
{ name: 'Ponds Detan Facewash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-detan-facewash/65ce74be9b6756f1df90177a' },
{ name: 'Paree Dry Feel Regular Sanitary Pad Pack Of 40', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paree-dry-feel-regular-sanitary-pad-pack-of-40/65ce713a9b6756043a901775' },
{ name: 'Philips Led Deco Yellow 05w Glass Candle Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-led-deco-yellow-05w-glass-candle-pack-of-4/65ce89d89b67565214901798' },
{ name: 'Halonix Shield Ionizer Air Purifier 9w B22 6500k Cool White Led Bulb Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-shield-ionizer-air-purifier-9w-b22-6500k-cool-white-led-bulb-pack-of-1/65ce82cf9b675602da90178e' },
{ name: 'Graphene Blaze Storm Hot Fire Soft Bullet Gun Toy With 10 Safe Soft Foam Bullets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/graphene-blaze-storm-hot-fire-soft-bullet-gun-toy-with-10-safe-soft-foam-bullets/65ce86549b67568be2901793' },
{ name: 'Ras Luxury Oils Lavender Tea Tree Moisturising Hand Sanitiser Spray Gentle Safe Free Of Added Fragrance Or Toxins Powered With Essential Oils Kills 999 Microbes Easy To Use 50ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ras-luxury-oils-lavender-tea-tree-moisturising-hand-sanitiser-spray-gentle-safe-free-of-added-fragrance-or-toxins-powered-with-essential-oils-kills-999-microbes-easy-to-use-50ml/65ce6db59b67566929901756' },
{ name: 'Biotique Tea Tree Skin Clearing Normal To Oily Skin Face Wash 150 Ml Treats Acne Pimples Clear Skin Paraben Sls Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-tea-tree-skin-clearing-normal-to-oily-skin-face-wash-150-ml-treats-acne-pimples-clear-skin-paraben-sls-free/65ce66aaea42036f9b543693' },
{ name: 'Revolution Pro Revolution Pro Skin Finish Golden Glare Copper 11 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/revolution-pro-revolution-pro-skin-finish-golden-glare-copper-11-g/65ce6326ea42031b1354368e' },
{ name: 'Beardo Dark Side Deo 150ml Spicy Woody Deodorant Body Spray Perfume For Men Deo For Men Long Lasting Perfume Gift For Men Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-dark-side-deo-150ml-spicy-woody-deodorant-body-spray-perfume-for-men-deo-for-men-long-lasting-perfume-gift-for-men-gift/65ce6a319b67561151901750' },
{ name: 'Candes Star 1200mm High Speed Decorative Ceiling Fans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/candes-star-1200mm-high-speed-decorative-ceiling-fans/65ce437fb9344183a2ccb143' },
{ name: 'And Solid Round Neck Polyester Womens Straight Top Ss22ag099tpg P', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/and-solid-round-neck-polyester-womens-straight-top-ss22ag099tpg-p/65ce4a87b934414f44ccb14d' },
{ name: 'Athom Living Easy Home Designer Soft Anti Slip Bath Mat 38x58 Cm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athom-living-easy-home-designer-soft-anti-slip-bath-mat-38x58-cm-pack-of-2/65ce3c76b934416f86ccb139' },
{ name: 'Global Desi Womens Regular Fit Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/global-desi-womens-regular-fit-shirt/65ce3ffab93441f10fccb13e' },
{ name: 'Status Memory Foam Bath Mat Rug Ultra Soft And Non Slip Bathroom Rugs Water Absorbent And Machine Washable Bath Rug', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-memory-foam-bath-mat-rug-ultra-soft-and-non-slip-bathroom-rugs-water-absorbent-and-machine-washable-bath-rug/65ce38f2b934414bc6ccb128' },
{ name: 'Status Artificial Grass Floor Door Mat In Home Kitchen Office Entrance Mats 12 X 18 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-artificial-grass-floor-door-mat-in-home-kitchen-office-entrance-mats-12-x-18-inch/65c7b04973e6cc83326b106f' },
{ name: 'Philips 3w Starsurface Striker Cabinet Cool White Flush Mount Ceiling Lamp Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-3w-starsurface-striker-cabinet-cool-white-flush-mount-ceiling-lamp-pack-of-2/65ce31e9b934417f90ccb113' },
{ name: 'Status Contract Set Of 2 Hair Towel Super Absorbent Quick Dry Towel Bathrobe Magic Hair Warp Towel Super Quick Drying Microfiber Bath Towel Bath Towel Pink 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-set-of-2-hair-towel-super-absorbent-quick-dry-towel-bathrobe-magic-hair-warp-towel-super-quick-drying-microfiber-bath-towel-bath-towel-pink-2/65ce356db93441645bccb120' },
{ name: 'Osaka Ultra Fast Charger Osk C903w Lcd Charger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/osaka-ultra-fast-charger-osk-c903w-lcd-charger/65c7a5bc73e6cc4cea6b1060' },
{ name: 'Crompton Laser Ray Neo 24 W Led Batten Cool Day Light Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-laser-ray-neo-24-w-led-batten-cool-day-light-pack-of-1/65c7acc573e6cc6d9c6b106a' },
{ name: 'Gizga Essentials 25cm X 21cm Gaming Mouse Pad Laptop Desk Mat Computer Mouse Pad With Smooth Mouse Control Mercerized Surface Antifray Stitched Embroidery Edges Anti Slip Rubber Base', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-25cm-x-21cm-gaming-mouse-pad-laptop-desk-mat-computer-mouse-pad-with-smooth-mouse-control-mercerized-surface-antifray-stitched-embroidery-edges-anti-slip-rubber-base/65c79eb373e6cc941b6b1056' },
{ name: 'Fire Boltt Newly Launched Ninja Fit Pro Smartwatch Bluetooth Calling Full Touch 20 120 Sports Modes With Ip68 Multi Ui Screen Over 100 Cloud Based Watch Faces Built In Games Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-newly-launched-ninja-fit-pro-smartwatch-bluetooth-calling-full-touch-20-120-sports-modes-with-ip68-multi-ui-screen-over-100-cloud-based-watch-faces-built-in-games-red/65ce2e64b93441ffb2ccb10d' },
{ name: 'Chromozome Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-mens-regular-fit-t-shirt/65c7a23873e6cc0fee6b105b' },
{ name: 'Philips Astra Sleek 5 Watt Round Led Downlighter Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-astra-sleek-5-watt-round-led-downlighter-led-ceiling-light/65c7a94073e6ccc5336b1065' },
{ name: 'Kolar Activity Ball Pool For Kids With Ball Pit Baby Play Area Indoor Toys For 1 2 3 Years Old Foldable Play Tent With Basketball Hoop No Play Balls Includedmulticolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kolar-activity-ball-pool-for-kids-with-ball-pit-baby-play-area-indoor-toys-for-1-2-3-years-old-foldable-play-tent-with-basketball-hoop-no-play-balls-includedmulticolor/65c79b2f73e6cc5f8a6b1051' },
{ name: 'Nitho Nx100 Gaming Headphones With Foldable Mic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nitho-nx100-gaming-headphones-with-foldable-mic/65c7942673e6cc79306b1047' },
{ name: 'Philips 12w B22d Led Crystal White Bulb Pack Of 1 929001177114', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-12w-b22d-led-crystal-white-bulb-pack-of-1-929001177114/65c797ab73e6cc39ad6b104c' },
{ name: 'Halonix Streak Square 10 Watt Led Batten Cool White Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-streak-square-10-watt-led-batten-cool-white-pack-of-4/65c790a273e6ccfafc6b1042' },
{ name: 'Ambrane 18w Wall Charger Usb Port Quick Charge 30 Boostedspeed Mobile Charger Adapter Fast Charging Compatible With Android Mobile Headphones Tws Game Console Power Banksraap M10black Clear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-18w-wall-charger-usb-port-quick-charge-30-boostedspeed-mobile-charger-adapter-fast-charging-compatible-with-android-mobile-headphones-tws-game-console-power-banksraap-m10black-clear/65c78d1e73e6cc46f06b103d' },
{ name: 'Fantastic Zone Mens Winter Woollen Warm Slouchy Beanie Hat Knitting Skull Cap And Scarf', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fantastic-zone-mens-winter-woollen-warm-slouchy-beanie-hat-knitting-skull-cap-and-scarf/65c7899973e6cc23fe6b1038' },
{ name: 'Cello Swiperr Spin Mop Stick Rod Only Without Bucket With 1 Microfiber Refill Standing Magic Pocha With Easy Grip Handle For Floor Cleaning Supplies Product', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-swiperr-spin-mop-stick-rod-only-without-bucket-with-1-microfiber-refill-standing-magic-pocha-with-easy-grip-handle-for-floor-cleaning-supplies-product/65c7829173e6cc86696b102e' },
{ name: 'Real Activ Coconut Water Tetrapack 1l Hydrating Coconut Water With Health Benefits No Added Flavour And Sugars Tasty And Nutritious', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/real-activ-coconut-water-tetrapack-1l-hydrating-coconut-water-with-health-benefits-no-added-flavour-and-sugars-tasty-and-nutritious/65c7861573e6cc44d46b1033' },
{ name: 'E Cosmos Sprout Maker With 4 Compartments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-sprout-maker-with-4-compartments/65c77f0c73e6cc40e26b1029' },
{ name: 'Vyoox Long Handle Silicone Bottle Cleaner Brush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vyoox-long-handle-silicone-bottle-cleaner-brush/65c77803978de00cc4fb9146' },
{ name: 'Wonderland Foods Device Dry Fruits Zahidi Dates 1kg 500g X 2 Pouch Healthy Nutritious Soft Khajoor Khajur Rich In Iron Fibre Vitamins Fresh', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderland-foods-device-dry-fruits-zahidi-dates-1kg-500g-x-2-pouch-healthy-nutritious-soft-khajoor-khajur-rich-in-iron-fibre-vitamins-fresh/65c77b8873e6cc0af76b1024' },
{ name: 'Loreal Paris Repairing Shampoo Restoring Conditioner Smoothening And Repairing Serum For Damaged And Weak Hair With Pro Keratin Ceramideuv Filter Total Repair 5 Bundle Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loreal-paris-repairing-shampoo-restoring-conditioner-smoothening-and-repairing-serum-for-damaged-and-weak-hair-with-pro-keratin-ceramideuv-filter-total-repair-5-bundle-pack/65c7747d0b96673c84f9d7bc' },
{ name: 'Havells Room Heater Pacifio Mica Convenction 1500 Watt With Micathermic Technology 2 Heat Setting Black Rose Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-room-heater-pacifio-mica-convenction-1500-watt-with-micathermic-technology-2-heat-setting-black-rose-gold/65c76d740b9667396bf9d7b2' },
{ name: 'Expertomind Neck Relaxer Cervical Pillow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/expertomind-neck-relaxer-cervical-pillow/65c770f90b96679b81f9d7b7' },
{ name: 'Aristo Swift Plastic Storage Container 300ml Set Of 3 Color May Vary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristo-swift-plastic-storage-container-300ml-set-of-3-color-may-vary/65c769f00b96677dc0f9d7ad' },
{ name: 'Gizga Essentials Multi Purpose Height Adjustable Laptop Table Ergonomic Study Table Portable With Docking For Tablet Perfect Desk Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-multi-purpose-height-adjustable-laptop-table-ergonomic-study-table-portable-with-docking-for-tablet-perfect-desk-ideal/65c762e70b9667d2f8f9d7a3' },
{ name: 'Open Secret Kimia Dates 800g Value Pack Khajoor Or Khajur Dry Fruit Healthy Nutritious Snack Rich In Protein Vitamins High Fibre No Added Sugar Ready To Eat 400g Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-kimia-dates-800g-value-pack-khajoor-or-khajur-dry-fruit-healthy-nutritious-snack-rich-in-protein-vitamins-high-fibre-no-added-sugar-ready-to-eat-400g-pack-of-2/65c7585a0b96676472f9d794' },
{ name: 'Ant Esports Flora Fit Weight Machine For Body Weight Digital Bathroom Scale For Human Body Weight Measurement With Wide Lcd Display Thick Tampered Glass', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-flora-fit-weight-machine-for-body-weight-digital-bathroom-scale-for-human-body-weight-measurement-with-wide-lcd-display-thick-tampered-glass/65c751520b9667ef18f9d78a' },
{ name: 'Chevalier Pepper Spray Self Defence', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chevalier-pepper-spray-self-defence/65c75bdf0b9667aeaef9d799' },
{ name: 'Lifelong Llm45 Rechargeable Full Body Massager With 3 Massage Heads Variable Speed Settings For Pain Relief And Relaxation Handheld Massager Cordless 1 Year Warranty Battery Powered Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llm45-rechargeable-full-body-massager-with-3-massage-heads-variable-speed-settings-for-pain-relief-and-relaxation-handheld-massager-cordless-1-year-warranty-battery-powered-brown/65c74dcd0b96673781f9d785' },
{ name: 'Greenfinity Fresh Almonds 500gm Badam Healthy And Tasty Dry Fruits Delicious Snacks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/greenfinity-fresh-almonds-500gm-badam-healthy-and-tasty-dry-fruits-delicious-snacks/65c75f630b966760f0f9d79e' },
{ name: 'Bajaj Ivora Hb Led Lamp 9w Cool Day Light 6500k B22 Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-ivora-hb-led-lamp-9w-cool-day-light-6500k-b22-pack-of-6/65c7666b0b9667212ff9d7a8' },
{ name: 'Kinder Mix Assortment Box Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kinder-mix-assortment-box-blue/65c754d60b9667995af9d78f' },
{ name: 'For Icici Bank Credit Card Oneplus Nord Ce 3 5g Aqua Surge 8gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-icici-bank-credit-card-oneplus-nord-ce-3-5g-aqua-surge-8gb-ram-128gb-storage/65c74a490b9667d467f9d780' },
{ name: 'Crompton Laser Ray Neo 20w Led Batten Cool Daylight Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-laser-ray-neo-20w-led-batten-cool-daylight-pack-of-1/65c746c40b96671530f9d77b' },
{ name: 'Glass Vase', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/glass-vase/65c73fbc0b9667631bf9d771' },
{ name: 'Athom Living Ecosaviour Premium Cotton Bath Towel Green Checkers Pack Of 5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athom-living-ecosaviour-premium-cotton-bath-towel-green-checkers-pack-of-5/65c743400b9667edd5f9d776' },
{ name: 'Havells Sameera 400mm Table Fan White Fhtsustwht16', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-sameera-400mm-table-fan-white-fhtsustwht16/65c7271b3d4e492034827e19' },
{ name: 'Vahdam Green Tea Bag Sampler Tea Variety Pack 10 Teas Bags Gluten Free Pure Ingredients Pure Assorted Green Tea Gift Sets Assortment Of 10 Green Tea Flavors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vahdam-green-tea-bag-sampler-tea-variety-pack-10-teas-bags-gluten-free-pure-ingredients-pure-assorted-green-tea-gift-sets-assortment-of-10-green-tea-flavors/65c720123d4e49df42827e0f' },
{ name: 'Bajaj Splendid 120ts 1200w Induction Cooktop With Tact Switch Blackwhite', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-splendid-120ts-1200w-induction-cooktop-with-tact-switch-blackwhite/65c723973d4e491629827e14' },
{ name: 'Park Avenue Voyage Amazon Woods Perfume 120mlpark Avenue Cool Blue Soap Pack Of 125 4 500gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-voyage-amazon-woods-perfume-120mlpark-avenue-cool-blue-soap-pack-of-125-4-500gm/65c71c8e3d4e4909d4827e0a' },
{ name: 'Brut Musk Deodorant Body Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/brut-musk-deodorant-body-spray/65c7190a3d4e496d7d827e05' },
{ name: 'Fun Homes 4 Shelf Closet Hanging Organizer 4 Tier Closet Wardrobe Organizer Clothes Storage Hanger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fun-homes-4-shelf-closet-hanging-organizer-4-tier-closet-wardrobe-organizer-clothes-storage-hanger/65c715853d4e49377c827e00' },
{ name: 'Park Avenue Good Morning Combo Perfume For Men Fresh Long Lasting Fragrance Super Saver Pack 300ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-good-morning-combo-perfume-for-men-fresh-long-lasting-fragrance-super-saver-pack-300ml-pack-of-2/65c70e7d3d4e496e84827df0' },
{ name: 'Blue Heaven Lash Twist Mascara Black 12 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blue-heaven-lash-twist-mascara-black-12-ml/65c703f03d4e49b2f1827ddf' },
{ name: 'Bombay Shaving Company Coffee Face Wash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bombay-shaving-company-coffee-face-wash/65c7006b3d4e4955be827dda' },
{ name: 'Milton Heavy Swiperr Spin Mop Stick Rod Only Without Bucket With 1 Microfiber Refill Standing Magic Pocha With Easy Grip Handle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-heavy-swiperr-spin-mop-stick-rod-only-without-bucket-with-1-microfiber-refill-standing-magic-pocha-with-easy-grip-handle-/65c6fce73d4e493c67827dd5' },
{ name: 'Wipro Safelite N10006 B22 05 Watt Led Night Lamp Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-safelite-n10006-b22-05-watt-led-night-lamp-pack-of-6/65c707743d4e492d06827de4' },
{ name: 'Santoor Perfumed Talc 150g Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/santoor-perfumed-talc-150g-pack-of-2/65c712013d4e49ce48827df5' },
{ name: 'Bajaj Ivora 20w Led Tubelight 2000 Lm Led Tubelight Round Led Batten White Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-ivora-20w-led-tubelight-2000-lm-led-tubelight-round-led-batten-white-pack-of-1/65c70af83d4e4979e7827de9' },
{ name: 'Go Vegan Dry Fruits Combo Pack Of Almonds Cashews Raisins Dried Apricot Pack Of 4 Each 250 Gm Hampers For Employees Dry Fruits Gift Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-vegan-dry-fruits-combo-pack-of-almonds-cashews-raisins-dried-apricot-pack-of-4-each-250-gm-hampers-for-employees-dry-fruits-gift-pack/65c6f9633d4e490bfd827dd0' },
{ name: 'Borosil Volcano 9 Fin Ofr 3 Power Heating Mode 2400w Oil Filter Filled Radiator Room Heater Noiseless Operation Ptc Fan 2 Years Warranty Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/borosil-volcano-9-fin-ofr-3-power-heating-mode-2400w-oil-filter-filled-radiator-room-heater-noiseless-operation-ptc-fan-2-years-warranty-black/65c6f5de3d4e49160f827dcb' },
{ name: 'Anchor By Panasonic Eco Breeze High Speed Energy Efficient Bldc Ceiling Fan With Remote 3 Blade Ceiling Fan 5 Star Rated 1200mm 48 Inch Ceiling Fan 2 Yrs Warranty Matt White 14143mwh', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anchor-by-panasonic-eco-breeze-high-speed-energy-efficient-bldc-ceiling-fan-with-remote-3-blade-ceiling-fan-5-star-rated-1200mm-48-inch-ceiling-fan-2-yrs-warranty-matt-white-14143mwh/65c6f25a3d4e491081827dc6' },
{ name: 'Ptron Newly Launched Bassbuds Nx Tws Earbuds Trutalk Ai Enc Calls 32hrs Playtime 13mm Drivers Bluetooth 53 Wireless Headphone Voice Assistant Type C Fast Charging Ipx5 Water Resistantblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-bassbuds-nx-tws-earbuds-trutalk-ai-enc-calls-32hrs-playtime-13mm-drivers-bluetooth-53-wireless-headphone-voice-assistant-type-c-fast-charging-ipx5-water-resistantblack/65c6eed63d4e49128e827dc0' },
{ name: 'Sugar Free Green Stevia Pouch 400 G 100 Plant Based Natural Sweetener Equivalent To Sweetness From 1kg Sugar 100 Natural Stevia Leaves Sweet Like Sugar But With Zero Calories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-free-green-stevia-pouch-400-g-100-plant-based-natural-sweetener-equivalent-to-sweetness-from-1kg-sugar-100-natural-stevia-leaves-sweet-like-sugar-but-with-zero-calories/65c6eb4fc2cd0eb5d0a34e41' },
{ name: 'Poco C51 Royal Blue 6gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/poco-c51-royal-blue-6gb-ram-128gb-storage/65c6d633bdc3850373abaf3b' },
{ name: 'Ocean San Marino Water Glass Set 245ml 6 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ocean-san-marino-water-glass-set-245ml-6-pieces/65c6e446c2cd0e59b3a34e37' },
{ name: 'Dr Batras Pro Hair Fall Control Oil Scalp Nourishment Enriched With Ginsend Rosemary Extracts Healthy Hair Suitable For All Best', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dr-batras-pro-hair-fall-control-oil-scalp-nourishment-enriched-with-ginsend-rosemary-extracts-healthy-hair-suitable-for-all-best/65c6e0c2c2cd0ec316a34e32' },
{ name: 'Pigeon By Stovekraft Air Fryer Oven 25l 2 In 1 Appliance Otg Airfryer 1400 Watts Air Fry Bake Broil Toast Defrost Black Without Rotisserie', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-air-fryer-oven-25l-2-in-1-appliance-otg-airfryer-1400-watts-air-fry-bake-broil-toast-defrost-black-without-rotisserie/65c6e7cac2cd0ead60a34e3c' },
{ name: 'Hp 150 Black Wired On Ear Headset With In Line Microphonetaggle Free With Long Flat Cord1 Year Rtb Warranty X7b04aa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-150-black-wired-on-ear-headset-with-in-line-microphonetaggle-free-with-long-flat-cord1-year-rtb-warranty-x7b04aa-black/65c6dd3ec2cd0e3b1ca34e2d' },
{ name: 'Wipro Safelite N10005 B22 05 Watt Led Night Lamp Pack Of 6 Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-safelite-n10005-b22-05-watt-led-night-lamp-pack-of-6-green/65c6d9b9c2cd0e4b47a34e27' },
{ name: 'Wow Skin Science 99 Pure Aloe Vera Gel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-99-pure-aloe-vera-gel/65c6d2aebdc3853283abaf36' },
{ name: 'Wow Skin Science Rosemary Oil Biotin Hair Growth Kit Complete Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-rosemary-oil-biotin-hair-growth-kit-complete-care/65c6cf2abdc3855e88abaf31' },
{ name: 'Truke Newly Launched Btg Neo Dual Pairing Earbuds With 6 Mic Advanced Enc 80h Playtime 35ms Ultra Low Latency 13mm Titanium Drivers 3 Eq Modes Fast Charge Instant Pairing Bluetooth 53 Ipx5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/truke-newly-launched-btg-neo-dual-pairing-earbuds-with-6-mic-advanced-enc-80h-playtime-35ms-ultra-low-latency-13mm-titanium-drivers-3-eq-modes-fast-charge-instant-pairing-bluetooth-53-ipx5/65c6cba6bdc3857de5abaf2c' },
{ name: 'Pentonic 06 Mm Gel Pen Blister Pack Black Body 4 Blue 4 Black 2 Red Ink Set Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pentonic-06-mm-gel-pen-blister-pack-black-body-4-blue-4-black-2-red-ink-set-of-10/65c6c821bdc385505cabaf27' },
{ name: 'Odonil Gel Pocket Mix 60g Pack Of 30gx2 Assorted Pack Of 3 New Fragrances Infused With Essential Oils Germ Protection Lasts Up To 30 Days Air Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/odonil-gel-pocket-mix-60g-pack-of-30gx2-assorted-pack-of-3-new-fragrances-infused-with-essential-oils-germ-protection-lasts-up-to-30-days-air-freshener/65c6c49dbdc385f21fabaf22' },
{ name: 'Odonil Gel Pocket Mix 30g Assorted Pack Of 3 New Fragrances Infused With Essential Oils Germ Protection Lasts Up To 30 Days Air Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/odonil-gel-pocket-mix-30g-assorted-pack-of-3-new-fragrances-infused-with-essential-oils-germ-protection-lasts-up-to-30-days-air-freshener/65c6c119bdc38534f9abaf1d' },
{ name: 'Havells Glaze 1200mm 1 Star Energy Saving Ceiling Fan Sapphire Blue Chrome Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-glaze-1200mm-1-star-energy-saving-ceiling-fan-sapphire-blue-chrome-pack-of-2/65c6af83bdc3856e20abaf04' },
{ name: 'Eveready 20w Led White Batten Light Pack Of 2 8901691018065', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-20w-led-white-batten-light-pack-of-2-8901691018065/65c6ba10bdc3854608abaf13' },
{ name: 'Fisher Hawk Trimmer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fisher-hawk-trimmer/65c6a87abdc3851486abaefa' },
{ name: 'Lifelong Llcmk901 Caffe Drip 5 Cup Coffee Maker Water Level Indicator 600wborosilicate Glass 750ml Carafe Jar Detachable Coffee Filter Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llcmk901-caffe-drip-5-cup-coffee-maker-water-level-indicator-600wborosilicate-glass-750ml-carafe-jar-detachable-coffee-filter-black/65c6abffbdc3856cf4abaeff' },
{ name: 'Go Desi Crushed Peanut Chikki Jar No Added Preservatives And Colours Gajak Sweets Gazak 50 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-crushed-peanut-chikki-jar-no-added-preservatives-and-colours-gajak-sweets-gazak-50-pieces/65c6b68cbdc3852644abaf0e' },
{ name: 'Tosaa Tea Sugar Coffee Container Set Of 3 Kitchen Counter Top Airtight Storage Canister With Lids Gray 800ml Plastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tosaa-tea-sugar-coffee-container-set-of-3-kitchen-counter-top-airtight-storage-canister-with-lids-gray-800ml-plastic/65c6b307bdc3853d0dabaf09' },
{ name: 'Evm 25 Sata Ssd Casing Usb 30 Portable External Hard Drivessd Transparent Case Cover Fast Transfer Speeds Upto 5gbps On The Go Storage Data Backup Esc Tp01', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evm-25-sata-ssd-casing-usb-30-portable-external-hard-drivessd-transparent-case-cover-fast-transfer-speeds-upto-5gbps-on-the-go-storage-data-backup-esc-tp01/65c6a4f6bdc385d4d6abaef5' },
{ name: 'Park Avenue Regal No Gas Premium Perfume For Men Long Lasting Fragrance 130ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-regal-no-gas-premium-perfume-for-men-long-lasting-fragrance-130ml/65c6bd94bdc3856b84abaf18' },
{ name: 'Honeywell Moxie V10 Bluetooth Wireless On Ear Headphones With Mic With Up To 12 Hours Playtime Foldable High Bass 50 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/honeywell-moxie-v10-bluetooth-wireless-on-ear-headphones-with-mic-with-up-to-12-hours-playtime-foldable-high-bass-50-black/65c69dedbdc385e458abaeeb' },
{ name: 'Sugar Free Green Stevia 300 Pellets 100 Plant Based Natural Sweetener 100 Natural Meethi Tulsi Stevia Leaves Sweet Like Sugar But With Zero Calories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-free-green-stevia-300-pellets-100-plant-based-natural-sweetener-100-natural-meethi-tulsi-stevia-leaves-sweet-like-sugar-but-with-zero-calories/65c6a172bdc385d7a7abaef0' },
{ name: 'Nippo Thor Leakproof Alkaline Aa Batteries 15v Pack Of 10 Longer Lasting Batteries Guaranteed 15 Months In Remote Non Rechargeable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nippo-thor-leakproof-alkaline-aa-batteries-15v-pack-of-10-longer-lasting-batteries-guaranteed-15-months-in-remote-non-rechargeable/65c69a69bdc3852364abaee6' },
{ name: 'Borosil Volcano 13 Fin Ofr 3 Power Heating Mode 2900w Oil Filter Filled Radiator Room Heater Noiseless Operation Ptc Fan 2 Years Warranty Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/borosil-volcano-13-fin-ofr-3-power-heating-mode-2900w-oil-filter-filled-radiator-room-heater-noiseless-operation-ptc-fan-2-years-warranty-black/65c696e5bdc3857326abaee1' },
{ name: 'Black Olive Vegetable And Cheese Grater With Container Cheese Grater With Handle Graters', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/black-olive-vegetable-and-cheese-grater-with-container-cheese-grater-with-handle-graters/65c688d3bdc385fae9abaec7' },
{ name: 'T Topline Magic Paper Sheet Art Book And Craft Paper Rainbow 10 Pages Scratch Book Return Gifts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/t-topline-magic-paper-sheet-art-book-and-craft-paper-rainbow-10-pages-scratch-book-return-gifts-/65c68c58bdc385ef12abaecc' },
{ name: 'Eclet Set Of 3 Double Sided Self Adhesive Acrylic Foam Mounting Tape', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eclet-set-of-3-double-sided-self-adhesive-acrylic-foam-mounting-tape-/65c681cbbdc38553d4abaebd' },
{ name: 'Kratos Thump Wired Earphones Powerful Bass Hd Sound Quality Earphones Tangle Free Cable Comfortable In Ear Fit With Mic 35 Mm Jack Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kratos-thump-wired-earphones-powerful-bass-hd-sound-quality-earphones-tangle-free-cable-comfortable-in-ear-fit-with-mic-35-mm-jack-black/65c68fdcbdc385be72abaed1' },
{ name: 'Lloyd 80 Kg Semi Automatic Top Load Washing Machine Glwms80ddgac Dark Grey Swift Dry', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lloyd-80-kg-semi-automatic-top-load-washing-machine-glwms80ddgac-dark-grey-swift-dry/65c69360bdc3850328abaedc' },
{ name: 'Floraware Food Strainer Colander Fruit Basket Pasta Strainer Vegetable Strainer Kitchen Sieve Washing Bowl Unbreakablemulticolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/floraware-food-strainer-colander-fruit-basket-pasta-strainer-vegetable-strainer-kitchen-sieve-washing-bowl-unbreakablemulticolor/65c6854fbdc38543c9abaec2' },
{ name: 'Morphy Richards At 200 2 Slice Pop Up Toaster With Dust Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/morphy-richards-at-200-2-slice-pop-up-toaster-with-dust-cover/65c67e46bdc38569ebabaeb8' },
{ name: 'Gizga Essentials Fabric Laptop Bag Sleeve Case Cover Pouch For 141 Laptops Macbook Premium Protective Felt Material Ultra Light Easy To Carry Office Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-fabric-laptop-bag-sleeve-case-cover-pouch-for-141-laptops-macbook-premium-protective-felt-material-ultra-light-easy-to-carry-office-bag/65c6773ebdc3851515abaeae' },
{ name: 'Redgear Refurbished Shadow Amulet Mechanical Keyboard With Clicky Blue Switch Rainbow Led Modes Wind Usb A', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redgear-refurbished-shadow-amulet-mechanical-keyboard-with-clicky-blue-switch-rainbow-led-modes-wind-usb-a/65c67ac2bdc3858213abaeb3' },
{ name: 'Cortina 1 Piece Leaf Design Panel Eyelet Polyester Window Curtain 5 Feet Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-1-piece-leaf-design-panel-eyelet-polyester-window-curtain-5-feet-pink/65c673b9bdc385ccdfabaea9' },
{ name: 'Nivea Nourishing Body Milk 600ml Body Lotion 48 H Moisturization With 2x Almond Oil Smooth And Healthy Looking Skin For Very Dry Skin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-nourishing-body-milk-600ml-body-lotion-48-h-moisturization-with-2x-almond-oil-smooth-and-healthy-looking-skin-for-very-dry-skin/65c67035bdc3859eb9abaea4' },
{ name: 'Lotus Botanicals Skin Brightening Night Cream Vitamin C Silicon Chemical Free All Skin Types 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-botanicals-skin-brightening-night-cream-vitamin-c-silicon-chemical-free-all-skin-types-50g/65c66cb0bdc3857cf2abae9f' },
{ name: 'Kelloggs Multigrain Chocos Moons Stars 12kg High In Calcium Proteinessential Vitaminsiron Immuno Nutrients Source Of Fibre Breakfast Cereal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-multigrain-chocos-moons-stars-12kg-high-in-calcium-proteinessential-vitaminsiron-immuno-nutrients-source-of-fibre-breakfast-cereal/65c65412bdc385388babae7c' },
{ name: 'Digno Refillable White Board Marker Pen Box Of 10 Red Non Toxic Low Odour Extra Smooth Writing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/digno-refillable-white-board-marker-pen-box-of-10-red-non-toxic-low-odour-extra-smooth-writing/65c66223bdc38508f1abae90' },
{ name: 'Toymagic Animal Themed Claysoft Water Based Fundough With 2 In 1 Moulding Lid Pack Of 12 Tubs 25gm Eachfun With Doughnon Toxickids 3art Craft Activitybirthday Return Giftsmade In India', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toymagic-animal-themed-claysoft-water-based-fundough-with-2-in-1-moulding-lid-pack-of-12-tubs-25gm-eachfun-with-doughnon-toxickids-3art-craft-activitybirthday-return-giftsmade-in-india/65c65b1bbdc38560d7abae86' },
{ name: 'Kelloggs Muesli Nuts Delight 1000g 12 In 1 Power Breakfast Indias No 1 Muesli Multigrain Breakfast Cereal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-muesli-nuts-delight-1000g-12-in-1-power-breakfast-indias-no-1-muesli-multigrain-breakfast-cereal/65c6508ebdc38519ebabae77' },
{ name: 'Bagrrys Quinoa 1kg Diet Food Cereal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bagrrys-quinoa-1kg-diet-food-cereal/65c64d09bdc3851f77abae72' },
{ name: 'Faber Castell Tri Grip Synth Hair Flat Size 5 Pck 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faber-castell-tri-grip-synth-hair-flat-size-5-pck-10/65c65e9fbdc385f02dabae8b' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round 500 Ml Set Of 12 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-500-ml-set-of-12-red/65c6692cbdc3853c60abae9a' },
{ name: 'Crompton Energion Hyperjet 1200mm Bldc Ceiling Fan With Remote Control High Air Delivery Energy Saving 2 Year Warranty Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-energion-hyperjet-1200mm-bldc-ceiling-fan-with-remote-control-high-air-delivery-energy-saving-2-year-warranty-ivory/65c665a8bdc3854d76abae95' },
{ name: 'Bambalio Bmc 006 Modelling Clay With 3 Moulds Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bambalio-bmc-006-modelling-clay-with-3-moulds-pack-of-3/65c65796bdc385ada2abae81' },
{ name: 'Wonder Homeware Alexa 9 Multipurpose Tokri', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-alexa-9-multipurpose-tokri/65c64600bdc3856f4cabae68' },
{ name: 'Wonder Homeware Prime Alexa 9 Multipurpose Tokri', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-prime-alexa-9-multipurpose-tokri/65c6427cbdc3851970abae63' },
{ name: 'Lakme Blush Glow Pomegranate Sheet Mask Soothing Hydrating 25 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-blush-glow-pomegranate-sheet-mask-soothing-hydrating-25-ml/65c64985bdc3854647abae6d' },
{ name: 'Park Avenue Premium Mens Soaps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-premium-mens-soaps/65c6346abdc3859045abae4e' },
{ name: 'Himalaya Herbals Cocoa Butter Intensive Body Lotion 400ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-herbals-cocoa-butter-intensive-body-lotion-400ml/65c630e495dd3f29aa935331' },
{ name: 'Amazon Basics Pincers 22 Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-pincers-22-set-of-2/65c62d5f95dd3fac3d93532c' },
{ name: 'Fastrack Mens Gradient Pilot Sunglasses', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-mens-gradient-pilot-sunglasses/65c6265695dd3f8b34935322' },
{ name: 'Fastrack M230bu1 From', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-m230bu1-from/65c629db95dd3f8650935327' },
{ name: 'Zebster Z Cc102p Usb To Type C Cable3a Strong Long Lastingwhite', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebster-z-cc102p-usb-to-type-c-cable3a-strong-long-lastingwhite/65c61bc995dd3fe660935313' },
{ name: 'Fastrack Avaitor Pilot Black With Green Lenses Polarised Sunglasses By Visionsindia M221gr1p', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-avaitor-pilot-black-with-green-lenses-polarised-sunglasses-by-visionsindia-m221gr1p/65c622d295dd3f1c9093531d' },
{ name: 'Roff Rainbow Tile Mate To Fill Tile Joints On Floors Walls 5mm Width Tiles 369 Kg Pastel Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/roff-rainbow-tile-mate-to-fill-tile-joints-on-floors-walls-5mm-width-tiles-369-kg-pastel-brown/65c61f4e95dd3ff994935318' },
{ name: 'Visko 319 2 G Clamp Woodwork Building Multipurpose Hand Tool Home Appliance Red Color', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/visko-319-2-g-clamp-woodwork-building-multipurpose-hand-tool-home-appliance-red-color-/65c6184595dd3f3bc493530e' },
{ name: 'Orient Electric Eternal Shine Led Strip 24w Green Colour Without Driver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-eternal-shine-led-strip-24w-green-colour-without-driver/65c606af95dd3f9ce39352f5' },
{ name: 'Fastrack Adult Unisex Square Eyeglasses Blue Prescription Eyewear Frames 51 Ft1147mfp1v', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-adult-unisex-square-eyeglasses-blue-prescription-eyewear-frames-51-ft1147mfp1v/65c60a3395dd3f61ce9352fa' },
{ name: 'Marks Spencer Womens T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/marks-spencer-womens-t-shirt/65c6032a95dd3fc4f99352f0' },
{ name: 'Amazon Basics 1200mm 32w Bldc Energy Saving Ceiling Fan With Remote 5 Star Rated Luster Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-1200mm-32w-bldc-energy-saving-ceiling-fan-with-remote-5-star-rated-luster-brown/65c5ffa695dd3f187b9352eb' },
{ name: 'Visko Tools Steel Visko S006 Doe Spanner 16x17 Adjustable Spanner Multi Tool Self Tightening Wrench Anti Corrosion Durable Repairing Tool Accessory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/visko-tools-steel-visko-s006-doe-spanner-16x17-adjustable-spanner-multi-tool-self-tightening-wrench-anti-corrosion-durable-repairing-tool-accessory/65c5fc2195dd3f09369352e6' },
{ name: 'Proskit Sd 081 S4 Precision Screwdriver 24 X 50mm Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/proskit-sd-081-s4-precision-screwdriver-24-x-50mm-pack-of-1/65c6113c95dd3fef79935304' },
{ name: 'Berger Sanding Disc Sand Paper Polishing Sand Paper Grade 60 Hook And Loop Assorted Sanding Paper Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/berger-sanding-disc-sand-paper-polishing-sand-paper-grade-60-hook-and-loop-assorted-sanding-paper-pack-of-2/65c614c195dd3fe08c935309' },
{ name: 'Saffola Fittify Whey Protein Peanut Butter Unsweetened Super Creamy High Protein No Added Sugar Control Diabetes Keto Diet Peanut Butter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saffola-fittify-whey-protein-peanut-butter-unsweetened-super-creamy-high-protein-no-added-sugar-control-diabetes-keto-diet-peanut-butter/65c60db895dd3f166f9352ff' },
{ name: 'Dixcy Scott Cotton Mens Brief Snug Fit Solid Innerwear Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dixcy-scott-cotton-mens-brief-snug-fit-solid-innerwear-pack-of-3/65c5f89d95dd3f5bf29352e1' },
{ name: 'Puma Womens Regular Woven Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puma-womens-regular-woven-pants/65c5f51895dd3ffdd39352dc' },
{ name: 'Youbella Valentine Non Precious Metal Alloy No Gemstone Jewellery Multi Layered Gold Plated Lock And Key Necklace', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/youbella-valentine-non-precious-metal-alloy-no-gemstone-jewellery-multi-layered-gold-plated-lock-and-key-necklace/65c5f19495dd3f471f9352d6' },
{ name: 'Longway Hot Max 20001000 Watts Room Heater With Adjustable Thermostat Isi Certified White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-hot-max-20001000-watts-room-heater-with-adjustable-thermostat-isi-certified-white/65c5ee0d73efffd6580ca0c0' },
{ name: 'Christy World Womens Jackets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/christy-world-womens-jackets/65c5ea8973effffe5d0ca0bb' },
{ name: 'Beardo Godfather Perfume For Men 50ml Aromatic Spicy Perfume For Men Long Lasting Date Night Fragrance Body Spray For Men Ideal Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-godfather-perfume-for-men-50ml-aromatic-spicy-perfume-for-men-long-lasting-date-night-fragrance-body-spray-for-men-ideal-gift/65c5dffc73effff4c50ca0ac' },
{ name: 'Macroman M Series Mens Polo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/macroman-m-series-mens-polo/65c5d1eaea6a8b460b4d5bbb' },
{ name: 'More More Mens Cottan Blend Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/more-more-mens-cottan-blend-kurta/65c5cae1ea6a8b1e674d5bb1' },
{ name: 'Playr Tennis Turf Balls 100 Rubber Yellow Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/playr-tennis-turf-balls-100-rubber-yellow-pack-of-3/65c5c75dea6a8b06644d5bac' },
{ name: 'Blaupunkt Btw100 Lite Truly Wireless Bluetooth Earbuds I Hd Sound I Gaming Mode I Low Latency I 30h Playtime I Turbovolt Charging I Bt Version 53 I Intuitive Touch Controls Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blaupunkt-btw100-lite-truly-wireless-bluetooth-earbuds-i-hd-sound-i-gaming-mode-i-low-latency-i-30h-playtime-i-turbovolt-charging-i-bt-version-53-i-intuitive-touch-controls-black/65c5c3d8ea6a8bd13b4d5ba7' },
{ name: 'Jaquar Prima 7w B22 Led Bulb 7 Watt Cool White B22 Base Led Bulb Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jaquar-prima-7w-b22-led-bulb-7-watt-cool-white-b22-base-led-bulb-pack-of-4/65c5e70573efff16680ca0b6' },
{ name: 'Corsair Ml140 Led Elite 140mm Magnetic Levitation Blue Led Fan With Airguide Single Packblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/corsair-ml140-led-elite-140mm-magnetic-levitation-blue-led-fan-with-airguide-single-packblack/65c5dc7773efffc6240ca0a7' },
{ name: 'E Cosmos Bottle Cleaning Brush Silicone Long Handle Water Bottle Containers Vase And Glass Bottle Cleaner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-bottle-cleaning-brush-silicone-long-handle-water-bottle-containers-vase-and-glass-bottle-cleaner/65c5e38073efff61aa0ca0b1' },
{ name: 'Cross Effect 32oz Motivational Water Bottle With Time Marker Leak Proof Bpa Free Non Toxic 1l Bottle With Carrying Strap Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cross-effect-32oz-motivational-water-bottle-with-time-marker-leak-proof-bpa-free-non-toxic-1l-bottle-with-carrying-strap-perfect/65c5d8f3ea6a8b09924d5bc5' },
{ name: 'Anchor By Panasonic Smart Air 200 Mm Exhaust Fan For Kitchen Bathroom With Strong Air Suction Rust Proof Body 60w Black Pack Of 2 14983bk13073bk', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anchor-by-panasonic-smart-air-200-mm-exhaust-fan-for-kitchen-bathroom-with-strong-air-suction-rust-proof-body-60w-black-pack-of-2-14983bk13073bk/65c5d56eea6a8b9d1f4d5bc0' },
{ name: 'Hindware Snowcrest Brisa Metallic 1200mm Designer 3 Blade Ceiling Fan With Premium Metallic Finish Dust Resistant Aerodynamic Blades For Even Air Distribution Dark Coffee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-snowcrest-brisa-metallic-1200mm-designer-3-blade-ceiling-fan-with-premium-metallic-finish-dust-resistant-aerodynamic-blades-for-even-air-distribution-dark-coffee/65c5ce66ea6a8beebe4d5bb6' },
{ name: 'Pigeon By Stovekraft Hard Anodised Aluminium Inner Lid Cooker 3 Litre With Induction Base Contura Silver Medium 14547', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-hard-anodised-aluminium-inner-lid-cooker-3-litre-with-induction-base-contura-silver-medium-14547/65c5b5c45ca0cd1911853f33' },
{ name: 'Amazon Brand Inkast Men Casual Trousers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-inkast-men-casual-trousers/65c5c05353888690db3dfe79' },
{ name: 'Hometales Pack Of 5 Polyester Checks Solid Square Cushion Cover 40x40 Cm Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hometales-pack-of-5-polyester-checks-solid-square-cushion-cover-40x40-cm-yellow/65c5ab375ca0cd9f42853f24' },
{ name: 'Casio Edifice Analog Digital Black Dial Mens Watch Ecb 20cl 1adf', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/casio-edifice-analog-digital-black-dial-mens-watch-ecb-20cl-1adf/65c5aebb5ca0cd3f27853f29' },
{ name: 'Himalaya Purifying Neem Face Wash 400 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-purifying-neem-face-wash-400-ml/65c5a7b25ca0cd1be5853f1f' },
{ name: 'Ningen Walnut Face Scrub I Enriched With Almond Green Cardamom And Peach I Dermatologically Tested Paraben Free I Moisturizes Exfoliates And Removes Tanblemishes I 100g White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ningen-walnut-face-scrub-i-enriched-with-almond-green-cardamom-and-peach-i-dermatologically-tested-paraben-free-i-moisturizes-exfoliates-and-removes-tanblemishes-i-100g-white/65c5a42e5ca0cd361c853f1a' },
{ name: 'Lenovo Refurbished L Series 238 605cm Fhd Ips 1920 X 1080 Pixels Ultraslim Monitor 167 Million C Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-refurbished-l-series-238-605cm-fhd-ips-1920-x-1080-pixels-ultraslim-monitor-167-million-c-black/65c5b2405ca0cdc0f7853f2e' },
{ name: 'Boat Imagine Marketing Ltd Type C A550 Type C Stress Resistanttangle Freesturdy Cable With 3a Rapid Charging 480mbps Data Transmission10000 Bends Lifespan Extended 15m Length Mercurial Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-imagine-marketing-ltd-type-c-a550-type-c-stress-resistanttangle-freesturdy-cable-with-3a-rapid-charging-480mbps-data-transmission10000-bends-lifespan-extended-15m-length-mercurial-black/65c5b9495ca0cd73ae853f38' },
{ name: 'Jack Jones Men Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jack-jones-men-casual-pants/65c5bccd5ca0cd61bd853f3d' },
{ name: 'Oraimo Mens Trimmerbeard Trimmer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oraimo-mens-trimmerbeard-trimmer/65c59d255ca0cd4154853f10' },
{ name: 'Crompton 5 Meter Strip Light Cool Day Light 300 Leds Pack Of 10 Without Driver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-5-meter-strip-light-cool-day-light-300-leds-pack-of-10-without-driver/65c599a15ca0cd944b853f0b' },
{ name: 'Perpetual Rajasthani Paintings For Living Room 3d Painting For Wall Decoration Rajasthani Decoration Items Paintings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/perpetual-rajasthani-paintings-for-living-room-3d-painting-for-wall-decoration-rajasthani-decoration-items-paintings/65c5a0aa5ca0cdc3d5853f15' },
{ name: 'Lagirl Lip Mousse Liquid Lipstick Attitude Glossy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lagirl-lip-mousse-liquid-lipstick-attitude-glossy/65c592985ca0cd442b853f01' },
{ name: 'Fastrack Trendies Analog Grey Dial Mens Watch 38040pp04 38040pp0438040pp04', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-trendies-analog-grey-dial-mens-watch-38040pp04-38040pp0438040pp04/65c58b905ca0cd49a3853ef7' },
{ name: 'Cadbury Dairy Milk Chocolate Bar 55 G Maha Pack Pack Of 15', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-dairy-milk-chocolate-bar-55-g-maha-pack-pack-of-15/65c5880b5ca0cd721e853ef2' },
{ name: 'House Wrap Aluminium Foil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/house-wrap-aluminium-foil/65c5961d5ca0cd4491853f06' },
{ name: 'Fitbox Sports Heavy Duty Door Anchor Great', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitbox-sports-heavy-duty-door-anchor-great/65c58f145ca0cdc8a3853efc' },
{ name: 'Educational Math Genius Puzzle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/educational-math-genius-puzzle/65c581035ca0cdf1e9853ee8' },
{ name: 'Crystal Stainless Steel Casserole With Glass Lid 180 Mm 25 Litre Induction Base Sandwich Bottom Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crystal-stainless-steel-casserole-with-glass-lid-180-mm-25-litre-induction-base-sandwich-bottom-silver/65c584875ca0cdbe8b853eed' },
{ name: 'Fitspire Super Gold Bcaa Supplement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-super-gold-bcaa-supplement/65c579fa5ca0cd02dc853ed6' },
{ name: 'Fitspire Energy Bar 100 Vegan With Redberry Flavour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitspire-energy-bar-100-vegan-with-redberry-flavour/65c57d7e5ca0cd7218853ee3' },
{ name: 'Trustbasket 7 Pcs Durable Mud Finger Multi Purpose Plant Caregarden Tool Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/trustbasket-7-pcs-durable-mud-finger-multi-purpose-plant-caregarden-tool-kit/65c56f6be446ed62832f3b88' },
{ name: 'Marks Spencer Womens Solid Regular Fit Top', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/marks-spencer-womens-solid-regular-fit-top/65c57675a19b3445d8cb47da' },
{ name: 'Aristo Aroma Toilet Brush With Stand Color May Vary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristo-aroma-toilet-brush-with-stand-color-may-vary/65c572efe446ed091f2f3b8d' },
{ name: 'Ewa Eco Wood Art 3d Wooden Puzzle For Interior Decoration Lion Head Laser Cut Decoration Wall Decal Diy Wooden Decorative Items', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ewa-eco-wood-art-3d-wooden-puzzle-for-interior-decoration-lion-head-laser-cut-decoration-wall-decal-diy-wooden-decorative-items/65c55dd5e446edbc312f3b6f' },
{ name: 'Fila Womens Pavero W Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fila-womens-pavero-w-sneaker/65c55a51e446ed94032f3b6a' },
{ name: 'Luxor Schneider Lx Max Roller Ball Pen Pack Of 3 Blueblackred Cone Tip 05mm 100 German Technology 1000 Mtrs Writing Length Waterproof Ink Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luxor-schneider-lx-max-roller-ball-pen-pack-of-3-blueblackred-cone-tip-05mm-100-german-technology-1000-mtrs-writing-length-waterproof-ink-ideal/65c556cce446ed56392f3b65' },
{ name: 'Garden Art Solar Path Light 3 Led Outdoor Garden Waterproof Landscape Light Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/garden-art-solar-path-light-3-led-outdoor-garden-waterproof-landscape-light-suitable/65c56862e446ed17022f3b7e' },
{ name: 'Lokza Single Sided Foam Tape Insulation Tape Self Adhesiveweather Stripping', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lokza-single-sided-foam-tape-insulation-tape-self-adhesiveweather-stripping/65c56be7e446ed5d752f3b83' },
{ name: 'Susvij Trending Premium Glossy Black Metal Tic Tac Hair Clips', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/susvij-trending-premium-glossy-black-metal-tic-tac-hair-clips/65c5615ae446edf0922f3b74' },
{ name: 'Urbanmark Mens Regular Fit Round Neck Full Sleeves Printed T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbanmark-mens-regular-fit-round-neck-full-sleeves-printed-t-shirt/65c55348e446ed70092f3b60' },
{ name: 'Size 30 Indian Terrain Mens 4 Pocket Printed Trousers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-30-indian-terrain-mens-4-pocket-printed-trousers/65c54fc4e446ed569d2f3b5b' },
{ name: 'Generic Wire Stripping Tool 8 Inch Self Adjusting Cable Stripper Industry Stranded Wire Cutting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/generic-wire-stripping-tool-8-inch-self-adjusting-cable-stripper-industry-stranded-wire-cutting/65c54c3fe446ed8fad2f3b56' },
{ name: 'Kadio Digital Black Clock', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kadio-digital-black-clock/65c564dee446ed6d242f3b79' },
{ name: 'Newport Women Womens Cotton Trackpants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/newport-women-womens-cotton-trackpants/65c548bbe446edc9d02f3b4a' },
{ name: 'Polycab Aereo Plus 1200 Mm High Speed 1 Star Rated 52 Watt Ceiling Fan With Rust Proof Aluminium Blades And 3 Years Warranty Matt Black Choclate Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-aereo-plus-1200-mm-high-speed-1-star-rated-52-watt-ceiling-fan-with-rust-proof-aluminium-blades-and-3-years-warranty-matt-black-choclate-gold/65c54537e446ed020e2f3b45' },
{ name: 'Denver Acne Clear Face Wash 100gm Pack Of 2 Remove Acne Lighten Dark Spots Face Gel For Men Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/denver-acne-clear-face-wash-100gm-pack-of-2-remove-acne-lighten-dark-spots-face-gel-for-men-suitable/65c53aaae446ed7dd22f3b36' },
{ name: 'Chromozome Men Cotton Rib Brief Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chromozome-men-cotton-rib-brief-pack-of-3/65c53e2ee446ed6b1b2f3b3b' },
{ name: 'Rivine Organic Vermicompost Fertilizer Manure', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rivine-organic-vermicompost-fertilizer-manure/65c5301de446ed5f322f3b27' },
{ name: 'Ajanta Quartz Plastic Silent Sweep Movement Vintage Wall Clock 14 Inch Wall Clock Oak Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ajanta-quartz-plastic-silent-sweep-movement-vintage-wall-clock-14-inch-wall-clock-oak-multicolour/65c52c98e446ed45e92f3b22' },
{ name: 'Soopii Pd10 20w Wall Charger With Fast Charging', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/soopii-pd10-20w-wall-charger-with-fast-charging/65c533a1e446ed9c132f3b2c' },
{ name: 'Clovia Womens Cotton Top Shorts Set With Panels', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-top-shorts-set-with-panels/65c541b2e446ed93692f3b40' },
{ name: 'Maxima Max Pro X1 Smartwatch Premium 14 Hd Display Of 500 Nits With 10 Days Battery Life 100 Watch Faces Sleep Spo2 Monitoring Social Media Alerts Multiple Exercise Modes Army Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxima-max-pro-x1-smartwatch-premium-14-hd-display-of-500-nits-with-10-days-battery-life-100-watch-faces-sleep-spo2-monitoring-social-media-alerts-multiple-exercise-modes-army-green/65c5258d5af3705debdc8aea' },
{ name: 'Amazon Brand Solimo Plastic Storage Containers With Sliding Mouth Set Of 6 1100ml Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-plastic-storage-containers-with-sliding-mouth-set-of-6-1100ml-blue/65c53725e446edaf582f3b31' },
{ name: 'Lifelong 9 Litres 1100 W Oven Toaster Griller Otg Oven', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-9-litres-1100-w-oven-toaster-griller-otg-oven/65c52913f719507655e11c7d' },
{ name: 'Saf Set Of 5 Buddha Uv Textured Home Decorative Gift Item Mdf Panel Painting 18 Inch X 42 Inch Sanfpnl31195', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saf-set-of-5-buddha-uv-textured-home-decorative-gift-item-mdf-panel-painting-18-inch-x-42-inch-sanfpnl31195/65c522095af370ae9edc8ae5' },
{ name: 'Compaq Q Watch Dimension Series With 181 Hd Display Bt Calling Instant Messaging Notification Blood Pressure Monitoring Heart Rate Monitoring Wireless Chargingsmartwatch Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/compaq-q-watch-dimension-series-with-181-hd-display-bt-calling-instant-messaging-notification-blood-pressure-monitoring-heart-rate-monitoring-wireless-chargingsmartwatch-black/65c51afd7c5c00c17567d220' },
{ name: 'Dell Smartchoice 14 Laptop 12th Gen Intel Core I5 1235u8gb Ddr4512gb Ssdintel Uhd Graphics140 3556cm Fhdwin 11 Mso21spill Resistant Keyboard15 Month Mcafeeblackthin Light 148kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-smartchoice-14-laptop-12th-gen-intel-core-i5-1235u8gb-ddr4512gb-ssdintel-uhd-graphics140-3556cm-fhdwin-11-mso21spill-resistant-keyboard15-month-mcafeeblackthin-light-148kg/65c51e8491cd52afc2eb2aa4' },
{ name: 'Park Avenue Good Morning Grooming Kit Combo Of 7in 1 Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-good-morning-grooming-kit-combo-of-7in-1-combo/65c517797c5c00cc4067d21b' },
{ name: 'Portronics Bubble Multimedia Wireless Keyboard 24 Ghz Bluetooth 50 Connectivity Noiseless Experience Compact Size Shortcut Keys Functionblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-bubble-multimedia-wireless-keyboard-24-ghz-bluetooth-50-connectivity-noiseless-experience-compact-size-shortcut-keys-functionblack/65c5025f7c5c0056af67d1eb' },
{ name: 'Amazon Brand Solimo Plastic Storage Container Set 16 Pieces Edge Pattern Royal Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-plastic-storage-container-set-16-pieces-edge-pattern-royal-blue/65c4fb577c5c001cef67d1e1' },
{ name: 'Samsung Galaxy Watch5 Bluetooth 44 Mm Silver Compatible With Android Only', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-watch5-bluetooth-44-mm-silver-compatible-with-android-only/65c4f7d27c5c0074e467d1d4' },
{ name: 'Evereve 100 Us Cotton Ultra Thin Rash Free Sanitary Pads Combo 12 Pads 3 Heavy 5 Medium 4 Light Flow Pads Unscented Leakproof Zero Toxin Dermatologically Tested Individual Disposal Bags', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evereve-100-us-cotton-ultra-thin-rash-free-sanitary-pads-combo-12-pads-3-heavy-5-medium-4-light-flow-pads-unscented-leakproof-zero-toxin-dermatologically-tested-individual-disposal-bags/65c4f44dd33e9b7bd7620e05' },
{ name: 'Park Avenue Beer Shampoo 650ml 670g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-beer-shampoo-650ml-670g/65c513f57c5c001db267d216' },
{ name: 'Go Desi Dried Chatpata Amla Candy 300g Indian Gooseberry Fruit Snacks Mouth Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-dried-chatpata-amla-candy-300g-indian-gooseberry-fruit-snacks-mouth-freshener/65c50cec7c5c00516467d1fe' },
{ name: 'Havells Fd5006 Trimmer Easy And Smooth Hair Removal Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-fd5006-trimmer-easy-and-smooth-hair-removal-ideal/65c510717c5c00acee67d211' },
{ name: 'Boat Type C A550 Stress Resistant Tangle Free Sturdy Cable With 3a Rapid Charging 480mbps Data Transmission 10000 Bends Lifespan And Extended 15m Length Martian Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-type-c-a550-stress-resistant-tangle-free-sturdy-cable-with-3a-rapid-charging-480mbps-data-transmission-10000-bends-lifespan-and-extended-15m-length-martian-red/65c4f0c736020c89105871da' },
{ name: 'Dukes Waffy Dark Vanilla Flavoured Rolls Tin 300g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dukes-waffy-dark-vanilla-flavoured-rolls-tin-300g/65c509687c5c00419067d1f5' },
{ name: 'Portronics Konnect Cl 20w Por 1067 Type C To 8 Pin Usb 12m Cable With Power Delivery 3a Quick Charge Support Nylon Braided For All Type C And 8 Pin Devices Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-konnect-cl-20w-por-1067-type-c-to-8-pin-usb-12m-cable-with-power-delivery-3a-quick-charge-support-nylon-braided-for-all-type-c-and-8-pin-devices-green/65c505e47c5c007b8467d1f0' },
{ name: 'Portronics Sounddrum 1 10w Tws Portable Bluetooth 53 Speaker With Powerful Bass Inbuilt Fm Type C Charging Cable Includedblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-sounddrum-1-10w-tws-portable-bluetooth-53-speaker-with-powerful-bass-inbuilt-fm-type-c-charging-cable-includedblack/65c4fedb7c5c00831867d1e6' },
{ name: 'Amazon Brand Symbol Women Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-women-sweatshirt/65c4dbad36020c6f575871bc' },
{ name: 'Hp K160 Wireless Keyboardquick Comfy Accurate12 Fn Shortcut Keysplug And Play Usb 3 Years Warranty Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-k160-wireless-keyboardquick-comfy-accurate12-fn-shortcut-keysplug-and-play-usb-3-years-warranty-black/65c4d828adbd4a4425579d5b' },
{ name: 'Ecolink Guardian Chief 4 Way Extension Boardcord Multi Socket Spike Guard 6a Extension Board With Led Indicator Cable Length 15 Meter Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-guardian-chief-4-way-extension-boardcord-multi-socket-spike-guard-6a-extension-board-with-led-indicator-cable-length-15-meter-pack-of-1/65c4d4a172d40232e9033c43' },
{ name: 'Letsshave Pro 2 Disposable Mini Kit Shaving Razor For Men Stainless Steel Shaving Blade Made In South Korea Pack 5 Transparent Shave Gel 30ml Travel Pouch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/letsshave-pro-2-disposable-mini-kit-shaving-razor-for-men-stainless-steel-shaving-blade-made-in-south-korea-pack-5-transparent-shave-gel-30ml-travel-pouch/65c4cd9972d402129f033c39' },
{ name: 'Zandu Chyavanprash Avaleha Made With Jaggery Gur 900g No Added Sugar 2x Immunity Increases Strength And Stamina', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zandu-chyavanprash-avaleha-made-with-jaggery-gur-900g-no-added-sugar-2x-immunity-increases-strength-and-stamina/65c4d11d72d4021c49033c3e' },
{ name: 'Qips Waist Bag Waist Pack Travel Zip Pouch Sports Bag Bum Bag Fanny Bag Fanny Pack Cycling Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qips-waist-bag-waist-pack-travel-zip-pouch-sports-bag-bum-bag-fanny-bag-fanny-pack-cycling-bag/65c4ca1472d40268ca033c34' },
{ name: 'Kesh King Ayurvedic Anti Hairfall Shampoo Reduces Hairfall 21 Natural Ingredients No Paraben No Silicon With The Goodness Of Aloe Vera Bhringraja And Amla', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesh-king-ayurvedic-anti-hairfall-shampoo-reduces-hairfall-21-natural-ingredients-no-paraben-no-silicon-with-the-goodness-of-aloe-vera-bhringraja-and-amla/65c4c69072d4020b46033c2f' },
{ name: 'Crompton Ds 500 Blk 500 Watt Mixer Grinder With Powertron Motor Motor Vent X Technology 3 Stainless Steel Jars Black Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-ds-500-blk-500-watt-mixer-grinder-with-powertron-motor-motor-vent-x-technology-3-stainless-steel-jars-black-grey/65c4bf87a0fc5f8b903c518a' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round Set Of 12 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-set-of-12-red/65c4b4f733ba302d6d99af99' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round Set Of 24 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-set-of-24-red/65c4b17333ba30742199af94' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round Set Of 18 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-set-of-18-blue/65c4adef33ba3064f799af8f' },
{ name: 'Cp Plus 24mp Ir Bullet Outdoor Security Camera 36mm Fixed Lens Max 2530fps At 24mp Dwdr Daynight Icr Ir Range Of 20 Mtrs Smart Ir Support Built In Mic Cp Urc Tc24pl2c V3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cp-plus-24mp-ir-bullet-outdoor-security-camera-36mm-fixed-lens-max-2530fps-at-24mp-dwdr-daynight-icr-ir-range-of-20-mtrs-smart-ir-support-built-in-mic-cp-urc-tc24pl2c-v3/65c4c30b72d402100c033c2a' },
{ name: 'Zest 4 Toyz Baby Piano Multi Function Electronic Keyboard Piano Musical Toys', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zest-4-toyz-baby-piano-multi-function-electronic-keyboard-piano-musical-toys/65c4bc02a0fc5f129d3c5184' },
{ name: 'Toypoint 2 In 1 Small Piano Xylophone Musical Toy For Kids Age 3 With 8 Keys 2 Sticks Keyboard Xylophone Piano Preschool Musical Learning Instruments Gift Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toypoint-2-in-1-small-piano-xylophone-musical-toy-for-kids-age-3-with-8-keys-2-sticks-keyboard-xylophone-piano-preschool-musical-learning-instruments-gift-toy/65c4b87c33ba307d2199af9e' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round 750 Ml Set Of 12 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-750-ml-set-of-12-blue/65c4aa6a33ba302a2499af8a' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round 500 Ml Set Of 3 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-500-ml-set-of-3-red/65c4a6e633ba309db199af85' },
{ name: 'Amazon Brand Symactive Leakproof Unbreakable Fruit Infuser Sports Sipper Water Bottle 700 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symactive-leakproof-unbreakable-fruit-infuser-sports-sipper-water-bottle-700-ml/65c4a36233ba3043ef99af80' },
{ name: 'Disano Peanut Butter All Natural Crunchy Unsweetened 30 Protein Gluten Free Non Gmo 1kg Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/disano-peanut-butter-all-natural-crunchy-unsweetened-30-protein-gluten-free-non-gmo-1kg-pack-of-2/65c49fdd10fac120712632c9' },
{ name: 'Anker Usb C Hub Powerexpand 5 In 1 Pd Hub 1 X 4k Hdmi 2 X Type C Pd Ports 2 X Usb 30 Ports 100w Pd Pass Through Charging 5 Gbps Data Transfer Speed Anodized Aluminium Body', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anker-usb-c-hub-powerexpand-5-in-1-pd-hub-1-x-4k-hdmi-2-x-type-c-pd-ports-2-x-usb-30-ports-100w-pd-pass-through-charging-5-gbps-data-transfer-speed-anodized-aluminium-body/65c49c577fea125ff0a9af41' },
{ name: 'Cera F1002201 Angle Cock With Wall Flange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cera-f1002201-angle-cock-with-wall-flange/65c48e457fea12d46fa9af2d' },
{ name: 'Amazonbasics 48 Amp24w Dual Usb Car Charger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonbasics-48-amp24w-dual-usb-car-charger/65c48ac17fea126b23a9af28' },
{ name: 'Crompton Dyna Ray 12w Round B22 Led Cool Day Light Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-dyna-ray-12w-round-b22-led-cool-day-light-pack-of-2/65c498d27fea12874da9af3c' },
{ name: 'Amazon Basics Electric Automatic Toothbush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-electric-automatic-toothbush/65c480347fea1252c5a9af19' },
{ name: 'For Hdfc Card Emi Iffalcon 108 Cm 43 Inches 4k Ultra Hd Smart Led Google Tv Iff43u62 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-hdfc-card-emi-iffalcon-108-cm-43-inches-4k-ultra-hd-smart-led-google-tv-iff43u62-black/65c483b87fea1292baa9af1e' },
{ name: 'Jaquline Usa Hawaii Amor Edp 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jaquline-usa-hawaii-amor-edp-100ml/65c47cb07fea1232daa9af14' },
{ name: 'Size L Levis Mens Cotton Style 002 Classic Regular Solid Brief Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-levis-mens-cotton-style-002-classic-regular-solid-brief-pack-of-2/65c4954e7fea12fd01a9af37' },
{ name: 'Dukes Utsav Danish Butter Cookies 400g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dukes-utsav-danish-butter-cookies-400g/65c4873d7fea126983a9af23' },
{ name: 'Lifelong Mtb 26t Gear Cycle For Men And Women 21 Speed Mountain Bike Micro Shifter Gear Cycles Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-mtb-26t-gear-cycle-for-men-and-women-21-speed-mountain-bike-micro-shifter-gear-cycles-suitable/65c491ca7fea122460a9af32' },
{ name: 'Philips 20w Ujjwal Led Batten Led Tubelight', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-20w-ujjwal-led-batten-led-tubelight/65c4792b7fea12ba7ba9af0f' },
{ name: 'Zebronics Companion 200 Wireless Combo With Silent Operation Mouse Full Size Keyboard 1600 Dpi Integrated Multimedia Onoff Power Saving Mode 24ghz Nano Receiver And Plug Play Usage White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-companion-200-wireless-combo-with-silent-operation-mouse-full-size-keyboard-1600-dpi-integrated-multimedia-onoff-power-saving-mode-24ghz-nano-receiver-and-plug-play-usage-white/65c475a77fea12f1aba9af0a' },
{ name: 'Rage Coffee Original Coffee Blend 100 Pure Arabica Beans Instant Coffee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rage-coffee-original-coffee-blend-100-pure-arabica-beans-instant-coffee/65c472237fea12133da9af05' },
{ name: 'Kubra Kb 134 1400 Watts Hair Dryer With Automatic Overheat Protection With One Detachable Nozzle Purple', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kubra-kb-134-1400-watts-hair-dryer-with-automatic-overheat-protection-with-one-detachable-nozzle-purple/65c467957fea12152ea9aef6' },
{ name: 'Panasonic 9w Led Bulb Led Bulb 9 Watt With B22 Base 4kv Surge Protection 9 Watt Bulb Cool Day Light Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panasonic-9w-led-bulb-led-bulb-9-watt-with-b22-base-4kv-surge-protection-9-watt-bulb-cool-day-light-pack-of-4/65c46e9e7fea124b7fa9af00' },
{ name: 'Archies Diwali Lights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-diwali-lights/65c45d087fea124697a9aee7' },
{ name: 'Verymiss 8 To 8 Lip Color Ruby Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/verymiss-8-to-8-lip-color-ruby-red/65c459847fea122b94a9aee2' },
{ name: 'Crucial Ram 16gb Ddr4 3200 Mhz Cl22 Laptop Memory Ct16g4sfra32a', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crucial-ram-16gb-ddr4-3200-mhz-cl22-laptop-memory-ct16g4sfra32a/65c4608d7fea128168a9aeec' },
{ name: 'Safari Regloss Antiscratch 55 Cms Polycarbonate Black Cabin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/safari-regloss-antiscratch-55-cms-polycarbonate-black-cabin/65c4527b7fea12ae64a9aed8' },
{ name: 'Verymiss Professional Nail Lacquer Gel Finish Go On Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/verymiss-professional-nail-lacquer-gel-finish-go-on-green/65c456007fea127c89a9aedd' },
{ name: 'Nippo Thor Leakproof Alkaline Aaa Batteries 15 V Pack Of 10 Longer Lasting Batteries Guaranteed 15 Months In Remote Non Rechargeable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nippo-thor-leakproof-alkaline-aaa-batteries-15-v-pack-of-10-longer-lasting-batteries-guaranteed-15-months-in-remote-non-rechargeable/65c46b1a7fea12388ea9aefb' },
{ name: 'Surya Atlantic 25 Litre Storage Water Heater Water Geyser White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surya-atlantic-25-litre-storage-water-heater-water-geyser-white/65c464117fea121522a9aef1' },
{ name: 'Beetle Sprinkles 14t Kids Bike 10 Inch Frame Red Single Speed Bike With Steel Frame Chequered Tyres Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beetle-sprinkles-14t-kids-bike-10-inch-frame-red-single-speed-bike-with-steel-frame-chequered-tyres-ideal/65c44ef77fea12b805a9aed3' },
{ name: 'Size 7 Uk Lotto Mens Vertigo Running Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-7-uk-lotto-mens-vertigo-running-shoe/65c447ee7fea12b5f0a9aec9' },
{ name: 'E Cosmos Lunch Box For Kids Lunch Box For Kids 3 Compartment Insulated Lunch Box Tiffin Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-lunch-box-for-kids-lunch-box-for-kids-3-compartment-insulated-lunch-box-tiffin-box/65c44b737fea12786aa9aece' },
{ name: 'Amazon Brand Solimo Premium Alloy Steel Extra Large Cloth Drying Stand White Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-premium-alloy-steel-extra-large-cloth-drying-stand-white-blue/65bc90a8ad9a0c2c1d650862' },
{ name: 'Mode By Red Tape Womens White Round Neck T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mode-by-red-tape-womens-white-round-neck-t-shirt/65bc720d250b9907080d1259' },
{ name: 'Aipl Masking Tape 20 Meter Length 24mm 1 Inch 2 Rolls Per Pack Easy Tear Tape Best', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aipl-masking-tape-20-meter-length-24mm-1-inch-2-rolls-per-pack-easy-tear-tape-best/65bc6e88250b995ff60d1254' },
{ name: 'Crocs Unisex Adult Navypepper Literide 360 Clog 206708 4cc M9w11', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crocs-unisex-adult-navypepper-literide-360-clog-206708-4cc-m9w11/65bc6075250b9922620d1240' },
{ name: 'Wonderchef Sicilia Life Happens Coffee Helps Mug 1 Piece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-sicilia-life-happens-coffee-helps-mug-1-piece/65bc63fa250b9989180d1245' },
{ name: 'T Topline Grip Tape Double Sided Tape Heavy Duty Adhesive Tape Magic Tap1mm Thick X 1m Length', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/t-topline-grip-tape-double-sided-tape-heavy-duty-adhesive-tape-magic-tap1mm-thick-x-1m-length/65bc6b03250b99591d0d124f' },
{ name: 'Size 30 Parx Mens Flat Front Low Rise Tapered Fit Dark Blue Casual Trouser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-30-parx-mens-flat-front-low-rise-tapered-fit-dark-blue-casual-trouser/65bc677e250b99048c0d124a' },
{ name: 'Fastrack Mens 100 Uv Protected Sportysunglasses', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-mens-100-uv-protected-sportysunglasses/65bc5cf0250b998da20d123b' },
{ name: 'For Sbi Credit Card Da Urban Merlion Office Chairhigh Back Mesh Ergonomic Home Office Desk Chair With 3 Years Warranty Adjustable Armrestsadjustable Lumbar Supporttilt Lock Mechanism Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-da-urban-merlion-office-chairhigh-back-mesh-ergonomic-home-office-desk-chair-with-3-years-warranty-adjustable-armrestsadjustable-lumbar-supporttilt-lock-mechanism-grey/65bc55e6250b9956650d1231' },
{ name: 'For Sbi Credit Card Cellbell Tauras Lite C100 Mesh High Back Office Chairstudy Chaircomputer Chairrevolving Chairdesk Chair', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-cellbell-tauras-lite-c100-mesh-high-back-office-chairstudy-chaircomputer-chairrevolving-chairdesk-chair/65bc5261250b99faaa0d122b' },
{ name: 'Lava Probuds N31 Bt In Ear Neckband Firefly Green45 Hrs Playtimeencfast Charge 10min 12hrsipx6 Rating10 Mm Driversbt V53 Pro Game Mode 60ms Low Latencydual Device Pairingwireless', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lava-probuds-n31-bt-in-ear-neckband-firefly-green45-hrs-playtimeencfast-charge-10min-12hrsipx6-rating10-mm-driversbt-v53-pro-game-mode-60ms-low-latencydual-device-pairingwireless/65bc596b250b99577a0d1236' },
{ name: 'Realme Buds Wireless 3 In Ear Bluetooth Headphones30db Anc Spatial Audio136mm Dynamic Bass Driverupto 40 Hours Playback Fast Charging 45ms Low Latency', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-buds-wireless-3-in-ear-bluetooth-headphones30db-anc-spatial-audio136mm-dynamic-bass-driverupto-40-hours-playback-fast-charging-45ms-low-latency/65bc4edb8088ce29379bf45b' },
{ name: 'For Sbi Credit Card Jbl Cinema Sb241 Dolby Digital Soundbar With Wired Subwoofer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-jbl-cinema-sb241-dolby-digital-soundbar-with-wired-subwoofer/65bc4b568088ce1b409bf456' },
{ name: 'For Sbi Credit Card Iffalcon 108 Cm 43 Inches 4k Ultra Hd Smart Led Google Tv Iff43u62 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-iffalcon-108-cm-43-inches-4k-ultra-hd-smart-led-google-tv-iff43u62-black/65bc47d18088ce8df99bf451' },
{ name: 'Pigeon By Stovekraft Vin 750 W Mixer Grinder With 4 Multipurpose Jars', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-vin-750-w-mixer-grinder-with-4-multipurpose-jars/65bc444d8088cee0119bf44c' },
{ name: 'For Sbi Credit Card Gopro Hero10 Black Waterproof Action Camera With Front Lcd And Touch Rear Screens 53k60 Ultra Hd Video Optical 1x And Digital 4x 23mp Photos 1 Year Intl Warranty 1 Year In Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-gopro-hero10-black-waterproof-action-camera-with-front-lcd-and-touch-rear-screens-53k60-ultra-hd-video-optical-1x-and-digital-4x-23mp-photos-1-year-intl-warranty-1-year-in-warranty/65bc36374d28314fd4540155' },
{ name: 'Bevzilla 24 Flavoured Coffee Sachets Frother Coffee Kit 24 X 2 Gram Sachets Makes 24 Cups 6 Sachets Each Flavour Turkish Hazelnut Colombian Gold French Vanilla English Butterscotch 100 Arabica Coffee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bevzilla-24-flavoured-coffee-sachets-frother-coffee-kit-24-x-2-gram-sachets-makes-24-cups-6-sachets-each-flavour-turkish-hazelnut-colombian-gold-french-vanilla-english-butterscotch-100-arabica-coffee/65bc40c88088ce50dd9bf43a' },
{ name: 'Qraa Men Look Good Kit 330 G Pack Of Face Wash Styling Gel Scrub Beard Oil Premium Gift Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qraa-men-look-good-kit-330-g-pack-of-face-wash-styling-gel-scrub-beard-oil-premium-gift-box/65bc2f2e4d28312e8154014b' },
{ name: 'The Man Company Specially Curated Perfume Gift Set For Men 4x20ml Polo Woods Collection Premium Long Lasting Fragrance Luxury Eau De Parfum Gift Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-man-company-specially-curated-perfume-gift-set-for-men-4x20ml-polo-woods-collection-premium-long-lasting-fragrance-luxury-eau-de-parfum-gift-set/65bc2ba94d2831a1dc540146' },
{ name: 'Impower Self Defence Pepper Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/impower-self-defence-pepper-spray/65bc32b24d283162a8540150' },
{ name: 'Amazon Brand Presto Disinfectant Liquid Minty Blue 500 Ml X 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-disinfectant-liquid-minty-blue-500-ml-x-3/65bc3d438088cef9429bf434' },
{ name: 'Beardo Activated Charcoal Anti Pollution Face Scrub 100 Gm Deep Pore Cleansing Removes Dirt Impurities Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-activated-charcoal-anti-pollution-face-scrub-100-gm-deep-pore-cleansing-removes-dirt-impurities-suitable/65bc39bc4d2831313c54015a' },
{ name: 'Kinder Mix Assortment Box Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kinder-mix-assortment-box-blue/65bc28244d2831d64d540141' },
{ name: 'For Sbi Credit Card Samsung Galaxy Watch4 Bluetooth40 Cm Black Compatible With Android Only', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-samsung-galaxy-watch4-bluetooth40-cm-black-compatible-with-android-only/65bc249f4d28314b8a54013c' },
{ name: 'Tukzer Active Stylus Pen Ipad Pencil With Palm Rejection Tilt Sensitivity High Precision', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tukzer-active-stylus-pen-ipad-pencil-with-palm-rejection-tilt-sensitivity-high-precision/65bc211a4d2831a539540136' },
{ name: 'Amazonbasics Usb Wired Ergonomic Gaming Mouse With 7 Programmable Keys 6400 Dpi', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonbasics-usb-wired-ergonomic-gaming-mouse-with-7-programmable-keys-6400-dpi/65bc1d93494a0d1e334f7ca1' },
{ name: 'Ritu Kitchen Tools Combo Kitchen Press And Coconut Scraper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ritu-kitchen-tools-combo-kitchen-press-and-coconut-scraper/65bc1a0e494a0d5f544f7c9c' },
{ name: 'For Sbi Credit Card Asus Vivobook 15 Intel Core I5 12500h 12th Gen 156 3962 Cm Fhd Thin And Light Laptop 16gb512gbwin11office 2021silver17 Kg X1502za Ej542ws', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-asus-vivobook-15-intel-core-i5-12500h-12th-gen-156-3962-cm-fhd-thin-and-light-laptop-16gb512gbwin11office-2021silver17-kg-x1502za-ej542ws/65bc0876494a0d70014f7c83' },
{ name: 'Supples Baby Wet Wipes With Lid Enriched With Aloe Vera 72 Wipespack Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/supples-baby-wet-wipes-with-lid-enriched-with-aloe-vera-72-wipespack-pack-of-4/65bc04f1494a0d1d9a4f7c7e' },
{ name: 'For Sbi Credit Card Lenovo Ideapad Slim 3 Amd Ryzen 3 7320u 156 Inch 396cm Fhd Laptop 8gb512gb Ssdwindows 11office 2021alexa Built In3 Month Game Passarctic Grey162kg 82xq008din', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lenovo-ideapad-slim-3-amd-ryzen-3-7320u-156-inch-396cm-fhd-laptop-8gb512gb-ssdwindows-11office-2021alexa-built-in3-month-game-passarctic-grey162kg-82xq008din/65bc0bfb494a0de8134f7c88' },
{ name: 'For Sbi Credit Card Jbl Flip 6 Wireless Portable Bluetooth Speaker Pro Sound Upto 12 Hours Playtime Ip67 Water Dustproof Partyboost Personalization App Without Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-jbl-flip-6-wireless-portable-bluetooth-speaker-pro-sound-upto-12-hours-playtime-ip67-water-dustproof-partyboost-personalization-app-without-mic-black/65bc1304494a0d7b4a4f7c92' },
{ name: 'For Sbi Credit Card Lenovo Ideapad Slim 3 Amd Ryzen 5 7520u 156 396cm Fhd Laptop 8gb512gb Ssdwin 11office 20211 Year Warrantyalexa Built In3 Month Game Passarctic Grey162kg 82xq008gin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lenovo-ideapad-slim-3-amd-ryzen-5-7520u-156-396cm-fhd-laptop-8gb512gb-ssdwin-11office-20211-year-warrantyalexa-built-in3-month-game-passarctic-grey162kg-82xq008gin/65bc0f7f494a0d72af4f7c8d' },
{ name: 'Dove Mencare Thick Strong 2in1 Shampooconditioner 650 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-mencare-thick-strong-2in1-shampooconditioner-650-ml/65bc1689494a0d543e4f7c97' },
{ name: 'Presto Disinfectant Surfacefloor Cleaner 2 Litre Citruskills 999 Germs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/presto-disinfectant-surfacefloor-cleaner-2-litre-citruskills-999-germs/65bc016d494a0d05984f7c79' },
{ name: 'Realme Narzo 60x 5g Nebula Purple 6gb128gb Storage Up To 2tb External Memory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-narzo-60x-5g-nebula-purple-6gb128gb-storage-up-to-2tb-external-memory-/65bbfde7494a0db8144f7c73' },
{ name: 'For Sbi Credit Card Emi Samsung 138 Cm 55 Inches Crystal 4k Neo Series Ultra Hd Smart Led Tv Ua55aue65akxxl Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-samsung-138-cm-55-inches-crystal-4k-neo-series-ultra-hd-smart-led-tv-ua55aue65akxxl-black/65bbfa61e48dc33e4a46c1dc' },
{ name: 'Engage Moments Luxury Perfume Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-moments-luxury-perfume-gift/65bbf6dce48dc3d6b746c1d7' },
{ name: 'Lays Potato Chips American Style Cream Onion Flavour 90g100g104gpack Of 3 Weight May Vary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lays-potato-chips-american-style-cream-onion-flavour-90g100g104gpack-of-3-weight-may-vary/65bbf357e48dc391f046c1d2' },
{ name: 'Size M Cloth Theory Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-cloth-theory-mens-regular-fit-t-shirt/65bbefd3e48dc3e8c046c1cd' },
{ name: 'Biotique Tea Tree Skin Clearing Mattifying Facial Toner For Normal To Oily Skin Face Toner 120ml Treats Acne Pimples Tightens Pores Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-tea-tree-skin-clearing-mattifying-facial-toner-for-normal-to-oily-skin-face-toner-120ml-treats-acne-pimples-tightens-pores-pack-of-1/65bbd72ffb857a0f8631b81d' },
{ name: 'Urban Terrain Maza 26 Blue City Lite Bike With Free Cycling Event Ride Tracking App By Cultsport 18 Inch Frame Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urban-terrain-maza-26-blue-city-lite-bike-with-free-cycling-event-ride-tracking-app-by-cultsport-18-inch-frame-ideal/65bbd025fb857a3be031b813' },
{ name: 'Size 6 Years 7 Years Atun All Things Uber Nice Girls Knee Length Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-years-7-years-atun-all-things-uber-nice-girls-knee-length-dress/65bbec4ee48dc3a6ad46c1c8' },
{ name: 'Impulse Imp Linear 45l Laptop Backpackoffice Bagschool Bagcollege Bagbusiness Bagtravel Backpack Water Resistant Fits Up To 16 Inch Laptop With 1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/impulse-imp-linear-45l-laptop-backpackoffice-bagschool-bagcollege-bagbusiness-bagtravel-backpack-water-resistant-fits-up-to-16-inch-laptop-with-1-year-warranty/65bbe544e48dc3627d46c1be' },
{ name: 'Impulse Diggy 30l Laptop Backpackoffice Bagschool Bagcollege Bagbusiness Bagtravel Backpack Water Resistant Fits Up To 16 Inch Laptop With 1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/impulse-diggy-30l-laptop-backpackoffice-bagschool-bagcollege-bagbusiness-bagtravel-backpack-water-resistant-fits-up-to-16-inch-laptop-with-1-year-warranty/65bbe1bfe48dc3086d46c1b9' },
{ name: 'Boroplus Boro Plus Cocoa Soft Body Lotion For Skin Combination 400 Ml 1 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-boro-plus-cocoa-soft-body-lotion-for-skin-combination-400-ml-1-count/65bbdab5e48dc325bc46c1ae' },
{ name: 'Maxima Max Pro X1 Smartwatch Premium 14 Hd Display Of 500 Nits With 10 Days Battery Life 100 Watch Faces Sleep Spo2 Monitoring Social Media Alerts Multiple Exercise Modes Army Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxima-max-pro-x1-smartwatch-premium-14-hd-display-of-500-nits-with-10-days-battery-life-100-watch-faces-sleep-spo2-monitoring-social-media-alerts-multiple-exercise-modes-army-green/65bbe8c9e48dc337f346c1c3' },
{ name: 'Boat Airdopes 121 Pro Tws Earbuds Signature Sound Quad Mic Enx Low Latency Mode', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-airdopes-121-pro-tws-earbuds-signature-sound-quad-mic-enx-low-latency-mode/65bbde3be48dc3168946c1b4' },
{ name: 'Ganesh Multipurpose Plastic Vegetable Master Chopper With 5 Stainless Steel Blades Blades For Effortlessly Chopping Vegetables And Fruits', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-multipurpose-plastic-vegetable-master-chopper-with-5-stainless-steel-blades-blades-for-effortlessly-chopping-vegetables-and-fruits/65bbcca1fb857a38aa31b80e' },
{ name: 'Lifelong Protein Shaker100 Leakproof Stylish Protein Shaker Bottlesipper Bottlegym Bottle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-protein-shaker100-leakproof-stylish-protein-shaker-bottlesipper-bottlegym-bottle/65bbd3aafb857ade4731b818' },
{ name: 'Bajaj 30w B22 Led White Bulb Corona', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-30w-b22-led-white-bulb-corona/65bbc597fb857a087031b804' },
{ name: 'Dji Action 2 Dual Screen Combo 12mp Action Camera With Front Touchscreen 4k Recording Upto 120 Fps 155 Fov Portable Wearable10m Waterproofblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dji-action-2-dual-screen-combo-12mp-action-camera-with-front-touchscreen-4k-recording-upto-120-fps-155-fov-portable-wearable10m-waterproofblack/65bbc91cfb857ac82a31b809' },
{ name: 'Horlicks Diabetes Plus Vanilla 400g Powder Helps Manage Blood Sugar Starts Working From Day 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/horlicks-diabetes-plus-vanilla-400g-powder-helps-manage-blood-sugar-starts-working-from-day-1/65bbbb09fb857aaa1531b7e9' },
{ name: 'Nature Purify Premium Nutty Mix Pack Of Dry Fruit Combo 1kg 250g Almond 250g Cashew 250g Apricot 250g Raisins', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nature-purify-premium-nutty-mix-pack-of-dry-fruit-combo-1kg-250g-almond-250g-cashew-250g-apricot-250g-raisins/65bbb3fefb857a506631b7df' },
{ name: 'Open Secret 100 Natural Premium Whole Cashews 200 G Value Pack Whole Crunchy Cashew Premium Kaju Nuts Nutritious Delicious Gluten Free High Protein', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-100-natural-premium-whole-cashews-200-g-value-pack-whole-crunchy-cashew-premium-kaju-nuts-nutritious-delicious-gluten-free-high-protein/65bbb079fb857a69e431b7d7' },
{ name: 'For Sbi Card Midea 75 Kg Semi Automatic Top Loading Washing Machine Rust Proof Body Air Dry Function With 1300 Rpm Mwmsa075ppg Maroon White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-card-midea-75-kg-semi-automatic-top-loading-washing-machine-rust-proof-body-air-dry-function-with-1300-rpm-mwmsa075ppg-maroon-white/65aa6e5942faee33da08f70c' },
{ name: 'Pigeon By Stovekraft Steam Iron Velvet 1600 Watts With Spray Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-steam-iron-velvet-1600-watts-with-spray-blue/65aa6ad542faee33da08f708' },
{ name: 'Cello Opalware Dazzle Secret Garden Dinner Set 35pcs White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-secret-garden-dinner-set-35pcs-white/65bbbe8efb857a6c0731b7ee' },
{ name: 'Samsung Soundbar Hw B55exl 21 Channel Wireless Subwoofer Dolby 2ch Dts Virtual X Experience Sound Ai Adaptive Sound Lite Energy Star Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-soundbar-hw-b55exl-21-channel-wireless-subwoofer-dolby-2ch-dts-virtual-x-experience-sound-ai-adaptive-sound-lite-energy-star-black/65bbc212fb857a23bd31b7ff' },
{ name: 'Sugar Free Green Stevia Pouch 400 G 100 Plant Based Natural Sweetener Equivalent To Sweetness From 1kg Sugar 100 Natural Stevia Leaves Sweet Like Sugar But With Zero Calories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-free-green-stevia-pouch-400-g-100-plant-based-natural-sweetener-equivalent-to-sweetness-from-1kg-sugar-100-natural-stevia-leaves-sweet-like-sugar-but-with-zero-calories/65bbb783fb857a9fb631b7e4' },
{ name: 'Kitchen Jungle Dried Apricot 1kg Khumani Jardalu Badam Bor Soft', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kitchen-jungle-dried-apricot-1kg-khumani-jardalu-badam-bor-soft/65aa675042faee33da08f704' },
{ name: 'Everyuth Brightening Lemon Cherry Face Wash 150 Gm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/everyuth-brightening-lemon-cherry-face-wash-150-gm-pack-of-2/65aa63cc42faee33da08f700' },
{ name: 'Philips 4w Cool Day White Downlighter Pack Of 1 929001951720', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-4w-cool-day-white-downlighter-pack-of-1-929001951720/65aa5cc342faee33da08f6f8' },
{ name: 'Cello Prima Induction Base Non Stick Aluminium Pan Cookware Set 3 Pieces Cherry Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-prima-induction-base-non-stick-aluminium-pan-cookware-set-3-pieces-cherry-red/65aa604742faee33da08f6fc' },
{ name: 'For Sbi Credit Card Godrej Smart Locks I Catus Connect I Smart Digital Lock', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-godrej-smart-locks-i-catus-connect-i-smart-digital-lock/65aa4eb042faee33da08f6e8' },
{ name: 'Pigeon By Stovekraft Hard Anodised Aluminium Pressure Cooker Combo 23and 5 Litreinduction Base Outer Lid Cooker 14414 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-hard-anodised-aluminium-pressure-cooker-combo-23and-5-litreinduction-base-outer-lid-cooker-14414-black/65aa593e42faee33da08f6f4' },
{ name: 'For Sbi Credit Card Lava Blaze 5g Glass Blue 8gb Ram Ufs 22 128gb Storage 5g Ready 50mp Ai Triple Camera Upto 16gb Expandable Ram Charger Included Clean Android No Bloatware', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lava-blaze-5g-glass-blue-8gb-ram-ufs-22-128gb-storage-5g-ready-50mp-ai-triple-camera-upto-16gb-expandable-ram-charger-included-clean-android-no-bloatware/65aa4b2c42faee33da08f6e4' },
{ name: 'Crompton Dyna Ray 9w Round B22 Led Cool Day Light Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-dyna-ray-9w-round-b22-led-cool-day-light-pack-of-10/65aa523542faee33da08f6ec' },
{ name: 'Pigeon By Stovekraft Stainless Steel Kitchen Knives Set 3 Pieces Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-stainless-steel-kitchen-knives-set-3-pieces-multicolor/65aa55b942faee33da08f6f0' },
{ name: 'Amazon Basics Dv I To Vga Cable 6 Foot Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-dv-i-to-vga-cable-6-foot-black/65aa442342faee33da08f6dc' },
{ name: 'Crompton Energion Cromair 1200mm 48 Inch High Speed 5s 28w Energy Efficient Bldc Ceiling Fan With Remote Brown 5 Years Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-energion-cromair-1200mm-48-inch-high-speed-5s-28w-energy-efficient-bldc-ceiling-fan-with-remote-brown-5-years-warranty/65aa409e42faee33da08f6d8' },
{ name: 'Lila Dry Fruits 100 Natural Raw Walnut Inshells 1000g 1kgs Value Pack Premium Sabut Akrot High In Protein Iron Low Calorie Nut Dry Fruit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lila-dry-fruits-100-natural-raw-walnut-inshells-1000g-1kgs-value-pack-premium-sabut-akrot-high-in-protein-iron-low-calorie-nut-dry-fruit/65aa3d1a42faee33da08f6d4' },
{ name: 'Ganesh Squeegee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-squeegee/65aa47a742faee33da08f6e0' },
{ name: 'Cello Super Club 200 750 Watt Sandwich Maker Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-super-club-200-750-watt-sandwich-maker-black/65aa399642faee33da08f6d0' },
{ name: 'Arto Keychain Led Light 2 Hours Battery Life With Bottle Opener Magnetic Base And Folding Bracket Mini Cob 1000 Lumens Rechargeable Emergency Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/arto-keychain-led-light-2-hours-battery-life-with-bottle-opener-magnetic-base-and-folding-bracket-mini-cob-1000-lumens-rechargeable-emergency-light/65aa328d42faee33da08f6c8' },
{ name: 'Vip Widget Durable Polyester Soft Sided Cabin Luggage Spinner Wheels With Quick Access Front Pockets Cabin 58cm Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vip-widget-durable-polyester-soft-sided-cabin-luggage-spinner-wheels-with-quick-access-front-pockets-cabin-58cm-red/65aa361142faee33da08f6cc' },
{ name: 'Nivia Country Colour Football England Moulded Suitable For Hard Ground Without Grass Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivia-country-colour-football-england-moulded-suitable-for-hard-ground-without-grass-ideal/65aa19ee42faee33da08f6ac' },
{ name: 'Amazon Brand Presto Disinfectant Toilet Cleaner Original 1 L Pack Of 4kills 999 Germs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-disinfectant-toilet-cleaner-original-1-l-pack-of-4kills-999-germs/65aa247b42faee33da08f6b8' },
{ name: 'For Sbi Credit Card Hp Victus Gaming Laptop12th Gen Intel Core I5 12450hnvidia Rtx 3050 Gpu156 Inch 396 Cmfhdips144hzwindows 11 Home9 Ms Response Time16gb Ddr4512gb Ssdbacklit Kbmsoblue229 Kg Fa0666tx', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-hp-victus-gaming-laptop12th-gen-intel-core-i5-12450hnvidia-rtx-3050-gpu156-inch-396-cmfhdips144hzwindows-11-home9-ms-response-time16gb-ddr4512gb-ssdbacklit-kbmsoblue229-kg-fa0666tx/65aa12e542faee33da08f6a4' },
{ name: 'Pigeon By Stovekraft Therminox Stark Plus Vacuum Insulated Flask 1000ml Red Inox Hydra Red Single Walled Fridge Bottle 900ml Bottle Combo Redleak Proofkitchentravelofficegymhomehiking', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-therminox-stark-plus-vacuum-insulated-flask-1000ml-red-inox-hydra-red-single-walled-fridge-bottle-900ml-bottle-combo-redleak-proofkitchentravelofficegymhomehiking/65aa0f6142faee33da08f6a0' },
{ name: 'Bergner Tripro Triply 7pc Cookware Set 20cm Deep Kadai 20cm Saute Pan 16cm Sauce Pan 20cm Casserole 3x Stainless Steel Lid Ergonomic Wire Handles Smooth Finished Surface Induction Gas Ready 5 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bergner-tripro-triply-7pc-cookware-set-20cm-deep-kadai-20cm-saute-pan-16cm-sauce-pan-20cm-casserole-3x-stainless-steel-lid-ergonomic-wire-handles-smooth-finished-surface-induction-gas-ready-5-year-warranty/65aa0bdc42faee33da08f69c' },
{ name: 'Yogabar Dark Chocolate Oatmeal 400g Gluten Free Whole Oatmeal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-dark-chocolate-oatmeal-400g-gluten-free-whole-oatmeal/65aa085842faee33da08f698' },
{ name: 'Dabur Vatika Neelibhringa 21 Hair Growth Oil 50ml25ml Extra Free 14 Ayurvedic Herbs Ayurvedic Medicine 4x Less Hairfall 3x Thicker Hair 21 Hair Benefits With Ergnomic Comb Applicator', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-neelibhringa-21-hair-growth-oil-50ml25ml-extra-free-14-ayurvedic-herbs-ayurvedic-medicine-4x-less-hairfall-3x-thicker-hair-21-hair-benefits-with-ergnomic-comb-applicator/65aa166a42faee33da08f6a8' },
{ name: 'Aipl Bopp Packaging Clear Tape 24mm X 50 Meter Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aipl-bopp-packaging-clear-tape-24mm-x-50-meter-pack-of-2-/65aa20f742faee33da08f6b4' },
{ name: 'Scott International Mens Quilted Puffer Jacket Lightweight Water Repellant Elastic Cuffs Zipped Pockets Casual Winter Jacket Stylish Outerwear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/scott-international-mens-quilted-puffer-jacket-lightweight-water-repellant-elastic-cuffs-zipped-pockets-casual-winter-jacket-stylish-outerwear/65aa1d7242faee33da08f6b0' },
{ name: 'For Sbi Credit Card Vw 80 Cm 32 Inches Frameless Series Hd Ready Android Smart Led Tv Vw32s Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-vw-80-cm-32-inches-frameless-series-hd-ready-android-smart-led-tv-vw32s-black/65aa2f0842faee33da08f6c4' },
{ name: 'Flixbeetel New Launch Powerxtreme 10000mah 12w Slim Power Bankusb Cmicro Usb Inputdual Usb A Outputcompatible With Iphone Type A To Lightning Samsung Google Pixel Onepluscobalt Blue P10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flixbeetel-new-launch-powerxtreme-10000mah-12w-slim-power-bankusb-cmicro-usb-inputdual-usb-a-outputcompatible-with-iphone-type-a-to-lightning-samsung-google-pixel-onepluscobalt-blue-p10/65aa280042faee33da08f6bc' },
{ name: 'Skybags One Size Brat 46 Cms 22 Ltrs Casual Standard Backpack Sea Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skybags-one-size-brat-46-cms-22-ltrs-casual-standard-backpack-sea-green/65aa2b8442faee33da08f6c0' },
{ name: 'Bombay Shaving Company Charcoal And Neem Bath Soap Pack Of 375g Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bombay-shaving-company-charcoal-and-neem-bath-soap-pack-of-375g-each/65a91bad42faee33da08f679' },
{ name: 'Go Desi Dry Fruits Barfi 480 Grams 24 Individually Wrapped Barfis Indian Sweets Gift Pack Dates Bar Anjeer Khajoor Nuts Sweets Indian Mithai', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-dry-fruits-barfi-480-grams-24-individually-wrapped-barfis-indian-sweets-gift-pack-dates-bar-anjeer-khajoor-nuts-sweets-indian-mithai/65a9182942faee33da08f674' },
{ name: 'Philips Daily Collection Hd258200 830 Watt 2 Slice Pop Up Toaster White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-daily-collection-hd258200-830-watt-2-slice-pop-up-toaster-white/65a9c7b042faee33da08f68c' },
{ name: 'Dettol Body Wash And Shower Gel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-body-wash-and-shower-gel/65a9445a42faee33da08f683' },
{ name: 'Rage Instant Coffee 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rage-instant-coffee-50g/65aa014e42faee33da08f690' },
{ name: 'Amazon Brand Presto Sports Shoe Cleaner With Brush Removes Tough Stains No Water Required Ready To Use', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-sports-shoe-cleaner-with-brush-removes-tough-stains-no-water-required-ready-to-use/65a9112042faee33da08f66a' },
{ name: 'Portronics Power Plate 5 1500w Power Converter With Usb Charger Mobile Holder 3ac Socket 3usb Ports 15 M Cord Length Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-power-plate-5-1500w-power-converter-with-usb-charger-mobile-holder-3ac-socket-3usb-ports-15-m-cord-length-black/65a90d9b42faee33da08f665' },
{ name: 'For Sbi Credit Card Samsung 633 L 3 Star Frost Free Convertible 5 In 1 Digital Inverter Side By Side Wifi Embedded Refrigerator Rs78cg8543s9hl Silver Refined Inox 2023 Model', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-samsung-633-l-3-star-frost-free-convertible-5-in-1-digital-inverter-side-by-side-wifi-embedded-refrigerator-rs78cg8543s9hl-silver-refined-inox-2023-model/65a90a1742faee33da08f660' },
{ name: 'Vincent Chase By Lenskart Green Full Rim Rectangular Holiday Edit Branded Latest And Stylish Sunglasses Polarized And 100 Uv Protected Men Women Medium Vc S16341', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vincent-chase-by-lenskart-green-full-rim-rectangular-holiday-edit-branded-latest-and-stylish-sunglasses-polarized-and-100-uv-protected-men-women-medium-vc-s16341/65a914a442faee33da08f66f' },
{ name: 'Powermax Fitness Bca 150 Smart Bluetooth Body Fat Scale Smart Bmi Digital Bathroom Wireless Weight Scale Body Composition Analyser With Smartphone App', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/powermax-fitness-bca-150-smart-bluetooth-body-fat-scale-smart-bmi-digital-bathroom-wireless-weight-scale-body-composition-analyser-with-smartphone-app/65a8fc0542faee33da08f64d' },
{ name: 'For Sbi Credit Card Oneplus Bullets Z2 Bluetooth Wireless In Ear Earphones With Mic Bombastic Bass 124 Mm Drivers 10 Mins Charge 20 Hrs Music 30 Hrs Battery Life Magico Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-oneplus-bullets-z2-bluetooth-wireless-in-ear-earphones-with-mic-bombastic-bass-124-mm-drivers-10-mins-charge-20-hrs-music-30-hrs-battery-life-magico-black/65a8ff8a42faee33da08f652' },
{ name: 'Panasonic 9w Led Bulb Led Bulb 9 Watt With B22 Base 4kv Surge Protection 9 Watt Bulb Cool Day Light Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panasonic-9w-led-bulb-led-bulb-9-watt-with-b22-base-4kv-surge-protection-9-watt-bulb-cool-day-light-pack-of-4/65a9069342faee33da08f65b' },
{ name: 'Intex It 2650 Digi Plus Fmub 60 Watt 41 Channel Wireless Bluetooth Multimedia Speaker Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/intex-it-2650-digi-plus-fmub-60-watt-41-channel-wireless-bluetooth-multimedia-speaker-black/65a7843842faee33da08f641' },
{ name: 'Namyaa Nourishing Body Lotionwith Long Lasting Fragrancemade With Natural Ingredients For Dry Skingives Glowing And Ultra Soft Skin Pack Of 3aloe Vera Coconut Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/namyaa-nourishing-body-lotionwith-long-lasting-fragrancemade-with-natural-ingredients-for-dry-skingives-glowing-and-ultra-soft-skin-pack-of-3aloe-vera-coconut-oil/65a9030e42faee33da08f656' },
{ name: 'Fitbox Sports Intruder 10 Kg Blend Weight Plates 25 Kg X 4 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitbox-sports-intruder-10-kg-blend-weight-plates-25-kg-x-4-black/65a7801f42faee33da08f63d' },
{ name: 'Tukzer Foldable Laptop Tablet Stand Riser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tukzer-foldable-laptop-tablet-stand-riser/65a61fafe4c6cd09050b7a53' },
{ name: 'F Gear String V2 Gym Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/f-gear-string-v2-gym-bag/65a61c2ae4c6cd2bd90b7a4d' },
{ name: 'Cello Wow Lunch Box With Inner Stainless Steel 3 Units Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-wow-lunch-box-with-inner-stainless-steel-3-units-brown/65a618909d8beabdd5be6d1c' },
{ name: 'Redmi Watch 2 Lite 394 Cm Large Hd Edge Display Multi System Standalone Gps Continuous Spo2 Stress Sleep Monitoring 24x7 Hr 5atm 120 Watch Faces 100 Sports Modes Women S Health Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-watch-2-lite-394-cm-large-hd-edge-display-multi-system-standalone-gps-continuous-spo2-stress-sleep-monitoring-24x7-hr-5atm-120-watch-faces-100-sports-modes-women-s-health-ivory/65a6150b9d8bea633dbe6d17' },
{ name: 'For Sbi Credit Card Lg Soundbar Sp2 100w 21ch Home Theatre System Built In Subwoofer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lg-soundbar-sp2-100w-21ch-home-theatre-system-built-in-subwoofer/65a611869d8bea3805be6d12' },
{ name: 'Yardley London Elegance After Shave Lotion With Aloe Vera Daily Use After Shave Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yardley-london-elegance-after-shave-lotion-with-aloe-vera-daily-use-after-shave-lotion/65a8f88042faee33da08f648' },
{ name: 'Portronics My Buddy Hexa 5 Laptop Stand With Foldable Ventilated Design Ergonomic Elevation Anti Slip Silicon Pads And Lightweight Body', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-my-buddy-hexa-5-laptop-stand-with-foldable-ventilated-design-ergonomic-elevation-anti-slip-silicon-pads-and-lightweight-body/65a60e029d8beacdc7be6d0d' },
{ name: 'Dabur Meswak Complete Oral Care Toothpaste 500g 2 X 200g 1 X100g Complete Oral Gum Care Toothpaste Contains Pure Rare Miswak Extract No Added Fluoride Paraben Triclosan Formalin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-meswak-complete-oral-care-toothpaste-500g-2-x-200g-1-x100g-complete-oral-gum-care-toothpaste-contains-pure-rare-miswak-extract-no-added-fluoride-paraben-triclosan-formalin/65a60372378855624fa9561f' },
{ name: 'For Sbi Credit Card Samsung 24 Inch598cm Fhd 75 Hz 1800r Curved 1920 X 1080 Led Monitor Va Panel Slim Design Amd Freesync Game Mode Flicker Free Hdmi Audio Port Ls24c360eawxxl Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-samsung-24-inch598cm-fhd-75-hz-1800r-curved-1920-x-1080-led-monitor-va-panel-slim-design-amd-freesync-game-mode-flicker-free-hdmi-audio-port-ls24c360eawxxl-black/65a5fc662ca1a45edd085a1c' },
{ name: 'Sizes M L Van Heusen Men Briefs 100 Combed Cotton Pack Of 3 Anti Bacterial Colour Fresh Moisture Wicking', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-m-l-van-heusen-men-briefs-100-combed-cotton-pack-of-3-anti-bacterial-colour-fresh-moisture-wicking/65a5f8e12ca1a4aee3085a17' },
{ name: 'Tag Usb Mouse Core 1000 Dpi Plug And Play Mouse With Optical Sensor And 15metre Cable 3 Button Leftrightscroll', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tag-usb-mouse-core-1000-dpi-plug-and-play-mouse-with-optical-sensor-and-15metre-cable-3-button-leftrightscroll/65a5f55c2ca1a454bb085a0d' },
{ name: 'Oneplus Bullets Z2 Bluetooth Wireless In Ear Earphones With Mic Bombastic Bass 124 Mm Drivers 10 Mins Charge 20 Hrs Music 30 Hrs Battery Life Magico Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-bullets-z2-bluetooth-wireless-in-ear-earphones-with-mic-bombastic-bass-124-mm-drivers-10-mins-charge-20-hrs-music-30-hrs-battery-life-magico-black/65a5f1d619d5f7817bc2bf59' },
{ name: 'For Sbi Credit Card Lenovo L Series 5448 Cm 2145 Inch Fhd Ultraslim Monitor 167 Mn Colors 75hz 4ms Amd Freesync Hdmi Tuv Eye Comfort Low Blue Light Smart Display Customization Artery L22e 40', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lenovo-l-series-5448-cm-2145-inch-fhd-ultraslim-monitor-167-mn-colors-75hz-4ms-amd-freesync-hdmi-tuv-eye-comfort-low-blue-light-smart-display-customization-artery-l22e-40/65a5ffed378855dbdea95619' },
{ name: 'Set Wet Ice Perfume For Men 100mlcitrusy Long Lasting Perfume For Mengift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/set-wet-ice-perfume-for-men-100mlcitrusy-long-lasting-perfume-for-mengift/65a60a7d9d8bea3e99be6d08' },
{ name: 'Bajaj Dx 6 1000w Dry Iron With Advance Soleplate And Anti Bacterial German Coating Technology White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-dx-6-1000w-dry-iron-with-advance-soleplate-and-anti-bacterial-german-coating-technology-white/65a606f89d8bea9b0ebe6d02' },
{ name: 'Vivel Body Wash Lavender Almond Oil Shower Creme Fragrant Moisturising For Soft And Smooth Skin High Foaming Formula 500 Ml Pump For Women And Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivel-body-wash-lavender-almond-oil-shower-creme-fragrant-moisturising-for-soft-and-smooth-skin-high-foaming-formula-500-ml-pump-for-women-and-men/65a5ee5119d5f71a50c2bf54' },
{ name: 'Boroplus Antiseptic And Moisturising Bathing Soap With Aloe Vera Neem And Tulsi 999 Germ And Virus Protection For Smooth Soft Nourished Skin 125g Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-antiseptic-and-moisturising-bathing-soap-with-aloe-vera-neem-and-tulsi-999-germ-and-virus-protection-for-smooth-soft-nourished-skin-125g-pack-of-6/65a5eacc19d5f7839dc2bf4f' },
{ name: 'For Sbi Credit Card Emi Tecno Spark 9 Infinity Black 4gb Ram64gb Storage 7gb Expandable Ram Helio G37 Gaming Processor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-tecno-spark-9-infinity-black-4gb-ram64gb-storage-7gb-expandable-ram-helio-g37-gaming-processor/65a5e74719d5f7cc56c2bf4a' },
{ name: 'Bajaj Esteem Table Fan 400 Mm Table Fans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-esteem-table-fan-400-mm-table-fans/65a5d93419d5f76af2c2bf1e' },
{ name: 'For Sbi Credit Card Lenovo Ideapad Gaming 3 Amd Ryzen 5 5500h 156 3962cm Fhd Ips 250nits 60hz Gaming Laptop 8gb512gb Ssdwindows 11nvidia Rtx 2050 4gbalexa3 Month Game Passonyx Grey232kg 82k2028ain', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lenovo-ideapad-gaming-3-amd-ryzen-5-5500h-156-3962cm-fhd-ips-250nits-60hz-gaming-laptop-8gb512gb-ssdwindows-11nvidia-rtx-2050-4gbalexa3-month-game-passonyx-grey232kg-82k2028ain/65a5d5af19d5f70c38c2bf19' },
{ name: 'Samsung Galaxy M04 Light Green 4gb Ram 64gb Storage Upto 8gb Ram With Ram Plus Mediatek Helio P35 Octa Core Processor 5000 Mah Battery 13mp Dual Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m04-light-green-4gb-ram-64gb-storage-upto-8gb-ram-with-ram-plus-mediatek-helio-p35-octa-core-processor-5000-mah-battery-13mp-dual-camera/65a5d22a19d5f71be6c2bf13' },
{ name: 'Homebasics By Home Puff Collapsible Basket Bpa Free Washing Bowl For Fruits Vegetable Noodles Pasta Washing Bowl Multipurpose Strainer Colander Kitchen Sieve Diy Organizer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/homebasics-by-home-puff-collapsible-basket-bpa-free-washing-bowl-for-fruits-vegetable-noodles-pasta-washing-bowl-multipurpose-strainer-colander-kitchen-sieve-diy-organizer/65a5cea3422abe445f2bbc31' },
{ name: 'For Sbi Credit Card Noise Arc 138 Advanced Bluetooth Calling Smart Watch 550 Nits Brightness 100 Sports Modes 100 Watch Faces 7 Day Battery Ip68 Rated Jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-noise-arc-138-advanced-bluetooth-calling-smart-watch-550-nits-brightness-100-sports-modes-100-watch-faces-7-day-battery-ip68-rated-jet-black/65a5cb1e422abe783b2bbc23' },
{ name: 'Vaseline Sun Protect Cooling Spf 15 Body Serum Lotion 180ml For Non Sticky Matte Sun Protected Skin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-sun-protect-cooling-spf-15-body-serum-lotion-180ml-for-non-sticky-matte-sun-protected-skin/65a5dcb919d5f75a96c2bf23' },
{ name: 'Hair Care Pro Blend Damage Repair Hair Shampoooil Combo 300ml300ml With Avocado Aloe Vera And Olive Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hair-care-pro-blend-damage-repair-hair-shampoooil-combo-300ml300ml-with-avocado-aloe-vera-and-olive-oil/65a5e03d19d5f71b91c2bf28' },
{ name: 'Dove Hair Therapy Breakage Repair Leave On Solution No Parabens Dyes With Nutri Lock Serum', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-hair-therapy-breakage-repair-leave-on-solution-no-parabens-dyes-with-nutri-lock-serum/65a5e3c219d5f7fcc9c2bf2d' },
{ name: 'Lakme Rose Face Powder Soft Pink 40g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-rose-face-powder-soft-pink-40g/65a5c799422abe2c192bbc1e' },
{ name: 'Parachute Advansed Almond Enriched Coconut Hair Oil Almond Hair Oil Superfoods Love Soft Silky Hair 300 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-almond-enriched-coconut-hair-oil-almond-hair-oil-superfoods-love-soft-silky-hair-300-ml/65a5c415422abe6a7c2bbc19' },
{ name: 'Noise Pulse 2 Max 185 Display Bluetooth Calling Smart Watch 10 Days Battery 550 Nits Brightness Smart Dnd 100 Sports Modes Smartwatch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-pulse-2-max-185-display-bluetooth-calling-smart-watch-10-days-battery-550-nits-brightness-smart-dnd-100-sports-modes-smartwatch/65a5c090422abe04032bbc14' },
{ name: 'Noise Newly Launched Quad Call 181 Display Bluetooth Calling Smart Watch Ai Voice Assistance 160hrs Battery Life Metallic Build In Built Games 100 Sports Modes 100 Watch Faces Jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-newly-launched-quad-call-181-display-bluetooth-calling-smart-watch-ai-voice-assistance-160hrs-battery-life-metallic-build-in-built-games-100-sports-modes-100-watch-faces-jet-black/65a5bd0b422abe224d2bbc0f' },
{ name: 'Razer Opus X Green Active Noise Cancellation Gaming Wireless On Ear Headset Rz04 03760400 R3m1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/razer-opus-x-green-active-noise-cancellation-gaming-wireless-on-ear-headset-rz04-03760400-r3m1/65a5aef7422abe4c322bbbfa' },
{ name: 'Acer One T4 82l 2032 Cm 8 Inch Bluetooth Tablet With 2gb Ram And 32 Gb Emmc 350 Nits Brightness Ips Panel Dual Camera 4g Lteandroid 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-one-t4-82l-2032-cm-8-inch-bluetooth-tablet-with-2gb-ram-and-32-gb-emmc-350-nits-brightness-ips-panel-dual-camera-4g-lteandroid-10/65a5ab7159c4e4742acf8816' },
{ name: 'Lakm Blush Glow Strawberry Sheet Mask 25 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-blush-glow-strawberry-sheet-mask-25-ml/65a5a7ec59c4e4cc54cf8810' },
{ name: 'Bsb Home 220 Gsm Reversible Microfiber Ac Comforterblanketquiltduvets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bsb-home-220-gsm-reversible-microfiber-ac-comforterblanketquiltduvets/65a5a46525c2c43d3915b808' },
{ name: 'Noise Mettalix 14 Display Bluetooth Calling Smart Watch With Metallic Strap Stainless Steel Finish Functional Crown 7 Day Battery Elite Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-mettalix-14-display-bluetooth-calling-smart-watch-with-metallic-strap-stainless-steel-finish-functional-crown-7-day-battery-elite-black/65a5b601422abe325e2bbc05' },
{ name: 'Brother Mfc L3735cdn Colour Led Multifunction Centre With Duplex Networking Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/brother-mfc-l3735cdn-colour-led-multifunction-centre-with-duplex-networking-technology/65a59d5b25c2c4f95e15b7fe' },
{ name: 'Lakme Peach Milk Soft Crme Light Moisturizer For Face 300 G Daily Lightweight Face Lotion With Vitamin E', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-peach-milk-soft-crme-light-moisturizer-for-face-300-g-daily-lightweight-face-lotion-with-vitamin-e/65a5b986422abe61512bbc0a' },
{ name: 'Fire Boltt Solaris 178 Amoled Always On Display With 368 448 Px Resolution 123 Sports Mode Ip68 Water Resistant Long Lasting Battery Life And Comprehensive Health Tracking Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-solaris-178-amoled-always-on-display-with-368-448-px-resolution-123-sports-mode-ip68-water-resistant-long-lasting-battery-life-and-comprehensive-health-tracking-black/65a5b27d422abe5b4e2bbc00' },
{ name: 'Philips Skyy 5w Rechargeable Touch Controlled Led Table Lamp Desk Lamp With Brightness Control', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-skyy-5w-rechargeable-touch-controlled-led-table-lamp-desk-lamp-with-brightness-control/65a5a0e025c2c4ae4115b803' },
{ name: 'Status All Season Multipurpose Polar Fleece Single Bed Blanketlight Weight Blanket Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-all-season-multipurpose-polar-fleece-single-bed-blanketlight-weight-blanket-black/65a599d725c2c4098c15b7f9' },
{ name: 'Philips 9 Watts Multipack B22 Led Cool Day White Led Bulb Pack Of 4 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-multipack-b22-led-cool-day-white-led-bulb-pack-of-4-ace-saver/65a5965225c2c486db15b7f4' },
{ name: 'Bosch Truemixx Black 500 Watt Joy Mixer Grinder 3 Jars Mgm2133bin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bosch-truemixx-black-500-watt-joy-mixer-grinder-3-jars-mgm2133bin/65a592cd25c2c411d615b7ef' },
{ name: 'Hp Reversible Protective 116 Inch Laptop Sleevezipper Lesflexible And Durable Neoprene Sleeve Protect From Everyday Bumps And Scrapes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-reversible-protective-116-inch-laptop-sleevezipper-lesflexible-and-durable-neoprene-sleeve-protect-from-everyday-bumps-and-scrapes/65a584ba25c2c4188915b7da' },
{ name: 'Amazon Basics Rj45 Cat7 Network Ethernet Patch Cable For Router Modem White 3 Feet 5 Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-rj45-cat7-network-ethernet-patch-cable-for-router-modem-white-3-feet-5-pack/65a5883f25c2c4681815b7e0' },
{ name: 'Clazkit Oil Container Honey Dispenser Bottle Kitchen Castor Stainless Steel Seasoning Bottle Kitchen Product Oil Brush 350ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clazkit-oil-container-honey-dispenser-bottle-kitchen-castor-stainless-steel-seasoning-bottle-kitchen-product-oil-brush-350ml/65a57dafc7a8383dae0b8bc9' },
{ name: 'Hp Reversible Protective 156 Inch Geo Laptop Sleeve Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-reversible-protective-156-inch-geo-laptop-sleeve-grey/65a58133c7a83880020b8bce' },
{ name: 'Gear Elevate Faux Leather 20l Water Resistant Anti Theft Laptop Bagbackpackoffice Bag With Raincover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-elevate-faux-leather-20l-water-resistant-anti-theft-laptop-bagbackpackoffice-bag-with-raincover/65a57a2ac7a838c85c0b8bc4' },
{ name: 'Hp X1000 Wireless Usb Over Ear Gaming Headphones With 71 Surround Sound With Mic 50 Mm Drivers 20 Hours Of Battery Life7hc43aa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-x1000-wireless-usb-over-ear-gaming-headphones-with-71-surround-sound-with-mic-50-mm-drivers-20-hours-of-battery-life7hc43aa-black/65a58bc325c2c41fa415b7e5' },
{ name: 'Stanley 70 939e High Grade Steel Combination Spanner With Matte Finish 9mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stanley-70-939e-high-grade-steel-combination-spanner-with-matte-finish-9mm/65a58f4825c2c4b1eb15b7ea' },
{ name: 'Panca Premium Steamer For Vegatables Momo Rice Multipurpose 2 Tier Stainless Steel Steamer With Glass Lid Premium Daily Use Steamer 18cm Diameter Induction Friendly Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-premium-steamer-for-vegatables-momo-rice-multipurpose-2-tier-stainless-steel-steamer-with-glass-lid-premium-daily-use-steamer-18cm-diameter-induction-friendly-silver/65a576a5c7a8385c4f0b8bbf' },
{ name: 'Baltra Bullet Thermosteel Stainless Steel Flask 750 Ml Bsl 203', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/baltra-bullet-thermosteel-stainless-steel-flask-750-ml-bsl-203/65a57320c7a83826550b8bba' },
{ name: 'Lenovo 156 3962cm Slim Everyday Backpack Made In India Compact Water Resistant Organized Storagelaptop Sleevetablet Pocketfront Workstation2 Side Pocketspadded Adjustable Shoulder Straps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-156-3962cm-slim-everyday-backpack-made-in-india-compact-water-resistant-organized-storagelaptop-sleevetablet-pocketfront-workstation2-side-pocketspadded-adjustable-shoulder-straps/65a56f9bc7a838736b0b8bb5' },
{ name: 'Hp 330 156 Inch Laptop Backpacktrolley Pass Through Padded Back Panel Padded Air Mesh Panelhand Wash And Air Dry1 Year Limited Warranty 793a7aa', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-330-156-inch-laptop-backpacktrolley-pass-through-padded-back-panel-padded-air-mesh-panelhand-wash-and-air-dry1-year-limited-warranty-793a7aa/65a56c16c7a83868c80b8ba9' },
{ name: 'For Card Redmi 13c Stardust Black 6gb Ram 128gb Storage Powered By 4g Mediatek Helio G85 90hz Display 50mp Ai Triple Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-card-redmi-13c-stardust-black-6gb-ram-128gb-storage-powered-by-4g-mediatek-helio-g85-90hz-display-50mp-ai-triple-camera/65a5688d9e6c2b7dd4fcc810' },
{ name: 'Lifelong Pull Up Bar For Home Adjustable Rod 65cm To 95cm Width Wall Mounted Without Screws Door Pull Up Bar To Increase Height Exercise Equipment', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-pull-up-bar-for-home-adjustable-rod-65cm-to-95cm-width-wall-mounted-without-screws-door-pull-up-bar-to-increase-height-exercise-equipment/65a55dff9e6c2b3922fcc801' },
{ name: 'Vaseline Intensive Care Deep Moisture Nourishing Body Lotion 600ml For Radiant Glowing Skin With Glycerin Non Sticky Fast Absorbing Daily Moisturizer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-intensive-care-deep-moisture-nourishing-body-lotion-600ml-for-radiant-glowing-skin-with-glycerin-non-sticky-fast-absorbing-daily-moisturizer/65a556f59e6c2b79fbfcc7f7' },
{ name: 'Auric Lab Certified Cow Ghee 1l 100 Pure And Natural Desi Ghee Highly Nutritious Helps Keep Your Heart Healthy Boost Immunity Energy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/auric-lab-certified-cow-ghee-1l-100-pure-and-natural-desi-ghee-highly-nutritious-helps-keep-your-heart-healthy-boost-immunity-energy/65a553719e6c2ba0b0fcc7ec' },
{ name: 'Philips Digital Xl Airfryer Hd928090 Wifi Enabled Touch Panel 5000 Series Xl 62 Ltr 12kg Rapid Air Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-digital-xl-airfryer-hd928090-wifi-enabled-touch-panel-5000-series-xl-62-ltr-12kg-rapid-air-technology/65a54fec9e6c2bbd74fcc7dc' },
{ name: 'Morphy Richards Kingsman Pro 12 In 1 Body Groomer3months Of Trimming Fast Usb Charging Multi Grooming Kit5face Nose Ear Hair Blades7beard Combs Privatepart Shaving2 Yr Warranty By Brandblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/morphy-richards-kingsman-pro-12-in-1-body-groomer3months-of-trimming-fast-usb-charging-multi-grooming-kit5face-nose-ear-hair-blades7beard-combs-privatepart-shaving2-yr-warranty-by-brandblack/65a565099e6c2b81f4fcc80b' },
{ name: 'For Sbi Credit Card Havells Adonia I 15 Litre Smart Storage Water Heater With Alexa Google Assistant Enabled Iot And Ios With Mobile Phones Wifi Enabled And Remote Controlled Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-havells-adonia-i-15-litre-smart-storage-water-heater-with-alexa-google-assistant-enabled-iot-and-ios-with-mobile-phones-wifi-enabled-and-remote-controlled-ivory/65a55a7a9e6c2b0bd7fcc7fc' },
{ name: 'Slovic Gym Ball For Exercise Yoga Ball Pregnancy Ball For Exercise Exercise Ball For Workouts Anti Burst Coating Yoga Ball', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/slovic-gym-ball-for-exercise-yoga-ball-pregnancy-ball-for-exercise-exercise-ball-for-workouts-anti-burst-coating-yoga-ball/65a561839e6c2b64aefcc806' },
{ name: 'Pigeon By Stovekraft Kitchen Tools Value Combo 400ml Chopper Storage 3 Knives Set 1 Kitchen Scissor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-kitchen-tools-value-combo-400ml-chopper-storage-3-knives-set-1-kitchen-scissor/65a54c679e6c2b70defcc7d7' },
{ name: 'For Sbi Credit Card Godrej 65 Kg 5 Star I Wash Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-godrej-65-kg-5-star-i-wash-technology/65a548e29e6c2b6944fcc7d2' },
{ name: 'Vaseline Intensive Care Cocoa Glow Serum In Lotion 400ml With 100 Pure Cocoa Shea Butter 48hr Long Lasting Moisturization Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-intensive-care-cocoa-glow-serum-in-lotion-400ml-with-100-pure-cocoa-shea-butter-48hr-long-lasting-moisturization-body-lotion/65a5455d9e6c2b1879fcc7cd' },
{ name: 'Footloose By Skybags Unisex 10 Ltrs Navy Blue Polyester Casual Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/footloose-by-skybags-unisex-10-ltrs-navy-blue-polyester-casual-backpack/65a541d99e6c2b54d3fcc7c4' },
{ name: 'Havells Super Grooming Kit Gs8714 15 In 1 Ipx7 Fully Washable Full Body Groomer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-super-grooming-kit-gs8714-15-in-1-ipx7-fully-washable-full-body-groomer/65a53e539e6c2b73d6fcc7be' },
{ name: 'Kent Storm Vacuum Cleaner 600w Cyclone5 Technology Hepa Filter Bagless Design Detachable Easy To Pack Ideal Cleaning', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kent-storm-vacuum-cleaner-600w-cyclone5-technology-hepa-filter-bagless-design-detachable-easy-to-pack-ideal-cleaning/65a50b5c25a1d7d9f9752952' },
{ name: 'Park Avenue Good Morning Grooming Kit Combo Of 7in 1 Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-good-morning-grooming-kit-combo-of-7in-1-combo/65a51268bb3e80239ce55cae' },
{ name: 'Prestige Psmfb D 800w Sandwich Toaster With Die Cast Aluminium Sandwich Platespower Indicatorsnon Stick Coatingblack1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prestige-psmfb-d-800w-sandwich-toaster-with-die-cast-aluminium-sandwich-platespower-indicatorsnon-stick-coatingblack1-year-warranty/65a50ee3bb3e80d59fe55c74' },
{ name: 'For Sbi Credit Card Emi Muthoot Pappachan Swarnavarsham Bis Hallmarked 22kt Round Laxmi Pendant 5 Gram', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-muthoot-pappachan-swarnavarsham-bis-hallmarked-22kt-round-laxmi-pendant-5-gram/65a515ecbb3e8058d4e55cb3' },
{ name: 'Fiama Gel Bar Peach And Avocado For Moisturized Skin 125g Soap Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-gel-bar-peach-and-avocado-for-moisturized-skin-125g-soap-pack-of-6/65a5045225a1d77874752948' },
{ name: 'Apple Lightning To 35 Mm Headphone Jack Adapter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-lightning-to-35-mm-headphone-jack-adapter/65a4fd4925a1d7572275293e' },
{ name: 'Cello Opalware Dazzle Lush Fiesta Dinner Set 13pcs White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-lush-fiesta-dinner-set-13pcs-white/65a4f63f25a1d72cdd752934' },
{ name: 'Cockatoo Ratcat Series Mini Cruise Triketricycle For Kidstricycle With Eva Wheels Bell Storage Baskettricycles', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-ratcat-series-mini-cruise-triketricycle-for-kidstricycle-with-eva-wheels-bell-storage-baskettricycles/65a500cd25a1d7baf7752943' },
{ name: 'Bevzilla 48 Black Roast Coffee Sachets 96 Gramsdark Wish Coffee Black Roast Rich Dark Instant Coffee Powder Strongest Coffee Expresso Cappuccino Latte Dark Wish Arabica Coffee Bold Intense Premium Coffee Makes 48 Cups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bevzilla-48-black-roast-coffee-sachets-96-gramsdark-wish-coffee-black-roast-rich-dark-instant-coffee-powder-strongest-coffee-expresso-cappuccino-latte-dark-wish-arabica-coffee-bold-intense-premium-coffee-makes-48-cups/65a507d725a1d7825875294d' },
{ name: 'Origami 2 Ply Kitchen Tissue Paper Roll 6 In 1 60 Pulls Per Roll 360 Sheets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/origami-2-ply-kitchen-tissue-paper-roll-6-in-1-60-pulls-per-roll-360-sheets/65a4f2ba25a1d7198475292f' },
{ name: 'Signoraware Lock N Store Round High Microwave Oven Borosilicate Safe Glass Container With Air Tight Lid Multipurpose Jar Box 240ml Transparent Food Grade', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/signoraware-lock-n-store-round-high-microwave-oven-borosilicate-safe-glass-container-with-air-tight-lid-multipurpose-jar-box-240ml-transparent-food-grade/65a4ef3525a1d7478a75292a' },
{ name: 'Status Towels For Bath Towels For Bath Large Size 300gsm Bath Towel For Menwomen Bathing Towel Towels', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-towels-for-bath-towels-for-bath-large-size-300gsm-bath-towel-for-menwomen-bathing-towel-towels/65a4f9c425a1d7e417752939' },
{ name: 'Philips Audio Tat1225 Bluetooth Truly Wireless In Ear Earbuds With Mic Tws With 18 Hours Playtime 612 Ipx4 Voice Assistant Noise And Echo Cancellation Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-audio-tat1225-bluetooth-truly-wireless-in-ear-earbuds-with-mic-tws-with-18-hours-playtime-612-ipx4-voice-assistant-noise-and-echo-cancellation-blue/65a4e1213a524e2bcaa09302' },
{ name: 'Realme Techlife Buds T100 Bluetooth Truly Wireless In Ear Earbuds With Mic Ai Enc', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-techlife-buds-t100-bluetooth-truly-wireless-in-ear-earbuds-with-mic-ai-enc/65a4dd9c3a524e10c9a092fd' },
{ name: 'For Sbi Credit Card Samsung 138 Cm 55 Inches 4k Ultra Hd Smart Qled Tv Qa55qe1caklxl Titan Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-samsung-138-cm-55-inches-4k-ultra-hd-smart-qled-tv-qa55qe1caklxl-titan-gray/65a4da173a524e1126a092f2' },
{ name: 'Beco Matic Natural Laundry Liquid Front Load 2l Super Value Refill Natural Formula', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-matic-natural-laundry-liquid-front-load-2l-super-value-refill-natural-formula/65a4d6905ca8b28dc677472d' },
{ name: 'Signoraware Ocean 165 Litres Jug Water Storage Container Jar Spill Proof Liquid Tight Pourer Bpa Free Food Grade 1650ml Lemon Yellow Plastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/signoraware-ocean-165-litres-jug-water-storage-container-jar-spill-proof-liquid-tight-pourer-bpa-free-food-grade-1650ml-lemon-yellow-plastic/65a4e82c25a1d790eb75291f' },
{ name: 'Radiant Door Dust Air Sound Stopper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/radiant-door-dust-air-sound-stopper/65a4e4a53a524edd6fa09307' },
{ name: 'Amazon Brand Solimo Fast Charging Braided Type C Data Cable Joint Suitable For All Supported Mobile Phones 1 Meter Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-fast-charging-braided-type-c-data-cable-joint-suitable-for-all-supported-mobile-phones-1-meter-black/65a4ebb125a1d75a51752925' },
{ name: 'Dabur Vatika Health Shampoo 640ml With 7 Natural Ingredients For Smooth Shiny Nourished Hair Repairs Hair Damage Controls Frizz For All Hair Types Goodness Of Henna Amla', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-health-shampoo-640ml-with-7-natural-ingredients-for-smooth-shiny-nourished-hair-repairs-hair-damage-controls-frizz-for-all-hair-types-goodness-of-henna-amla/65a4d30b5ca8b242b4774728' },
{ name: 'For Sbi Credit Card Emi Samsung Galaxy A34 5g Awesome Lime 8gb 128gb Storage 48 Mp No Shake Cam Ois Ip67 Gorilla Glass 5 Voice Focus Without Charger', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-samsung-galaxy-a34-5g-awesome-lime-8gb-128gb-storage-48-mp-no-shake-cam-ois-ip67-gorilla-glass-5-voice-focus-without-charger/65a4cf865ca8b29d15774723' },
{ name: 'Size 8 Uk Lotto Mens Vertigo Running Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-uk-lotto-mens-vertigo-running-shoe/65a4c87d5ca8b2233a774719' },
{ name: 'Kwality Corn Flakes 800g Made With Golden Corns 99 Fat Free Natural Source Of Vitamin Iron High In Protein Fiber Healthy Food Breakfast Cereal Low Fat Cholesterol', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kwality-corn-flakes-800g-made-with-golden-corns-99-fat-free-natural-source-of-vitamin-iron-high-in-protein-fiber-healthy-food-breakfast-cereal-low-fat-cholesterol/65a4cc025ca8b26fac77471e' },
{ name: 'Noise Twist Round Dial Smart Watch With Bluetooth Calling 138 Tft Display Up To 7 Days Battery 100 Watch Faces Ip68 Heart Rate Monitor Sleep Tracking Jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-twist-round-dial-smart-watch-with-bluetooth-calling-138-tft-display-up-to-7-days-battery-100-watch-faces-ip68-heart-rate-monitor-sleep-tracking-jet-black/65a4c4f85ca8b2395c774714' },
{ name: 'F Gear Cohort 36 L Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/f-gear-cohort-36-l-backpack/65a4c1745ca8b24a1e77470f' },
{ name: 'Tukzer Multi Purpose Microfiber Cloths Gizga Ultra Soft Absorbent Lint Streak Free Cloth', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tukzer-multi-purpose-microfiber-cloths-gizga-ultra-soft-absorbent-lint-streak-free-cloth/65a4b35b190089b0c71fdaf4' },
{ name: 'Ponds Light Face Moisturizer 200 Ml Daily Lightweight Non Oily Cream With Vitamin E', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-light-face-moisturizer-200-ml-daily-lightweight-non-oily-cream-with-vitamin-e/65a4afd6190089085c1fdaef' },
{ name: 'Cadbury Dairy Milk Bites Almond Roasted Chocolate Coated Rich Luscious Dessert 40 G Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-dairy-milk-bites-almond-roasted-chocolate-coated-rich-luscious-dessert-40-g-pack-of-6/65a4ac5119008924c51fdaea' },
{ name: 'Dabur Vatika Ayurvedic Shampoo 640ml Damage Therapy With Power Of 10 Ingredients', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-ayurvedic-shampoo-640ml-damage-therapy-with-power-of-10-ingredients/65a4a8cc1900894a8f1fdae4' },
{ name: 'Biotique Milk Protein Instant Glow Brightening Face Pack For All Skin Types 175gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-milk-protein-instant-glow-brightening-face-pack-for-all-skin-types-175gm/65a4b6df19008964ec1fdaf9' },
{ name: 'Zebronics Zeb Sound Bomb 1 Tws Earbuds With Bt50 Up To 12h Playback Touch Controls Voice Assistant Splash Proof With Type C Portable Charging Case Black With Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-sound-bomb-1-tws-earbuds-with-bt50-up-to-12h-playback-touch-controls-voice-assistant-splash-proof-with-type-c-portable-charging-case-black-with-green/65a4bdef5ca8b2cd2377470a' },
{ name: 'Fizbiz 2 In 1 X Shot Blaster Toy Gun With Jelly Shots Ball Bulletsapprox 200 Balls 10 Soft Foam Dart Bullets Safe And Long Range Shooting Gun', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fizbiz-2-in-1-x-shot-blaster-toy-gun-with-jelly-shots-ball-bulletsapprox-200-balls-10-soft-foam-dart-bullets-safe-and-long-range-shooting-gun/65a4ba6a5ca8b22186774704' },
{ name: 'Wonderchef Crescent Electric Kettle 15 Litres 2 Years Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-crescent-electric-kettle-15-litres-2-years-warranty/65a4a544729b947dfe855005' },
{ name: 'T2f Girls Casual Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/t2f-girls-casual-dress/65a4a1bf729b949820854fff' },
{ name: 'Bikano Besan Laddu Spl 400 Gram Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikano-besan-laddu-spl-400-gram-box/65a493935edbb53b5a7af337' },
{ name: 'Atomberg Efficio Alpha 1200mm Bldc Motor 5 Star Rated Classic Ceiling Fans With Remote Control High Air Delivery Fan With Led Indicators Upto 65 Energy Saving 11 Year Warranty Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/atomberg-efficio-alpha-1200mm-bldc-motor-5-star-rated-classic-ceiling-fans-with-remote-control-high-air-delivery-fan-with-led-indicators-upto-65-energy-saving-11-year-warranty-black/65a44d8a44a52d92b192ffb3' },
{ name: 'Coirfit Foam Spa Modern Genx Three Fold Reversible 4 Inch Foam Medium Single Size Mattress 72x36x4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/coirfit-foam-spa-modern-genx-three-fold-reversible-4-inch-foam-medium-single-size-mattress-72x36x4/65a41cb1853f4e880a5c68d3' },
{ name: 'Lavie Sport Bankers Mens Wallet Purse', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lavie-sport-bankers-mens-wallet-purse/65a427427c0ab4f782b10ff3' },
{ name: 'Fastrack Analog Grey Dial Womens Watch Nm6158sl02 Nl6158sl02np6158sl02', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-analog-grey-dial-womens-watch-nm6158sl02-nl6158sl02np6158sl02/65a420387c0ab40755b10fdf' },
{ name: 'Nutrela Maxx Walnut Kernals 200g Without Shell Akhrot Giri Dry Fruits Snacks Rich In Omega 3 Protein Fiber', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutrela-maxx-walnut-kernals-200g-without-shell-akhrot-giri-dry-fruits-snacks-rich-in-omega-3-protein-fiber/65a415a8853f4e18be5c68c8' },
{ name: 'Colgate Gentle Sensitive Care Ultra Soft Bristles Toothbrush 5pcs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/colgate-gentle-sensitive-care-ultra-soft-bristles-toothbrush-5pcs/65a412215d139c519f5a25c8' },
{ name: 'Amazon Brand Symbol Womens Quilted Jacket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-womens-quilted-jacket/65a40e9c5d139c20895a25c2' },
{ name: 'Cosmic Byte Gs410 Wired Over Ear Headphones With Mic And', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cosmic-byte-gs410-wired-over-ear-headphones-with-mic-and/65a3dd3052b411098a404839' },
{ name: 'Yuki Unisex Slippers Hawai Chappal Rubber Flip Flops Slippers Comfortable Ultra Light Waterproof Non Slip Thong', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yuki-unisex-slippers-hawai-chappal-rubber-flip-flops-slippers-comfortable-ultra-light-waterproof-non-slip-thong/65a3c36227219723d5b872f2' },
{ name: 'The Moms Co Natural Ceramide Face Toner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-moms-co-natural-ceramide-face-toner/65a3aeba23474f52030b188e' },
{ name: 'Pinnaclz A 250mu Micro Usb Sync 2a Fast Charging Cable Made In India 480mbps 1 Meter Long Usb Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pinnaclz-a-250mu-micro-usb-sync-2a-fast-charging-cable-made-in-india-480mbps-1-meter-long-usb-cable/65a3a51e6b69293455648586' },
{ name: 'Jivo Premium Cold Pressed Kachi Ghani Pure Mustard Oil 5 Litre Healthy Cooking Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jivo-premium-cold-pressed-kachi-ghani-pure-mustard-oil-5-litre-healthy-cooking-oil/65a36fe1578c0e1a1516b97a' },
{ name: 'Bestor Ultra Low Friction Gaming Mouse Mat Anti Slip Base High Durability Waterproof Quick Movements Mousepad', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bestor-ultra-low-friction-gaming-mouse-mat-anti-slip-base-high-durability-waterproof-quick-movements-mousepad/65a382978bdf80336169c1ac' },
{ name: 'For Sbi Credit Card Emi Asus Tuf Gaming F15 1563962 Cms Fhd 144hz Intel Core I5 11400h 11th Gen Rtx 3050 4gb Graphics Gaming Laptop 8gb512gb Ssd90whrs Batterywindows 11black23 Kg Fx506hc Hn089w', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-asus-tuf-gaming-f15-1563962-cms-fhd-144hz-intel-core-i5-11400h-11th-gen-rtx-3050-4gb-graphics-gaming-laptop-8gb512gb-ssd90whrs-batterywindows-11black23-kg-fx506hc-hn089w/65a368b425a9021a2b41fccc' },
{ name: 'V Guard Ecowind Prime Bldc Ceiling Fan For Home Energy Efficient 5 Star Rated 5 Speed Settings For Customised Cooling Convenient Remote Control Ivory 12m', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/v-guard-ecowind-prime-bldc-ceiling-fan-for-home-energy-efficient-5-star-rated-5-speed-settings-for-customised-cooling-convenient-remote-control-ivory-12m/65a3651cadf72163290ad79a' },
{ name: 'Boult Audio Z60 Truly Wireless In Ear Earbuds With 60h Playtime 4 Mics Enc Clear Calling 50ms Low Latency Gaming 13mm Bass Driver Type C Fast Charging Ipx5 Ear Buds Bluetooth 53 Flamingo Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boult-audio-z60-truly-wireless-in-ear-earbuds-with-60h-playtime-4-mics-enc-clear-calling-50ms-low-latency-gaming-13mm-bass-driver-type-c-fast-charging-ipx5-ear-buds-bluetooth-53-flamingo-pink/65a3736eb9e24e09e7382cad' },
{ name: 'Boult Audio Curve Buds Pro With 100h Playtime 4 Mics Clear Calling 40ms Low Latency Gaming Made In India Equalizer Modes Bluetooth 53 Blink Pair True Wireless In Ear Earbuds White Wave', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boult-audio-curve-buds-pro-with-100h-playtime-4-mics-clear-calling-40ms-low-latency-gaming-made-in-india-equalizer-modes-bluetooth-53-blink-pair-true-wireless-in-ear-earbuds-white-wave/65a376f3b9e24ea44e382cbb' },
{ name: 'Striff Laptop Stand Macbook Stand Adjustable Gaming Laptop Computer Stand Multi Angle Stand Phone Stand Portable Foldable Laptop Riser Notebook Holder Stand Compatible', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/striff-laptop-stand-macbook-stand-adjustable-gaming-laptop-computer-stand-multi-angle-stand-phone-stand-portable-foldable-laptop-riser-notebook-holder-stand-compatible/65a36c4a25a902681341fcda' },
{ name: 'Amazon Brand Solimo Wooden Ironing Boardtable With Iron Holder Foldable Adjustable 122 X 40cm Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-wooden-ironing-boardtable-with-iron-holder-foldable-adjustable-122-x-40cm-grey/65a3618735e5cf5217747222' },
{ name: 'Amazon Brand Jam Honey Sniper Evo Foam Blaster Gun 10 Soft Foam Bullets Safe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-jam-honey-sniper-evo-foam-blaster-gun-10-soft-foam-bullets-safe/65a35def9211e209aa1a3462' },
{ name: 'Amazon Brand Solimo Sketchbooks Spiral Bound Mini Size Professional Grade Sheets Compatible With A Range Of Art Material A5 Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-sketchbooks-spiral-bound-mini-size-professional-grade-sheets-compatible-with-a-range-of-art-material-a5-set-of-2/65a356b2bf0484aeb5126740' },
{ name: 'Xl Kitchen Adjustable Washing Machine Trolley', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/xl-kitchen-adjustable-washing-machine-trolley/65a35a4d331f8f900622ec60' },
{ name: 'Eclet Pack Of 12 Air Dry Clay Colorful Children Soft Clay Creative Art Crafts Gifts For Kids Multi Color Non Toxic Modeling Magic Fluffy Foam Bouncing Clay Putty Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eclet-pack-of-12-air-dry-clay-colorful-children-soft-clay-creative-art-crafts-gifts-for-kids-multi-color-non-toxic-modeling-magic-fluffy-foam-bouncing-clay-putty-kit/65a3531b6a64f74e7a01c5d3' },
{ name: 'Ptron Newly Launched Tangent Impulse Safebeats Open Ear Wireless Headphones With Mic 10h Playtime Designed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-tangent-impulse-safebeats-open-ear-wireless-headphones-with-mic-10h-playtime-designed/65a34122004d0b78d06cb50b' },
{ name: 'Bsb Home Premium Super Soft Cloudy Printed Mink Double Bed Blanket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bsb-home-premium-super-soft-cloudy-printed-mink-double-bed-blanket/65a344b85f842538aae7624a' },
{ name: 'Fiama Cooling Shower Gel Menthol Magnolia Body Wash With Skin Conditioners Menthol For Moisturized Cool Skin 250ml Bottle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-cooling-shower-gel-menthol-magnolia-body-wash-with-skin-conditioners-menthol-for-moisturized-cool-skin-250ml-bottle/65a33d8be33bd701b18450fb' },
{ name: 'Lifelong Abs Health Faucet With Ss 304 Grade 10 Meter Flexible Chrome Hose Pipe Wall Hookjet Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-abs-health-faucet-with-ss-304-grade-10-meter-flexible-chrome-hose-pipe-wall-hookjet-spray/65a339f427ec0c909c5bcae6' },
{ name: 'Fostelo Womens Everly Faux Leather Handbag Large', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fostelo-womens-everly-faux-leather-handbag-large/65a3365dc2f77bedc10c2dc1' },
{ name: 'Amazon Basics Wired Gaming Mouse With Up To 6400 Dpi Rgb 4 Programmable Keys', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-wired-gaming-mouse-with-up-to-6400-dpi-rgb-4-programmable-keys/65a332c2b498b74a6675147d' },
{ name: 'Amazon Basics Travel Organiser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-travel-organiser/65a34f838ee4cc509cc880ea' },
{ name: 'Fire Boltt Lumos Stainless Steel Luxury Smart Watch With 191 Large Display Bluetooth Calling Voice Assistant 100 Sports Modes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-lumos-stainless-steel-luxury-smart-watch-with-191-large-display-bluetooth-calling-voice-assistant-100-sports-modes/65a34be98ee4cc12ecc880de' },
{ name: 'Elton Vinyl Self Adhesive Pearl White Marble High Gloss Wallpaper 16 X 72 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/elton-vinyl-self-adhesive-pearl-white-marble-high-gloss-wallpaper-16-x-72-inch/65a3484d2f2a9b87927fdc8f' },
{ name: 'Lifelong Mtb 26t Gear Cycle For Men And Women 21 Speed Mountain Bike Micro Shifter Gear Cycles Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-mtb-26t-gear-cycle-for-men-and-women-21-speed-mountain-bike-micro-shifter-gear-cycles-suitable/65a32f2d11fc75249858f80c' },
{ name: 'Amazon Basics Permanent Fine Point Marker Set Black Pack Of 12', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-permanent-fine-point-marker-set-black-pack-of-12/65a32b9633722ca9766b133b' },
{ name: 'For Sbi Credit Card Emi Hp Pavilion 15 Ryzen 5 5625u 156 Inch396cm Fhd Laptop 8gb Ram512gb Ssd Amd Radeon Graphicsbo Audio Backlit Keypadwin 11alexa175 Kgsnatural Silver 15 Eh2050au', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-hp-pavilion-15-ryzen-5-5625u-156-inch396cm-fhd-laptop-8gb-ram512gb-ssd-amd-radeon-graphicsbo-audio-backlit-keypadwin-11alexa175-kgsnatural-silver-15-eh2050au/65a32800f3e45c2280a8a03e' },
{ name: 'For Sbi Credit Card Zebronics 685cms 27inch Widescreen Monitor Ips Ultra Slim Fhd 1920x1080 Pixels Hdmi Vga 250nits Built In Speaker Wall Mountable 75hz Zeb A27fhd Led Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-zebronics-685cms-27inch-widescreen-monitor-ips-ultra-slim-fhd-1920x1080-pixels-hdmi-vga-250nits-built-in-speaker-wall-mountable-75hz-zeb-a27fhd-led-black/65a31d3b54124c6ed760a758' },
{ name: 'Jbl Commercial Cslm20 Auxiliary Omnidirectional Lavalier Microphone Earphone For Calls Video Conferences And Monitoring Black Small', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jbl-commercial-cslm20-auxiliary-omnidirectional-lavalier-microphone-earphone-for-calls-video-conferences-and-monitoring-black-small/65a3160c2424f2f3fc5740da' },
{ name: 'For Sbi Credit Card Samsung 163 Cm 65 Inches 4k Ultra Hd Smart Led Tv Ua65cu8570ulxl Titan Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-samsung-163-cm-65-inches-4k-ultra-hd-smart-led-tv-ua65cu8570ulxl-titan-grey/65a2e3cabf42dfc3da9979d2' },
{ name: 'For Sbi Credit Card Lenovo L Series 6045 Cm 24 Inch 1920 X 1080 Pixels Fhd Ips Ultraslim Monitor 167 Million Colors 75hz Amd Freesync Hdmi Tilt Vesa Mount Smart Display Customization Artery L24i 30 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-lenovo-l-series-6045-cm-24-inch-1920-x-1080-pixels-fhd-ips-ultraslim-monitor-167-million-colors-75hz-amd-freesync-hdmi-tilt-vesa-mount-smart-display-customization-artery-l24i-30-black/65a320d56c73368ed02ca1f0' },
{ name: 'For Sbi Credit Card Emi Honor Pad X9 With Free Flip Cover 115 Inch 2921 Cm 2k Display Snapdragon 685 7gb 4gb3gb Ram Turbo 128gb Storage 6 Speakers Up To 13 Hours Battery Android 13 Wifi Tablet Metal Body Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-honor-pad-x9-with-free-flip-cover-115-inch-2921-cm-2k-display-snapdragon-685-7gb-4gb3gb-ram-turbo-128gb-storage-6-speakers-up-to-13-hours-battery-android-13-wifi-tablet-metal-body-gray/65a3246b354c0da2a22fdd02' },
{ name: 'For Sbi Credit Card Oppo A79 5g Mystery Black 8gb Ram 128gb Storage 5000 Mah Battery With 33w Supervooc Charger 50mp Ai Rear Camera 672 Fhd 90hz Display With No Cost Emiadditional Exchange Offers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-oppo-a79-5g-mystery-black-8gb-ram-128gb-storage-5000-mah-battery-with-33w-supervooc-charger-50mp-ai-rear-camera-672-fhd-90hz-display-with-no-cost-emiadditional-exchange-offers/65a319a4f8e3acb779de506c' },
{ name: 'Ganesh Wonder Kitchen Dori Handy Vegetable And Fruit Manual Onion Dry Fruit Salad Maker Vegetable Quick String Chopper Machine Cutter 3 Stainless Steel Blades 470 Ml Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-wonder-kitchen-dori-handy-vegetable-and-fruit-manual-onion-dry-fruit-salad-maker-vegetable-quick-string-chopper-machine-cutter-3-stainless-steel-blades-470-ml-grey/65a2accba0a89a433f2e8357' },
{ name: 'Amazon Brand Solimo Nestable Stackable Polypropylene Container Set Round 500 Ml Set Of 12 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nestable-stackable-polypropylene-container-set-round-500-ml-set-of-12-red/65a2b06b92361d52b58e8cb8' },
{ name: 'Ganesh Multipurpose Plastic Vegetable Chopper Cutter 3 Blades For Effortlessly Chopping Vegetables And Fruits', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-multipurpose-plastic-vegetable-chopper-cutter-3-blades-for-effortlessly-chopping-vegetables-and-fruits/65a2a945a0a89a69072e8348' },
{ name: 'Qubo Car Dash Camera Pro X From Hero Group 2mp Fhd 1080p Made In India Dashcam Super Capacitor Wifi With Emergency Recording Easy Diy Set Up Up To 1tb Sd Card Supported Space Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qubo-car-dash-camera-pro-x-from-hero-group-2mp-fhd-1080p-made-in-india-dashcam-super-capacitor-wifi-with-emergency-recording-easy-diy-set-up-up-to-1tb-sd-card-supported-space-grey/65a2a5c0a0a89a3e562e833b' },
{ name: 'Nivea Shea Smooth 400ml Body Lotion 48 H Moisturization With Deep Moisture Serum Shea Butter Non Greasy Healthy Looking Skin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-shea-smooth-400ml-body-lotion-48-h-moisturization-with-deep-moisture-serum-shea-butter-non-greasy-healthy-looking-skin/65a2a23ba0a89a2ae42e8336' },
{ name: 'Dabur Herbl Activated Charcoal Toothpaste 120gblack Gel Toothpastewhitening Toothpastefluoride Freefights Plaqueextrinsic Stainswith Power Of Charcoalmintcoolrefreshing Mouth Experience', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-herbl-activated-charcoal-toothpaste-120gblack-gel-toothpastewhitening-toothpastefluoride-freefights-plaqueextrinsic-stainswith-power-of-charcoalmintcoolrefreshing-mouth-experience/65a29eb6a0a89afea42e832c' },
{ name: 'Lifelong Llym93 Yoga Mat For Women Men Eva Material 4mm Sea Blue Anti Slip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llym93-yoga-mat-for-women-men-eva-material-4mm-sea-blue-anti-slip/65a297ada0a89a05dd2e8322' },
{ name: 'Havells 1200mm Ambrose Energy Saving Ceiling Fan Pearl White Wood Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-1200mm-ambrose-energy-saving-ceiling-fan-pearl-white-wood-pack-of-1/65a290a2a0a89ac4332e8317' },
{ name: 'For Sbi Credit Card Acer Aspire Lite 12th Gen Intel Core I3 1215u Premium Metal Laptop 8gb Ram512gb Ssdwindows 11 Home Al15 52 3962cm 156 Full Hd Display Metal Body Steel Gray 159 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-acer-aspire-lite-12th-gen-intel-core-i3-1215u-premium-metal-laptop-8gb-ram512gb-ssdwindows-11-home-al15-52-3962cm-156-full-hd-display-metal-body-steel-gray-159-kg/65a29b31a0a89a07632e8327' },
{ name: 'Amazon Brand Solimo Car Wax 220 Gms', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-car-wax-220-gms/659ecaac21d5f5806c269139' },
{ name: 'Orient Electric Newly Launched Zeno 1200mm Bldc Motor 5 Star Rated Ceiling Fan With Remote 3 Years Warranty By Orient Decorative Ceiling Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-newly-launched-zeno-1200mm-bldc-motor-5-star-rated-ceiling-fan-with-remote-3-years-warranty-by-orient-decorative-ceiling-fan/65a29428a0a89ab3572e831d' },
{ name: 'Zebronics Zeb Jukebar 9200 Dws Dolby Digital Plus Soundbar Supporting Bluetooth Usbauxoptical Inhdmiarc Wall Mountable With Led Display And Media Controls', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-jukebar-9200-dws-dolby-digital-plus-soundbar-supporting-bluetooth-usbauxoptical-inhdmiarc-wall-mountable-with-led-display-and-media-controls/65a28c9f88bd4db9d8a9ccdc' },
{ name: 'Amazon Basics 1700 W Car Pressure Washer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-1700-w-car-pressure-washer/659ec72821d5f5a58a269134' },
{ name: 'Eveready Led Rechargeable Torch Light Dl31 Overcharge Protection 250 Lumens 3w Torch 1w Sidelight Li Ion Battery With Charging Cable Colour May Vary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-led-rechargeable-torch-light-dl31-overcharge-protection-250-lumens-3w-torch-1w-sidelight-li-ion-battery-with-charging-cable-colour-may-vary/659ece3121d5f57b9126913e' },
{ name: 'Go Desi Choco Meetha Paan Box Of 20 Sachets Mouth Fresheners Desi Mints Refreshing Mouthfreshener Mukhwas No Supari', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-choco-meetha-paan-box-of-20-sachets-mouth-fresheners-desi-mints-refreshing-mouthfreshener-mukhwas-no-supari/659ec3a421d5f5668026912f' },
{ name: 'Hyperx Alloy Origins 60 Usb C Mechanical Gaming Keyboard Ultra Compact 60 Form Factor Red Switch Double Shot Pbt Keycaps Rgb Led Backlit Ngenuity Software Compatible 4p5n4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hyperx-alloy-origins-60-usb-c-mechanical-gaming-keyboard-ultra-compact-60-form-factor-red-switch-double-shot-pbt-keycaps-rgb-led-backlit-ngenuity-software-compatible-4p5n4/659ec02021d5f5fd49269128' },
{ name: 'Sandisk Ultra Curve Usb 32 64gb 100mbs R Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sandisk-ultra-curve-usb-32-64gb-100mbs-r-black/659eb91721d5f553d826911e' },
{ name: 'Mustard Tempo Smart Watch With 169 Hd Display Water Proof Ip68 120 Sports Mode Multiple Watch Faces Dedicated Spo2 Android Ios Dynamic Heart Rate Analysis Notifications Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mustard-tempo-smart-watch-with-169-hd-display-water-proof-ip68-120-sports-mode-multiple-watch-faces-dedicated-spo2-android-ios-dynamic-heart-rate-analysis-notifications-black/659ebc9b21d5f565e5269123' },
{ name: 'Nayasa Plastic Big Corner Rack Light Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-plastic-big-corner-rack-light-ivory/659eb20e21d5f5289a269114' },
{ name: 'Cockatoo Ym100 Yoga Mat For Women Men Anti Slip Eva Material 4mm 6mm Exercise Mat For Home Gym Yoga Mat For Gym Workout And Yoga Exercise 4mm Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-ym100-yoga-mat-for-women-men-anti-slip-eva-material-4mm-6mm-exercise-mat-for-home-gym-yoga-mat-for-gym-workout-and-yoga-exercise-4mm-black/659ea78121d5f5160c2690f4' },
{ name: 'Princeware Plastic Plaid Casserole Set 3 Pcs Pink 31 Liter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/princeware-plastic-plaid-casserole-set-3-pcs-pink-31-liter/659e926721d5f5abe22690b6' },
{ name: 'Havells Trim Clipon Square Led Panel 6w 4000k Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-trim-clipon-square-led-panel-6w-4000k-led-ceiling-light/659e95eb21d5f5850e2690bb' },
{ name: 'Fastrack Limitless Fs1 Smartwatchbiggest 195 Horizon Curve Displaysinglesync Bt Calling V53built In Alexaupto 5 Day Batteryats Chipset100 Sports Modes150 Watchfaces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-limitless-fs1-smartwatchbiggest-195-horizon-curve-displaysinglesync-bt-calling-v53built-in-alexaupto-5-day-batteryats-chipset100-sports-modes150-watchfaces/659e8ee221d5f522482690b1' },
{ name: 'Luvyh Shea Butter Cold Cream And Winter Cream For Men And Women Dry Oily Skin 100g For Moisturizing Hydrating Whitening Brightening Noirishing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luvyh-shea-butter-cold-cream-and-winter-cream-for-men-and-women-dry-oily-skin-100g-for-moisturizing-hydrating-whitening-brightening-noirishing/659e764421d5f55fd426908e' },
{ name: 'Nord Deodorant Body Spray For Men Gunther 120 Ml Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nord-deodorant-body-spray-for-men-gunther-120-ml-pack-of-1/659e6f3b21d5f5b374269084' },
{ name: 'Boroplus Soft Antiseptic Cream Light Non Sticky Provides 24 Hour Moisturisationayurvedic Cream', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-soft-antiseptic-cream-light-non-sticky-provides-24-hour-moisturisationayurvedic-cream/659e72bf21d5f513ee269089' },
{ name: 'Sonata Analog Watch Ep10005sp02', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sonata-analog-watch-ep10005sp02/659e683221d5f5325026907a' },
{ name: 'Park Avenue Voyage Amazon Woods Perfume For Men 120ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-voyage-amazon-woods-perfume-for-men-120ml/659e6bb621d5f54ab226907f' },
{ name: 'Havells Leganza 4b 1200mm 1 Star Energy Saving Ceiling Fan Bronze Gold Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-leganza-4b-1200mm-1-star-energy-saving-ceiling-fan-bronze-gold-pack-of-1/659e8b5e21d5f52ee02690ac' },
{ name: 'Zebster Peak With Tangle Free Design 10mm Drivers In Line Mic Deep Bass 12m Cable Gold Plated 35mm Jack Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebster-peak-with-tangle-free-design-10mm-drivers-in-line-mic-deep-bass-12m-cable-gold-plated-35mm-jack-black/659e79c821d5f54c1c269093' },
{ name: 'E Cosmos Oil Dispenser 1 Litre Cooking Oil Dispenser Bottle Oil Container Kitchen Accessories Items Kitchen Tools Pack Of 1 1000ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-oil-dispenser-1-litre-cooking-oil-dispenser-bottle-oil-container-kitchen-accessories-items-kitchen-tools-pack-of-1-1000ml/659e64ad21d5f55518269075' },
{ name: 'Havells Room Heater Pacifio Mica Convenction 2000 Watt With Micathermic Technology 2 Heat Setting Black Rose Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-room-heater-pacifio-mica-convenction-2000-watt-with-micathermic-technology-2-heat-setting-black-rose-gold/659e87da21d5f578882690a7' },
{ name: 'Pidilite Steelgrip Self Adhesive Pvc Electrical Insulation Tape Color May Vary 650m', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pidilite-steelgrip-self-adhesive-pvc-electrical-insulation-tape-color-may-vary-650m/659e80d121d5f51c8126909d' },
{ name: 'Lux Inferno Mens Cotton Thermal Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lux-inferno-mens-cotton-thermal-set/659e845521d5f5d4b02690a2' },
{ name: 'Showpin 122 In 1 Magnetic Laptop Precision Screwdriver Watch Mini Repair Tools Small Impact Set With Case', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/showpin-122-in-1-magnetic-laptop-precision-screwdriver-watch-mini-repair-tools-small-impact-set-with-case/659e5a1e0a33d37efb9ebce9' },
{ name: 'Graphene Radish Style Pretend Play Cell Phone Toy For Kids Toddlers With 20 Musical Songs Animal Sound For Kids Ringtones Lights Birthday Party Favors And Gifts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/graphene-radish-style-pretend-play-cell-phone-toy-for-kids-toddlers-with-20-musical-songs-animal-sound-for-kids-ringtones-lights-birthday-party-favors-and-gifts/659e53150a33d30d4a9ebcdf' },
{ name: 'Orion Strawberry Chocopie Premium Gift Pack 500g Premium New Year Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orion-strawberry-chocopie-premium-gift-pack-500g-premium-new-year-gift/659e4f910a33d31cea9ebcda' },
{ name: 'Bajaj Ledz 85w Rechargeable Emergency Inverter Led Bulb Cool Day Light White Upto 4 Hours Battery', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-ledz-85w-rechargeable-emergency-inverter-led-bulb-cool-day-light-white-upto-4-hours-battery/659e4c0c0a33d3ef7d9ebcd5' },
{ name: 'Status Contract Microfiber Towels For Bath Soft Coral Fleece Towel With Hook Quick Dry Super Absorbent Stripes Bath Towel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-microfiber-towels-for-bath-soft-coral-fleece-towel-with-hook-quick-dry-super-absorbent-stripes-bath-towel/659e48880a33d3dff49ebcd0' },
{ name: 'Paree Soft Rash Free Sanitary Pads For Womenxl 40 Padsquick Absorptionheavy Flow Championdouble Feathers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/paree-soft-rash-free-sanitary-pads-for-womenxl-40-padsquick-absorptionheavy-flow-championdouble-feathers/659e612921d5f560f5269056' },
{ name: 'Cortina 1 Piece Leaf Design Panel Eyelet Polyester Door Curtain 7 Feet Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-1-piece-leaf-design-panel-eyelet-polyester-door-curtain-7-feet-pink/659e56990a33d3a2ad9ebce4' },
{ name: 'Eveready 9w B22d Emergency Inverter Led Bulb Cool Day Light 6500k Energy Efficient Ibms Technology 4 Hour Battery Backup Li On Battery Inside', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-9w-b22d-emergency-inverter-led-bulb-cool-day-light-6500k-energy-efficient-ibms-technology-4-hour-battery-backup-li-on-battery-inside/659e5da421d5f587eb269050' },
{ name: 'Amazon Brand Solimo 20001000 Watts Room Heater With Adjustable Thermostat Isi Certified White Colour Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-20001000-watts-room-heater-with-adjustable-thermostat-isi-certified-white-colour-ideal/659e45030a33d3f9089ebccb' },
{ name: 'Softouch Face Tissue Paper Box 100 Pull 200 Sheet Each Box Set Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/softouch-face-tissue-paper-box-100-pull-200-sheet-each-box-set-of-4/659e417f0a33d325c99ebcc6' },
{ name: 'Nayasa Plastic Square Funk Bucket Matching Funk Mug And Matching Stoolpatla Set Of 3 By Bansal Group Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-plastic-square-funk-bucket-matching-funk-mug-and-matching-stoolpatla-set-of-3-by-bansal-group-blue/659e3dfa0a33d329ca9ebcc1' },
{ name: 'Neelam Stainless Steel Induction Bottom Sauce Pan 1150ml 1 Piece Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-induction-bottom-sauce-pan-1150ml-1-piece-silver/659e3a760a33d328619ebcbc' },
{ name: 'Syska Sq2001 20w Slm 20watt Slim T5 Tubelight Pack Of 1 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/syska-sq2001-20w-slm-20watt-slim-t5-tubelight-pack-of-1-white/659e36f10a33d3ed4a9ebcb7' },
{ name: 'Kolorr Stitch 7l Plastic Pedal Modern Design Waste Bin Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kolorr-stitch-7l-plastic-pedal-modern-design-waste-bin-black/659e336d0a33d325cf9ebcab' },
{ name: 'Ritu Knife Peeler Multipurpose Kitchen Scissor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ritu-knife-peeler-multipurpose-kitchen-scissor/659e2fe80a33d37a319ebca6' },
{ name: 'Pigeon Inox Hydra Curve 700ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-inox-hydra-curve-700ml/659e2c640a33d3ab699ebca1' },
{ name: 'Clazkit Reusable Rubber Hand Gloves', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clazkit-reusable-rubber-hand-gloves/659e1e52c331c80dadad6692' },
{ name: 'Signoraware Nano Round Steel Small Set Of 2 50ml50ml Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/signoraware-nano-round-steel-small-set-of-2-50ml50ml-black/659e255bc331c8074cad66da' },
{ name: 'Ritu Masala Box With Transparent Lid', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ritu-masala-box-with-transparent-lid/659e21d6c331c8917aad6697' },
{ name: 'Aristo Modern200 Air Tight Storage Container For Kitchen Plastic Jar For Storing Spices Snacks Grains Dals 7 Litretranparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristo-modern200-air-tight-storage-container-for-kitchen-plastic-jar-for-storing-spices-snacks-grains-dals-7-litretranparent/659e28dfc331c824a2ad66df' },
{ name: 'Cello H2o Round Unbreakable Plastic Water Bottle Lid Is Sealed By A Silicone Ring Leak Proof Break Proof Best Usage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-h2o-round-unbreakable-plastic-water-bottle-lid-is-sealed-by-a-silicone-ring-leak-proof-break-proof-best-usage/659e1acec331c87c7bad6685' },
{ name: 'Syska B22d Led Bulb Ssk Srl 9w Pack Of 2 Cool Day Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/syska-b22d-led-bulb-ssk-srl-9w-pack-of-2-cool-day-light/659e1749c331c810d0ad6680' },
{ name: 'Philips Deco Mini Base B22 05 Watt Led Bulb Yellow Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-deco-mini-base-b22-05-watt-led-bulb-yellow-pack-of-3/659e13c5c331c8434aad667b' },
{ name: 'Philips 9 Watts B22 Led Cool Day White Led Bulb Pack Of 1 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-b22-led-cool-day-white-led-bulb-pack-of-1-ace-saver/659e1040c331c81f68ad6676' },
{ name: 'Cutting Edge Airtight Food Saver Containers Combo Tiffin Lunch Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cutting-edge-airtight-food-saver-containers-combo-tiffin-lunch-box/659e022d4736710ecab5b83c' },
{ name: 'Nayasa Ambrosia Insulated Casserole1500 Ml White Plastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-ambrosia-insulated-casserole1500-ml-white-plastic/659dfea71ca99abe96598ef2' },
{ name: 'Syska Ssk Srl Base B22d 9 Watt Led Bulb Pack Of 10 Cool White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/syska-ssk-srl-base-b22d-9-watt-led-bulb-pack-of-10-cool-white/659e0938c331c88ec6ad6660' },
{ name: 'Megaga Shadow Make Up Brush No 55', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/megaga-shadow-make-up-brush-no-55/659df79e1ca99ad964598ee3' },
{ name: 'Halonix 10w B22d Led White Bulb Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-10w-b22d-led-white-bulb-pack-of-6/659df41a1ca99ad42b598ede' },
{ name: 'Clovia Womens Cotton Tiger Print Short Nightdress With Pocket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-tiger-print-short-nightdress-with-pocket/659dfb221ca99a85f9598eed' },
{ name: 'Kleeno By Cello Dura Handy Scourer Red Large', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kleeno-by-cello-dura-handy-scourer-red-large/659e0cbcc331c8c6e7ad6665' },
{ name: 'Makeup Revolution Revolutionary Purse Spray Floral Perfume Opens With Sweet Vanilla Fruity Patchouli Woody', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-revolutionary-purse-spray-floral-perfume-opens-with-sweet-vanilla-fruity-patchouli-woody/659df0951ca99a21cd598ed9' },
{ name: 'Cello Hong Kong Plastic Storage Shelf Ivory Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-hong-kong-plastic-storage-shelf-ivory-yellow/659e05b3c331c8a699ad665a' },
{ name: 'House Wrap Aluminium Foil For Food Packing Cooking Baking Aluminium Foil 9 Meter Net Guaranteed 11 Microns In Thickness', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/house-wrap-aluminium-foil-for-food-packing-cooking-baking-aluminium-foil-9-meter-net-guaranteed-11-microns-in-thickness/659de6081ca99a7677598ec6' },
{ name: 'Peachtree Single Seater Manila Swed Fabric Recliner With Cup Holder Light Brown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/peachtree-single-seater-manila-swed-fabric-recliner-with-cup-holder-light-brown/659ded111ca99ad2d2598ed0' },
{ name: 'Liberti 504 Workmate 3 Step Platform Fiberglass Ladder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/liberti-504-workmate-3-step-platform-fiberglass-ladder/659de98d1ca99a61ec598ecb' },
{ name: 'Qube By Fort Collins Womens Fleece Hooded Neck Blouson Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qube-by-fort-collins-womens-fleece-hooded-neck-blouson-sweatshirt/659dd7f71ca99a43d1598eaa' },
{ name: 'Makeup Revolution Euphoric Foil Palette Sparkle Up 1889 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-euphoric-foil-palette-sparkle-up-1889-g/659dd4731ca99aa20a598ea5' },
{ name: 'Orient Electric Ventilator Dx 200mm Fan Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-ventilator-dx-200mm-fan-ivory/659dd0ee1ca99ae1b3598e9f' },
{ name: 'Appulse Womens Sleeveless Henleypack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/appulse-womens-sleeveless-henleypack-of-2/659dcd68c3bb4151412cd880' },
{ name: 'Verage V Lite Global Navy Blue 30 Liters Water Repellant 175 Laptop Backpack With Rain Cover For Casual Outdoor Office Or Daily Use', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/verage-v-lite-global-navy-blue-30-liters-water-repellant-175-laptop-backpack-with-rain-cover-for-casual-outdoor-office-or-daily-use/659dc65fc3bb4123392cd876' },
{ name: 'Amazon Brand Symbol Men Slim Flexi Waist Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-men-slim-flexi-waist-casual-pants/659ddb7b1ca99a78f5598eaf' },
{ name: 'Butterfly Infinity 5 J 750w Mixer Grinder Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/butterfly-infinity-5-j-750w-mixer-grinder-blue/659de2841ca99a2da8598ec1' },
{ name: 'Amazon Brand House Shields Men Flexi Waist Regular Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-house-shields-men-flexi-waist-regular-casual-pants/659ddf001ca99abf97598ebc' },
{ name: 'Amazon Basics Rj45 Cat 6 Ethernet Patchlan Cable For Smartphonerouterprinter 25feet 76meters Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-rj45-cat-6-ethernet-patchlan-cable-for-smartphonerouterprinter-25feet-76meters-black/659dc9e3c3bb4161712cd87b' },
{ name: 'Tefal Grindforce 750 Watt Mixer Grinder With 3 Jars Blacksilver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tefal-grindforce-750-watt-mixer-grinder-with-3-jars-blacksilver/659dc2dbc3bb4172cd2cd871' },
{ name: 'Amazon Brand Symbol Men Stretch Jogger Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-men-stretch-jogger-pants/659dbf57c3bb41a1f12cd86c' },
{ name: 'Fire Boltt Asteroid 143 Super Amoled Display Smart Watch One Tap Bluetooth Calling 466 466 Px Resolution 123 Sports Modes In Built Voice Assistance 350mah Large Battery Silver Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-asteroid-143-super-amoled-display-smart-watch-one-tap-bluetooth-calling-466-466-px-resolution-123-sports-modes-in-built-voice-assistance-350mah-large-battery-silver-black/659dbbd2c3bb41936e2cd867' },
{ name: 'Panca Tea Glass Set Coffee Glass Set Steel Glass And Cup Set Of 4 South Indian Coffee Glass Set Glass', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-tea-glass-set-coffee-glass-set-steel-glass-and-cup-set-of-4-south-indian-coffee-glass-set-glass/659db145c3bb4197ba2cd858' },
{ name: 'Amazon Brand Symbol Men Regular Flexi Waist Casual Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-men-regular-flexi-waist-casual-pants/659dadc1c3bb413a692cd853' },
{ name: 'Status Contract Single Bed Mink Blanket Single Ply', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-single-bed-mink-blanket-single-ply/659db4cac3bb4116ff2cd85d' },
{ name: 'Longway Blaze 2 Rod 800 Watts Quartz Heater Isi Approved 1 Year Warranty White And Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-blaze-2-rod-800-watts-quartz-heater-isi-approved-1-year-warranty-white-and-grey/659daa3dc3bb417b4d2cd84e' },
{ name: 'Nayasa Plastic Spotty No 1 Fruit Basket Set 2 Piece Small Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-plastic-spotty-no-1-fruit-basket-set-2-piece-small-red/659db84ec3bb413b8f2cd862' },
{ name: 'Tecno Spark 10c Meta Blue8gb Ram128gb Storage16gb Expandable Ram 90hz Refresh Rate 656 Hddot Display16mp Ai Dual Rear Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-spark-10c-meta-blue8gb-ram128gb-storage16gb-expandable-ram-90hz-refresh-rate-656-hddot-display16mp-ai-dual-rear-camera/659da6b8c3bb419b022cd849' },
{ name: 'Cello Fusion Plastic Pedal Dustbin Big Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-fusion-plastic-pedal-dustbin-big-pink/659d9fafc3bb41fae92cd83f' },
{ name: 'Schneider Electric Unica Pure 1m Plastic Surround Gridplate We Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/schneider-electric-unica-pure-1m-plastic-surround-gridplate-we-pack-of-2/659d9c2bc3bb41348b2cd83a' },
{ name: 'Suzec Washing Bowl Strainer For Rice Fruits Vegetable Noodles Pasta Perfect Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/suzec-washing-bowl-strainer-for-rice-fruits-vegetable-noodles-pasta-perfect-size/659da334c3bb410ad02cd844' },
{ name: 'Numero Uno Men Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/numero-uno-men-jeans/659d98a7c3bb41cf172cd835' },
{ name: 'Sky Jewel Unbreakable Pet Water Bottleset Of 31000 Mlmulticolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sky-jewel-unbreakable-pet-water-bottleset-of-31000-mlmulticolor/659d9522c3bb4164b12cd830' },
{ name: 'Mercato Ferrara Robe Hook Black Mattaluminum Mr 511031 Bk', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mercato-ferrara-robe-hook-black-mattaluminum-mr-511031-bk/659d919ec3bb413c872cd82b' },
{ name: 'Glun Magic 3d Led Night Lamp With Plug Smart Sensor Auto Onoff And Color Change Mushroom Flowers Beautiful Illumination Home Decoration Lights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/glun-magic-3d-led-night-lamp-with-plug-smart-sensor-auto-onoff-and-color-change-mushroom-flowers-beautiful-illumination-home-decoration-lights/659d838cab424f1e5d5a0408' },
{ name: 'La Opala Cook Serve Store Borosilicate Storage Container Clip N Store With Snap Lid Square 320 Ml 1 Pc Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-opala-cook-serve-store-borosilicate-storage-container-clip-n-store-with-snap-lid-square-320-ml-1-pc-transparent/659d8e19c3bb4182142cd822' },
{ name: 'Ecraftindia Pink And Transparent Double Sided Crystal Car Ganesha Showpiece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-pink-and-transparent-double-sided-crystal-car-ganesha-showpiece/659d7c83ab424f29f65a03fe' },
{ name: 'Ascension Christmas Tree Xmas Normal Tree With Solid Plastic Legslight Weight Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ascension-christmas-tree-xmas-normal-tree-with-solid-plastic-legslight-weight-perfect/659d8a95872b6d22e2f77314' },
{ name: 'Archies Mothers Day Artificial Hydrangea Silk Flower Bunch Height 11 Cm Number Of Branches 5 Number Of Flowers 5 Pink Green Mothers Day Giftting 16', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-mothers-day-artificial-hydrangea-silk-flower-bunch-height-11-cm-number-of-branches-5-number-of-flowers-5-pink-green-mothers-day-giftting-16/659d71f6ab424f9eee5a03ef' },
{ name: 'Collectible India Rakhi With Gift For Brother And Bhabhi Kids Combo 4 Rakhi For Rakshabandhan Gifts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/collectible-india-rakhi-with-gift-for-brother-and-bhabhi-kids-combo-4-rakhi-for-rakshabandhan-gifts/659d757bab424f61685a03f4' },
{ name: 'Cortina Polyster Linen Solid Sheer Curtain Set With Eyelet Rings Non Blackout Window Curtain Set Of 2 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-polyster-linen-solid-sheer-curtain-set-with-eyelet-rings-non-blackout-window-curtain-set-of-2-white/659d8008ab424f03895a0403' },
{ name: 'Asian Paints Wall Ons Original Mtv Xl Wall Decal Play The Mtv Record Diy Removable Peel And Stick Wall Sticker Covers H 18 Ft X W 15 Ft On The Wall Home Decor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asian-paints-wall-ons-original-mtv-xl-wall-decal-play-the-mtv-record-diy-removable-peel-and-stick-wall-sticker-covers-h-18-ft-x-w-15-ft-on-the-wall-home-decor/659d8710ab424f07a15a040d' },
{ name: 'Dynore Stainless Steel Double Sided Set Of 2 Damru Peg Measure', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-stainless-steel-double-sided-set-of-2-damru-peg-measure/659d78ffab424f348e5a03f9' },
{ name: 'French Connection Womens Rose Analog Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-womens-rose-analog-watch/659d6aeeab424f26685a03da' },
{ name: 'Urbane Analog White Dial Mens Watch U 40600lagi', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbane-analog-white-dial-mens-watch-u-40600lagi/659d6769ab424f1ac35a03d5' },
{ name: 'Msi Modern 15 Intel 13th Gen I5 1335u 40cm Fhd 60hz Laptop 8gb512gb Nvme Ssdwindows 11 Homeiris Xe Graphicsstar Blue17kg B13m 291in', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/msi-modern-15-intel-13th-gen-i5-1335u-40cm-fhd-60hz-laptop-8gb512gb-nvme-ssdwindows-11-homeiris-xe-graphicsstar-blue17kg-b13m-291in/659d6e72ab424f3fcf5a03e8' },
{ name: 'Size L Aurelia Womens Cotton Regular Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-aurelia-womens-cotton-regular-kurta/659d5cdcab424f552e5a03a6' },
{ name: 'Hotmark Ice Candy Maker Mould 6 Pcs Set Kulfi Maker Pack Of 1 Multicolorplastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hotmark-ice-candy-maker-mould-6-pcs-set-kulfi-maker-pack-of-1-multicolorplastic/659d6061ab424f06515a03af' },
{ name: 'Lg 24mp88hv S 24 Inch Ips Slim Led Monitor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-24mp88hv-s-24-inch-ips-slim-led-monitor/659d443d432d483ef8529387' },
{ name: 'Mezonite Mid Back Revolving Leatherette Ergonomic Home Office Executive Chair With High Comfort Seating Height Adjustable Seat Nylon Base Black Abd White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mezonite-mid-back-revolving-leatherette-ergonomic-home-office-executive-chair-with-high-comfort-seating-height-adjustable-seat-nylon-base-black-abd-white/659d40b837ff8332a67d2119' },
{ name: 'Bumtum Baby Gentle 99 Pure Water Soft Moisturizing Wet Wipes With Lid Aloe Vera Chamomile Extracts Paraben Sulfate Free Pack Of 1 72 Pcs Per Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bumtum-baby-gentle-99-pure-water-soft-moisturizing-wet-wipes-with-lid-aloe-vera-chamomile-extracts-paraben-sulfate-free-pack-of-1-72-pcs-per-pack/659d3d3407a356408dfb5fcf' },
{ name: 'Lenovo Tab M7 3rd Gen 7 Inch177cm 2 Gb 32 Gb Wi Fi Only Iron Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-tab-m7-3rd-gen-7-inch177cm-2-gb-32-gb-wi-fi-only-iron-grey/659d249507a356399afb5f9e' },
{ name: 'Kikibix Dark Chocolate Chia Seeds Natural Peanut Butter No Added Sugar Omega 3 Fatty Acids Vegan Non Gmo No Transfat High Protein No Added Oil Or Emulsifiers 340 Gm X Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kikibix-dark-chocolate-chia-seeds-natural-peanut-butter-no-added-sugar-omega-3-fatty-acids-vegan-non-gmo-no-transfat-high-protein-no-added-oil-or-emulsifiers-340-gm-x-pack-of-4/659d211107a35655e2fb5f99' },
{ name: 'Pasabache Penguen Tumbler Set 170ml 6 Pieces Evergreen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pasabache-penguen-tumbler-set-170ml-6-pieces-evergreen/659d1d8c07a3560e7afb5f94' },
{ name: 'Cortina Polyster Linen Solid Sheer Curtain Set With Eyelet Rings Non Blackout Door Curtain Set Of 2 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-polyster-linen-solid-sheer-curtain-set-with-eyelet-rings-non-blackout-door-curtain-set-of-2-white/659d1a0807a35671e9fb5f8f' },
{ name: 'Zingavita Hair Skin Gummies With Biotin Vitamin A C E 30 Gummies', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zingavita-hair-skin-gummies-with-biotin-vitamin-a-c-e-30-gummies/659d168307a356aaf1fb5f89' },
{ name: 'Henko Matic Top Load Detergent Powder 2 Kg Pouch With Power Of Lintelligent Nano Fiber Lock Technology Laundry Detergent Powder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/henko-matic-top-load-detergent-powder-2-kg-pouch-with-power-of-lintelligent-nano-fiber-lock-technology-laundry-detergent-powder/659d12fda8cd41af2ce02aeb' },
{ name: 'Open Secret Chocolate Almond Brownie Snack Box Gift Hamper Zero Refined Sugar No Maida Guilt Chocolate Brownie Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-chocolate-almond-brownie-snack-box-gift-hamper-zero-refined-sugar-no-maida-guilt-chocolate-brownie-pack-of-6/659d39b007a3561f4cfb5fc4' },
{ name: 'Urban Forest Leather Belt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urban-forest-leather-belt/659d2b9e07a35662e1fb5fa8' },
{ name: 'Cellux Single Side 6mm Thick High Density Self Adhesive Foam Seal Tape Weather Stripping Doors And Window Insulation Soundproofing 12mm Width5 Meter Length', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cellux-single-side-6mm-thick-high-density-self-adhesive-foam-seal-tape-weather-stripping-doors-and-window-insulation-soundproofing-12mm-width5-meter-length/659d281a07a3560cb2fb5fa3' },
{ name: 'Ponds Bright Beauty Spf 15 Pa Day Cream 50 G Non Oily Mattifying Daily Face Moisturizer With Niacinamide To Lighten Dark Spots', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-bright-beauty-spf-15-pa-day-cream-50-g-non-oily-mattifying-daily-face-moisturizer-with-niacinamide-to-lighten-dark-spots/659d32a707a3560b89fb5fb2' },
{ name: 'Tex Ro 3 Compartment Lunch Box For Office Men And Lunch Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tex-ro-3-compartment-lunch-box-for-office-men-and-lunch-box/659d362b07a356861dfb5fb7' },
{ name: 'Longway Blaze 2 Rod 800 Watts Quartz Heater Room Heater 1 Year Warranty White And Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-blaze-2-rod-800-watts-quartz-heater-room-heater-1-year-warranty-white-and-grey/659d2f2207a356110dfb5fad' },
{ name: 'Ambrane Type C To Type C 60w Fast Charging L Shape For Easy Holding Pd Technology 480mbps Data Sync Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-type-c-to-type-c-60w-fast-charging-l-shape-for-easy-holding-pd-technology-480mbps-data-sync-cable/659cf6daa8cd4151f6e02ab4' },
{ name: 'Orient Electric Moonlite 6w Led Down Light Cool White Light Round Pack Of 1 Recessed Ceiling Led Light Suited', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-moonlite-6w-led-down-light-cool-white-light-round-pack-of-1-recessed-ceiling-led-light-suited/659d04eba8cd4181d3e02acc' },
{ name: 'Go Desi Peanut Chikki Bar No Added Preservatives And Colours Gajak Sweets Gazak 50 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-peanut-chikki-bar-no-added-preservatives-and-colours-gajak-sweets-gazak-50-pieces/659cf356a8cd411b3de02aa9' },
{ name: 'Halonix Twinkle 10m Blue 46 Led Decorative String Light Diwali Light Fairy Light Festival Light Curtain Light For Decoration Ladiya', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-twinkle-10m-blue-46-led-decorative-string-light-diwali-light-fairy-light-festival-light-curtain-light-for-decoration-ladiya/659cefd1a8cd4169d3e02aa4' },
{ name: 'Gear Origami 35 L Water Resistant Backpack With Rain Coverschool Bagcollege Bagdaypackcasual Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-origami-35-l-water-resistant-backpack-with-rain-coverschool-bagcollege-bagdaypackcasual-backpack/659cfa5ea8cd4100ade02ab9' },
{ name: 'Philips Deco Mini Base B22 05 Watt Led Bulb Pack Of 4 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-deco-mini-base-b22-05-watt-led-bulb-pack-of-4-blue/659ce8c9a8cd410785e02a9a' },
{ name: 'Samsung Galaxy S23 5g Green 8gb 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-s23-5g-green-8gb-128gb-storage/659cec4da8cd4137f3e02a9f' },
{ name: 'E Cosmos Bowl With Water Strainer Multi Use Strainerwasher Bowl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-bowl-with-water-strainer-multi-use-strainerwasher-bowl/659d0167a8cd41187ee02ac7' },
{ name: 'Fastrack Analog Yellow Dial Unisex Adult Watch 9915pp67nq2664sl01', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-analog-yellow-dial-unisex-adult-watch-9915pp67nq2664sl01/659cfde3a8cd4169f0e02abe' },
{ name: 'Dabur Gulabari Perfumed Body Lotion 400ml For Dry Dull Skin Made With 100 Organic Rose Oil Dermatologically Tested And Paraben Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-gulabari-perfumed-body-lotion-400ml-for-dry-dull-skin-made-with-100-organic-rose-oil-dermatologically-tested-and-paraben-free/659d0870a8cd413047e02ad1' },
{ name: 'Status Contract 3d Printed Vintage Persian Bedside Runner Polyester Blend Carpet Rug Anti Skid Backing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-3d-printed-vintage-persian-bedside-runner-polyester-blend-carpet-rug-anti-skid-backing/659d0f78a8cd413f90e02adb' },
{ name: 'Premium Whey Protein 22lbs1kg Rich Chocolate 33 Serving Zero Fats Sugar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/premium-whey-protein-22lbs1kg-rich-chocolate-33-serving-zero-fats-sugar/659d0bf4a8cd4129abe02ad6' },
{ name: 'Havells Instanio Prime 25 Litre Storage Water Heater With Flexi Pipe And Free Installation White Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-instanio-prime-25-litre-storage-water-heater-with-flexi-pipe-and-free-installation-white-blue/659cdab7a8cd412516e02a86' },
{ name: 'Delsey Polyester 71 Cms Black Softsided Check In Luggage Lautaret', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/delsey-polyester-71-cms-black-softsided-check-in-luggage-lautaret/659cd733a8cd41a0a7e02a81' },
{ name: 'Acer Ek240yc 238 Inch 604cm Full Hd 1920x1080 Pixels Va Panel Lcd Monitor With Led Back Light I 250 Nits I Hdmi Vga Ports I Eye Care Features Like Bluelight Shield Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-ek240yc-238-inch-604cm-full-hd-1920x1080-pixels-va-panel-lcd-monitor-with-led-back-light-i-250-nits-i-hdmi-vga-ports-i-eye-care-features-like-bluelight-shield-black/659cd02aa8cd41ee30e02a77' },
{ name: 'Fun Homes Floral Print Plastic Bathroom Stool Blue Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fun-homes-floral-print-plastic-bathroom-stool-blue-pack-of-1/659ce544a8cd417490e02a95' },
{ name: 'Del Monte Tomato Ketchup Classic Blend Spout Pack 500g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/del-monte-tomato-ketchup-classic-blend-spout-pack-500g/659cde3ca8cd411322e02a8b' },
{ name: 'For Icici Hdfc Card Vivo Y28 5g Glitter Aqua 6gb Ram 128gb Storage With No Cost Emiadditional Exchange Offers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-icici-hdfc-card-vivo-y28-5g-glitter-aqua-6gb-ram-128gb-storage-with-no-cost-emiadditional-exchange-offers/659ce1c0a8cd41fdb3e02a90' },
{ name: 'Vivel Body Wash Lavender Almond Oil Shower Creme Fragrant Moisturising For Soft And Smooth Skin High Foaming Formula 500 Ml Pump For Women And Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivel-body-wash-lavender-almond-oil-shower-creme-fragrant-moisturising-for-soft-and-smooth-skin-high-foaming-formula-500-ml-pump-for-women-and-men/659ccca6a8cd41fbf8e02a72' },
{ name: 'Ambrane 20000mah Power Bank 20w Fast Charging Triple Output Type C Pd Input Output Quick Charge Li Polymer Multi Layer Protection', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-20000mah-power-bank-20w-fast-charging-triple-output-type-c-pd-input-output-quick-charge-li-polymer-multi-layer-protection/659cd3afa8cd417c07e02a7c' },
{ name: 'Huggies Complete Comfort Wonder Pants Newborn Extra Small Nbxs Size Up To 5 Kg Baby Diaper Pants90 Countindias Fastest Absorbing Diaper With Upto 4x Faster Unique Dry Xpert Channel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/huggies-complete-comfort-wonder-pants-newborn-extra-small-nbxs-size-up-to-5-kg-baby-diaper-pants90-countindias-fastest-absorbing-diaper-with-upto-4x-faster-unique-dry-xpert-channel/659cc921a8cd4113f1e02a6c' },
{ name: 'Jbl Endurance Runbt Sports In Ear Wireless Bluetooth Earphones With Mic Sweatproof Flexsoft Eartips Magnetic Earbuds Fliphook Twistlock Technology Voice Assistant Support Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jbl-endurance-runbt-sports-in-ear-wireless-bluetooth-earphones-with-mic-sweatproof-flexsoft-eartips-magnetic-earbuds-fliphook-twistlock-technology-voice-assistant-support-black/659cc59bc01384cbd3fe64ea' },
{ name: 'Primelife Plastic Orange Peeler Tool Potato Peelers With Container Orange Peeler Vegetable Peelers For Kitchen Multi Functional Peelers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/primelife-plastic-orange-peeler-tool-potato-peelers-with-container-orange-peeler-vegetable-peelers-for-kitchen-multi-functional-peelers/659cbe92c013840720fe64e0' },
{ name: 'Samsung Galaxy S23 Plus 5g Cream 8gb 256gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-s23-plus-5g-cream-8gb-256gb-storage/659cc216c013844f47fe64e5' },
{ name: 'Nayasa Plastic Frill Basket No2 Peach Ssb062', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-plastic-frill-basket-no2-peach-ssb062/659cacfdc013844c67fe64c1' },
{ name: 'Hp V222w 64gb Usb 20 Pen Drive Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-v222w-64gb-usb-20-pen-drive-silver/659cbb0ec0138412f2fe64db' },
{ name: 'Dell E2223hn5461 Cm Fhd Monitor 1920 X 1080 At 60hzva Panelbrightness 250 Cdm167m Colourscolour Gamut 72cie 1931 83cie 1976 Anti Glarewide Viewing Angle3 Year Warrantyblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-e2223hn5461-cm-fhd-monitor-1920-x-1080-at-60hzva-panelbrightness-250-cdm167m-colourscolour-gamut-72cie-1931-83cie-1976-anti-glarewide-viewing-angle3-year-warrantyblack/659cb405c013845172fe64d1' },
{ name: 'Maxima Max Pro Sky 185 Hd Smart Watch With Bluetooth Calling Ai Voice Assistant Add Personalised Qr Always On Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxima-max-pro-sky-185-hd-smart-watch-with-bluetooth-calling-ai-voice-assistant-add-personalised-qr-always-on-display/659ca270c013840798fe64b2' },
{ name: 'Bon Amour By Sapphire Tiramisu Almonds Milk Chocolate I Extra Smooth Creamy Chocolates With Nuts I Crunchy Almond Covered With Milk Choco 80gm Nutritious And Nutty Crunch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bon-amour-by-sapphire-tiramisu-almonds-milk-chocolate-i-extra-smooth-creamy-chocolates-with-nuts-i-crunchy-almond-covered-with-milk-choco-80gm-nutritious-and-nutty-crunch/659ca5f4c013840f77fe64b7' },
{ name: 'Eveready Ultima Coin Battery 3v Cr2032 Made With High Purity Lithium With Child Proof Packaging Best Suited', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-ultima-coin-battery-3v-cr2032-made-with-high-purity-lithium-with-child-proof-packaging-best-suited/659cb081c01384d474fe64cc' },
{ name: 'Wonder Homeware Exel Small Tray Heavy Quality Set Of 6 Pcs 2 Ltr Blue Yellow Color Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-exel-small-tray-heavy-quality-set-of-6-pcs-2-ltr-blue-yellow-color-red/659ca978c013843329fe64bc' },
{ name: 'Storite Nylon 52 Cm Imported Travel Duffle Bag Expandable Folding Travel Bag For Women Lightweight Waterproof Carry Weekender Overnight Luggage Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/storite-nylon-52-cm-imported-travel-duffle-bag-expandable-folding-travel-bag-for-women-lightweight-waterproof-carry-weekender-overnight-luggage-bag/659cb78ac013844cbdfe64d6' },
{ name: 'Cello Maxi 3 Rectangle Plastic Drawer Peach', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-maxi-3-rectangle-plastic-drawer-peach/659c9eebc01384bffbfe64ad' },
{ name: 'Crompton Tf Hispeed Gale Classic 16 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-tf-hispeed-gale-classic-16-black/659c9b67c01384d422fe64a8' },
{ name: 'Appulse Mens Long Sleeve Mandarin Collar Pack Of 2 Size M', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/appulse-mens-long-sleeve-mandarin-collar-pack-of-2-size-m/659c97e3c01384d33ffe64a3' },
{ name: 'Lloyd 12 Ton 5 Star Inverter Split Ac 5 In 1 Convertible 100 Copper Anti Viral Pm 25 Filter 2023 Model White With Graphic Design Gls15i5fwgev', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lloyd-12-ton-5-star-inverter-split-ac-5-in-1-convertible-100-copper-anti-viral-pm-25-filter-2023-model-white-with-graphic-design-gls15i5fwgev/659c945ec013846bf6fe649e' },
{ name: 'Sbd Safari Abs Plastic Health Faucet Gun With Flexible Stainless Steel Hose Tube And Pvc Holder Chrome Finished Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbd-safari-abs-plastic-health-faucet-gun-with-flexible-stainless-steel-hose-tube-and-pvc-holder-chrome-finished-perfect/659c864dc01384685bfe648a' },
{ name: 'Milton Ernesto Inner Stainless Steel Jr Casserole Set Of 3 420 Ml 850 Ml 143 Litres Grey Easy To Carry Serving Stackable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-ernesto-inner-stainless-steel-jr-casserole-set-of-3-420-ml-850-ml-143-litres-grey-easy-to-carry-serving-stackable/659c7f44c013846aeefe6480' },
{ name: 'Specific Users Fleur Colonie Avocado Moisturizing Body Butter With Cocoa Butter Shea Butter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/specific-users-fleur-colonie-avocado-moisturizing-body-butter-with-cocoa-butter-shea-butter/659c82c9c01384b59cfe6485' },
{ name: 'Boat Bassheads 103 Wired In Ear Earphone With Mic Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-bassheads-103-wired-in-ear-earphone-with-mic-red/659c783cc01384c7c3fe6476' },
{ name: 'Cello Style Knit Small Basket Without Lid Set Of 3 Ivoryplastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-style-knit-small-basket-without-lid-set-of-3-ivoryplastic/659c7bc0c013847bbdfe647b' },
{ name: 'Lavie Sport Atlantis Hexa Laptop Bpk School Bag With Tosaa Lunch Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lavie-sport-atlantis-hexa-laptop-bpk-school-bag-with-tosaa-lunch-box/659c74b7c013844e66fe6471' },
{ name: 'Zebronics Eternal Bluetooth Calling Smart Watch With 185 Large Display Voice Assistant 100 Sports Ip67 Waterproof 11 Built In Customizable Wallpaper 8 Menu Ui Crown And Calculator Metallic Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-eternal-bluetooth-calling-smart-watch-with-185-large-display-voice-assistant-100-sports-ip67-waterproof-11-built-in-customizable-wallpaper-8-menu-ui-crown-and-calculator-metallic-silver/659c89d1c013844c38fe648f' },
{ name: 'Specific Users Environmanly Face Body Cream For Men 100 Gm Spf 20 Shea Butter Brown Rice Wheat Germ Intense Hydration Sun Protection Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/specific-users-environmanly-face-body-cream-for-men-100-gm-spf-20-shea-butter-brown-rice-wheat-germ-intense-hydration-sun-protection-suitable/659c90dac0138472a8fe6499' },
{ name: 'Kent Kettle Multicooker Cum Steamer 12 L 800w Boiling Of Water Tea Eggs Instant Noodle Maker Steaming Idlis Momos Inner Stainless Steel Without Joint And Welding Cool Touch Outer Body', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kent-kettle-multicooker-cum-steamer-12-l-800w-boiling-of-water-tea-eggs-instant-noodle-maker-steaming-idlis-momos-inner-stainless-steel-without-joint-and-welding-cool-touch-outer-body/659c8d56c01384cc3bfe6494' },
{ name: 'Cablecreation Micro Usb Type C To Usb 20 Female Cable 06ft Short Usb C And Micro Usb Otg Cable Compatible With Pixel 3 Xl 2 Xl Galaxy S20s10s10s9s9 018m Space Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cablecreation-micro-usb-type-c-to-usb-20-female-cable-06ft-short-usb-c-and-micro-usb-otg-cable-compatible-with-pixel-3-xl-2-xl-galaxy-s20s10s10s9s9-018m-space-gray/659c7133c01384a71afe646b' },
{ name: 'Qraa One Time Facial Kit Pack Of 5 De Tan Facial Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qraa-one-time-facial-kit-pack-of-5-de-tan-facial-kit/659c6a28b8968e572761960d' },
{ name: 'Lifelong 27 Litre Stainless Steel Insulated Casserole With Plastic Cover Bottom Keeps Food Hotcold For Long Hours', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-27-litre-stainless-steel-insulated-casserole-with-plastic-cover-bottom-keeps-food-hotcold-for-long-hours-/659c6dadb8968e4645619612' },
{ name: 'Gear Carryon 16l Foldable Water Resistant School Baghiking Daypackbackpackcollege Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-carryon-16l-foldable-water-resistant-school-baghiking-daypackbackpackcollege-bag/659c5892b8968e11e66195f4' },
{ name: 'Skybags Klik Daypack 01 Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skybags-klik-daypack-01-grey/659c5c17b8968e69856195f9' },
{ name: 'Gear Modern Eco5 21l Medium Water Resistant School Bagcasual Backpackdaypackcollege Bag For Menwomen Navy Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-modern-eco5-21l-medium-water-resistant-school-bagcasual-backpackdaypackcollege-bag-for-menwomen-navy-blue/659c550eb8968e780a6195ef' },
{ name: 'Dhananjay Food Grade Plastic 1 Litres Oil Dispenser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dhananjay-food-grade-plastic-1-litres-oil-dispenser/659c518ab8968e10456195ea' },
{ name: 'Ecolink 24 Watt Aluminium Batten Warm White Pack Of 4 B22 Led', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-24-watt-aluminium-batten-warm-white-pack-of-4-b22-led/659c4e05b8968e1b286195e5' },
{ name: 'Hindware Smart Appliances Ventus White Birken 1200mm Ceiling Fan Star Rated With Metallic Finish Energy Efficient Air Delivery Fan Comes With 49 W Copper Motor And Unique Aerodynamic Aluminium Blades', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-smart-appliances-ventus-white-birken-1200mm-ceiling-fan-star-rated-with-metallic-finish-energy-efficient-air-delivery-fan-comes-with-49-w-copper-motor-and-unique-aerodynamic-aluminium-blades/659c6320b8968e9194619603' },
{ name: 'Polycab 18w Led Panel Light Scintillate Edge Slim Round Smart Offers Bright Lumination Long Lifespan No Harmful Radiation Warm White 3000k 6 Pcs Cut Out 779 Inches', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-18w-led-panel-light-scintillate-edge-slim-round-smart-offers-bright-lumination-long-lifespan-no-harmful-radiation-warm-white-3000k-6-pcs-cut-out-779-inches/659c5f9bb8968eaf0b6195fe' },
{ name: 'Aco Ventilating Newtone Exhaust Fan A151p', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aco-ventilating-newtone-exhaust-fan-a151p/659c66a4b8968ed948619608' },
{ name: 'Philips Led Deco Yellow 05w Glass Candle Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-led-deco-yellow-05w-glass-candle-pack-of-2/659c4a81b8968e290a6195e0' },
{ name: 'Status Towels For Bath Towels For Bath Large Size 300 Gsm Bath Towel For Menwomen Bathing Towel Towels', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-towels-for-bath-towels-for-bath-large-size-300-gsm-bath-towel-for-menwomen-bathing-towel-towels/659c46fdb8968ea3ae6195db' },
{ name: 'Intex 5ft X 10in Ocean Reef Snapset Pool Bath Toy Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/intex-5ft-x-10in-ocean-reef-snapset-pool-bath-toy-multicolor/659c3ff4b8968e4c0e6195d1' },
{ name: 'Portronics Por 343 Ufo Usb Home Charger 6 Ports 8a Charging Station', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-por-343-ufo-usb-home-charger-6-ports-8a-charging-station/659c4378b8968ea9c46195d6' },
{ name: 'Fitness Mantra Super Soft Anti Slip Marble Design Yoga Mat With Carrying Strap For Men Women Qty 1 Piece 4mm Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitness-mantra-super-soft-anti-slip-marble-design-yoga-mat-with-carrying-strap-for-men-women-qty-1-piece-4mm-blue/659c38ebb8968e12fc6195c7' },
{ name: 'Polyset Super Lock Container Rectangular 1600ml Set Of 4 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polyset-super-lock-container-rectangular-1600ml-set-of-4-black/659c3c70b8968eb4976195cc' },
{ name: 'Body Maxx Pvc Weight Plates Home Gym Combo 14', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/body-maxx-pvc-weight-plates-home-gym-combo-14/659c31e3b8968e26896195bd' },
{ name: 'La Opala Cook Serve Store Borosilicate Storage Container Simply Store With Lid Rectangle 105 L 1 Pc Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-opala-cook-serve-store-borosilicate-storage-container-simply-store-with-lid-rectangle-105-l-1-pc-transparent/659c2e5eb8968eedb26195b4' },
{ name: 'Themisto Th Tds10 Digital Tds Meter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/themisto-th-tds10-digital-tds-meter/659c3567b8968e40bc6195c2' },
{ name: 'Pigeon By Stovekraft Basics Aluminium Non Stick Non Induction Base Frypan 220 Mm Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-basics-aluminium-non-stick-non-induction-base-frypan-220-mm-red/659c2756b8968e45456195aa' },
{ name: 'Philips Deco Mini Base B22 05 Watt Led Bulb Pack Of 4 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-deco-mini-base-b22-05-watt-led-bulb-pack-of-4-red/659c204db8968e67ee619592' },
{ name: 'Sizes M Xl 2xl Clovia Womens Cotton Printed Short Sleeves T Shirt White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-m-xl-2xl-clovia-womens-cotton-printed-short-sleeves-t-shirt-white/659c2adab8968eaf746195af' },
{ name: 'Giggles Magnetic Slate Alphabet And Numbers Learning Board Early Learning 3 Years Above Preschool Toys', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/giggles-magnetic-slate-alphabet-and-numbers-learning-board-early-learning-3-years-above-preschool-toys/659c23d1b8968e6ad3619599' },
{ name: 'Cello Non Stick 12 Cavity Grill Appam Patra 2 Side Handle With Stainless Steel Lid', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-non-stick-12-cavity-grill-appam-patra-2-side-handle-with-stainless-steel-lid/659c1cc9b8968e4fc7619589' },
{ name: 'Toyzone Sun Shine Stroller Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toyzone-sun-shine-stroller-orange/659c194410e9b292ca11fcc2' },
{ name: 'Creative Educational Aids 0700 Animal Puzzle No 0 3 To 6 Piece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/creative-educational-aids-0700-animal-puzzle-no-0-3-to-6-piece/659c15c010e9b2d4c211fca9' },
{ name: 'Dabur Vatika Long Black Shampoo 1l With Amla Bhringhraj For Shiny Long Black Hair No Added Parabens Provides Gentle Cleansing Conditioning And Nourishment To Hair', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-long-black-shampoo-1l-with-amla-bhringhraj-for-shiny-long-black-hair-no-added-parabens-provides-gentle-cleansing-conditioning-and-nourishment-to-hair/659c123b10e9b2120011fc89' },
{ name: 'Hindware Smart Appliances Caeli White Star Rated Ceiling Fan 1200mm 425 Rpm Energy Efficient High Air Delivery Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-smart-appliances-caeli-white-star-rated-ceiling-fan-1200mm-425-rpm-energy-efficient-high-air-delivery-fan/659bf758159e64e986c682c1' },
{ name: 'Qxore Vegetable Storage Box With Removable Drain Plate Stackable Freezer Storage Plastic Container Keep Fresh For Storing Fish Meat Vegetables Fruits1500 Ml Transparent 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qxore-vegetable-storage-box-with-removable-drain-plate-stackable-freezer-storage-plastic-container-keep-fresh-for-storing-fish-meat-vegetables-fruits1500-ml-transparent-4/659bf3d4159e646ee8c682bc' },
{ name: 'Supreme Deals Roller Skates With Front Break', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/supreme-deals-roller-skates-with-front-break/659bf050159e641433c682b7' },
{ name: 'Baidyanath Mahanarayan Tel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/baidyanath-mahanarayan-tel/659beccb159e6435dbc682b2' },
{ name: 'Self Adhesive Shower Curtain Rod U Shape Closet Rod Bracket Drapery Hook Holders For Bathroom Living Room', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/self-adhesive-shower-curtain-rod-u-shape-closet-rod-bracket-drapery-hook-holders-for-bathroom-living-room/659bfe61159e640fb7c682cb' },
{ name: 'Hosley Myrrh Natural Fragrance Incense Sticks Infused With Essential Oils Agarbatti Sticksperfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hosley-myrrh-natural-fragrance-incense-sticks-infused-with-essential-oils-agarbatti-sticksperfect/659bfadc159e649317c682c6' },
{ name: 'Cadbury Celebrations Rich Dry Fruit Collection Chocolate Gift Box 177 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-celebrations-rich-dry-fruit-collection-chocolate-gift-box-177-g/659c01e5159e642b82c682d0' },
{ name: 'Cipla 500 15 Tablets Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cipla-500-15-tablets-pack-of-3/659be947159e645d37c682ad' },
{ name: 'Equinox Digital Thermometer Eq Dt 63 Lcd Display Underarm And Oral Use Lightweight 18 Months Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/equinox-digital-thermometer-eq-dt-63-lcd-display-underarm-and-oral-use-lightweight-18-months-warranty/659be5c3159e64128dc682a4' },
{ name: 'Boldveda Ashwagandha Tablets For Men And Women Ashwagandha Tablets 500mg For Stress Relief Strength With Piperine', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boldveda-ashwagandha-tablets-for-men-and-women-ashwagandha-tablets-500mg-for-stress-relief-strength-with-piperine/659be23e159e64eebec6829f' },
{ name: 'Emwise Sleep Wise Melatonin Gummies Sugar Free Gluten Free Gmo Free Regulate Sleep Wake Cycle Reduce Stress Anxiety Enjoy Sound Sleep Cruelty Free Vegan 30 Delicious Mixed Fruit Gummies', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/emwise-sleep-wise-melatonin-gummies-sugar-free-gluten-free-gmo-free-regulate-sleep-wake-cycle-reduce-stress-anxiety-enjoy-sound-sleep-cruelty-free-vegan-30-delicious-mixed-fruit-gummies/659bdeba159e64841dc6829a' },
{ name: 'Soulflower Vitamin C Face Wash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/soulflower-vitamin-c-face-wash/659bdb36159e643484c68295' },
{ name: 'Sirona Reusable Blink Glow Face Razor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sirona-reusable-blink-glow-face-razor/659bd7b1159e640085c68290' },
{ name: 'Flyloons Green Red Balloons Pack Of 50 For Birthday Decoration Items Also Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flyloons-green-red-balloons-pack-of-50-for-birthday-decoration-items-also-suitable/659bd0a9159e648ed3c68286' },
{ name: 'Godrej Protekt Masterchefs Handwash Refill Lime 725ml 999 Germ Protection With Glycerin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-protekt-masterchefs-handwash-refill-lime-725ml-999-germ-protection-with-glycerin/659bd42d159e6422dac6828b' },
{ name: 'Just Party 25pcs Red 25pcs Golden Metallic Chrome Balloons With Shiny Surface For Birthdaysanniversaryengagementbaby Showerbachelorette Party Decorations Pack Of 50', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/just-party-25pcs-red-25pcs-golden-metallic-chrome-balloons-with-shiny-surface-for-birthdaysanniversaryengagementbaby-showerbachelorette-party-decorations-pack-of-50/659bcd24159e645518c68278' },
{ name: 'Voolex Mini Toy Scooter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/voolex-mini-toy-scooter/659bc297159e64c4fac68269' },
{ name: 'Crackles Animals Theme Baby Milestone Cards Recording 0 12 Months Along With Event Cards Pack Of 21 Cards', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crackles-animals-theme-baby-milestone-cards-recording-0-12-months-along-with-event-cards-pack-of-21-cards/659bc9a0159e64a47ec68273' },
{ name: 'Jack Royal Friction Gear Monster Animal With Flashing Lights Best Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jack-royal-friction-gear-monster-animal-with-flashing-lights-best-gift/659bbf13159e643c86c68264' },
{ name: 'Vgrassp Magic Toy Copybook Doodle And Scribble Toy Book Set Plastic And Cardboard Made Fun Learning Toy With 4 Toy Books 1 Pen 10 Refills 1 Grip Early Learners Boys And Girls', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vgrassp-magic-toy-copybook-doodle-and-scribble-toy-book-set-plastic-and-cardboard-made-fun-learning-toy-with-4-toy-books-1-pen-10-refills-1-grip-early-learners-boys-and-girls/659bbb8f159e642c80c6825f' },
{ name: 'Boroplus Aloe Vera Gel With Green Tea 200 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-aloe-vera-gel-with-green-tea-200-ml/659bb80b159e64b8d2c6825a' },
{ name: 'Vibgyor Vibes Glow In Dark Stickers Of 8 Heavely Bodies Multicolor 8 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vibgyor-vibes-glow-in-dark-stickers-of-8-heavely-bodies-multicolor-8-pieces/659bc61c159e644b72c6826e' },
{ name: 'Halonix 1w Massal Pro Strong Abs Body Flashlight Torch Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-1w-massal-pro-strong-abs-body-flashlight-torch-pack-of-2/659bb486159e645e97c6824c' },
{ name: 'Maharaja Whiteline Aquis Neo Storage Water Heater 10 Litre Glasslined Coating Rust Shock Proof Metal Body Blue White Color Free Installation Aquis Neowh 167', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maharaja-whiteline-aquis-neo-storage-water-heater-10-litre-glasslined-coating-rust-shock-proof-metal-body-blue-white-color-free-installation-aquis-neowh-167/659ba9f9159e648360c6823d' },
{ name: 'Ocean Tango Rock Long Drink Glass Set 315ml Set Of 6 Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ocean-tango-rock-long-drink-glass-set-315ml-set-of-6-transparent/659bb102159e64565ac68247' },
{ name: 'Open Secret Premium Roasted Salted Pistachios 200 G Pista Dry Fruit Tasty Healthy High In Protein Dietary Fiber Gluten Free Low Calorie Nuts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-premium-roasted-salted-pistachios-200-g-pista-dry-fruit-tasty-healthy-high-in-protein-dietary-fiber-gluten-free-low-calorie-nuts/659b9f696f224806d6954fa3' },
{ name: 'Hubberholme Mens Puffer Jacket Size 2xl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hubberholme-mens-puffer-jacket-size-2xl/659b9be5ae314478271a13a8' },
{ name: 'Minitatu Girls Cotton Heart Printed Pajama Bottom', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/minitatu-girls-cotton-heart-printed-pajama-bottom/659ba675159e64ddc8c68223' },
{ name: 'Desidiya Glass 200 Watt Ultra Thin Slim Ip66 Led Flood Light Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/desidiya-glass-200-watt-ultra-thin-slim-ip66-led-flood-light-pack-of-1/659ba2ee6f224898c9954fae' },
{ name: 'Crocs Unisex Adult Ballerina Pink Clogs 8 Uk 425 Eu 9 Us 15907 6gd', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crocs-unisex-adult-ballerina-pink-clogs-8-uk-425-eu-9-us-15907-6gd/659b9860ae31442b7e1a139f' },
{ name: 'Athom Living Light Weight Cotton Premium Waffle Border Bath Towel 75x150 Cm Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athom-living-light-weight-cotton-premium-waffle-border-bath-towel-75x150-cm-pack-of-3/659bad7e159e64b3fcc68242' },
{ name: 'Hubberholme Mens Puffer Jacket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hubberholme-mens-puffer-jacket/659b94dcae3144c6481a139a' },
{ name: 'Atomberg Studio Exhaust Fan 150mm With Bldc Motor Easy To Clean 11 Year Warranty White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/atomberg-studio-exhaust-fan-150mm-with-bldc-motor-easy-to-clean-11-year-warranty-white/659b9158ae3144469f1a1395' },
{ name: 'Toyzone Superman Educational Bus Blocks 111 Pcs 80824 Blocks Game Educational Blocks Bus Pack Educational Toy For Kids Smart Activity Fun And Learning Blocks Colorful Blocks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toyzone-superman-educational-bus-blocks-111-pcs-80824-blocks-game-educational-blocks-bus-pack-educational-toy-for-kids-smart-activity-fun-and-learning-blocks-colorful-blocks/659b8a4fae314496da1a138b' },
{ name: 'Oshotto Car Backseat Storage Organizer Phone Holdermulti Pocket For Bottles Tissue Boxeskids Toy Storage And Great Travel Accessory Compatible With Skoda Laura Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oshotto-car-backseat-storage-organizer-phone-holdermulti-pocket-for-bottles-tissue-boxeskids-toy-storage-and-great-travel-accessory-compatible-with-skoda-laura-black/659b8dd3ae314440fe1a1390' },
{ name: 'Tata Sampann Gulab Jamun Dessert Mix Soft Juicy Buy 1 Get 1 Promo Pack 2x 175g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tata-sampann-gulab-jamun-dessert-mix-soft-juicy-buy-1-get-1-promo-pack-2x-175g/659b7c3eae3144190d1a1373' },
{ name: 'Alcis Women Pink Solid Track Pant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/alcis-women-pink-solid-track-pant/659b86cbae314467a01a1386' },
{ name: 'Sano Pure Honey 500 G Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sano-pure-honey-500-g-pack-of-1/659b7535ae314453f81a1367' },
{ name: 'Everyuth Naturals Body Lotion Soothings Citrus Aloe 500ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/everyuth-naturals-body-lotion-soothings-citrus-aloe-500ml/659b8347ae31448f791a137d' },
{ name: 'Kuber Industries Table Dustbinplastic Swinging Lid Waste Storage Garbage Bindesktop Trash Can For Study Table Officecoffee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-table-dustbinplastic-swinging-lid-waste-storage-garbage-bindesktop-trash-can-for-study-table-officecoffee/659b7fc2ae314459081a1378' },
{ name: 'Sens Edyson 3 Pro With 185 Display Bt Calling Wireless Charging 200 Watch Faces Smart Watch Pink Diamond', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sens-edyson-3-pro-with-185-display-bt-calling-wireless-charging-200-watch-faces-smart-watch-pink-diamond/659b71b1ae314463181a1362' },
{ name: 'Good Knight Gold Flash Liquid Vapourizer Mosquito Repellent Combo Pack Machine Pack Of 3 Refills 45ml Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/good-knight-gold-flash-liquid-vapourizer-mosquito-repellent-combo-pack-machine-pack-of-3-refills-45ml-each/659b6e2dae3144522b1a135d' },
{ name: 'Kuber Industries Iron Stand Stainless Steel Trivet Round Steamer Rack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-iron-stand-stainless-steel-trivet-round-steamer-rack/659b78baae314413821a136e' },
{ name: 'Unibic Foods Snappers Potato Crackers Cream Onion 300 Gms', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-foods-snappers-potato-crackers-cream-onion-300-gms/659b6aa9ae3144db391a134c' },
{ name: 'Home By Nilkamal Polyester 3 Seater Sofa Cover Floral Elegancestretachable Fitted Cover Washable Couch Cover With Universal Fit Set Of 1 230 X 90 Cm Off White Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/home-by-nilkamal-polyester-3-seater-sofa-cover-floral-elegancestretachable-fitted-cover-washable-couch-cover-with-universal-fit-set-of-1-230-x-90-cm-off-white-multicolor/659b63a0ae314407441a1342' },
{ name: 'Oscar Forever Oud 100 Ml Luxury Long Lasting Perfume For Men And Women Oud Fragrance Eau De Parfum Edp', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oscar-forever-oud-100-ml-luxury-long-lasting-perfume-for-men-and-women-oud-fragrance-eau-de-parfum-edp-/659b6724ae31444c181a1347' },
{ name: 'Hindware Smart Appliances Ventus Brown Gold 1200mm Ceiling Fan Star Rated With Metallic Finish Energy Efficient High Speed Air Delivery Fan Comes With 49w Copper Motor And Aerodynamic Aluminium Blades', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-smart-appliances-ventus-brown-gold-1200mm-ceiling-fan-star-rated-with-metallic-finish-energy-efficient-high-speed-air-delivery-fan-comes-with-49w-copper-motor-and-aerodynamic-aluminium-blades/659b601cae314439a21a133d' },
{ name: 'Ferro Lucca Analog Silver Dial Womens Watch F21926c A', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ferro-lucca-analog-silver-dial-womens-watch-f21926c-a/659b477c2678cb7a1e7bd0fd' },
{ name: 'Microtek Mcb 20a Dp C Pro', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/microtek-mcb-20a-dp-c-pro/659b520aae3144adce1a1325' },
{ name: 'Sanwara Mens Regular Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sanwara-mens-regular-shirt/659b40732678cb54077bd0ef' },
{ name: 'Bralcon Organic Mulethi Powder 100g Yashimadhu Liquorice Powder 100 Natural Glycyrrhiza Glabra Skin Whitening Hair Skin Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bralcon-organic-mulethi-powder-100g-yashimadhu-liquorice-powder-100-natural-glycyrrhiza-glabra-skin-whitening-hair-skin-care/659b4b02ae3144a8841a1311' },
{ name: 'Oscar Ice Man Mini Pocket Perfume For Men 8 Ml Long Lasting Fragrance Eau De Parfum Fresh Fragrance Edp', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oscar-ice-man-mini-pocket-perfume-for-men-8-ml-long-lasting-fragrance-eau-de-parfum-fresh-fragrance-edp/659b396a2678cb3e847bd0e1' },
{ name: 'Unibic Foods India Pvt Ltd Choco Kiss Cookies 250g Filled With Chocolate Rich Indulgent Snack Delicious Creamy Flavors Crunchy And Choco Cream Centred Biscuits Made', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-foods-india-pvt-ltd-choco-kiss-cookies-250g-filled-with-chocolate-rich-indulgent-snack-delicious-creamy-flavors-crunchy-and-choco-cream-centred-biscuits-made/659b3cef2678cb1feb7bd0ea' },
{ name: 'Apple Macbook 13 133 Inch Apple Macbook Sleeve Carry Case Bag Ultrathin Snug Fit Sleeve Soft Neoprene Bag Case Sleeve Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-macbook-13-133-inch-apple-macbook-sleeve-carry-case-bag-ultrathin-snug-fit-sleeve-soft-neoprene-bag-case-sleeve-cover/659b43f72678cb650d7bd0f8' },
{ name: 'Kuber Industries Ctktc06002 Stainless Steel Water Bottle 1000ml 2 Pieces Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-ctktc06002-stainless-steel-water-bottle-1000ml-2-pieces-silver/659b4e86ae314409501a1317' },
{ name: 'Pigeon By Stovekraft Cast Iron Long Handle Dosa Tawa 280 Mm Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-cast-iron-long-handle-dosa-tawa-280-mm-black/659b5c97ae314474721a1338' },
{ name: 'Clazkit Plastic Portable Toothbrush Case Box Storage Organizer Cover For Travel Bathroom Hiking Camping Multicolour Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clazkit-plastic-portable-toothbrush-case-box-storage-organizer-cover-for-travel-bathroom-hiking-camping-multicolour-pack-of-2/659b558fae31449d601a132e' },
{ name: 'Rixtec Ice Roller For Face Neck Eyes Massage Reusable Facial Tool For Glowing Tighten Skin Enhance Your Natural Glow And De Puff The Eye Area Cryotherapy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rixtec-ice-roller-for-face-neck-eyes-massage-reusable-facial-tool-for-glowing-tighten-skin-enhance-your-natural-glow-and-de-puff-the-eye-area-cryotherapy/659b5913ae3144f8d61a1333' },
{ name: 'Clovia Womens Cotton Rich Pyjama', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clovia-womens-cotton-rich-pyjama/659b35e62678cb43507bd0dc' },
{ name: 'Sanwara Men Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sanwara-men-kurta/659b24502678cb9efc7bd0c3' },
{ name: 'Global Desi Rhythmic Soul Star Gazer Pack Of 2 Body Mist 200ml Each Long Lasting Scent Spray Gift For Women Perfume Crafted By Ajmal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/global-desi-rhythmic-soul-star-gazer-pack-of-2-body-mist-200ml-each-long-lasting-scent-spray-gift-for-women-perfume-crafted-by-ajmal/659b32622678cb98fc7bd0d7' },
{ name: 'Refurbished Samsung 27 Inch686cm Fhd Monitor Ips 75 Hz Bezel Less Design Amd Freesync Flicker Free Hdmi D Sub Ls27r354fhwxxl Dark Blue Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/refurbished-samsung-27-inch686cm-fhd-monitor-ips-75-hz-bezel-less-design-amd-freesync-flicker-free-hdmi-d-sub-ls27r354fhwxxl-dark-blue-gray/659b20cc2678cb061b7bd0be' },
{ name: 'Biotique Fruit Whiteningbrightening Lip Balm Hydrated And Nourishing Lips Visibly Lighter Lips Evens Out Lip Tone De Pigmentation Balm All Skin Types 12g Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-fruit-whiteningbrightening-lip-balm-hydrated-and-nourishing-lips-visibly-lighter-lips-evens-out-lip-tone-de-pigmentation-balm-all-skin-types-12g-pack-of-2/659b19c32678cbd0857bd0b4' },
{ name: 'Marie Claire Women Blouse', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/marie-claire-women-blouse/659b1d472678cb9a127bd0b9' },
{ name: 'Frenchware Set Of 6 Non Stick Premium Silicone Spatula Kitchen Utensil Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/frenchware-set-of-6-non-stick-premium-silicone-spatula-kitchen-utensil-set/659b27d42678cb6c037bd0c8' },
{ name: 'Bajaj Almond Drops Moisturizing Soap 5 X 125g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-almond-drops-moisturizing-soap-5-x-125g/659b2edd2678cb74f97bd0d2' },
{ name: 'Louis Devin Silicone Strap Analog Wrist Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/louis-devin-silicone-strap-analog-wrist-watch/659b163f2678cb14e27bd0af' },
{ name: 'Rixtec Face Cleansing Sponge Puff Makeup Washing Pad Deep Cleansing Exfoliating Facial Sponge Assorted Color Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rixtec-face-cleansing-sponge-puff-makeup-washing-pad-deep-cleansing-exfoliating-facial-sponge-assorted-color-pack-of-2/659b2b592678cb583c7bd0cd' },
{ name: 'Msi Modern 14 Intel 13th Gen I5 1335u 36cm Fhd 60hz Laptop 8gb512gb Nvme Ssdwindows 11 Homeiris Xe Graphicsclassic Black14kg C13m 437in', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/msi-modern-14-intel-13th-gen-i5-1335u-36cm-fhd-60hz-laptop-8gb512gb-nvme-ssdwindows-11-homeiris-xe-graphicsclassic-black14kg-c13m-437in/659b0f362678cb0c927bd0a5' },
{ name: 'Go Desi Meetha Paan Mouth Fresheners Desi Mints Refreshing Mouthfreshener Mukhwas 2 X 90g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-meetha-paan-mouth-fresheners-desi-mints-refreshing-mouthfreshener-mukhwas-2-x-90g/659b0bb22678cb4ca37bd0a0' },
{ name: 'Diverse Mens Slim Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/diverse-mens-slim-jeans/659b12ba2678cb0c227bd0aa' },
{ name: 'Aurelia Womens Synthetic Straight Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aurelia-womens-synthetic-straight-kurta/659b082d2678cb0a8f7bd09b' },
{ name: 'Ambrane 20w Type C Mobile Charger Adapter Fast Charging', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-20w-type-c-mobile-charger-adapter-fast-charging/659b04a92678cb82377bd096' },
{ name: 'Dynore Stainless Steel Cake Tong', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-stainless-steel-cake-tong/659af696cc8663095dcdbc1a' },
{ name: 'Orient Electric Full Screen 12w Rimless Led Surface Panel Light Cool White Light Round Pack Of 1 Surface Ceiling Led Light No False Ceiling Required', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-full-screen-12w-rimless-led-surface-panel-light-cool-white-light-round-pack-of-1-surface-ceiling-led-light-no-false-ceiling-required/659b01252678cb63297bd091' },
{ name: 'Cp Plus Weatherproof Outdoor Wired Bullet Security Camera 24 Mp 36 Mm Lens', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cp-plus-weatherproof-outdoor-wired-bullet-security-camera-24-mp-36-mm-lens/659aef8dcc86639a35cdbc10' },
{ name: 'Arnv Dustproof Scratch Proof Premium Range Car Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/arnv-dustproof-scratch-proof-premium-range-car-cover/659af311cc86636669cdbc15' },
{ name: 'Orient Electric Quartz Room Heater Qh800asq 800w Power2 Heating Rodscompact Designquick Heatingcool Touch Body1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-quartz-room-heater-qh800asq-800w-power2-heating-rodscompact-designquick-heatingcool-touch-body1-year-warranty/659afa1c2678cb64447bd086' },
{ name: 'Macroman M Series Men Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/macroman-m-series-men-shorts/659afda02678cb512c7bd08c' },
{ name: 'Leotude Mens Solid Half Sleeve With Regular Fit Knitted T Shirt Color Beige', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/leotude-mens-solid-half-sleeve-with-regular-fit-knitted-t-shirt-color-beige/659ae884cc86630211cdbc06' },
{ name: 'Sanifresh Bathroom Cleaner 1l Advanced Thicker Formulation 10x Better Germ Protection Kills 999 Germs Removes Toughtest Stains Long Lasting Fresh Fragrance', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sanifresh-bathroom-cleaner-1l-advanced-thicker-formulation-10x-better-germ-protection-kills-999-germs-removes-toughtest-stains-long-lasting-fresh-fragrance/659aec09cc866364c4cdbc0b' },
{ name: 'Us Polo Assn Kids Boys Boys Shirts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/us-polo-assn-kids-boys-boys-shirts/659ae500cc8663919fcdbc01' },
{ name: 'La French Hope Perfume 30ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-french-hope-perfume-30ml/659ae17bcc86639ebdcdbbfc' },
{ name: 'Lakme Lumi Cream Face Cream With Moisturizer Highlighter Enriched With Niacinamide Hyaluronic Acid Dewy Rose 30g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-lumi-cream-face-cream-with-moisturizer-highlighter-enriched-with-niacinamide-hyaluronic-acid-dewy-rose-30g/659ada46cc866305cdcdbbe4' },
{ name: 'Park Avenue Cool Blue Soap Pack Of 250 4 500gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-cool-blue-soap-pack-of-250-4-500gm/659ace5ff2391f0f79fa3640' },
{ name: 'Fastrack Mens 100 Uv Protected Green Lens Round Sunglasses', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-mens-100-uv-protected-green-lens-round-sunglasses/659ada73cc86633d4ecdbbe9' },
{ name: 'Fastrack Fs1 Pro Smartwatchworlds First 196 Super Amoled Arched Display With Highest Resolution Of 410x502singlesync Bt Callingnitrofast Charging110 Sports Modes200 Watchfaces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-fs1-pro-smartwatchworlds-first-196-super-amoled-arched-display-with-highest-resolution-of-410x502singlesync-bt-callingnitrofast-charging110-sports-modes200-watchfaces/659ac4ba42faee33da08f610' },
{ name: 'Tcl 8004 Cm 32 Inches Bezel Less S Series Hd Ready Smart Android Led Tv 32s5400a Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tcl-8004-cm-32-inches-bezel-less-s-series-hd-ready-smart-android-led-tv-32s5400a-black/659ac3b642faee33da08f60b' },
{ name: 'Wow Skin Science Eau De Parfum Doon Deodars Crisp And Woody All Day Fragrance Long Lasting Unisex Perfume 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-eau-de-parfum-doon-deodars-crisp-and-woody-all-day-fragrance-long-lasting-unisex-perfume-100ml/659ac81c456d854b4a577c2c' },
{ name: 'Xml Bottle Cleaning Brush Silicone Long Handle For Baby Bottle Water Bottle Containers Vase And Glass Bottle Cleaner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/xml-bottle-cleaning-brush-silicone-long-handle-for-baby-bottle-water-bottle-containers-vase-and-glass-bottle-cleaner/659addf7cc86630b8dcdbbef' },
{ name: 'Body Cupid Wild Strawberry Face Wash 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/body-cupid-wild-strawberry-face-wash-100-ml/659ad5717d16f725dd568606' },
{ name: 'Ant Esports Infinity Enc True Wireless Earbuds 53 Bluetooth With 4 Microphone 40h Playtime 50ms Low Latency Ear Bud Sport Noise Cancelling In Ear Headphones Touch Control Game Mode Blackgrey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-infinity-enc-true-wireless-earbuds-53-bluetooth-with-4-microphone-40h-playtime-50ms-low-latency-ear-bud-sport-noise-cancelling-in-ear-headphones-touch-control-game-mode-blackgrey/659abcad42faee33da08f601' },
{ name: 'Milton Eiffel 500 Plastic Insulated Flask 480 Ml Red Bpa Free Easy To Carry Food Grade', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-eiffel-500-plastic-insulated-flask-480-ml-red-bpa-free-easy-to-carry-food-grade/659ac03242faee33da08f606' },
{ name: 'Agaro Elite Hard Anodised Tadka Pan 11 Cm Diameter Riveted Handle 3 Mm Thick Plate Gas Oven Compatible Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-elite-hard-anodised-tadka-pan-11-cm-diameter-riveted-handle-3-mm-thick-plate-gas-oven-compatible-black/659ab5a542faee33da08f5f7' },
{ name: 'Toy Imagine Ramp Roll Ball Tower For Toddlers Ball Drop And Roll Swirling Tower Educational Brain Development Activity Learning Monkey Toys Best Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toy-imagine-ramp-roll-ball-tower-for-toddlers-ball-drop-and-roll-swirling-tower-educational-brain-development-activity-learning-monkey-toys-best-gift/659ab92942faee33da08f5fc' },
{ name: 'Bsb Home 100 Microfiber Bedsheet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bsb-home-100-microfiber-bedsheet/659aa79342faee33da08f5e3' },
{ name: 'Wipro Garnet 12w Led Emergency Bulb Cool Day White 6500k B22 Led Bulb Base Inverter Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-12w-led-emergency-bulb-cool-day-white-6500k-b22-led-bulb-base-inverter-bulb/659aa40f42faee33da08f5de' },
{ name: 'Cortina 1 Piece Damask Design Panel Eyelet Polyester Window Curtain 5 Feet Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-1-piece-damask-design-panel-eyelet-polyester-window-curtain-5-feet-green/659aa08b42faee33da08f5d9' },
{ name: 'Fire Boltt Collide 132 Display Smartwatch Bluetooth Calling With Body Shielding Metal Paint Single Bt Connection Bt 20 Ultra Low Power Consumption Spo2 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-collide-132-display-smartwatch-bluetooth-calling-with-body-shielding-metal-paint-single-bt-connection-bt-20-ultra-low-power-consumption-spo2-black/659a9d0742faee33da08f5d4' },
{ name: 'Wildhorn Blue Leather Wallet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wildhorn-blue-leather-wallet/659ab22142faee33da08f5f2' },
{ name: 'Go Desi Dried Chatpata Amla Candy 300g Indian Gooseberry Fruit Snacks Mouth Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-dried-chatpata-amla-candy-300g-indian-gooseberry-fruit-snacks-mouth-freshener/659aab1842faee33da08f5e8' },
{ name: 'Fire Boltt Emperor Amoled 196 Display Bluetooth Calling Smartwatch Ai Voice Assistant 100 App Based Sports Modes Rotating Crown With Always On Display Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-emperor-amoled-196-display-bluetooth-calling-smartwatch-ai-voice-assistant-100-app-based-sports-modes-rotating-crown-with-always-on-display-pink/659a998242faee33da08f5cf' },
{ name: 'Agaro Hair Straightening Brush Hair Straightening Comb For Women Fast Heating Ionic Care 5 Heat Settings Hair Straightener Hot Brush Gives Naturally Straight Hair In 5 Mins Black Hsb2107', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-hair-straightening-brush-hair-straightening-comb-for-women-fast-heating-ionic-care-5-heat-settings-hair-straightener-hot-brush-gives-naturally-straight-hair-in-5-mins-black-hsb2107/659aae9c42faee33da08f5ed' },
{ name: 'Fire Boltt Edge 178 Amoled Bluetooth Calling Smart Watch With Ai Voice Assistant Gaming 110 Sports Mode Health Suite Rotating Crown Button 368 448 Pixel High Resolution', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-edge-178-amoled-bluetooth-calling-smart-watch-with-ai-voice-assistant-gaming-110-sports-mode-health-suite-rotating-crown-button-368-448-pixel-high-resolution/659a95fe42faee33da08f5ca' },
{ name: 'Fire Boltt Eterno 199 Largest Display Bluetooth Calling Smartwatch With 1184mm Display Area Ai Voice Assistant 120 Sports Modes Yoga With Smart Split Screen To Toggle Between Functions Gaming On The Wrist', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-eterno-199-largest-display-bluetooth-calling-smartwatch-with-1184mm-display-area-ai-voice-assistant-120-sports-modes-yoga-with-smart-split-screen-to-toggle-between-functions-gaming-on-the-wrist/659a927a42faee33da08f5c5' },
{ name: 'Noise Pulse Go Buzz Smart Watch With Advanced Bluetooth Calling 169 Tft Display Spo2 100 Sports Mode With Auto Detection Upto 7 Days Battery 2 Days With Heavy Calling Jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-pulse-go-buzz-smart-watch-with-advanced-bluetooth-calling-169-tft-display-spo2-100-sports-mode-with-auto-detection-upto-7-days-battery-2-days-with-heavy-calling-jet-black/659a8b7142faee33da08f5bb' },
{ name: 'Vega Salon Smooth Hair Straightener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vega-salon-smooth-hair-straightener/659a8ef542faee33da08f5c0' },
{ name: 'Reynolds Aeroslim Bp 25 Ct Jar Blue Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-aeroslim-bp-25-ct-jar-blue-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/659a72d342faee33da08f598' },
{ name: 'Park Avenue Pure Collection Trance Fragrance Body Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-pure-collection-trance-fragrance-body-spray/659a7d6042faee33da08f5a7' },
{ name: 'Ptron Volta 12w Single Port Usb Fast Charger Bis Certified Made In India Wall Charger Adapter Universal Compatibility 1 M Micro Usb Cable Included White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-volta-12w-single-port-usb-fast-charger-bis-certified-made-in-india-wall-charger-adapter-universal-compatibility-1-m-micro-usb-cable-included-white/659a6bca42faee33da08f58e' },
{ name: 'Amfin 10 Inch Light Pink Red Metallic Balloons With Matching Ribbon For Decorationballoon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amfin-10-inch-light-pink-red-metallic-balloons-with-matching-ribbon-for-decorationballoon/659a765742faee33da08f59d' },
{ name: 'Havells Cozio Quartz Room Heater 800 Watt 2 Heat Setting 2 Year Warranty White Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-cozio-quartz-room-heater-800-watt-2-heat-setting-2-year-warranty-white-blue/659a64c142faee33da08f584' },
{ name: 'Lifelong Adjustable Cycling Helmet With Detachable Visoradjustable Light Weight Mountain Bike Cycle Helmet With Padding For Kids And Adultsracing Helmet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-adjustable-cycling-helmet-with-detachable-visoradjustable-light-weight-mountain-bike-cycle-helmet-with-padding-for-kids-and-adultsracing-helmet/659a79db42faee33da08f5a2' },
{ name: 'Presto Disinfectant Surfacefloor Cleaner 2 Litre Citruskills 999 Germs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/presto-disinfectant-surfacefloor-cleaner-2-litre-citruskills-999-germs/659a6f4e42faee33da08f593' },
{ name: 'Infinix Hot 20 5g 128 Gb 6 Gb Ram Blaster Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/infinix-hot-20-5g-128-gb-6-gb-ram-blaster-green/659a684642faee33da08f589' },
{ name: 'Status Contract Status Luxury Premium Polypropylene Home Decor Doormats Floor Door Mat Pack Of 2 58 Cm X 38 Cm Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-status-luxury-premium-polypropylene-home-decor-doormats-floor-door-mat-pack-of-2-58-cm-x-38-cm-green/659a613d42faee33da08f57f' },
{ name: 'Havells Room Heater Pacifio Mica Convenction 2000 Watt With Micathermic Technology 2 Heat Setting Black Rose Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-room-heater-pacifio-mica-convenction-2000-watt-with-micathermic-technology-2-heat-setting-black-rose-gold/659a87ed42faee33da08f5b6' },
{ name: 'Beybee Baby Diaper Nappy Changing Baby Diaper Bagbaby Bagmummy Baghandbag Mamas Bag Diaper Bag Red One Size Dark Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beybee-baby-diaper-nappy-changing-baby-diaper-bagbaby-bagmummy-baghandbag-mamas-bag-diaper-bag-red-one-size-dark-blue/659a846842faee33da08f5b1' },
{ name: 'Wow Skin Science Sugarcane Conditioner For Dry Frizzy And Damaged Hair 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-sugarcane-conditioner-for-dry-frizzy-and-damaged-hair-300ml/659a80e442faee33da08f5ac' },
{ name: 'Size S Lawman Pg3 Mens Track Suit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-lawman-pg3-mens-track-suit/659a4e7642faee33da08f56b' },
{ name: 'Awg All Weather Gear Hoodie For Winter Mens Stylish Warm Sweatshirt With Hood Cozy And Fashionable Cold Weather Apparel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/awg-all-weather-gear-hoodie-for-winter-mens-stylish-warm-sweatshirt-with-hood-cozy-and-fashionable-cold-weather-apparel/659a58dd42faee33da08f575' },
{ name: 'Carsun Tyre Inflator For Car150psi Car Tyre 120 Watts Inflator Pump Dc 12v Portable Air Compressor Pump With Led Lightdigital Pressure Gauge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/carsun-tyre-inflator-for-car150psi-car-tyre-120-watts-inflator-pump-dc-12v-portable-air-compressor-pump-with-led-lightdigital-pressure-gauge/659a472e42faee33da08f55c' },
{ name: 'Vas Collections 220 Gsm Reversible Microfiber Ac Comforterblanketquiltduvets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vas-collections-220-gsm-reversible-microfiber-ac-comforterblanketquiltduvets/659a4af242faee33da08f566' },
{ name: 'Cello Knits Style Plastic Basket Pink Rectangular', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-knits-style-plastic-basket-pink-rectangular/659a476e42faee33da08f561' },
{ name: 'Aula S11 Wired Gaming Mouse Ultra Lightweight Honeycomb Computer Mice With Rgb Backlit 3600 Dpi Optical Sensor 15m Cable Length Usb Computer Mouse', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aula-s11-wired-gaming-mouse-ultra-lightweight-honeycomb-computer-mice-with-rgb-backlit-3600-dpi-optical-sensor-15m-cable-length-usb-computer-mouse/659a450f42faee33da08f552' },
{ name: 'Redmi Buds 4 Active Bass Black 12mm Driverspremium Sound Quality Up To 30 Hours Battery Life Google Fast Pair Ipx4 Bluetooth 53 Enc Up To 60ms Low Latency Mode App Support', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-buds-4-active-bass-black-12mm-driverspremium-sound-quality-up-to-30-hours-battery-life-google-fast-pair-ipx4-bluetooth-53-enc-up-to-60ms-low-latency-mode-app-support/659a51fa42faee33da08f570' },
{ name: 'Dabur Vatika Enriched Coconut Hair Oil 450ml For Strong Thick Shiny Hair Clinically Tested To Reduce 90 Hairfall In 4 Wks Controls Dandruff Prevents Dull Damaged Hair Enriched With 10 Herbs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-enriched-coconut-hair-oil-450ml-for-strong-thick-shiny-hair-clinically-tested-to-reduce-90-hairfall-in-4-wks-controls-dandruff-prevents-dull-damaged-hair-enriched-with-10-herbs/659a5db842faee33da08f57a' },
{ name: 'Parachute Advansed Coconut Water Hydrating Gel Niacinamide Glycerine Insta 2x Hydration 72h Moisturisation Skin Hair 200ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-coconut-water-hydrating-gel-niacinamide-glycerine-insta-2x-hydration-72h-moisturisation-skin-hair-200ml/659a467842faee33da08f557' },
{ name: 'Havells Bt5100c Rechargeable Beard Trimmer With Hypoallergenic Blades Zero Trim With 05 Mm Precisionupto 17mm Length Setting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-bt5100c-rechargeable-beard-trimmer-with-hypoallergenic-blades-zero-trim-with-05-mm-precisionupto-17mm-length-setting/659a318642faee33da08f54d' },
{ name: 'E Cosmos 18 Pcs 3 Different Size Plastic Food Snack Bag Pouch Clip Sealer Large Medium Small Plastic Snack Seal Sealing Bag Clips Vacuum Sealer Set Of 18 Multi Color Mix Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-18-pcs-3-different-size-plastic-food-snack-bag-pouch-clip-sealer-large-medium-small-plastic-snack-seal-sealing-bag-clips-vacuum-sealer-set-of-18-multi-color-mix-size/659a24fa42faee33da08f548' },
{ name: 'Bajaj Almond Drops Nourishing Body Lotion I Long Lasting Moisturization I Almond Oil Vitamin E I 600ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-almond-drops-nourishing-body-lotion-i-long-lasting-moisturization-i-almond-oil-vitamin-e-i-600ml/659a217642faee33da08f543' },
{ name: 'Generic Usb Type C Otg Cable Male To Usb A 30 Female High Speed Data Transfer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/generic-usb-type-c-otg-cable-male-to-usb-a-30-female-high-speed-data-transfer/659a1e5342faee33da08f53e' },
{ name: 'Protecta Bolt 30 L Backpack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/protecta-bolt-30-l-backpack/659a1acf42faee33da08f539' },
{ name: 'Size 6 Uk Liberty Mens Jpl 247 Black Uniform Dress Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-uk-liberty-mens-jpl-247-black-uniform-dress-shoe/659a13c642faee33da08f52f' },
{ name: 'Size S Allen Solly Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-allen-solly-mens-regular-fit-t-shirt/659a174b42faee33da08f534' },
{ name: 'Sounce 100w 6a Speed Fast Charging Usb 30 Cable 4 Ft 12m To 3 In 1 Long Charging Cord With Type C Micro Usb Lightning Connector 480 Mbps Data Transfer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sounce-100w-6a-speed-fast-charging-usb-30-cable-4-ft-12m-to-3-in-1-long-charging-cord-with-type-c-micro-usb-lightning-connector-480-mbps-data-transfer/659a05b542faee33da08f51b' },
{ name: 'Parachute Advansed Deep Nourish Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-deep-nourish-body-lotion/659a023142faee33da08f516' },
{ name: 'Reynolds D Fine Ballpen 5 Ct Blue Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-d-fine-ballpen-5-ct-blue-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/659a0cbe42faee33da08f525' },
{ name: 'Sounce Cleaning Soft Brush Keyboard Cleaner 5 In 1 Multi Function Computer Cleaning Tools Kit Corner Gap Duster Keycap Puller', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sounce-cleaning-soft-brush-keyboard-cleaner-5-in-1-multi-function-computer-cleaning-tools-kit-corner-gap-duster-keycap-puller/659a104242faee33da08f52a' },
{ name: 'Beardo Godfather Perfume Deo Spray 150ml Mafia Perfume Body Spray 120ml Maverick Perfume Deo Spray 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-godfather-perfume-deo-spray-150ml-mafia-perfume-body-spray-120ml-maverick-perfume-deo-spray-150ml/659a093942faee33da08f520' },
{ name: 'Wow Skin Science Vanilla Latte Lip Balm Softens Dry Chapped Lips Protects Lips From Dryness Long Lasting Moisturization Prevent Flaky Lips 16hrs Guaranteed Relief From Dry Chapped Lips 4g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-vanilla-latte-lip-balm-softens-dry-chapped-lips-protects-lips-from-dryness-long-lasting-moisturization-prevent-flaky-lips-16hrs-guaranteed-relief-from-dry-chapped-lips-4g/6599f7a442faee33da08f507' },
{ name: 'Engage W3 Perfume Spray For Women Citrus And Floral Skin Friendly 120ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-w3-perfume-spray-for-women-citrus-and-floral-skin-friendly-120ml/6599fead42faee33da08f511' },
{ name: 'Camay Classic Carnations Roses Beauty Soap With Indulging French Fragrance Moisturizing Bathing Body Soap With Natures Scent Creamy Lather', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/camay-classic-carnations-roses-beauty-soap-with-indulging-french-fragrance-moisturizing-bathing-body-soap-with-natures-scent-creamy-lather/6599fb2842faee33da08f50c' },
{ name: 'Fire Boltt Gladiator 196 Biggest Display Smart Watch With Bluetooth Calling Voice Assistant 123 Sports Modes 8 Unique Ui Interactions Spo2 247 Heart Rate Tracking Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-gladiator-196-biggest-display-smart-watch-with-bluetooth-calling-voice-assistant-123-sports-modes-8-unique-ui-interactions-spo2-247-heart-rate-tracking-black/6599e99342faee33da08f44b' },
{ name: 'Noise Biggest Launch Pro 5 Smart Watch With 185 Amoled Display Bt Calling New Diy Watch Faces Ultra Personalization With Smart Dock Productivity Suite 100 Sports Modes And More Rosegold Elite', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-biggest-launch-pro-5-smart-watch-with-185-amoled-display-bt-calling-new-diy-watch-faces-ultra-personalization-with-smart-dock-productivity-suite-100-sports-modes-and-more-rosegold-elite/6599f42042faee33da08f502' },
{ name: 'Fire Boltt Asteroid 143 Super Amoled Display Smart Watch One Tap Bluetooth Calling 466 466 Px Resolution 123 Sports Modes In Built Voice Assistance 350mah Large Battery Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-asteroid-143-super-amoled-display-smart-watch-one-tap-bluetooth-calling-466-466-px-resolution-123-sports-modes-in-built-voice-assistance-350mah-large-battery-orange/6599e28a42faee33da08f2e6' },
{ name: 'Cortina 3 Layer Reversible Sofa Cover 100 Polyester Quilted Fabric Couch Covers Machine Washable Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-3-layer-reversible-sofa-cover-100-polyester-quilted-fabric-couch-covers-machine-washable-suitable/6599df0642faee33da08f237' },
{ name: 'Lotus Botanicals Skin Brightening Night Cream Vitamin C Silicon Chemical Free All Skin Types 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-botanicals-skin-brightening-night-cream-vitamin-c-silicon-chemical-free-all-skin-types-50g/6599db8242faee33da08f232' },
{ name: 'French Connection Fcuk Analog Mens Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-fcuk-analog-mens-watch/6599f09b42faee33da08f4fd' },
{ name: 'Philips 9 Watts Multipack B22 Led Cool Day White Led Bulb Pack Of 2 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-multipack-b22-led-cool-day-white-led-bulb-pack-of-2-ace-saver/6599e60e42faee33da08f399' },
{ name: 'Bajaj Rhx 2 Halogen Heater2 Heat Settings 400w800wnoiseless Operationduraelement With 1 Yr Heating Element Warranty By Bajajconvection Room Heater For Winter2 Yr Warranty By Bajajblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-rhx-2-halogen-heater2-heat-settings-400w800wnoiseless-operationduraelement-with-1-yr-heating-element-warranty-by-bajajconvection-room-heater-for-winter2-yr-warranty-by-bajajblack/6599ed1742faee33da08f4f5' },
{ name: 'Dove Sandalwood Beauty Bathing Bar 125 Gm Bar Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-sandalwood-beauty-bathing-bar-125-gm-bar-pack-of-3/6599d7fe42faee33da08f22d' },
{ name: 'Reynolds Smoothmate 10 Ct Pens Blue Ball Pen Reynolds Smoothmate 10 Ct Pens Black Ball Pen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-smoothmate-10-ct-pens-blue-ball-pen-reynolds-smoothmate-10-ct-pens-black-ball-pen/6599d0f542faee33da08f223' },
{ name: 'Odonil Gel Pocket Mix 60g Pack Of 30gx2 Assorted Pack Of 3 New Fragrances Infused With Essential Oils Germ Protection Lasts Up To 30 Days Air Freshener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/odonil-gel-pocket-mix-60g-pack-of-30gx2-assorted-pack-of-3-new-fragrances-infused-with-essential-oils-germ-protection-lasts-up-to-30-days-air-freshener/6599cd7142faee33da08f21e' },
{ name: 'The Indian Garage Co Adult Mens Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-indian-garage-co-adult-mens-shirt/6599d47942faee33da08f228' },
{ name: 'Fiama Shower Gel Peach Avocado Body Wash With Skin Conditioners', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-shower-gel-peach-avocado-body-wash-with-skin-conditioners/6599bf6042faee33da08f20a' },
{ name: 'Dabur Vatika Enriched Coconut Hair Oil 900ml 450ml X 2 For Strong Thick Shiny Hair Clinically Tested To Reduce 90 Hairfall In 4 Weeks Good', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-enriched-coconut-hair-oil-900ml-450ml-x-2-for-strong-thick-shiny-hair-clinically-tested-to-reduce-90-hairfall-in-4-weeks-good/6599bbdc42faee33da08f205' },
{ name: 'Boat Bassheads 103 Wired In Ear Earphone With Mic Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-bassheads-103-wired-in-ear-earphone-with-mic-red/6599c9ed42faee33da08f219' },
{ name: 'Fastrack Analog Silver Dial Mens Casual Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-analog-silver-dial-mens-casual-watch/6599b4d342faee33da08f1fb' },
{ name: 'Fitness Mantra Counter 10kg To 60kg Weight Adjustable Professional Counter Hand Gripper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitness-mantra-counter-10kg-to-60kg-weight-adjustable-professional-counter-hand-gripper/6599b14f42faee33da08f1f6' },
{ name: 'Longway Immensio 1500 Watt Immersion Rod Water Heater With Waterproof Shockproof Protection Isi Certified 1 Year Warranty 1500 Watt Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-immensio-1500-watt-immersion-rod-water-heater-with-waterproof-shockproof-protection-isi-certified-1-year-warranty-1500-watt-red/6599b85842faee33da08f200' },
{ name: 'Aeropostale Blue Contrast Neck Brand Applique T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aeropostale-blue-contrast-neck-brand-applique-t-shirt/6599c2e542faee33da08f20f' },
{ name: 'Amazon Basics Universal Mobile Tablet Holder 360 Rotation Long Flexible Alloy Arm Stand For Desk Table Bed Kitchen Bathroom', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-universal-mobile-tablet-holder-360-rotation-long-flexible-alloy-arm-stand-for-desk-table-bed-kitchen-bathroom/6599c66942faee33da08f214' },
{ name: 'Sizes S M L 2xl Puma Mens Cotton Classic Regular Briefs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sizes-s-m-l-2xl-puma-mens-cotton-classic-regular-briefs/6599adcb42faee33da08f1f1' },
{ name: 'Crompton Galaxy Decoration Copper Usb Powered String Fairy Lights With 100 Led Light 10 Meters Warm White Cool White Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-galaxy-decoration-copper-usb-powered-string-fairy-lights-with-100-led-light-10-meters-warm-white-cool-white-pack-of-2/6599aa4642faee33da08f1ec' },
{ name: 'Bajaj Splendora 3 Litre 3kw Iwh Instant Water Heater Geyser White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-splendora-3-litre-3kw-iwh-instant-water-heater-geyser-white/6599a6c242faee33da08f1e7' },
{ name: 'Wipro Garnet 9w Led Bulb For Home Office Warm White 2700k E27 Base220 Degree Light Coverage 4kv Surge Protection 400v High Voltage Protection Energy Efficient Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-9w-led-bulb-for-home-office-warm-white-2700k-e27-base220-degree-light-coverage-4kv-surge-protection-400v-high-voltage-protection-energy-efficient-pack-of-1/6599a33e42faee33da08f1e2' },
{ name: 'Fyltr Womens Sleeveless Casual Top Lightweight Soft Handfeel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fyltr-womens-sleeveless-casual-top-lightweight-soft-handfeel/65999fb942faee33da08f1dc' },
{ name: 'Oshotto Air Fabric Lumbar Support For Office Chair Back Pillow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oshotto-air-fabric-lumbar-support-for-office-chair-back-pillow/65999a1242faee33da08f1c8' },
{ name: 'Klaxon Modern Tv Unit For Living Room Tv Stand Tv Unit Engineered Wood Tv Cabinet Tv Unit With Open Shelf Storage Tv Unit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/klaxon-modern-tv-unit-for-living-room-tv-stand-tv-unit-engineered-wood-tv-cabinet-tv-unit-with-open-shelf-storage-tv-unit/659998a142faee33da08f1c3' },
{ name: 'Lexton Kin Lmp 3042 Ethnic Style Single Head Wall Lamp White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lexton-kin-lmp-3042-ethnic-style-single-head-wall-lamp-white/659996a042faee33da08f1bd' },
{ name: 'Reynolds D Fine Ballpen Blue Pack Of 25 Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-d-fine-ballpen-blue-pack-of-25-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/65999b0542faee33da08f1d2' },
{ name: 'Crompton Laser Ray Neo 20w Led Batten Cool Daylight Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-laser-ray-neo-20w-led-batten-cool-daylight-pack-of-2/65999f8342faee33da08f1d7' },
{ name: 'Agaro Classic Portable Yogurt Maker 12l Capacity Electric Automatic Medium 33603', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-classic-portable-yogurt-maker-12l-capacity-electric-automatic-medium-33603/65999abf42faee33da08f1cd' },
{ name: 'Polycab Etira Plus 5 Litre 3kw Electric Instant Water Heater Geyser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polycab-etira-plus-5-litre-3kw-electric-instant-water-heater-geyser/6599962b42faee33da08f1b8' },
{ name: 'E Cosmos Bowl With Water Strainer Multi Use Strainerwasher Bowl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-bowl-with-water-strainer-multi-use-strainerwasher-bowl-/65997dbe42faee33da08f18e' },
{ name: 'Cello All In One Portable Sanitizersterilizerdisinfected Uv Light White Medium Cloallin1uvsntzrwht', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-all-in-one-portable-sanitizersterilizerdisinfected-uv-light-white-medium-cloallin1uvsntzrwht/659991c742faee33da08f1a8' },
{ name: 'Kuber Industries Canvas Sling Bag For Menbrown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-canvas-sling-bag-for-menbrown/65997d8242faee33da08f182' },
{ name: 'Neelam Stainless Steel 7 22g Diamond Thums Up Glass 300 Ml Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-7-22g-diamond-thums-up-glass-300-ml-silver/65997d2e42faee33da08f17c' },
{ name: 'Khadi Herbal Activated Charcoal Face Wash 100 Natural Paraben Free Cleanses Pollution Dirt From Skin Pack Of 1 210ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/khadi-herbal-activated-charcoal-face-wash-100-natural-paraben-free-cleanses-pollution-dirt-from-skin-pack-of-1-210ml/65996af242faee33da08f163' },
{ name: 'Stanley 70 368e Double Open End Spanner Matte Finish 10 11mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stanley-70-368e-double-open-end-spanner-matte-finish-10-11mm/6599790342faee33da08f177' },
{ name: 'Pigeon By Stovekraft Basics Aluminium Non Stick Non Induction Base Frypan 220 Mm Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-basics-aluminium-non-stick-non-induction-base-frypan-220-mm-red/659971fb42faee33da08f16d' },
{ name: 'Stanley 70 939e High Grade Steel Combination Spanner With Matte Finish 9mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stanley-70-939e-high-grade-steel-combination-spanner-with-matte-finish-9mm/6599757f42faee33da08f172' },
{ name: 'Livon Shake Spray Serum', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/livon-shake-spray-serum/659963e942faee33da08f159' },
{ name: 'Biotique Face Glo Advance Brightening Fruit Cream Visibly Flawless Skin Lightens Skin Tone 100 Botanical Extracts Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-face-glo-advance-brightening-fruit-cream-visibly-flawless-skin-lightens-skin-tone-100-botanical-extracts-suitable/6599606542faee33da08f154' },
{ name: 'Relove By Revolution 2 Hydrating Hyaluronic Acid Serum For Silky Soft And Healthier Looking Skin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/relove-by-revolution-2-hydrating-hyaluronic-acid-serum-for-silky-soft-and-healthier-looking-skin/65996e7642faee33da08f168' },
{ name: 'Nestle Foxs Crystal Clear Passion Mint Candy Imported 90g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nestle-foxs-crystal-clear-passion-mint-candy-imported-90g/6599676d42faee33da08f15e' },
{ name: 'House Wrap Aluminium Foil For Food Packing Cooking Baking Aluminium Foil 9 Meter Net Guaranteed 11 Microns In Thickness', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/house-wrap-aluminium-foil-for-food-packing-cooking-baking-aluminium-foil-9-meter-net-guaranteed-11-microns-in-thickness/65995ce142faee33da08f14f' },
{ name: 'Lifelong Lldc45 Ultra Sonic Care Battery Powered Toothbrush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-lldc45-ultra-sonic-care-battery-powered-toothbrush/6599595c42faee33da08f14a' },
{ name: 'Khadi Natural Rose Honey Body Wash Cleaner And Glowing Skin Fragrance Of Rose And Honeysls Paraben Free Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/khadi-natural-rose-honey-body-wash-cleaner-and-glowing-skin-fragrance-of-rose-and-honeysls-paraben-free-suitable/659955d842faee33da08f145' },
{ name: '999store Printed Wall Mount Mirror Mirror', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/999store-printed-wall-mount-mirror-mirror/6599525442faee33da08f140' },
{ name: 'Creative Arts N Frames 11 Inch Set Of 3 Round Wall Dcor Hand Crafted Wooden Hd Wall Mirror Panel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/creative-arts-n-frames-11-inch-set-of-3-round-wall-dcor-hand-crafted-wooden-hd-wall-mirror-panel/65994ed042faee33da08f13b' },
{ name: 'Asian Paints Living Room Branch Wall Sticker Pvc Vinyl 3048cm X 7620 Cm Multicolour Self Adhesive', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asian-paints-living-room-branch-wall-sticker-pvc-vinyl-3048cm-x-7620-cm-multicolour-self-adhesive/65994b4b42faee33da08f136' },
{ name: 'Crompton Insta Airohot 2000w Heat Convector With Adjustable Thermostats Plastic Body Isi Approved Maroon Standard', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-insta-airohot-2000w-heat-convector-with-adjustable-thermostats-plastic-body-isi-approved-maroon-standard/659939b742faee33da08f11d' },
{ name: 'Cortina 1 Piece Floral Design Panel Eyelet Polyester Window Curtain 5 Feet Maroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-1-piece-floral-design-panel-eyelet-polyester-window-curtain-5-feet-maroon/6599363242faee33da08f118' },
{ name: 'Status Contract Amaze Place Mat Pack Of 2 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-amaze-place-mat-pack-of-2-blue/6599444342faee33da08f12c' },
{ name: 'Kuber Industries Cleaning Towel Reusable Cleaning Cloths For Kitchen Duster Towel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-cleaning-towel-reusable-cleaning-cloths-for-kitchen-duster-towel/659947c742faee33da08f131' },
{ name: 'Targus 3302 3556 Cm 13 14 Cypress Ecosmart Tbs64602gl Sleeve Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/targus-3302-3556-cm-13-14-cypress-ecosmart-tbs64602gl-sleeve-grey/659940bf42faee33da08f127' },
{ name: 'Philips Hair Dryer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-hair-dryer/65993d3b42faee33da08f122' },
{ name: 'Aipl Bopp Packaging Clear Tape 24mm X 50 Meter Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aipl-bopp-packaging-clear-tape-24mm-x-50-meter-pack-of-2-/659932ae42faee33da08f113' },
{ name: 'Dabur Chyawanprash Awaleha 900g With Goodness Of Gur Jaggery 3x Immunity Action No Added Sugar Helps Improve Digestive Respiratory Health With Goodness Of 40 Ayurvedic Herbs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-chyawanprash-awaleha-900g-with-goodness-of-gur-jaggery-3x-immunity-action-no-added-sugar-helps-improve-digestive-respiratory-health-with-goodness-of-40-ayurvedic-herbs/65992f2a42faee33da08f10e' },
{ name: 'Reynolds Brite 30 Ct Blue Dispenser I Lightweight Ball Pen With Comfortable Grip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-brite-30-ct-blue-dispenser-i-lightweight-ball-pen-with-comfortable-grip/6599282142faee33da08f104' },
{ name: 'Hp 150 Black Wired On Ear Headset With In Line Microphonetaggle Free With Long Flat Cord1 Year Rtb Warranty X7b04aa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-150-black-wired-on-ear-headset-with-in-line-microphonetaggle-free-with-long-flat-cord1-year-rtb-warranty-x7b04aa-black/65992ba642faee33da08f109' },
{ name: 'Fitness Mantra Winter Woolen Beanie Cap Muffler', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitness-mantra-winter-woolen-beanie-cap-muffler/65991a1142faee33da08f0f0' },
{ name: 'Reynolds Hd Bold Marker Combo 10 Ct Jar 3 Hd Pm Black 3 Hd Pm Blue 1 Hd Wm Black 1 Hd Wm Blue 1 Hd Pm Red 1 Hd Pm Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-hd-bold-marker-combo-10-ct-jar-3-hd-pm-black-3-hd-pm-blue-1-hd-wm-black-1-hd-wm-blue-1-hd-pm-red-1-hd-pm-green/6599249d42faee33da08f0ff' },
{ name: 'Goldmedal Nixon I Remote Infrared Remote Control', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/goldmedal-nixon-i-remote-infrared-remote-control/6599211942faee33da08f0fa' },
{ name: 'Horlicks Chocolate Health Nutrition Drink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/horlicks-chocolate-health-nutrition-drink/65990f8442faee33da08f0e1' },
{ name: 'Luminous Jaipur Amer Ceiling Fan With 40 Energy Savings High Speed Designer Ceiling Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-jaipur-amer-ceiling-fan-with-40-energy-savings-high-speed-designer-ceiling-fan/65990bff42faee33da08f0dc' },
{ name: 'Wow Skin Science Aloe Vera Shampoo For Hydration And Soothing Scalp For Dry Weak Dull Hair And Irritated Scalp 250ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-aloe-vera-shampoo-for-hydration-and-soothing-scalp-for-dry-weak-dull-hair-and-irritated-scalp-250ml/6599168c42faee33da08f0eb' },
{ name: 'Glun Keyboard Cleaning Brush 5 In 1 Multi Function Computer And Earphone Cleaning Tools Kit Keyboard Brush Tws Cleaner Key Puller Metal Pen Tip High Density Brush Bush Mix', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/glun-keyboard-cleaning-brush-5-in-1-multi-function-computer-and-earphone-cleaning-tools-kit-keyboard-brush-tws-cleaner-key-puller-metal-pen-tip-high-density-brush-bush-mix/65991d9542faee33da08f0f5' },
{ name: 'Chokhi Dhani Food Kadhi Pakora Ready To Eat 300 Grams Servings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chokhi-dhani-food-kadhi-pakora-ready-to-eat-300-grams-servings/6599087b42faee33da08f0d7' },
{ name: 'Reynolds Smoothmate 10 Ct Box Blue Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-smoothmate-10-ct-box-blue-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/6599130842faee33da08f0e6' },
{ name: 'Bajaj Energos 12dc5r 1200mm Silent Bldc Ceiling Fan5starrated Energy Efficient Ceiling Fans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-energos-12dc5r-1200mm-silent-bldc-ceiling-fan5starrated-energy-efficient-ceiling-fans/6599017242faee33da08f0cd' },
{ name: 'Ecolink Vento 300mm Metal Exhaust Fan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-vento-300mm-metal-exhaust-fan/6598fdee42faee33da08f0c8' },
{ name: 'Amazon Brand Solimo Mixingserving Borosilicate Glass Solid Bowl Set 2 Pieces 1600ml Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-mixingserving-borosilicate-glass-solid-bowl-set-2-pieces-1600ml-transparent/659904f742faee33da08f0d2' },
{ name: 'Gizga Essentials Spiral Cable Protector Cord Saver For Mac Charger Iphone Charger Wire Protector Lightweight Durable Flexible Wire Winder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-spiral-cable-protector-cord-saver-for-mac-charger-iphone-charger-wire-protector-lightweight-durable-flexible-wire-winder/6598f6e542faee33da08f0be' },
{ name: 'Loreal Paris Shampoo For Damaged And Weak Hair With Pro Keratin Ceramide Total Repair 5 1ltr', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loreal-paris-shampoo-for-damaged-and-weak-hair-with-pro-keratin-ceramide-total-repair-5-1ltr/6598ec5842faee33da08f0af' },
{ name: 'Ecraftindia Lord Ganesha Tea Light Holder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-lord-ganesha-tea-light-holder/6598e8d442faee33da08f0aa' },
{ name: 'For Card Tecno Spark Go 2024 Mystery White8gb Ram 64gb Rom Segment First 90hz Dot In Display With Dynamic Port Dual Speakers With Dts 5000mah 10w Type C Fingerprint Sensor Octa Core Processor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-card-tecno-spark-go-2024-mystery-white8gb-ram-64gb-rom-segment-first-90hz-dot-in-display-with-dynamic-port-dual-speakers-with-dts-5000mah-10w-type-c-fingerprint-sensor-octa-core-processor/6598e54f42faee33da08f0a5' },
{ name: 'Jbl Endurance Runbt Sports In Ear Wireless Bluetooth Earphones With Mic Sweatproof Flexsoft Eartips Magnetic Earbuds Fliphook Twistlock Technology Voice Assistant Support Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jbl-endurance-runbt-sports-in-ear-wireless-bluetooth-earphones-with-mic-sweatproof-flexsoft-eartips-magnetic-earbuds-fliphook-twistlock-technology-voice-assistant-support-black/6598e1cb42faee33da08f0a0' },
{ name: 'Buildskill Kitchen Liquid Dispenser Transparent Easy Refill Soap Dispenser For Bathroom Kitchen Durable 1 Hand Pump Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buildskill-kitchen-liquid-dispenser-transparent-easy-refill-soap-dispenser-for-bathroom-kitchen-durable-1-hand-pump-ideal/6598de4742faee33da08f09b' },
{ name: 'Toy Imagine 85 Inch Lcd Writing Tablet For Children 3 8 Years Digital Magic Slate Electronic Notepad Scribble Doodle Drawing Rough Pad Best Birthday Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/toy-imagine-85-inch-lcd-writing-tablet-for-children-3-8-years-digital-magic-slate-electronic-notepad-scribble-doodle-drawing-rough-pad-best-birthday-gift/6598fa6a42faee33da08f0c3' },
{ name: 'Lacto Calamine Daily Cleansing Face Wipes With Aloe Vera Cucumber And Vitamin E White Pack Of 2 50 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lacto-calamine-daily-cleansing-face-wipes-with-aloe-vera-cucumber-and-vitamin-e-white-pack-of-2-50-count/6598f36142faee33da08f0b9' },
{ name: 'Renee Fab 3 In 1 Highlighter 45gm 3 Shades In 1 Stick Enriched With Vitamin E Long Lasting Pearl Finish Non Oily Non Sticky Formula', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renee-fab-3-in-1-highlighter-45gm-3-shades-in-1-stick-enriched-with-vitamin-e-long-lasting-pearl-finish-non-oily-non-sticky-formula/6598efdc42faee33da08f0b4' },
{ name: 'Size L T2f Womens Cotton Hooded Neck Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-t2f-womens-cotton-hooded-neck-sweatshirt/6598dac242faee33da08f096' },
{ name: 'Dynore Stainless Steel Double Sided Set Of 2 Damru Peg Measure', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-stainless-steel-double-sided-set-of-2-damru-peg-measure/6598d3ba42faee33da08f08c' },
{ name: 'Kesh King Emami Kesh King Scalp And Hair Medicine Ayurvedic Hairfall Expert Anti Dandruff Shampoo1000 Grams', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesh-king-emami-kesh-king-scalp-and-hair-medicine-ayurvedic-hairfall-expert-anti-dandruff-shampoo1000-grams/6598d73e42faee33da08f091' },
{ name: 'Aashirvaad Organic Masur Dal 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aashirvaad-organic-masur-dal-1kg/6598c92d42faee33da08f07d' },
{ name: 'Beco Compostable Bamboo Toothbrush With Ultra Soft Charcoal Activated Bristles 100 Natural Eco Friendly Pack Of 5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-compostable-bamboo-toothbrush-with-ultra-soft-charcoal-activated-bristles-100-natural-eco-friendly-pack-of-5/6598c5a842faee33da08f078' },
{ name: 'Apsara Platinum Pencils Value Pack Pack Of 20', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apsara-platinum-pencils-value-pack-pack-of-20/6598bea042faee33da08f06e' },
{ name: 'Smart Bottle With Led 500ml Capacity With Premium Black Color Stainless Steel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/smart-bottle-with-led-500ml-capacity-with-premium-black-color-stainless-steel/6598ba2542faee33da08f069' },
{ name: 'Targus Usb 20 4 Port Usb Hub Ach214ap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/targus-usb-20-4-port-usb-hub-ach214ap/6598b8f942faee33da08f064' },
{ name: 'Beardo Hair Growth Vitalizer Shampoo 200 Ml Shampoo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-hair-growth-vitalizer-shampoo-200-ml-shampoo/6598b7cd42faee33da08f05f' },
{ name: 'Amazon Basics 1500w Electric Kettle Stainless Steel Body 15l', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-1500w-electric-kettle-stainless-steel-body-15l/6598c22442faee33da08f073' },
{ name: 'Dove Body Love Supple Bounce Body Lotion 400 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-body-love-supple-bounce-body-lotion-400-ml-/6598ccb142faee33da08f082' },
{ name: 'Happilo Premium Pumpkin Seeds For Eating 200g Each Pack Of 2 Raw Authentic And Natural', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/happilo-premium-pumpkin-seeds-for-eating-200g-each-pack-of-2-raw-authentic-and-natural/6598d03542faee33da08f087' },
{ name: 'Lakme Bi Phasic Remover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-bi-phasic-remover/6598b57442faee33da08f055' },
{ name: 'Beardo Onion Oil Concentrate 25 Ml Onion Hair Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-onion-oil-concentrate-25-ml-onion-hair-oil/6598b44842faee33da08f050' },
{ name: 'Ponds Serum Boost Sunscreen Prevent And Fade Dark Patches With The Power Of Spf 55 And Niacinamide C Serum 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-serum-boost-sunscreen-prevent-and-fade-dark-patches-with-the-power-of-spf-55-and-niacinamide-c-serum-50g/6598b6a142faee33da08f05a' },
{ name: 'Unibic Festive Moment Cookies 500g Gift Hamper For Festivals Sweet Gourmet Delicacies Corporate Gifting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unibic-festive-moment-cookies-500g-gift-hamper-for-festivals-sweet-gourmet-delicacies-corporate-gifting/6598b0c342faee33da08f041' },
{ name: 'Ponds Pure Detox Foaming Brush Facewash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-pure-detox-foaming-brush-facewash/6598b31c42faee33da08f04b' },
{ name: 'Lakm 9to5 Vitamin C Facewash With Microcrystalline Beads For Refreshed Glowing Skin 100 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-9to5-vitamin-c-facewash-with-microcrystalline-beads-for-refreshed-glowing-skin-100-g/6598ad3f42faee33da08f032' },
{ name: 'Avani Metrobuzz Lightweight And Transportable Music Walker With Parent Rod Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/avani-metrobuzz-lightweight-and-transportable-music-walker-with-parent-rod-blue/6598aae642faee33da08f028' },
{ name: 'Lakm Absolute Hydra Pro Gel Day Crme 50 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-absolute-hydra-pro-gel-day-crme-50-g/6598ac1242faee33da08f02d' },
{ name: 'Logitech Mk215 Wireless Keyboard And Mouse Combo 24 Ghz Wireless 3 Years Warranty Compact Design 2 Year Battery Lifekeyboard5 Month Battery Lifemouse Pclaptop Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/logitech-mk215-wireless-keyboard-and-mouse-combo-24-ghz-wireless-3-years-warranty-compact-design-2-year-battery-lifekeyboard5-month-battery-lifemouse-pclaptop-black/6598a88e42faee33da08f01e' },
{ name: 'Lakme Eyeconic Curling Mascara Black Waterproof Mascara With Smart Curl Brush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-eyeconic-curling-mascara-black-waterproof-mascara-with-smart-curl-brush/6598ae6b42faee33da08f037' },
{ name: 'Cello Whitemate Whiteboard Boldmarkers Set Of 6 Assorted Ink Colours Whiteboard Marker With Easily Erasable Ink Refillable Whiteboard Markers Markers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-whitemate-whiteboard-boldmarkers-set-of-6-assorted-ink-colours-whiteboard-marker-with-easily-erasable-ink-refillable-whiteboard-markers-markers/6598af9742faee33da08f03c' },
{ name: 'Targus Asm154mbp6ap 60 154 Inch Magnetic Privacy Screen Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/targus-asm154mbp6ap-60-154-inch-magnetic-privacy-screen-black/6598b1f042faee33da08f046' },
{ name: 'Beco Eco Friendly Baking Wrapping Paper 20 Meter Roll Pack Of 1 100 Bamboo Pulp Chemical Free Parchment Paper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-eco-friendly-baking-wrapping-paper-20-meter-roll-pack-of-1-100-bamboo-pulp-chemical-free-parchment-paper/6598a76142faee33da08f019' },
{ name: 'Excello Biodegradable Wooden Stick Cotton Buds 100sticks 200tips Pack Of 12', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/excello-biodegradable-wooden-stick-cotton-buds-100sticks-200tips-pack-of-12/6598a9ba42faee33da08f023' },
{ name: 'Dynore Stainless Steel Bar Spoon Stirrer Size 112 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-stainless-steel-bar-spoon-stirrer-size-112-inch/6598a63542faee33da08f014' },
{ name: 'Yardley London Morning Dew Refreshing Deo For Women 150ml Pack Of 3 150x3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yardley-london-morning-dew-refreshing-deo-for-women-150ml-pack-of-3-150x3/6598a05842faee33da08effb' },
{ name: 'Longway Hotspring 3 Ltr Automatic Instant Water Heater With Multiple Safety System Rust Proof Abs Body Gray 3 Ltr', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-hotspring-3-ltr-automatic-instant-water-heater-with-multiple-safety-system-rust-proof-abs-body-gray-3-ltr/6598a50942faee33da08f00f' },
{ name: 'Renee Fab 5 Refill Bullet Matte Lipstick L 21 Tictoc Tangerine 15gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renee-fab-5-refill-bullet-matte-lipstick-l-21-tictoc-tangerine-15gm/6598a2b042faee33da08f005' },
{ name: 'Cadbury Celebrations Rich Dry Fruit Collection Chocolate Gift Box 177 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-celebrations-rich-dry-fruit-collection-chocolate-gift-box-177-g/65989f2c42faee33da08eff6' },
{ name: 'Orient Electric Areva Fan Heater2000w Power2 Heating Modescompact Design 1 Year Replacement Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-areva-fan-heater2000w-power2-heating-modescompact-design-1-year-replacement-warranty/65989dff42faee33da08eff1' },
{ name: 'Lakme Sun Expert Spf 50 Pa Tinted Sunscreen 100g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-sun-expert-spf-50-pa-tinted-sunscreen-100g/65989cd342faee33da08efec' },
{ name: 'Wow Skin Science Aloe Vera Hair Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-aloe-vera-hair-oil/6598a3dd42faee33da08f00a' },
{ name: 'Lakm Absolute Gel Stylist Lobster Love 12ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-absolute-gel-stylist-lobster-love-12ml/65989ba742faee33da08efe7' },
{ name: 'Mortein 45ml X 6 Buy 4 Get 2 Free Smartplus Mosquito Repellent Refill Mosquito Repellent Killer 100 Protection From Dengue Mosquitoes Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mortein-45ml-x-6-buy-4-get-2-free-smartplus-mosquito-repellent-refill-mosquito-repellent-killer-100-protection-from-dengue-mosquitoes-pack-of-6/6598a18442faee33da08f000' },
{ name: 'Vania Milk Protein Bleach Cream 250 Gm Long Lasting Glow With Natural Fairnesscool Bleachno Tears Moisturized Skin Zero Irritation Levelwith Fragrancenormal To Dry Skin With Milk Honey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vania-milk-protein-bleach-cream-250-gm-long-lasting-glow-with-natural-fairnesscool-bleachno-tears-moisturized-skin-zero-irritation-levelwith-fragrancenormal-to-dry-skin-with-milk-honey/65989a7b42faee33da08efe2' },
{ name: 'Ponds Bright Beauty Mineral Clay Vitamin B3 4x Oil Absorbing Brightening For Oil Free Instant Glow Face Wash 90 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-bright-beauty-mineral-clay-vitamin-b3-4x-oil-absorbing-brightening-for-oil-free-instant-glow-face-wash-90-g/6598982242faee33da08efd8' },
{ name: 'Lapster 24pcs Mix Spiral Charger Spiral Charger Cable Protectors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lapster-24pcs-mix-spiral-charger-spiral-charger-cable-protectors/6598994e42faee33da08efdd' },
{ name: 'Hp K300 Backlit Membrane Wired Gaming Keyboard With Mixed Color Lighting 4 Led Indicators Matte Finish Double Injection Key Caps And Windows Lock Key 3 Years Warranty4qm95aa', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-k300-backlit-membrane-wired-gaming-keyboard-with-mixed-color-lighting-4-led-indicators-matte-finish-double-injection-key-caps-and-windows-lock-key-3-years-warranty4qm95aa/659895ca42faee33da08efce' },
{ name: 'Portronics Power Plate 5 1500w Power Converter With Usb Charger Mobile Holder 3ac Socket 3usb Ports I 15 M Cord Length White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-power-plate-5-1500w-power-converter-with-usb-charger-mobile-holder-3ac-socket-3usb-ports-i-15-m-cord-length-white/6598949d42faee33da08efc9' },
{ name: 'Attro Noodles Bowl Comes With Inner Stainless Steel Soup Bowl And Spoon Holderlight Weightstackableperfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/attro-noodles-bowl-comes-with-inner-stainless-steel-soup-bowl-and-spoon-holderlight-weightstackableperfect/6598924542faee33da08efbf' },
{ name: 'Surya 6w Moon Pro Led Downlighter Recessed Led Downlight For Ceiling Led Ceiling Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surya-6w-moon-pro-led-downlighter-recessed-led-downlight-for-ceiling-led-ceiling-light/6598937142faee33da08efc4' },
{ name: 'Lifelong Yoga Mat For Women Men Eva Material 4mm Black Anti Slip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-yoga-mat-for-women-men-eva-material-4mm-black-anti-slip/659896f642faee33da08efd3' },
{ name: 'Panca Dry Fruit Cutter And Slicer Choppers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-dry-fruit-cutter-and-slicer-choppers/6598911942faee33da08efba' },
{ name: 'Apollo Tyre 12080 17 61p Tramplr St Tubless Bike Tyre Rear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apollo-tyre-12080-17-61p-tramplr-st-tubless-bike-tyre-rear/65988fec42faee33da08efb5' },
{ name: 'Bajaj Minor Radiant Room Heater For Home1000w Pedestal Heater For Winterelectric Heater For Roomstainless Steel Heat Reflectornickel Chrome Plated Mesh Guardtilted Legs2 Year Warranty By Bajaj', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-minor-radiant-room-heater-for-home1000w-pedestal-heater-for-winterelectric-heater-for-roomstainless-steel-heat-reflectornickel-chrome-plated-mesh-guardtilted-legs2-year-warranty-by-bajaj/65988ec042faee33da08efb0' },
{ name: 'Orient Electric Fabristyle 1100 Watt Dry Iron For Clothes With Weilburger Coated Non Stick Soleplate Silver Layered Thermostat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-fabristyle-1100-watt-dry-iron-for-clothes-with-weilburger-coated-non-stick-soleplate-silver-layered-thermostat/65988d9442faee33da08efab' },
{ name: 'Zandu Dantveer 200g Indias 1st Ayurvedic Toothpaste With Irimedadi Oil Scientifically Proven', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zandu-dantveer-200g-indias-1st-ayurvedic-toothpaste-with-irimedadi-oil-scientifically-proven/65988c6842faee33da08efa6' },
{ name: 'Makeup Revolution Clueless X Revolution Dionne Phone Nude Eyeshadow Palette Long Lasting Multi Reflective Shimmer Matte Glitter Pressed Pearls Eye Shadow Makeup Pallet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-clueless-x-revolution-dionne-phone-nude-eyeshadow-palette-long-lasting-multi-reflective-shimmer-matte-glitter-pressed-pearls-eye-shadow-makeup-pallet/65988b3b42faee33da08ef61' },
{ name: 'Zebronics Eternal Bluetooth Calling Smart Watch With 185 Large Display Voice Assistant 100 Sport Modes Ip67 Waterproof 11 Built In Customizable Wallpaper 8 Menu Ui Crown And Calculator Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-eternal-bluetooth-calling-smart-watch-with-185-large-display-voice-assistant-100-sport-modes-ip67-waterproof-11-built-in-customizable-wallpaper-8-menu-ui-crown-and-calculator-grey/65988a0f42faee33da08eec8' },
{ name: 'Lacto Calamine Neem Aloe Turmeric Foaming Face Wash Reduces Pimples Purifies Skin With Built In Foaming Brushsulphate Free Face Washparaben Free 150 Ml X Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lacto-calamine-neem-aloe-turmeric-foaming-face-wash-reduces-pimples-purifies-skin-with-built-in-foaming-brushsulphate-free-face-washparaben-free-150-ml-x-pack-of-1/6598830642faee33da08eba2' },
{ name: 'Happilo Marvel Captain Marvel Edition International Pizza Oregano Party Snack 150g 265 Protein Healthy Snacks With Roasted Cashews Pumpkin Seeds Roasted California Almonds', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/happilo-marvel-captain-marvel-edition-international-pizza-oregano-party-snack-150g-265-protein-healthy-snacks-with-roasted-cashews-pumpkin-seeds-roasted-california-almonds/6598855e42faee33da08ec60' },
{ name: 'Vaseline Intensive Care Deep Moisture Nourishing Body Lotion 600 Ml Daily Moisturizer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-intensive-care-deep-moisture-nourishing-body-lotion-600-ml-daily-moisturizer/6598843242faee33da08ebc8' },
{ name: 'Pigeon By Stovekraft Kitchen Tools Value Combo 400ml Chopper Storage 3 Knives Set 1 Kitchen Scissor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-kitchen-tools-value-combo-400ml-chopper-storage-3-knives-set-1-kitchen-scissor/659881d942faee33da08eb9d' },
{ name: 'Clazkit Silicone Flower Shape Chocolate Mould Brown 10 Inch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clazkit-silicone-flower-shape-chocolate-mould-brown-10-inch/659880ad42faee33da08eb98' },
{ name: 'Cello Puro Junior Hot Wheel Print Plastic Water Bottle For Kids Easy Carry Wrist Belt Leak Proof Refrigerator Safe Keeps Content Cold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-puro-junior-hot-wheel-print-plastic-water-bottle-for-kids-easy-carry-wrist-belt-leak-proof-refrigerator-safe-keeps-content-cold/65987f8142faee33da08eb93' },
{ name: 'Striff 12 Pieces Highly Flexible Silicone Micro Usb Protector Mouse Cable Protector Suit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/striff-12-pieces-highly-flexible-silicone-micro-usb-protector-mouse-cable-protector-suit/6598868a42faee33da08ecf9' },
{ name: 'Portronics Konnect L Por 1403 Fast Charging 3a Type C Cable 12 Meter With Charge Sync Function', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-konnect-l-por-1403-fast-charging-3a-type-c-cable-12-meter-with-charge-sync-function/659888e342faee33da08ee31' },
{ name: 'Boldfit Spider Gym Shaker Bottle Shakers For Protein Shake With 2 Storage Compartment Gym Protein Shaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boldfit-spider-gym-shaker-bottle-shakers-for-protein-shake-with-2-storage-compartment-gym-protein-shaker/659887b742faee33da08ed96' },
{ name: 'Gionee Stylfit Gsw11 Smartwatch With Large 18 Ips Display Spo2 Stress Monitor Heart Rate Monitor 200 Watch Facesazure Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gionee-stylfit-gsw11-smartwatch-with-large-18-ips-display-spo2-stress-monitor-heart-rate-monitor-200-watch-facesazure-blue/65987e5542faee33da08eb8e' },
{ name: 'Go Desi Assorted Pops Imli Candy Aam Candy 3 X Pack Of 10 Pcs Desi Popz 8g X 30 Pcs 240 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/go-desi-assorted-pops-imli-candy-aam-candy-3-x-pack-of-10-pcs-desi-popz-8g-x-30-pcs-240-g/65987bfc42faee33da08eb84' },
{ name: 'Vaseline Light Hydrate Serum In Lotion 400 Ml Superlight Non Sticky Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vaseline-light-hydrate-serum-in-lotion-400-ml-superlight-non-sticky-body-lotion/65987d2842faee33da08eb89' },
{ name: 'Milton Floret Tiffin 3 Microwave Safe Inner Steel Containers 180320 320 Ml 1 Stainless Steel Tumbler With Lid 415 Ml With Insulated Fabric Jacket Blue Lunch Box Office', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-floret-tiffin-3-microwave-safe-inner-steel-containers-180320-320-ml-1-stainless-steel-tumbler-with-lid-415-ml-with-insulated-fabric-jacket-blue-lunch-box-office/6598761f42faee33da08eb6b' },
{ name: 'Apple Lightning To 35mm Audio Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-lightning-to-35mm-audio-cable/65987ad042faee33da08eb7f' },
{ name: 'Dove Body Love Pro Age Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-body-love-pro-age-body-lotion/659874f342faee33da08eb66' },
{ name: 'Morphy Richards Kenzo Cold Press Slow Juicer 150 W Powerful Dc Motor 60 Rpm Speed With Stainless Steel Filter And Rev Button Rose Gold 150 Watt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/morphy-richards-kenzo-cold-press-slow-juicer-150-w-powerful-dc-motor-60-rpm-speed-with-stainless-steel-filter-and-rev-button-rose-gold-150-watt/659873c642faee33da08eb61' },
{ name: 'Costar Automatic Water Dispenser Pump Usb Rechargeable Electric Mini Water Pump For 20 Litre Bottle Can Portable Drinking Water Pump', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/costar-automatic-water-dispenser-pump-usb-rechargeable-electric-mini-water-pump-for-20-litre-bottle-can-portable-drinking-water-pump/659879a442faee33da08eb7a' },
{ name: 'Philips 9 Watts B22 Led Warm Whitegolden Yellow Led Bulb Pack Of 2 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-b22-led-warm-whitegolden-yellow-led-bulb-pack-of-2-ace-saver/6598774b42faee33da08eb70' },
{ name: 'Neelam Stainless Steel Dura Hot Food Carrier 4 Tier 8 Inch Big Container Lunch Box With Insulated Carry Bag Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neelam-stainless-steel-dura-hot-food-carrier-4-tier-8-inch-big-container-lunch-box-with-insulated-carry-bag-silver/6598787742faee33da08eb75' },
{ name: 'Lakme 9 To 5 Complexion Care Face Cc Cream Beige Spf 30 Conceals Dark Spots Blemishes 30 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-9-to-5-complexion-care-face-cc-cream-beige-spf-30-conceals-dark-spots-blemishes-30-g/6598729a42faee33da08eb5c' },
{ name: 'Crompton Galaxy Decoration Copper Usb Powered String Fairy Lights With 100 Led Light 10 Meters Warm White Green Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-galaxy-decoration-copper-usb-powered-string-fairy-lights-with-100-led-light-10-meters-warm-white-green-pack-of-2/6598716e42faee33da08eb57' },
{ name: 'Milton Vitro Plastic Pet Storage Jar And Container Set Of 18 6 Pcs X 230 Ml Each 6 Pcs X 600 Ml Each 6 Pcs X 118 Ltrs Each Red Wine Food Grade Air Tight Bpa Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-vitro-plastic-pet-storage-jar-and-container-set-of-18-6-pcs-x-230-ml-each-6-pcs-x-600-ml-each-6-pcs-x-118-ltrs-each-red-wine-food-grade-air-tight-bpa-free/6598704242faee33da08eb52' },
{ name: 'Vega Round Hair Brush Indias No1 Hair Brush Brand For Adding Curls Volume Waves In Hairs Men And Women All Hair Types E7 Rb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vega-round-hair-brush-indias-no1-hair-brush-brand-for-adding-curls-volume-waves-in-hairs-men-and-women-all-hair-types-e7-rb/65986f1542faee33da08eb4d' },
{ name: 'Realme Narzo 60x 5g Nebula Purple 6gb128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-narzo-60x-5g-nebula-purple-6gb128gb-storage-/65986de942faee33da08eb48' },
{ name: 'Ecraftindia Goddess Laxmi Lord Ganesha Golden Metal Diyagoldone Sizeaglg508', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-goddess-laxmi-lord-ganesha-golden-metal-diyagoldone-sizeaglg508/65986cbd42faee33da08eb43' },
{ name: 'Stony Brook By Nasher Miles Aurora Laptop Backpack 40l', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stony-brook-by-nasher-miles-aurora-laptop-backpack-40l/659866e042faee33da08eb2a' },
{ name: 'Purela Dishwash Liquid Gel 5 Liter With Lime And Basil Fragrance Leaves No Residue Dishwashing Liquid 5 Ltr Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/purela-dishwash-liquid-gel-5-liter-with-lime-and-basil-fragrance-leaves-no-residue-dishwashing-liquid-5-ltr-green/6598680c42faee33da08eb2f' },
{ name: 'Havells Klassic 750 Watts 4 Jar Mixer Grinder With 304 Ss Blades Large Size Ss Jars Break Resistant Polycarbonate Transparent Jar Lids With Twist Lock Mechanism', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-klassic-750-watts-4-jar-mixer-grinder-with-304-ss-blades-large-size-ss-jars-break-resistant-polycarbonate-transparent-jar-lids-with-twist-lock-mechanism/659865b342faee33da08eb25' },
{ name: 'Prakriti Naturals Small Piece Broken Cashew Kaju Tukda 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prakriti-naturals-small-piece-broken-cashew-kaju-tukda-1kg/6598693842faee33da08eb34' },
{ name: 'Cadbury Fuse Peanut Caramel Filled Chocolate Home Treats 1085g Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-fuse-peanut-caramel-filled-chocolate-home-treats-1085g-pack-of-4/6598648742faee33da08eb20' },
{ name: 'Body Cupid Himalayan Rose Gold Scrub Skin Glow Booster No Sulphates Parabens 200 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/body-cupid-himalayan-rose-gold-scrub-skin-glow-booster-no-sulphates-parabens-200-ml/65986a6442faee33da08eb39' },
{ name: 'Farmley Premium Flax Seeds Alsi Seeds 200 Grams I Rich In Fiber Omega 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/farmley-premium-flax-seeds-alsi-seeds-200-grams-i-rich-in-fiber-omega-3/65986b9142faee33da08eb3e' },
{ name: 'Littles Soft Cleansing Baby Wipes Lid 80 Wipes Pack Of 5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/littles-soft-cleansing-baby-wipes-lid-80-wipes-pack-of-5/6598622f42faee33da08eb16' },
{ name: 'Size Xs Rupa Women Cotton Panty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-xs-rupa-women-cotton-panty/6598610242faee33da08eb11' },
{ name: 'For Hdfc Debit Card Vu 139cm 55inches Cinema Tv Action Series 4k Ultra Hd Led Smart Android Tv 55lx Black 2021 Model I With 100 Watt Front Soundbar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-hdfc-debit-card-vu-139cm-55inches-cinema-tv-action-series-4k-ultra-hd-led-smart-android-tv-55lx-black-2021-model-i-with-100-watt-front-soundbar/6598635b42faee33da08eb1b' },
{ name: 'Ponds Light Face Moisturizer 200 Ml Daily Lightweight Non Oily Cream With Vitamin E', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-light-face-moisturizer-200-ml-daily-lightweight-non-oily-cream-with-vitamin-e/65985fd642faee33da08eb0c' },
{ name: 'Size 9 Uk Jqr Moj 403 Sports Runningwalking Gym Training Shoes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-9-uk-jqr-moj-403-sports-runningwalking-gym-training-shoes/65985b2442faee33da08eaf8' },
{ name: 'Amazon Basics Amazonbasics Mini Displayport To Hdmidvivga Adapter White 5 Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-amazonbasics-mini-displayport-to-hdmidvivga-adapter-white-5-pack/65985c5142faee33da08eafd' },
{ name: 'Ddecora Multipurpose Food Snack Plastic Bag Clip Sealer Packet Sealer Clamps Manual Vacuum Bag Sealer Food Pouch Clip Bag Zipper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ddecora-multipurpose-food-snack-plastic-bag-clip-sealer-packet-sealer-clamps-manual-vacuum-bag-sealer-food-pouch-clip-bag-zipper/659859f842faee33da08eaf3' },
{ name: 'Ptron Newly Launched Bassbuds Gomax Tws Earbuds Trutalk Ai Enc Calls 36hrs Playtime 13mm Drivers In Ear Bluetooth 53 Wireless Headphones Voice Assistant Type C Fast Charging Ipx5 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-bassbuds-gomax-tws-earbuds-trutalk-ai-enc-calls-36hrs-playtime-13mm-drivers-in-ear-bluetooth-53-wireless-headphones-voice-assistant-type-c-fast-charging-ipx5-white/659857a042faee33da08eae9' },
{ name: 'Amazon Basics True Wireless In Ear Earbuds With Mic Touch Control Ipx5 Water Resistance Bluetooth 53 Up To 36 Hours Play Time Voice Assistance And Fast Charging Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-true-wireless-in-ear-earbuds-with-mic-touch-control-ipx5-water-resistance-bluetooth-53-up-to-36-hours-play-time-voice-assistance-and-fast-charging-grey/6598567342faee33da08eae4' },
{ name: 'Noise Halo Plus 146 Super Amoled Display Elite Smart Watch Bluetooth Calling Stainless Steel Build Always On Display Upto 7 Days Battery Elite Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-halo-plus-146-super-amoled-display-elite-smart-watch-bluetooth-calling-stainless-steel-build-always-on-display-upto-7-days-battery-elite-black/65985d7d42faee33da08eb02' },
{ name: 'Walvia Premium Descaler Washing Machine Cleaner Liquid With Fresh Citrus Scent Quick Descaler', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/walvia-premium-descaler-washing-machine-cleaner-liquid-with-fresh-citrus-scent-quick-descaler/65985ea942faee33da08eb07' },
{ name: 'Zebronics Newly Launched Ck200 20 In 1 Cleaning Kit With Press To Open Design Magnetic Lid Portable Design Multi Gadget Cleaner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-newly-launched-ck200-20-in-1-cleaning-kit-with-press-to-open-design-magnetic-lid-portable-design-multi-gadget-cleaner/659858cc42faee33da08eaee' },
{ name: 'Reebok Havana Analog Black Dial Womens Watch Rv Hav L2 A1lb B1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reebok-havana-analog-black-dial-womens-watch-rv-hav-l2-a1lb-b1/6598554742faee33da08eadf' },
{ name: 'Cutting Edge Smart Slim Storage 11 Litre Air Tight Leak Resistant Plastic Round Modular Container For Pasta Juice Snacks Cookies Wafers Stackable Bpa Free Refrigerator Safe Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cutting-edge-smart-slim-storage-11-litre-air-tight-leak-resistant-plastic-round-modular-container-for-pasta-juice-snacks-cookies-wafers-stackable-bpa-free-refrigerator-safe-red/659852ee42faee33da08ead5' },
{ name: 'Amazon Basics 20w Bluetooth Soundbar Speaker With 2000mah Battery Bt V51 Aux Usb Port Led Display And Rgb Party Lights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-20w-bluetooth-soundbar-speaker-with-2000mah-battery-bt-v51-aux-usb-port-led-display-and-rgb-party-lights/6598541b42faee33da08eada' },
{ name: 'Blank Cotton Canvas Boards For Painting 10x10 Cm Pack Of 6 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blank-cotton-canvas-boards-for-painting-10x10-cm-pack-of-6-white/659851c242faee33da08ead0' },
{ name: 'Agaro Cv1077 Car Cartridge Vacuum Cleaner Portable Handheld12v Dc 110w 45kpa Power Socket 147ft Long Cordstainless Steel Filter Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-cv1077-car-cartridge-vacuum-cleaner-portable-handheld12v-dc-110w-45kpa-power-socket-147ft-long-cordstainless-steel-filter-black/65984f6a42faee33da08eac6' },
{ name: 'Cello Opalware Dazzle Series Lush Fiesta Dinner Set 18 Units Opal Glass Dinner Set For 6 Light Weight Daily Use Crockery Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-series-lush-fiesta-dinner-set-18-units-opal-glass-dinner-set-for-6-light-weight-daily-use-crockery-set/65984e3d42faee33da08eac1' },
{ name: 'Size 11 Uk Puma Unisex Adult Anzarun Lite Bold Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-11-uk-puma-unisex-adult-anzarun-lite-bold-sneaker/6598509642faee33da08eacb' },
{ name: 'Instant Electric Water Heater Faucet Hot Tap With Shower Home Kitchen Bathroom Led Temperature Electric Heating Tankless Water Heaters Elimator Professional Heater', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/instant-electric-water-heater-faucet-hot-tap-with-shower-home-kitchen-bathroom-led-temperature-electric-heating-tankless-water-heaters-elimator-professional-heater/65984d1142faee33da08eabc' },
{ name: 'Nayasa Square Ring Plastic Deluxe Funk Mug Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nayasa-square-ring-plastic-deluxe-funk-mug-pink/65984ab842faee33da08eab2' },
{ name: 'Nakoda Daisy Storage Basket Multipurpose Use For Kitchen Home Organiser Box', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nakoda-daisy-storage-basket-multipurpose-use-for-kitchen-home-organiser-box/6598498c42faee33da08eaad' },
{ name: 'Agaro Charcoal Toothbrush Gentle Soft Teeth Whitening For Adults Children Medium Tip Bristles Family Pack Of 5 Mix Colour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-charcoal-toothbrush-gentle-soft-teeth-whitening-for-adults-children-medium-tip-bristles-family-pack-of-5-mix-colour/65984be542faee33da08eab7' },
{ name: 'C E 5 Pack Micro Usb Otg To Usb 20 Adpater Otg Cable Right Angle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/c-e-5-pack-micro-usb-otg-to-usb-20-adpater-otg-cable-right-angle/6598486042faee33da08eaa8' },
{ name: 'Kesariyaji Reusable Hot Cold Soft And Flexible Gel Ice Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kesariyaji-reusable-hot-cold-soft-and-flexible-gel-ice-pack/6598473442faee33da08eaa3' },
{ name: 'Apple Iphone 14 128 Gb Midnight', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-iphone-14-128-gb-midnight/6598460742faee33da08ea9e' },
{ name: 'Fims Fashion Is My Style Women Cotton Bra Panty Set For Women Lingerie Set Bra Panty Set Bra Panty Set For Women With Sexy Bra Panty Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fims-fashion-is-my-style-women-cotton-bra-panty-set-for-women-lingerie-set-bra-panty-set-bra-panty-set-for-women-with-sexy-bra-panty-set/659843ad42faee33da08ea94' },
{ name: 'Philips Deco Mini Base B22 05 Watt Led Bulb Yellow Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-deco-mini-base-b22-05-watt-led-bulb-yellow-pack-of-3/659844da42faee33da08ea99' },
{ name: 'Mancode Wet Look Hair Wax 100 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mancode-wet-look-hair-wax-100-gm/659839ef42faee33da08ea6c' },
{ name: 'Shavin Pvc Transparent With 3d Diamond Design Print Curtainac Roomwaterproof Curtain Width 54inches X Height 108inches 9 Feet Clear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/shavin-pvc-transparent-with-3d-diamond-design-print-curtainac-roomwaterproof-curtain-width-54inches-x-height-108inches-9-feet-clear/659838bb42faee33da08ea67' },
{ name: 'Schmick 19 Kgs White Pebbles For Decoration Pebbles For Plants Pots Pebbles', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/schmick-19-kgs-white-pebbles-for-decoration-pebbles-for-plants-pots-pebbles/65983ec242faee33da08ea80' },
{ name: 'Heart Home Anti Bird Netuv Stabilized Pigeon Netheavy Duty Mesh Net For Garden Balcony 12x10 Feet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/heart-home-anti-bird-netuv-stabilized-pigeon-netheavy-duty-mesh-net-for-garden-balcony-12x10-feet/65983b2d42faee33da08ea71' },
{ name: 'Cellux Single Side High Density Self Adhesive Foam Seal Tape Weather Stripping Doors And Window Insulation Soundproofing 12mm Width 3mm Thickness 10 Meter Length', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cellux-single-side-high-density-self-adhesive-foam-seal-tape-weather-stripping-doors-and-window-insulation-soundproofing-12mm-width-3mm-thickness-10-meter-length/6598415342faee33da08ea8a' },
{ name: 'Rey Naturals Organic Indigo Powder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rey-naturals-organic-indigo-powder/6598378f42faee33da08ea62' },
{ name: 'Park Avenue Voyage Signature Collection Perfume', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-voyage-signature-collection-perfume/6598353742faee33da08ea58' },
{ name: 'He Advanced Grooming Respect Perfumed Body Spray For Men 150ml Fresh', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/he-advanced-grooming-respect-perfumed-body-spray-for-men-150ml-fresh/6598366342faee33da08ea5d' },
{ name: 'Bajaj 100 Pure Henna 500 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-100-pure-henna-500-gm/65982aa842faee33da08ea2b' },
{ name: 'Kent Storm Vacuum Cleaner 600w Cyclone5 Technology Hepa Filter Bagless Design Detachable Easy To Pack Ideal Cleaning', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kent-storm-vacuum-cleaner-600w-cyclone5-technology-hepa-filter-bagless-design-detachable-easy-to-pack-ideal-cleaning/65982bd542faee33da08ea30' },
{ name: 'Status Orthopedic Memory Foam Bed Pillow For Sleeping Neck Pain Relief Soft Yet Supportive Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-orthopedic-memory-foam-bed-pillow-for-sleeping-neck-pain-relief-soft-yet-supportive-suitable/65982f5942faee33da08ea3f' },
{ name: 'Vcr Masking Tape 20 Meters In Length 12mm 05 Width 2 Rolls Per Pack Easy Tear Tape Best', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vcr-masking-tape-20-meters-in-length-12mm-05-width-2-rolls-per-pack-easy-tear-tape-best/659832de42faee33da08ea4e' },
{ name: 'Asian Paints Trucare Carbon Steel Measuring Tape Strip With Thumb Lock And Belt Clip 3m White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asian-paints-trucare-carbon-steel-measuring-tape-strip-with-thumb-lock-and-belt-clip-3m-white/6598285042faee33da08ea21' },
{ name: 'Status Polypropylene Striped Anti Skid Runner For Living Area Rug For Bedroom Rug For Dining Table Rug', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-polypropylene-striped-anti-skid-runner-for-living-area-rug-for-bedroom-rug-for-dining-table-rug/65982d0142faee33da08ea35' },
{ name: 'Butterfly Aluminium Rapid Kcp3 Pcs Set Non Stick Cookware Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/butterfly-aluminium-rapid-kcp3-pcs-set-non-stick-cookware-blue/6598272442faee33da08ea1c' },
{ name: 'Wipro North West Ufo 61 Flex Box With 6 Universal Socket Single Switch 2 Meter Long Extension Cord Ultra Smooth Rotation Round Powerstrip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-north-west-ufo-61-flex-box-with-6-universal-socket-single-switch-2-meter-long-extension-cord-ultra-smooth-rotation-round-powerstrip/6598297c42faee33da08ea26' },
{ name: 'Panca Idli Maker 4 Platesstainless Steel Idli Cooker Induction And Gas Stove Compatible Idli Cooker 16 Idli Maker 4 Plates', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-idli-maker-4-platesstainless-steel-idli-cooker-induction-and-gas-stove-compatible-idli-cooker-16-idli-maker-4-plates/659825f742faee33da08ea17' },
{ name: 'Simply Home Aanchal Plastic Fridge Ice Tray Set 2 Pc Tray With 14 Cube Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/simply-home-aanchal-plastic-fridge-ice-tray-set-2-pc-tray-with-14-cube-multicolor/6598214642faee33da08ea03' },
{ name: 'Dynore Stainless Steel Multipurpose Usage Mugteacoffee Serving Mug 300 Ml Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-stainless-steel-multipurpose-usage-mugteacoffee-serving-mug-300-ml-silver/6598201a42faee33da08e9fe' },
{ name: 'Wonder Homeware Prime Sigma Snacker Mixing Bowl 500 Ml Set Of 3 Multicolor Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-prime-sigma-snacker-mixing-bowl-500-ml-set-of-3-multicolor-pink/65981eee42faee33da08e9f9' },
{ name: 'Graphene Airplane Launcher Gunsafe And Fun Shooting Guns For Kidsmulticolor Graphene Rainbow Spring Fun Activity Stress Relief Toy Adults Of All Age Group', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/graphene-airplane-launcher-gunsafe-and-fun-shooting-guns-for-kidsmulticolor-graphene-rainbow-spring-fun-activity-stress-relief-toy-adults-of-all-age-group/65981dc142faee33da08e9f4' },
{ name: 'Popo Toys Holi Superhero Bagtank Pichkari With 100 Free Balloons Color May Varry 25 Ltr', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/popo-toys-holi-superhero-bagtank-pichkari-with-100-free-balloons-color-may-varry-25-ltr/65981c9542faee33da08e9ef' },
{ name: 'Wonder Sigma 1000 Microwave Safe Multipurpose Plastic Bowl Set 12 Pc Bowl 650 Ml Violet Color', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-sigma-1000-microwave-safe-multipurpose-plastic-bowl-set-12-pc-bowl-650-ml-violet-color/6598227242faee33da08ea08' },
{ name: 'Bajaj Compagno 2000 W 10 Litre Vertical Storage Water Heater Star Rated Geyser Water Heating With Titanium Armour Swirl Flow Technology Child Safety Mode2 Yr Warranty By Bajaj White Bluewall', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-compagno-2000-w-10-litre-vertical-storage-water-heater-star-rated-geyser-water-heating-with-titanium-armour-swirl-flow-technology-child-safety-mode2-yr-warranty-by-bajaj-white-bluewall/6598239f42faee33da08ea0d' },
{ name: 'The White Willow Soft Fabric Elevated Leg Rest Wedge Case 24 L X 21 W X 8 H Washable Removable Zippered Pillow Cover Only Fits Elevated Leg Rest Wedge Pillow Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-white-willow-soft-fabric-elevated-leg-rest-wedge-case-24-l-x-21-w-x-8-h-washable-removable-zippered-pillow-cover-only-fits-elevated-leg-rest-wedge-pillow-grey/659824cb42faee33da08ea12' },
{ name: 'Dynore Black Nylon Spatula Turner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dynore-black-nylon-spatula-turner/65981b6942faee33da08e9ea' },
{ name: 'Atevon Stainless Steel Electronic Gas Lighter Multicolour Peeling Knife Jumbo Stainless Steel Blade Color May Vary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/atevon-stainless-steel-electronic-gas-lighter-multicolour-peeling-knife-jumbo-stainless-steel-blade-color-may-vary/65981a3c42faee33da08e9e5' },
{ name: 'Leawall Kitchen Wiper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/leawall-kitchen-wiper-/6598191042faee33da08e9e0' },
{ name: 'Furniture Cafe Wooden Multipurpose Turn N Tube Engineered Wood End Table Beside Sofa Side Table Corner Night Stand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/furniture-cafe-wooden-multipurpose-turn-n-tube-engineered-wood-end-table-beside-sofa-side-table-corner-night-stand/6598158b42faee33da08e9d1' },
{ name: 'Lenskart Blu Hustlr Peyush Bansal Glasses For Eye Protection From Digital Screens Computer Glasses With Blue Cut Uv Protection Lightweight Specs With Zero Power Medium Sand Dune', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenskart-blu-hustlr-peyush-bansal-glasses-for-eye-protection-from-digital-screens-computer-glasses-with-blue-cut-uv-protection-lightweight-specs-with-zero-power-medium-sand-dune/65980fae42faee33da08e9b8' },
{ name: 'John Jacobs Uv Protection Sunglasses For Men Women Black Green Solid Full Rim Wayfarer Jj S13311 C3 Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/john-jacobs-uv-protection-sunglasses-for-men-women-black-green-solid-full-rim-wayfarer-jj-s13311-c3-pack-of-1/65980e8242faee33da08e9b3' },
{ name: 'Beco Eco Friendly Dishwash Liquid 750 Ml Coconut Based Surfactants With Orange Extracts 100 Natural', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-eco-friendly-dishwash-liquid-750-ml-coconut-based-surfactants-with-orange-extracts-100-natural/6598120742faee33da08e9c2' },
{ name: 'Nexellar Traveling Foldable Plastic Glass Bpa Free Portable Pocket Water Glass With Lid', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nexellar-traveling-foldable-plastic-glass-bpa-free-portable-pocket-water-glass-with-lid/659816b842faee33da08e9d6' },
{ name: 'Redmi 12 5g Pastel Blue 8gb Ram 256gb Rom', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-12-5g-pastel-blue-8gb-ram-256gb-rom/659817e442faee33da08e9db' },
{ name: 'Lenskart Blu Hustlr Peyush Bansal Glasses For Eye Protection From Digital Screens Computer Glasses With Blue Cut Anti Glare Uv Protection Lightweight Specs With Zero Power Medium Amber', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenskart-blu-hustlr-peyush-bansal-glasses-for-eye-protection-from-digital-screens-computer-glasses-with-blue-cut-anti-glare-uv-protection-lightweight-specs-with-zero-power-medium-amber/659810da42faee33da08e9bd' },
{ name: 'Nivea Sun Protect And Moisture 75ml Spf 30 Sunscreen Pa Uva Uvb Protection System Water Resistant For Men Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-sun-protect-and-moisture-75ml-spf-30-sunscreen-pa-uva-uvb-protection-system-water-resistant-for-men-women/65980d5642faee33da08e9ae' },
{ name: 'Wonder Plastic Prime Aanchal Fridge Ice Cube Tray Set Of 6 Pc Tray 14 Cube Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-plastic-prime-aanchal-fridge-ice-cube-tray-set-of-6-pc-tray-14-cube-multicolor/6598133342faee33da08e9c7' },
{ name: 'Pigeon Inox Hydra Curve 700ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-inox-hydra-curve-700ml/6598145f42faee33da08e9cc' },
{ name: 'Da Urban Mexico Pro High Back Mesh Ergonomic Office Chair With 3 Years Warranty Adjustable Armrestsadjustable Pu Padsadjustable Lumbar Supportsmart Any Angle Tilt Lock Mechanism Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/da-urban-mexico-pro-high-back-mesh-ergonomic-office-chair-with-3-years-warranty-adjustable-armrestsadjustable-pu-padsadjustable-lumbar-supportsmart-any-angle-tilt-lock-mechanism-black/65980afd42faee33da08e9a4' },
{ name: 'Perpetual Tulsi Diya', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/perpetual-tulsi-diya/65980c2942faee33da08e9a9' },
{ name: 'Park Avenue Regal No Gas Premium Perfume For Men Long Lasting Fragrance 130ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-regal-no-gas-premium-perfume-for-men-long-lasting-fragrance-130ml/6598052042faee33da08e98b' },
{ name: 'Kuber Industries Pizza Cutter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-pizza-cutter-/6598064c42faee33da08e990' },
{ name: 'For Icici Bank Credit Card Redmi 13c Stardust Black 4gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-icici-bank-credit-card-redmi-13c-stardust-black-4gb-ram-128gb-storage/659803f442faee33da08e986' },
{ name: 'Bajaj Almond Drops Moisturising Soap With Almond Oil And Vitamin E 100gm4 400gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-almond-drops-moisturising-soap-with-almond-oil-and-vitamin-e-100gm4-400gm/659802c742faee33da08e981' },
{ name: 'Open Secret Christmas New Year Gift Hamper I 5 Item Gift Combo Cookies Biscuits Tandoori Masala Nuts Mix 23g Card I Gift Box For X Mas New Year Healthy Gift Hamper', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-christmas-new-year-gift-hamper-i-5-item-gift-combo-cookies-biscuits-tandoori-masala-nuts-mix-23g-card-i-gift-box-for-x-mas-new-year-healthy-gift-hamper/6598019b42faee33da08e97c' },
{ name: 'Tri Activ Double Strong Disinfectant Floor Cleaner Half Cap Only 10x Cleaning With 999 Germ Kill Citrus Fragrance Pack Of 2 1000ml X 2 Units', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tri-activ-double-strong-disinfectant-floor-cleaner-half-cap-only-10x-cleaning-with-999-germ-kill-citrus-fragrance-pack-of-2-1000ml-x-2-units/6598006f42faee33da08e977' },
{ name: 'Wonder Homeware Server Euro Dustbin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-server-euro-dustbin/659808a542faee33da08e99a' },
{ name: 'Wonder Homeware Yummy Dhokla Maker Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-homeware-yummy-dhokla-maker-set/6598077842faee33da08e995' },
{ name: 'Amazon Brand Solimo Reveston Square Coffee Tablesolid Acacia Wood And Iron Natural Finish', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-reveston-square-coffee-tablesolid-acacia-wood-and-iron-natural-finish/659809d142faee33da08e99f' },
{ name: 'Graphene Rainbow Spring Fun Activity Stress Relief Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/graphene-rainbow-spring-fun-activity-stress-relief-toy-/6597ff4342faee33da08e972' },
{ name: 'Odonil Room Air Freshener Spray 440ml Combo Pack Of 2 220ml Each Jasmine Fresh Ocean Breeze Nature Inspired Fragrance', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/odonil-room-air-freshener-spray-440ml-combo-pack-of-2-220ml-each-jasmine-fresh-ocean-breeze-nature-inspired-fragrance/6597fcea42faee33da08e968' },
{ name: 'Santoor Pureglo Glycerine Bathing Bar Soap With Almond Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/santoor-pureglo-glycerine-bathing-bar-soap-with-almond-oil/6597fe1642faee33da08e96d' },
{ name: 'Arto Keychain Led Light 2 Hours Battery Life With Bottle Opener Magnetic Base And Folding Bracket Mini Cob 1000 Lumens Rechargeable Emergency Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/arto-keychain-led-light-2-hours-battery-life-with-bottle-opener-magnetic-base-and-folding-bracket-mini-cob-1000-lumens-rechargeable-emergency-light/6597fbbe42faee33da08e963' },
{ name: 'Xml Mini Car Dustbin Trash Bin Universal Traveling Portable Useful', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/xml-mini-car-dustbin-trash-bin-universal-traveling-portable-useful/6597f83942faee33da08e954' },
{ name: 'Sirona Reusable Blink Glow Face Razor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sirona-reusable-blink-glow-face-razor/6597fa9142faee33da08e95e' },
{ name: 'The Nile Vitamin C With Hyaluronic Acid Skin Clearing Serum Brightening Anti Aging Skin Repair Supercharged Face Serum Dark Circle Fine Line N Sun Damage Corrector 30 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-nile-vitamin-c-with-hyaluronic-acid-skin-clearing-serum-brightening-anti-aging-skin-repair-supercharged-face-serum-dark-circle-fine-line-n-sun-damage-corrector-30-ml/6597f96542faee33da08e959' },
{ name: 'Status Contract 3 X 5 Feet Multi Printed Vintage Persian Carpet Rug Runner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-3-x-5-feet-multi-printed-vintage-persian-carpet-rug-runner/6597f4b442faee33da08e945' },
{ name: 'Hersheys Bar Milk 40gm Pack Of 6 Pouch 6 X 40 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-bar-milk-40gm-pack-of-6-pouch-6-x-40-g/6597f5e042faee33da08e94a' },
{ name: 'Wow Skin Science Sangria Face Mask', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-sangria-face-mask/6597f38742faee33da08e940' },
{ name: 'Amazon Brand Presto Disinfectant Toilet Cleaner Original 1 L Pack Of 4kills 999 Germs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-disinfectant-toilet-cleaner-original-1-l-pack-of-4kills-999-germs/6597f70c42faee33da08e94f' },
{ name: 'Eveready Led Rechargeable Torch Light Dl22 Bright Led 3x Battery Life 4x Torch Life Overcharge Protection Plug N Play Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eveready-led-rechargeable-torch-light-dl22-bright-led-3x-battery-life-4x-torch-life-overcharge-protection-plug-n-play-suitable/6597f25b42faee33da08e93b' },
{ name: 'Tecno Phantom X2 5g Stardust Grey 8gb Ram 256gb Storage Worlds 1st 4nm Dimensity 9000 5g Processor Curved Amoled Display 64mp Rgbw Camera 5gb Memory Fusion 50 Charge In Just 20 Minutes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-phantom-x2-5g-stardust-grey-8gb-ram-256gb-storage-worlds-1st-4nm-dimensity-9000-5g-processor-curved-amoled-display-64mp-rgbw-camera-5gb-memory-fusion-50-charge-in-just-20-minutes/6597f12f42faee33da08e936' },
{ name: 'Realme Narzo N53 Feather Black 4gb64gb 33w Segment Fastest Charging Slimmest Phone In Segment 90 Hz Smooth Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-narzo-n53-feather-black-4gb64gb-33w-segment-fastest-charging-slimmest-phone-in-segment-90-hz-smooth-display/6597f00242faee33da08e931' },
{ name: 'Indulekha Bringha Shampoo 1l Proprietary Ayurvedic Medicine', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/indulekha-bringha-shampoo-1l-proprietary-ayurvedic-medicine/6597eed642faee33da08e92c' },
{ name: 'Comfyhome Air Fryer For Home 1600w 65 Liter Digital Air Fryer Wsee Through Window Touch Panel Uses 95 Less Oil 8 Pre Set Menu Recipe Non Stick Basket Rapid Air Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/comfyhome-air-fryer-for-home-1600w-65-liter-digital-air-fryer-wsee-through-window-touch-panel-uses-95-less-oil-8-pre-set-menu-recipe-non-stick-basket-rapid-air-technology/6597eb5142faee33da08e91d' },
{ name: 'Amazon Brand Solimo Sticky Notes Pad Pocket Size Wirobound 3 X 4 Notes 200 Sheets', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-sticky-notes-pad-pocket-size-wirobound-3-x-4-notes-200-sheets/6597ec7d42faee33da08e922' },
{ name: 'Fogg I Am Queen Scent Eau De Parfum Perfume Long Lasting Fresh Floral Fragrance For Women 100ml Each Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fogg-i-am-queen-scent-eau-de-parfum-perfume-long-lasting-fresh-floral-fragrance-for-women-100ml-each-pack-of-2/6597e7cc42faee33da08e86a' },
{ name: 'Street Wear Satin Smooth Lipstick Poison Berry 42g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/street-wear-satin-smooth-lipstick-poison-berry-42g/6597e69f42faee33da08e7d3' },
{ name: 'Fossil Stella White Watch Ce1116', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fossil-stella-white-watch-ce1116/6597e57342faee33da08e748' },
{ name: 'Yogabar Veggie Magic Oats 400 G Premium Oats With High Fibre 100 Whole Grain Non Gmo Gluten Free Golden Oats', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-veggie-magic-oats-400-g-premium-oats-with-high-fibre-100-whole-grain-non-gmo-gluten-free-golden-oats/6597e44742faee33da08e6b1' },
{ name: 'Beardo Godfather Perume Deo Spray 150ml And Whisky Smoke Perfume Body Spray 120ml Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-godfather-perume-deo-spray-150ml-and-whisky-smoke-perfume-body-spray-120ml-combo/6597e8f842faee33da08e907' },
{ name: 'Halonix 20 Watt Led Battentubelight Streak Square 4 Ft Led Batten', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/halonix-20-watt-led-battentubelight-streak-square-4-ft-led-batten/6597ea2442faee33da08e918' },
{ name: 'Kamasutra Ultrathin Condom', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kamasutra-ultrathin-condom/6597edaa42faee33da08e927' },
{ name: 'Dabur Organic Honey 500g 100 Pure And Natural Npop Organic Certified Raw Unprocessed Unpasteurized Honey No Sugar Adulteration', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-organic-honey-500g-100-pure-and-natural-npop-organic-certified-raw-unprocessed-unpasteurized-honey-no-sugar-adulteration/6597e31a42faee33da08e614' },
{ name: 'Jialto 2 Pieces Clothes Organiser For Wardrobe Cupboard For Clothes Foldable And Stackable Organizer For Wardrobe Organizer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jialto-2-pieces-clothes-organiser-for-wardrobe-cupboard-for-clothes-foldable-and-stackable-organizer-for-wardrobe-organizer/6597e1ee42faee33da08e57b' },
{ name: 'Yogabar Muesli Wholegrain Breakfast Cereals Healthy Breakfast Cereals Granola Gluten Free Antioxidant Rich Healthy Food', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-muesli-wholegrain-breakfast-cereals-healthy-breakfast-cereals-granola-gluten-free-antioxidant-rich-healthy-food/6597e0c242faee33da08e4dc' },
{ name: 'Pidilite Steelgrip Self Adhesive Pvc Electrical Insulation Tape Color May Vary 650m', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pidilite-steelgrip-self-adhesive-pvc-electrical-insulation-tape-color-may-vary-650m/6597df9542faee33da08e449' },
{ name: 'Axe Adrenaline Long Lasting Deodorant Bodyspray For Men 215 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/axe-adrenaline-long-lasting-deodorant-bodyspray-for-men-215-ml/6597dc1042faee33da08e280' },
{ name: 'Oscar Oud Lady Mini Pocket Perfume For Women 8 Ml Musky Notes Mini Perfume Travel Size Perfume Floral Fragrance Edp Women Girl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oscar-oud-lady-mini-pocket-perfume-for-women-8-ml-musky-notes-mini-perfume-travel-size-perfume-floral-fragrance-edp-women-girl/6597de6942faee33da08e3b0' },
{ name: 'Longway Wave P2 1200 Mm48 Inch 400 Rpm Ultra High Speed 3 Blade Star Rated Anti Dust Decorative Ceiling Fan 2 Year Warranty Silver Blue Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longway-wave-p2-1200-mm48-inch-400-rpm-ultra-high-speed-3-blade-star-rated-anti-dust-decorative-ceiling-fan-2-year-warranty-silver-blue-pack-of-2/6597d88b42faee33da08e1f5' },
{ name: 'Yardley London Gentleman Intense Fougere Body Perfume The Elite Collection No Gas Deodorant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yardley-london-gentleman-intense-fougere-body-perfume-the-elite-collection-no-gas-deodorant/6597d63342faee33da08e1eb' },
{ name: 'Philips 20w Ujjwal Led Batten Led Tubelight', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-20w-ujjwal-led-batten-led-tubelight/6597d75f42faee33da08e1f0' },
{ name: 'Crompton Energion Hyperjet 1200mm Bldc Ceiling Fan With Remote Control High Air Delivery Energy Saving 2 Year Warranty Ivory', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-energion-hyperjet-1200mm-bldc-ceiling-fan-with-remote-control-high-air-delivery-energy-saving-2-year-warranty-ivory/6597dae442faee33da08e1ff' },
{ name: 'Status Memory Foam Bath Mat Rug Ultra Soft And Non Slip Bathroom Rugs Water Absorbent And Machine Washable Bath Rug', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-memory-foam-bath-mat-rug-ultra-soft-and-non-slip-bathroom-rugs-water-absorbent-and-machine-washable-bath-rug/6597dd3d42faee33da08e31d' },
{ name: 'Beco Bamboo 3 Ply Toilet Paper Roll 160 Pulls Each Pack Of 12 100 Natural Unbleached And Eco Friendly Tissue Papers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beco-bamboo-3-ply-toilet-paper-roll-160-pulls-each-pack-of-12-100-natural-unbleached-and-eco-friendly-tissue-papers/6597d9b842faee33da08e1fa' },
{ name: 'Ponds Bright Beauty Foaming Brush Facewash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-bright-beauty-foaming-brush-facewash/6597d50642faee33da08e1e6' },
{ name: 'Reynolds D Fine Ballpen Blue Pack Of 50 Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-d-fine-ballpen-blue-pack-of-50-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/6597d2ae42faee33da08e1dc' },
{ name: 'Lifelong Fabric Resistance Band Loop Hip Band For Women Men For Hip Legs Stretching Toning Workoutmini Loop Bands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-fabric-resistance-band-loop-hip-band-for-women-men-for-hip-legs-stretching-toning-workoutmini-loop-bands/6597d3da42faee33da08e1e1' },
{ name: 'Tecno Megabook T1 Intel Core 11th Gen I5 Processor 16gb Ram 512gb Ssd Storage156 Inch 3962 Cm Eye Comfort Disply 148mm Ultra Slim 70 Wh Large Batterywindows 11 Space Grey 156 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-megabook-t1-intel-core-11th-gen-i5-processor-16gb-ram-512gb-ssd-storage156-inch-3962-cm-eye-comfort-disply-148mm-ultra-slim-70-wh-large-batterywindows-11-space-grey-156-kg/6597ccd042faee33da08e1c3' },
{ name: 'Tecno Megabook T1 Intel Core 11th Gen I3 Processor 8gb Ram 512gb Ssd Storage 156 Inch 3962 Cm Eye Comfort Disply 148mm Ultra Slim 70 Wh Large Batterywindows 11 Space Grey 156 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-megabook-t1-intel-core-11th-gen-i3-processor-8gb-ram-512gb-ssd-storage-156-inch-3962-cm-eye-comfort-disply-148mm-ultra-slim-70-wh-large-batterywindows-11-space-grey-156-kg/6597cdfd42faee33da08e1c8' },
{ name: 'La Opala Cook Serve Store Borosilicate Storage Container Simply Store With Lid Rectangle 105 L 1 Pc Transparent', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/la-opala-cook-serve-store-borosilicate-storage-container-simply-store-with-lid-rectangle-105-l-1-pc-transparent/6597cba442faee33da08e1be' },
{ name: 'Philips Audio Tat1225 Bluetooth Truly Wireless In Ear Earbuds With Mic Tws With 18 Hours Playtime 612 Ipx4 Voice Assistant Noise And Echo Cancellation Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-audio-tat1225-bluetooth-truly-wireless-in-ear-earbuds-with-mic-tws-with-18-hours-playtime-612-ipx4-voice-assistant-noise-and-echo-cancellation-blue/6597ca7842faee33da08e1b9' },
{ name: 'Cortina Microfiber Hair Towel Cap Soft Absorbent Quick Drying Cap Pack Of 2 White Purple', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-microfiber-hair-towel-cap-soft-absorbent-quick-drying-cap-pack-of-2-white-purple/6597c94c42faee33da08e1b4' },
{ name: 'Auto Pearl Sapphire Series H11 Cool Blue Halogen Head Light Bulb 55w 12 V 1 Bulb Compatible With Indigo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/auto-pearl-sapphire-series-h11-cool-blue-halogen-head-light-bulb-55w-12-v-1-bulb-compatible-with-indigo/6597c81f42faee33da08e1af' },
{ name: 'Fastrack Limitless Fs1 Smartwatchbiggest 195 Horizon Curve Displaysinglesync Bt Calling V53built In Alexaupto 5 Day Batteryats Chipset100 Sports Modes150 Watchfaces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-limitless-fs1-smartwatchbiggest-195-horizon-curve-displaysinglesync-bt-calling-v53built-in-alexaupto-5-day-batteryats-chipset100-sports-modes150-watchfaces/6597d05542faee33da08e1d2' },
{ name: 'Tecno Megabook T1intel Core 11th Gen I7 Processor 16gb Ram1tb Ssd Storage 156 Inch 3962 Cm Eye Comfort Disply 148mm Ultra Slim 70 Wh Large Batterywindows 11 Space Grey 156 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-megabook-t1intel-core-11th-gen-i7-processor-16gb-ram1tb-ssd-storage-156-inch-3962-cm-eye-comfort-disply-148mm-ultra-slim-70-wh-large-batterywindows-11-space-grey-156-kg/6597cf2942faee33da08e1cd' },
{ name: 'Bedspun 150 Tc Microfiber 1 U Double Size Bed Sheet With 2 Pillow Covers 220 X 225 Cm Or 86 X 88 Inch Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bedspun-150-tc-microfiber-1-u-double-size-bed-sheet-with-2-pillow-covers-220-x-225-cm-or-86-x-88-inch-yellow/6597d18242faee33da08e1d7' },
{ name: 'Enter Click Wired Usb Mouse 800 Dpi Optical Sensor 3 Button Design Plug Play', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/enter-click-wired-usb-mouse-800-dpi-optical-sensor-3-button-design-plug-play/6597c5c742faee33da08e1a5' },
{ name: 'Puma Unisexs Cap 2496802high Risk Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puma-unisexs-cap-2496802high-risk-red/6597c6f342faee33da08e1aa' },
{ name: 'Portronics Toad 23 Wireless Optical Mouse With 24ghz Usb Nano Dongle Optical Orientation Click Wheel Adjustable Dpiblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-toad-23-wireless-optical-mouse-with-24ghz-usb-nano-dongle-optical-orientation-click-wheel-adjustable-dpiblack/6597c49b42faee33da08e1a0' },
{ name: 'Amazon Basics 1 Male To 2 Male Rca Audio Interconnects For Dvd Player Television 4 Feet 1 Male To 2 Male Black Red Purple Silver Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-1-male-to-2-male-rca-audio-interconnects-for-dvd-player-television-4-feet-1-male-to-2-male-black-red-purple-silver-black/6597c11642faee33da08e191' },
{ name: 'Nivea Milk Delights Face Wash Moisturizing Honey For Dry Skin 100ml 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivea-milk-delights-face-wash-moisturizing-honey-for-dry-skin-100ml-100-ml/6597bc6542faee33da08e17d' },
{ name: 'Lifelong 20t Cycle For Kids 5 To 8 Years Bike For Boys And Girls 85 Pre Assembled Frame Size 12 Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-20t-cycle-for-kids-5-to-8-years-bike-for-boys-and-girls-85-pre-assembled-frame-size-12-suitable/6597bd9142faee33da08e182' },
{ name: 'Scott International Mens Quilted Puffer Jacket Lightweight Water Repellant Elastic Cuffs Zipped Pockets Casual Winter Jacket Stylish Outerwear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/scott-international-mens-quilted-puffer-jacket-lightweight-water-repellant-elastic-cuffs-zipped-pockets-casual-winter-jacket-stylish-outerwear/6597c36e42faee33da08e19b' },
{ name: 'Wow Skin Science Shea Butter And Cocoa Butter Moisturizing Body Lotion Deep Hydration 400ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-shea-butter-and-cocoa-butter-moisturizing-body-lotion-deep-hydration-400ml/6597bb3842faee33da08e178' },
{ name: 'Happilo Marvel International Lime Pepper Nut Party Snack 150g 255 Protein Healthy Snacks With Roasted Cashews Pumpkin Seeds Roasted California Almonds', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/happilo-marvel-international-lime-pepper-nut-party-snack-150g-255-protein-healthy-snacks-with-roasted-cashews-pumpkin-seeds-roasted-california-almonds/6597ba0c42faee33da08e173' },
{ name: 'Amazon Basics Subwoofer Dvd Player Cable 25 Feet Black Purple', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-subwoofer-dvd-player-cable-25-feet-black-purple-/6597bfea42faee33da08e18c' },
{ name: 'Amazon Basics Television Dvd Player 15 Feet 12 Male To 2 Male Rca Audio Interconnects Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-television-dvd-player-15-feet-12-male-to-2-male-rca-audio-interconnects-black/6597bebd42faee33da08e187' },
{ name: 'Wow Life Science Testoboostpack Of 60 Capsules', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-life-science-testoboostpack-of-60-capsules/6597c24242faee33da08e196' },
{ name: 'Himalaya Dark Spot Clearing Turmeric Face Wash Reduce Dark Spots In 7 Days Organically Sourced Cold Pressed Turmeric 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-dark-spot-clearing-turmeric-face-wash-reduce-dark-spots-in-7-days-organically-sourced-cold-pressed-turmeric-100ml/6597b8e042faee33da08e16e' },
{ name: 'Prakriti Naturals Chia Seed Omega 3 Seeds For Eating Non Gmo And Fibre Rich Seeds Best', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prakriti-naturals-chia-seed-omega-3-seeds-for-eating-non-gmo-and-fibre-rich-seeds-best/6597b7b442faee33da08e169' },
{ name: 'Hersheys Kisses Festive Moments Gift Pack Delicious Chocolatey Delights 1782g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-kisses-festive-moments-gift-pack-delicious-chocolatey-delights-1782g/6597b68742faee33da08e164' },
{ name: 'Hp V150w 32gb Usb 20 Flash Drive Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-v150w-32gb-usb-20-flash-drive-blue/6597b42f42faee33da08e15a' },
{ name: 'Strontium Pollex Usb 64 Gb Flash Drive Black Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strontium-pollex-usb-64-gb-flash-drive-black-red/6597b55b42faee33da08e15f' },
{ name: 'Schneider Line Up Fineliner With Stand Up Case 04mm 4 Pieces Assorted Colors 191094', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/schneider-line-up-fineliner-with-stand-up-case-04mm-4-pieces-assorted-colors-191094/6597ae5142faee33da08e141' },
{ name: 'Amazon Brand Solimo Polyester Neutral Bouquet Single Bedsheet With 1 Pillow Cover Multicolor 120 Gsm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-polyester-neutral-bouquet-single-bedsheet-with-1-pillow-cover-multicolor-120-gsm/6597af7d42faee33da08e146' },
{ name: 'Amazon Brand Solimo Executive Lunch Box Set Stainless Steel Containers Set Of 2 400 Ml 550 Ml Insulated Easy To Carry Lunch Bag', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-executive-lunch-box-set-stainless-steel-containers-set-of-2-400-ml-550-ml-insulated-easy-to-carry-lunch-bag/6597b1d642faee33da08e150' },
{ name: 'Havells Enticer Art Collector Edition 1200mm Decorative Dust Resistant High Power In Low Voltage Hplv High Speed Ceiling Fan Rose Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-enticer-art-collector-edition-1200mm-decorative-dust-resistant-high-power-in-low-voltage-hplv-high-speed-ceiling-fan-rose-gold/6597ad2442faee33da08e13c' },
{ name: 'Park Avenue Premium Mens Soaps', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-premium-mens-soaps/6597abf842faee33da08e137' },
{ name: 'Amazon Brand Solimo Plastic Kitchen Container Set With Airtight Lids Set Of 3 25l Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-plastic-kitchen-container-set-with-airtight-lids-set-of-3-25l-each/6597b0aa42faee33da08e14b' },
{ name: 'Lotus Botanicals Skin Brightening Day Cream Vitamin C Spf 25 Pa Lightweight Silicon Chemical Free All Skin Types 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-botanicals-skin-brightening-day-cream-vitamin-c-spf-25-pa-lightweight-silicon-chemical-free-all-skin-types-50g/6597aacc42faee33da08e132' },
{ name: 'Wipro Garnet 7w Led Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-7w-led-bulb/6597a99f42faee33da08e12d' },
{ name: 'Hersheys Kisses Cookies N Creme Chocolate 1008g Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-kisses-cookies-n-creme-chocolate-1008g-pack-of-4/6597a87342faee33da08e128' },
{ name: 'Wipro 3 Way Multiplug Adaptor With 1 Universal Socket Inbuilt Surge Protection Power Supply Indicator Compact Light Weight 6amp Multiplug Socket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-3-way-multiplug-adaptor-with-1-universal-socket-inbuilt-surge-protection-power-supply-indicator-compact-light-weight-6amp-multiplug-socket/6597a3c242faee33da08e114' },
{ name: 'Ecolink 20 Watt Polycarbonate Led Batten Cool Whitepack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecolink-20-watt-polycarbonate-led-batten-cool-whitepack-of-2/6597a61a42faee33da08e11e' },
{ name: 'True Elements Rolled Oats 12 Kg Gluten Free Oats Healthy Breakfast Cereal Diet Food Keeps You Full Till Lunch 100 Wholegrain Oats', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/true-elements-rolled-oats-12-kg-gluten-free-oats-healthy-breakfast-cereal-diet-food-keeps-you-full-till-lunch-100-wholegrain-oats/6597a16942faee33da08e10a' },
{ name: 'Wipro Garnet 20w Led Batten For Living Room Bedroom Bright Energy Efficient Tubelight', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-20w-led-batten-for-living-room-bedroom-bright-energy-efficient-tubelight/65979cb742faee33da08e0f6' },
{ name: 'E Cosmos Bowl With Water Strainer Multi Use Strainerwasher Bowl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-cosmos-bowl-with-water-strainer-multi-use-strainerwasher-bowl/6597a03d42faee33da08e105' },
{ name: 'Crompton Night Buddy 05 Watt Night Lamp With Usb Charging Adapter Port Natural White Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-night-buddy-05-watt-night-lamp-with-usb-charging-adapter-port-natural-white-pack-of-1/65979f1042faee33da08e100' },
{ name: 'Wow Skin Science Himalayan Rose Body Lotion For Light Hydration Normal To Oily Skin With Rose Water Beetroot Extract 400ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-himalayan-rose-body-lotion-for-light-hydration-normal-to-oily-skin-with-rose-water-beetroot-extract-400ml/6597a29542faee33da08e10f' },
{ name: 'Crompton Premion Aura2 Designer 2d Arab Design Antidust1200 Mm 48 Inch Ceiling Fan Pearl White Star Rated Energy Efficient Fans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-premion-aura2-designer-2d-arab-design-antidust1200-mm-48-inch-ceiling-fan-pearl-white-star-rated-energy-efficient-fans/65979de442faee33da08e0fb' },
{ name: 'Panasonic 9w Led Bulb Led Bulb 9 Watt With B22 Base 4kv Surge Protection 9 Watt Bulb Cool Day Light Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panasonic-9w-led-bulb-led-bulb-9-watt-with-b22-base-4kv-surge-protection-9-watt-bulb-cool-day-light-pack-of-2/65979a5f42faee33da08e0ec' },
{ name: 'Orient Electric High Glo Led Bulb 12w Cool White Light 6500k B22d Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-high-glo-led-bulb-12w-cool-white-light-6500k-b22d-pack-of-2/65979b8b42faee33da08e0f1' },
{ name: 'Crompton Mini Everest Residential Water Pump Self Priming Regenerative 2 Hp Single Phase', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-mini-everest-residential-water-pump-self-priming-regenerative-2-hp-single-phase/659795ae42faee33da08e0d8' },
{ name: 'Myfitfuel Bcaa 6300 211 200 Gm Tangy Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/myfitfuel-bcaa-6300-211-200-gm-tangy-orange/6597948142faee33da08e0d3' },
{ name: 'Open Secret Birthday Gift 12 Healthy Chocolate Cookies With Nuts No Maida Low Calorie Snacks Assorted Gift Box Personalized Happy Birthday Card Sticker Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/open-secret-birthday-gift-12-healthy-chocolate-cookies-with-nuts-no-maida-low-calorie-snacks-assorted-gift-box-personalized-happy-birthday-card-sticker-gift/6597980642faee33da08e0e2' },
{ name: 'Lakme 9 To 5 Complexion Care Face Cc Creamalmond Spf 30 Conceals Dark Spots Blemishes 30 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakme-9-to-5-complexion-care-face-cc-creamalmond-spf-30-conceals-dark-spots-blemishes-30-g/6597993242faee33da08e0e7' },
{ name: 'Vim Dishwash Liquid Gel Lemon Refill Pouch 2 Ltr Dishwash Gel Infused With The Power Of Lemons Leaves No Residue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vim-dishwash-liquid-gel-lemon-refill-pouch-2-ltr-dishwash-gel-infused-with-the-power-of-lemons-leaves-no-residue/659790fc42faee33da08e0c4' },
{ name: 'Luminous Lmu1202 Mini Ups Router Ups Reliable Power Backup During Power Cut', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-lmu1202-mini-ups-router-ups-reliable-power-backup-during-power-cut/65978fd042faee33da08e0bf' },
{ name: 'Urbn 20000 Mah 45w Ultra Fast Charging Compact Power Bank Type C Power Delivery Input Output Quick Charge Two Way Fast Charging Charge Laptopmobilestwsspeakers Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-20000-mah-45w-ultra-fast-charging-compact-power-bank-type-c-power-delivery-input-output-quick-charge-two-way-fast-charging-charge-laptopmobilestwsspeakers-blue/6597922842faee33da08e0c9' },
{ name: 'Hyperx Cloud Stinger Core Wireless On Ear Headphones With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hyperx-cloud-stinger-core-wireless-on-ear-headphones-with-mic-black/6597935542faee33da08e0ce' },
{ name: 'Zebronics Zeb Orion Usb Headphone With Simulated 71 Surround Sound 40mm Driver Rgb Led Advanced Windows Software 2m Braided Cable In Line Control Pod Flexible Mic And Suspended Headbands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-orion-usb-headphone-with-simulated-71-surround-sound-40mm-driver-rgb-led-advanced-windows-software-2m-braided-cable-in-line-control-pod-flexible-mic-and-suspended-headbands/659796da42faee33da08e0dd' },
{ name: 'Gigawatts Gw003gs 400w Electric Paint Sprayer Gun 800 Ml Capacity 600 Mlmin High Pressure Spraying Machine', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gigawatts-gw003gs-400w-electric-paint-sprayer-gun-800-ml-capacity-600-mlmin-high-pressure-spraying-machine/65978d7742faee33da08e0b5' },
{ name: 'Fire Boltt Dagger Luxe 143 Super Amoled Display Luxury Smartwatch Stainless Steel Build 600 Nits Brightness With Single Bt Bluetooth Connection Ip68 Dual Button Technology Stainless Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-dagger-luxe-143-super-amoled-display-luxury-smartwatch-stainless-steel-build-600-nits-brightness-with-single-bt-bluetooth-connection-ip68-dual-button-technology-stainless-silver/65978ea442faee33da08e0ba' },
{ name: 'Panca Stainless Steel Sauce Pan Set Of 3 Sauce Pan Milk Pan Tapeli Patila Induction Base 2 Litre15 Litre 1 Litre Milk Boiler Cookware With Handle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/panca-stainless-steel-sauce-pan-set-of-3-sauce-pan-milk-pan-tapeli-patila-induction-base-2-litre15-litre-1-litre-milk-boiler-cookware-with-handle/65978c4b42faee33da08e0b0' },
{ name: 'Renee Immortal All In One Face Cream Brightens Repairs Skin Reduces Spots Fine Lines With Vitamin C Niacinamide Lactic Acid Hyaluronic Acid Peptides Ceramides', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renee-immortal-all-in-one-face-cream-brightens-repairs-skin-reduces-spots-fine-lines-with-vitamin-c-niacinamide-lactic-acid-hyaluronic-acid-peptides-ceramides/6597866d42faee33da08e097' },
{ name: 'Bevzilla 50 Grams X 4 Flavoured Instant Coffee Powder Pack Box Of Colombian Gold Turkish Hazelnut French Vanilla English Butterscotch Strong Coffee 100 Arabica Instant Coffee', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bevzilla-50-grams-x-4-flavoured-instant-coffee-powder-pack-box-of-colombian-gold-turkish-hazelnut-french-vanilla-english-butterscotch-strong-coffee-100-arabica-instant-coffee/659782e842faee33da08e088' },
{ name: 'Parachute Advansed Onion Hair Oil For Hair Growth 200ml Hair Shampoo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-onion-hair-oil-for-hair-growth-200ml-hair-shampoo/659781bc42faee33da08e083' },
{ name: 'Nutri Blender Grinder By Echt Nb4s 450 Watt2 Multi Purpose Jars', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutri-blender-grinder-by-echt-nb4s-450-watt2-multi-purpose-jars/6597879942faee33da08e09c' },
{ name: 'Vivel Vedvidya Luxury Pack Of 3 Skincare Soaps For Soft Even Toned Clear Radiant And Glowing Skin Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivel-vedvidya-luxury-pack-of-3-skincare-soaps-for-soft-even-toned-clear-radiant-and-glowing-skin-suitable/6597854142faee33da08e092' },
{ name: 'Size 10 Uk 11 Us Reebok Mens Reebok Genesis Runner M Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-10-uk-11-us-reebok-mens-reebok-genesis-runner-m-sneaker/6597841442faee33da08e08d' },
{ name: 'Ant Esports Alpha Lite Sonic Electric Toothbrush With 2 Brush Heads Sonic Toothbrushes 28000 Vpm Ipx7 Ergonomic Designs Aaa Powered 120 Days Battery Life Ultra Soft Dupont Vibrating Bristles Smart Travel Power Toothbrush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-alpha-lite-sonic-electric-toothbrush-with-2-brush-heads-sonic-toothbrushes-28000-vpm-ipx7-ergonomic-designs-aaa-powered-120-days-battery-life-ultra-soft-dupont-vibrating-bristles-smart-travel-power-toothbrush/6597808f42faee33da08e07e' },
{ name: 'Gionee Stylfit Gsw11 Smartwatch With Large 18 Ips Display Spo2 Stress Monitor Heart Rate Monitor 200 Watch Faces Ebony Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gionee-stylfit-gsw11-smartwatch-with-large-18-ips-display-spo2-stress-monitor-heart-rate-monitor-200-watch-faces-ebony-black/65977f6342faee33da08e079' },
{ name: 'Gionee Stylfit Gsw11 Smartwatch With Large 18 Ips Display Spo2 Stress Monitor Heart Rate Monitor 200 Watch Facesstate Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gionee-stylfit-gsw11-smartwatch-with-large-18-ips-display-spo2-stress-monitor-heart-rate-monitor-200-watch-facesstate-silver/65977e3642faee33da08e074' },
{ name: 'Kanshitas Rasoiware Stainless Steel Sauce Pantea Pan 750 And 1500 Ml Induction And Gas Stove Friendly Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kanshitas-rasoiware-stainless-steel-sauce-pantea-pan-750-and-1500-ml-induction-and-gas-stove-friendly-pack-of-2/65978b1e42faee33da08e0ab' },
{ name: 'Noise Colorfit Pro 4 Alpha 178 Amoled Display Bluetooth Calling Smart Watch Functional Crown Metallic Build Intelligent Gesture Control Instacharge Jet Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-colorfit-pro-4-alpha-178-amoled-display-bluetooth-calling-smart-watch-functional-crown-metallic-build-intelligent-gesture-control-instacharge-jet-black/659788c642faee33da08e0a1' },
{ name: 'The Sleep Company Smartgrid Orthopedic Pro Doctor Recommended Pressure Relieving Scientifically Proven 5 Zone Support 8 Inch Single Size Aiha Certified Firm Mattress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-sleep-company-smartgrid-orthopedic-pro-doctor-recommended-pressure-relieving-scientifically-proven-5-zone-support-8-inch-single-size-aiha-certified-firm-mattress/659789f242faee33da08e0a6' },
{ name: 'Worthy Shoppee Plant Stand Metal 6 Tier 7 Potted Multiple Flower Pot Holder Shelf Indoor Outdoor Planter Display Shelving Unit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/worthy-shoppee-plant-stand-metal-6-tier-7-potted-multiple-flower-pot-holder-shelf-indoor-outdoor-planter-display-shelving-unit/65977bde42faee33da08e06a' },
{ name: 'Nature Purify Pure Natural 1000 Gram Anjeer Tasty Healthy Anytime Snacking Diting Delicious', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nature-purify-pure-natural-1000-gram-anjeer-tasty-healthy-anytime-snacking-diting-delicious/65977d0a42faee33da08e06f' },
{ name: 'Finedine Premium Muscle Body Massager Gun With 6 Speed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/finedine-premium-muscle-body-massager-gun-with-6-speed/6597772d42faee33da08e052' },
{ name: 'Sery Eyeshadow Palette Gold Show Shimmery', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sery-eyeshadow-palette-gold-show-shimmery/6597760042faee33da08e04d' },
{ name: 'Kenneth Cole Reaction Womens Quartz Metal And Stainless Steel Casual Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kenneth-cole-reaction-womens-quartz-metal-and-stainless-steel-casual-watch/659774d442faee33da08e048' },
{ name: 'Urbano Fashion Leather Belt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbano-fashion-leather-belt/65977ab142faee33da08e065' },
{ name: 'Ponds Pure Detox Facewash 100g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-pure-detox-facewash-100g/6597785942faee33da08e057' },
{ name: 'Kbs Washing Machine Cleaner Tablets Descaler Drum Cleaner Deep Cleaning Descaling Laundry Accessories 12', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kbs-washing-machine-cleaner-tablets-descaler-drum-cleaner-deep-cleaning-descaling-laundry-accessories-12/6597798542faee33da08e060' },
{ name: 'Hersheys Exotic Dark Chocolate Raspberry Goji 333g Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-exotic-dark-chocolate-raspberry-goji-333g-pack-of-10/65976d7f42faee33da08e043' },
{ name: 'Park Avenue Men 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-men-1/65975f6f42faee33da08e03e' },
{ name: 'Fire Boltt Ninja 3 Smartwatch Full Touch 169 60 Sports Modes With Ip68 Sp02 Tracking Over 100 Cloud Based Watch Faces Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-ninja-3-smartwatch-full-touch-169-60-sports-modes-with-ip68-sp02-tracking-over-100-cloud-based-watch-faces-black/6597515e42faee33da08e039' },
{ name: 'Havells Sprint 400mm Energy Saving With Remote Control Bldc Pedestal Fan White Yellow Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-sprint-400mm-energy-saving-with-remote-control-bldc-pedestal-fan-white-yellow-pack-of-2/6597434e42faee33da08e034' },
{ name: 'Philips Ojasmini Rechargeable Emergency Led Lantern', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ojasmini-rechargeable-emergency-led-lantern/6597191d42faee33da08d50b' },
{ name: 'Status Contract Set Of 2 Hair Towel Super Absorbent Quick Dry Towel Bathrobe Magic Hair Warp Towel Super Quick Drying Microfiber Bath Towel Bath Towel Pink 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-set-of-2-hair-towel-super-absorbent-quick-dry-towel-bathrobe-magic-hair-warp-towel-super-quick-drying-microfiber-bath-towel-bath-towel-pink-2/6597272e42faee33da08d510' },
{ name: 'Sugr Womens Polyester Solid Ankle Length Night Gown', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugr-womens-polyester-solid-ankle-length-night-gown/65970a8d42faee33da08d4f9' },
{ name: 'Kadio Analog 245 Cm X 245 Cm Wall Clock Maroon With Glass Standard', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kadio-analog-245-cm-x-245-cm-wall-clock-maroon-with-glass-standard/65970ac942faee33da08d502' },
{ name: 'Hersheys Exotic Dark Chocolate Raspberry Goji 333g Pack Of 10', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-exotic-dark-chocolate-raspberry-goji-333g-pack-of-10/659704de42faee33da08d4e0' },
{ name: 'Lotus Herbals Radiance Boost Ubtan Face Wash Turmeric Sandalwood And Rose Water Glowing Skin Reducing Dark Spots Paraben Free Mineral Oil Free 100gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-herbals-radiance-boost-ubtan-face-wash-turmeric-sandalwood-and-rose-water-glowing-skin-reducing-dark-spots-paraben-free-mineral-oil-free-100gm/6597086042faee33da08d4eb' },
{ name: 'Gadgetbite Soft Silicone Buckle Strap Compatible With Watch Of 42mm 44mm 45mm Soft Silicone Buckle Strap Wristbands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gadgetbite-soft-silicone-buckle-strap-compatible-with-watch-of-42mm-44mm-45mm-soft-silicone-buckle-strap-wristbands/659708ba42faee33da08d4f0' },
{ name: 'Yardley London Refreshing Deo Body Spray Tripack English Lavender English Rose Morning Dew For Women 150ml Each Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yardley-london-refreshing-deo-body-spray-tripack-english-lavender-english-rose-morning-dew-for-women-150ml-each-pack-of-3/6597353e42faee33da08de6b' },
{ name: 'Lotus Botanicals Skin Brightening Night Cream Vitamin C Silicon Chemical Free All Skin Types 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-botanicals-skin-brightening-night-cream-vitamin-c-silicon-chemical-free-all-skin-types-50g/6594526842faee33da08cc85' },
{ name: 'Cortina 3 Layer Reversible Sofa Cover 100 Polyester Quilted Fabric Couch Covers Machine Washable Suitable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cortina-3-layer-reversible-sofa-cover-100-polyester-quilted-fabric-couch-covers-machine-washable-suitable/6594526842faee33da08cc89' },
{ name: 'Park Avenue Men 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-avenue-men-1/659704a842faee33da08d4cf' },
{ name: 'Kenneth Cole Reaction Womens Quartz Metal And Stainless Steel Casual Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kenneth-cole-reaction-womens-quartz-metal-and-stainless-steel-casual-watch/6597037542faee33da08d4c4' },
{ name: 'Havells Bt5100c Rechargeable Beard Trimmer With Hypoallergenic Blades Zero Trim With 05 Mm Precisionupto 17mm Length Setting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-bt5100c-rechargeable-beard-trimmer-with-hypoallergenic-blades-zero-trim-with-05-mm-precisionupto-17mm-length-setting/659443f942faee33da08cbdc' },
{ name: 'Hp 150 Black Wired On Ear Headset With In Line Microphonetaggle Free With Long Flat Cord1 Year Rtb Warranty X7b04aa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-150-black-wired-on-ear-headset-with-in-line-microphonetaggle-free-with-long-flat-cord1-year-rtb-warranty-x7b04aa-black/659443f942faee33da08cbc4' },
{ name: 'Size S Allen Solly Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-allen-solly-mens-regular-fit-t-shirt/659443f942faee33da08cbbc' },
{ name: 'Sounce Cleaning Soft Brush Keyboard Cleaner 5 In 1 Multi Function Computer Cleaning Tools Kit Corner Gap Duster Keycap Puller', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sounce-cleaning-soft-brush-keyboard-cleaner-5-in-1-multi-function-computer-cleaning-tools-kit-corner-gap-duster-keycap-puller/659443f842faee33da08cbb4' },
{ name: 'Philips 9 Watts Multipack B22 Led Cool Day White Led Bulb Pack Of 2 Ace Saver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-9-watts-multipack-b22-led-cool-day-white-led-bulb-pack-of-2-ace-saver/6594474142faee33da08cc02' },
{ name: 'Carsun Tyre Inflator For Car150psi Car Tyre 120 Watts Inflator Pump Dc 12v Portable Air Compressor Pump With Led Lightdigital Pressure Gauge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/carsun-tyre-inflator-for-car150psi-car-tyre-120-watts-inflator-pump-dc-12v-portable-air-compressor-pump-with-led-lightdigital-pressure-gauge/659443f942faee33da08cbeb' },
{ name: 'Size 6 Uk Liberty Mens Jpl 247 Black Uniform Dress Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-uk-liberty-mens-jpl-247-black-uniform-dress-shoe/659443f842faee33da08cbb8' },
{ name: 'Parachute Advansed Coconut Water Hydrating Gel Niacinamide Glycerine Insta 2x Hydration 72h Moisturisation Skin Hair 200ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-coconut-water-hydrating-gel-niacinamide-glycerine-insta-2x-hydration-72h-moisturisation-skin-hair-200ml/659443f942faee33da08cbe6' },
{ name: 'Camay Classic Carnations Roses Beauty Soap With Indulging French Fragrance Moisturizing Bathing Body Soap With Natures Scent Creamy Lather', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/camay-classic-carnations-roses-beauty-soap-with-indulging-french-fragrance-moisturizing-bathing-body-soap-with-natures-scent-creamy-lather/659443f842faee33da08cb9c' },
{ name: 'Beardo Godfather Perfume Deo Spray 150ml Mafia Perfume Body Spray 120ml Maverick Perfume Deo Spray 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beardo-godfather-perfume-deo-spray-150ml-mafia-perfume-body-spray-120ml-maverick-perfume-deo-spray-150ml/659443f842faee33da08cbac' },
{ name: 'Engage W3 Perfume Spray For Women Citrus And Floral Skin Friendly 120ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-w3-perfume-spray-for-women-citrus-and-floral-skin-friendly-120ml/659443f842faee33da08cba0' },
{ name: 'Parachute Advansed Deep Nourish Body Lotion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-deep-nourish-body-lotion/659443f842faee33da08cba4' },
{ name: 'Msi Gaming Gf63 Thin Intel Core I7 11800h 40cm Fhd 144hz Gaming Laptop 8gb512gb Nvme Ssdwindows 10 Homenvidia Gtx1650 4gb Gddr6 Black186kg 11sc 852in', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/msi-gaming-gf63-thin-intel-core-i7-11800h-40cm-fhd-144hz-gaming-laptop-8gb512gb-nvme-ssdwindows-10-homenvidia-gtx1650-4gb-gddr6-black186kg-11sc-852in/6594376342faee33da08cb83' },
{ name: 'Apple 2021 102 Inch 2591 Cm Ipad With A13 Bionic Chip Wi Fi 64gb Space Grey 9th Generation', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-2021-102-inch-2591-cm-ipad-with-a13-bionic-chip-wi-fi-64gb-space-grey-9th-generation/6594387142faee33da08cb97' },
{ name: 'Boat Stone 352 Bluetooth Speaker With 10w Rms Stereo Sound Ipx7 Water Resistance Tws Feature Up To 12h Total Playtime Multi Compatibility Modes And Type C Chargingraging Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-stone-352-bluetooth-speaker-with-10w-rms-stereo-sound-ipx7-water-resistance-tws-feature-up-to-12h-total-playtime-multi-compatibility-modes-and-type-c-chargingraging-black/65916491d1516e3c398c9606' },
{ name: 'Webby Plush Husky Dog Stuffed Animal Puppy Soft Toy Adorable Gifts For Kids And Adult Glitter Eyes Soft Toy 35cm Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/webby-plush-husky-dog-stuffed-animal-puppy-soft-toy-adorable-gifts-for-kids-and-adult-glitter-eyes-soft-toy-35cm-grey/658ecfb4f15a623316195d15' },
{ name: 'Redmi Buds 4 Active Bass Black 12mm Driverspremium Sound Quality Up To 30 Hours Battery Life Google Fast Pair Ipx4 Bluetooth 53 Enc Up To 60ms Low Latency Mode App Support', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-buds-4-active-bass-black-12mm-driverspremium-sound-quality-up-to-30-hours-battery-life-google-fast-pair-ipx4-bluetooth-53-enc-up-to-60ms-low-latency-mode-app-support/659435f542faee33da08cb79' },
{ name: 'Apply 50 Coupon Aula S11 Wired Gaming Mouse Ultra Lightweight Honeycomb Computer Mice With Rgb Backlit 3600 Dpi Optical Sensor 15m Cable Length Usb Computer Mouse For Desktop Pc Gamers Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apply-50-coupon-aula-s11-wired-gaming-mouse-ultra-lightweight-honeycomb-computer-mice-with-rgb-backlit-3600-dpi-optical-sensor-15m-cable-length-usb-computer-mouse-for-desktop-pc-gamers-black/659434a342faee33da08cb69' },
{ name: 'Philips 5 Star Cctv Wi Fi Hsp3500 Indoor 360 3 Mp 2k1296p Resolution Security Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-5-star-cctv-wi-fi-hsp3500-indoor-360-3-mp-2k1296p-resolution-security-camera/658ed0d9f15a621057195d27' },
{ name: 'Benq Refurbished 27 Inch 686 Cm Edge To Edge Slim Bezel Led Monitor Full Hd Ips Panel With Vga Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/benq-refurbished-27-inch-686-cm-edge-to-edge-slim-bezel-led-monitor-full-hd-ips-panel-with-vga-black/658b98b94da4ee503d7cf7d1' },
{ name: 'Tcl 8004 Cm 32 Inches Bezel Less S Series Hd Ready Smart Android Led Tv 32s5400a Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tcl-8004-cm-32-inches-bezel-less-s-series-hd-ready-smart-android-led-tv-32s5400a-black/659436ea42faee33da08cb7e' },
{ name: 'Apply 50 Off Carsun Tyre Inflator For Car150psi Car Tyre 120 Watts Inflator Pump Dc 12v Portable Air Compressor Pump With Led Lightdigital Pressure Gauge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apply-50-off-carsun-tyre-inflator-for-car150psi-car-tyre-120-watts-inflator-pump-dc-12v-portable-air-compressor-pump-with-led-lightdigital-pressure-gauge/659435a842faee33da08cb74' },
{ name: 'Campus Mens Crysta Prorunning Shoes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/campus-mens-crysta-prorunning-shoes/658b984e4da4ee8a417cf7cc' },
{ name: 'Whirlpool 184 L 4 Star Inverter Direct Cool Single Door Refrigerator 205 Wde Roy 4sinv Sapphire Flower Rain Z Blue Base Stand With Drawer 2023 Model', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/whirlpool-184-l-4-star-inverter-direct-cool-single-door-refrigerator-205-wde-roy-4sinv-sapphire-flower-rain-z-blue-base-stand-with-drawer-2023-model/656df83f9df68e609dfff511' },
{ name: 'Fegsy Adjustable Knee Cap Support Brace For Knee Pain Gym Workout Running Arthritis And Protection For Men And Womennon Toxic 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fegsy-adjustable-knee-cap-support-brace-for-knee-pain-gym-workout-running-arthritis-and-protection-for-men-and-womennon-toxic-2/656f52302897a3deab8c0801' },
{ name: 'Lawman Pg3 Mens Regular Track Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lawman-pg3-mens-regular-track-pants/658b96f24da4ee642a7cf7bd' },
{ name: 'Pindia 20 Arch Strings Acrylic Crystal Bead Curtain Transparent For Partition And Decoration', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pindia-20-arch-strings-acrylic-crystal-bead-curtain-transparent-for-partition-and-decoration/658b978b4da4ee1f857cf7c7' },
{ name: 'Commander Shoes Casual Heel Boots For Girls And Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/commander-shoes-casual-heel-boots-for-girls-and-women/656df7559df68e609dfff506' },
{ name: 'Laxis Laundry Basket Heavy Duty Laundry Basket Clothes Storage Basket For Blankets Laundry Room Organizing Bedroom Large Dirty Clothes Bag For Dorm Apartment And Laundromat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/laxis-laundry-basket-heavy-duty-laundry-basket-clothes-storage-basket-for-blankets-laundry-room-organizing-bedroom-large-dirty-clothes-bag-for-dorm-apartment-and-laundromat/656cbc002f2bd152b82b2622' },
{ name: 'Apple 2022 109 Inch Ipad Wi Fi 64gb Blue 10th Generation', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-2022-109-inch-ipad-wi-fi-64gb-blue-10th-generation-/656cbb482f2bd121dd2b261d' },
{ name: 'Philips Digital Air Fryer Hd925290 With Touch Panel Uses Up To 90 Less Fat 7 Pre Set Menu 1400w 41 Liter With Rapid Air Technology Black Large', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-digital-air-fryer-hd925290-with-touch-panel-uses-up-to-90-less-fat-7-pre-set-menu-1400w-41-liter-with-rapid-air-technology-black-large/656df7e29df68e609dfff50c' },
{ name: 'Artarium Marble Dust And Resin Lord Bahubali Hanuman Idol Statue Bajrang Bali Murti Showpiece Height 748 Inch Gold Antique', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/artarium-marble-dust-and-resin-lord-bahubali-hanuman-idol-statue-bajrang-bali-murti-showpiece-height-748-inch-gold-antique/65660a520502b99c67bbf64f' },
{ name: 'Involve Your Senses One Musk Organic Car Perfume Involve Your Senses Strong Fiber Air Freshener To Freshenup Your Car Ione01 40 Gcar Accessories Interior Car Perfumes And Fresheners', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/involve-your-senses-one-musk-organic-car-perfume-involve-your-senses-strong-fiber-air-freshener-to-freshenup-your-car-ione01-40-gcar-accessories-interior-car-perfumes-and-fresheners/656609d00502b979dcbbf648' },
{ name: 'Razzai Luxury Down Alternative Soft Quilted 300 Gsm All Weather Comforter 60 X 90 Inches152cm X 228cm Single Bedsilve Microfiber Breathable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/razzai-luxury-down-alternative-soft-quilted-300-gsm-all-weather-comforter-60-x-90-inches152cm-x-228cm-single-bedsilve-microfiber-breathable/6566092f0502b9b160bbf630' },
{ name: 'Apple Airpods Pro 2nd Generation', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-airpods-pro-2nd-generation-/6566087c0502b9b737bbf62b' },
{ name: 'Puma Womens Maximal Comfort Wns Walking Shoe', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/puma-womens-maximal-comfort-wns-walking-shoe/6566082c0502b91200bbf626' },
{ name: 'Adidas Mens Fleecewalk M Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/adidas-mens-fleecewalk-m-sneaker/656401633a5efb9ea7afbeda' },
{ name: 'Nakoda 222 Dune Basket Without Lid Set Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nakoda-222-dune-basket-without-lid-set-of-1/65640068f8796e453b22954f' },
{ name: 'Nuts About You Raisin 500 G 100 Natural Premium Kishmish Saugi', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nuts-about-you-raisin-500-g-100-natural-premium-kishmish-saugi/6560bf3c1aacb9f30dc385f2' },
{ name: 'Sugr Womens Polyester Graphic Print Nightwear Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugr-womens-polyester-graphic-print-nightwear-set/655ac93292585730d6fe100c' },
{ name: 'Laxis Laundry Basket Heavy Duty Laundry Basket Clothes Storage Basket For Blankets Laundry Room Organizing Bedroom Large Dirty Clothes Bag For Dorm Apartment And Laundromat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/laxis-laundry-basket-heavy-duty-laundry-basket-clothes-storage-basket-for-blankets-laundry-room-organizing-bedroom-large-dirty-clothes-bag-for-dorm-apartment-and-laundromat/6561660dfe902879934bf939' },
{ name: 'Dhruvi Trendz Women Dt Sh149w1516', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dhruvi-trendz-women-dt-sh149w1516/6560bfd21aacb96c1bc385f9' },
{ name: 'Yogabar Instant Oats 2 Kg Premium Oats Ready To Cook Gluten Free Oats With High Fibre 100 Whole Grain Non Gmo 2kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-instant-oats-2-kg-premium-oats-ready-to-cook-gluten-free-oats-with-high-fibre-100-whole-grain-non-gmo-2kg/6563ff9af8796e280822954a' },
{ name: '20w Type C Port 30 Pd Fast Charger Compatible For 1212 Pro 12 Pro Max 12 Mini 1111 Pro 11 Pro Max Bis Certified Only Adapter Kvrt 23 Pda 1611', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/20w-type-c-port-30-pd-fast-charger-compatible-for-1212-pro-12-pro-max-12-mini-1111-pro-11-pro-max-bis-certified-only-adapter-kvrt-23-pda-1611/655ac86e925857a75afe1007' },
{ name: 'Satrani Womens Printed Poly Silk Saree With Unstitched Blouse Piece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/satrani-womens-printed-poly-silk-saree-with-unstitched-blouse-piece/655ac9929258571956fe1011' },
{ name: 'Wipro Smart Wireless Doorbell 2 Mp 1080p Full Hd Camera With Night Vision Ai Smart Motion Detection Two Way Communication Wireless Chime With 52 Tunes Ip65 Protection Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-smart-wireless-doorbell-2-mp-1080p-full-hd-camera-with-night-vision-ai-smart-motion-detection-two-way-communication-wireless-chime-with-52-tunes-ip65-protection-black/655cdd1a9c257e37caaac704' },
{ name: 'Ausk Mens Henley Neck Full Sleeves Regular Fit Cotton T Shirts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ausk-mens-henley-neck-full-sleeves-regular-fit-cotton-t-shirts-/65536b9f656ba6944f8357f7' },
{ name: 'Hersheys Bar Whole Almond Creamy Milk Chocolate 100 Gm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-bar-whole-almond-creamy-milk-chocolate-100-gm-pack-of-2/65598541c8d94ce885b0ee9c' },
{ name: 'Oneplus 9rt 5g Hacker Black 12gb Ram 256gb Storagealexa Hands Free Capable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-9rt-5g-hacker-black-12gb-ram-256gb-storagealexa-hands-free-capable/65504d991230c0d2082b4fd0' },
{ name: 'Dennis Lingo Mens Checkered Slim Fit Cotton Casual Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dennis-lingo-mens-checkered-slim-fit-cotton-casual-shirt/65502d0e5036feabbe7d153c' },
{ name: 'Tp Link Tapo 2mp 1080p Full Hd Home Security Wi Fi Smart Camera Alexa Enabled 2 Way Audio Night Vision Motion Detection Sound And Light Alarm Indoor Cctv Tapo C100 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tapo-2mp-1080p-full-hd-home-security-wi-fi-smart-camera-alexa-enabled-2-way-audio-night-vision-motion-detection-sound-and-light-alarm-indoor-cctv-tapo-c100-white/65504e041230c022d92b4fd6' },
{ name: 'Kwality Corn Flakes Made With Golden Corns 99 Fat Free Natural Source Of Vitamin Iron And Protein', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kwality-corn-flakes-made-with-golden-corns-99-fat-free-natural-source-of-vitamin-iron-and-protein-/65557a252f916529cf3342b9' },
{ name: 'Tygot 10 Portable Led Ring Light With 3 Color Modes Dimmable Lighting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tygot-10-portable-led-ring-light-with-3-color-modes-dimmable-lighting/655984c6c8d94c9e13b0ee97' },
{ name: 'Bullar Customizable Home Gym Set And Dumbbells Set With Steel Iron Rubber Rubber Coated Weight Plates Complete Home Gym Combo Home Gym Dumbbell Rods Rubber 10 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bullar-customizable-home-gym-set-and-dumbbells-set-with-steel-iron-rubber-rubber-coated-weight-plates-complete-home-gym-combo-home-gym-dumbbell-rods-rubber-10-kg/654ee60944d214581322a733' },
{ name: 'Savlon Moisturizing Glycerin Soap Bar With Germ Protection Pack Of 5 125g Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savlon-moisturizing-glycerin-soap-bar-with-germ-protection-pack-of-5-125g-each/654e405a02e8ebd8727b907c' },
{ name: 'Digital Alarm Clockbattery Operated Small Desk Clockswith Dateindoor Temperaturesmart Night Lightlcd Electronic Clock For Bedroom Home Office White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/digital-alarm-clockbattery-operated-small-desk-clockswith-dateindoor-temperaturesmart-night-lightlcd-electronic-clock-for-bedroom-home-office-white/6559b6714571e47e94c14e4e' },
{ name: 'Safari Polyester Soft Suitcase Set Pack Of 2 Prismaml4wredred', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/safari-polyester-soft-suitcase-set-pack-of-2-prismaml4wredred/6559b5354571e4891cc14e48' },
{ name: 'Deevine Craft Soft Cotton Quilt Foldable Light Weight Filled Single Firm Mattress Gadda 3d Design Printed 1 Sleeping Capacity 3 X 6 Ft Or 72 X 36 Inches Grey Floral Cotton Mattress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deevine-craft-soft-cotton-quilt-foldable-light-weight-filled-single-firm-mattress-gadda-3d-design-printed-1-sleeping-capacity-3-x-6-ft-or-72-x-36-inches-grey-floral-cotton-mattress/655985a4c8d94c65a8b0eea1' },
{ name: 'Philips Starfit 10 Watt Concealed Jb Led Downlighter For Ceiling With 3 Inch Cut Out Cool Day Light Pack Of 4 White 9290025934064', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-starfit-10-watt-concealed-jb-led-downlighter-for-ceiling-with-3-inch-cut-out-cool-day-light-pack-of-4-white-9290025934064/654c63af30eec1805ab0335a' },
{ name: 'Optimum Nutrition On Gold Standard 100 Whey Protein Powder 17 Kg Double Rich Chocolate 55 Serves For Muscle Support Recovery Vegetarian Primary Source Whey Isolate', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/optimum-nutrition-on-gold-standard-100-whey-protein-powder-17-kg-double-rich-chocolate-55-serves-for-muscle-support-recovery-vegetarian-primary-source-whey-isolate/654c3f8641cf6525ce8b2866' },
{ name: 'Aegon Gym Water Bottle Sports Bpa Free Motivational Time Marked Sipper Bottle For Gym Office School Stylish Gym Bottle For Men Women Leak Proof Sports Shaker 1 Liter Tritan Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aegon-gym-water-bottle-sports-bpa-free-motivational-time-marked-sipper-bottle-for-gym-office-school-stylish-gym-bottle-for-men-women-leak-proof-sports-shaker-1-liter-tritan-black/654cdab65152ab0c6d776aeb' },
{ name: '2000 Coupon Samsung Galaxy A23 5g Silver 6gb 128gb Storage With Offer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/2000-coupon-samsung-galaxy-a23-5g-silver-6gb-128gb-storage-with-offer/654db877f62609d02f1d20d8' },
{ name: 'Oneplus Nord Ce 3 Lite 5g Pastel Lime 8gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-nord-ce-3-lite-5g-pastel-lime-8gb-ram-128gb-storage/654cdc435152ab6a99776af0' },
{ name: 'Bajaj Splendora 3 Litre 3kw Iwh Instant Water Heater Geyser White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-splendora-3-litre-3kw-iwh-instant-water-heater-geyser-white/654c7ca0dd96e949c882c388' },
{ name: 'Pampers All Round Protection Pants Style Baby Diapers Large L Size 64 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pampers-all-round-protection-pants-style-baby-diapers-large-l-size-64-count/654bd0931b2aedf3a26c5d61' },
{ name: 'Dyazo Aluminum Super Light Weight Capacitive Stylus Pen For Touch Screen Devices With Fine Point Disc Compatible With All Ios And Smart Android Phone Tablets Non Magnetic White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dyazo-aluminum-super-light-weight-capacitive-stylus-pen-for-touch-screen-devices-with-fine-point-disc-compatible-with-all-ios-and-smart-android-phone-tablets-non-magnetic-white/654e3fc302e8eb5efd7b9077' },
{ name: 'Whirlpool 6 Kg 5 Star Royal Fully Automatic Top Loading Washing Machine Whitemagic Royal 6 Genx Grey Zpf Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/whirlpool-6-kg-5-star-royal-fully-automatic-top-loading-washing-machine-whitemagic-royal-6-genx-grey-zpf-technology/654bd1011b2aed1fbf6c5d66' },
{ name: 'Qubo Smart Air Purifier Q200 From Hero Group Wifi App Control Voice Control True Hepa H 13 Filter Removes Air Pollutants Micro Allergens 4 Stage Filtration Coverage Area Up To 200 Sq Ft', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qubo-smart-air-purifier-q200-from-hero-group-wifi-app-control-voice-control-true-hepa-h-13-filter-removes-air-pollutants-micro-allergens-4-stage-filtration-coverage-area-up-to-200-sq-ft/654b8ea9f3477d43820344dc' },
{ name: 'Rangriti Women Dress Pants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rangriti-women-dress-pants/654b61c1ed9782156c6482f3' },
{ name: 'Gesto 8 Modes 10 Meters33 Feet Remote Operated Waterproof Fairy String Light Forbirthdayfestivalweddingparty For Home Warm Whiteledcorded Electric', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gesto-8-modes-10-meters33-feet-remote-operated-waterproof-fairy-string-light-forbirthdayfestivalweddingparty-for-home-warm-whiteledcorded-electric/654b6353ed9782f9ca648302' },
{ name: 'Ecraftindia Handcrafted Polyresin Black Adiyogi Lord Shiva Statue Murti For Car', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ecraftindia-handcrafted-polyresin-black-adiyogi-lord-shiva-statue-murti-for-car-/654b92491382502bc3e0ca23' },
{ name: 'Hp Laptop 15 12th Gen I5 1235u 156 Inch 396 Cm Fhd Anti Glare 8gb Ddr4 512gb Ssd Intel Iris X Graphics Backlit Keyboard Dual Speakers Win 11 Mso 2021 Silver 169 Kg 15s Fy5007tu', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-laptop-15-12th-gen-i5-1235u-156-inch-396-cm-fhd-anti-glare-8gb-ddr4-512gb-ssd-intel-iris-x-graphics-backlit-keyboard-dual-speakers-win-11-mso-2021-silver-169-kg-15s-fy5007tu/654b6242ed9782afa06482f8' },
{ name: 'Cadbury Chocobakes Choclayered Cakes 114 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-chocobakes-choclayered-cakes-114-g/65499aac2a31732239f0d304' },
{ name: 'Vendoz Women Stylish Flat Sandals', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vendoz-women-stylish-flat-sandals/654b6164ed978235a96482ee' },
{ name: 'Amazon Basics 9w Smart Led Bulb With Alexa Google Assistant Wi Fi Enabled Pack Of 1 Multicolour B22 Holder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-basics-9w-smart-led-bulb-with-alexa-google-assistant-wi-fi-enabled-pack-of-1-multicolour-b22-holder/6549b8733024a5b73d8216bd' },
{ name: 'Ben Martin Mens Relaxed Fit Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ben-martin-mens-relaxed-fit-jeans/654b62aeed97823b276482fd' },
{ name: 'Philips Cordless Facial Hair Remover With Wide Hypoallergenic Head For Gentle', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-cordless-facial-hair-remover-with-wide-hypoallergenic-head-for-gentle-/654999de2a31733d14f0d2fa' },
{ name: 'Ptron Newly Launched Reflect Pro Smartwatch Bluetooth Calling 185 Full Touch Display 600 Nits Digital Crown Metal Frame 100 Watch Faces Hr Spo2 Voice Assist 5 Days Battery Life Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-newly-launched-reflect-pro-smartwatch-bluetooth-calling-185-full-touch-display-600-nits-digital-crown-metal-frame-100-watch-faces-hr-spo2-voice-assist-5-days-battery-life-black/654999892a31739171f0d2f5' },
{ name: 'Msi G2412 Esports Gaming Monitor 238 Inch Full Hd 1920x1080 Ips Panel 170hz Refresh Rate 1ms Wide Color Gamut Amd Freesync Premium Anti Glare Less Blue Light Vesa Mount Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/msi-g2412-esports-gaming-monitor-238-inch-full-hd-1920x1080-ips-panel-170hz-refresh-rate-1ms-wide-color-gamut-amd-freesync-premium-anti-glare-less-blue-light-vesa-mount-black-/65499a352a3173296bf0d2ff' },
{ name: 'Cockatoo Shoe Bag For Travel Storage Women Men Travelling Shoe Storage Bag Shoe Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-shoe-bag-for-travel-storage-women-men-travelling-shoe-storage-bag-shoe-storage/6545e984e27562d324357d38' },
{ name: 'Cello Non Stick 12 Cavity Appam Patra 2 Side Handle With Stainless Steel Lid', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-non-stick-12-cavity-appam-patra-2-side-handle-with-stainless-steel-lid/6545090f557c92c31d1aa567' },
{ name: 'Limited Time Deal Allextreme Exctcb1 T Type Car Tyre Cleaning Brush Scrubber With Antislip Handl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/limited-time-deal-allextreme-exctcb1-t-type-car-tyre-cleaning-brush-scrubber-with-antislip-handl/6544f79d639daf9956909029' },
{ name: 'Limited Time Deal Desidiya 12 Stars 138 Led Curtain String Lights Window Curtain Lights With 8', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/limited-time-deal-desidiya-12-stars-138-led-curtain-string-lights-window-curtain-lights-with-8-/6544f6f8639dafc87d909024' },
{ name: 'Oneplus 80 Cm 32 Inches Y Series Hd Ready Led Smart Android Tv 32y1 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-80-cm-32-inches-y-series-hd-ready-led-smart-android-tv-32y1-black/6544f680639dafaf2f90901f' },
{ name: 'The Seeker Of Nothing A Fable On Owning Your Life', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-seeker-of-nothing-a-fable-on-owning-your-life/6544f606639dafe9ee90901a' },
{ name: 'Honeywell Air Touch V2 Indoor Air Purifier Pre Filter H13 Hepa Filter Activated Carbon Filter Removes 9999 Pollutants Micro Allergens 3 Stage Filtration Coverage Area Of 388 Sqft', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/honeywell-air-touch-v2-indoor-air-purifier-pre-filter-h13-hepa-filter-activated-carbon-filter-removes-9999-pollutants-micro-allergens-3-stage-filtration-coverage-area-of-388-sqft/6547a2bf5ba4f06890de9947' },
{ name: 'Deal Of The Day Amazon Basics Monitor Stand Height Adjustable Arm Mount Steel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deal-of-the-day-amazon-basics-monitor-stand-height-adjustable-arm-mount-steel/6545ea0ae2756274f9357d3d' },
{ name: 'Inalsa Ekon 60bk 1050 Mhr Pyramid Kitchen Chimney With Elegant Lookpush Button Controlefficient Dual Led Lamps Double Baffle Filter5 Year Warranty On Motor Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/inalsa-ekon-60bk-1050-mhr-pyramid-kitchen-chimney-with-elegant-lookpush-button-controlefficient-dual-led-lamps-double-baffle-filter5-year-warranty-on-motor-black/654509ad557c92c31d1aa56c' },
{ name: 'Boat Wave Elevate Smart Watch With 196 Display Bt Calling Functional Crown Ai Voice Assistant Built In Game Hr Spo2 Monitoring And Stress Monitoring Ip67royal Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-wave-elevate-smart-watch-with-196-display-bt-calling-functional-crown-ai-voice-assistant-built-in-game-hr-spo2-monitoring-and-stress-monitoring-ip67royal-orange/6544f5a3639daf1674909011' },
{ name: 'Kamiliant By American Tourister Harrier 56 Cms Small Cabin Polypropylene Pp Hard Sided 4 Wheeler Spinner Wheels Luggage Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kamiliant-by-american-tourister-harrier-56-cms-small-cabin-polypropylene-pp-hard-sided-4-wheeler-spinner-wheels-luggage-blue/654248455573ed903cb39668' },
{ name: 'Kids Tricycle With Eva Wheels Bell Storage Basketbaby Trikeage Group 2 Years To 5 Years Carrying Capacity Upto 30 Kgs Llktc04 Black Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kids-tricycle-with-eva-wheels-bell-storage-basketbaby-trikeage-group-2-years-to-5-years-carrying-capacity-upto-30-kgs-llktc04-black-blue/65426f1b5396dfb077d3ca6b' },
{ name: 'Redmi 12 5g Jade Black 8gb Ram 256gb Rom', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-12-5g-jade-black-8gb-ram-256gb-rom/6541ac42a7691f484280b9fd' },
{ name: 'Acer Aspire Lite 11th Gen Intel Core I5 Premium Metal Laptop 8gb Ram512gb Ssdintel Iris Xe Graphicswindows 11 Home Al15 51 3962cm 156 Full Hd Display Metal Body Steel Gray 159 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-aspire-lite-11th-gen-intel-core-i5-premium-metal-laptop-8gb-ram512gb-ssdintel-iris-xe-graphicswindows-11-home-al15-51-3962cm-156-full-hd-display-metal-body-steel-gray-159-kg/654246935573ed81c2b3965f' },
{ name: 'Fire Boltt Phoenix Pro 139 Bluetooth Calling Smartwatch Ai Voice Assistant Metal Body With 120 Sports Modes Spo2 Heart Rate Monitoring Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-phoenix-pro-139-bluetooth-calling-smartwatch-ai-voice-assistant-metal-body-with-120-sports-modes-spo2-heart-rate-monitoring-black/6541e0385444e41667e8d714' },
{ name: 'Bajaj Immersion Rod Water Heater 1500 Watts Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-immersion-rod-water-heater-1500-watts-silver/6541abe1a7691fb74480b9fa' },
{ name: 'Crompton Arno Neo 25 L 5 Star Rated Storage Water Heater With Advanced 3 Level Safety White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-arno-neo-25-l-5-star-rated-storage-water-heater-with-advanced-3-level-safety-white/6541ab8ea7691f705c80b9f7' },
{ name: 'Wildhorn Brown Leather Wallet For Men I 9 Card Slots I 2 Currency Secret Compartments I 1 Zipper 3 Id Card Slots', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wildhorn-brown-leather-wallet-for-men-i-9-card-slots-i-2-currency-secret-compartments-i-1-zipper-3-id-card-slots/6540ffaa575fe3b13e6705eb' },
{ name: 'Lexton 40 Feet Led Decorative String Light For Indoor Outdoor Decorations Warm White Pack Of 1 Standard Lex String40feetplastic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lexton-40-feet-led-decorative-string-light-for-indoor-outdoor-decorations-warm-white-pack-of-1-standard-lex-string40feetplastic/6540b9798ab53a3be49544e6' },
{ name: 'Kuber Industries Plastic 2 In 1 Vegetable Fruit Multi Cutter Peelerveg Cutter Sharp', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-plastic-2-in-1-vegetable-fruit-multi-cutter-peelerveg-cutter-sharp/6540b77c8ab53a51b79544d5' },
{ name: 'Tp Link Tapo C325wb 2k Qhd Outdoor Security Wi Fi Camera Ip66 Weatherproof Motion Detection 1179large Sensor4mp Color Pro Night Vision Cloudsd Card Storage Works With Alexagoogle Home', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tapo-c325wb-2k-qhd-outdoor-security-wi-fi-camera-ip66-weatherproof-motion-detection-1179large-sensor4mp-color-pro-night-vision-cloudsd-card-storage-works-with-alexagoogle-home/6540b1a68ab53acf4e9544b6' },
{ name: 'Yukany Polyester Printed Tree Eyelet Window Curtains 1 Tree 2 Plain 6 Feet Aqua Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yukany-polyester-printed-tree-eyelet-window-curtains-1-tree-2-plain-6-feet-aqua-blue/6540b3d08ab53a306d9544c1' },
{ name: 'Microfiber 150 Tc Bedsheet For Double Bed With 2 Pillow Covers Soft Touchmuted Teal Floral Size 220 Cm X 225 Cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/microfiber-150-tc-bedsheet-for-double-bed-with-2-pillow-covers-soft-touchmuted-teal-floral-size-220-cm-x-225-cm/6540af7b462fc98aa795744b' },
{ name: 'Clazkit Plastic Kitchen Dori Handy Vegetable And Fruit Manual Onion Dry Fruit Salad Maker Vegetable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/clazkit-plastic-kitchen-dori-handy-vegetable-and-fruit-manual-onion-dry-fruit-salad-maker-vegetable/6540b6ad8ab53a7d3d9544c6' },
{ name: 'Gear Voyager 15l Casual Backpackdaypackhiking Daypackdrawstring Bag For Menwomen Black Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gear-voyager-15l-casual-backpackdaypackhiking-daypackdrawstring-bag-for-menwomen-black-blue/6540ac60462fc98aa7957442' },
{ name: 'True Elements Chia Seeds 500gm Raw Chia Seeds Organic Chia Seeds For Eating Diet Snacks Seeds For Weight Loss High Protein Seeds', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/true-elements-chia-seeds-500gm-raw-chia-seeds-organic-chia-seeds-for-eating-diet-snacks-seeds-for-weight-loss-high-protein-seeds/653fcc5c66ed257938d1a08b' },
{ name: 'Reynolds Brite Bp 25 Ct Jar Blue Ball Point Pen Set With Comfortable Grip Pens For Writing School And Office Stationery Pens For Students 07 Mm Tip Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reynolds-brite-bp-25-ct-jar-blue-ball-point-pen-set-with-comfortable-grip-pens-for-writing-school-and-office-stationery-pens-for-students-07-mm-tip-size/653fcb9466ed257938d1a083' },
{ name: 'Attro Prime Stainless Steel Single Wall Lunch Box Airtight Leak Proof 4 Containers With Bag Fork Spoon For Office Use School Picnic 1100 Ml Dark Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/attro-prime-stainless-steel-single-wall-lunch-box-airtight-leak-proof-4-containers-with-bag-fork-spoon-for-office-use-school-picnic-1100-ml-dark-blue/653fcae066ed257938d1a07b' },
{ name: 'Anker Powertouch 5w Wireless Charger For Galaxy Note 5 S7s7 Edges6s6 Edges6 Edge Nexus 4567 Lg G3 And Other Devices Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anker-powertouch-5w-wireless-charger-for-galaxy-note-5-s7s7-edges6s6-edges6-edge-nexus-4567-lg-g3-and-other-devices-black/653fcb6666ed257938d1a080' },
{ name: 'Beatxp Marv Sense 196 Ultra Hd Large Display Bluetooth Calling Smart Watch Metal Body Rotary Crown 320 386px 500 Nits 60hz Refresh Rate 100 Sports Modes 24x7 Health Monitoring Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beatxp-marv-sense-196-ultra-hd-large-display-bluetooth-calling-smart-watch-metal-body-rotary-crown-320-386px-500-nits-60hz-refresh-rate-100-sports-modes-24x7-health-monitoring-silver/653fca9766ed257938d1a078' },
{ name: 'Portronics Mport 31 Usb Hub 4 In 1 Multiport Adapter With 1 X Usb 30 3 X Usb 20 Ports Up To 5 Gbps High Speed Data Transfer For Laptoppc Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-mport-31-usb-hub-4-in-1-multiport-adapter-with-1-x-usb-30-3-x-usb-20-ports-up-to-5-gbps-high-speed-data-transfer-for-laptoppc-grey/64d0655faaa462bbfe0f69c3' },
{ name: 'Latest Mens Boys Stainless Steel Watch For Kids Black Chain Belt Watches For Man New', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/latest-mens-boys-stainless-steel-watch-for-kids-black-chain-belt-watches-for-man-new/64b3f949aaa462bbfe0f69b8' },
{ name: 'Amazon Brand Symbol Men Raincoat', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-men-raincoat/64b3f8e8aaa462bbfe0f69b5' },
{ name: 'Amazon Prime Day Sale', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-prime-day-sale/64af69eaaaa462bbfe0f699f' },
{ name: 'Samsung Evo Plus 128gb Microsdxc Uhs I U3 130mbs Full Hd 4k Uhd Memory Card With Adapter Mb Mc128ka Visit The Samsung Store', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-128gb-microsdxc-uhs-i-u3-130mbs-full-hd-4k-uhd-memory-card-with-adapter-mb-mc128ka-visit-the-samsung-store/64af680aaaa462bbfe0f699c' },
{ name: 'Nexdigitron Ace Car Dash Camera With Gps Logger Full Hd 1080p F18 Aperture 6g Lens 140 Wide Angle Super Capacitor G Sensor Wifi Upto 128gb Supported Made In India', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nexdigitron-ace-car-dash-camera-with-gps-logger-full-hd-1080p-f18-aperture-6g-lens-140-wide-angle-super-capacitor-g-sensor-wifi-upto-128gb-supported-made-in-india/64af67c6aaa462bbfe0f6999' },
{ name: 'Bella Vita Luxury Ceo Man Eau De Parfum Perfume For Men With Lemon Lavender Tonka Agarwoodwoody Spicy Long Lasting Edp Fragrance Scent 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bella-vita-luxury-ceo-man-eau-de-parfum-perfume-for-men-with-lemon-lavender-tonka-agarwoodwoody-spicy-long-lasting-edp-fragrance-scent-100-ml/64acaf2caaa462bbfe0f6984' },
{ name: 'Qubo Car Dash Camera Pro Dash Cam From Hero Group Made In India Dashcam Full Hd 1080p Wide Angle View G Sensor Wifi Emergency Recording Upto 256gb Sd Card Supported', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qubo-car-dash-camera-pro-dash-cam-from-hero-group-made-in-india-dashcam-full-hd-1080p-wide-angle-view-g-sensor-wifi-emergency-recording-upto-256gb-sd-card-supported/64acaf89aaa462bbfe0f698d' },
{ name: 'The Home Style Cotton Feel Glace Cotton Elastic Fitted Printed King Size Double Bed Bedsheet With 2 Pillow Cover72x78pastel Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-home-style-cotton-feel-glace-cotton-elastic-fitted-printed-king-size-double-bed-bedsheet-with-2-pillow-cover72x78pastel-green/64acaecfaaa462bbfe0f6981' },
{ name: 'Samsung 322 L 2 Star Convertible 5in1 Digital Inverter Frost Free Double Door Refrigerator Rt37c4512bxhl Luxe Black 2023 Model', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-322-l-2-star-convertible-5in1-digital-inverter-frost-free-double-door-refrigerator-rt37c4512bxhl-luxe-black-2023-model/64acae86aaa462bbfe0f697e' },
{ name: 'Sony Wh 1000xm5 Wireless Industry Leading Active Noise Cancelling Headphones 8 Mics For Clear Calling 30hr Battery 3 Min Quick Charge 3 Hours Playback Multi Point Connectivity Alexa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sony-wh-1000xm5-wireless-industry-leading-active-noise-cancelling-headphones-8-mics-for-clear-calling-30hr-battery-3-min-quick-charge-3-hours-playback-multi-point-connectivity-alexa-black/64b3f8a5aaa462bbfe0f69b2' },
{ name: 'Amazon Brand Presto Oxo Biodegradable Garbage Bags Medium 19 X 21 Inches 30 Bagsroll Pack Of 6 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-oxo-biodegradable-garbage-bags-medium-19-x-21-inches-30-bagsroll-pack-of-6-black/64acae14aaa462bbfe0f697b' },
{ name: 'Tp Link Tapo 2mp 1080p Full Hd Home Security Wi Fi Smart Camera Alexa Enabled 2 Way Audio Night Vision Motion Detection Sound And Light Alarm Indoor Cctv Tapo C100 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tapo-2mp-1080p-full-hd-home-security-wi-fi-smart-camera-alexa-enabled-2-way-audio-night-vision-motion-detection-sound-and-light-alarm-indoor-cctv-tapo-c100-white/64acada7aaa462bbfe0f6972' },
{ name: 'Steelbird Gt Off Road Isi Certified Motocross Double Visor Full Face Helmet Outer Clear Visor And Inner Smoke Sun Shield', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/steelbird-gt-off-road-isi-certified-motocross-double-visor-full-face-helmet-outer-clear-visor-and-inner-smoke-sun-shield/64acad63aaa462bbfe0f696f' },
{ name: 'The Man Company Ocean Perfume For Men Premium Luxury Long Lasting Fragrance Spray Eau De Perfume 30ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-man-company-ocean-perfume-for-men-premium-luxury-long-lasting-fragrance-spray-eau-de-perfume-30ml/64a6eb9d435bbe5fdf7e4d5c' },
{ name: 'Kwality Corn Flakes Made With Golden Corns 99 Fat Free Natural Source Of Vitamin Iron And Protein 800g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kwality-corn-flakes-made-with-golden-corns-99-fat-free-natural-source-of-vitamin-iron-and-protein-800g/64a6ec26435bbe5fdf7e4d62' },
{ name: 'Pubg Regular Fit Men T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pubg-regular-fit-men-t-shirt/64a5a4f0435bbe5fdf7e4d51' },
{ name: 'Kanget Wireless 2 In 1 Lavalier Microphone Recording Phone Mic For Live Streamvideo Recordinginterview Facebook Liveyoutubeclip On Plug Play Auto Sync For All Iphones Android And Usb Devices', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kanget-wireless-2-in-1-lavalier-microphone-recording-phone-mic-for-live-streamvideo-recordinginterview-facebook-liveyoutubeclip-on-plug-play-auto-sync-for-all-iphones-android-and-usb-devices/64a6ed21435bbe5fdf7e4d68' },
{ name: 'Eightiz Mobile Stand Holder Metal Cell Phone Stand Perfect For Video Table Online Class Flexible Movie Office Desktop Foldable Lazy Clip Multi Angle Clamp For All Smartphones Online Studies', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eightiz-mobile-stand-holder-metal-cell-phone-stand-perfect-for-video-table-online-class-flexible-movie-office-desktop-foldable-lazy-clip-multi-angle-clamp-for-all-smartphones-online-studies/64a6ec9f435bbe5fdf7e4d65' },
{ name: 'Hammer Pulse X 183 Display Smart Watch With Bluetooth Calling Voice Assistant 60 Sports Modes Spo2 Heart Rate Tracking Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hammer-pulse-x-183-display-smart-watch-with-bluetooth-calling-voice-assistant-60-sports-modes-spo2-heart-rate-tracking-black/64a6ebef435bbe5fdf7e4d5f' },
{ name: 'Portronics Ruffpad 85e Re Writable Lcd Writing Pad With Screen 215cm 85 Inch For Drawing Playing Handwriting Gifts For Kids Adults Indias First Notepad To Save And Share Your Childs First Creatives Via Ruffpad App On Your Smartphoneblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-ruffpad-85e-re-writable-lcd-writing-pad-with-screen-215cm-85-inch-for-drawing-playing-handwriting-gifts-for-kids-adults-indias-first-notepad-to-save-and-share-your-childs-first-creatives-via-ruffpad-app-on-your-smartphoneblack/64a5a459750143dc5ea286af' },
{ name: 'Lapster Spiral Charger Spiral Charger Cable Protectors For Wires Data Cable Saver Charging Cord Protective Cable Cover Set Of 3 12 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lapster-spiral-charger-spiral-charger-cable-protectors-for-wires-data-cable-saver-charging-cord-protective-cable-cover-set-of-3-12-pieces/64a5a413750143a0d0a286ac' },
{ name: 'Athom Living Easy Home Designer Soft Anti Slip Bath Mat 38x58 Cm Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/athom-living-easy-home-designer-soft-anti-slip-bath-mat-38x58-cm-pack-of-2/64a5a3a07501430ae5a286a5' },
{ name: 'Philips Skinprotect Beard Trimmer Lasts 4x Longer Durapower Technology Cordless Rechargeable With Usb Charging Charging Indicator Travel Lock No Oil Needed Bt123218', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-skinprotect-beard-trimmer-lasts-4x-longer-durapower-technology-cordless-rechargeable-with-usb-charging-charging-indicator-travel-lock-no-oil-needed-bt123218/64a95d62aaa462bbfe0f6930' },
{ name: 'Asus Vivobook Flip 14 Thin And Light 2 In 1 Laptop 14 Fhd Touch Display Amd Ryzen 5 5500u 8gb Ram 512gb Ssd Stylus Fingerprint Reader Windows 10 Home Bespoke Black Tm420ua Ds52t', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asus-vivobook-flip-14-thin-and-light-2-in-1-laptop-14-fhd-touch-display-amd-ryzen-5-5500u-8gb-ram-512gb-ssd-stylus-fingerprint-reader-windows-10-home-bespoke-black-tm420ua-ds52t/64a944b5aaa462bbfe0f6924' },
{ name: 'Whirlpool 6 Kg 5 Star Royal Fully Automatic Top Loading Washing Machine Whitemagic Royal 6 Genx Grey Hard Water Wash Amazon Prime Day Offer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/whirlpool-6-kg-5-star-royal-fully-automatic-top-loading-washing-machine-whitemagic-royal-6-genx-grey-hard-water-wash-amazon-prime-day-offer/64a94400aaa462bbfe0f6919' },
{ name: 'Ajanta Royal Classic Set Of 16 Individual Photo Frames 3 4x64 5x74 6x82 6x103 8x10 Inch A 85', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ajanta-royal-classic-set-of-16-individual-photo-frames-3-4x64-5x74-6x82-6x103-8x10-inch-a-85/64a459c6435bbe5fdf7e4d14' },
{ name: 'Amazon Brand Myx Womens Loose Fit Palazzo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-myx-womens-loose-fit-palazzo/64a45b72435bbe5fdf7e4d1d' },
{ name: 'Ant Esports Gs170 Gaming Speaker For Pc Stereo 20 Usb Powered Desktop Speaker With 35 Mm Aux In In Line Volume Control Rgb Led Lights Mini Multimedia Speakers For Pc Laptop Tablet Cellphone', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-gs170-gaming-speaker-for-pc-stereo-20-usb-powered-desktop-speaker-with-35-mm-aux-in-in-line-volume-control-rgb-led-lights-mini-multimedia-speakers-for-pc-laptop-tablet-cellphone/64a45a36435bbe5fdf7e4d17' },
{ name: 'Unlisted By Kenneth Cole Autumn Winter 20 Analog Blue Dial Mens Watch 10032025', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/unlisted-by-kenneth-cole-autumn-winter-20-analog-blue-dial-mens-watch-10032025/64a45af6435bbe5fdf7e4d1a' },
{ name: 'Lakm 9to5 Primer Gloss Nail Colour Pink Paisley 6 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-9to5-primer-gloss-nail-colour-pink-paisley-6-ml/64a309bc4d676a528c23e77d' },
{ name: 'Miss Olive Women Dress', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/miss-olive-women-dress/64a3093c4d676a7d4a23e77a' },
{ name: 'Lenovo C24 20 238 6045 Cms Fhd Va 1920x1080 Monitor Vga And Hdmi Ports 75hz Refresh Rate Amd Freesync 62a8kar1ww Raven Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-c24-20-238-6045-cms-fhd-va-1920x1080-monitor-vga-and-hdmi-ports-75hz-refresh-rate-amd-freesync-62a8kar1ww-raven-black/64a309819be9ae52a34ed840' },
{ name: 'Oscar Forever Paris Long Lasting Perfume For Men Women Exhilarating Floral Fragrance Everyday Unisex Perfume 60ml Original Forever Paris Perfume', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oscar-forever-paris-long-lasting-perfume-for-men-women-exhilarating-floral-fragrance-everyday-unisex-perfume-60ml-original-forever-paris-perfume/64a308986c094e21d5e582f3' },
{ name: 'Multipurpose Clothes Metal Rack Entryway Hall Tree 3 Tier Shoe Storage Organizer Shelf With Hanger For Clothes Hat Scarf Umbrella Bag Hanging Coat Rack Size Brown 55 X 35 X 170cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/multipurpose-clothes-metal-rack-entryway-hall-tree-3-tier-shoe-storage-organizer-shelf-with-hanger-for-clothes-hat-scarf-umbrella-bag-hanging-coat-rack-size-brown-55-x-35-x-170cm/64a308ee6c094e093ae582fa' },
{ name: 'Redgear A 17 Gaming Mouse With Upto 6400 Dpi Rgb Lighting And Braided Cableblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redgear-a-17-gaming-mouse-with-upto-6400-dpi-rgb-lighting-and-braided-cableblack/64a3085a4d676a871423e777' },
{ name: 'Kelloggs Muesli 20 Nuts Delight Multigrain Breakfast Cereal High In Iron High In Fibre Naturally Cholesterol Free 1 Kg Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-muesli-20-nuts-delight-multigrain-breakfast-cereal-high-in-iron-high-in-fibre-naturally-cholesterol-free-1-kg-pack/62f142ca026528107b251f90' },
{ name: 'Fiama Gel Bar Celebration Pack With 5 Unique Gel Bars Skin Conditioners For Moisturized Skin 125g Soap Buy 4 Get 1 Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fiama-gel-bar-celebration-pack-with-5-unique-gel-bars-skin-conditioners-for-moisturized-skin-125g-soap-buy-4-get-1-free/63f6cca9f19c8d74a0a30a84' },
{ name: 'Boat Rockerz 255 Pro In Ear Bluetooth Neckband With Upto 40 Hours Playback Asap Charge Ipx7', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-255-pro-in-ear-bluetooth-neckband-with-upto-40-hours-playback-asap-charge-ipx7/63f578533e3b516edda414e1' },
{ name: 'Portronics Hydra 10 Mechanical Wireless Gaming Keyboard With Bluetooth 50 Wi Fi 24 Ghz Rgb Lights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-hydra-10-mechanical-wireless-gaming-keyboard-with-bluetooth-50-wi-fi-24-ghz-rgb-lights/63f577ff3e3b516edda414de' },
{ name: 'Limited Time Offer Boat Stone 1200f Black Raptor With Upto 9 Hours Battery Ipx7 And Rgb Leds 14 W Bluetooth', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/limited-time-offer-boat-stone-1200f-black-raptor-with-upto-9-hours-battery-ipx7-and-rgb-leds-14-w-bluetooth/63f77b27f19c8d74a0a30aad' },
{ name: 'Fastrack Reflex Beat 169 Ultravu Display500 Nits Brightness60 Sports Modes247 Heart Rate Monitorspo2 Monitorsleep Trackerip68 Water Resistantmusic Camera Control', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-reflex-beat-169-ultravu-display500-nits-brightness60-sports-modes247-heart-rate-monitorspo2-monitorsleep-trackerip68-water-resistantmusic-camera-control/640d8600f19c8d74a0a30d96' },
{ name: 'Zebronics Juke Bar 100a Compact Soundbar With Subwoofer 60w Rms Output Powerful Bass Glossy Design Hdmi Arc Coaxial Bluetooth 50 Aux Led Indicator And Remote Control', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-juke-bar-100a-compact-soundbar-with-subwoofer-60w-rms-output-powerful-bass-glossy-design-hdmi-arc-coaxial-bluetooth-50-aux-led-indicator-and-remote-control/640a9e62f19c8d74a0a30d81' },
{ name: '1080 P Mini Portable Mini Projector Color Led Lcd Video Multimedia Home Theater', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/1080-p-mini-portable-mini-projector-color-led-lcd-video-multimedia-home-theater/63f3a3deaa66ad65d67c559a' },
{ name: 'Engage Garden Mystique Deodorant For Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-garden-mystique-deodorant-for-women/63f4f4972ed43d6b1d5cfe29' },
{ name: 'Dipinsure Headvoice 1000 Wireless In Ear Earphones With Mic Neckband Bluetooth Headphones Deep Bass', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dipinsure-headvoice-1000-wireless-in-ear-earphones-with-mic-neckband-bluetooth-headphones-deep-bass/63f623aef19c8d74a0a30a69' },
{ name: 'Stylevillein Women Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stylevillein-women-sweatshirt/640a9e2ef19c8d74a0a30d7e' },
{ name: 'Pinkmint Women Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pinkmint-women-kurta/63fb73c0f19c8d74a0a30b57' },
{ name: 'Ptron Bassbuds Duo In Ear Earbuds With 32hrs Total Playtime Bluetooth 51 Wireless Stereo Audio Touch Control Tws', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-duo-in-ear-earbuds-with-32hrs-total-playtime-bluetooth-51-wireless-stereo-audio-touch-control-tws/63fe05e6f19c8d74a0a30bee' },
{ name: 'Game Of Thrones Mens T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/game-of-thrones-mens-t-shirt/647d32831be71912d2e12559' },
{ name: 'Wipro Garnet Base B22 7 Watt Led Bulb Pack Of 10 Warm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-base-b22-7-watt-led-bulb-pack-of-10-warm-white/63f248733065aa1105937b85' },
{ name: 'Saffola Honey Active Made With Sundarban Forest Honey 100 Pure Honey No Sugar Adulteration Natural Immunity Booster 2 X 350g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saffola-honey-active-made-with-sundarban-forest-honey-100-pure-honey-no-sugar-adulteration-natural-immunity-booster-2-x-350g/63f247d9fa46bd5ab390fcad' },
{ name: 'Renewed Ant Esports Gm320 Optical Gaming Mouse Rgb 8 Programmable Buttons 7200 Dpi Adjustable Comfortable Grip Ergonomic Wired Gaming Mice', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-ant-esports-gm320-optical-gaming-mouse-rgb-8-programmable-buttons-7200-dpi-adjustable-comfortable-grip-ergonomic-wired-gaming-mice/62f142cb026528107b251fac' },
{ name: 'Domo Mount Tabletop T23 Universal Mount I Stand For Tablet Pc I Mobile Phones Stand I Adjustable I Mobile Stand Holder I Ipad Stand I Multi Angle Portable Universal Stand For Tablet Mobiles', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/domo-mount-tabletop-t23-universal-mount-i-stand-for-tablet-pc-i-mobile-phones-stand-i-adjustable-i-mobile-stand-holder-i-ipad-stand-i-multi-angle-portable-universal-stand-for-tablet-mobiles/649fba36a4b88dea8cf7d6cd' },
{ name: 'Whirlpool 207 L 5 Star Inverter Direct Cool Single Door Refrigerator', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/whirlpool-207-l-5-star-inverter-direct-cool-single-door-refrigerator/63f2490ffa46bd25b990fcb2' },
{ name: 'Safari Pentagon Trolley Bags For Travel 55 Cm Cabin Suitcase', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/safari-pentagon-trolley-bags-for-travel-55-cm-cabin-suitcase/63fcb997f19c8d74a0a30b8d' },
{ name: 'Zebronics Zeb Thunder Bluetooth Wireless Over Ear Headphone Fm Msd 9 Hrs Playback With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-thunder-bluetooth-wireless-over-ear-headphone-fm-msd-9-hrs-playback-with-mic-black/63f24a55fa46bd597290fcbf' },
{ name: 'Boat Airdopes 131 With Upto 60 Hours And Asap Charge Bluetooth Headset Active Black True Wireless', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-airdopes-131-with-upto-60-hours-and-asap-charge-bluetooth-headset-active-black-true-wireless/63fb78e7f19c8d74a0a30b71' },
{ name: 'Dell Latitude E7240 12 Inch Laptop Core I5 4th Gen8gb256 Gb Ssd', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-latitude-e7240-12-inch-laptop-core-i5-4th-gen8gb256-gb-ssd/63fcb7bdf19c8d74a0a30b80' },
{ name: 'Nivia Storm Football Size 5 Pack Of 1 Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivia-storm-football-size-5-pack-of-1-multicolor/63fe070bf19c8d74a0a30bf1' },
{ name: 'Boat Blaze Smart Watch With 175 Hd Display Fast Charge Apollo 3 Blue Plus Processor 24x7 Heart Rate Spo2 Monitor Multiple Watch Faces Multiple Sports Modes 7 Days Battery Lifeactive Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-blaze-smart-watch-with-175-hd-display-fast-charge-apollo-3-blue-plus-processor-24x7-heart-rate-spo2-monitor-multiple-watch-faces-multiple-sports-modes-7-days-battery-lifeactive-black/640341f9f19c8d74a0a30d28' },
{ name: 'Acer 127 Cm 50 Inches I Series 4k Ultra Hd Android Smart Led Tv Ar50ar2851udfl Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-127-cm-50-inches-i-series-4k-ultra-hd-android-smart-led-tv-ar50ar2851udfl-black/6402c0ddf19c8d74a0a30cd9' },
{ name: 'Cuzor 12v Mini Ups For Wifi Router Power Backup Up To 4 Hours Replaceable Battery Ups For Wi Fi Router And Modem Ups For Router Up To 2a Ups For Uninterrupted Wi Fi Portable Ups Wifi Ups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cuzor-12v-mini-ups-for-wifi-router-power-backup-up-to-4-hours-replaceable-battery-ups-for-wi-fi-router-and-modem-ups-for-router-up-to-2a-ups-for-uninterrupted-wi-fi-portable-ups-wifi-ups/64981bd7435bbe5fdf7e4a08' },
{ name: 'Docoss Unisex Adult Warm Slippers For Indoor Women Slipper Unicorn', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/docoss-unisex-adult-warm-slippers-for-indoor-women-slipper-unicorn/64981917435bbe5fdf7e4a02' },
{ name: 'Miraan Readymade Crepe Short Kurti Tunic Top For Women Snekurtisc2725', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/miraan-readymade-crepe-short-kurti-tunic-top-for-women-snekurtisc2725/6498196f435bbe5fdf7e4a05' },
{ name: 'Gm 3031 05 Watt Xoom Led Night Lamp Orange And Whitepack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gm-3031-05-watt-xoom-led-night-lamp-orange-and-whitepack-of-1/6493ab0a1be71912d2e12682' },
{ name: 'Harry Potter And The Philosophers Stone Audiobook Audible', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/harry-potter-and-the-philosophers-stone-audiobook-audible/649268621be71912d2e1266b' },
{ name: 'Night Light Lamp Projector Star Light Rotating Projector Star Projector Lamp With Colors And 360 Degree Moon Star Projection With Usb Cablelamp For Kids Room', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/night-light-lamp-projector-star-light-rotating-projector-star-projector-lamp-with-colors-and-360-degree-moon-star-projection-with-usb-cablelamp-for-kids-room/6493ab4b1be71912d2e12685' },
{ name: 'Mens Flip Flop Slippers And House Slippers Comfortable Lightweight Flip Flop Slippers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mens-flip-flop-slippers-and-house-slippers-comfortable-lightweight-flip-flop-slippers/6493aaa11be71912d2e1267e' },
{ name: 'Luminous Eco Watt Neo 1050 Square Wave 900va12v Inverter For Home Office And Shops', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luminous-eco-watt-neo-1050-square-wave-900va12v-inverter-for-home-office-and-shops/649267ad1be71912d2e12668' },
{ name: 'Portable Power Station River 2 256wh Lifepo4 Battery 1 Hour Fast Charging 2 Ac Outlets Up To 600w For Outdoor Camping And Home Back Up', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portable-power-station-river-2-256wh-lifepo4-battery-1-hour-fast-charging-2-ac-outlets-up-to-600w-for-outdoor-camping-and-home-back-up/649266fc1be71912d2e1265c' },
{ name: 'Solar Panel Foldable 60w Portable Solar Charger For Suaokijackerywebetop Portable Generatorgoal Zero Yeti Power Stationusb Devices Qc30 Usb Portsincluding Aeiusny Generator Solar Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/solar-panel-foldable-60w-portable-solar-charger-for-suaokijackerywebetop-portable-generatorgoal-zero-yeti-power-stationusb-devices-qc30-usb-portsincluding-aeiusny-generator-solar-cable/649267701be71912d2e12665' },
{ name: 'Funday Fashion Womens Solid Ribbed Crop Tank Top For Womens Classic Purple Top', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/funday-fashion-womens-solid-ribbed-crop-tank-top-for-womens-classic-purple-top/649266451be71912d2e12656' },
{ name: 'Microfiber Bedsheet For Double Bed With 2 Pillow Covers Breathable Wrinklefree And Softtouch Football Print Sheet Grey And Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/microfiber-bedsheet-for-double-bed-with-2-pillow-covers-breathable-wrinklefree-and-softtouch-football-print-sheet-grey-and-blue/649265e01be71912d2e12653' },
{ name: 'Crazy Offers On Premium Dash Cams From Ddpai 70mai', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crazy-offers-on-premium-dash-cams-from-ddpai-70mai/648c8e9d1be71912d2e125f5' },
{ name: 'Bestsellers In Watches', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bestsellers-in-watches/648c8f581be71912d2e125fb' },
{ name: 'Save On Airish Bags Collection', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/save-on-airish-bags-collection/648c8eec1be71912d2e125f8' },
{ name: 'Ubon Cl 117 Bluetooth Wireless Earphones 50 With Hi Fi Stereo Sound 12hrs Playtime Lightweight Ergonomic Neckband Water Resistant Magnetic Earbuds Voice Assistant Mic Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ubon-cl-117-bluetooth-wireless-earphones-50-with-hi-fi-stereo-sound-12hrs-playtime-lightweight-ergonomic-neckband-water-resistant-magnetic-earbuds-voice-assistant-mic-orange/648c8de11be71912d2e125ef' },
{ name: 'Meqstore Stainless Steel Sauce Pan Flat Base Sauce Pan Tea Pan Milk Pan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/meqstore-stainless-steel-sauce-pan-flat-base-sauce-pan-tea-pan-milk-pan-/648da8cf1be71912d2e12649' },
{ name: 'Tygot 10 Inches Big Led Ring Light For Camera Phone Tiktok Youtube Video Shooting And Makeup 10 Inch Ring Light With 7 Feet Long Foldable And Lightweight Tripod Stand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tygot-10-inches-big-led-ring-light-for-camera-phone-tiktok-youtube-video-shooting-and-makeup-10-inch-ring-light-with-7-feet-long-foldable-and-lightweight-tripod-stand/648c8d9f1be71912d2e125ec' },
{ name: 'Super Hot Deals On Philips Audio', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/super-hot-deals-on-philips-audio/648c8e4d1be71912d2e125f2' },
{ name: 'Oneplus Nord Ce 3 Lite 5g Chromatic Gray 8gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-nord-ce-3-lite-5g-chromatic-gray-8gb-ram-128gb-storage/648c89db1be71912d2e125b1' },
{ name: 'Yash Gallery Womens Printed A Line Kurta For Women Ikat Kurtis', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yash-gallery-womens-printed-a-line-kurta-for-women-ikat-kurtis/648c8a3b1be71912d2e125b4' },
{ name: 'Tripod Stand Holder For Mobile Phones Camera 360 Mm 1060 Mm 42 Inch 14 Inch Screw Mobile Holder Bracket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tripod-stand-holder-for-mobile-phones-camera-360-mm-1060-mm-42-inch-14-inch-screw-mobile-holder-bracket/648c8d691be71912d2e125e9' },
{ name: 'Skateboard Casterboard Cruiserboard For Beginners Learners And Training Purpose For All Age Group Girls Boys Kids Teens', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skateboard-casterboard-cruiserboard-for-beginners-learners-and-training-purpose-for-all-age-group-girls-boys-kids-teens/648c898e1be71912d2e125ae' },
{ name: 'Nutripro Juicer Mixer Grinder Smoothie Maker 500 Watts 3 Jar Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutripro-juicer-mixer-grinder-smoothie-maker-500-watts-3-jar-silver/648c89051be71912d2e125ab' },
{ name: 'Night Light Lamp Projector Star Light Rotating Projector Star Projector Lamp With Colors And 360 Degree Moon Star Projection With Usb Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/night-light-lamp-projector-star-light-rotating-projector-star-projector-lamp-with-colors-and-360-degree-moon-star-projection-with-usb-cable/648c87951be71912d2e125a4' },
{ name: 'Amazon Brand Solimo Nylon Backpack 23 Ltr Greynavy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-nylon-backpack-23-ltr-greynavy/64868e971be71912d2e12595' },
{ name: 'Elko Ab Roller For Men Women Abs Workout Equipment For Home Gym Abdominal Exercise For Fitness Strengthen And Tone Your Core Anti Slip Anti Skid Wheel Roller With Knee Mat Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/elko-ab-roller-for-men-women-abs-workout-equipment-for-home-gym-abdominal-exercise-for-fitness-strengthen-and-tone-your-core-anti-slip-anti-skid-wheel-roller-with-knee-mat-black/648c88a61be71912d2e125a8' },
{ name: 'Envy Absolute Deodorant Body Spray 120ml Long Lasting Deo For Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/envy-absolute-deodorant-body-spray-120ml-long-lasting-deo-for-men/64868e2f1be71912d2e12592' },
{ name: 'Omeo Pure And Natural Arnica Jaborandi Hair Oil 100 Herbal Extract Reduces Hair Fall Promotes Hair Growth And Strong Hair For Male And Female', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/omeo-pure-and-natural-arnica-jaborandi-hair-oil-100-herbal-extract-reduces-hair-fall-promotes-hair-growth-and-strong-hair-for-male-and-female/64868dda1be71912d2e1258f' },
{ name: 'Govo Gosurround 410 80 Watt 20 Channel With Hdmi And Optical Wireless Bluetooth Soundbar Rms Output 40 W', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/govo-gosurround-410-80-watt-20-channel-with-hdmi-and-optical-wireless-bluetooth-soundbar-rms-output-40-w/647d34271be71912d2e12571' },
{ name: 'Wipro Garnet 20 Watt Led Batten Pack Of 2 Warm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-20-watt-led-batten-pack-of-2-warm-white/64868c581be71912d2e12580' },
{ name: 'Dettol Foaming Handwash Pump Aloe Coconut Pack Of 2 250ml Each Rich Foam Moisturizing Hand Wash', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-foaming-handwash-pump-aloe-coconut-pack-of-2-250ml-each-rich-foam-moisturizing-hand-wash/64868d661be71912d2e1258c' },
{ name: 'Boat Stone 650 10w Bluetooth Speaker With Upto 7 Hours Playback', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-stone-650-10w-bluetooth-speaker-with-upto-7-hours-playback/647d33d91be71912d2e1256e' },
{ name: 'Longies Womens Cotton Blend Boxer Regular Fit Casual Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/longies-womens-cotton-blend-boxer-regular-fit-casual-shorts/647d333b1be71912d2e12562' },
{ name: 'The Power Of A Positive Attitude Your Road To Success', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-power-of-a-positive-attitude-your-road-to-success-/640d872af19c8d74a0a30db2' },
{ name: 'Fusefit Womens Polyester Core Sports Bra', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fusefit-womens-polyester-core-sports-bra/647d33811be71912d2e12565' },
{ name: 'Tu Casa Hg 35 220watts Pendant Light Black Diamond', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tu-casa-hg-35-220watts-pendant-light-black-diamond/640d86d9f19c8d74a0a30da5' },
{ name: 'Heart Home Heat Resistant Cotton Kitchen Oven Mitt Microwave Glove Set Non Slip Grip And Hanging Loop For Kitchen Cooking Baking Set Of 2 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/heart-home-heat-resistant-cotton-kitchen-oven-mitt-microwave-glove-set-non-slip-grip-and-hanging-loop-for-kitchen-cooking-baking-set-of-2-blue/640d86a7f19c8d74a0a30da2' },
{ name: 'Urban Magic 104 Tc Cotton Rajasthani Jaipuri Printed Flat Sheets Home Dcor And Bedding Single Size Bedsheet With 1 Pillow Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urban-magic-104-tc-cotton-rajasthani-jaipuri-printed-flat-sheets-home-dcor-and-bedding-single-size-bedsheet-with-1-pillow-cover/640d8645f19c8d74a0a30d9f' },
{ name: 'Automatic Fill And Tie Magic Water Balloons For Holi Multicolour Pack Of 6 222 Balloons', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/automatic-fill-and-tie-magic-water-balloons-for-holi-multicolour-pack-of-6-222-balloons/640017c7f19c8d74a0a30cb8' },
{ name: ' Globe For Kids Stem Steam Educational World Globe With Magnifying Glass For Kidspolitical Globeoffice Globeglobes For Students 8 Inchblue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/-globe-for-kids-stem-steam-educational-world-globe-with-magnifying-glass-for-kidspolitical-globeoffice-globeglobes-for-students-8-inchblue/6400181df19c8d74a0a30cc0' },
{ name: 'Holi T Shirt Hh3 For Men And Women Printed Holi Hai Round Neck Matte Tshirts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/holi-t-shirt-hh3-for-men-and-women-printed-holi-hai-round-neck-matte-tshirts/64001785f19c8d74a0a30caf' },
{ name: 'Wolpin Foldable Bookshelf Open Bookcase Space Saving Storage Solution Home Book Shelf Organizer Rack For Study Room Office Library Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wolpin-foldable-bookshelf-open-bookcase-space-saving-storage-solution-home-book-shelf-organizer-rack-for-study-room-office-library-grey/640a9cfbf19c8d74a0a30d50' },
{ name: 'Status Contract Abstract Runner Grey Nylon 22 X 55', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-contract-abstract-runner-grey-nylon-22-x-55/64001748f19c8d74a0a30cac' },
{ name: 'Limited Time Offer Boat Stone 1200f Sunburn Edition With Upto 9 Hours Playback And Rgb Leds 14 W Bluetooth Speaker Blue Stereo Channel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/limited-time-offer-boat-stone-1200f-sunburn-edition-with-upto-9-hours-playback-and-rgb-leds-14-w-bluetooth-speaker-blue-stereo-channel/63fe0899f19c8d74a0a30c12' },
{ name: 'Jockey Women Cotton Hipster Briefpack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jockey-women-cotton-hipster-briefpack-of-3/63ff6109f19c8d74a0a30c8e' },
{ name: 'Boult Audio Bassbuds X1 In Ear Wired Earphones With 10mm Extra Bass Driver And Hd Sound With Micblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boult-audio-bassbuds-x1-in-ear-wired-earphones-with-10mm-extra-bass-driver-and-hd-sound-with-micblack/640016f5f19c8d74a0a30ca9' },
{ name: 'Led Motion 029 Sensor Night Light Usb Rechargeable Stickanywhere Light Wall Lamp Night Lamp 5 Cm White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/led-motion-029-sensor-night-light-usb-rechargeable-stickanywhere-light-wall-lamp-night-lamp-5-cm-white/63fe079ff19c8d74a0a30bf4' },
{ name: 'Bigmuscles Nutrition Crude Whey Unflavoured Protein Powder 1kg Us Fda Regd Brand 24g Protein 55g Bcaa 4 G Glutamine', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bigmuscles-nutrition-crude-whey-unflavoured-protein-powder-1kg-us-fda-regd-brand-24g-protein-55g-bcaa-4-g-glutamine/63fcba48f19c8d74a0a30b90' },
{ name: 'Kuber Industries Saree Covers With Zipsaree Covers For Storagesaree Packing Covers For Weddingpack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-saree-covers-with-zipsaree-covers-for-storagesaree-packing-covers-for-weddingpack-of-3-/63fcbaa2f19c8d74a0a30b93' },
{ name: 'Cockatoo Economy Badminton Set Pack Of Two Racquet With 3 Shuttle Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-economy-badminton-set-pack-of-two-racquet-with-3-shuttle-cover/63f8fdabf19c8d74a0a30b2b' },
{ name: 'Geonix Pc Monitor 47 Cm185 Inch Vga Hdmi Led Display 1366 X 768 Resolution', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/geonix-pc-monitor-47-cm185-inch-vga-hdmi-led-display-1366-x-768-resolution/63f8fd36f19c8d74a0a30b28' },
{ name: 'Ptron Pride Lite Hbe High Bass Earphones In Ear Wired Earphones With Mic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-pride-lite-hbe-high-bass-earphones-in-ear-wired-earphones-with-mic/63f82625f19c8d74a0a30ae7' },
{ name: 'Makeup Revolution Conceal And Define Cream Concealer With Matte Finish C2 Beige 34ml Matte Finish', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-conceal-and-define-cream-concealer-with-matte-finish-c2-beige-34ml-matte-finish/63f77ce2f19c8d74a0a30abb' },
{ name: 'Adjustable Laptop Tabletop Stand Patented Riser Ventilated Portable Foldable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/adjustable-laptop-tabletop-stand-patented-riser-ventilated-portable-foldable/63f77beff19c8d74a0a30ab8' },
{ name: 'Elv Aluminium Adjustable And Foldable Dock Mobile Holder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/elv-aluminium-adjustable-and-foldable-dock-mobile-holder/63f77f0ff19c8d74a0a30ac6' },
{ name: 'Redmi A1 Light Blue 2gb Ram 32gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-a1-light-blue-2gb-ram-32gb-storage/63f6cdd5f19c8d74a0a30a91' },
{ name: 'Nerf Super Soaker Scatter Blast Water Blaster 5 Streams Of Water Pump Action Kids Outdoor Water Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nerf-super-soaker-scatter-blast-water-blaster-5-streams-of-water-pump-action-kids-outdoor-water-toy/63f6cd8af19c8d74a0a30a8e' },
{ name: 'Funblast Holi Pichkari High Pressure Water Gun Toy For Kids With Tank Water Pichkari Water Gun Pichkari For Kids Holi Gun Tank Capacity 1 L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/funblast-holi-pichkari-high-pressure-water-gun-toy-for-kids-with-tank-water-pichkari-water-gun-pichkari-for-kids-holi-gun-tank-capacity-1-l-/63f6cd29f19c8d74a0a30a8b' },
{ name: 'Trmcalidad Jai Hind Edition Ultra Premium Synthetic Color Chain Lube', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/trmcalidad-jai-hind-edition-ultra-premium-synthetic-color-chain-lube/63f577643e3b516edda414d1' },
{ name: 'Sesa Ayurvedic Hair Fall Control Shampoo In 15 Days Bhringraj 16 Herbs No Parabens 500 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sesa-ayurvedic-hair-fall-control-shampoo-in-15-days-bhringraj-16-herbs-no-parabens-500-ml/63f576c73e3b516edda414ce' },
{ name: 'Lg 15 Ton 3 Star Ai Dual Inverter Split Ac Copper Super Convertible 6 In 1 Cooling Hd Filter With Anti Virus Protection', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-15-ton-3-star-ai-dual-inverter-split-ac-copper-super-convertible-6-in-1-cooling-hd-filter-with-anti-virus-protection/63f574f13e3b516edda414c0' },
{ name: 'Pigeon By Stovekraft Inox Hydra Plus Stainless Steel Drinking Water Bottle 700 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pigeon-by-stovekraft-inox-hydra-plus-stainless-steel-drinking-water-bottle-700-ml/63f4fa76b4e1bf6cd8be2e03' },
{ name: 'Acer 80 Cm 32 Inches I Series Hd Ready Android Smart Led Tv', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acer-80-cm-32-inches-i-series-hd-ready-android-smart-led-tv/63f574403e3b516edda414bd' },
{ name: 'Kitchen Scale Multipurpose Portable Electronic Digital Weighing Scale Weight Machine With Back Light Lcd Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kitchen-scale-multipurpose-portable-electronic-digital-weighing-scale-weight-machine-with-back-light-lcd-display/63f4f4fb2ed43d6b1d5cfe2f' },
{ name: 'Mosquito Net For Double Bed Queen Size Strong 30gsm Net High Durability', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mosquito-net-for-double-bed-queen-size-strong-30gsm-net-high-durability/63f4f4c62ed43d6b1d5cfe2c' },
{ name: 'Sancom Womens Crepe Regular Kurta', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sancom-womens-crepe-regular-kurta/63f4f4642ed43d6b1d5cfe26' },
{ name: 'Magic Slate Digital Lcd Writing Tablet With Stylus Pen 85 Inch Screen Slate Board For E Notes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/magic-slate-digital-lcd-writing-tablet-with-stylus-pen-85-inch-screen-slate-board-for-e-notes/63f4f5312ed43d6b1d5cfe32' },
{ name: 'Fire Tv Stick Lite With All New Alexa Voice Remote Lite', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-tv-stick-lite-with-all-new-alexa-voice-remote-lite-/63f3a303aa66ad65d67c5597' },
{ name: 'Evereve Tampons For Regular Menstrual Flow 20 Pcs Super Absorption Safe Soft Comfortable Stain Free Periods Rash Free Prevents Odour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evereve-tampons-for-regular-menstrual-flow-20-pcs-super-absorption-safe-soft-comfortable-stain-free-periods-rash-free-prevents-odour/63f4f4002ed43d6b1d5cfe23' },
{ name: 'Vivo Y33s Midday Dream 8gb Ram 128gb Stoarge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivo-y33s-midday-dream-8gb-ram-128gb-stoarge/63f3a2a6aa66ad65d67c5594' },
{ name: 'Samsung Evo Plus 64gb Microsdxc Uhs I U1 130mbs Full Hd 4k Uhd Memory Card With Adapter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-64gb-microsdxc-uhs-i-u1-130mbs-full-hd-4k-uhd-memory-card-with-adapter/63f3a20daa66ad65d67c5591' },
{ name: 'Classic Xl Bean Bag Cover Without Beans Blue And Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/classic-xl-bean-bag-cover-without-beans-blue-and-pink/63f3a0dc69776c7ead5d7242' },
{ name: 'Ant Value Fkbri03 Auto Stand By Silent Keys 8 Hot Keys Keyboard Mouse Combo Wireless', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-value-fkbri03-auto-stand-by-silent-keys-8-hot-keys-keyboard-mouse-combo-wireless/63f39ffa69776ca0235d723a' },
{ name: 'Maple Engineered Wood Coffee Table Wenge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maple-engineered-wood-coffee-table-wenge/63f3a07b69776c19e95d723d' },
{ name: 'Nirlon Stainless Steel Single Wall Water Bottle With Flip Cap Fridge Refrigerator Bottle 100 Leak Proof', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nirlon-stainless-steel-single-wall-water-bottle-with-flip-cap-fridge-refrigerator-bottle-100-leak-proof/63f3a02a0258daf4fb605249' },
{ name: 'Aoc 22b2hm Monitor Va Panel Monitor Borderless Experience Ultra Slim With Hdmi Cable Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aoc-22b2hm-monitor-va-panel-monitor-borderless-experience-ultra-slim-with-hdmi-cable-black/63f39eb769776c32585d7228' },
{ name: 'Bank Offer Digitek Gocam Dac 101 5k 30fps 48mp Wifi Ultra Hd Sports Action Camera', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bank-offer-digitek-gocam-dac-101-5k-30fps-48mp-wifi-ultra-hd-sports-action-camera/63f39f0f69776c73fb5d722b' },
{ name: ' Fox Nuts Phool Makhana 100g Healthy Snack Low Calorie Gluten Free And Vegan', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/-fox-nuts-phool-makhana-100g-healthy-snack-low-calorie-gluten-free-and-vegan/63f39fb469776c5b5b5d7235' },
{ name: 'Long Tripod Stand With Adjustable Mobile Clip Holder For All Mobiles', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/long-tripod-stand-with-adjustable-mobile-clip-holder-for-all-mobiles/63f39f5c69776c3e0d5d7230' },
{ name: 'Nelle Harper Mens Leather Bifold Wallet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nelle-harper-mens-leather-bifold-wallet/63f39e630258dadc6760523e' },
{ name: 'American Tourister Polypropylene Hard 78 Cms Luggage Suitcase', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/american-tourister-polypropylene-hard-78-cms-luggage-suitcase/63f39e2a69776cfe045d7225' },
{ name: 'Tosaa Popular Nonstick Cookware 8 Pcs Gift Set Amber Includes 1 Flat Tawa 1 Kadhai 1 Frying Pan 1 Sauce Pan 1 Tadka Pan 1 Stainless Steel Lid 2 Spatula', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tosaa-popular-nonstick-cookware-8-pcs-gift-set-amber-includes-1-flat-tawa-1-kadhai-1-frying-pan-1-sauce-pan-1-tadka-pan-1-stainless-steel-lid-2-spatula/63f249f8fa46bd743190fcb8' },
{ name: 'Zinq Technologies Usb Type C To Usb Type A 20 Cable For Smartphone Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zinq-technologies-usb-type-c-to-usb-type-a-20-cable-for-smartphone-silver/63f249a0fa46bd7a4390fcb5' },
{ name: 'Milton Vitro Plastic Pet Storage Jar And Container Set Of 18 6 Pcs X 230 Ml Each 6 Pcs X 600 Ml Each 6 Pcs X 118 Ltrs Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-vitro-plastic-pet-storage-jar-and-container-set-of-18-6-pcs-x-230-ml-each-6-pcs-x-600-ml-each-6-pcs-x-118-ltrs-each/62fa7e26026528107b2522a6' },
{ name: 'Ruggers By Unlimited Mens Slim Fit Polo Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ruggers-by-unlimited-mens-slim-fit-polo-shirt/63f249463065aa39a2937b8b' },
{ name: 'Saffola Soya Chunks With Supersoft Technology 53 Protien Tender Juicy 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saffola-soya-chunks-with-supersoft-technology-53-protien-tender-juicy-1kg/63f2470ffa46bd8ef090fcaa' },
{ name: 'Renewed Oneplus 6 Mirror Black 6gb Ram 64gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-oneplus-6-mirror-black-6gb-ram-64gb-storage/62fa7dd1026528107b2522a3' },
{ name: 'Echo Dot 3rd Gen Certified Refurbished Black Improved Smart Speaker With Alexa With 1 Year Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/echo-dot-3rd-gen-certified-refurbished-black-improved-smart-speaker-with-alexa-with-1-year-warranty/62fa7d18026528107b2522a0' },
{ name: 'Lenovo Flex 5 14 140 Laptop 140 Fhd 1920 X 1080 Touch Display Amd Ryzen 5 5500u Processor 16gb Ddr4 Ram 256gb Nvme Ssd Storage Amd Radeon Graphics Digital Pen Win 10h 82hu0036us Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lenovo-flex-5-14-140-laptop-140-fhd-1920-x-1080-touch-display-amd-ryzen-5-5500u-processor-16gb-ddr4-ram-256gb-nvme-ssd-storage-amd-radeon-graphics-digital-pen-win-10h-82hu0036us-grey/62fa7f01026528107b2522a9' },
{ name: 'Rgb Video Led Camera Light 360 Full Color', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rgb-video-led-camera-light-360-full-color/63f248b23065aa5ebd937b88' },
{ name: 'Ganesh Fruit Vegetable Steel Handle Juicer Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ganesh-fruit-vegetable-steel-handle-juicer-blue/62f8d35c026528107b25228c' },
{ name: 'Buy Youbella Jewellery Bohemian Multi Color Earrings For Girls And Women At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-youbella-jewellery-bohemian-multi-color-earrings-for-girls-and-women-at-amazonin/61388f903f48af1bd045d23f' },
{ name: 'Buy Amazon Brand Solimo Plastic 500 Ml Large Vegetable Chopper With 3 Blades Blue Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-solimo-plastic-500-ml-large-vegetable-chopper-with-3-blades-blue-online-at-low-prices-in-india-amazonin/61adb91782cbfab1f0ee97f7' },
{ name: 'Adidas Mens Drogo M Running Shoe Prime Deal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/adidas-mens-drogo-m-running-shoe-prime-deal-/6158710045435f8ccbd6a8aa' },
{ name: 'E Tech I Book 41 Power Strip With Safety Shutter And International Socket Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/e-tech-i-book-41-power-strip-with-safety-shutter-and-international-socket-amazonin-home-improvement/61d45ca2d2306ac51c03c92e' },
{ name: 'Noise Zest 3w Wireless Bluetooth Speaker 8 Hrs Playtime With Tws Pairing For Stereo Sound Portable Speaker With Dual Equalizer Bass Normal Modes Coal Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-zest-3w-wireless-bluetooth-speaker-8-hrs-playtime-with-tws-pairing-for-stereo-sound-portable-speaker-with-dual-equalizer-bass-normal-modes-coal-black/62efc4233f493407576ccd45' },
{ name: 'Ambrane 3a Fast Charging Output Cable With Type C To Usb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-3a-fast-charging-output-cable-with-type-c-to-usb/62f0757894e59d0abf09944c' },
{ name: 'Flix Beetel Thunder Lite 1000 Bluetooth Wireless In Ear Earphones With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flix-beetel-thunder-lite-1000-bluetooth-wireless-in-ear-earphones-with-mic-black/62f142c6026528107b251f2a' },
{ name: 'Size M Aurelia Womens Cape Gilet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-aurelia-womens-cape-gilet/62f0757b94e59d0abf099479' },
{ name: 'Lembar Portable Electric Usb Juice Maker Juicer Bottle Blender Grinder Mixer Rechargeable Bottle With 6 Blades', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lembar-portable-electric-usb-juice-maker-juicer-bottle-blender-grinder-mixer-rechargeable-bottle-with-6-blades-/62f76952026528107b2521b9' },
{ name: 'Ambrane 3a Fast Charging Output Cable With Type C To Usb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-3a-fast-charging-output-cable-with-type-c-to-usb/62f0757c94e59d0abf099491' },
{ name: 'Zebronics Zeb Thunder Wireless Bluetooth Over The Ear Headphone Fm Msd 9 Hrs Playback With Mic Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-thunder-wireless-bluetooth-over-the-ear-headphone-fm-msd-9-hrs-playback-with-mic-red/62f142cd026528107b251fde' },
{ name: 'Dancing Toy Talking Game Cactus Toy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dancing-toy-talking-game-cactus-toy/62f319d9026528107b25207c' },
{ name: 'Fire Boltt Beast Spo2 169 Industrys Largest Display Size Full Touch Smart Watch With Blood Oxygen Monitoring Heart Rate Monitor Multiple Watch Faces Long Battery Life', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-beast-spo2-169-industrys-largest-display-size-full-touch-smart-watch-with-blood-oxygen-monitoring-heart-rate-monitor-multiple-watch-faces-long-battery-life/62f3cabb026528107b2520db' },
{ name: 'Size S Bronz Womens Plain Slim Fit Shirt 280732862lt Greenssl Small', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-bronz-womens-plain-slim-fit-shirt-280732862lt-greenssl-small/62f0757b94e59d0abf099484' },
{ name: 'Ld Saffola Oodles Instant Noodles Ring Shape Yummy Masala Flavour No Maida Whole Grain Oats Snackpack Offer Bowl And Spork 14 X 53g Pouch 14 Serves', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-saffola-oodles-instant-noodles-ring-shape-yummy-masala-flavour-no-maida-whole-grain-oats-snackpack-offer-bowl-and-spork-14-x-53g-pouch-14-serves/62f142c5026528107b251f13' },
{ name: 'Butterfly Jet Elite Mixer Grinder 750w 4 Jars Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/butterfly-jet-elite-mixer-grinder-750w-4-jars-grey/62f142c6026528107b251f27' },
{ name: 'Renewed Sennheiser Cx 120bt Wireless Bluetooth In Ear Headphone With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-sennheiser-cx-120bt-wireless-bluetooth-in-ear-headphone-with-mic-black/62f142ca026528107b251f9f' },
{ name: 'Sbi Credit Card Bajaj Herculo 1000w Powerful Mixer Grinder With Nutri Pro Feature 4 Jars Coffee Brown And Gold', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-bajaj-herculo-1000w-powerful-mixer-grinder-with-nutri-pro-feature-4-jars-coffee-brown-and-gold/62f142cb026528107b251fbd' },
{ name: 'Stayfree Secure Xl Ultra Thin Dry Cover Sanitary Pads For Women With Wings 40 Pads', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stayfree-secure-xl-ultra-thin-dry-cover-sanitary-pads-for-women-with-wings-40-pads/62f142c8026528107b251f64' },
{ name: 'Amazonbasics 500 Watt Mixer Grinder With 3 Jarswhite And Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonbasics-500-watt-mixer-grinder-with-3-jarswhite-and-blue/62f142c6026528107b251f35' },
{ name: 'Renewed Hp Chromebook Mediatek Mt8183 116 Inches Thin And Light Touchscreen Laptop 4gb64gb Emmcchrome Os107kgindigo Blue 11a Na0002mu', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-hp-chromebook-mediatek-mt8183-116-inches-thin-and-light-touchscreen-laptop-4gb64gb-emmcchrome-os107kgindigo-blue-11a-na0002mu/62f142cb026528107b251fb0' },
{ name: 'Samsung Galaxy S20 Fe 5g Cloud Navy 8gb Ram 128gb Storage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-s20-fe-5g-cloud-navy-8gb-ram-128gb-storage-/62f287c5026528107b25206b' },
{ name: 'Storite 6 Slots Rfid Blocking Metal Credit Card Holder Wallet For Men', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/storite-6-slots-rfid-blocking-metal-credit-card-holder-wallet-for-men/62f1c90c026528107b252043' },
{ name: 'Renewed Zebronics Falcon Gaming Headphone With Mic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-zebronics-falcon-gaming-headphone-with-mic/62f3cab9026528107b25209a' },
{ name: 'About Space Magnetic Photo Frame Pack Of 5 Fridge Picture Frames Personalized Refrigerator Magnet Frame Holds 8x7 Inch Photos', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/about-space-magnetic-photo-frame-pack-of-5-fridge-picture-frames-personalized-refrigerator-magnet-frame-holds-8x7-inch-photos/62f3cab8026528107b252096' },
{ name: 'Veronika Decides To Die Like The Flowing River Thoughts And Reflections Set Of 2 Books', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/veronika-decides-to-die-like-the-flowing-river-thoughts-and-reflections-set-of-2-books/62f3cab9026528107b2520a0' },
{ name: 'Daniel Klein Analog Grey Dial Womens Watch Dk112512 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/daniel-klein-analog-grey-dial-womens-watch-dk112512-6/62f3cab9026528107b2520a4' },
{ name: 'Wk Design Wk023blkm Wdc 023 1m Micro Usb Cable', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wk-design-wk023blkm-wdc-023-1m-micro-usb-cable/62f46a32026528107b252162' },
{ name: 'Himalaya Herbals Babycare Gift Jar Soap Shampoo Rash Cream And Powder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/himalaya-herbals-babycare-gift-jar-soap-shampoo-rash-cream-and-powder/62f46a32026528107b252164' },
{ name: 'Sykit Dustproof Uv Protection Full Wheeler Body Cover For Hero Passion Pro Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sykit-dustproof-uv-protection-full-wheeler-body-cover-for-hero-passion-pro-silver/62f46a32026528107b25216a' },
{ name: 'Fitbox Sports Pro Pvc 20 Kg Weight Plates 3kg X 4 2kg X 4 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fitbox-sports-pro-pvc-20-kg-weight-plates-3kg-x-4-2kg-x-4-black/62f46a32026528107b25216e' },
{ name: 'Natural Herbal Body Wash Lavender And Ylang Ylang 420 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/natural-herbal-body-wash-lavender-and-ylang-ylang-420-ml/62f46a32026528107b252168' },
{ name: 'Te A Me Orange Ginger Infusion Tea 25 Tea Bags 100 Caffeine Free Fruit Infusion Tea', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/te-a-me-orange-ginger-infusion-tea-25-tea-bags-100-caffeine-free-fruit-infusion-tea/62f46a32026528107b252170' },
{ name: 'Kuber Industries Plastic Rectangular Refrigerator Drawer Mat Set Multicolour 6 Pieces', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-plastic-rectangular-refrigerator-drawer-mat-set-multicolour-6-pieces/62f46a32026528107b25216c' },
{ name: 'Renewed Logitech K480 Multi Device Bluetooth Keyboard Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-logitech-k480-multi-device-bluetooth-keyboard-black/62f46a31026528107b25215a' },
{ name: 'Strauss Adjustable Yoga Mat Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strauss-adjustable-yoga-mat-strap/62f46a31026528107b25215c' },
{ name: 'Industrial Safety Nylon Anti Cut Resistant Hand Gloves Grey 1 Pair', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/industrial-safety-nylon-anti-cut-resistant-hand-gloves-grey-1-pair/62f46a31026528107b252158' },
{ name: 'Amazon Brand Solimo Solimo Tomato Sheet Mask For Face', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-solimo-tomato-sheet-mask-for-face/62f46a31026528107b252156' },
{ name: 'Bajaj Led Batten 20w Cool Day Light Pack Of 4white', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-led-batten-20w-cool-day-light-pack-of-4white/62f46a32026528107b252160' },
{ name: '4 Pcs Magic Practice Copybook For Kids Handwriting English Reusable Magical Practice Copy Books For Kids Tracing Book Letter Writing Book', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/4-pcs-magic-practice-copybook-for-kids-handwriting-english-reusable-magical-practice-copy-books-for-kids-tracing-book-letter-writing-book/62f3cabd026528107b252104' },
{ name: 'Uniqchoice Rajasthani Tradition 144 Tc Cotton Double Bedsheet With 2 Pillow Covers Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/uniqchoice-rajasthani-tradition-144-tc-cotton-double-bedsheet-with-2-pillow-covers-red/62f3cabd026528107b25210a' },
{ name: 'Omortex Elegant 4 In 1 Stylish Multipurpose Dry Fruit Box Candy Box Achar Box Traditional Box 1 Piece Spice Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/omortex-elegant-4-in-1-stylish-multipurpose-dry-fruit-box-candy-box-achar-box-traditional-box-1-piece-spice-set/62f3cab8026528107b252088' },
{ name: 'Cosmic Byte Dwarf Control Type Gaming Mousepad 320mm X 270mm X 4mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cosmic-byte-dwarf-control-type-gaming-mousepad-320mm-x-270mm-x-4mm/62f46a31026528107b25215e' },
{ name: 'Ld Pigeon By Stovekraft Aluminium Outer Lid Pressure Cooker Combo 2 Litre 3 Litre And 5 Litre Induction Base 12685 Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-pigeon-by-stovekraft-aluminium-outer-lid-pressure-cooker-combo-2-litre-3-litre-and-5-litre-induction-base-12685-silver/62f142c6026528107b251f3b' },
{ name: 'Crompton Rapid Jet 3 L Instant Water Heater With Advanced 4 Level Safety White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crompton-rapid-jet-3-l-instant-water-heater-with-advanced-4-level-safety-white/62f3cabd026528107b252110' },
{ name: 'Noise Buds Vs104 Bluetooth Truly Wireless In Ear Earbuds With Mic 30 Hours Of Playtime Instacharge 13mm Driver And Hyper Sync Charcoal Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/noise-buds-vs104-bluetooth-truly-wireless-in-ear-earbuds-with-mic-30-hours-of-playtime-instacharge-13mm-driver-and-hyper-sync-charcoal-black/62f3cabd026528107b252101' },
{ name: 'Art And Craft Step By Step Activity Book Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/art-and-craft-step-by-step-activity-book-set/62f3cabd026528107b252108' },
{ name: 'Amazon Brand Solimo Stainless Steel Water Bottle Set Of 3 1 L Each Blitz', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-stainless-steel-water-bottle-set-of-3-1-l-each-blitz/62f3cabd026528107b25210c' },
{ name: 'Amazon Brand Solimo Plastic Fridge Bottle Set 6 Pieces 1l Checkered Pattern Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-plastic-fridge-bottle-set-6-pieces-1l-checkered-pattern-multicolour/62f3cabc026528107b2520fc' },
{ name: 'Amazon Brand Presto Dish Wash Gel 2 L Lemon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-dish-wash-gel-2-l-lemon/62f3cabc026528107b2520ec' },
{ name: 'Cello Sportigo Plastic Bottle Set Set Of 4 Assorted', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-sportigo-plastic-bottle-set-set-of-4-assorted/62f3cabc026528107b2520fa' },
{ name: 'Raisco R716f Nylon Badminton Net Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/raisco-r716f-nylon-badminton-net-blue/62f3cabb026528107b2520ca' },
{ name: 'Pantene Advanced Hairfall Solution With Bamboo Shampoo 650ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantene-advanced-hairfall-solution-with-bamboo-shampoo-650ml/62f3cabb026528107b2520d6' },
{ name: 'Oswaal Ncert Cbse Question Bank Class 6 Mathematics Book Oswaal Ncert Cbse Question Bank Class 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oswaal-ncert-cbse-question-bank-class-6-mathematics-book-oswaal-ncert-cbse-question-bank-class-6-/62f3cabb026528107b2520cc' },
{ name: 'Lifree Extra Absorb Adult Diaper Pants Unisex Extra Large Size 10 Pieces Waist Size 90 125 Cm 35 49 Inches New Version', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifree-extra-absorb-adult-diaper-pants-unisex-extra-large-size-10-pieces-waist-size-90-125-cm-35-49-inches-new-version/62f3cabb026528107b2520d0' },
{ name: 'Boat Rockerz 450 Masaba Edition Upto 15 Hours Playback 40mm Drivers Padded Ear Cushions And Dual Modes Bluetooth Wireless On Ear Headphones With Mic And Aztec Fusion', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-450-masaba-edition-upto-15-hours-playback-40mm-drivers-padded-ear-cushions-and-dual-modes-bluetooth-wireless-on-ear-headphones-with-mic-and-aztec-fusion/62f3cabc026528107b2520e3' },
{ name: 'Tresemme Keratin Smooth Shampoo 580 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tresemme-keratin-smooth-shampoo-580-ml/62f3cabb026528107b2520d3' },
{ name: 'Yardley London Gentleman Classic Activated Charcoal Soap With 999 Germ Protection And Deep Cleansing Of Skin Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yardley-london-gentleman-classic-activated-charcoal-soap-with-999-germ-protection-and-deep-cleansing-of-skin-pack-of-4/62f3cabb026528107b2520ce' },
{ name: 'New Jbl Tune 130nc Tws Active Noise Cancellation Earbuds Upto 40db Jbl App Adjust Eq For Extra Bass Massive 40hrs Playtime Legendary Jbl Sound 4mics For Clear Calls Bt 52 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/new-jbl-tune-130nc-tws-active-noise-cancellation-earbuds-upto-40db-jbl-app-adjust-eq-for-extra-bass-massive-40hrs-playtime-legendary-jbl-sound-4mics-for-clear-calls-bt-52-black/62f3cabc026528107b2520f0' },
{ name: 'Purifit Pure And Safe Instant Hand Sanitizer Liquid Spray Orange Extracts With Glycerine For Home And Office Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/purifit-pure-and-safe-instant-hand-sanitizer-liquid-spray-orange-extracts-with-glycerine-for-home-and-office-orange/62f3cabc026528107b2520e5' },
{ name: 'Acpl Precious Moments Bis Hallmarked Ganesha Laxmi Saraswati Silver Coin 999 Pure', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/acpl-precious-moments-bis-hallmarked-ganesha-laxmi-saraswati-silver-coin-999-pure/62f3cabc026528107b2520e7' },
{ name: 'Storite Uport 5 Meter Cat 6 Ethernet Lan Cable High Speed Gigabit Internet Network Rj45 Patch Cable Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/storite-uport-5-meter-cat-6-ethernet-lan-cable-high-speed-gigabit-internet-network-rj45-patch-cable-grey/62f3caba026528107b2520c5' },
{ name: 'Renewed Fire Boltt Supreme 179 Inches Borderless Ltps 368448 Uhd Pro Display With 96 Screen To Body Ratio 3atm Waterproof Spo2 Heart Rate And Blood Pressure Smart Watch Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-fire-boltt-supreme-179-inches-borderless-ltps-368448-uhd-pro-display-with-96-screen-to-body-ratio-3atm-waterproof-spo2-heart-rate-and-blood-pressure-smart-watch-black/62f3caba026528107b2520af' },
{ name: 'Renewed Nokia 150 2020 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-nokia-150-2020-red/62f3caba026528107b2520ba' },
{ name: 'Colorbar Matte Touch Lipstick Wild Mauve 42g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/colorbar-matte-touch-lipstick-wild-mauve-42g/62f3caba026528107b2520b4' },
{ name: 'Logitech X300 Mobile Wireless Speaker Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/logitech-x300-mobile-wireless-speaker-red/62f3caba026528107b2520be' },
{ name: 'Cello Opalware Dazzle Scarlet Bliss Dinner Set 18pcs White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-scarlet-bliss-dinner-set-18pcs-white/62f3caba026528107b2520c3' },
{ name: 'Crown Craft Stainless Steel Airtight Tiffin Box Bowl With Tuff Lid Set Of 2 Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/crown-craft-stainless-steel-airtight-tiffin-box-bowl-with-tuff-lid-set-of-2-grey/62f3cab9026528107b2520ab' },
{ name: 'Size S Clovia Womens Satin Halter Neck Babydoll', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-clovia-womens-satin-halter-neck-babydoll/62f3caba026528107b2520c1' },
{ name: 'Engage Floral Zest Deodorant For Women Citrus And Floral Skin Friendly 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/engage-floral-zest-deodorant-for-women-citrus-and-floral-skin-friendly-150ml/62f3cab9026528107b2520a8' },
{ name: 'For Sbi Credit Card Iqoo Neo 6 5g Dark Nova 8gb Ram 128gb Storage Snapdragon 870 5g 80w Flashcharge', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-iqoo-neo-6-5g-dark-nova-8gb-ram-128gb-storage-snapdragon-870-5g-80w-flashcharge/62f3cab9026528107b2520a2' },
{ name: 'Oriental Botanics Australian Tea Tree Anti Acne Foaming Face Wash 120 Ml Infused Withtea Tree Gives Clear Skin Fights Acne Cruelty Free Vegan Paraben Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oriental-botanics-australian-tea-tree-anti-acne-foaming-face-wash-120-ml-infused-withtea-tree-gives-clear-skin-fights-acne-cruelty-free-vegan-paraben-free/62f3cab9026528107b25209c' },
{ name: 'Cello Opalware Dazzle Lush Fiesta Dinner Set 13pcs White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-lush-fiesta-dinner-set-13pcs-white/62f3cab9026528107b25209e' },
{ name: 'Ofixo Pack Of 50 Sheets 10 Color50 Sheets A4 Color Paper For Art And Craftprinting Purpose Multi Color Paper Plain A4 Craft Paper Set Of 1 Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ofixo-pack-of-50-sheets-10-color50-sheets-a4-color-paper-for-art-and-craftprinting-purpose-multi-color-paper-plain-a4-craft-paper-set-of-1-multicolor/62f3cab8026528107b252098' },
{ name: 'Kuber Industries Dot Printed Multiuses Non Woven Underbedstorage Bagorganizer With Transparent Window Handle Pack Of 2 Pink 46km0591', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-dot-printed-multiuses-non-woven-underbedstorage-bagorganizer-with-transparent-window-handle-pack-of-2-pink-46km0591/62f3cab8026528107b25208c' },
{ name: 'Memory Foam Bath Mat Coral Velvet Super Non Slip Rapid Water Absorption Soft And Comfortable Easier To Dry Machine Wash Bathroom Mat 17 X 24 Inches Gray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/memory-foam-bath-mat-coral-velvet-super-non-slip-rapid-water-absorption-soft-and-comfortable-easier-to-dry-machine-wash-bathroom-mat-17-x-24-inches-gray/62f3cab8026528107b252092' },
{ name: 'Cetc Women Check Pants Jegging Style Formalscasual Stretchable With 2 Pockets Pack Of 1 Free Size Waist Size 26 34', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cetc-women-check-pants-jegging-style-formalscasual-stretchable-with-2-pockets-pack-of-1-free-size-waist-size-26-34/62f3cab8026528107b252094' },
{ name: 'Tri Activ Disinfectant Spray', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tri-activ-disinfectant-spray/62f3cab8026528107b252090' },
{ name: 'Fun Homes Multipurpose Dot Printed Cotton Hanging Storage Mesh Bagorganizer For Home Bathroom Kitchen Travel Use With Zipper Hanging Loop Red 49fh0423', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fun-homes-multipurpose-dot-printed-cotton-hanging-storage-mesh-bagorganizer-for-home-bathroom-kitchen-travel-use-with-zipper-hanging-loop-red-49fh0423/62f3cab8026528107b25208e' },
{ name: 'Bentley Momentum Intense Edp 60ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bentley-momentum-intense-edp-60ml/62f3cab8026528107b25208a' },
{ name: 'Lg 215 L 4 Star Inverter Direct Cool Single Door Refrigerator Gl B221ascy Scarlet Charm Moist N Fresh', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lg-215-l-4-star-inverter-direct-cool-single-door-refrigerator-gl-b221ascy-scarlet-charm-moist-n-fresh/62f3cab8026528107b252086' },
{ name: 'Park Daniel Jaborandi Herbal Hair Oil For Anti Hair Fall And Strong Shiny Hair Combo Pack 2 Bottle Of 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/park-daniel-jaborandi-herbal-hair-oil-for-anti-hair-fall-and-strong-shiny-hair-combo-pack-2-bottle-of-100-ml/62f2764f026528107b252061' },
{ name: 'Faber 60 Cm 1000 Mhr Pyramid Kitchen Chimney Hood Class Pro Pb Bk Ltw 60 Baffle Filtersblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faber-60-cm-1000-mhr-pyramid-kitchen-chimney-hood-class-pro-pb-bk-ltw-60-baffle-filtersblack/62f142c7026528107b251f52' },
{ name: 'Realme Buds Wireless 2 Neo Bluetooth In Ear Earphones With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-buds-wireless-2-neo-bluetooth-in-ear-earphones-with-mic-black/62f142c5026528107b251f1e' },
{ name: 'Jabra Elite 4 Active In Ear Bluetooth Earbuds True Wireless Ear Buds With Secure Active Fit 4 Built In Microphones Active Noise Cancellation And Adjustable Hearthrough Technology Light Mint', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jabra-elite-4-active-in-ear-bluetooth-earbuds-true-wireless-ear-buds-with-secure-active-fit-4-built-in-microphones-active-noise-cancellation-and-adjustable-hearthrough-technology-light-mint/62f142c6026528107b251f32' },
{ name: 'Renewed Lenovo Tab M10 Fhd Plus Tablet 103 Inch 4gb 128gb Wi Fi Lte Volte Calling Platinum Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-lenovo-tab-m10-fhd-plus-tablet-103-inch-4gb-128gb-wi-fi-lte-volte-calling-platinum-grey/62f142c6026528107b251f3d' },
{ name: 'Wonder Plastic Prime Pedal Light Dustbin Set For Homekitchenoffice 2 Pcs Dustbin 6 Ltr Green Color Made In India', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonder-plastic-prime-pedal-light-dustbin-set-for-homekitchenoffice-2-pcs-dustbin-6-ltr-green-color-made-in-india/62f142c7026528107b251f4c' },
{ name: 'Milton Pacific 1000 Pet Water Bottles Set Of 6 1 Litre Each Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-pacific-1000-pet-water-bottles-set-of-6-1-litre-each-blue/62f142cc026528107b251fd6' },
{ name: 'Saregama Carvaan Hindi Portable Music Player With 5000 Preloaded Songs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saregama-carvaan-hindi-portable-music-player-with-5000-preloaded-songs/62f1ca77026528107b252057' },
{ name: 'Portronics Phonic 15w Portable Wireless Bluetooth Speaker With Tws Built In Mic Aux In Slot Tf Card Slot 7 8 Hrs Playtimeblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-phonic-15w-portable-wireless-bluetooth-speaker-with-tws-built-in-mic-aux-in-slot-tf-card-slot-7-8-hrs-playtimeblack/62f142cc026528107b251fd2' },
{ name: 'Sbi Credit Card Hp 15s 12th Gen Intel Core I5 8gb Ram512gb Ssd 156inch396cm Fhd Micro Edge Displayintel Iris Xe Graphicsdual Speakerswin 11alexa Built Inbacklit Kbmso 2021169kg 15s Fq5010tu', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-hp-15s-12th-gen-intel-core-i5-8gb-ram512gb-ssd-156inch396cm-fhd-micro-edge-displayintel-iris-xe-graphicsdual-speakerswin-11alexa-built-inbacklit-kbmso-2021169kg-15s-fq5010tu/62f142c9026528107b251f77' },
{ name: 'Mi 4a Dualband Gigabit Upto 1200mbps Speed Router 24ghz 5ghz Frequency 128mb Ram Dualcore 4 Thread Cpu4 Omni Directional Antennami Wi Fi App Parental Control Anti Hackingrepeater', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mi-4a-dualband-gigabit-upto-1200mbps-speed-router-24ghz-5ghz-frequency-128mb-ram-dualcore-4-thread-cpu4-omni-directional-antennami-wi-fi-app-parental-control-anti-hackingrepeater/62f142cc026528107b251fc3' },
{ name: 'Boldfit Adjustable Hand Grip Strengthener Hand Gripper For Men Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boldfit-adjustable-hand-grip-strengthener-hand-gripper-for-men-women/62f142cc026528107b251fc5' },
{ name: 'Cello Hot Serve Plastic Casserole Set 3 Pieces Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-hot-serve-plastic-casserole-set-3-pieces-blue/62f142cc026528107b251fd8' },
{ name: 'Renewed Lenovo 110 Stereo Usb A Headset Audio And Voice Optimized For Learn Work From Home Passive Noise Cancellation', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-lenovo-110-stereo-usb-a-headset-audio-and-voice-optimized-for-learn-work-from-home-passive-noise-cancellation/62f142cb026528107b251fb3' },
{ name: 'Smart Care Blood Glucose Monitor 3217', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/smart-care-blood-glucose-monitor-3217/62f142cb026528107b251fb7' },
{ name: 'Renewed Mi Watch Revolve Premium Metallic Frame 139 Amoled Display 14 Days Battery Heart Rate Stress And Sleep Monitoring 110 Watch Faces In Built Gps Midnight Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-mi-watch-revolve-premium-metallic-frame-139-amoled-display-14-days-battery-heart-rate-stress-and-sleep-monitoring-110-watch-faces-in-built-gps-midnight-black/62f142cb026528107b251fb5' },
{ name: 'Renewed Logitech Wireless Mk270r Keyboard And Mouse Set', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-logitech-wireless-mk270r-keyboard-and-mouse-set/62f142ca026528107b251fa1' },
{ name: 'Bombay Shaving Company Shea Butter Moisturizing Bath Soap With Coconut Husk And Honey For Soft Skin 100g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bombay-shaving-company-shea-butter-moisturizing-bath-soap-with-coconut-husk-and-honey-for-soft-skin-100g/62f142ca026528107b251f92' },
{ name: 'Ptron Tangent Urban With 60hrs Playtime Enc Wireless Bluetooth 53 Headphones Low Latency Gaming Punchy Bass In Ear Wireless Earphones With Mic Type C Charging Ipx4 Voice Assistance Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-tangent-urban-with-60hrs-playtime-enc-wireless-bluetooth-53-headphones-low-latency-gaming-punchy-bass-in-ear-wireless-earphones-with-mic-type-c-charging-ipx4-voice-assistance-black/62f142ca026528107b251f99' },
{ name: 'Ld Spigen Urban Fit Cover Case Designed For Samsung Galaxy Buds 2 2021 Galaxy Buds Pro 2021 Galaxy Buds Live 2020 Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-spigen-urban-fit-cover-case-designed-for-samsung-galaxy-buds-2-2021-galaxy-buds-pro-2021-galaxy-buds-live-2020-black/62f142ca026528107b251f94' },
{ name: 'Zebronics Zeb Ht106 6 In 1 Grooming Kit With Cordlesscord Use Trimmer Styling Tools 90mins Backup Fast Charge Ipx6 2 Speed Modes Rounded Tip Blade 4 Guide Combs Washable Add Ons And Absblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-ht106-6-in-1-grooming-kit-with-cordlesscord-use-trimmer-styling-tools-90mins-backup-fast-charge-ipx6-2-speed-modes-rounded-tip-blade-4-guide-combs-washable-add-ons-and-absblack/62f142ca026528107b251faa' },
{ name: 'For Sbi Credit Card Amazonbasics 127 Cm 50 Inches 4k Ultra Hd Smart Led Fire Tv With Dolby Atmos And Dolby Vision Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-amazonbasics-127-cm-50-inches-4k-ultra-hd-smart-led-fire-tv-with-dolby-atmos-and-dolby-vision-black/62f142ca026528107b251fa8' },
{ name: 'Parle Nutricrunch Classic Digestive Cookies 1000g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parle-nutricrunch-classic-digestive-cookies-1000g/62f142c9026528107b251f89' },
{ name: 'For Sbi Credit Card Emi Joyalukkas 24 Kt 999 2 Gram Goddess Lakshmi Gold Pendant For Girls', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-emi-joyalukkas-24-kt-999-2-gram-goddess-lakshmi-gold-pendant-for-girls/62f142ca026528107b251fa3' },
{ name: 'Stanley Sdh600kp In Diy 13 Mm Hammer Drill Machine And Hand Tool Kit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stanley-sdh600kp-in-diy-13-mm-hammer-drill-machine-and-hand-tool-kit/62f142c9026528107b251f79' },
{ name: 'Tresemme Pro Protect Sulphate Free Shampoo 580ml Sulphate Free Conditioner 190ml With Morrocan Argan Oil No Sulphates Or Parabens Gentle Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tresemme-pro-protect-sulphate-free-shampoo-580ml-sulphate-free-conditioner-190ml-with-morrocan-argan-oil-no-sulphates-or-parabens-gentle-care/62f142c9026528107b251f7b' },
{ name: 'Ld Ant Esports Mp280s Speed Gaming Mouse Pad L Large With Stitched Edges Waterproof Non Slip Base For Gaming Office Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-ant-esports-mp280s-speed-gaming-mouse-pad-l-large-with-stitched-edges-waterproof-non-slip-base-for-gaming-office-black/62f142c9026528107b251f8b' },
{ name: 'Larah By Borosil Rose Red Silk Series Opalware Dinner Set 19 Pieces White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/larah-by-borosil-rose-red-silk-series-opalware-dinner-set-19-pieces-white/62f142c9026528107b251f8d' },
{ name: 'Ld Foxsky 76 Kg Semi Automatic Top Loading Washing Machine Foxsky Aqua Wash 76 Kg Maroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-foxsky-76-kg-semi-automatic-top-loading-washing-machine-foxsky-aqua-wash-76-kg-maroon/62f142c8026528107b251f5c' },
{ name: 'Milton Dobby Multipurpose Storage Large Basket With Lid 1 Pc 357 X 306 X 21 Cm 155 Ltrs Brown For Kitchen Toys Accessories Clothes Vegetables Cosmetics Books Office', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-dobby-multipurpose-storage-large-basket-with-lid-1-pc-357-x-306-x-21-cm-155-ltrs-brown-for-kitchen-toys-accessories-clothes-vegetables-cosmetics-books-office/62f142c8026528107b251f62' },
{ name: 'Bajaj Zero Grey Hair Oil Enriched With Onion Helps Fight Greying Of Hair Naturally 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-zero-grey-hair-oil-enriched-with-onion-helps-fight-greying-of-hair-naturally-100ml/62f142c8026528107b251f6a' },
{ name: 'Pears Body Wash 250 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pears-body-wash-250-ml/62f142c8026528107b251f60' },
{ name: 'Creative Pebble Plus 21 Usb Powered Desktop Speakers With Powerful Down Firing Subwoofer And Far Field Drivers 8w Rms With 16w Peak Power', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/creative-pebble-plus-21-usb-powered-desktop-speakers-with-powerful-down-firing-subwoofer-and-far-field-drivers-8w-rms-with-16w-peak-power/62f142c8026528107b251f66' },
{ name: 'Tresemme Keratin Smooth Shampoo 1l Conditioner 335ml With Keratin And Argan Oil For Salon Smooth Hair At Home Used By Professionals', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tresemme-keratin-smooth-shampoo-1l-conditioner-335ml-with-keratin-and-argan-oil-for-salon-smooth-hair-at-home-used-by-professionals/62f142c8026528107b251f68' },
{ name: 'Dishtv Hd Only Set Top Box Dth Connection With 1 Month Sampurna Bangla Pack Installation', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dishtv-hd-only-set-top-box-dth-connection-with-1-month-sampurna-bangla-pack-installation/62f142c8026528107b251f6d' },
{ name: 'Echo Dot 3rd Gen 1 Smart Speaker Brand In India With Alexa Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/echo-dot-3rd-gen-1-smart-speaker-brand-in-india-with-alexa-black/62f142c6026528107b251f30' },
{ name: 'Prestige Orion Mixer Grinder 500 W 3 Jars1200ml1000ml500ml Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prestige-orion-mixer-grinder-500-w-3-jars1200ml1000ml500ml-red/62f142c6026528107b251f38' },
{ name: 'Ld Milton Pebble 530 Thermosteel 24 Hours Hot And Cold Water Bottle 530 Ml Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-milton-pebble-530-thermosteel-24-hours-hot-and-cold-water-bottle-530-ml-grey/62f142c6026528107b251f3f' },
{ name: 'Zebronics Zeb V19hd 185 Inch 4699 Cm Led Monitor With Supporting Hdmi Vga Input Hd 1366 X 768 Pixels 167m Colors Glossy Panel Slim Design Wall Mountable Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-v19hd-185-inch-4699-cm-led-monitor-with-supporting-hdmi-vga-input-hd-1366-x-768-pixels-167m-colors-glossy-panel-slim-design-wall-mountable-black/62f142c5026528107b251f0e' },
{ name: 'Samsung Galaxy Buds Pro 99 Noise Cancellation Wireless Charging 18 Hours Playtime Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-buds-pro-99-noise-cancellation-wireless-charging-18-hours-playtime-black/62f142c5026528107b251f25' },
{ name: 'Size 10kids Liberty Unisex Child Lpmxt 833 Clog', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-10kids-liberty-unisex-child-lpmxt-833-clog/62f142c6026528107b251f2d' },
{ name: 'Dell Slim Soundbar Sb521a', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dell-slim-soundbar-sb521a/62f142c5026528107b251f10' },
{ name: 'Newly Launched Fire Boltt Ring 3 Bluetooth Calling Smartwatch 18 Biggest Display 118 Sports Modes Voice Assistance Spo2 Heart Rate Monitoring In Built Calculator Games Rose Gold Free Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/newly-launched-fire-boltt-ring-3-bluetooth-calling-smartwatch-18-biggest-display-118-sports-modes-voice-assistance-spo2-heart-rate-monitoring-in-built-calculator-games-rose-gold-free-size/62f142c5026528107b251f15' },
{ name: 'Savlon Germ Protection Wipes 72s Pack Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savlon-germ-protection-wipes-72s-pack-pack-of-2/62f142c5026528107b251f17' },
{ name: 'Sbi Credit Card Godrej 185 L 4 Star Inverter Direct Cool Single Door Refrigerator Rd Uno 1854 Pti Zn Bl Zen Blue Cool Lock Technology', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-godrej-185-l-4-star-inverter-direct-cool-single-door-refrigerator-rd-uno-1854-pti-zn-bl-zen-blue-cool-lock-technology/62f142c7026528107b251f43' },
{ name: 'Ld Vlcc Turmeric Berberis Face Wash Anti Tan Skin Lightening Face Wash Buy 1 Get 1 Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-vlcc-turmeric-berberis-face-wash-anti-tan-skin-lightening-face-wash-buy-1-get-1-free/62f142c7026528107b251f4e' },
{ name: 'Ld Candy 15 Ton 3 Star Dual Dc Inverter Split Ac Copper Self Clean 2022 Model C18f13inv White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-candy-15-ton-3-star-dual-dc-inverter-split-ac-copper-self-clean-2022-model-c18f13inv-white/62f142c7026528107b251f54' },
{ name: 'Ant Esports Gm100 Rgb Optical Wired Gaming Mouse 4800 Dpi', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-gm100-rgb-optical-wired-gaming-mouse-4800-dpi/62f142c4026528107b251f00' },
{ name: 'Wens Love You Silent Non Ticking Battery Operated Wall Clock 29 Cm X 29 Cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wens-love-you-silent-non-ticking-battery-operated-wall-clock-29-cm-x-29-cm/62f142c4026528107b251f0a' },
{ name: 'Sbi Credit Card Lg Ultragear 32gn650 Qhd 32 Inch80 Cm 2560 X 1440 Pixels 165 Hz 1ms Nvidia G Sync Compatible Freesync Premium Hdr 10 Hdmi Tilt Height Pivot Stand Va Panel Gaming Lcd Monitor Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-lg-ultragear-32gn650-qhd-32-inch80-cm-2560-x-1440-pixels-165-hz-1ms-nvidia-g-sync-compatible-freesync-premium-hdr-10-hdmi-tilt-height-pivot-stand-va-panel-gaming-lcd-monitor-black/62f142c4026528107b251f04' },
{ name: 'Renewed Jbl Quantum 100 By Harman Wired Over Ear Headphone With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-jbl-quantum-100-by-harman-wired-over-ear-headphone-with-mic-black/62f142c4026528107b251f0c' },
{ name: 'Fire Boltt Ninja Calling 169429cm Full Touch Bluetooth Calling Smartwatch With 30 Sports Mode Spo2 Heart Rate Monitoring Ai Voice Assistant Rose Gold Free Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fire-boltt-ninja-calling-169429cm-full-touch-bluetooth-calling-smartwatch-with-30-sports-mode-spo2-heart-rate-monitoring-ai-voice-assistant-rose-gold-free-size/62f142c4026528107b251f02' },
{ name: 'Dettol Foaming Handwash Refill Combo Strawberry Aloe Coconut Pack Of 2 700ml Each Rich Foam Moisturizing Hand Wash Soft On Hands', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-foaming-handwash-refill-combo-strawberry-aloe-coconut-pack-of-2-700ml-each-rich-foam-moisturizing-hand-wash-soft-on-hands/62f142c4026528107b251f08' },
{ name: 'Renewed Sony Wi C100 Wireless Headphones With 25 Hrs Battery Quick Charge Dsee Upscale Splash Proof Ipx4 360ra Clear Bass Fast Pair In Ear Bluetooth Headset With Mic', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-sony-wi-c100-wireless-headphones-with-25-hrs-battery-quick-charge-dsee-upscale-splash-proof-ipx4-360ra-clear-bass-fast-pair-in-ear-bluetooth-headset-with-mic/62f142c4026528107b251f06' },
{ name: 'Status Nylon Digital Printed Kids Door Runner Mats House Carpet For Playing Activity In Home Kids Mat Strawberry 57x 57cm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/status-nylon-digital-printed-kids-door-runner-mats-house-carpet-for-playing-activity-in-home-kids-mat-strawberry-57x-57cm/62f142c4026528107b251efa' },
{ name: 'Kingsway Sticky Sun Shade', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kingsway-sticky-sun-shade/62f142c3026528107b251ef4' },
{ name: 'Pantene Advanced Hairfall Solution 2in1 Anti Hairfall Shampoo Conditioner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantene-advanced-hairfall-solution-2in1-anti-hairfall-shampoo-conditioner/62f142c3026528107b251ef6' },
{ name: 'Aurion Badmintontennissquash Zipper Kit Bag Equipment Bag For Racquets Waterproof And Dustproof With Space', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aurion-badmintontennissquash-zipper-kit-bag-equipment-bag-for-racquets-waterproof-and-dustproof-with-space/62f0757c94e59d0abf09948f' },
{ name: 'Beautiful Talc Mesmeric Pleasing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/beautiful-talc-mesmeric-pleasing/62f0757b94e59d0abf099480' },
{ name: 'Ld Orient Electric Wendy 1200mm Ceiling Fan Pearl Whitewalnut', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-orient-electric-wendy-1200mm-ceiling-fan-pearl-whitewalnut/62f0757b94e59d0abf09948d' },
{ name: 'Size L Dixcy Scott Men Trunks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-dixcy-scott-men-trunks/62f0757a94e59d0abf09946c' },
{ name: 'Renewed Lenovo Gx30n81776 Kb Micebo 510 Wireless Combo Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-lenovo-gx30n81776-kb-micebo-510-wireless-combo-black/62f0757a94e59d0abf09946f' },
{ name: 'Size L Aarika Womens Shirt Shorts Set Night Shirt Ns Re 5407whitel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-aarika-womens-shirt-shorts-set-night-shirt-ns-re-5407whitel/62f0757a94e59d0abf099461' },
{ name: 'Kodak 200 Gsm 4r 4 X 6 Photo Paper High Glossy', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kodak-200-gsm-4r-4-x-6-photo-paper-high-glossy/62f0757a94e59d0abf09945e' },
{ name: 'Li Ning Xp Iv Aluminum Strung Badminton Racquet Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/li-ning-xp-iv-aluminum-strung-badminton-racquet-set-of-2/62f0757a94e59d0abf099468' },
{ name: 'Flocare Onion Hair Oil For Strong Healthy Hair With Pure Argan Oil Almond Olive Jojoba Coconut Oils Reduces Hair Fall Mineral Oil Free All Hair Types 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flocare-onion-hair-oil-for-strong-healthy-hair-with-pure-argan-oil-almond-olive-jojoba-coconut-oils-reduces-hair-fall-mineral-oil-free-all-hair-types-100-ml/62f0757b94e59d0abf099476' },
{ name: 'Nivia Deflate Round 01 Bag Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nivia-deflate-round-01-bag-black/62f0757b94e59d0abf099486' },
{ name: 'Kingsway Pu Leatherite Classic Stitched Steering Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kingsway-pu-leatherite-classic-stitched-steering-cover/62f0757b94e59d0abf099488' },
{ name: 'Ibell Pt3833 Non Stick Tawa Dosa Roti Chapati Pathiri Tawa Large Rectangular Aluminium Body 38cm X 33cm With 4mm Thickness Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ibell-pt3833-non-stick-tawa-dosa-roti-chapati-pathiri-tawa-large-rectangular-aluminium-body-38cm-x-33cm-with-4mm-thickness-black/62f0757a94e59d0abf099464' },
{ name: 'Horlicks Health And Nutrition Drink 750 G Refill Pack Chocolate Flavor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/horlicks-health-and-nutrition-drink-750-g-refill-pack-chocolate-flavor/62f0757994e59d0abf099450' },
{ name: 'Camel Fabrica Acrylic Ultra Colors 10ml Each 6 Shades', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/camel-fabrica-acrylic-ultra-colors-10ml-each-6-shades/62f0757994e59d0abf099452' },
{ name: 'Bikri Kendra 20 Hexagon With 20 Butterfly Golden Wall Stickers 3d Acrylic Stickers Mirror Stickers Set Of 20', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikri-kendra-20-hexagon-with-20-butterfly-golden-wall-stickers-3d-acrylic-stickers-mirror-stickers-set-of-20/62f0757894e59d0abf099446' },
{ name: 'Luxor Graphic Micro Pen Blue Bopp Pouch Pack Of 10 Pen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luxor-graphic-micro-pen-blue-bopp-pouch-pack-of-10-pen/62f0757894e59d0abf09943c' },
{ name: 'Kelloggs Chocos Moons And Stars 12 Kg Pouch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-chocos-moons-and-stars-12-kg-pouch/62f0757894e59d0abf099444' },
{ name: 'Bata Mens Canvas Sneakers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bata-mens-canvas-sneakers/62f0757894e59d0abf099436' },
{ name: 'Bata Mens Glance Th Hawaii Thong Sandals', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bata-mens-glance-th-hawaii-thong-sandals/62f0757894e59d0abf09944a' },
{ name: 'Milton Super 1000 Single Wall Stainless Steel Bottle 1000 Ml Silverset Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/milton-super-1000-single-wall-stainless-steel-bottle-1000-ml-silverset-of-1/62f0757894e59d0abf099434' },
{ name: 'Heart Home Non Woven Hanging Saree Cover Wardrobe Organizer Closet Organizer Saree Garments Protector Cover With 1 Zipper Compartment On Back Side Pack Of 6 Orange Hs38hearth21481', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/heart-home-non-woven-hanging-saree-cover-wardrobe-organizer-closet-organizer-saree-garments-protector-cover-with-1-zipper-compartment-on-back-side-pack-of-6-orange-hs38hearth21481/62f0757894e59d0abf09943e' },
{ name: 'Wonderchef Sumo Mixer Grinder 600w With 3 Jars And Anti Rust Stainless Steel Blades Ergonomic Handles 5 Years Warranty On Motor Red And Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-sumo-mixer-grinder-600w-with-3-jars-and-anti-rust-stainless-steel-blades-ergonomic-handles-5-years-warranty-on-motor-red-and-black/62f0757894e59d0abf099442' },
{ name: 'Ponds Bright Beauty Spot Less Glow Face Wash With Vitamins Removes Dead Skin Cells Dark Spots Double Brightness Action All Skin Types 150g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ponds-bright-beauty-spot-less-glow-face-wash-with-vitamins-removes-dead-skin-cells-dark-spots-double-brightness-action-all-skin-types-150g/62f0757994e59d0abf09944e' },
{ name: 'For Sbi Credit Card Redmi Book 15 Intel Core I3 11th Gen8 Gb256 Gb Ssdwindows 11 Home156 Inches 3962 Cms Fhd Anti Glarems Officecharcoal Gray18 Kg Thin And Light Laptop', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-redmi-book-15-intel-core-i3-11th-gen8-gb256-gb-ssdwindows-11-home156-inches-3962-cms-fhd-anti-glarems-officecharcoal-gray18-kg-thin-and-light-laptop/62f0757994e59d0abf099454' },
{ name: 'Fossil Tillie Mini Analog Multicolor Dial Womens Watch Bq3502', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fossil-tillie-mini-analog-multicolor-dial-womens-watch-bq3502/62f0757994e59d0abf099457' },
{ name: 'Kuber Industries Square Cotton Bath Towel Super Soft Fluffy And Absorbent Perfect', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kuber-industries-square-cotton-bath-towel-super-soft-fluffy-and-absorbent-perfect/62f0757894e59d0abf099438' },
{ name: 'Havells 20w Led Cool Day Light Batten Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-20w-led-cool-day-light-batten-pack-of-1/62f0757794e59d0abf09942c' },
{ name: 'T2f Boys Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/t2f-boys-sweatshirt/62f0757794e59d0abf099430' },
{ name: 'Cello Tropical Lagoon Dazzle Series Opalware Dinner Set 35 Pieces Service', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-tropical-lagoon-dazzle-series-opalware-dinner-set-35-pieces-service/62efc4263f493407576ccd6e' },
{ name: 'Zebronics Jet Premium Gaming Headphone With Led Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-jet-premium-gaming-headphone-with-led-light/62efc4263f493407576ccd73' },
{ name: 'For Sbi Credit Card Vu 139 Cm 55 Inches Premium Series 4k Ultra Hd Smart Android Qled Tv 55qml Charcoal Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/for-sbi-credit-card-vu-139-cm-55-inches-premium-series-4k-ultra-hd-smart-android-qled-tv-55qml-charcoal-grey/62efc4263f493407576ccd6c' },
{ name: 'Luvlap Instadry Anti Piling Fleece Extra Absorbent Quick Dry Sheet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luvlap-instadry-anti-piling-fleece-extra-absorbent-quick-dry-sheet/62f0757794e59d0abf09942e' },
{ name: 'Zebronics Zeb Fame 20 Multi Media Speakers With Aux Usb And Volume Control Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-fame-20-multi-media-speakers-with-aux-usb-and-volume-control-black/62efc4263f493407576ccd71' },
{ name: 'Zebronics Zeb Symphony Bluetooth Wireless In Ear Earphones With Mic V52 Type C Charging', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-symphony-bluetooth-wireless-in-ear-earphones-with-mic-v52-type-c-charging/62f0757894e59d0abf09943a' },
{ name: 'Luxor Graphic Micro Pen Blue Bopp Pouch Pack Of 10 Pen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/luxor-graphic-micro-pen-blue-bopp-pouch-pack-of-10-pen/62f0757894e59d0abf09943c' },
{ name: 'Sbi Credit Card Toshiba 80 Cm 32 Inches V Series Hd Ready Smart Android Led Tv 32v35kp Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-toshiba-80-cm-32-inches-v-series-hd-ready-smart-android-led-tv-32v35kp-silver/62efc4263f493407576ccd6a' },
{ name: 'My Newborn Flannel And Fleece Multi Purpose Baby Blanket Wrapper Sleeping Bag Pack Of 2 Multi 670cm L X 780cm W', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/my-newborn-flannel-and-fleece-multi-purpose-baby-blanket-wrapper-sleeping-bag-pack-of-2-multi-670cm-l-x-780cm-w/62efc4253f493407576ccd66' },
{ name: 'Size 9 Red Tape Mens Tan Loafer 9 Uk Rts11143d', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-9-red-tape-mens-tan-loafer-9-uk-rts11143d/62efc4253f493407576ccd68' },
{ name: 'Ant Audio Ammo Portable Ip66 Bluetooth Speakers Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-audio-ammo-portable-ip66-bluetooth-speakers-blue/62efc4253f493407576ccd62' },
{ name: 'Aristocrat Jude 66cm Polypropylene Hardsided Medium Luggage 8w Black Strolley', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aristocrat-jude-66cm-polypropylene-hardsided-medium-luggage-8w-black-strolley/62efc4253f493407576ccd5c' },
{ name: 'Happilo 100 Natural Premium California Almonds 1kg Value Pack Pouch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/happilo-100-natural-premium-california-almonds-1kg-value-pack-pouch-/62efc4253f493407576ccd52' },
{ name: 'Boat Bassheads 152 Made In India In Ear Wired Earphones With Mic Vibrant Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-bassheads-152-made-in-india-in-ear-wired-earphones-with-mic-vibrant-green/62efc4253f493407576ccd5f' },
{ name: 'Lifelong Llwh106 Flash 3 Litres Instant Water Heater For Home Use 8 Bar Pressurepower Onoff Indicator And Advanced Safety 3000w Isi Certified 2 Years Warranty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llwh106-flash-3-litres-instant-water-heater-for-home-use-8-bar-pressurepower-onoff-indicator-and-advanced-safety-3000w-isi-certified-2-years-warranty/62efc4253f493407576ccd56' },
{ name: 'Amazon Brand Symbol Mens Boxer Shorts Tsh17 03grey Melange4xl', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-boxer-shorts-tsh17-03grey-melange4xl/62efc4243f493407576ccd50' },
{ name: 'Post It Super Sticky Notes 2x Sticking Power 3 In X 3 In 5 Padspack 90 Sheetspad Neon Colour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/post-it-super-sticky-notes-2x-sticking-power-3-in-x-3-in-5-padspack-90-sheetspad-neon-colour/62efc4243f493407576ccd4d' },
{ name: 'Vega Power Series P1 Beard Trimmer For Men With 160 Mins Runtime 40 Length Settings Green Vhth 25', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vega-power-series-p1-beard-trimmer-for-men-with-160-mins-runtime-40-length-settings-green-vhth-25/62efc4253f493407576ccd58' },
{ name: 'Jaguar Classic Black Eau De Toilette 40ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jaguar-classic-black-eau-de-toilette-40ml/62efc4233f493407576ccd32' },
{ name: 'Larah By Borosil Bella Silk Series Opalware 35 Pieces Dinner Set Opal Glass Dinner Plates Bowls Crockery Set For Dining', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/larah-by-borosil-bella-silk-series-opalware-35-pieces-dinner-set-opal-glass-dinner-plates-bowls-crockery-set-for-dining/62efc4243f493407576ccd47' },
{ name: 'Parker Classic Anti Microbial Stainless Steel Ball Pen Cion Coated Ink Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parker-classic-anti-microbial-stainless-steel-ball-pen-cion-coated-ink-blue/62efc4233f493407576ccd3e' },
{ name: 'Size 8 Red Tape Mens Loafer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-red-tape-mens-loafer/62efc4233f493407576ccd42' },
{ name: 'Wild Stone Classic Cologne And Leather Long Lasting Deodorants', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wild-stone-classic-cologne-and-leather-long-lasting-deodorants/62efc4233f493407576ccd30' },
{ name: 'Qube By Fort Collins Mens Windcheater', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/qube-by-fort-collins-mens-windcheater/62efc4233f493407576ccd37' },
{ name: 'Parachute Advansed Body Lotion Soft Touch With Honey Silky Smooth Skin 400 Ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-body-lotion-soft-touch-with-honey-silky-smooth-skin-400-ml-pack-of-2/62efc4233f493407576ccd35' },
{ name: 'Medohealthy 3 Ply Disposable Cotton Face Masks Pack Of 100 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/medohealthy-3-ply-disposable-cotton-face-masks-pack-of-100-blue/62efc4233f493407576ccd2d' },
{ name: '3m Post It Sticky Notes Pad Assorted Colours Pack Of 3 100 Sheets Each 3 X 3 For Notes Reminders Study School And Organizing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/3m-post-it-sticky-notes-pad-assorted-colours-pack-of-3-100-sheets-each-3-x-3-for-notes-reminders-study-school-and-organizing/62efc4233f493407576ccd3a' },
{ name: 'Makeup Revolution X Tammi Tropical Paradise Palette 223g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-x-tammi-tropical-paradise-palette-223g/62efc4223f493407576ccd25' },
{ name: 'Isle Locada By Hidesign Womens Shoulder Bag Cardinalcardina', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/isle-locada-by-hidesign-womens-shoulder-bag-cardinalcardina/62efc4223f493407576ccd21' },
{ name: 'Havells 1200 Mm Efficiencia Prime High Speed Bldc Motor Energy Efficient With Remote Control Ceiling Fan White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-1200-mm-efficiencia-prime-high-speed-bldc-motor-energy-efficient-with-remote-control-ceiling-fan-white/62efc4213f493407576ccd1d' },
{ name: 'Ptron Bassbuds Duo In Ear Earbuds With 32hrs Total Playtime Bluetooth 51 Wireless Headphones Stereo Audio Touch Control Tws Dual Mic Type C Fast Charging Ipx4 Voice Assistance Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-duo-in-ear-earbuds-with-32hrs-total-playtime-bluetooth-51-wireless-headphones-stereo-audio-touch-control-tws-dual-mic-type-c-fast-charging-ipx4-voice-assistance-black/62efc4213f493407576ccd13' },
{ name: 'Asus Vivobook 14 2021 14 Inch 3556 Cm Fhd Intel Core I5 1035g1 10th Gen Thin And Light Laptop 8gb1tb Hddoffice 2021windows 11integrated Graphicssilver16 Kg X415ja Eb501ws', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asus-vivobook-14-2021-14-inch-3556-cm-fhd-intel-core-i5-1035g1-10th-gen-thin-and-light-laptop-8gb1tb-hddoffice-2021windows-11integrated-graphicssilver16-kg-x415ja-eb501ws/62efc4213f493407576ccd19' },
{ name: 'Eurospa Set Of 7 Cotton Bath Hand Face Towel Set Pink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eurospa-set-of-7-cotton-bath-hand-face-towel-set-pink/62efc4213f493407576ccd1f' },
{ name: 'Cockatoo Cs 01 Shaker Bottle Black 700 Milliliters', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cockatoo-cs-01-shaker-bottle-black-700-milliliters/62efc4213f493407576ccd15' },
{ name: 'T2f Boys Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/t2f-boys-sweatshirt/62efc4213f493407576ccd1b' },
{ name: 'Anandeshwar White Girls Crepe Top And Palazzo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/anandeshwar-white-girls-crepe-top-and-palazzo/62efc4213f493407576ccd17' },
{ name: 'Zebronics Zeb Wonderbar 10 Usb Powered 20 Computer Speaker With Rgb Lights', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-wonderbar-10-usb-powered-20-computer-speaker-with-rgb-lights/62efc3e63f493407576ccd10' },
{ name: 'Sbi Credit Card Iqoo 9t 5g Alpha 8gb Ram 128gb Storage Snapdragon 8 Gen 1 120w Flashcharge 120hz E5 Amoled Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sbi-credit-card-iqoo-9t-5g-alpha-8gb-ram-128gb-storage-snapdragon-8-gen-1-120w-flashcharge-120hz-e5-amoled-display/62efc4223f493407576ccd23' },
{ name: 'Size Xl Oxolloxo Womens Plus Size Top Whitex Large', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-xl-oxolloxo-womens-plus-size-top-whitex-large/62ef77f596805c83be175e8a' },
{ name: 'Strontium Nitro 32gb Micro Sdhc Memory Card 85mbs Uhs I U1 Class 10 High Speed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strontium-nitro-32gb-micro-sdhc-memory-card-85mbs-uhs-i-u1-class-10-high-speed/62efc4223f493407576ccd2a' },
{ name: 'Ptron Bassbuds Duo New Bluetooth 51 Wireless Headphones 32hrs Total Playtime Stereo Audio Touch Control Tws Dual Hd Mic Type C Fast Charging Ipx4 Water Resistant Voice Assistance Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-duo-new-bluetooth-51-wireless-headphones-32hrs-total-playtime-stereo-audio-touch-control-tws-dual-hd-mic-type-c-fast-charging-ipx4-water-resistant-voice-assistance-black-amazonin-electronics/6224ee9b0a86a1d7b7f3b47c' },
{ name: 'Buy Maya Creation Pack Of 6 Ceramic Random Color Handmade Ceramic Multi Random Color Coffee Cup Set Medium Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-maya-creation-pack-of-6-ceramic-random-color-handmade-ceramic-multi-random-color-coffee-cup-set-medium-online-at-low-prices-in-india-amazonin/61d45d99d2306a22e903c932' },
{ name: 'Ptron Bassbuds Duo New Bluetooth 51 Wireless Headphones Stereo Audio Touch Control Tws Dual Hd Mic Type C Fast Charging Ipx4 Water Resistant Passive Noise Cancelling Voice Assistant Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-duo-new-bluetooth-51-wireless-headphones-stereo-audio-touch-control-tws-dual-hd-mic-type-c-fast-charging-ipx4-water-resistant-passive-noise-cancelling-voice-assistant-black-amazonin-electronics/61e177c8fc2ae00727bb3620' },
{ name: 'Saffola Honey 100 Pure Nmr Tested Honey Squeezy Pack 2 X 400g Buy 1 Get 1 Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/saffola-honey-100-pure-nmr-tested-honey-squeezy-pack-2-x-400g-buy-1-get-1-amazonin-grocery-gourmet-foods/61cb08570769ba208f4d77d1' },
{ name: 'Buy Amazon Brand Symbol Mens Solid Regular T Shirt Ss19tshsvnpo3 3ecru Olive Sunflower Blue S At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-symbol-mens-solid-regular-t-shirt-ss19tshsvnpo3-3ecru-olive-sunflower-blue-s-at-amazonin/61cb07b50769ba1d604d77ce' },
{ name: 'Wall1ders Hexagon Hexagon Mirror Wall Stickers Mirror Stickers For Wall 3d Mirror Wall Stickers Wall Stickers For Hall Room Home Offices Silver Pack Of 7 Acrylic Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wall1ders-hexagon-hexagon-mirror-wall-stickers-mirror-stickers-for-wall-3d-mirror-wall-stickers-wall-stickers-for-hall-room-home-offices-silver-pack-of-7-acrylic-amazonin-home-improvement/61af38f8572b4430f1655708' },
{ name: 'Buy Sukkhi Dazzling Gold Plated Wedding Jewellery Austrian Diamond Choker Necklace Set Combo For Women 413cb1900 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-sukkhi-dazzling-gold-plated-wedding-jewellery-austrian-diamond-choker-necklace-set-combo-for-women-413cb1900-at-amazonin/61cb06fe0769ba7cc84d77c7' },
{ name: 'Buy Amazon Brand Symbol Analog Digital Black Dial Mens Watch Az Sym Ss21 1155c At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-symbol-analog-digital-black-dial-mens-watch-az-sym-ss21-1155c-at-amazonin/61b0351c9d0159a140b51d17' },
{ name: 'Buy Whisper Ultra Soft Sanitary Pads 50 Pieces Xl Venus Breeze Hair Removal Razor For Women Combo Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-whisper-ultra-soft-sanitary-pads-50-pieces-xl-venus-breeze-hair-removal-razor-for-women-combo-online-at-low-prices-in-india-amazonin/61adbe2d6aa2643d1ca8bb5f' },
{ name: 'Buy Asian Premium Family Inner Steel Casserole 2500 Ml Red Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-asian-premium-family-inner-steel-casserole-2500-ml-red-online-at-low-prices-in-india-amazonin/61adbda96aa2648bb5a8bb5c' },
{ name: 'Nature Purify Raisin Dry Fruitskishmish 250gm Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nature-purify-raisin-dry-fruitskishmish-250gm-amazonin-grocery-gourmet-foods/61adbdf282cbfa8e56ee980d' },
{ name: 'Bajaj 830418 Pk4 Plus Led Lamp 9 Watts Cool Daylight B22 Pack Of 4 White Medium Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-830418-pk4-plus-led-lamp-9-watts-cool-daylight-b22-pack-of-4-white-medium-amazonin/61adbe6c6aa26421b7a8bb62' },
{ name: 'Cadbury Celebrations Assorted Chocolate Gift Pack 1836g Pack Of 2 Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-celebrations-assorted-chocolate-gift-pack-1836g-pack-of-2-amazonin-grocery-gourmet-foods/61adba9b82cbfa539aee9807' },
{ name: 'Buy Sanfe Anti Perspirant Odour Balancing Deo Cream For Women 60g With Sea Aster And Green Seaweed Ph Balancing Controls Sweat Odour Anti Bacterial All Natural Deodorant Cream Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-sanfe-anti-perspirant-odour-balancing-deo-cream-for-women-60g-with-sea-aster-and-green-seaweed-ph-balancing-controls-sweat-odour-anti-bacterial-all-natural-deodorant-cream-online-at-low-prices-in-india-amazonin/61adba5682cbfa7c25ee9804' },
{ name: 'Tfpro Mobile Holder Table And Bed Stand Adjustable 5 Steps Foldable For Universal Mobile Multi Coloured Pack Of 2 Buy Tfpro Mobile Holder Table And Bed Stand Adjustable 5 Steps Foldable For Universal Mobile Multi Coloured Pack Of 2 Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tfpro-mobile-holder-table-and-bed-stand-adjustable-5-steps-foldable-for-universal-mobile-multi-coloured-pack-of-2-buy-tfpro-mobile-holder-table-and-bed-stand-adjustable-5-steps-foldable-for-universal-mobile-multi-coloured-pack-of-2-online-at-low-price-in-india-amazonin/61adbd7382cbfa47a6ee980a' },
{ name: 'Buy Zunpulse Wifi Enabled Smart Led Bulb B22 10 Watt Compatible With Amazon Alexa And Google Assistant 16 Million Colors Tunable White Dimmablepack Of 2 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-zunpulse-wifi-enabled-smart-led-bulb-b22-10-watt-compatible-with-amazon-alexa-and-google-assistant-16-million-colors-tunable-white-dimmablepack-of-2-online-at-low-prices-in-india-amazonin/61adb96482cbfaa63dee97fb' },
{ name: 'Portronics Adapto Iii Dual Usb Adapter With 1 Ac Power Socket 34amp Total Output White Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-adapto-iii-dual-usb-adapter-with-1-ac-power-socket-34amp-total-output-white-amazonin-electronics/61a9ea48fe8c28311d5ed179' },
{ name: 'Amazonin Buy Saleshop365 Wi Fi Receiver 300mbps 24ghz 80211bgn Usb 20 Wireless Wi Fi Network Adapter Online At Low Prices In India Saleshop365 Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-saleshop365-wi-fi-receiver-300mbps-24ghz-80211bgn-usb-20-wireless-wi-fi-network-adapter-online-at-low-prices-in-india-saleshop365-reviews-ratings/61adb8ee82cbfac729ee97f4' },
{ name: 'Boat Rockerz 245v2 Wireless Bluetooth V50 8 Hours Playback Time Ipx5 Sweat And Water Resistance In Built Mic And Voice Assistantactive Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-245v2-wireless-bluetooth-v50-8-hours-playback-time-ipx5-sweat-and-water-resistance-in-built-mic-and-voice-assistantactive-black-amazonin-electronics/619340e54b8c2f4a28f819b4' },
{ name: 'Laptop Stand Ivoler Adjustable Aluminum Laptop Computer Stand Tablet Standergonomic Foldable Portable Desktop Holder Compatible With Macbook Air Pro Dell Xps Hp Lenovo More 10 156 White Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/laptop-stand-ivoler-adjustable-aluminum-laptop-computer-stand-tablet-standergonomic-foldable-portable-desktop-holder-compatible-with-macbook-air-pro-dell-xps-hp-lenovo-more-10-156-white-amazonin-computers-accessories/61934058392d6b82f885ff87' },
{ name: '3m Interior Cleaner 580 G Amazonin Car Motorbike', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/3m-interior-cleaner-580-g-amazonin-car-motorbike/61740771923abfd0f033c707' },
{ name: 'Solefit Mens Red Flip Flops 8 Uk 42 Eu Slft 0008 Amazonin Shoes Handbags', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/solefit-mens-red-flip-flops-8-uk-42-eu-slft-0008-amazonin-shoes-handbags/61adba1782cbfa81c4ee9801' },
{ name: 'Adroitz Neck Cushion Black Pack Of 2 Amazonin Bags Wallets And Luggage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/adroitz-neck-cushion-black-pack-of-2-amazonin-bags-wallets-and-luggage/61adb9b582cbfa16f0ee97fe' },
{ name: 'Oppo A74 5g Fluid Black6gb Ram128gb Storage 5g Android Smartphone 5000 Mah Battery 18w Fast Charge 90hz Lcd Display Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oppo-a74-5g-fluid-black6gb-ram128gb-storage-5g-android-smartphone-5000-mah-battery-18w-fast-charge-90hz-lcd-display-amazonin-electronics/619340aa392d6b2ae385ff8a' },
{ name: 'Bagrrys Corn Flakes Plus Original And Healthier Pouch 1000 G Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bagrrys-corn-flakes-plus-original-and-healthier-pouch-1000-g-amazonin-grocery-gourmet-foods/615ce7513adbab7b9128f99d' },
{ name: 'Buy Wipro Wi Fi Enabled Smart Led Bulb B22 9 Watt Pack Of 1 Shades Of White And Yellow Compatible With Amazon Alexa And Google Assistant Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-wipro-wi-fi-enabled-smart-led-bulb-b22-9-watt-pack-of-1-shades-of-white-and-yellow-compatible-with-amazon-alexa-and-google-assistant-online-at-low-prices-in-india-amazonin/615ce65a3adbab679128f999' },
{ name: 'Buy Bajaj New Shakti Neo 15l Metal Body 4 Star Water Heater With Multiple Safety System White Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-bajaj-new-shakti-neo-15l-metal-body-4-star-water-heater-with-multiple-safety-system-white-online-at-low-prices-in-india-amazonin/615c41c0c1e3cdb33af3e09d' },
{ name: 'Buy Pampers All Round Protection Pants Small Size Baby Diapers Sm 86 Count Lotion With Aloe Vera Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pampers-all-round-protection-pants-small-size-baby-diapers-sm-86-count-lotion-with-aloe-vera-online-at-low-prices-in-india-amazonin/615878170d641746efc68547' },
{ name: 'Hersheys Spreads Cocoa 350g Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hersheys-spreads-cocoa-350g-amazonin-grocery-gourmet-foods/615877a2871fc1717aa16532' },
{ name: 'Buy Fusefit Womens Plain Slim Fit Sports T Shirt Ffa Wt010 02black Melange Small At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fusefit-womens-plain-slim-fit-sports-t-shirt-ffa-wt010-02black-melange-small-at-amazonin/61587435871fc16799a164ef' },
{ name: 'Buy Redux Digital Sports Watch Multi Functional Watch For Boys At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-redux-digital-sports-watch-multi-functional-watch-for-boys-at-amazonin/615874e2871fc1b5e0a16511' },
{ name: 'Yogabar No Added Sugar Oatmeal 1kg With Alphonso Mango Chia Seeds And Real Fruits Berries Gluten Free Oatmeal For Breakfast Whole Oatmeal Cereal With Rolled Oats For Weight Loss Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-no-added-sugar-oatmeal-1kg-with-alphonso-mango-chia-seeds-and-real-fruits-berries-gluten-free-oatmeal-for-breakfast-whole-oatmeal-cereal-with-rolled-oats-for-weight-loss-amazonin-grocery-gourmet-foods/615875e0871fc1e78fa1652c' },
{ name: 'Buy Canon Powershot Sx430 Is 20mp Digital Camera With 45x Optical Zoom Black Online At Low Price In India Canon Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-canon-powershot-sx430-is-20mp-digital-camera-with-45x-optical-zoom-black-online-at-low-price-in-india-canon-camera-reviews-ratings-amazonin/6158729545435f71fed6a8b6' },
{ name: 'Eyebogler Regular Fit Mens Cotton T Shirt At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eyebogler-regular-fit-mens-cotton-t-shirt-at-amazonin/61587393871fc15e47a164bb' },
{ name: 'Buy Butterfly Jet Elite Mixer Grinder 750w 4 Jars Grey Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-butterfly-jet-elite-mixer-grinder-750w-4-jars-grey-online-at-low-prices-in-india-amazonin/6158748d871fc12bd1a16502' },
{ name: 'Neufarm Value Cashewkaju Whole W400 Cashew Nuts Small Size 500g Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/neufarm-value-cashewkaju-whole-w400-cashew-nuts-small-size-500g-amazonin-grocery-gourmet-foods/6158776b871fc14eaca1652f' },
{ name: 'Apple Iphone 11 64gb Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/apple-iphone-11-64gb-black-amazonin-electronics/61586f6645435f2d05d6a8a0' },
{ name: 'Buy Skybags Trooper 55 Cms Polycarbonate Blue Hardsided Cabin Luggage At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-skybags-trooper-55-cms-polycarbonate-blue-hardsided-cabin-luggage-at-amazonin/6150106507062c291b4db008' },
{ name: 'Boat Flash Edition Smartwatch With Activity Trackermultiple Sports Modesfull Touch 13 Screengesture Controlsleep Monitorcamera Music Controlip68 Dustsweat Splash Resistancegalaxy Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-flash-edition-smartwatch-with-activity-trackermultiple-sports-modesfull-touch-13-screengesture-controlsleep-monitorcamera-music-controlip68-dustsweat-splash-resistancegalaxy-blue/6149dbaba58f5165ceafcdcf' },
{ name: 'Ofixo Multi Purpose Laptop Tablestudy Tablebed Tablefoldable And Portable Woodenwriting Desk Wooden Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ofixo-multi-purpose-laptop-tablestudy-tablebed-tablefoldable-and-portable-woodenwriting-desk-wooden-amazonin-computers-accessories/612fa1fcb77654cae143931c' },
{ name: 'Kelloggs Corn Flakes Original High In Iron High In B Group Vitamins Breakfast Cereals 875g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-corn-flakes-original-high-in-iron-high-in-b-group-vitamins-breakfast-cereals-875g-amazonin-grocery-amp-gourmet-foods/612022868bb86c358802f026' },
{ name: 'Buy Stayfree Secure X Large Cottony Sanitary Pads For Women 40s X 3 Pack Of 120 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-stayfree-secure-x-large-cottony-sanitary-pads-for-women-40s-x-3-pack-of-120-online-at-low-prices-in-india-amazonin/612da66d11a6aa0814ad0935' },
{ name: 'Buy Leriya Fashion Cotton With Blouse Piece Womens Wear Leriya S1115multicolouredone Size At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-leriya-fashion-cotton-with-blouse-piece-womens-wear-leriya-s1115multicolouredone-size-at-amazonin/612da82a11a6aa3ed3ad094d' },
{ name: 'Yogabar Wholegrain Breakfast Muesli Fruits Nuts Seeds 700 G Super Saver Pack Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-wholegrain-breakfast-muesli-fruits-nuts-seeds-700-g-super-saver-pack-amazonin-grocery-gourmet-foods/614b5877482d26343e64d1ac' },
{ name: 'Tcl Socl 100 Wired In Ear Headphone With Mic Ocean Blue Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tcl-socl-100-wired-in-ear-headphone-with-mic-ocean-blue-amazonin-electronics/6149fd9688184a0e63dbacac' },
{ name: 'Laptop Stand Ivoler Adjustable Aluminum Laptop Computer Stand Tablet Standergonomic Foldable Portable Desktop Holder Compatible With Macbook Air Pro Dell Xps Hp Lenovo More 10 156 White Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/laptop-stand-ivoler-adjustable-aluminum-laptop-computer-stand-tablet-standergonomic-foldable-portable-desktop-holder-compatible-with-macbook-air-pro-dell-xps-hp-lenovo-more-10-156-white-amazonin-computers-accessories/6149e1e8a58f51a624afcdd6' },
{ name: 'Helix Smart Watch Digital Black Dial Unisex Adult Watch Tw0hxw201t Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/helix-smart-watch-digital-black-dial-unisex-adult-watch-tw0hxw201t-amazonin-electronics/6149da2aa58f51824cafcdcc' },
{ name: 'Visko Tools 701 Doe Spanner Set Silver 8 Pieces Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/visko-tools-701-doe-spanner-set-silver-8-pieces-amazonin-home-improvement/6149a84abc52f2588055668b' },
{ name: 'Ptron Solero Swing 3 In 1 Fast Charging 2a Cable For Type C Micro Ios Smartphones Smart Charge 3 Port Data Charging Cable Power Line Blue Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-solero-swing-3-in-1-fast-charging-2a-cable-for-type-c-micro-ios-smartphones-smart-charge-3-port-data-charging-cable-power-line-blue-amazonin-computers-accessories/6149d8e4a58f51523bafcdc9' },
{ name: 'Omron Hem 7120 Fully Automatic Digital Blood Pressure Monitor With Intellisense Technology For Most Accurate Measurement Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/omron-hem-7120-fully-automatic-digital-blood-pressure-monitor-with-intellisense-technology-for-most-accurate-measurement-amazonin-health-personal-care/61494d3191d598e99d9322af' },
{ name: 'Agaro Electric Handheld Full Body Massager With 3 Massage Heads Variable Speed Settings For Pain Relief And Relaxation Back Leg Foot Black Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-electric-handheld-full-body-massager-with-3-massage-heads-variable-speed-settings-for-pain-relief-and-relaxation-back-leg-foot-black-amazonin-health-personal-care/61494c7f91d598024e9322ac' },
{ name: 'Ptron Tangent Lite Bluetooth 50 Wireless Headphones With Hi Fi Stereo Sound 8hrs Playtime Lightweight Ergonomic Neckband Sweat Resistant Magnetic Earbuds Voice Assistant Mic Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-tangent-lite-bluetooth-50-wireless-headphones-with-hi-fi-stereo-sound-8hrs-playtime-lightweight-ergonomic-neckband-sweat-resistant-magnetic-earbuds-voice-assistant-mic-black-amazonin-electronics/61494bfd91d598f5129322a4' },
{ name: 'Asus Vivo Aio V222 215 5461 Cm Fhd Intel Pentium Gold 6405u All In One Desktop 4gb1tb Hddoffice 2019windows 10integrated Graphicswireless Keyboard Mouseblack48 Kg V222fak Ba021ts Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asus-vivo-aio-v222-215-5461-cm-fhd-intel-pentium-gold-6405u-all-in-one-desktop-4gb1tb-hddoffice-2019windows-10integrated-graphicswireless-keyboard-mouseblack48-kg-v222fak-ba021ts-amazonin-computers-accessories/6148b09b987b0d0c8e75f44f' },
{ name: 'Buy Pedigree Biscrok Biscuits Dog Treat Above 4 Months Milk And Chicken Flavor 500g Pack Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pedigree-biscrok-biscuits-dog-treat-above-4-months-milk-and-chicken-flavor-500g-pack-online-at-low-prices-in-india-amazonin/61489066987b0d18a375f2fc' },
{ name: 'Syga Dslr Camera Shoulder Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/syga-dslr-camera-shoulder-strap-/61486a47ae0f911dd7320441' },
{ name: 'Buy Dove Body Love Light Hydration Body Lotion For All Skin Types Paraben Free 48hrs Moisturisation With Plan Based Moisturiser Fresh Hydrated Skin 400ml Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-dove-body-love-light-hydration-body-lotion-for-all-skin-types-paraben-free-48hrs-moisturisation-with-plan-based-moisturiser-fresh-hydrated-skin-400ml-online-at-low-prices-in-india-amazonin/61470370766e1b5a43f01385' },
{ name: 'Ancy Dry Fruits Premium Dried Afghani Anjeer 250g Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ancy-dry-fruits-premium-dried-afghani-anjeer-250g-amazonin-grocery-gourmet-foods/61470257766e1b23bbf0137c' },
{ name: 'Buy Kent Egg Boiler W 16053 3 Boiling Modes Stainless Steel Body Boils Upto 7 Eggs At A Time Automatic Shut Down Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-kent-egg-boiler-w-16053-3-boiling-modes-stainless-steel-body-boils-upto-7-eggs-at-a-time-automatic-shut-down-online-at-low-prices-in-india-amazonin/61470226766e1b06e7f01379' },
{ name: 'Zoook Color Blast Bluetooth Party Speaker 30 Watts With Rgb Lightsipx Rated Water Proof4000mah Battery57mm X 2 Driversos Alarmsiri Google Voice Assist Outdoor Party Handsfree Calling Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zoook-color-blast-bluetooth-party-speaker-30-watts-with-rgb-lightsipx-rated-water-proof4000mah-battery57mm-x-2-driversos-alarmsiri-google-voice-assist-outdoor-party-handsfree-calling-black-amazonin-electronics/6147011f766e1be3dcf01369' },
{ name: 'Klaxon Deacon Engineered Wood Tv Unitdisplay Storage Cabinet Rack With Decor Shelf Walnut Amazonin Home Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/klaxon-deacon-engineered-wood-tv-unitdisplay-storage-cabinet-rack-with-decor-shelf-walnut-amazonin-home-kitchen/614702b7766e1b45b6f0137f' },
{ name: 'Philips Hp8302 Selfie Straightener Black Amazonin Beauty', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-hp8302-selfie-straightener-black-amazonin-beauty/614702dd766e1bd5bbf01382' },
{ name: 'Amazonin Buy Zebronics Wired Keyboard And Mouse Combo With 104 Keys And A Usb Mouse With 1200 Dpi Judwaa 750 Online At Low Prices In India Zebronics Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-zebronics-wired-keyboard-and-mouse-combo-with-104-keys-and-a-usb-mouse-with-1200-dpi-judwaa-750-online-at-low-prices-in-india-zebronics-reviews-ratings/61480d26e330daca6418f96d' },
{ name: 'Disano All Natural Peanut Butter Crunchy 30 Protein Unsweetened Gluten Free 1 Kg Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/disano-all-natural-peanut-butter-crunchy-30-protein-unsweetened-gluten-free-1-kg-amazonin-grocery-gourmet-foods/614701a8766e1b511df0136f' },
{ name: 'Nilkamal Polyurethane 4 Chest Drawers Purple Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nilkamal-polyurethane-4-chest-drawers-purple-amazonin-furniture/6144a4cfb47a5c2ac3b3ab5f' },
{ name: 'Wolpin Wall Stickers Wallpaper Vintage Wood 60 X 300 Cm Latest Wall Decals Design Planks Self Adhesive Home Office Decor Self Adhesive Arctic Blue Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wolpin-wall-stickers-wallpaper-vintage-wood-60-x-300-cm-latest-wall-decals-design-planks-self-adhesive-home-office-decor-self-adhesive-arctic-blue-amazonin-home-improvement/61470170766e1b9bf3f0136b' },
{ name: 'Disano Oats With High In Protein And Fibre Pouch 15 Kg Pack Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/disano-oats-with-high-in-protein-and-fibre-pouch-15-kg-pack-amazonin-grocery-gourmet-foods/614701d3766e1b6734f01372' },
{ name: 'Cello H2o Unbreakable Plastic Bottle 1 Litre Assorted Colour Amazonin Home Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-h2o-unbreakable-plastic-bottle-1-litre-assorted-colour-amazonin-home-kitchen/613f82f37538aafc27e31a19' },
{ name: 'Daadis Besan Laddoo Pure Ghee 120g Pack Of 2 Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/daadis-besan-laddoo-pure-ghee-120g-pack-of-2-amazonin-grocery-gourmet-foods/613e116d5ef01a78a51704ce' },
{ name: 'Amazonin Buy Portronics Por 015 Toad 11 Wireless Mouse With 24ghz Technology Blue Online At Low Prices In India Portronics Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-portronics-por-015-toad-11-wireless-mouse-with-24ghz-technology-blue-online-at-low-prices-in-india-portronics-reviews-ratings/613ee4a494ed083fe2a9227f' },
{ name: 'Buy Playy Bags Water Proof Laptop Bag Blue Grey At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-playy-bags-water-proof-laptop-bag-blue-grey-at-amazonin/613e10f15ef01ac7621704cb' },
{ name: 'Buy Amazon Brand Anarva Womens Toe Ring In Sterling Silver Jewellery Leaf Design Online At Low Prices In India Amazon Jewellery Store Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-anarva-womens-toe-ring-in-sterling-silver-jewellery-leaf-design-online-at-low-prices-in-india-amazon-jewellery-store-amazonin/613f82557538aafdd3e31a10' },
{ name: 'Boya Bym1 Omnidirectional Lavalier Condenser Microphone With 20ft Audio Cable Black Amazonin Musical Instruments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boya-bym1-omnidirectional-lavalier-condenser-microphone-with-20ft-audio-cable-black-amazonin-musical-instruments/614205b222f15831c975184c' },
{ name: 'Buy Leveret Multipurpose Plastic Big Revolving Spice Rack 16 In 1 Masala Rack Setcondiment Setspice Container Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-leveret-multipurpose-plastic-big-revolving-spice-rack-16-in-1-masala-rack-setcondiment-setspice-container-online-at-low-prices-in-india-amazonin/613f833d7538aa8e41e31a1c' },
{ name: 'Buy Inalsa Air Fryer Fry Light 1400w With 42l Cooking Pan Capacity Timer Selection And Fully Adjustable Temperature Control Blacksilver Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-inalsa-air-fryer-fry-light-1400w-with-42l-cooking-pan-capacity-timer-selection-and-fully-adjustable-temperature-control-blacksilver-online-at-low-prices-in-india-amazonin/613f83d57538aa4609e31a1f' },
{ name: 'Amazonin Buy Asus Tuf Gaming F15 2020 156 Inch 3962 Cms Fhd 60hz Intel Core I5 10300h 10th Gen Nvidia Geforce Gtx 1650 4gb Graphics Gaming Laptop8gb512gb Ssdwindows 10gray23 Kg Fx566lh Bq275t Online At Low Prices In India Asus Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-asus-tuf-gaming-f15-2020-156-inch-3962-cms-fhd-60hz-intel-core-i5-10300h-10th-gen-nvidia-geforce-gtx-1650-4gb-graphics-gaming-laptop8gb512gb-ssdwindows-10gray23-kg-fx566lh-bq275t-online-at-low-prices-in-india-asus-reviews-ratings/613e11c35ef01a813b1704d1' },
{ name: 'Buy Bangcool Grow Plant 60 Leds Energy Efficient Light Bulb Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-bangcool-grow-plant-60-leds-energy-efficient-light-bulb-online-at-low-prices-in-india-amazonin/613e107f5ef01a74171704c8' },
{ name: 'Dukes Waffy Rolls Jar Chocolate 250 G Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dukes-waffy-rolls-jar-chocolate-250-g-amazonin-grocery-gourmet-foods/613ca9c233ccc913aaf47100' },
{ name: 'Maxima Max Pro X4 Smartwatch With Spo2 Up To 15 Day Battery Life 13 Round Full Touch Display With Ultra Bright Screen Of 380 Nits 10 Sports Mode Continuous Heart Rate Monitoring Unlimited Customized Watch Faces Black Silicone Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxima-max-pro-x4-smartwatch-with-spo2-up-to-15-day-battery-life-13-round-full-touch-display-with-ultra-bright-screen-of-380-nits-10-sports-mode-continuous-heart-rate-monitoring-unlimited-customized-watch-faces-black-silicone-amazonin-electronics/613ae0f59f897b37d9345c97' },
{ name: 'Jb Collection Square Black And Yellow 20045cm Lw 9sqft Approxpvc Self Adhesive Wallpaper Peel And Stick Easy To Stick And Kitchen Bedroom Livingroom Cabine Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jb-collection-square-black-and-yellow-20045cm-lw-9sqft-approxpvc-self-adhesive-wallpaper-peel-and-stick-easy-to-stick-and-kitchen-bedroom-livingroom-cabine-amazonin-home-improvement/61362ccf55038c984bce71dc' },
{ name: 'Riatech Large Size 600mm X 300mm X 2mm Speed Type Extended Gaming Mouse Pad With Stitched Embroidery Edge Premium Textured Keyboard Desk Mat For Laptopcomputer Black With Green Border Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/riatech-large-size-600mm-x-300mm-x-2mm-speed-type-extended-gaming-mouse-pad-with-stitched-embroidery-edge-premium-textured-keyboard-desk-mat-for-laptopcomputer-black-with-green-border-amazonin-computers-accessories/613ad93f39af6cdcd6361afe' },
{ name: 'Urbn 10000 Mah Li Polymer Ultra Compact Power Bank With 12w Fast Charge Camo Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/urbn-10000-mah-li-polymer-ultra-compact-power-bank-with-12w-fast-charge-camo-amazonin-electronics/6138df139c62f33ac0ad2670' },
{ name: 'Buy Vowmix Wooden Hand Cranked Collectable Engraved Music Box Happy Birthday Pack Of 1 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-vowmix-wooden-hand-cranked-collectable-engraved-music-box-happy-birthday-pack-of-1-online-at-low-prices-in-india-amazonin/61362c9855038c7446ce71d9' },
{ name: 'Buy Tosaa Non Stick 12 Cavity Appam Patra Side Handle With Lid Color May Vary Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tosaa-non-stick-12-cavity-appam-patra-side-handle-with-lid-color-may-vary-online-at-low-prices-in-india-amazonin/61362c7655038c4b73ce71d6' },
{ name: 'Vudy Exclusively Designed Wall Shelf With 5 Intersecting Decorative Living Room Shelves Black Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vudy-exclusively-designed-wall-shelf-with-5-intersecting-decorative-living-room-shelves-black-amazonin-furniture/61362c1555038c6061ce71d0' },
{ name: 'Buy Mattel Games Uno Flip Side Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-mattel-games-uno-flip-side-online-at-low-prices-in-india-amazonin/61362d2055038c2e30ce71df' },
{ name: 'Amazonin Buy Corsair Carbide Series Spec Delta Rgb Tempered Glass Mid Tower Atx Gaming Case Black Online At Low Prices In India Corsair Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-corsair-carbide-series-spec-delta-rgb-tempered-glass-mid-tower-atx-gaming-case-black-online-at-low-prices-in-india-corsair-reviews-ratings/61362d4c55038c82ffce71e2' },
{ name: 'Smart Shop Brandshoppy 6 Pcs Designing Kitchen Unbearable Plastic Masala Storage Fridge Container Utility Box Tea Coffee And Sugar Container For Kitchen Spice Jar Square Amazonin Home Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/smart-shop-brandshoppy-6-pcs-designing-kitchen-unbearable-plastic-masala-storage-fridge-container-utility-box-tea-coffee-and-sugar-container-for-kitchen-spice-jar-square-amazonin-home-kitchen/61362b4355038cfbc3ce71ca' },
{ name: 'Portronics Portable Ruffpad Re Writeable 2159cm 85 Inch Lcd Por 628 With 4 Magnet Stylus Drawing Handwriting Board Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-portable-ruffpad-re-writeable-2159cm-85-inch-lcd-por-628-with-4-magnet-stylus-drawing-handwriting-board-black-amazonin-electronics/61362baa55038cb79fce71cd' },
{ name: 'Sheesham Wooden Table Top Masala Dabba Containers Jars Cum Kitchen Spice Box With Spoon Brown Amazonin Home Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sheesham-wooden-table-top-masala-dabba-containers-jars-cum-kitchen-spice-box-with-spoon-brown-amazonin-home-kitchen/61362c2c55038cb3adce71d3' },
{ name: 'Buy Lifelong Oven Toaster Griller 23 Litres Otg Oven For Baking Cake With 4 Heating Modes Temperature Timer Selection Oven For Kitchenblack Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-lifelong-oven-toaster-griller-23-litres-otg-oven-for-baking-cake-with-4-heating-modes-temperature-timer-selection-oven-for-kitchenblack-online-at-low-prices-in-india-amazonin/61362ae755038cde2bce71c7' },
{ name: 'Buy Wipro 9 Watt B22 Wifi Enabled Smart Ns9001 Led Bulb 16 Million Colors Warm Whiteneutral Whitewhite Compatible With Amazon Alexa And Google Assistant Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-wipro-9-watt-b22-wifi-enabled-smart-ns9001-led-bulb-16-million-colors-warm-whiteneutral-whitewhite-compatible-with-amazon-alexa-and-google-assistant-online-at-low-prices-in-india-amazonin/61362a7955038ca191ce71c4' },
{ name: 'Buy Techtop Smart Study Desktable Led Lamp With Touch Rechargeable Control Light Touch Onoff Switch Desk Lamp Children Eye Protection Student Study Reading Dimmer Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-techtop-smart-study-desktable-led-lamp-with-touch-rechargeable-control-light-touch-onoff-switch-desk-lamp-children-eye-protection-student-study-reading-dimmer-online-at-low-prices-in-india-amazonin/61362a1655038c58f9ce71bc' },
{ name: 'Buy Milton Executive Lunch Box Set Of 3 Orange Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-milton-executive-lunch-box-set-of-3-orange-online-at-low-prices-in-india-amazonin/6136294555038c2259ce71b6' },
{ name: 'Buy Fujifilm Instax Mini 9 Instant Camera Ice Blue Online At Low Price In India Fujifilm Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fujifilm-instax-mini-9-instant-camera-ice-blue-online-at-low-price-in-india-fujifilm-camera-reviews-ratings-amazonin/613628b055038c967ace71b0' },
{ name: 'Trendzsmart Neck Fan Hands Free Portable 7 Blade Neck Hanging Usb Rechargeable Neckband Fan3 Speeds And 360 Degree Rotation Desk Fan For Outdoor Sport Office Multi Colour Amazonin Computers Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/trendzsmart-neck-fan-hands-free-portable-7-blade-neck-hanging-usb-rechargeable-neckband-fan3-speeds-and-360-degree-rotation-desk-fan-for-outdoor-sport-office-multi-colour-amazonin-computers-accessories/61362a3c55038c533bce71bf' },
{ name: 'Rozi Decoration Birthday Combos Diy Set Of Happy Birthday Banner Confetti Latex Balloons Blue Birthday Theme 54 Pcs Combo Amazonin Toys Games', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rozi-decoration-birthday-combos-diy-set-of-happy-birthday-banner-confetti-latex-balloons-blue-birthday-theme-54-pcs-combo-amazonin-toys-games/613629a155038c2627ce71b9' },
{ name: 'Buy Tripod Stand With Bag By Prosmart Aluminium Tripod Stand Adjustable Tripod Stand Portable And Foldable Tripod Stand Mobile Clip Camera Holder Tripod Stand Online At Low Price In India Prosmart Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tripod-stand-with-bag-by-prosmart-aluminium-tripod-stand-adjustable-tripod-stand-portable-and-foldable-tripod-stand-mobile-clip-camera-holder-tripod-stand-online-at-low-price-in-india-prosmart-camera-reviews-ratings-amazonin/6134ebde8aa0901aa037fc22' },
{ name: 'Bajaj 4 Way Spike And Surge Guard With 2m Wire 220v White Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-4-way-spike-and-surge-guard-with-2m-wire-220v-white-amazonin-electronics/6134f85931071d1cf9145dfc' },
{ name: 'Amazonin Buy Tukzer 4g Lte Wireless Usb Dongle Stick With All Sim Network Support Plug Play Data Card With Up To 150mbps Data Speed Sim Adapter Included Black Online At Low Prices In India Tukzer Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-tukzer-4g-lte-wireless-usb-dongle-stick-with-all-sim-network-support-plug-play-data-card-with-up-to-150mbps-data-speed-sim-adapter-included-black-online-at-low-prices-in-india-tukzer-reviews-ratings/6134eaff8aa0902fdc37fc16' },
{ name: 'Ojs Non Slip Yoga Mat With Shoulder Strap And Carrying Bag High Density Yoga Mats For Home Gym Outdoor Workout Black Amazonin Sports Fitness Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ojs-non-slip-yoga-mat-with-shoulder-strap-and-carrying-bag-high-density-yoga-mats-for-home-gym-outdoor-workout-black-amazonin-sports-fitness-outdoors/6134eb4f8aa09015b937fc19' },
{ name: 'Orpat Ot 512gt Calculator Black Amazonin Office Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orpat-ot-512gt-calculator-black-amazonin-office-products/6134eb7d8aa09075a737fc1c' },
{ name: 'I12 Truly Wireless Bluetooth In Ear Earphone With Mic White Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/i12-truly-wireless-bluetooth-in-ear-earphone-with-mic-white-amazonin-electronics/6134ebbf8aa0903bf337fc1f' },
{ name: 'Buy Amazon Brand Presto Toilet Cleaner 1 Liters Pack Of 2 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-presto-toilet-cleaner-1-liters-pack-of-2-online-at-low-prices-in-india-amazonin/6134e9628aa090014537fc03' },
{ name: 'Buy Bigmuscles Nutrition Premium Gold Whey 1kg Caffe Latte Whey Protein Isolate Whey Protein Concentrate 25g Protein Per Serving 0g Sugar 55g Bcaa Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-bigmuscles-nutrition-premium-gold-whey-1kg-caffe-latte-whey-protein-isolate-whey-protein-concentrate-25g-protein-per-serving-0g-sugar-55g-bcaa-online-at-low-prices-in-india-amazonin/6134e91c8aa090ea1b37fc00' },
{ name: 'Homemade Love Punjabi Mango Pickle 250 Gms Aam Ka Achar Homemade Mango Pickles Mango Achar Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/homemade-love-punjabi-mango-pickle-250-gms-aam-ka-achar-homemade-mango-pickles-mango-achar-amazonin-grocery-gourmet-foods/6134e8e98aa0904e6537fbfd' },
{ name: 'Buy Baidyanath Chyawanprash Special 1kg All Round Immunity And Protection Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-baidyanath-chyawanprash-special-1kg-all-round-immunity-and-protection-online-at-low-prices-in-india-amazonin/6134e9dc8aa090e91337fc09' },
{ name: 'Haier 258 L 2 Star Frost Free Double Door Refrigeratorhef 25tgs Grey Steelmoon Silver Convertible Amazonin Appliances', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/haier-258-l-2-star-frost-free-double-door-refrigeratorhef-25tgs-grey-steelmoon-silver-convertible-amazonin-appliances/6134eaa18aa09059ae37fc13' },
{ name: 'Nutralite Choco Spread Calcium Hazelnut Spread Uses Premium Chocolate275g Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutralite-choco-spread-calcium-hazelnut-spread-uses-premium-chocolate275g-amazonin-grocery-gourmet-foods/6134e8698aa090b79f37fbf7' },
{ name: 'Gillette Fusion Manual Razor 1 Pc Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gillette-fusion-manual-razor-1-pc-amazonin-health-personal-care/6134e9b58aa0906f5537fc06' },
{ name: 'Kelloggs Corn Flakes Real Almond Honey Breakfast Cereals High In Vitamin B1 B2 B3 B6 C High In Iron Naturally Cholesterol Free 1 Kg Pack Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-corn-flakes-real-almond-honey-breakfast-cereals-high-in-vitamin-b1-b2-b3-b6-c-high-in-iron-naturally-cholesterol-free-1-kg-pack-amazonin-grocery-gourmet-foods/6134e89e8aa09050b237fbfa' },
{ name: 'Buy Tygot 10 Portable Led Ring Light With 3 Color Modes Dimmable Lighting For Youtube Photo Shoot Video Shoot Live Stream Makeup Vlogging Compatible With Iphoneandroid Phones Cameras Online At Low Price In India Tygot Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tygot-10-portable-led-ring-light-with-3-color-modes-dimmable-lighting-for-youtube-photo-shoot-video-shoot-live-stream-makeup-vlogging-compatible-with-iphoneandroid-phones-cameras-online-at-low-price-in-india-tygot-camera-reviews-ratings-amazonin/6134e7a88aa09033d937fbf1' },
{ name: 'Amigo Ac 11 Hd Sports Action Camera With 12mp High Resolution Lens 720p Hd Image With Wide Angle Lens And Waterproof Upto 30 Meters Black Works Best With Class 10 Micro Sd Card Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amigo-ac-11-hd-sports-action-camera-with-12mp-high-resolution-lens-720p-hd-image-with-wide-angle-lens-and-waterproof-upto-30-meters-black-works-best-with-class-10-micro-sd-card-amazonin-electronics/6134e70b8aa090027f37fbee' },
{ name: 'Palay Bts Bags For Girls Gradient Cute Rabbit Ear Waterproof Backpack Maximum Capacity 55l Used For School Bag Computer Bag Gift Blue Amazonin Bags Wallets And Luggage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/palay-bts-bags-for-girls-gradient-cute-rabbit-ear-waterproof-backpack-maximum-capacity-55l-used-for-school-bag-computer-bag-gift-blue-amazonin-bags-wallets-and-luggage/6134e67d8aa09044b737fbeb' },
{ name: 'Digitek 3 Axis Handheld Gimbal Stabilizer For Smartphones Gopro With Face Object Tracking Motion Various Time Lapse Features And Upto 12 Hrs Operational Time Dsg 005 Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/digitek-3-axis-handheld-gimbal-stabilizer-for-smartphones-gopro-with-face-object-tracking-motion-various-time-lapse-features-and-upto-12-hrs-operational-time-dsg-005-amazonin-electronics/6134e8258aa0905f5c37fbf4' },
{ name: 'Buy Mi Notebook Horizon Edition 14 Intel Core I7 10510u 10th Gen Thin And Light Laptop8gb512gb Ssdwindows 10nvidia Mx350 2gb Graphicsgrey135kgwithout Webcam Xma1904 Af Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-mi-notebook-horizon-edition-14-intel-core-i7-10510u-10th-gen-thin-and-light-laptop8gb512gb-ssdwindows-10nvidia-mx350-2gb-graphicsgrey135kgwithout-webcam-xma1904-af-online-at-low-prices-in-india-amazonin/613370a98721cbb7c66cf9e4' },
{ name: 'Samsung Evo Plus 32gb Microsdhc Uhs I U1 95mbs Full Hd Memory Card With Adapter Mb Mc32ga Buy Samsung Evo Plus 32gb Microsdhc Uhs I U1 95mbs Full Hd Memory Card With Adapter Mb Mc32ga Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-32gb-microsdhc-uhs-i-u1-95mbs-full-hd-memory-card-with-adapter-mb-mc32ga-buy-samsung-evo-plus-32gb-microsdhc-uhs-i-u1-95mbs-full-hd-memory-card-with-adapter-mb-mc32ga-online-at-low-price-in-india-amazonin/6132efa99452396169004dea' },
{ name: 'Buy Fastrack Analog Silver Men Watch 3229sm03 3229sm03 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fastrack-analog-silver-men-watch-3229sm03-3229sm03-online-at-low-prices-in-india-amazonin/6134e6058aa09025bb37fbe7' },
{ name: 'Bfit Gen 1 Color Black Single Touch Unisex Smartwatch With Heart Rate Monitoring Smartphone Notifications And Upto 15 Days Active Battery Life Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bfit-gen-1-color-black-single-touch-unisex-smartwatch-with-heart-rate-monitoring-smartphone-notifications-and-upto-15-days-active-battery-life-amazonin-electronics/6134e5d98aa090d52837fbe5' },
{ name: 'Buy Retail Paratpar Jar 6 Pcs Set Pepper Salt Spice Masala Box Storage Containers Easy Flow Storage Pack Of 1 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-retail-paratpar-jar-6-pcs-set-pepper-salt-spice-masala-box-storage-containers-easy-flow-storage-pack-of-1-online-at-low-prices-in-india-amazonin/6132ef8a9452397028004de7' },
{ name: 'Mivi Duopods A25 True Wireless Earbuds Made In India Bluetooth Wireless Ear Buds With 30hours Battery Immersive Sound Quality Powerful Bass Touch Control Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mivi-duopods-a25-true-wireless-earbuds-made-in-india-bluetooth-wireless-ear-buds-with-30hours-battery-immersive-sound-quality-powerful-bass-touch-control-black-amazonin-electronics/6132eeeb945239040d004de4' },
{ name: 'Orion Choco Pie Happiness Gift Pack 560 G 20 Count Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orion-choco-pie-happiness-gift-pack-560-g-20-count-amazonin-grocery-gourmet-foods/6131f59340d14be662ae67a8' },
{ name: 'Buy Pedigree Adult Wet Dog Food Chicken Liver Chunks In Gravy 70 G Pack Of 15 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pedigree-adult-wet-dog-food-chicken-liver-chunks-in-gravy-70-g-pack-of-15-online-at-low-prices-in-india-amazonin/612fa9d3b77654bfb74394b7' },
{ name: 'Buy Stitchnest Unique Cute Elephant Cartoon Blue Printed Canvas Cotton Cushion Covers Set Of 5 12 X 12 Inches Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-stitchnest-unique-cute-elephant-cartoon-blue-printed-canvas-cotton-cushion-covers-set-of-5-12-x-12-inches-online-at-low-prices-in-india-amazonin/612fa571b7765456594393dd' },
{ name: 'Renewed Zebronics Zeb Grace Headphone With Mic With Dual 35mm Connectors For Pclaptops Adjustable Headband Mic Cushion Earcups Led Indicators Multifunction Control Pod Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-zebronics-zeb-grace-headphone-with-mic-with-dual-35mm-connectors-for-pclaptops-adjustable-headband-mic-cushion-earcups-led-indicators-multifunction-control-pod-black-amazonin-electronics/612fa3efb77654361543939f' },
{ name: 'Amazfit Bip U Pro Nyse Listed Smart Watch With Spo2 Built In Gps Built In Alexa Sleep Stress Monitor 143 Color Display Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazfit-bip-u-pro-nyse-listed-smart-watch-with-spo2-built-in-gps-built-in-alexa-sleep-stress-monitor-143-color-display-black-amazonin-electronics/612fa3a6b77654685543938c' },
{ name: 'Buy Adamo Analog Rose Gold Dial Womens Watch 839kkm21 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-adamo-analog-rose-gold-dial-womens-watch-839kkm21-at-amazonin/612fa4f8b7765469d04393bc' },
{ name: 'Buy Maybelline New York Fit Me Matteporeless Liquid Foundation Tube 128 Warm Nude 18ml Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-maybelline-new-york-fit-me-matteporeless-liquid-foundation-tube-128-warm-nude-18ml-online-at-low-prices-in-india-amazonin/612fa309b77654cdf743935c' },
{ name: 'Amazon Brand Solimo No Sugar Muesli 1kg Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-no-sugar-muesli-1kg-amazonin-grocery-gourmet-foods/612fa2aeb7765465b843933d' },
{ name: 'Kindle 10th Gen 6 Display With Built In Lightwifi Black Amazonin Amazon Devices Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kindle-10th-gen-6-display-with-built-in-lightwifi-black-amazonin-amazon-devices-accessories/612fa371b7765476a443937b' },
{ name: 'Buy Lenovo Tab M7 Tablet 7 Inch 1gb 8gb Wi Fi Only Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-lenovo-tab-m7-tablet-7-inch-1gb-8gb-wi-fi-only-online-at-low-prices-in-india-amazonin/612fa25fb7765402c343932e' },
{ name: 'Buy Mi Wi Fi 1080p Full Hd 360 Viewing Area Smart Security Camera White Online At Low Price In India Mi Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-mi-wi-fi-1080p-full-hd-360-viewing-area-smart-security-camera-white-online-at-low-price-in-india-mi-camera-reviews-ratings-amazonin/612fa218b776549bc543931f' },
{ name: 'Amazonin Buy Redgear A 10 Wired Gaming Mouse With Led Lightweight Durable And Dpi Upto 2400 For Windows Pc Gamers Online At Low Prices In India Redgear Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-redgear-a-10-wired-gaming-mouse-with-led-lightweight-durable-and-dpi-upto-2400-for-windows-pc-gamers-online-at-low-prices-in-india-redgear-reviews-ratings/612fa1bfb77654ac1f43930d' },
{ name: 'Ironfix Pack Of 2 Mobile Holder For Home Wall Charging Wall Mount Phone Holder Wall Mobile Charging Holderwall Holder For Phone Charging Stand With Holder For Mobile And Tablet Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ironfix-pack-of-2-mobile-holder-for-home-wall-charging-wall-mount-phone-holder-wall-mobile-charging-holderwall-holder-for-phone-charging-stand-with-holder-for-mobile-and-tablet-amazonin-electronics/612fa15fb77654d3624392ec' },
{ name: 'Disano High In Protein And Fibre Oats Pouch 1 Kg Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/disano-high-in-protein-and-fibre-oats-pouch-1-kg-amazonin-grocery-gourmet-foods/612da8eb372ec041f71da6f0' },
{ name: 'Skywalk Stainless Steel Strainer Kitchen Drain Basin Basket Filter Stopper Drainer Sink Jali 9 Cm Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skywalk-stainless-steel-strainer-kitchen-drain-basin-basket-filter-stopper-drainer-sink-jali-9-cm-amazonin-home-improvement/612da814372ec0530f1da6ed' },
{ name: 'Buy Adisa Bp010 Navy Blue Light Weight 32 Ltrs Casual Backpack With Rain Cover At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-adisa-bp010-navy-blue-light-weight-32-ltrs-casual-backpack-with-rain-cover-at-amazonin/612da7d111a6aa3ae9ad094a' },
{ name: 'Buy Tosaa Non Stick 7 Cavity Appam Patra With Lid 17 Cm 200 Grms Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tosaa-non-stick-7-cavity-appam-patra-with-lid-17-cm-200-grms-online-at-low-prices-in-india-amazonin/612da7b911a6aa4cc7ad0947' },
{ name: 'Sandisk Cruzer Blade 32gb Usb Flash Drive Buy Sandisk Cruzer Blade 32gb Usb Flash Drive Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sandisk-cruzer-blade-32gb-usb-flash-drive-buy-sandisk-cruzer-blade-32gb-usb-flash-drive-online-at-low-price-in-india-amazonin/612da79311a6aa4d76ad0944' },
{ name: 'Buy Timewear Timewear Casual Day Date Watch Collection For Men Analogue Mens Watchblue Dial Silver Colored Strap 231bdtg At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-timewear-timewear-casual-day-date-watch-collection-for-men-analogue-mens-watchblue-dial-silver-colored-strap-231bdtg-at-amazonin/612da6ef11a6aaf574ad093b' },
{ name: 'Buy Rupa Jon Mens Cotton Vest Jn Vest Rnwhite90largepack Of 5 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-rupa-jon-mens-cotton-vest-jn-vest-rnwhite90largepack-of-5-at-amazonin/612da64a372ec059391da6e8' },
{ name: 'Portronics Modesk Por 122 Universal Mobile Holder Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-modesk-por-122-universal-mobile-holder-black-amazonin-electronics/612da5f211a6aab5a9ad0932' },
{ name: 'Buy Amazon Brand Solimo 500 Ml Large Vegetable Chopper With 3 Blades Blue Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-solimo-500-ml-large-vegetable-chopper-with-3-blades-blue-online-at-low-prices-in-india-amazonin/612da592372ec0077c1da6e3' },
{ name: 'Buy Srk Trendz Polyresin Abstract Window Curtain 5 Feet Blue Pack Of 1 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-srk-trendz-polyresin-abstract-window-curtain-5-feet-blue-pack-of-1-online-at-low-prices-in-india-amazonin/612da49d11a6aa4549ad092f' },
{ name: 'Samsung Evo Plus 32gb Microsdhc Uhs I U1 95mbs Full Hd Memory Card With Adapter Mb Mc32ga Buy Samsung Evo Plus 32gb Microsdhc Uhs I U1 95mbs Full Hd Memory Card With Adapter Mb Mc32ga Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-32gb-microsdhc-uhs-i-u1-95mbs-full-hd-memory-card-with-adapter-mb-mc32ga-buy-samsung-evo-plus-32gb-microsdhc-uhs-i-u1-95mbs-full-hd-memory-card-with-adapter-mb-mc32ga-online-at-low-price-in-india-amazonin/612da409372ec05a0b1da6dd' },
{ name: 'Buy Youth Club Casual And Stylish Combo Of 3 Mens Day And Date Functioning Analog Watches 27scdd Combo Watch For Men At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-youth-club-casual-and-stylish-combo-of-3-mens-day-and-date-functioning-analog-watches-27scdd-combo-watch-for-men-at-amazonin/612da476372ec04cd01da6e0' },
{ name: 'Fastrack Reflex 30 Digital Black Dial Unisex Adult Watch Swd90067pp03a Amazonin Sports Fitness Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-reflex-30-digital-black-dial-unisex-adult-watch-swd90067pp03a-amazonin-sports-fitness-outdoors/612da384372ec03ee51da6da' },
{ name: 'Partystuff Cotton Hanging Planter White Pack Of 1 Amazonin Garden Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/partystuff-cotton-hanging-planter-white-pack-of-1-amazonin-garden-outdoors/612da3c411a6aade4cad092c' },
{ name: 'Buy Fostelo Womens Catlin Handbag Maroon Fsb 1033 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fostelo-womens-catlin-handbag-maroon-fsb-1033-at-amazonin/612bc08aca4cab1a98c8278d' },
{ name: 'Get Free 1 Month Audible Subscription Get Your First Audiobook Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/get-free-1-month-audible-subscription-get-your-first-audiobook-free-/6125e1c0bdd2a2039e81bb17' },
{ name: 'Philips Bt123215 Skin Friendly Beard Trimmer Durapower Technology Cordless Rechargeable With Usb Charging Charging Indicator Travel Lock No Oil Needed Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-bt123215-skin-friendly-beard-trimmer-durapower-technology-cordless-rechargeable-with-usb-charging-charging-indicator-travel-lock-no-oil-needed-amazonin-health-personal-care/612ce29fc5c84a8355146229' },
{ name: 'Orion Choco Pie Chocolate Coated Soft Biscuit 4 X 12 Piece Pack Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orion-choco-pie-chocolate-coated-soft-biscuit-4-x-12-piece-pack-amazonin-grocery-amp-gourmet-foods/611fa262cc4b7576f96944a7' },
{ name: 'Buy Rajnandini Womens Off White Chanderi Silk Embroidered Semi Stitched Salwar Suit Material With Printed Dupatta Free Size At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-rajnandini-womens-off-white-chanderi-silk-embroidered-semi-stitched-salwar-suit-material-with-printed-dupatta-free-size-at-amazonin/612ba7c2778639023d845908' },
{ name: 'Airtel Amf 311ww Data Card Black 4g Hotspot Support With 2300 Mah Battery Buy Airtel Amf 311ww Data Card Black 4g Hotspot Support With 2300 Mah Battery Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/airtel-amf-311ww-data-card-black-4g-hotspot-support-with-2300-mah-battery-buy-airtel-amf-311ww-data-card-black-4g-hotspot-support-with-2300-mah-battery-online-at-low-price-in-india-amazonin/612cbc25b5942adefde1efd5' },
{ name: 'Amazonin Buy Wacom One By Ctl 472k0 Cx Small 6 Inch X 35 Inch Graphic Tablet Redblack Online At Low Prices In India Wacom Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-wacom-one-by-ctl-472k0-cx-small-6-inch-x-35-inch-graphic-tablet-redblack-online-at-low-prices-in-india-wacom-reviews-ratings/612ba60a0728dc82ea2370c8' },
{ name: 'Juarez Jrz10hm Key C Diatonic Blues Harmonica 10 Hole 20 Tones With Case Mouth Organ For Beginners Students Kids Professionals Silver Amazonin Musical Instruments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/juarez-jrz10hm-key-c-diatonic-blues-harmonica-10-hole-20-tones-with-case-mouth-organ-for-beginners-students-kids-professionals-silver-amazonin-musical-instruments/612ba5c25ebcf1faf0541e85' },
{ name: 'Dettol Disinfectant Sanitizer Spray Bottle Kills 999 Germs Viruses Germ Kill On Hard And Soft Surfaces Original Pine Pack Of 2 225ml Each Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-disinfectant-sanitizer-spray-bottle-kills-999-germs-viruses-germ-kill-on-hard-and-soft-surfaces-original-pine-pack-of-2-225ml-each-amazonin-health-personal-care/612b6fc5fa6f03177d962b18' },
{ name: 'Amazonin Buy Honor Band 6 Meteorite Black 147 Amoled Touch Display Smart Watch Like Design 14 Days Battery Spo2 247 Heart Rate Stress Sleep Monitor Personalised Watch Faces Workout Auto Detection Online At Low Prices In India Honor Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-honor-band-6-meteorite-black-147-amoled-touch-display-smart-watch-like-design-14-days-battery-spo2-247-heart-rate-stress-sleep-monitor-personalised-watch-faces-workout-auto-detection-online-at-low-prices-in-india-honor-reviews-ratings/612ba490c4d2d083e105f437' },
{ name: 'Amazon Brand Solimo Almonds 500g Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-almonds-500g-amazonin-grocery-gourmet-foods/612b32ac68f88c3b829827fd' },
{ name: 'Gizga Essentials Usb Hub 5 In 1 3 Usb 20 2 Port Card Reader Sd Tf Data Transfer Upto 480mbps For Smartphone Macbook Chromebook Dell Other Android Devices Buy Gizga Essentials Usb Hub 5 In 1 3 Usb 20 2 Port Card Reader Sd Tf Data Transfer Upto 480mbps For Smartphone Macbook Chromebook Dell Other Android Devices Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gizga-essentials-usb-hub-5-in-1-3-usb-20-2-port-card-reader-sd-tf-data-transfer-upto-480mbps-for-smartphone-macbook-chromebook-dell-other-android-devices-buy-gizga-essentials-usb-hub-5-in-1-3-usb-20-2-port-card-reader-sd-tf-data-transfer-upto-480mbps-for-smartphone-macbook-chromebook-dell-other-android-devices-online-at-low-price-in-india-amazonin/612ba579f166774f999fb8c4' },
{ name: 'Bournvita Health Drink Jar 1 Kg Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bournvita-health-drink-jar-1-kg-amazonin-health-personal-care/6128fffd32fb093091b07483' },
{ name: 'Truke Buds S1 True Wireless In Ear Earbuds With Mic Environmental Noise Cancellationenc Quad Mems Mic For Clear Calls Up To 10hrs Of Playtime Premium Sliding Case Low Latency Bluetooth 51 Ipx4 Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/truke-buds-s1-true-wireless-in-ear-earbuds-with-mic-environmental-noise-cancellationenc-quad-mems-mic-for-clear-calls-up-to-10hrs-of-playtime-premium-sliding-case-low-latency-bluetooth-51-ipx4-amazonin-electronics/612b25afb84ee636cfd077aa' },
{ name: 'Samsung Eo Hs130dgegin Hs 1303 Wired In Ear Volume Control Handsfree With Mic Green Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-eo-hs130dgegin-hs-1303-wired-in-ear-volume-control-handsfree-with-mic-green-amazonin-electronics/6121e4df73b6ac3467b271ec' },
{ name: 'Amazonin Buy Msi Bravo 15 Ryzen 7 4800h 156quot 3962cms Fhd Gaming Laptop 16gb512gb Ssd144 Hzwindows 10 Rx5500mgddr6 4gbblack186 Kg A4ddr 212in Online At Low Prices In India Msi Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-msi-bravo-15-ryzen-7-4800h-156quot-3962cms-fhd-gaming-laptop-16gb512gb-ssd144-hzwindows-10-rx5500mgddr6-4gbblack186-kg-a4ddr-212in-online-at-low-prices-in-india-msi-reviews-amp-ratings/6121c3142d1ce2476e91417f' },
{ name: 'Amazonin Buy Lenovo Ideacentre 3 Desktop Amd Athlon Silver 3050u 23ghz Base Speed4gb1tb Hdddosamd Radeon Graphicswifi 5bluetooth 50 Mineral Grey 90mvz9khin Online At Low Prices In India Lenovo Reviews Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-lenovo-ideacentre-3-desktop-amd-athlon-silver-3050u-23ghz-base-speed4gb1tb-hdddosamd-radeon-graphicswifi-5bluetooth-50-mineral-grey-90mvz9khin-online-at-low-prices-in-india-lenovo-reviews-ratings/612a32315851140771978ef3' },
{ name: 'Nilkamal Crystal Chair Milky White Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nilkamal-crystal-chair-milky-white-amazonin-furniture/612b2d96767751093f70678c' },
{ name: 'Bikano Navratan Mixture 1kg Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikano-navratan-mixture-1kg-amazonin-grocery-gourmet-foods/6128ffaa32fb0920e3b07481' },
{ name: 'Quaker Oats Pouch 2 Kg Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/quaker-oats-pouch-2-kg-amazonin-grocery-amp-gourmet-foods/611fa88988c39310da8e88a0' },
{ name: 'Yogabar Dark Chocolate Oats 400g Wholegrain Oatmeal That Helps Reduce Cholesterol Healthy Breakfast Cereal High In Protein Amp Gluten Free Now With Black Raisins Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-dark-chocolate-oats-400g-wholegrain-oatmeal-that-helps-reduce-cholesterol-healthy-breakfast-cereal-high-in-protein-amp-gluten-free-now-with-black-raisins-amazonin-grocery-amp-gourmet-foods/611fa396cc4b756cdf69451b' },
{ name: 'Yuv Baby Boys Baby Girls T Shirt Pack Of 5 Kt5001xxskt5002xxskt5003xxsmulticolored9 12 Months Amazonin Clothing Amp Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yuv-baby-boys-baby-girls-t-shirt-pack-of-5-kt5001xxskt5002xxskt5003xxsmulticolored9-12-months-amazonin-clothing-amp-accessories/611fae4851fad82df045edca' },
{ name: 'Dhruvi Trendz Women Printed Top With Half Sleeves For Office Wear Casual Wear Under 399 Top For Womengirls Top Red Amp Multi Coloured Amp Greenlarge Amazonin Clothing Amp Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dhruvi-trendz-women-printed-top-with-half-sleeves-for-office-wear-casual-wear-under-399-top-for-womengirls-top-red-amp-multi-coloured-amp-greenlarge-amazonin-clothing-amp-accessories/611f5e0461129b75f2be49f3' },
{ name: 'Btc India Hpj Aluminum Hand Press Juicer Made In India Amazonin Home Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/btc-india-hpj-aluminum-hand-press-juicer-made-in-india-amazonin-home-kitchen/612b2edd02c96f32258a1992' },
{ name: 'Bildos Unisex Cotton Khadi Reusable Super Breathable Fabric Face Mask With Adjustable Ear Loop Pack Of 12 Amazonin Health Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bildos-unisex-cotton-khadi-reusable-super-breathable-fabric-face-mask-with-adjustable-ear-loop-pack-of-12-amazonin-health-personal-care/61265cd72af267ce4fff779d' },
{ name: 'Buy Pigeon By Stovekraft Amaze 550 Watt Mixer Grinder With 3 Stainless Steel Jars For Dry Grinding Wet Grinding And Making Chutney Dark Grey Medium 14772 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pigeon-by-stovekraft-amaze-550-watt-mixer-grinder-with-3-stainless-steel-jars-for-dry-grinding-wet-grinding-and-making-chutney-dark-grey-medium-14772-online-at-low-prices-in-india-amazonin/611f4f1edbce711ca6dd95ef' },
{ name: 'Buy Sumeet Stainless Steel Belly Shape Masala Spice Boxdabbaorganiser With See Through Lid With 7 Containers And Small Spoon Small Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-sumeet-stainless-steel-belly-shape-masala-spice-boxdabbaorganiser-with-see-through-lid-with-7-containers-and-small-spoon-small-online-at-low-prices-in-india-amazonin/6128d4ca984e7008a00a7b37' },
{ name: 'Buy Blive Kids Hooded Neck Full Sleeves T Shirt Printed T Shirt Yellow Black 11 12 Years At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-blive-kids-hooded-neck-full-sleeves-t-shirt-printed-t-shirt-yellow-black-11-12-years-at-amazonin/61265d472af2671893ff77a0' },
{ name: 'Buy Swisstone Analogue Women39s Watch Pink Dial Silver Colored Strap At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-swisstone-analogue-women39s-watch-pink-dial-silver-colored-strap-at-amazonin/611f922a1aaeb013690a90ed' },
{ name: 'Buy Tygot 10 Portable Led Ring Light With 3 Color Modes Dimmable Lighting For Youtube Photo Shoot Video Shoot Live Stream Makeup Vlogging Compatible With Iphoneandroid Phones Cameras Online At Low Price In India Tygot Camera Reviews Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tygot-10-portable-led-ring-light-with-3-color-modes-dimmable-lighting-for-youtube-photo-shoot-video-shoot-live-stream-makeup-vlogging-compatible-with-iphoneandroid-phones-cameras-online-at-low-price-in-india-tygot-camera-reviews-ratings-amazonin/6128f6ebddc5e0672a53939d' },
{ name: 'Da Urban Carex Medium Back Revolving Office Chair Black 1pc Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/da-urban-carex-medium-back-revolving-office-chair-black-1pc-amazonin-furniture/611f5e6761129b20e8be49f5' },
{ name: 'Amazonin Buy Live Tech Boss 7d Gaming Mouse With Rgb Led Light With 13 Modes Running Rgb Led Led Onoff To Switch Instant 725f Chipset Up To 7200dpi Acceleration 20g 60ips Online At Low Prices In India Live Tech Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-live-tech-boss-7d-gaming-mouse-with-rgb-led-light-with-13-modes-running-rgb-led-led-onoff-to-switch-instant-725f-chipset-up-to-7200dpi-acceleration-20g-60ips-online-at-low-prices-in-india-live-tech-reviews-amp-ratings/6121e279b48d0962228320c1' },
{ name: 'Hershey39s Exotic Dark Chocolate Pomegranate Blueberry Amp Acai Raspberry Amp Goji 100g Pack Of 3 Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hershey39s-exotic-dark-chocolate-pomegranate-blueberry-amp-acai-raspberry-amp-goji-100g-pack-of-3-amazonin-grocery-amp-gourmet-foods/6121e58373b6ac8457b271f2' },
{ name: 'Vivo V21e 5g Sunset Jazz 8gb Ram 128gb Storage With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivo-v21e-5g-sunset-jazz-8gb-ram-128gb-storage-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/611fa5d288c3937c508e87f9' },
{ name: 'Samsung Galaxy M31 Ocean Blue 6gb Ram 128gb Storage Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m31-ocean-blue-6gb-ram-128gb-storage-amazonin-electronics/611fa2d3cc4b751cd06944e1' },
{ name: 'Buy Pigeon Aluminium Pressure Cooker Without Induction Base Outer Lid Combo 3 35 5 Litre With Common Lid For 3 Litre Amp 35 Litre Separate Lid For 5 Litre Silver Medium 14784 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pigeon-aluminium-pressure-cooker-without-induction-base-outer-lid-combo-3-35-5-litre-with-common-lid-for-3-litre-amp-35-litre-separate-lid-for-5-litre-silver-medium-14784-online-at-low-prices-in-india-amazonin/611fa22ccc4b75e59469448b' },
{ name: 'Buy Timewear Commando Series Digital Analog Multifunction Silicone Strap Sports Watch For Men At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-timewear-commando-series-digital-analog-multifunction-silicone-strap-sports-watch-for-men-at-amazonin/6121e658b48d09405f8320ca' },
{ name: 'Echo Show 5 1st Gen 2019 Release Smart Speaker With Alexa 55 Screen Crisp Sound And 1mp Camera Black Amazonin Amazon Devices Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/echo-show-5-1st-gen-2019-release-smart-speaker-with-alexa-55-screen-crisp-sound-and-1mp-camera-black-amazonin-amazon-devices-accessories/6125abff5368f05058a913a4' },
{ name: 'Buy Adidas Mens Drogo M Conavysilvmttrublu Running Shoes 9 Uk Cl4155 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-adidas-mens-drogo-m-conavysilvmttrublu-running-shoes-9-uk-cl4155-at-amazonin/6125ab5b5368f03072a913a1' },
{ name: 'Buy Ibell Eo250g Premium 25 Litre 1500 Watt Electric Oven Toaster Grill Otg With Motorised Rotisserie And Convection Illuminated Chamber Black Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-ibell-eo250g-premium-25-litre-1500-watt-electric-oven-toaster-grill-otg-with-motorised-rotisserie-and-convection-illuminated-chamber-black-online-at-low-prices-in-india-amazonin/61252e06a0ec6c2a40e093e4' },
{ name: 'Tata Tea Gold 1kg Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tata-tea-gold-1kg-amazonin-grocery-amp-gourmet-foods/61252be6a0ec6cab73e093de' },
{ name: 'Bru Instant Coffee Powder Made For Blend Of Arabica And Robusta Beans With Fresh Roasted Coffee Aroma 200 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bru-instant-coffee-powder-made-for-blend-of-arabica-and-robusta-beans-with-fresh-roasted-coffee-aroma-200-g-amazonin-grocery-amp-gourmet-foods/61252c0da0ec6cecfbe093e0' },
{ name: 'Buy Pigeon By Stovekraft Mio Aluminium Gift Set Red 8 Pieces Non Induction Tawa Fry Pan Kitchen Tool Set Kadai With Glass Lid Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-pigeon-by-stovekraft-mio-aluminium-gift-set-red-8-pieces-non-induction-tawa-fry-pan-kitchen-tool-set-kadai-with-glass-lid-online-at-low-prices-in-india-amazonin/61252d90a0ec6c0baee093e2' },
{ name: 'Orion Choco Pie Chocolate Coated Soft Biscuit Happiness Pack 2 X 560 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orion-choco-pie-chocolate-coated-soft-biscuit-happiness-pack-2-x-560-g-amazonin-grocery-amp-gourmet-foods/61252baca0ec6c39e2e093dc' },
{ name: 'Kbzone Baby Girl39s Empire Knee Length Dress 1 2 Years Amazonin Clothing Amp Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kbzone-baby-girl39s-empire-knee-length-dress-1-2-years-amazonin-clothing-amp-accessories/61252a8ea0ec6c4d1ee093cb' },
{ name: 'Buy Shirtwalas Jaipuri Rajasthani Rayon Straight Kurti With Plazzo For Women Amp Girls Dress At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-shirtwalas-jaipuri-rajasthani-rayon-straight-kurti-with-plazzo-for-women-amp-girls-dress-at-amazonin/612529f9a0ec6c8667e093c8' },
{ name: 'Buy Om Sai Latest Creation Designer Slub Rayon Fully Stitched Anarkali Kurti For Women Amp Girls On Jeans Palazzo Or Skirt Plus Size Upto 2xl Beige X Large At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-om-sai-latest-creation-designer-slub-rayon-fully-stitched-anarkali-kurti-for-women-amp-girls-on-jeans-palazzo-or-skirt-plus-size-upto-2xl-beige-x-large-at-amazonin/612529c2a0ec6c33a4e093c6' },
{ name: 'Samsung Galaxy Z Flip3 5g Phantom Black 8gb Ram 128gb Storage With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-z-flip3-5g-phantom-black-8gb-ram-128gb-storage-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/6124de0af9ef12a9408b96f0' },
{ name: 'Tp Link Tl Mr100 300mbps Wireless N 4g Lte Wi Fi N300 Plug And Play Parental Controls Guest Network With Micro Sim Card Slot Wifi Router Buy Tp Link Tl Mr100 300mbps Wireless N 4g Lte Wi Fi N300 Plug And Play Parental Controls Guest Network With Micro Sim Card Slot Wifi Router Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tp-link-tl-mr100-300mbps-wireless-n-4g-lte-wi-fi-n300-plug-and-play-parental-controls-guest-network-with-micro-sim-card-slot-wifi-router-buy-tp-link-tl-mr100-300mbps-wireless-n-4g-lte-wi-fi-n300-plug-and-play-parental-controls-guest-network-with-micro-sim-card-slot-wifi-router-online-at-low-price-in-india-amazonin/6124da10f9ef1270ab8b96e7' },
{ name: 'Amazon Brand Solimo Stainless Steel Insulated Bottle 24 Hours Hot Or Cold 1000 Ml Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-stainless-steel-insulated-bottle-24-hours-hot-or-cold-1000-ml-amazonin-home-amp-kitchen/611f6dc78c9bc45b5d508f84' },
{ name: 'Buy Hikvision Full Hd 2mp 4 Cctv Camera Amp 4chfull Hd Dvr Kit All Accessories Online At Low Price In India Hikvision Camera Reviews Amp Ratings Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-hikvision-full-hd-2mp-4-cctv-camera-amp-4chfull-hd-dvr-kit-all-accessories-online-at-low-price-in-india-hikvision-camera-reviews-amp-ratings-amazonin/612376763cad5734b585966e' },
{ name: 'Verilux Usb Hub 30 For Pc 4 Port Usb Hub With Type C Otg High Speed Aluminum Type C Hub Compatible For Pc Macbook Mac Pro Mac Mini Imac Surface Pro Xps Pc Flash Drive Buy Verilux Usb Hub 30 For Pc 4 Port Usb Hub With Type C Otg High Speed Aluminum Type C Hub Compatible For Pc Macbook Mac Pro Mac Mini Imac Surface Pro Xps Pc Flash Drive Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/verilux-usb-hub-30-for-pc-4-port-usb-hub-with-type-c-otg-high-speed-aluminum-type-c-hub-compatible-for-pc-macbook-mac-pro-mac-mini-imac-surface-pro-xps-pc-flash-drive-buy-verilux-usb-hub-30-for-pc-4-port-usb-hub-with-type-c-otg-high-speed-aluminum-type-c-hub-compatible-for-pc-macbook-mac-pro-mac-mini-imac-surface-pro-xps-pc-flash-drive-online-at-low-price-in-india-amazonin/612374cd3cad57725b85966b' },
{ name: 'Amazonin Buy Hp Pavilion Gaming 156 Inch 3962 Cms Fhd Gaming Laptop Ryzen 5 4600h8gb512gb Ssdwindows 10144hznvidia Gtx 1650ti 4gbshadow Black 15 Ec1051ax Online At Low Prices In India Hp Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-hp-pavilion-gaming-156-inch-3962-cms-fhd-gaming-laptop-ryzen-5-4600h8gb512gb-ssdwindows-10144hznvidia-gtx-1650ti-4gbshadow-black-15-ec1051ax-online-at-low-prices-in-india-hp-reviews-amp-ratings/6124cd3be62760253e9cf305' },
{ name: 'Sunfeast Yippee Magic Masala70 G Count Of 12 Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sunfeast-yippee-magic-masala70-g-count-of-12-amazonin-grocery-amp-gourmet-foods/6120163e25b4325bc06ad9be' },
{ name: 'Zomato Burn With Caffeine L Carnitine Amp Chromium Improves Metabolism Energy Amp Endurance Pre Workout Amp Metabolism Booster Liquid Filled For Fast Absorption 30 Veg Capsules Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zomato-burn-with-caffeine-l-carnitine-amp-chromium-improves-metabolism-energy-amp-endurance-pre-workout-amp-metabolism-booster-liquid-filled-for-fast-absorption-30-veg-capsules-amazonin-health-amp-personal-care/612376993cad57033d859670' },
{ name: 'Safari Ray Polycarbonate 67 Cms Black Hardsided Medium Luggage Ray 67 4w Black Amazonin Bags Wallets And Luggage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/safari-ray-polycarbonate-67-cms-black-hardsided-medium-luggage-ray-67-4w-black-amazonin-bags-wallets-and-luggage/61236e7ffee5ceeedf9839aa' },
{ name: 'Surf Excel Matic Liquid Detergent Top Load Pouch 2l Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-matic-liquid-detergent-top-load-pouch-2l-amazonin-health-amp-personal-care/61235aa951961f1538aca720' },
{ name: 'Dabur Himalayan Apple Cider Vinegar With Mother Of Vinegar Raw Unfiltered Unpasteurized 500 Ml Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-himalayan-apple-cider-vinegar-with-mother-of-vinegar-raw-unfiltered-unpasteurized-500-ml-amazonin-health-amp-personal-care/612338cc1f185872bbfd217d' },
{ name: 'Eureka Forbes Aquaguard Ivory Ro Auto Uv Water Purifier With Patented Active Copper Amp Mineral Guard Technology White Amazonin Industrial Amp Scientific', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eureka-forbes-aquaguard-ivory-ro-auto-uv-water-purifier-with-patented-active-copper-amp-mineral-guard-technology-white-amazonin-industrial-amp-scientific/612311ce7be4272b6f4d69c6' },
{ name: 'Mi 11x 5g Lunar White 6gb Ram 128gb Rom Sd 870 Displaymate A Rated E4 Amoled Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mi-11x-5g-lunar-white-6gb-ram-128gb-rom-sd-870-displaymate-a-rated-e4-amoled-amazonin-electronics/611f93311aaeb073610a90f4' },
{ name: 'Buy Milton Thermosteel Flip Lid Flask 1000 Milliliters Silver Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-milton-thermosteel-flip-lid-flask-1000-milliliters-silver-online-at-low-prices-in-india-amazonin/6121eba5b48d09152c8320d4' },
{ name: 'Lifelong Llek30 Power Pro Electric Kettle 18 Litres With Stainless Steel Body Easy And Fast Boiler For Water With Water Level Indicator Soup Tea Etc 1 Year Warranty Silver Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lifelong-llek30-power-pro-electric-kettle-18-litres-with-stainless-steel-body-easy-and-fast-boiler-for-water-with-water-level-indicator-soup-tea-etc-1-year-warranty-silver-amazonin-home-amp-kitchen/6121eb02b48d0926688320d2' },
{ name: 'Buy Kidsville Mickey Amp Friends Blue Half Sleeve Boys T Shirt5 6 Years At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-kidsville-mickey-amp-friends-blue-half-sleeve-boys-t-shirt5-6-years-at-amazonin/6121e844b48d090cbe8320ce' },
{ name: 'Amazon Brand Solimo Almonds 500g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-almonds-500g-amazonin-grocery-amp-gourmet-foods/6121e780b48d09158a8320cc' },
{ name: 'Boat Rockerz 255 Pro In Ear Earphones With 40 Hours Battery Asap Charge Ipx7 Bluetooth Version 50 10mm Drivers And Voice Assistantactive Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-255-pro-in-ear-earphones-with-40-hours-battery-asap-charge-ipx7-bluetooth-version-50-10mm-drivers-and-voice-assistantactive-black-amazonin-electronics/6121e8feb48d095be98320d0' },
{ name: 'Sugar Free Dlite Rich Cocoa Dark Chocolate Bar 80g Pack Of 3 Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugar-free-dlite-rich-cocoa-dark-chocolate-bar-80g-pack-of-3-amazonin-grocery-amp-gourmet-foods/6121e5b0b48d09859a8320c8' },
{ name: 'Orbit Mixed Fruit Chewing Gum Pot Jar 2 X 132 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orbit-mixed-fruit-chewing-gum-pot-jar-2-x-132-g-amazonin-grocery-amp-gourmet-foods/6121e5f873b6ac4635b271f4' },
{ name: 'Haldirams Patisa 800 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/haldirams-patisa-800-g-amazonin-grocery-amp-gourmet-foods/6121e32573b6ac1602b271e9' },
{ name: 'Buy Kent Otg Oven Toaster Griller 20l Multifunctional Knobs Keep Warm Function Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-kent-otg-oven-toaster-griller-20l-multifunctional-knobs-keep-warm-function-online-at-low-prices-in-india-amazonin/6121e53473b6ac4de3b271f0' },
{ name: 'Buy Timex Analog Blue Men Watch Tw00zr262e At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-timex-analog-blue-men-watch-tw00zr262e-at-amazonin/6121e49fb48d0921958320c6' },
{ name: 'Buy Borosil Jumbo 1000 Watt Grill Sandwich Maker Black Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-borosil-jumbo-1000-watt-grill-sandwich-maker-black-online-at-low-prices-in-india-amazonin/6121e4fd73b6ac63abb271ee' },
{ name: 'Dukes Waffy Rolls Jar Chocolate 250 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dukes-waffy-rolls-jar-chocolate-250-g-amazonin-grocery-amp-gourmet-foods/6121e2dab48d09242a8320c4' },
{ name: 'Bikano Bikaneri Bhujia 1 Kg Amazonin Grocery Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikano-bikaneri-bhujia-1-kg-amazonin-grocery-gourmet-foods/6120169870f7d1622ff1630c' },
{ name: 'Esquire Elegant Grey 360 Spin Mop Set With Easy Wheels And Additional Refill Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/esquire-elegant-grey-360-spin-mop-set-with-easy-wheels-and-additional-refill-amazonin-home-improvement/6120152525b432705f6ad9b9' },
{ name: 'Hoffen Ho 18 Digital Electronic Lcd Personal Body Fitness Weighing Scale Black Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hoffen-ho-18-digital-electronic-lcd-personal-body-fitness-weighing-scale-black-amazonin-health-amp-personal-care/611fa53dcc4b75247c694597' },
{ name: 'Buy Romsons Ffp1 Certified Disposable Respirator White Melt Blown Filter 50 Pcs For Unisex Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-romsons-ffp1-certified-disposable-respirator-white-melt-blown-filter-50-pcs-for-unisex-online-at-low-prices-in-india-amazonin/611fa4dbcc4b75b85069456b' },
{ name: 'Buy Amazon Brand Solimo Plastic Storage Container Set 20 Pieces Checkered Pattern Black Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-solimo-plastic-storage-container-set-20-pieces-checkered-pattern-black-online-at-low-prices-in-india-amazonin/6120157425b4325e5a6ad9bc' },
{ name: 'Buy Urbangabru Charcoal Peel Off Mask Remove Blackheads Amp Whiteheads Deep Skin Purifying Cleansing 60 Gm Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-urbangabru-charcoal-peel-off-mask-remove-blackheads-amp-whiteheads-deep-skin-purifying-cleansing-60-gm-online-at-low-prices-in-india-amazonin/611fa433cc4b758096694549' },
{ name: 'Cadbury Celebrations Rich Dry Fruit Chocolate Gift Box 177 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-celebrations-rich-dry-fruit-chocolate-gift-box-177-g-amazonin-grocery-amp-gourmet-foods/611fa3dccc4b75ed35694539' },
{ name: 'Amazonin Buy Brother Dcp L2520d Multi Function Monochrome Laser Printer With Auto Duplex Printing Online At Low Prices In India Brother Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-brother-dcp-l2520d-multi-function-monochrome-laser-printer-with-auto-duplex-printing-online-at-low-prices-in-india-brother-reviews-amp-ratings/6120de9d773c5a2241f90e77' },
{ name: 'Wd 15tb Elements Portable External Hard Drive Usb 30 Compatible With Pc Ps4 Amp Xbox Wdbu6y0015bbk Wesn Buy Wd 15tb Elements Portable External Hard Drive Usb 30 Compatible With Pc Ps4 Amp Xbox Wdbu6y0015bbk Wesn Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wd-15tb-elements-portable-external-hard-drive-usb-30-compatible-with-pc-ps4-amp-xbox-wdbu6y0015bbk-wesn-buy-wd-15tb-elements-portable-external-hard-drive-usb-30-compatible-with-pc-ps4-amp-xbox-wdbu6y0015bbk-wesn-online-at-low-price-in-india-amazonin/61207f75a99e7b2ad91fb986' },
{ name: 'Boldfit Yoga Mat For Women And Men With Carry Strap Eva Material 6mm Extra Thick Exercise Mat For Workout Yoga Fitness Pilates And Meditation Anti Tear Anti Slip For Home Amp Gym Use Amazonin Sports Fitness Amp Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boldfit-yoga-mat-for-women-and-men-with-carry-strap-eva-material-6mm-extra-thick-exercise-mat-for-workout-yoga-fitness-pilates-and-meditation-anti-tear-anti-slip-for-home-amp-gym-use-amazonin-sports-fitness-amp-outdoors/611fa2a9e7d33336b70bd2df' },
{ name: 'Amazonin Buy Hp Pavilion 2021 Thin Amp Light 11th Gen Core I5 Laptop 16 Gb Ram 512gb Ssd Iris Xe Graphics 14 Inch 3556 Cms Fhd Screen Win 10 Ms Office Backlit Keyboard Natural Silver 14 Dv0054tu Online At Low Prices In India Hp Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-hp-pavilion-2021-thin-amp-light-11th-gen-core-i5-laptop-16-gb-ram-512gb-ssd-iris-xe-graphics-14-inch-3556-cms-fhd-screen-win-10-ms-office-backlit-keyboard-natural-silver-14-dv0054tu-online-at-low-prices-in-india-hp-reviews-amp-ratings/61207f37a99e7b7e791fb982' },
{ name: 'Flix Beetel Marathon M1 Slim 10000 Mah Lithium Polymer Power Bank With 2 Usb Output Amp Led Indicatortype C Input Amp Micro Usb Input Portmade In Indiapremium Build Quality Blackxpb 102s Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flix-beetel-marathon-m1-slim-10000-mah-lithium-polymer-power-bank-with-2-usb-output-amp-led-indicatortype-c-input-amp-micro-usb-input-portmade-in-indiapremium-build-quality-blackxpb-102s-amazonin-electronics/611fa31bcc4b75442c6944fd' },
{ name: 'Buy Santoor Handwash Classic 750ml 200ml 200ml Orange Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-santoor-handwash-classic-750ml-200ml-200ml-orange-online-at-low-prices-in-india-amazonin/611f92e31aaeb02d850a90f2' },
{ name: 'Steller Blaze Popular Willow Cricket Bat With Tennis Ball And Bat Cover Size 5 Amazonin Sports Fitness Amp Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/steller-blaze-popular-willow-cricket-bat-with-tennis-ball-and-bat-cover-size-5-amazonin-sports-fitness-amp-outdoors/611f675431d4cce36b4b7e14' },
{ name: 'Priority Arc 51 Cms Aqua Blue 2 Wheel Duffle Travel Luggage Amazonin Bags Wallets And Luggage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/priority-arc-51-cms-aqua-blue-2-wheel-duffle-travel-luggage-amazonin-bags-wallets-and-luggage/611f926c1aaeb07fbe0a90f0' },
{ name: 'Buy Roo Boo Baby Wet Wipes Paraben Free 99 Water Wipes 72 Pcspack Pack Of 3 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-roo-boo-baby-wet-wipes-paraben-free-99-water-wipes-72-pcspack-pack-of-3-online-at-low-prices-in-india-amazonin/611f6e4c8c9bc477a8508f86' },
{ name: 'Zebronics Zeb County Bluetooth Speaker With Built In Fm Radio Aux Input And Call Function Red Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-county-bluetooth-speaker-with-built-in-fm-radio-aux-input-and-call-function-red-amazonin-electronics/611f935e1aaeb070bb0a90f6' },
{ name: 'Cadbury Silk Rakhi Special Potli343g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-silk-rakhi-special-potli343g-amazonin-grocery-amp-gourmet-foods/611f66d831d4cc35d94b7e11' },
{ name: 'Buy Amazon Brand Solimo 12 Inch Wall Clock Victorian Bliss Silent Movement Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-amazon-brand-solimo-12-inch-wall-clock-victorian-bliss-silent-movement-online-at-low-prices-in-india-amazonin/611f6d908c9bc4445f508f82' },
{ name: 'Hathmic Cold Pressed Mustard Oil 2l Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hathmic-cold-pressed-mustard-oil-2l-amazonin-grocery-amp-gourmet-foods/611f5ed461129b22a7be49f7' },
{ name: 'Amazonin Buy Honor Band 6 Meteorite Black 1473939 Amoled Touch Display Smart Watch Like Design 14 Days Battery Spo2 247 Heart Rate Stress Amp Sleep Monitor Personalised Watch Faces Workout Auto Detection Online At Low Prices In India Honor Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-honor-band-6-meteorite-black-1473939-amoled-touch-display-smart-watch-like-design-14-days-battery-spo2-247-heart-rate-stress-amp-sleep-monitor-personalised-watch-faces-workout-auto-detection-online-at-low-prices-in-india-honor-reviews-amp-ratings/611f5c8e61129b5f07be49ec' },
{ name: 'Dji Osmo Mobile 3 3 Axis Smartphone Gimbal Handheld Stabilizer Vlog Live Video For Iphone Android Grey Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dji-osmo-mobile-3-3-axis-smartphone-gimbal-handheld-stabilizer-vlog-live-video-for-iphone-android-grey-amazonin-electronics/611f5cd161129bacedbe49ef' },
{ name: 'Hyperx Cloud Core 71 Gaming Headset For Pc Ps4 Xbox One Nintendo Switch And Mobile Devices Hx Hscc 2 Bkww Amazonin Video Games', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hyperx-cloud-core-71-gaming-headset-for-pc-ps4-xbox-one-nintendo-switch-and-mobile-devices-hx-hscc-2-bkww-amazonin-video-games/611f5d4261129b4e83be49f1' },
{ name: 'Amazonin Buy Ant Esports Gm320 Rgb Optical Wired Gaming Mouse 8 Programmable Buttons 7200 Dpi Online At Low Prices In India Ant Esports Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-ant-esports-gm320-rgb-optical-wired-gaming-mouse-8-programmable-buttons-7200-dpi-online-at-low-prices-in-india-ant-esports-reviews-amp-ratings/611f504adbce71271edd95f7' },
{ name: 'Fabelle Rakhi Gift Pack 524g Gift Box Of 4 Chocolate Bars Free Rakhi Combo Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fabelle-rakhi-gift-pack-524g-gift-box-of-4-chocolate-bars-free-rakhi-combo-amazonin-grocery-amp-gourmet-foods/611f4fccdbce71bb79dd95f5' },
{ name: 'Buy Prestige Sandwich Maker Pgmfd 01 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-prestige-sandwich-maker-pgmfd-01-online-at-low-prices-in-india-amazonin/611f4f81dbce7115cbdd95f3' },
{ name: 'Amazonin Buy Lenovo Ideapad Slim 3 2021 11th Gen Intel Core I5 156quot 3963cm Fhd Thin Amp Light Laptop 8gb512gb Ssdwindows 10ms Officebacklit Keyboard2 Year Warrantyarctic Grey165kg 82h8014bin Online At Low Prices In India Lenovo Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-lenovo-ideapad-slim-3-2021-11th-gen-intel-core-i5-156quot-3963cm-fhd-thin-amp-light-laptop-8gb512gb-ssdwindows-10ms-officebacklit-keyboard2-year-warrantyarctic-grey165kg-82h8014bin-online-at-low-prices-in-india-lenovo-reviews-amp-ratings/611f4a60dbce715994dd95e3' },
{ name: 'Omron Hem 7120 Fully Automatic Digital Blood Pressure Monitor With Intellisense Technology For Most Accurate Measurement Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/omron-hem-7120-fully-automatic-digital-blood-pressure-monitor-with-intellisense-technology-for-most-accurate-measurement-amazonin-health-amp-personal-care/611f4edddbce71245edd95ed' },
{ name: 'Eureka Forbes Quick Clean Dx 1200 Watt Vacuum Cleaner For Home With Free Reusable Dust Bag Red Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/eureka-forbes-quick-clean-dx-1200-watt-vacuum-cleaner-for-home-with-free-reusable-dust-bag-red-amazonin/611f4f59dbce7127a6dd95f1' },
{ name: 'Philips Qp252510 Cordless Oneblade Hybrid Trimmer And Shaver With 3 Trimming Combs Lime Green Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-qp252510-cordless-oneblade-hybrid-trimmer-and-shaver-with-3-trimming-combs-lime-green-amazonin-health-amp-personal-care/611f4eb8dbce71694add95eb' },
{ name: 'Happer Premium Double Supported 3 Layer Cloth Drying Stand With Breaking Wheels Prince Jumbo Orange Stainless Steel Plastic Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/happer-premium-double-supported-3-layer-cloth-drying-stand-with-breaking-wheels-prince-jumbo-orange-stainless-steel-plastic-amazonin-home-amp-kitchen/611f4b4cdbce71a4b0dd95e5' },
{ name: 'Tecno Spark 7tjewel Blue 4gb Ram 64gb Storage 6000 Mah Battery 48 Mp Ai Dual Rear Camera Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tecno-spark-7tjewel-blue-4gb-ram-64gb-storage-6000-mah-battery-48-mp-ai-dual-rear-camera-amazonin-electronics/611f493adbce7121b7dd95e0' },
{ name: 'Kadence Frontier Seriesblack Acoustic Guitar With Die Cast Keys Combo Bagstrapstrings And 3 Picks Amazonin Musical Instruments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kadence-frontier-seriesblack-acoustic-guitar-with-die-cast-keys-combo-bagstrapstrings-and-3-picks-amazonin-musical-instruments/611f0bbbc385e76d9110f236' },
{ name: 'Amazonin Buy Tukzer Capacitive Stylus Pen For Touch Screens Devices Fine Point Lightweight Metal Body With Magnetism Cover Cap For Smartphonestabletsipadipad Proiphone Grey Online At Low Prices In India Tukzer Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-tukzer-capacitive-stylus-pen-for-touch-screens-devices-fine-point-lightweight-metal-body-with-magnetism-cover-cap-for-smartphonestabletsipadipad-proiphone-grey-online-at-low-prices-in-india-tukzer-reviews-amp-ratings/611f0bf4c385e78e8510f239' },
{ name: 'Sdermatech Electric Derma Pen Needles 36 Pin Cartridges Pack Of 2 Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sdermatech-electric-derma-pen-needles-36-pin-cartridges-pack-of-2-amazonin-health-amp-personal-care/611e7a2c88bde0e8edf2e600' },
{ name: 'Buy Lights Whites E27 Led Rgb Light Bulb With Bluetooth Speaker Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-lights-whites-e27-led-rgb-light-bulb-with-bluetooth-speaker-online-at-low-prices-in-india-amazonin/611e748288bde09986f2e5c1' },
{ name: 'Buy Panasonic 7w Led Rgb Bulb Pack Of 1 Pbum11070 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-panasonic-7w-led-rgb-bulb-pack-of-1-pbum11070-online-at-low-prices-in-india-amazonin/611e73f288bde0de3cf2e5b9' },
{ name: 'Zinq Technologies Zq4h Hi Speed 4 Port Ultra Slim Usb Hub For Laptops And Computers Black Buy Zinq Technologies Zq4h Hi Speed 4 Port Ultra Slim Usb Hub For Laptops And Computers Black Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zinq-technologies-zq4h-hi-speed-4-port-ultra-slim-usb-hub-for-laptops-and-computers-black-buy-zinq-technologies-zq4h-hi-speed-4-port-ultra-slim-usb-hub-for-laptops-and-computers-black-online-at-low-price-in-india-amazonin/611df4b58a28244da1f6dfb2' },
{ name: 'Hp Ryzen 5 Quad Core 3500u 8 Gb1 Tb Hdd256 Gb Ssdwindows 10 Home 14s Dk0501au Thin And Light Laptop 14 Inch Natural Silver 151 Kg With Ms Office', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-ryzen-5-quad-core-3500u-8-gb1-tb-hdd256-gb-ssdwindows-10-home-14s-dk0501au-thin-and-light-laptop-14-inch-natural-silver-151-kg-with-ms-office/611d125f45324504470d1328' },
{ name: 'Buy Surf Excel Matic Top Load Detergent Powder 2 Kg Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-surf-excel-matic-top-load-detergent-powder-2-kg-online-at-low-prices-in-india-amazonin/611d05ed05fbd341d1bcf8c8' },
{ name: 'The Grocery Cart Orange Candy Narangee Candy Narangee Toffee Orange Toffee Orange Flavoured Toffee Pack Of 250gm Jar Pack Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/the-grocery-cart-orange-candy-narangee-candy-narangee-toffee-orange-toffee-orange-flavoured-toffee-pack-of-250gm-jar-pack-amazonin-grocery-amp-gourmet-foods/611d056d05fbd3ea34bcf8c6' },
{ name: 'Ptron Bassbuds Vista In Ear True Wireless Bluetooth 51 Headphones With Deep Bass Ipx4 Watersweat Resistant Passive Noise Cancelation Voice Assistance Amp Earbuds With Built In Hd Mic Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-bassbuds-vista-in-ear-true-wireless-bluetooth-51-headphones-with-deep-bass-ipx4-watersweat-resistant-passive-noise-cancelation-voice-assistance-amp-earbuds-with-built-in-hd-mic-black-amazonin-electronics/611e4f22c4e4140a55fba3ee' },
{ name: 'Amazonin Buy Chiptronex Kranos Rgb Backlit Gaming Keyboard Led 104 Keys Usb Ergonomic Wrist Rest Keyboard 10 Multimedia Keys 7 Rgb Color Modes Online At Low Prices In India Chiptronex Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-chiptronex-kranos-rgb-backlit-gaming-keyboard-led-104-keys-usb-ergonomic-wrist-rest-keyboard-10-multimedia-keys-7-rgb-color-modes-online-at-low-prices-in-india-chiptronex-reviews-amp-ratings/611e738288bde0c691f2e5b7' },
{ name: 'Ptron Mount St4f Adjustable Car Mount Phone Holder For Dashboard Amp Windshield 360 Rotating Clamp Telescopic Extendable Arm Strong Suction Base Amp Easy To Install Black Buy Ptron Mount St4f Adjustable Car Mount Phone Holder For Dashboard Amp Windshield 360 Rotating Clamp Telescopic Extendable Arm Strong Suction Base Amp Easy To Install Black Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-mount-st4f-adjustable-car-mount-phone-holder-for-dashboard-amp-windshield-360-rotating-clamp-telescopic-extendable-arm-strong-suction-base-amp-easy-to-install-black-buy-ptron-mount-st4f-adjustable-car-mount-phone-holder-for-dashboard-amp-windshield-360-rotating-clamp-telescopic-extendable-arm-strong-suction-base-amp-easy-to-install-black-online-at-low-price-in-india-amazonin/611e49805c331e1e140f9bc7' },
{ name: 'Vivo Y12s Phantom Black 3gb 32gb With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vivo-y12s-phantom-black-3gb-32gb-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/611d04fb05fbd398cabcf8c4' },
{ name: 'Nokia G20 Smartphone Dual Sim 4g 4gb Ram64gb Storage 48mp Quad Camera With 65 1651 Cm Screen Blue Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nokia-g20-smartphone-dual-sim-4g-4gb-ram64gb-storage-48mp-quad-camera-with-65-1651-cm-screen-blue-amazonin/611d04d005fbd35ebfbcf8c2' },
{ name: 'Cello Signature Ball Pen And Rakhi Giftset Special Raksha Bandhan Gift Pack Stylish Ball Pen With A Rakhi For Brother Amazonin Office Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-signature-ball-pen-and-rakhi-giftset-special-raksha-bandhan-gift-pack-stylish-ball-pen-with-a-rakhi-for-brother-amazonin-office-products/611cc97af6602b85e813b867' },
{ name: 'Minimalist 10 Vitamin C Face Serum For Glowing Skin 30 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/minimalist-10-vitamin-c-face-serum-for-glowing-skin-30-ml/611bd9a0c10e130f96f86b4b' },
{ name: 'Exciting Offers On Mens Womenss Wear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/exciting-offers-on-mens-womenss-wear/611bd97dc10e130f96f86b49' },
{ name: 'Deals On Kitchen Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deals-on-kitchen-products/611bd936c10e130f96f86b45' },
{ name: 'Wow Skin Science Brightening Vitamin C Foaming Face Wash With Built In Face Brush For Deep Cleansing No Parabens Sulphate Silicones Color 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-brightening-vitamin-c-foaming-face-wash-with-built-in-face-brush-for-deep-cleansing-no-parabens-sulphate-silicones-color-100ml/611bd95ac10e130f96f86b47' },
{ name: 'Mamaearth Aloe Vera Gel For Face 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mamaearth-aloe-vera-gel-for-face-300ml/611bd913c10e130f96f86b43' },
{ name: 'Deals On Kitchen Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deals-on-kitchen-products/611bd8f0c10e130f96f86b41' },
{ name: 'Minimalist 2 Salicylic Acid Serum For Acne Blackheads Op', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/minimalist-2-salicylic-acid-serum-for-acne-blackheads-op/611bd8cdc10e130f96f86b3f' },
{ name: 'Great Offers On Jiwa', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/great-offers-on-jiwa/611bd8aac10e130f96f86b3d' },
{ name: 'Min 40 Off On Prh Must Reads', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/min-40-off-on-prh-must-reads/611bd887c10e130f96f86b3b' },
{ name: 'Comfort After Wash Fabric Conditioner Pouch 2 L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/comfort-after-wash-fabric-conditioner-pouch-2-l/611bd863c10e130f96f86b39' },
{ name: 'Colgate Strong Teeth Anticavity Toothpaste With Amino Shakti 500gm Saver Pack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/colgate-strong-teeth-anticavity-toothpaste-with-amino-shakti-500gm-saver-pack/611bd81cc10e130f96f86b31' },
{ name: 'Cmf Blue Po4 4150g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cmf-blue-po4-4150g/611bd840c10e130f96f86b33' },
{ name: 'Up To 40 Off On Games Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/up-to-40-off-on-games-accessories/611bd7d0c10e130f96f86b2f' },
{ name: 'Upto 40 Off On Amazara Baby Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/upto-40-off-on-amazara-baby-products/611bd741c10e130f96f86b27' },
{ name: 'Surf Excel Matic Top Load Detergent Powder 3 Kg 1 Kg Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-matic-top-load-detergent-powder-3-kg-1-kg-free/611bd71ec10e130f96f86b25' },
{ name: 'Wecool Bluetooth Extendable Selfie Sticks With Wireless Remote And Tripod Stand 3 In 1 Multifunctional Selfie Stick With Tripod Stand Compatible With Iphoneoneplussamsungoppovivo And All Phones', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wecool-bluetooth-extendable-selfie-sticks-with-wireless-remote-and-tripod-stand-3-in-1-multifunctional-selfie-stick-with-tripod-stand-compatible-with-iphoneoneplussamsungoppovivo-and-all-phones/611bd6d8c10e130f96f86b21' },
{ name: 'Mamaearth Vitamin C Face Wash 100ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mamaearth-vitamin-c-face-wash-100ml/611bd6fbc10e130f96f86b23' },
{ name: 'Iqoo 7 Starting 31990 Sd 870 5g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/iqoo-7-starting-31990-sd-870-5g/611bd6b5c10e130f96f86b1f' },
{ name: 'Upto 40 Off On Socks Belts Wallets On Creature', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/upto-40-off-on-socks-belts-wallets-on-creature/611bd692c10e130f96f86b1d' },
{ name: '40 Off On Childrens Early Learing Books From Dreamland', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/40-off-on-childrens-early-learing-books-from-dreamland/611bd66fc10e130f96f86b1b' },
{ name: 'Surf Excel Matic Top Load Detergent Powder 2 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-matic-top-load-detergent-powder-2-kg/611bd64bc10e130f96f86b19' },
{ name: 'Comfort After Wash Morning Fresh Fabric Conditioner 860 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/comfort-after-wash-morning-fresh-fabric-conditioner-860-ml/611bd628c10e130f96f86b17' },
{ name: 'Mcaffeine Naked Raw Coffee Body Scrub 100 G Coconut T', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mcaffeine-naked-raw-coffee-body-scrub-100-g-coconut-t/611bd7acc10e130f96f86b2d' },
{ name: 'Best Offers On Atun Kids Clothing', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-offers-on-atun-kids-clothing/611bd789c10e130f96f86b2b' },
{ name: 'Wow Skin Science Apple Cider Vinegar Foaming Face Wash No Parabens Sulphate Silicones With Built In Brush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-apple-cider-vinegar-foaming-face-wash-no-parabens-sulphate-silicones-with-built-in-brush/611bd765c10e130f96f86b29' },
{ name: 'Up To 5 Off On Top School Books', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/up-to-5-off-on-top-school-books/611bd59cc10e130f96f86b0d' },
{ name: 'Deals On Kitchen Products', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/deals-on-kitchen-products/611bd555c10e130f96f86b09' },
{ name: '10 Off On Premium Tea Brand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/10-off-on-premium-tea-brand/611bd532c10e130f96f86b07' },
{ name: 'Wow Skin Science Onion Red Seed Oil Shampoo 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-onion-red-seed-oil-shampoo-300ml/611bd50fc10e130f96f86b05' },
{ name: '15 Off On Premium Coffee Brand', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/15-off-on-premium-coffee-brand/611bd578c10e130f96f86b0b' },
{ name: 'Wow Skin Science Aloe Vera Gel 150 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-aloe-vera-gel-150-ml/611bd5bfc10e130f96f86b0f' },
{ name: 'Wow Skin Science Apple Cider Vinegar Shampoo 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-skin-science-apple-cider-vinegar-shampoo-300ml/611bd605c10e130f96f86b15' },
{ name: 'Godrej Aer Pocket Assorted Pack 50 G Pack Of 5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-aer-pocket-assorted-pack-50-g-pack-of-5/611bd5e2c10e130f96f86b13' },
{ name: 'Upto 70 Off On Suitcases Duffles Backpacks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/upto-70-off-on-suitcases-duffles-backpacks/611bd4ecc10e130f96f86b03' },
{ name: 'Preethi Zodiac Cosmo Mg236 Mixer Grinder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/preethi-zodiac-cosmo-mg236-mixer-grinder/611bd4a5c10e130f96f86af9' },
{ name: 'Boult Audio Probass Qcharge In Ear Earphones With Fast Charging 24h Battery Life In Built Mic Ipx5 Water Resistant Neckband Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boult-audio-probass-qcharge-in-ear-earphones-with-fast-charging-24h-battery-life-in-built-mic-ipx5-water-resistant-neckband-black/611bd482c10e130f96f86af7' },
{ name: 'Mi 11x Just Launched Snapdragon 870', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mi-11x-just-launched-snapdragon-870/611bd4c8c10e130f96f86aff' },
{ name: 'Best Deals On Prestige', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-deals-on-prestige/611bd228c10e130f96f86ab5' },
{ name: 'Mamaearth Vitamin C Face Wash With Foaming Silicone Cleanser', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mamaearth-vitamin-c-face-wash-with-foaming-silicone-cleanser/611bd272c10e130f96f86ac1' },
{ name: 'Timewear Timewear Formal Day Date Watch Collection For Men Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/timewear-timewear-formal-day-date-watch-collection-for-men-watch/611bd2b9c10e130f96f86acb' },
{ name: 'Ross Hair Scalp Massager Shampoo Brush', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ross-hair-scalp-massager-shampoo-brush/611bd204c10e130f96f86aa7' },
{ name: 'Genteel Liquid Detergent 1kg 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/genteel-liquid-detergent-1kg-1kg/611bd1a4c10e130f96f86aa3' },
{ name: 'Listerine Cool Mint Mouthwash 250ml Buy 2 Get 1 Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/listerine-cool-mint-mouthwash-250ml-buy-2-get-1-free/611bd1c7c10e130f96f86aa5' },
{ name: 'Mcaffeine Naked And Raw Coffee Body Polishing Olive Oil For', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mcaffeine-naked-and-raw-coffee-body-polishing-olive-oil-for/611bd296c10e130f96f86ac9' },
{ name: 'Best Deals On Gardening Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-deals-on-gardening-outdoors/611bd24bc10e130f96f86ab7' },
{ name: 'Samsung Galaxy M32 6 Months Free Screen Replacement For Prime', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m32-6-months-free-screen-replacement-for-prime/611bd181c10e130f96f86aa1' },
{ name: 'Oneplus Nord Ce 5g Buy Now', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-nord-ce-5g-buy-now/611bd15ec10e130f96f86a9f' },
{ name: 'Vim Dishwash Gel Lemon 18 L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vim-dishwash-gel-lemon-18-l/611bd13ac10e130f96f86a9d' },
{ name: 'Smart Bulb', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/smart-bulb/611bd117c10e130f96f86a97' },
{ name: 'Mamaearth Anti Hair Fall Spa Range With Onion Hair Oil', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mamaearth-anti-hair-fall-spa-range-with-onion-hair-oil/611bd0f4c10e130f96f86a91' },
{ name: 'Hammer Earphones Starting From 1232', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hammer-earphones-starting-from-1232/611bd0d1c10e130f96f86a8b' },
{ name: 'Best Deals On Trukewecool', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-deals-on-trukewecool/611bd0aec10e130f96f86a89' },
{ name: 'Samsung Galaxy M31 6 Months Free Screen Replacement For Prime', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m31-6-months-free-screen-replacement-for-prime/611bd08bc10e130f96f86a87' },
{ name: 'Vim Dishwash Liquid Gel Lemon Refill Pouch 2 Ltr', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vim-dishwash-liquid-gel-lemon-refill-pouch-2-ltr/611bd068c10e130f96f86a85' },
{ name: 'Surf Excel Matic Front Load Detergent Powder 3 Kg 1 Kg Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-matic-front-load-detergent-powder-3-kg-1-kg-free/611bd044c10e130f96f86a83' },
{ name: 'Asgard Melt Blown Fabric Disposable Face Mask With Nose Clip', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asgard-melt-blown-fabric-disposable-face-mask-with-nose-clip/611bd021c10e130f96f86a81' },
{ name: 'Best Deals From Quarant Masks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-deals-from-quarant-masks/611bcffec10e130f96f86a7f' },
{ name: 'Realme C11 2021 Cool Grey Buy Now', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-c11-2021-cool-grey-buy-now/611bcf95c10e130f96f86a79' },
{ name: 'Cases And Covers Starting From 149', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cases-and-covers-starting-from-149/611bcfb8c10e130f96f86a7b' },
{ name: 'Save Upto 60 Off On Surgical Masks', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/save-upto-60-off-on-surgical-masks/611bcf6fc10e130f96f86a77' },
{ name: 'Attractive Offers On Urban Platter', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/attractive-offers-on-urban-platter/611bcfdbc10e130f96f86a7d' },
{ name: 'Wecool Moonwalk Mini Earbuds With Magnetic Charging Case Ipx5 Wireless Earphones With Digital Battery Indicator For Crisp Sound Bluetooth Earphones For Secure Sports Fit', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wecool-moonwalk-mini-earbuds-with-magnetic-charging-case-ipx5-wireless-earphones-with-digital-battery-indicator-for-crisp-sound-bluetooth-earphones-for-secure-sports-fit/611bcd74c10e130f96f86a75' },
{ name: 'Comfort After Wash Morning Fresh Fabric Conditioner Pouch 2 L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/comfort-after-wash-morning-fresh-fabric-conditioner-pouch-2-l/611bcd2dc10e130f96f86a71' },
{ name: 'Surf Excel Easy Wash Detergent Powder 4 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-easy-wash-detergent-powder-4-kg/611bcd50c10e130f96f86a73' },
{ name: 'Cases And Cover Starting From 149', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cases-and-cover-starting-from-149/611bcd09c10e130f96f86a6f' },
{ name: 'Redmi Note 10 48mp Quad Camera Amoled Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-10-48mp-quad-camera-amoled-display/611bcce6c10e130f96f86a6d' },
{ name: 'Best Price On Sounce Smartwatch Pc Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/best-price-on-sounce-smartwatch-pc-accessories/611bccc3c10e130f96f86a6b' },
{ name: 'Realme C11 2021 Buy Now', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-c11-2021-buy-now/611bcc7cc10e130f96f86a67' },
{ name: 'Oneplus Nord 2 5g Buy Now', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-nord-2-5g-buy-now/611bcb81c10e130f96f86a63' },
{ name: 'Oneplus Nord Ce 5g Buy Now', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oneplus-nord-ce-5g-buy-now/611bcca0c10e130f96f86a69' },
{ name: 'Samsung Galaxy M21 6 Months Free Screen Replacement For Prime', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-galaxy-m21-6-months-free-screen-replacement-for-prime/611bcb3ac10e130f96f86a5f' },
{ name: 'Redmi Note 10 48mp Quad Camera Amoled Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-10-48mp-quad-camera-amoled-display/611bcb16c10e130f96f86a5d' },
{ name: 'Surf Excel Matic Liquid Detergent 2 Litre Pouch Front Load', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/surf-excel-matic-liquid-detergent-2-litre-pouch-front-load/611bcb5ec10e130f96f86a61' },
{ name: 'Redmi Note 10 48mp Quad Camera Amoled Display', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-10-48mp-quad-camera-amoled-display/611b99fcc10e130f96f869bf' },
{ name: 'Buy Kaameri Bazaar Birthday Bhaidooj Rakhi Gift Combo For Sister 12quot X 12quot Cushion Cover With Filler Printed Coffee Mug Printed Key Ring Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-kaameri-bazaar-birthday-bhaidooj-rakhi-gift-combo-for-sister-12quot-x-12quot-cushion-cover-with-filler-printed-coffee-mug-printed-key-ring-online-at-low-prices-in-india-amazonin/611a5c9b4853798ecbd58266' },
{ name: 'Iqoo Z3 5g Cyber Blue 8gb Ram 128gb Storage India39s First Sd 768g 5g Processor 55w Flashcharge Upto 9 Months No Cost Emi 6 Months Free Screen Replacement Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/iqoo-z3-5g-cyber-blue-8gb-ram-128gb-storage-india39s-first-sd-768g-5g-processor-55w-flashcharge-upto-9-months-no-cost-emi-6-months-free-screen-replacement-amazonin-electronics/611a6a794853799e7ad583d7' },
{ name: 'Buy Fossil Analog Gold Women Watch Bq3181 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fossil-analog-gold-women-watch-bq3181-at-amazonin/611b8be12019780ee5f7a6f8' },
{ name: 'Buy The Purple Tree Porcelain Mug Rakhimg00013 330 Ml Multicolour Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-the-purple-tree-porcelain-mug-rakhimg00013-330-ml-multicolour-online-at-low-prices-in-india-amazonin/611a5c13b21bddf68826166b' },
{ name: 'Cadbury Silk Rakhi Special Potli343g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-silk-rakhi-special-potli343g-amazonin-grocery-amp-gourmet-foods/611a56c25889f757759a5ab4' },
{ name: 'Chaayos Premium Gift Box Darjeeling Green Tea 6 Natural Flavours Infuser Mug Spoon Amp Coaster Rakhi Gift Immunity Gift Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chaayos-premium-gift-box-darjeeling-green-tea-6-natural-flavours-infuser-mug-spoon-amp-coaster-rakhi-gift-immunity-gift-amazonin-grocery-amp-gourmet-foods/611a5c68485379a296d58258' },
{ name: 'Amazonin Buy Hp Pavilion 2021 Thin Amp Light 11th Gen Core I5 Laptop 16 Gb Ram 512gb Ssd Iris Xe Graphics 14 Inch 3556 Cms Fhd Screen Win 10 Ms Office Backlit Keyboard Tranquil Pink 14 Dv0055tu Online At Low Prices In India Hp Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-hp-pavilion-2021-thin-amp-light-11th-gen-core-i5-laptop-16-gb-ram-512gb-ssd-iris-xe-graphics-14-inch-3556-cms-fhd-screen-win-10-ms-office-backlit-keyboard-tranquil-pink-14-dv0055tu-online-at-low-prices-in-india-hp-reviews-amp-ratings/611a566e5889f787d09a5ab0' },
{ name: 'Realme Narzo 20 Glory Sliver 4gb Ram 128gb Storage With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-narzo-20-glory-sliver-4gb-ram-128gb-storage-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/611a55bba565f0c8e5c14183' },
{ name: 'Ptron Studio Over Ear Bluetooth 50 Wireless Headphones With Mic Hi Fi Sound With Deep Bass 12hrs Playback Ergonomic Amp Lightweight Wireless Headset Soft Cushions Earpads Aux Port Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-studio-over-ear-bluetooth-50-wireless-headphones-with-mic-hi-fi-sound-with-deep-bass-12hrs-playback-ergonomic-amp-lightweight-wireless-headset-soft-cushions-earpads-aux-port-black-amazonin-electronics/611a56235889f712c99a5aab' },
{ name: 'Buy Tied Ribbons Rakhi For Brother With Gifts Premium Rakhi And Coffee Mug Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tied-ribbons-rakhi-for-brother-with-gifts-premium-rakhi-and-coffee-mug-online-at-low-prices-in-india-amazonin/611a56ac5889f709249a5ab2' },
{ name: 'Ptron Tangent Lite Bluetooth 50 Wireless Headphones With Hi Fi Stereo Sound 8hrs Playtime Lightweight Ergonomic Neckband Sweat Resistant Magnetic Earbuds Voice Assistant Amp Mic Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ptron-tangent-lite-bluetooth-50-wireless-headphones-with-hi-fi-stereo-sound-8hrs-playtime-lightweight-ergonomic-neckband-sweat-resistant-magnetic-earbuds-voice-assistant-amp-mic-black-amazonin-electronics/611a56075ea3a1c82b638ebc' },
{ name: 'Redmi Note 9 Aqua Green 4gb Ram 64gb Storage 48mp Quad Camera Amp Full Hd Display Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-9-aqua-green-4gb-ram-64gb-storage-48mp-quad-camera-amp-full-hd-display-amazonin-electronics/611a5530f1612b4f538bb691' },
{ name: 'Boat Rockerz 255 In Ear Earphones With 8 Hours Battery Ipx5 Bluetooth V50 And Voice Assistantactive Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-255-in-ear-earphones-with-8-hours-battery-ipx5-bluetooth-v50-and-voice-assistantactive-black-amazonin-electronics/611a54d1f1612b4f538bb68f' },
{ name: 'Dabur Red Paste 500g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-red-paste-500g/611a34983367414da4f1168d' },
{ name: 'Pantry Sunfeast Marie Light Active Bag 1 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-sunfeast-marie-light-active-bag-1-kg/611a34753367414da4f1168b' },
{ name: 'Pantry Dukes Waffy Biscuits Chocolate 75g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-dukes-waffy-biscuits-chocolate-75g/611a34523367414da4f11689' },
{ name: 'Pantry Catch Turmeric Powder 500g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-catch-turmeric-powder-500g/611a342e3367414da4f11687' },
{ name: 'Pantry Sunfeast Dark Fantasy Choco Fills Plus Coffee Fills Combo 75g Buy 3 Get 1 Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-sunfeast-dark-fantasy-choco-fills-plus-coffee-fills-combo-75g-buy-3-get-1-free/611a340b3367414da4f11685' },
{ name: 'Pantry Nature Fresh Sampoorna Chakki Atta 10kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-nature-fresh-sampoorna-chakki-atta-10kg/611a33e83367414da4f11683' },
{ name: 'Strontium Nitro A1 64gb Micro Sdxc Memory Card 100mbs A1 Uhs I U3 Class 10 With High Speed Adapter For Smartphones Tablets Drones Action Cams Srn64gtfu3a1a Buy Strontium Nitro A1 64gb Micro Sdxc Memory Card 100mbs A1 Uhs I U3 Class 10 With High Speed Adapter For Smartphones Tablets Drones Action Cams Srn64gtfu3a1a Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strontium-nitro-a1-64gb-micro-sdxc-memory-card-100mbs-a1-uhs-i-u3-class-10-with-high-speed-adapter-for-smartphones-tablets-drones-action-cams-srn64gtfu3a1a-buy-strontium-nitro-a1-64gb-micro-sdxc-memory-card-100mbs-a1-uhs-i-u3-class-10-with-high-speed-adapter-for-smartphones-tablets-drones-action-cams-srn64gtfu3a1a-online-at-low-price-in-india-amazonin/611a5570f1612b4f538bb695' },
{ name: 'Samsung Evo Plus 64gb Microsdxc Uhs I 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc64ha Buy Samsung Evo Plus 64gb Microsdxc Uhs I 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc64ha Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-64gb-microsdxc-uhs-i-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc64ha-buy-samsung-evo-plus-64gb-microsdxc-uhs-i-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc64ha-online-at-low-price-in-india-amazonin/611a5559f1612b4f538bb693' },
{ name: 'Pantry Tata Sampann Thick Poha 500g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-tata-sampann-thick-poha-500g/611a33c53367414da4f11681' },
{ name: 'Realme C11 2021 Cool Blue 2gb Ram 32gb Storage With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-c11-2021-cool-blue-2gb-ram-32gb-storage-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/611a5592f1612b4f538bb697' },
{ name: 'Systene Dual Port Rapid Car Charger Qualcomm Certified With Quick Charge 30 Intelligent Lcd Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/systene-dual-port-rapid-car-charger-qualcomm-certified-with-quick-charge-30-intelligent-lcd-black/611a33383367414da4f11679' },
{ name: 'Pantry Ponds Light Moisturiser Non Oily With Vitamin E And Glycerine For Soft And Glowing Skin 250 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-ponds-light-moisturiser-non-oily-with-vitamin-e-and-glycerine-for-soft-and-glowing-skin-250-ml/611a33153367414da4f11677' },
{ name: 'Pantry Elina Rice Long Grain 5kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-elina-rice-long-grain-5kg/611a32f13367414da4f11675' },
{ name: 'Size 8 United Colors Of Benetton Men Sneakers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-united-colors-of-benetton-men-sneakers/611a32ce3367414da4f11673' },
{ name: 'Pantry Aashirvaad Select Premium Sharbati Atta 5kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-aashirvaad-select-premium-sharbati-atta-5kg/611a32ab3367414da4f11671' },
{ name: 'Pantry Pril Perfect Active Lime Grease Fighter 750 Ml Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-pril-perfect-active-lime-grease-fighter-750-ml-green/611a32643367414da4f1166d' },
{ name: 'Pantry Sanifresh Ultrashine 1l 500 500 Toilet Cleaner 15x Extra Strong Extra Clean With Odonil Room Freshner Blocks 50 G Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-sanifresh-ultrashine-1l-500-500-toilet-cleaner-15x-extra-strong-extra-clean-with-odonil-room-freshner-blocks-50-g-free/611a32883367414da4f1166f' },
{ name: 'Debelle Fairness Talc Combo Pack Of 2 400g Each', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/debelle-fairness-talc-combo-pack-of-2-400g-each/611a32413367414da4f1166b' },
{ name: 'Pantry Dukeswaffy Biscuits Strawberry 75g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-dukeswaffy-biscuits-strawberry-75g/611a321e3367414da4f11669' },
{ name: 'Pantry Goodknight Naturals Neem Mosquito Repellent Refill Pack Of 2 90 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-goodknight-naturals-neem-mosquito-repellent-refill-pack-of-2-90-ml/611a33a23367414da4f1167f' },
{ name: 'Maybelline New York Color Sensational Creamy Matte Lipstick 695 Divine Wine 39g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maybelline-new-york-color-sensational-creamy-matte-lipstick-695-divine-wine-39g/611a335b3367414da4f1167b' },
{ name: 'Biotique Bio Papaya Visibly Flawless Skin Face Wash For All Skin Types 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-bio-papaya-visibly-flawless-skin-face-wash-for-all-skin-types-150ml/611a337e3367414da4f1167d' },
{ name: 'Goldmedal Essenza 09211 Plastic Plugged In 6x1 Spike Guard White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/goldmedal-essenza-09211-plastic-plugged-in-6x1-spike-guard-white/611a31d73367414da4f11665' },
{ name: 'Whisper Ultra Clean Sanitary Pads', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/whisper-ultra-clean-sanitary-pads/611a31fa3367414da4f11667' },
{ name: 'Sparx Mens Sm 631 Sneaker', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sparx-mens-sm-631-sneaker/611a31493367414da4f1165d' },
{ name: 'Hangit Cotton Swing Chair Ocean Blue 50 Centimeters', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hangit-cotton-swing-chair-ocean-blue-50-centimeters/611a31263367414da4f1165b' },
{ name: 'Pantry Pepsodent Germicheck Toothpaste 150 G Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-pepsodent-germicheck-toothpaste-150-g-pack-of-2/611a31903367414da4f11661' },
{ name: 'Pantry Whisper Choice Ultra 20 Extra Large Sanitary Pads For Women', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-whisper-choice-ultra-20-extra-large-sanitary-pads-for-women/611a30e03367414da4f11657' },
{ name: 'Stayfree Secure Xl Ultra Thin Sanitary Napkins With Wings Extra Large 30 Count With Dry Max All Night Sanitary', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/stayfree-secure-xl-ultra-thin-sanitary-napkins-with-wings-extra-large-30-count-with-dry-max-all-night-sanitary/611a31033367414da4f11659' },
{ name: 'Wipro Garnet 20 Watt Led Batten Pack Of 4 Cool Day Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-garnet-20-watt-led-batten-pack-of-4-cool-day-light/611a316d3367414da4f1165f' },
{ name: 'Havells Leganza 3 Blade 1200mm Ceiling Fan Pearl White Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/havells-leganza-3-blade-1200mm-ceiling-fan-pearl-white-silver/611a31b43367414da4f11663' },
{ name: 'Softouch Tissue Paper Napkin 100 Pcs Set Of 5 30 Cm X 30 Cm Multicolor', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/softouch-tissue-paper-napkin-100-pcs-set-of-5-30-cm-x-30-cm-multicolor/611a30993367414da4f11655' },
{ name: 'Size 9 Amazon Brand Symbol Mens Slide Sandal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-9-amazon-brand-symbol-mens-slide-sandal/611a30533367414da4f11651' },
{ name: 'Relaxo Plus Unisex Kids Beige Brown Slippers 11 Uk 29 Eu Cu0016cbgbr0011', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/relaxo-plus-unisex-kids-beige-brown-slippers-11-uk-29-eu-cu0016cbgbr0011/611a30763367414da4f11653' },
{ name: 'Orient Electric Miracle Mgmr75s3 750 Watts High Performance Motor With 3 Jars And 2 Storage Jars Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-miracle-mgmr75s3-750-watts-high-performance-motor-with-3-jars-and-2-storage-jars-grey/611a2ce93367414da4f1164d' },
{ name: 'Ld Skybags Cardiff Polyester 55 Cms Red Travel Duffle Dfcar55ered', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-skybags-cardiff-polyester-55-cms-red-travel-duffle-dfcar55ered/611a2ba3ae75824bdd46a8a8' },
{ name: 'Size 40 Neostreak Mens Casual Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-40-neostreak-mens-casual-shirt/611a28ff4de32925e33e4c74' },
{ name: 'Zebronics Zeb Corolla In Ear Wired Earphone With Mic 35mm Jack 12 Meter Cable Multi Function Button', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-corolla-in-ear-wired-earphone-with-mic-35mm-jack-12-meter-cable-multi-function-button/611a28dc4de32925e33e4c72' },
{ name: 'Arha Iinternational Jumbo Size Extremely Light Weight Water Proof Cottage Of Love Kids Play Tent House', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/arha-iinternational-jumbo-size-extremely-light-weight-water-proof-cottage-of-love-kids-play-tent-house/611a28724de32925e33e4c6c' },
{ name: 'Dukes Truffel Butter Scotch 135 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dukes-truffel-butter-scotch-135-g/611a28b94de32925e33e4c70' },
{ name: 'Fingerprint Cabinet Lock Smart Biometric Cabinet Lock Keyless Hidden File Drawer Wardrobe Lock Child Safety Electric Fingerprint Lock Diy Wooden Furniture Privacy Lock', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fingerprint-cabinet-lock-smart-biometric-cabinet-lock-keyless-hidden-file-drawer-wardrobe-lock-child-safety-electric-fingerprint-lock-diy-wooden-furniture-privacy-lock/611a284f4de32925e33e4c6a' },
{ name: 'Size M Diverse Men Boxer Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-diverse-men-boxer-shorts/611a30303367414da4f1164f' },
{ name: 'Bajaj Majesty Dx 11 1000w Dry Iron With Advance Soleplate And Anti Bacterial German Coating Technology White And Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bajaj-majesty-dx-11-1000w-dry-iron-with-advance-soleplate-and-anti-bacterial-german-coating-technology-white-and-blue/611a28964de32925e33e4c6e' },
{ name: 'Outgeek Usb Portable Foldable Standing Up Handheld Humidify Mist Rechargeable Battery Table Desk Fan With Night Light Air Humidifier And 3 Speeds With Rope Hole', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/outgeek-usb-portable-foldable-standing-up-handheld-humidify-mist-rechargeable-battery-table-desk-fan-with-night-light-air-humidifier-and-3-speeds-with-rope-hole/611a282c4de32925e33e4c68' },
{ name: 'Mayukh Premium Green Tea Darjeeling Tea Black Tea Loose Tea Leaves 100 Pure Darjeeling Tea 100 Gms 40 Cups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mayukh-premium-green-tea-darjeeling-tea-black-tea-loose-tea-leaves-100-pure-darjeeling-tea-100-gms-40-cups/611a28094de32925e33e4c66' },
{ name: 'Signoraware Shake N Shake Steel Sports Shaker With Blending Ball And Whisking Wheel 650ml Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/signoraware-shake-n-shake-steel-sports-shaker-with-blending-ball-and-whisking-wheel-650ml-black/611a27e64de32925e33e4c64' },
{ name: 'Motul 8100 X Max 0w40 Api Sn Fully Synthetic Gasoline And Diesel Engine Oil 4000 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/motul-8100-x-max-0w40-api-sn-fully-synthetic-gasoline-and-diesel-engine-oil-4000-ml/611a27c34de32925e33e4c62' },
{ name: 'French Connection Analog Dial Womens Watch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/french-connection-analog-dial-womens-watch/611a27564de32925e33e4c5c' },
{ name: 'Pinnaclz Original Made In India Usb Type C Fast Charging Cable Usb C Data Cable For Data Transfer Perfect For Mi Samsung Smart Phones White 3 Feet', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pinnaclz-original-made-in-india-usb-type-c-fast-charging-cable-usb-c-data-cable-for-data-transfer-perfect-for-mi-samsung-smart-phones-white-3-feet/611a279f4de32925e33e4c60' },
{ name: 'Sugr By Unlimited Women Jeans', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sugr-by-unlimited-women-jeans/611a26524de32925e33e4c5a' },
{ name: 'Auto Pearl Yellow And Blue Matty Bike Body Cover With Mirror Pockets Buckle Belt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/auto-pearl-yellow-and-blue-matty-bike-body-cover-with-mirror-pockets-buckle-belt/611a262f4de32925e33e4c58' },
{ name: 'Oshotto Portable High Pressure Foot Air Pump Heavy Compressor Cylinder Compatible With Maruti Wagonr 2019 2021', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oshotto-portable-high-pressure-foot-air-pump-heavy-compressor-cylinder-compatible-with-maruti-wagonr-2019-2021/611a260c4de32925e33e4c56' },
{ name: 'Seaking Bullet Brass Long Body Bib Taps With Wall Flange Chrome Chrome Finish', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/seaking-bullet-brass-long-body-bib-taps-with-wall-flange-chrome-chrome-finish/611a25c54de32925e33e4c52' },
{ name: 'Ld Swisstone Analogue Womens Watch Black Dial Black Colored Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-swisstone-analogue-womens-watch-black-dial-black-colored-strap/611a25384de32925e33e4c4a' },
{ name: 'Zollyss Portable Digital 50 Kg Weighing Scale With Metal Hook Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zollyss-portable-digital-50-kg-weighing-scale-with-metal-hook-black/611a25e84de32925e33e4c54' },
{ name: 'Ld Flybot Bolt Rugged Polyester Braided Unbreakable Micro Usb Fast Charging Cable Length 15 Meter Color Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-flybot-bolt-rugged-polyester-braided-unbreakable-micro-usb-fast-charging-cable-length-15-meter-color-grey/611a25154de32925e33e4c48' },
{ name: 'Afast Funky Stylish Pendant Hanging Ceiling Lamp Dx12', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/afast-funky-stylish-pendant-hanging-ceiling-lamp-dx12/611a24f24de32925e33e4c46' },
{ name: 'Dove Dryness Care Conditioner 180ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dove-dryness-care-conditioner-180ml/611a24cf4de32925e33e4c44' },
{ name: 'Nutriglow Wild Turmeric Natural Face Wash For Dry Skin Tan Removal And Skin Brightning For Women Glowing Skin Repair Sun Damage Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nutriglow-wild-turmeric-natural-face-wash-for-dry-skin-tan-removal-and-skin-brightning-for-women-glowing-skin-repair-sun-damage-pack-of-3/611a24ac4de32925e33e4c42' },
{ name: 'Huggies Premium Soft Pants Extra Small Size Diaper Pants 90 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/huggies-premium-soft-pants-extra-small-size-diaper-pants-90-count/611a24654de32925e33e4c3e' },
{ name: 'Donut Baby Girls Regular Legging Doigleg10013n01navy06m', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/donut-baby-girls-regular-legging-doigleg10013n01navy06m/611a24894de32925e33e4c40' },
{ name: 'Klenza Alcohol Free Hand Sanitizer With Moisturizer 50 Ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/klenza-alcohol-free-hand-sanitizer-with-moisturizer-50-ml-pack-of-2/611a21c64de32925e33e4c36' },
{ name: 'Activex Australia Ivy Digital Body Weight Bathroom Scale With Step On Technology High Precision Measurements With Free Activex Armband Mobile Holder Night Sky', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/activex-australia-ivy-digital-body-weight-bathroom-scale-with-step-on-technology-high-precision-measurements-with-free-activex-armband-mobile-holder-night-sky/611a241f4de32925e33e4c3a' },
{ name: 'Pantry Colgate Vedshakti Mouth Protect Spray 10ml Instant Germ Kill With Breath Freshener Saunf Flavor Contains Clove Neem Lemon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-colgate-vedshakti-mouth-protect-spray-10ml-instant-germ-kill-with-breath-freshener-saunf-flavor-contains-clove-neem-lemon/611a028f4de32925e33e4c2a' },
{ name: 'Mayukh Tea Masala Tea Indian Masala Chai Milk Tea Loose Tea Leaves 100 Gms 40 Cups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mayukh-tea-masala-tea-indian-masala-chai-milk-tea-loose-tea-leaves-100-gms-40-cups/611a02d44de32925e33e4c2c' },
{ name: 'Pantry Colgate Swarna Vedshakti Ayurvedic Toothpaste 400g Saver Pack With Neem Clove Honey Anti Germ Properties For Whole Mouth Protection', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-colgate-swarna-vedshakti-ayurvedic-toothpaste-400g-saver-pack-with-neem-clove-honey-anti-germ-properties-for-whole-mouth-protection/611a024e4de32925e33e4c28' },
{ name: 'Ld Boult Audio Bassbuds Storm X In Ear Wired Earphones With Mic And Full Metal Body', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-boult-audio-bassbuds-storm-x-in-ear-wired-earphones-with-mic-and-full-metal-body/611a018a4de32925e33e4c24' },
{ name: 'Size S Fusefit Womens Slim Fit Vest', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-fusefit-womens-slim-fit-vest/611a00464de32925e33e4c20' },
{ name: 'Size S M L Amazon Brand Inkast Denim Co Mens Regular Fit T Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-m-l-amazon-brand-inkast-denim-co-mens-regular-fit-t-shirt/611a020d4de32925e33e4c26' },
{ name: 'Xmate Cruise 3 Axis Handheld Smartphone Gimbal Black Object Tracking Zoom Capability Video Edit Share Support 8 Hours Battery Life', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/xmate-cruise-3-axis-handheld-smartphone-gimbal-black-object-tracking-zoom-capability-video-edit-share-support-8-hours-battery-life/611a01494de32925e33e4c22' },
{ name: 'Prolet Universal Mobile Stand For Table With Adjustable Height 360 Degree Rotation Mobile Holder', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prolet-universal-mobile-stand-for-table-with-adjustable-height-360-degree-rotation-mobile-holder/6119ffc34de32925e33e4c1c' },
{ name: 'Mom World Repair Renew Youth Firming Night Cream With Vitamin C Retinol For Smooth Bright And Younger Looking Skin 50 G Momwld41', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mom-world-repair-renew-youth-firming-night-cream-with-vitamin-c-retinol-for-smooth-bright-and-younger-looking-skin-50-g-momwld41/611a00054de32925e33e4c1e' },
{ name: 'Ancy Foods Indian Raisins Kishmish Long Size And Sweet 2 X 250 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ancy-foods-indian-raisins-kishmish-long-size-and-sweet-2-x-250-g/6119ff414de32925e33e4c18' },
{ name: 'Womens Jacket Starts From Rs 501', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/womens-jacket-starts-from-rs-501-/6119febf4de32925e33e4c14' },
{ name: 'Amazon Brand Solimo Coconut Water Enriched With Vitamin C 200ml Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-coconut-water-enriched-with-vitamin-c-200ml-pack-of-6/6119ff824de32925e33e4c1a' },
{ name: 'Larah By Borosil Tiara Series Rose Gold 21 Pcs Opalware Dinner Set White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/larah-by-borosil-tiara-series-rose-gold-21-pcs-opalware-dinner-set-white/6119fe3c4de32925e33e4c10' },
{ name: 'Ancy Indian Green Raisins Kishmish Long Size And Sweet 1 Kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ancy-indian-green-raisins-kishmish-long-size-and-sweet-1-kg/6119fe7d4de32925e33e4c12' },
{ name: 'Zebronics Zeb Bro In Ear Wired Earphones With Mic 35mm Audio Jack 10mm Drivers Phonetablet Compatible', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-bro-in-ear-wired-earphones-with-mic-35mm-audio-jack-10mm-drivers-phonetablet-compatible/6119fdfb4de32925e33e4c0e' },
{ name: 'Dettol Disinfectant Skin Surface Wipes Original 10 Count Safe On Skin Ideal To Clean Multiple Surfaces Resealable Lock Lid', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dettol-disinfectant-skin-surface-wipes-original-10-count-safe-on-skin-ideal-to-clean-multiple-surfaces-resealable-lock-lid/6119fd794de32925e33e4c0a' },
{ name: 'Maycreate Toothbrush Heads Compatible Electric Toothbrush Replacement Brush Headfor Oral Eb Toothbrush Head With 16pcs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maycreate-toothbrush-heads-compatible-electric-toothbrush-replacement-brush-headfor-oral-eb-toothbrush-head-with-16pcs/6119fdba4de32925e33e4c0c' },
{ name: 'Live Tech Wireless 24g Optical Mouse With 12 Months Battery Life Ambidextrous Usb Receiver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/live-tech-wireless-24g-optical-mouse-with-12-months-battery-life-ambidextrous-usb-receiver/6119fd374de32925e33e4c08' },
{ name: 'Hitage Earphones Hp 315 Hd Sound Deep Extra Bass Wired Earphone With Mic Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hitage-earphones-hp-315-hd-sound-deep-extra-bass-wired-earphone-with-mic-orange/6119fcf54de32925e33e4c06' },
{ name: 'Lacto Calamine Sunshield Matte Look Sunscreen Spf50 Pa For Oily Or Acne Prone Skin Paraben Sulphate Free 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lacto-calamine-sunshield-matte-look-sunscreen-spf50-pa-for-oily-or-acne-prone-skin-paraben-sulphate-free-50g/6119fc6a4de32925e33e4c04' },
{ name: 'Brewvin Wheatgrass Aloe Vera Juice 1l Detoxifying Naturally Neutraceutical Juice Organically Harvested Zero Added Sugar', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/brewvin-wheatgrass-aloe-vera-juice-1l-detoxifying-naturally-neutraceutical-juice-organically-harvested-zero-added-sugar/6119fc294de32925e33e4c02' },
{ name: 'Simparte Ifc 235 Induction Base Aluminium Pressure Cooker 2 Litres 3 Litres Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/simparte-ifc-235-induction-base-aluminium-pressure-cooker-2-litres-3-litres-silver/6119fbe84de32925e33e4c00' },
{ name: 'Cosmic Byte Equinox Phobos 71 Rgb Dual Input Usb35mm Gaming Headset With Detachable Microphone', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cosmic-byte-equinox-phobos-71-rgb-dual-input-usb35mm-gaming-headset-with-detachable-microphone/6119fb654de32925e33e4bfc' },
{ name: 'Lacto Calamine Daily Cleansing Face Wipes With Aloe Vera Cucumber And Vitamin E 25 Wipes Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lacto-calamine-daily-cleansing-face-wipes-with-aloe-vera-cucumber-and-vitamin-e-25-wipes-pack-of-3/6119fba64de32925e33e4bfe' },
{ name: 'Live Tech Bold Gaming Mouse With Customizable 6 Rgb Lighting Mode Onoff 7 Programmable Buttons Gaming Grade Sensor 6400 Dpi Tracking Light Weight Ergonomic Design', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/live-tech-bold-gaming-mouse-with-customizable-6-rgb-lighting-mode-onoff-7-programmable-buttons-gaming-grade-sensor-6400-dpi-tracking-light-weight-ergonomic-design/6119fb244de32925e33e4bfa' },
{ name: 'Lakm Enrich Lip Crayon Red Stop 22g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-enrich-lip-crayon-red-stop-22g/6119fae24de32925e33e4bf8' },
{ name: 'Faces Canada Ultime Pro Heavenly Lashes Mascara Black 12 G Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/faces-canada-ultime-pro-heavenly-lashes-mascara-black-12-g-black/6119faa04de32925e33e4bf6' },
{ name: 'Kitchen Mate Black Aluminium Saucepantea Pan 1 Litre', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kitchen-mate-black-aluminium-saucepantea-pan-1-litre/6119fa5f4de32925e33e4bf4' },
{ name: 'Size S Amazon Brand Symbol Men Boxer Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-amazon-brand-symbol-men-boxer-shorts/6119f9dc4de32925e33e4bf0' },
{ name: 'Garnier Micellar Pink 125 Ml With Maybelline Creamy Matte Lipstick Rich Ruby 39 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/garnier-micellar-pink-125-ml-with-maybelline-creamy-matte-lipstick-rich-ruby-39-g/6119f99b4de32925e33e4bee' },
{ name: 'Size L Amazon Brand Symbol Men Sweatshirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-amazon-brand-symbol-men-sweatshirt/6119f95a4de32925e33e4bec' },
{ name: 'Hitage Champ Super Sound Bass With Hd Clear Sound Wired Headset Black Wired In The Ear 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hitage-champ-super-sound-bass-with-hd-clear-sound-wired-headset-black-wired-in-the-ear-1/6119f9194de32925e33e4bea' },
{ name: 'Ld Wolpin Wall Stickers Diy Wallpaper Decal 45 X 500 Cm 3d Brick Self Adhesive House Office Decorative Pvc Vinyl Living Room Hall Kitchen Shelf Liner Contact Paper Interior Smokin Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-wolpin-wall-stickers-diy-wallpaper-decal-45-x-500-cm-3d-brick-self-adhesive-house-office-decorative-pvc-vinyl-living-room-hall-kitchen-shelf-liner-contact-paper-interior-smokin-red/6119f8d84de32925e33e4be8' },
{ name: 'Tri Activ 6 Layer Protective Face Masks Pm25 N95 Tested As Per Niosh Standard Anti Virus Coating 995 Filtration Efficiency Black Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tri-activ-6-layer-protective-face-masks-pm25-n95-tested-as-per-niosh-standard-anti-virus-coating-995-filtration-efficiency-black-pack-of-2/6119f8554de32925e33e4be4' },
{ name: 'Ld Amazon Brand Presto Squeegee Wiper For Kitchen Platform Top And Glass', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-amazon-brand-presto-squeegee-wiper-for-kitchen-platform-top-and-glass/6119f8964de32925e33e4be6' },
{ name: 'Bikaji Bhujia No 1 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-bhujia-no-1-1kg/6119f8134de32925e33e4be2' },
{ name: 'Bikaji Rasgulla Gol Matol 125kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-rasgulla-gol-matol-125kg/6119f7d24de32925e33e4be0' },
{ name: 'Mcaffeine Coffee Mood Skin Care Gift Kit Tan Removal Deep Cleanser Body Scrub Face Wash Face Scrub Face Mask Paraben Sls Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mcaffeine-coffee-mood-skin-care-gift-kit-tan-removal-deep-cleanser-body-scrub-face-wash-face-scrub-face-mask-paraben-sls-free/6119f74f4de32925e33e4bdc' },
{ name: 'Ld Bombay Shaving Company Charcoal De Tan Glow Kit Rakhi Gift', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-bombay-shaving-company-charcoal-de-tan-glow-kit-rakhi-gift/6119f70b4de32925e33e4bda' },
{ name: 'Bikaji Papad Baat Cheet 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-papad-baat-cheet-1kg/6119f7904de32925e33e4bde' },
{ name: 'Bikaji Kesar Baati 125kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bikaji-kesar-baati-125kg/6119f5964de32925e33e4bd8' },
{ name: 'Fastrack Uv Protected Square Mens Sunglasses P357bk141 Millimeterssmoke Greyblack', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fastrack-uv-protected-square-mens-sunglasses-p357bk141-millimeterssmoke-greyblack/6119f5544de32925e33e4bd6' },
{ name: 'Mam Originals Analogue Mens Watch Black Dial Black Colored Strap', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mam-originals-analogue-mens-watch-black-dial-black-colored-strap/6119f5124de32925e33e4bd4' },
{ name: 'Tata Tea Premium 1500 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tata-tea-premium-1500-g/6119eef34de32925e33e4bcc' },
{ name: 'Mivi Collar Flash Bluetooth Earphones Fast Charging Wireless Earphones With Mic 24hrs Battery Life Hd Sound Powerful Bass Made In India Neckband Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mivi-collar-flash-bluetooth-earphones-fast-charging-wireless-earphones-with-mic-24hrs-battery-life-hd-sound-powerful-bass-made-in-india-neckband-black/6119eef24de32925e33e4bca' },
{ name: 'Fun Homes Foldable Non Woven Mens Coat Blazer Cover Maroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fun-homes-foldable-non-woven-mens-coat-blazer-cover-maroon/6119eef24de32925e33e4bc2' },
{ name: 'Tauro By Zing Kashmir Willow Cricket Full Kit For Boys Multicolour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tauro-by-zing-kashmir-willow-cricket-full-kit-for-boys-multicolour/6119eef24de32925e33e4bc6' },
{ name: 'Chennai Super Kings Fragrance Body Spray 175 Ml Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/chennai-super-kings-fragrance-body-spray-175-ml-pack-of-2/6119eef24de32925e33e4bc8' },
{ name: 'Kaya Youth Oxy Infusion Face Serum Boosts Skin Oxygen Intense Skin Replenishment 50 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kaya-youth-oxy-infusion-face-serum-boosts-skin-oxygen-intense-skin-replenishment-50-ml/6119eef14de32925e33e4bb8' },
{ name: 'Size S Amazon Brand House Shields Men Boxers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-s-amazon-brand-house-shields-men-boxers/6119eef24de32925e33e4bc4' },
{ name: 'Ld Krishna White Floor Cleaner 1x3 Liter Pack Of 31 Unit Free', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-krishna-white-floor-cleaner-1x3-liter-pack-of-31-unit-free/6119eef14de32925e33e4bc0' },
{ name: 'Signoraware Modular Steel Container Round 10001500ml2000ml6100ml Set Of 4 Red', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/signoraware-modular-steel-container-round-10001500ml2000ml6100ml-set-of-4-red/6119eef14de32925e33e4bbe' },
{ name: ' Xmate Quik Cordless Trimmer 120 Min Runtime', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/-xmate-quik-cordless-trimmer-120-min-runtime/6119eef14de32925e33e4bba' },
{ name: 'Soundmagic P10s Headphones With Mic Blackred', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/soundmagic-p10s-headphones-with-mic-blackred/6119eef14de32925e33e4bbc' },
{ name: 'Amazon Brand Symbol Mens Striped Ankle Socks Pack Of 3', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-symbol-mens-striped-ankle-socks-pack-of-3/6119eef04de32925e33e4bae' },
{ name: 'Ld Yogabar Crunchy Peanut Butter 1kg Dark Chocolate Peanut Butter With High Protein Anti Oxidants Creamy Crunchy Chocolatey Non Gmo Vegan Peanut Butter Contains No Palm Oil Or Preservatives', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-yogabar-crunchy-peanut-butter-1kg-dark-chocolate-peanut-butter-with-high-protein-anti-oxidants-creamy-crunchy-chocolatey-non-gmo-vegan-peanut-butter-contains-no-palm-oil-or-preservatives/6119eef04de32925e33e4bb4' },
{ name: 'Amazon Brand Presto Dish Wash Gel 750 Ml Pack Of 4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-presto-dish-wash-gel-750-ml-pack-of-4/6119eef04de32925e33e4bb6' },
{ name: 'Gainda Toilet Cleaner Liquid Disinfectant Bowl Pot Cleanser Safe Hygienic Bathroom Stain 1 L', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gainda-toilet-cleaner-liquid-disinfectant-bowl-pot-cleanser-safe-hygienic-bathroom-stain-1-l/6119eef04de32925e33e4bb0' },
{ name: 'Quercetti Geoville Toolbox Scatola', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/quercetti-geoville-toolbox-scatola/6119eef04de32925e33e4bac' },
{ name: 'Kitchen Treaures Golden Turmeric Milk 250g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kitchen-treaures-golden-turmeric-milk-250g/6119eeef4de32925e33e4baa' },
{ name: 'Ambrane Aux 11 15m Aux Cable Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ambrane-aux-11-15m-aux-cable-black/6119eef04de32925e33e4bb2' },
{ name: 'Sampla Tucana Series Ceramic Coffee Mugs 6 Pieces Matt Black 250 Ml Random Inside Colour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sampla-tucana-series-ceramic-coffee-mugs-6-pieces-matt-black-250-ml-random-inside-colour/6119eeef4de32925e33e4ba2' },
{ name: 'Baltra Glimmer Glass Top 4 Burner Gas Stove Manual Ignition Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/baltra-glimmer-glass-top-4-burner-gas-stove-manual-ignition-black/6119eeef4de32925e33e4ba0' },
{ name: 'Size 7 Flavia Womens Fashion Sandals', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-7-flavia-womens-fashion-sandals/6119eeef4de32925e33e4ba4' },
{ name: 'Yogabar Dark Chocolate Peanut Butter Creamy Chocolatey Slow Roasted Non Gmo Premium Peanuts 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/yogabar-dark-chocolate-peanut-butter-creamy-chocolatey-slow-roasted-non-gmo-premium-peanuts-1kg/6119eeef4de32925e33e4ba8' },
{ name: 'Parachute Advansed Aloe Vera Enriched Coconut Hair Oil 250ml Free 75ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/parachute-advansed-aloe-vera-enriched-coconut-hair-oil-250ml-free-75ml/6119eeef4de32925e33e4ba6' },
{ name: 'Envias Leatherette Latest Handbags For Womens Ladies Combo Of 4 Babypinkprintstripevs 118', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/envias-leatherette-latest-handbags-for-womens-ladies-combo-of-4-babypinkprintstripevs-118/6119eeee4de32925e33e4b9a' },
{ name: 'Envias Leatherette Latest Handbags For Womens Ladies Combo Of 4 Blackprintstripevs 119 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/envias-leatherette-latest-handbags-for-womens-ladies-combo-of-4-blackprintstripevs-119-2/6119eeee4de32925e33e4b94' },
{ name: 'Dabur Himalayan Apple Cider Vinegar With Mother Of Vinegar Raw Unfiltered 500 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-himalayan-apple-cider-vinegar-with-mother-of-vinegar-raw-unfiltered-500-ml/6119eeee4de32925e33e4b96' },
{ name: 'Size 8 Elise Women Sneakers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-elise-women-sneakers/6119eeee4de32925e33e4b9c' },
{ name: 'Savya Home Apex Chairs Delta Mb Chair Umbrella Base Office Chair Standard Black Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savya-home-apex-chairs-delta-mb-chair-umbrella-base-office-chair-standard-black-amazonin-furniture/611942bdf8d2225eb64d5949' },
{ name: 'Azani Active Nutrition Plant Mass Gainer 1kg 1083 Kcal 45g Vegan Protein 225g Carbohydrates 05g Chlorella 03g Ashwagandha Intense Muscle Recovery Weight Gainer No Bloating Natural Digestive Enzymes Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/azani-active-nutrition-plant-mass-gainer-1kg-1083-kcal-45g-vegan-protein-225g-carbohydrates-05g-chlorella-03g-ashwagandha-intense-muscle-recovery-weight-gainer-no-bloating-natural-digestive-enzymes-amazonin-health-amp-personal-care/611941e7f8d222bc564d5947' },
{ name: 'Cello Opalware Dazzle Tropical Lagoon Dinner Set 18pcs White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-opalware-dazzle-tropical-lagoon-dinner-set-18pcs-white/6119eeee4de32925e33e4b98' },
{ name: 'Size Xxl Gm Full Length Cricket Trousers 7130 White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-xxl-gm-full-length-cricket-trousers-7130-white/6119eeee4de32925e33e4b9e' },
{ name: 'Amazon Brand Solimo Almonds 500g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-almonds-500g-amazonin-grocery-amp-gourmet-foods/61194174f8d222528f4d5945' },
{ name: 'Cub Mcpaws Girls Green Double Waterfall Ruffle Dress 4 12 Years Green 7years Amazonin Clothing Amp Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cub-mcpaws-girls-green-double-waterfall-ruffle-dress-4-12-years-green-7years-amazonin-clothing-amp-accessories/61190a26149991da937d43a9' },
{ name: 'Buy Dimpy Garments Cotton Blend Knee Long Striped Women39s Dungaree Dress With Top Navy Medium At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-dimpy-garments-cotton-blend-knee-long-striped-women39s-dungaree-dress-with-top-navy-medium-at-amazonin/61190a0614999104f67d43a7' },
{ name: 'Samsung Evo Plus 128gb Microsdxc Uhs I U3 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc128ha Buy Samsung Evo Plus 128gb Microsdxc Uhs I U3 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc128ha Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-128gb-microsdxc-uhs-i-u3-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc128ha-buy-samsung-evo-plus-128gb-microsdxc-uhs-i-u3-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc128ha-online-at-low-price-in-india-amazonin/611903dc149991d0247d439b' },
{ name: 'Tronica Dual 65quot Thunder Vibra Party Speaker With Two Karoke Mics Amp Vivid Light Effects Supports Bluetoothusbsd Cardfm Amp Mobilepclaptop Or Any Given Aux Source 6 Hours Non Stop Play Back Price Buy Tronica Dual 65quot Thunder Vibra Party Speaker With Two Karoke Mics Amp Vivid Light Effects Supports Bluetoothusbsd Cardfm Amp Mobilepclaptop Or Any Given Aux Source 6 Hours Non Stop Play Back Online In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tronica-dual-65quot-thunder-vibra-party-speaker-with-two-karoke-mics-amp-vivid-light-effects-supports-bluetoothusbsd-cardfm-amp-mobilepclaptop-or-any-given-aux-source-6-hours-non-stop-play-back-price-buy-tronica-dual-65quot-thunder-vibra-party-speaker-with-two-karoke-mics-amp-vivid-light-effects-supports-bluetoothusbsd-cardfm-amp-mobilepclaptop-or-any-given-aux-source-6-hours-non-stop-play-back-online-in-india-amazonin/6119074a149991b6547d439d' },
{ name: 'Buy Tied Ribbons Rakhi For Brother With Gifts Premium Rakhi And Coffee Mug Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-tied-ribbons-rakhi-for-brother-with-gifts-premium-rakhi-and-coffee-mug-online-at-low-prices-in-india-amazonin/611900c645475bb45b75bb76' },
{ name: 'Buy Park Avenue Beer Shampoo Damage Free 650 Ml 650 Ml Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-park-avenue-beer-shampoo-damage-free-650-ml-650-ml-online-at-low-prices-in-india-amazonin/6118b8414fc5b839b89c44d9' },
{ name: 'Amazon Brand Solimo Germ Protect Handwash Liquid 750 Ml Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-solimo-germ-protect-handwash-liquid-750-ml-amazonin-health-amp-personal-care/6118b8002ef55322017f5ddc' },
{ name: 'Buy Jhingalala Designer Rakhi With Roli Chawal Bro I Love You Printed Mug And Greeting Card Combo Gift Pack Gift For Rakshabandhan For Brother Rakhi Gift For Brother Raksha Bandhan Gift For Brother Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-jhingalala-designer-rakhi-with-roli-chawal-bro-i-love-you-printed-mug-and-greeting-card-combo-gift-pack-gift-for-rakshabandhan-for-brother-rakhi-gift-for-brother-raksha-bandhan-gift-for-brother-online-at-low-prices-in-india-amazonin/6119018214999134497d4393' },
{ name: 'Buy Urban Forest Zeus Rfid Blocking Vintage Brown Leather Wallet Amp Rakhi Combo Gift Set For Brother At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-urban-forest-zeus-rfid-blocking-vintage-brown-leather-wallet-amp-rakhi-combo-gift-set-for-brother-at-amazonin/6119013b149991f3717d4391' },
{ name: 'Cadbury Silk Rakhi Special Potli343g Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-silk-rakhi-special-potli343g-amazonin-grocery-amp-gourmet-foods/6119010f14999133237d438f' },
{ name: 'Jbl Cinema Sb231 21 Channel Dolby Digital Soundbar With Wired Subwoofer For Deep Bass Home Theatre With Remote Hdmi Arc Bluetooth Amp Optical Connectivity 110w Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jbl-cinema-sb231-21-channel-dolby-digital-soundbar-with-wired-subwoofer-for-deep-bass-home-theatre-with-remote-hdmi-arc-bluetooth-amp-optical-connectivity-110w-amazonin-electronics/6118aef7612e036b8abdfda3' },
{ name: 'Tronica Cowin Series 71 Channel Home Theatre System Bluetooth Usbfm Sd Rca Inputsaux Led Tv Supported 4 Inch Active Subwoofer 3 Passive Radiator Vivid Lights Wireless Remote Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/tronica-cowin-series-71-channel-home-theatre-system-bluetooth-usbfm-sd-rca-inputsaux-led-tv-supported-4-inch-active-subwoofer-3-passive-radiator-vivid-lights-wireless-remote-amazonin-electronics/6118dde9556f163f4ef2d06c' },
{ name: 'Collectible India Raksha Bandhan Gift Hamper For Brother With Ganesha Wall Hanging Showpiece With 4 Rakhi Krishna Rakhi Rudraksh Rakhi Lumba Rakhi With Colorful Greeting Card Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/collectible-india-raksha-bandhan-gift-hamper-for-brother-with-ganesha-wall-hanging-showpiece-with-4-rakhi-krishna-rakhi-rudraksh-rakhi-lumba-rakhi-with-colorful-greeting-card-amazonin-home-amp-kitchen/6118aeab612e03650fbdfda1' },
{ name: 'Buy Skybags Brat Black 46 Cms Casual Backpack At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-skybags-brat-black-46-cms-casual-backpack-at-amazonin/6118ae1e612e03d418bdfd9d' },
{ name: 'Mi Original 27w Superfast Charging Adapter 2021 Edition Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mi-original-27w-superfast-charging-adapter-2021-edition-amazonin-electronics/6118adbe612e0318e7bdfd99' },
{ name: 'Jabra Elite 65t Alexa Enabled True Wireless Earbuds With Charging Case 15 Hours Batterytitanium Black Designed In Denmark Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jabra-elite-65t-alexa-enabled-true-wireless-earbuds-with-charging-case-15-hours-batterytitanium-black-designed-in-denmark-amazonin-electronics/6118ade3612e03145dbdfd9b' },
{ name: 'Redmi Note 10 Shadow Black 4gb Ram 64gb Storage Amoled Dot Display 48mp Sony Sensor Imx582 Snapdragon 678 Processor Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-10-shadow-black-4gb-ram-64gb-storage-amoled-dot-display-48mp-sony-sensor-imx582-snapdragon-678-processor-amazonin-electronics/61189ef4fbff77a163d6c147' },
{ name: 'Redmi Note 9 Shadow Black 4gb Ram 64gb Storage Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-9-shadow-black-4gb-ram-64gb-storage-amazonin-electronics/61189d7bfbff777910d6c0e0' },
{ name: 'Redmi 9a Nature Green 2gb Ram 32gb Storage 2ghz Octa Core Helio G25 Processor 5000 Mah Battery Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-9a-nature-green-2gb-ram-32gb-storage-2ghz-octa-core-helio-g25-processor-5000-mah-battery-amazonin-electronics/61189dd8796062289a5fa62d' },
{ name: 'Samsung Evo Plus 64gb Microsdxc Uhs I 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc64ha Buy Samsung Evo Plus 64gb Microsdxc Uhs I 100mbs Full Hd Amp 4k Uhd Memory Card With Adapter Mb Mc64ha Online At Low Price In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/samsung-evo-plus-64gb-microsdxc-uhs-i-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc64ha-buy-samsung-evo-plus-64gb-microsdxc-uhs-i-100mbs-full-hd-amp-4k-uhd-memory-card-with-adapter-mb-mc64ha-online-at-low-price-in-india-amazonin/61187e6b3d867bb5d73d6d68' },
{ name: 'Realme C11 2021 Cool Blue 2gb Ram 32gb Storage With No Cost Emiadditional Exchange Offers Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/realme-c11-2021-cool-blue-2gb-ram-32gb-storage-with-no-cost-emiadditional-exchange-offers-amazonin-electronics/61187deeddf6cc29ed654c5c' },
{ name: 'Amazon Brand Umi Satin Stripe Microfiber Cushion Insertfiller 16 Inches X 16 Inches White Pack Of 5 Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazon-brand-umi-satin-stripe-microfiber-cushion-insertfiller-16-inches-x-16-inches-white-pack-of-5-amazonin/61187dc7ddf6cc24c0654c5a' },
{ name: 'Recron Certified Dream Fibre Pillow 16quotx24quot Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/recron-certified-dream-fibre-pillow-16quotx24quot-amazonin-home-amp-kitchen/61187da23d867bdc443d6d66' },
{ name: 'Zebronics Sound Feast 50 Portable Speaker Supporting Bluetooth Pendrive Slot Msd Card Fm Call Function Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-sound-feast-50-portable-speaker-supporting-bluetooth-pendrive-slot-msd-card-fm-call-function-black-amazonin-electronics/61187d77ddf6ccfa14654c57' },
{ name: 'Zebronics Zeb County Bluetooth Speaker With Built In Fm Radio Aux Input And Call Function Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-county-bluetooth-speaker-with-built-in-fm-radio-aux-input-and-call-function-black-amazonin-electronics/61187d253d867bcc4c3d6d61' },
{ name: 'Madhuran Prelam Finish Wood Studio Book Shelf Bookcase Space Savingbooks Holder Classic Walnut For Home Decor And Office Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/madhuran-prelam-finish-wood-studio-book-shelf-bookcase-space-savingbooks-holder-classic-walnut-for-home-decor-and-office-amazonin-furniture/61181f3f68b735048b9fcbf5' },
{ name: 'Amazonin Buy Hp 15 11th Gen Intel Core I3 Processor 156quot 3962cms Fhd Laptop With Alexa Built Ini3 1115g48gb1tb Hddm2 Slotwin 10ms Officenatural Silver176 Kg 15s Du3038tu Online At Low Prices In India Hp Reviews Amp Ratings', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonin-buy-hp-15-11th-gen-intel-core-i3-processor-156quot-3962cms-fhd-laptop-with-alexa-built-ini3-1115g48gb1tb-hddm2-slotwin-10ms-officenatural-silver176-kg-15s-du3038tu-online-at-low-prices-in-india-hp-reviews-amp-ratings/61181d41db63cf9edcd5e5ef' },
{ name: 'Portronics Sounddrum 1 10w Tws Portable Bluetooth 50 Speaker With Inbuilt Fm Amp Type C Charging Cable Includedblack Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/portronics-sounddrum-1-10w-tws-portable-bluetooth-50-speaker-with-inbuilt-fm-amp-type-c-charging-cable-includedblack-amazonin-electronics/61187d523d867b40313d6d64' },
{ name: 'Dabur Sanitize Hand Sanitizer Alcohol Based Sanitizer Regular 500 Ml Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-sanitize-hand-sanitizer-alcohol-based-sanitizer-regular-500-ml-amazonin-health-amp-personal-care/61181d12db63cf3160d5e5ed' },
{ name: 'Rainsound Stainless Steel Multifunction Swiss Knife Set 14 In 1 Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rainsound-stainless-steel-multifunction-swiss-knife-set-14-in-1-amazonin-home-improvement/61181c28db63cf6ac8d5e5e8' },
{ name: 'Buy Yellow Chimes Combo Of 3 Pcs Handmade Dori Worked Gold Toned Om My Bro And Celtic Design Evil Eye Beads Rakhi For Brother With Roli Amp Chawal At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-yellow-chimes-combo-of-3-pcs-handmade-dori-worked-gold-toned-om-my-bro-and-celtic-design-evil-eye-beads-rakhi-for-brother-with-roli-amp-chawal-at-amazonin/61181cc7db63cfac42d5e5eb' },
{ name: 'Buy Sonata Pankh Analog Champagne Dial Women39s Watch Nm8085ym05nn8085ym05 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-sonata-pankh-analog-champagne-dial-women39s-watch-nm8085ym05nn8085ym05-at-amazonin/61181c7c68b73565829fcbf3' },
{ name: 'American Tourister 32 Ltrs Black Casual Backpack Amt Fizz Sch Bag 02 Black Amazonin Bags Wallets And Luggage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/american-tourister-32-ltrs-black-casual-backpack-amt-fizz-sch-bag-02-black-amazonin-bags-wallets-and-luggage/61181be268b73510439fcbf1' },
{ name: 'Savlon Mask Bis Certified Ffp2 S Mask Comparable To N95 Earloop Pack Of 4 Amazonin Health Amp Personal Care', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savlon-mask-bis-certified-ffp2-s-mask-comparable-to-n95-earloop-pack-of-4-amazonin-health-amp-personal-care/61181afd68b73584f59fcbef' },
{ name: 'Wipro 10a Smart Plug With Energy Monitoring Suitable For Small Appliances Like Tvs Electric Kettle Mobile And Laptop Chargers Works With Alexa And Google Assistant Dsp1100 Amazonin Home Improvement', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wipro-10a-smart-plug-with-energy-monitoring-suitable-for-small-appliances-like-tvs-electric-kettle-mobile-and-laptop-chargers-works-with-alexa-and-google-assistant-dsp1100-amazonin-home-improvement/61181abb68b73574339fcbec' },
{ name: 'Amazonbasics 65 Kg Fully Automatic Top Load Washing Machine Greyblack Full Metal Body Led Display Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonbasics-65-kg-fully-automatic-top-load-washing-machine-greyblack-full-metal-body-led-display-amazonin-home-amp-kitchen/61180f3cae5d13a5bc256b91' },
{ name: 'Klaxon Plastic Multipurpose Organizer Storage Basket Red Amp Black Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/klaxon-plastic-multipurpose-organizer-storage-basket-red-amp-black-amazonin-home-amp-kitchen/6118076c6cc90900159b313d' },
{ name: 'Buy Mamaearth Onion Hair Fall Shampoo For Hair Growth Amp Hair Fall Control With Onion Oil Amp Plant Keratin 250ml Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-mamaearth-onion-hair-fall-shampoo-for-hair-growth-amp-hair-fall-control-with-onion-oil-amp-plant-keratin-250ml-online-at-low-prices-in-india-amazonin/6118029f6cc90900159b3139' },
{ name: 'Reffair Ax30 Air Car Air Purifier Internationally Tested True Hepa Filter Aromatherapy Function Black Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/reffair-ax30-air-car-air-purifier-internationally-tested-true-hepa-filter-aromatherapy-function-black-amazonin-home-amp-kitchen/61180828c85295006a1109b7' },
{ name: 'Indus Handy Uv Sterilization Air Purifier Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/indus-handy-uv-sterilization-air-purifier-amazonin-home-amp-kitchen/61180b236cc90900159b3147' },
{ name: 'Buy Usha Power Spin 750 Watt Copper Motor Mixer Grinder With 3 Jars Red Sparkle Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-usha-power-spin-750-watt-copper-motor-mixer-grinder-with-3-jars-red-sparkle-online-at-low-prices-in-india-amazonin/61177b50e5230c0015c8c4e2' },
{ name: 'Buy The Man Company Non Gas Body Perfume Combo Set For Men Kopar And Vert No Gas Deodorant For Men Long Lasting Fragrance 120ml2 Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-the-man-company-non-gas-body-perfume-combo-set-for-men-kopar-and-vert-no-gas-deodorant-for-men-long-lasting-fragrance-120ml2-online-at-low-prices-in-india-amazonin/61171f9d93b171001520fb62' },
{ name: 'Kore Pvc 16 Kg Home Gym Set With One 3 Ft Curl And One Pair Dumbbell Rods With Gym Accessories Black Amazonin Sports Fitness Amp Outdoors', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kore-pvc-16-kg-home-gym-set-with-one-3-ft-curl-and-one-pair-dumbbell-rods-with-gym-accessories-black-amazonin-sports-fitness-amp-outdoors/611736f993b171001520fb76' },
{ name: 'Aquaguard Ritz Ro Uv Mtds Alkaline Water Purifier With Stainless Steel 55l White Large Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/aquaguard-ritz-ro-uv-mtds-alkaline-water-purifier-with-stainless-steel-55l-white-large-amazonin-home-amp-kitchen/611719a993b171001520fb5e' },
{ name: 'Redmi Note 10s Shadow Black 6gb Ram 64gb Storage Super Amoled Display 64 Mp Quad Camera Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/redmi-note-10s-shadow-black-6gb-ram-64gb-storage-super-amoled-display-64-mp-quad-camera-amazonin/6117188c93b171001520fb56' },
{ name: 'Buy Soru Fashion Women39s Banarasi Silk Saree With Unstitched Blouse Piece Cott 668 66 67teal Green At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-soru-fashion-women39s-banarasi-silk-saree-with-unstitched-blouse-piece-cott-668-66-67teal-green-at-amazonin/6117183193b171001520fb52' },
{ name: 'Sennheiser Hd 280 Pro Ideal Over Ear Headphones For Home Amp Recording Studio Dj39s Mixing And Listening Music Amazonin Musical Instruments', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sennheiser-hd-280-pro-ideal-over-ear-headphones-for-home-amp-recording-studio-dj39s-mixing-and-listening-music-amazonin-musical-instruments/611717d493b171001520fb50' },
{ name: 'Buy Rovok Rakhi Gift For Sisterrakshabndhan Gifts Women39s Leather Sling Bagcross Body Bags For Casual Wearparty Wearrakshabandhan Giftrakhi Gifts For Sister Brown Ac 2103 Brn At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-rovok-rakhi-gift-for-sisterrakshabndhan-gifts-women39s-leather-sling-bagcross-body-bags-for-casual-wearparty-wearrakshabandhan-giftrakhi-gifts-for-sister-brown-ac-2103-brn-at-amazonin/6116b42cb6bad10015228dab' },
{ name: 'Edifier W800bt Bluetooth Headphones Over The Ear Wireless Headphone Extended Playback Lightweight Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/edifier-w800bt-bluetooth-headphones-over-the-ear-wireless-headphone-extended-playback-lightweight-black-amazonin-electronics/6116b4bdb6bad10015228db1' },
{ name: 'Cadbury Bournville Rich Cocoa Dark Chocolate Bar 80 Gm Pack Of 5 Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cadbury-bournville-rich-cocoa-dark-chocolate-bar-80-gm-pack-of-5-amazonin-grocery-amp-gourmet-foods/6116b4ddb6bad10015228db3' },
{ name: 'Buy 2021 Meri Pyaari Behaana Ananth Jewels Bis Hallmarked Silver Coin Gift For Sister Raksha Bandhan Rakhi Online At Low Prices In India Amazon Jewellery Store Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-2021-meri-pyaari-behaana-ananth-jewels-bis-hallmarked-silver-coin-gift-for-sister-raksha-bandhan-rakhi-online-at-low-prices-in-india-amazon-jewellery-store-amazonin/6116b40db6bad10015228da9' },
{ name: 'Arctic Fox 15 Inch Laptop Pink Tote Bag For Women Amazonin Computers Amp Accessories', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/arctic-fox-15-inch-laptop-pink-tote-bag-for-women-amazonin-computers-amp-accessories/6117185893b171001520fb54' },
{ name: 'Buy Kaameri Bazaar Birthday Bhaidooj Rakhi Gift Combo For Sister 12quot X 12quot Cushion Cover With Filler Printed Coffee Mug Printed Key Ring Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-kaameri-bazaar-birthday-bhaidooj-rakhi-gift-combo-for-sister-12quot-x-12quot-cushion-cover-with-filler-printed-coffee-mug-printed-key-ring-online-at-low-prices-in-india-amazonin/6116b3ccb6bad10015228da7' },
{ name: 'Buy Philips Hd697500 25 Litre Digital Oven Toaster Grill Grey 25 Liter Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-philips-hd697500-25-litre-digital-oven-toaster-grill-grey-25-liter-online-at-low-prices-in-india-amazonin/6116b330b6bad10015228da1' },
{ name: 'Boat Rockerz 330 Wireless Neckband With Asap Charge Up To 30h Playback Enhanced Bass Metal Control Board Ipx5 Type C Port Bluetooth V50 Voice Assistantteal Green Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-rockerz-330-wireless-neckband-with-asap-charge-up-to-30h-playback-enhanced-bass-metal-control-board-ipx5-type-c-port-bluetooth-v50-voice-assistantteal-green-amazonin-electronics/6116b308b6bad10015228d9f' },
{ name: 'Buy Bajaj Platini Px97 Torque 36 Litres Personal Air Cooler White For Medium Room Online At Low Prices In India Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-bajaj-platini-px97-torque-36-litres-personal-air-cooler-white-for-medium-room-online-at-low-prices-in-india-amazonin/6116a6b1896e7600150de3d0' },
{ name: 'Savya Home Apex Chairs Delta Mb Chair Umbrella Base Office Chair Standard Black Amazonin Furniture', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/savya-home-apex-chairs-delta-mb-chair-umbrella-base-office-chair-standard-black-amazonin-furniture/6116b2e9b6bad10015228d9d' },
{ name: 'Exotes Pista Roasted Amp Salted Vacuum Packed Popular Pouch 500 G Amazonin Grocery Amp Gourmet Foods', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/exotes-pista-roasted-amp-salted-vacuum-packed-popular-pouch-500-g-amazonin-grocery-amp-gourmet-foods/6116a666896e7600150de3ce' },
{ name: 'Buy Fossil Analog Gold Women Watch Bq3181 At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-fossil-analog-gold-women-watch-bq3181-at-amazonin/6116a5f6896e7600150de3c8' },
{ name: 'Buy Timex Analog Blue Men Watch Tw00zr262e At Amazonin', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/buy-timex-analog-blue-men-watch-tw00zr262e-at-amazonin/6116a5dc896e7600150de3c6' },
{ name: 'Amazonbasics Vacuum Cleaner With Power Suction Low Sound High Energy Efficiency And 2 Years Warranty 15l Reusable Dust Bag Black Amazonin Home Amp Kitchen', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/amazonbasics-vacuum-cleaner-with-power-suction-low-sound-high-energy-efficiency-and-2-years-warranty-15l-reusable-dust-bag-black-amazonin-home-amp-kitchen/6116a64b896e7600150de3cc' },
{ name: 'Boult Audio Airbass Fx1 True Wireless Earbuds With 32h Total Playtime Ipx5 Water Resistant Touch Controls Type C Fast Charging Voice Assistant Black Amazonin Electronics', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boult-audio-airbass-fx1-true-wireless-earbuds-with-32h-total-playtime-ipx5-water-resistant-touch-controls-type-c-fast-charging-voice-assistant-black-amazonin-electronics/6116a5ae896e7600150de3c4' },
{ name: 'Dussledorf Leonardo 22 Liters Navy Blue And Grey Laptop Backpack Leo 2318', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dussledorf-leonardo-22-liters-navy-blue-and-grey-laptop-backpack-leo-2318/611686997677003ab08d3313' },
{ name: 'Ant Esports Gp300 Pro V2 Wireless Gaming Controller Compatible', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-esports-gp300-pro-v2-wireless-gaming-controller-compatible/611686997677003ab08d3314' },
{ name: 'Skullcandy Spoke True Wireless Earbuds With 14 Hours Battery Ipx4 Sweat And Water Resistant', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/skullcandy-spoke-true-wireless-earbuds-with-14-hours-battery-ipx4-sweat-and-water-resistant/611686987677003ab08d3312' },
{ name: 'Prestige Stainless Steel Thermopro Flask 350 Ml Silver', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/prestige-stainless-steel-thermopro-flask-350-ml-silver/611686977677003ab08d330e' },
{ name: 'Ld Godrej Protekt Disinfectant Sanitizer Spray Alcohol Based 240ml Each Pack Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-godrej-protekt-disinfectant-sanitizer-spray-alcohol-based-240ml-each-pack-of-2/611686977677003ab08d3310' },
{ name: 'Renewed Bajaj Majesty New Rcx 3 15 Litre Multifunction Rice Cooker Whitepink', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/renewed-bajaj-majesty-new-rcx-3-15-litre-multifunction-rice-cooker-whitepink/611686967677003ab08d330d' },
{ name: 'Cello Infuse Plastic Water Bottle Set 800ml Set Of 3 Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-infuse-plastic-water-bottle-set-800ml-set-of-3-orange/611686967677003ab08d330b' },
{ name: 'Godrej Ezee 2 In 1 Liquid Detergent Fabric Sanitizer 2kg Pouch', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/godrej-ezee-2-in-1-liquid-detergent-fabric-sanitizer-2kg-pouch/611686977677003ab08d330f' },
{ name: 'Cello Frosty Bucket Dlx 16 Litres Peach', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-frosty-bucket-dlx-16-litres-peach/611686967677003ab08d330c' },
{ name: 'Size 6 Sparx Women Black Grey Outdoor Sandals Pugm20gbkgy0006', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-6-sparx-women-black-grey-outdoor-sandals-pugm20gbkgy0006/611686957677003ab08d330a' },
{ name: 'Jabra Blueparrott New M300 Xt Mono Bluetooth In Ear Headset Ultra Light Noise Cancelling Headset', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/jabra-blueparrott-new-m300-xt-mono-bluetooth-in-ear-headset-ultra-light-noise-cancelling-headset/611686957677003ab08d3309' },
{ name: 'Biotique Bio Henna Leaf Fresh Texture Shampoo And Conditioner 650ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/biotique-bio-henna-leaf-fresh-texture-shampoo-and-conditioner-650ml/611686947677003ab08d3306' },
{ name: 'Wonderchef Ebony Hard Anodized Nonstick Handi With Lid 21cm 35l 325mm Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-ebony-hard-anodized-nonstick-handi-with-lid-21cm-35l-325mm-black/611686937677003ab08d3304' },
{ name: 'Blackdecker Kr554re 550w 13mm Variable Speed Reversible Hammer Drill Machine With Combination Plier', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/blackdecker-kr554re-550w-13mm-variable-speed-reversible-hammer-drill-machine-with-combination-plier/611686947677003ab08d3307' },
{ name: 'Dabur Vatika Select Moroccan Argan Oil Shampoomoisturize Smooth 300 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dabur-vatika-select-moroccan-argan-oil-shampoomoisturize-smooth-300-ml/611686937677003ab08d3305' },
{ name: 'Wonderchef Acti Bot Stainless Steel Single Wall Water Bottle 650ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-acti-bot-stainless-steel-single-wall-water-bottle-650ml/611686917677003ab08d3300' },
{ name: 'Head Shoulders Smooth And Silky Shampoo 1l', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/head-shoulders-smooth-and-silky-shampoo-1l/611686907677003ab08d32fd' },
{ name: 'Fogg Master Pine 150 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fogg-master-pine-150-ml/611686927677003ab08d3301' },
{ name: 'Lotus Organics Mystic Indulgence Spf 20 Body Lotion With Olive Butter 200 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lotus-organics-mystic-indulgence-spf-20-body-lotion-with-olive-butter-200-ml/611686927677003ab08d3302' },
{ name: 'Size M Karam 100 Cotton Protective Clothing For Work Wear', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-karam-100-cotton-protective-clothing-for-work-wear/611686917677003ab08d32ff' },
{ name: 'Makeup Revolution Euphoric Foil Palette House Of Fun 1889 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-euphoric-foil-palette-house-of-fun-1889-g/611686907677003ab08d32fc' },
{ name: 'Loreal Paris Rouge Signature Matte Liquid Lipstick 119 Dream 7 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loreal-paris-rouge-signature-matte-liquid-lipstick-119-dream-7-g/6116868f7677003ab08d32f9' },
{ name: 'Huggies Nature Care Pants Large Size Diaper Pants 17 Count', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/huggies-nature-care-pants-large-size-diaper-pants-17-count/6116868f7677003ab08d32fb' },
{ name: 'Loreal Paris Matte Signature Liner 06 Brown 25ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loreal-paris-matte-signature-liner-06-brown-25ml/6116868f7677003ab08d32fa' },
{ name: 'Joy Hello Sun Sunblock Anti Tan Lotion Sunscreen Spf 40 Pa120ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/joy-hello-sun-sunblock-anti-tan-lotion-sunscreen-spf-40-pa120ml/6116868e7677003ab08d32f7' },
{ name: 'Loreal Paris Total Repair 5 Shampoo 1 Litre', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/loreal-paris-total-repair-5-shampoo-1-litre/6116868e7677003ab08d32f8' },
{ name: 'Nirvasa Plant Based Collagen Builder For Anti Aging Beauty Skin Repair Regeneration Skin Glow 500gm Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nirvasa-plant-based-collagen-builder-for-anti-aging-beauty-skin-repair-regeneration-skin-glow-500gm-pack-of-1/6116868d7677003ab08d32f5' },
{ name: 'Wow Miracle 10 In 1 No Parabens Sulphate Shampoo 300ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wow-miracle-10-in-1-no-parabens-sulphate-shampoo-300ml/6116868c7677003ab08d32f1' },
{ name: 'Size 10 11 Adidas Mens Fluo M Running Shoes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-10-11-adidas-mens-fluo-m-running-shoes/6116868c7677003ab08d32f2' },
{ name: 'Boroplus Aloe Vera Gel With Haldi Chandan Kesar 300ml Pack Of 2 150ml 150ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boroplus-aloe-vera-gel-with-haldi-chandan-kesar-300ml-pack-of-2-150ml-150ml/6116868e7677003ab08d32f6' },
{ name: 'Mcvities Fruit Cookies 600 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mcvities-fruit-cookies-600-g/6116868a7677003ab08d32f0' },
{ name: 'Zebronics Zeb Umc101 Usb To Micro Usb Cable Charge And Sync 1 Metre Length Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-umc101-usb-to-micro-usb-cable-charge-and-sync-1-metre-length-black/611686897677003ab08d32ef' },
{ name: 'Pantry Tata Sampann Chicken Masala 100g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-tata-sampann-chicken-masala-100g/611686877677003ab08d32ee' },
{ name: 'Spotzero By Milton Smart Spin Mop With Bucket Aqua Green Two Refills', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/spotzero-by-milton-smart-spin-mop-with-bucket-aqua-green-two-refills/611686877677003ab08d32ed' },
{ name: 'Hindware Ciro Black Small Ic100009', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-ciro-black-small-ic100009/6116867c7677003ab08d32e9' },
{ name: 'Ear Lobe Accessories Personalprofessional Metallic Colorful Blue Glitter Shadow No 9', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ear-lobe-accessories-personalprofessional-metallic-colorful-blue-glitter-shadow-no-9/611686837677003ab08d32eb' },
{ name: 'Bms Plastics Mixing Bowlcontainer Set 250ml Set Of 8 Assorted Colour', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/bms-plastics-mixing-bowlcontainer-set-250ml-set-of-8-assorted-colour/611686847677003ab08d32ec' },
{ name: 'Kelloggs Chocos Magic 300g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kelloggs-chocos-magic-300g/6116867e7677003ab08d32ea' },
{ name: 'Mee Mee Comb And Brush Set Easy Grip Pink Green', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/mee-mee-comb-and-brush-set-easy-grip-pink-green/6116867c7677003ab08d32e8' },
{ name: 'Santoor Gold Soap With Saffron Sandal Sakura Extracts 125g Pack Of 6', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/santoor-gold-soap-with-saffron-sandal-sakura-extracts-125g-pack-of-6/611686767677003ab08d32e4' },
{ name: 'Autokraftz Half Finger Motorcycle Gloves Medium Green Set Of 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/autokraftz-half-finger-motorcycle-gloves-medium-green-set-of-2/611686757677003ab08d32e2' },
{ name: 'Philips Ujjwal Bright 18 Watt Led Batten Tubelight Pack Of 4 Cool Day Light', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/philips-ujjwal-bright-18-watt-led-batten-tubelight-pack-of-4-cool-day-light/611686797677003ab08d32e6' },
{ name: 'Size M L Hifzaa Mens Relaxed Fit Regular Cotton Blend Knit Lounge Shorts', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-l-hifzaa-mens-relaxed-fit-regular-cotton-blend-knit-lounge-shorts/611686747677003ab08d32e1' },
{ name: 'Hindware Lyra F920033cp 2 In 1 Brass Bib Cock For Bathroom Chrome Finish', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hindware-lyra-f920033cp-2-in-1-brass-bib-cock-for-bathroom-chrome-finish/6116867a7677003ab08d32e7' },
{ name: 'Meal Of The Moment Instant Pav Bhaji 3 X 75 G With Combo', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/meal-of-the-moment-instant-pav-bhaji-3-x-75-g-with-combo/611686757677003ab08d32e3' },
{ name: 'Ld Recron Certified Dream Fibre Pillow 41 Cm X 61 Cm White 2 Piece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ld-recron-certified-dream-fibre-pillow-41-cm-x-61-cm-white-2-piece/611686747677003ab08d32e0' },
{ name: 'Timios Weekly Snack Pack Snack Packs For School Healthy Snack Packs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/timios-weekly-snack-pack-snack-packs-for-school-healthy-snack-packs/611686737677003ab08d32df' },
{ name: 'Feroc Aluminium Badminton Racket Set Of 2 With Feather Shuttles 3 Pieces With Full Cover', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/feroc-aluminium-badminton-racket-set-of-2-with-feather-shuttles-3-pieces-with-full-cover/611686737677003ab08d32de' },
{ name: 'Orient Electric Miracle Mgmr75s3 750 Watts High Performance Motor With 3 Jars And 2 Storage Jars Grey', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/orient-electric-miracle-mgmr75s3-750-watts-high-performance-motor-with-3-jars-and-2-storage-jars-grey/611686727677003ab08d32dd' },
{ name: 'Vahdam Green Tea Sampler Trial Pack 8 Assorted Green Tea Bags Usda Certified Organic Green Tea', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vahdam-green-tea-sampler-trial-pack-8-assorted-green-tea-bags-usda-certified-organic-green-tea/611686717677003ab08d32dc' },
{ name: 'Joy Hair Fruits Hair Dryness Control Conditioning Shampoo Enriched With Lemon Olives 340 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/joy-hair-fruits-hair-dryness-control-conditioning-shampoo-enriched-with-lemon-olives-340-ml/611686707677003ab08d32d9' },
{ name: 'Pantry Tata Tea Gold 1kg', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-tata-tea-gold-1kg/611686707677003ab08d32db' },
{ name: 'Zandu Tulsi Cinnamon Ayurvedic Infusion Kadha 50 Dip Bags Natural Immunity Booster Herbal Tea Full Of Anti Oxidants Pack Of 25 Tea Bags X 2', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zandu-tulsi-cinnamon-ayurvedic-infusion-kadha-50-dip-bags-natural-immunity-booster-herbal-tea-full-of-anti-oxidants-pack-of-25-tea-bags-x-2/6116866f7677003ab08d32d6' },
{ name: 'Rajipo Rotate Shower Head Holder 1 Pcs Adjustable Self Adhesive Shower Arm Multi Pack Of 1 Pcs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/rajipo-rotate-shower-head-holder-1-pcs-adjustable-self-adhesive-shower-arm-multi-pack-of-1-pcs/6116866f7677003ab08d32d8' },
{ name: 'Ant Audio Treble X 900 Portable Enhanced Bluetooth Speakers 6w With Mic Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ant-audio-treble-x-900-portable-enhanced-bluetooth-speakers-6w-with-mic-black/611686707677003ab08d32da' },
{ name: 'Strauss Adjustable Wrist Forearm Strengthener', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strauss-adjustable-wrist-forearm-strengthener/6116866e7677003ab08d32d3' },
{ name: 'Envent Rock 400 40w 21 Channel Mini Tower Bluetooth Speaker Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/envent-rock-400-40w-21-channel-mini-tower-bluetooth-speaker-black/6116866e7677003ab08d32d4' },
{ name: 'Zebronics Zeb Clash Premium Usb Gaming Mouse With 6 Buttons High Resolution Gaming Sensor 3600 Dpi Mulitcolor Led', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-clash-premium-usb-gaming-mouse-with-6-buttons-high-resolution-gaming-sensor-3600-dpi-mulitcolor-led/6116866e7677003ab08d32d5' },
{ name: 'Scott International Unisex Reusable Face Mask', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/scott-international-unisex-reusable-face-mask/6116866d7677003ab08d32d0' },
{ name: 'Odonil Bathroom Air Freshener Zipper Mix 50gm 10gm5', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/odonil-bathroom-air-freshener-zipper-mix-50gm-10gm5/6116866c7677003ab08d32cf' },
{ name: 'Globus Naturals Almond Body Massage Oil 100 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/globus-naturals-almond-body-massage-oil-100-ml/6116866d7677003ab08d32d2' },
{ name: 'Size M Vector X Pink Purple T Shirt Vtdf 006 A Size', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-vector-x-pink-purple-t-shirt-vtdf-006-a-size/6116866d7677003ab08d32d1' },
{ name: 'Maxkleen Citrus Joy Disinfectant Floor Cleaner 18l', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/maxkleen-citrus-joy-disinfectant-floor-cleaner-18l/6116866c7677003ab08d32cd' },
{ name: 'Size M Sg Polyester Tvest Men V3 Sage M Msage', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-m-sg-polyester-tvest-men-v3-sage-m-msage/6116866b7677003ab08d32cc' },
{ name: 'Oxyskin Cosmetics Aloe Vera Face Wash For Deep Cleanser Soothes Nourishes Reduces Blemishes 500 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oxyskin-cosmetics-aloe-vera-face-wash-for-deep-cleanser-soothes-nourishes-reduces-blemishes-500-ml/6116866c7677003ab08d32ce' },
{ name: 'Swisse Skincare Bamboo Skin Refining Exfoliator For With Witch Hazel Rice Bran Water 125g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/swisse-skincare-bamboo-skin-refining-exfoliator-for-with-witch-hazel-rice-bran-water-125g/6116866b7677003ab08d32cb' },
{ name: 'Snd Art Set Of 3 Ganesha Uv Coated Home Decorative Gift Item Framed Painting 135 Inch X 225 Inch Modern05', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/snd-art-set-of-3-ganesha-uv-coated-home-decorative-gift-item-framed-painting-135-inch-x-225-inch-modern05/6116866a7677003ab08d32ca' },
{ name: 'Makeup Revolution Maxi Reloaded Palette Dream Big Multicolor 60 G', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/makeup-revolution-maxi-reloaded-palette-dream-big-multicolor-60-g/6116866a7677003ab08d32c9' },
{ name: 'Oxyskin Cosmetics Passion Fruit Face And Body Scrub For Glowing Skin Oily Skin Women Men 150 Gm No Paraben Mineral Oils', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oxyskin-cosmetics-passion-fruit-face-and-body-scrub-for-glowing-skin-oily-skin-women-men-150-gm-no-paraben-mineral-oils/611686697677003ab08d32c8' },
{ name: 'Underjeans By Spykar Men Brief', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/underjeans-by-spykar-men-brief/611686697677003ab08d32c6' },
{ name: 'Gala Of London Pearl Face Powder Ivory 40g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gala-of-london-pearl-face-powder-ivory-40g/611686697677003ab08d32c7' },
{ name: 'Dome Cones', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/dome-cones/611686687677003ab08d32c4' },
{ name: 'Asian Smart Stackable Storage Containers Set Of 4 Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/asian-smart-stackable-storage-containers-set-of-4-blue/611686687677003ab08d32c5' },
{ name: 'Oxyskin Cosmetics Mens Grooming Kit Gift Set Face Beard Wash Apricot Fruit Scrub Hair Wax Shaving Cream', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oxyskin-cosmetics-mens-grooming-kit-gift-set-face-beard-wash-apricot-fruit-scrub-hair-wax-shaving-cream/611686687677003ab08d32c3' },
{ name: 'Grand Theft Auto V Premium Edition Ps4', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/grand-theft-auto-v-premium-edition-ps4/611686667677003ab08d32bf' },
{ name: 'Wonderchef Chestnut Fabric Oven Mitt Maroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-chestnut-fabric-oven-mitt-maroon/611686667677003ab08d32be' },
{ name: 'Movik Salon Barber Stainless Steel Straight Handle Folding Razor For Men Black M1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/movik-salon-barber-stainless-steel-straight-handle-folding-razor-for-men-black-m1/611686657677003ab08d32bb' },
{ name: 'Wonderchef Chestnut Fabric 2 Piece Kitchen Towel Set Maroon', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-chestnut-fabric-2-piece-kitchen-towel-set-maroon/611686667677003ab08d32bd' },
{ name: 'Wonderchef Ambrosia Silicone Rose Mould Brown Standard', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-ambrosia-silicone-rose-mould-brown-standard/611686657677003ab08d32ba' },
{ name: 'Wonderchef Ambrosia Baking Paper 10 Metre White Standard 63153624', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/wonderchef-ambrosia-baking-paper-10-metre-white-standard-63153624/611686657677003ab08d32bc' },
{ name: 'Lakm Enrich Matte Lipstick Shade Rm14 47g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-enrich-matte-lipstick-shade-rm14-47g/611686677677003ab08d32c2' },
{ name: 'Pantry Tata Coffee Grand Jar 50g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-tata-coffee-grand-jar-50g/611686677677003ab08d32c0' },
{ name: 'Lakm Enrich Matte Lipstick Shade Rm16 47g', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/lakm-enrich-matte-lipstick-shade-rm16-47g/611686677677003ab08d32c1' },
{ name: 'Polyset Onyx Vacuum Insulated Steel Water Bottle Pink Shimmer Finish 500 Ml Everyday Travel Thermos Leak Proof Hot And Cold Ideal', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/polyset-onyx-vacuum-insulated-steel-water-bottle-pink-shimmer-finish-500-ml-everyday-travel-thermos-leak-proof-hot-and-cold-ideal/611686647677003ab08d32b9' },
{ name: 'Pantry Unity Super Authentic Long Grain Basmati Rice 5 Kg Pack From The House Of India Gate', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/pantry-unity-super-authentic-long-grain-basmati-rice-5-kg-pack-from-the-house-of-india-gate/611686647677003ab08d32b7' },
{ name: 'Size 8 Amazon Brand Symbol Mens Formal Shoes', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-amazon-brand-symbol-mens-formal-shoes/611686647677003ab08d32b8' },
{ name: 'Archies Rakhi For Brother Special Rakshabandhan Combo Set Of 2 Rudraksh Bhaiya Bro Bhai Rakhis With Greeting', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-rakhi-for-brother-special-rakshabandhan-combo-set-of-2-rudraksh-bhaiya-bro-bhai-rakhis-with-greeting/611686637677003ab08d32b5' },
{ name: 'Flomaster Probiker Full Finger Motorcycle Gloves Blue M', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/flomaster-probiker-full-finger-motorcycle-gloves-blue-m/611686627677003ab08d32b4' },
{ name: 'Oshotto Rear Arm With Blade Compatible With Toyota Innova Crysta Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oshotto-rear-arm-with-blade-compatible-with-toyota-innova-crysta-black/611686637677003ab08d32b6' },
{ name: 'Babymoon Breast Pump Silicone Hand Breast Pump Breastfeeding Food Grade Bpa Portable Milk Collector Milk Storage Bottle Breast Massager', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/babymoon-breast-pump-silicone-hand-breast-pump-breastfeeding-food-grade-bpa-portable-milk-collector-milk-storage-bottle-breast-massager/611686627677003ab08d32b3' },
{ name: 'Nyle Hand Sanitizer Strawberry 400 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nyle-hand-sanitizer-strawberry-400-ml/611686617677003ab08d32b1' },
{ name: 'Gardens Need 100 Virgin Plastic Tray 10 No Set Of 8 Trays 155cm X 185cm X 3cm Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/gardens-need-100-virgin-plastic-tray-10-no-set-of-8-trays-155cm-x-185cm-x-3cm-orange/611686617677003ab08d32b0' },
{ name: 'Archies Cow Showpiece', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-cow-showpiece/611686617677003ab08d32b2' },
{ name: 'Archies Rakhi For Brother Special Rakshabandhan Combo Set Of 3 Crystal Stone Bhaiya Bro Bhai Rakhis With Greeting Card 2 Dairy Milk Chocolates 24 Gms Roli Chawal 10 Gms Pk Gift Pack Of 7', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/archies-rakhi-for-brother-special-rakshabandhan-combo-set-of-3-crystal-stone-bhaiya-bro-bhai-rakhis-with-greeting-card-2-dairy-milk-chocolates-24-gms-roli-chawal-10-gms-pk-gift-pack-of-7/611686607677003ab08d32af' },
{ name: 'Ideal Stainless Steel Utensils Tiffin Plate 210 Mm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/ideal-stainless-steel-utensils-tiffin-plate-210-mm/6116865f7677003ab08d32ad' },
{ name: 'Cello Hi Fresh Safe Plastic Jumbo Container 4 Pccapacity 5ltrs 7ltrs 10ltrs 15ltrs Blue', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/cello-hi-fresh-safe-plastic-jumbo-container-4-pccapacity-5ltrs-7ltrs-10ltrs-15ltrs-blue/6116865e7677003ab08d32ab' },
{ name: 'Size 9 Amazon Brand Symbol Mens Sneakers', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-9-amazon-brand-symbol-mens-sneakers/6116865e7677003ab08d32ac' },
{ name: 'Limetro Steel Stainless Steel Copper Base 7 Pcs Handi Set 7 Pieces Gas Compatible Only', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/limetro-steel-stainless-steel-copper-base-7-pcs-handi-set-7-pieces-gas-compatible-only/6116865f7677003ab08d32ae' },
{ name: 'Boat Bassheads 900 On Ear Wired Headphonescarbon Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/boat-bassheads-900-on-ear-wired-headphonescarbon-black/6116865d7677003ab08d32a8' },
{ name: 'Sage Apothecary Strawberry Lip Scrub For Lightening Brightening Darksmokerdrychapped Lips Provides Moisture To Dry Lips Made With Natural Ingredients 8 Gm', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sage-apothecary-strawberry-lip-scrub-for-lightening-brightening-darksmokerdrychapped-lips-provides-moisture-to-dry-lips-made-with-natural-ingredients-8-gm/6116865d7677003ab08d32a9' },
{ name: 'Facescanada Ultime Pro Hd Runway Ready Foundation Sand 04 Beige 30 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/facescanada-ultime-pro-hd-runway-ready-foundation-sand-04-beige-30-ml/6116865b7677003ab08d32a5' },
{ name: 'Primefair Complete Doctor Kit Box Medical Accessories Pretend Gift Toys For Girls Gift Type Pack Of 13', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/primefair-complete-doctor-kit-box-medical-accessories-pretend-gift-toys-for-girls-gift-type-pack-of-13/6116865e7677003ab08d32aa' },
{ name: 'Kosh Silicone Plastic Foldable Collapsible Durable Kitchen Sink Dish Rack Drainer Drying Rack Washing Basket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kosh-silicone-plastic-foldable-collapsible-durable-kitchen-sink-dish-rack-drainer-drying-rack-washing-basket/6116865c7677003ab08d32a6' },
{ name: 'Sutariya Enterprise 24kw Portable High Power Suction Handheld Dry Wet Vacuum Cleaner', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sutariya-enterprise-24kw-portable-high-power-suction-handheld-dry-wet-vacuum-cleaner/6116865c7677003ab08d32a7' },
{ name: 'Zebronics Zeb Calyx Wired Earphone Comes With 10mm Drivers 35mm Connectivity In Line Microphone', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-calyx-wired-earphone-comes-with-10mm-drivers-35mm-connectivity-in-line-microphone/6116865a7677003ab08d32a2' },
{ name: 'Vlcc De Tan Plus White Glow Moisturising Body Lotion Spf 30 Pa 350ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vlcc-de-tan-plus-white-glow-moisturising-body-lotion-spf-30-pa-350ml/6116865b7677003ab08d32a4' },
{ name: 'Size 8 9y Us Polo Girls Jacket', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-8-9y-us-polo-girls-jacket/6116865b7677003ab08d32a3' },
{ name: 'Steelo 750ml X 1 Pcs Sante Infuser Water Bottle Orange', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/steelo-750ml-x-1-pcs-sante-infuser-water-bottle-orange/611686597677003ab08d329e' },
{ name: 'Myglamm Manish Malhotra Methi Face Scrub Gel', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/myglamm-manish-malhotra-methi-face-scrub-gel/611686597677003ab08d329d' },
{ name: 'Hunky Dory 10ml Unique Empty Refillable Glossy Black Glass Bottle With Spray Pump And Cap For For Diy Perfume Essential Oils Blends Rose Water Pack Of 1', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hunky-dory-10ml-unique-empty-refillable-glossy-black-glass-bottle-with-spray-pump-and-cap-for-for-diy-perfume-essential-oils-blends-rose-water-pack-of-1/6116865a7677003ab08d32a0' },
{ name: 'Size L Amazon Brand Inkast Denim Co Mens Slim Fit Casual Shirt', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/size-l-amazon-brand-inkast-denim-co-mens-slim-fit-casual-shirt/611686587677003ab08d329c' },
{ name: 'Oxyskin Cosmetics Beard Face Wash Passion Fruit Scrub Combo Pack Brightening Skin Care 150 Ml', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/oxyskin-cosmetics-beard-face-wash-passion-fruit-scrub-combo-pack-brightening-skin-care-150-ml/611686587677003ab08d329a' },
{ name: 'Vega Atom Black Helmet Medium', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/vega-atom-black-helmet-medium/611686577677003ab08d3299' },
{ name: 'Nikunj Dhaba Special Tea 1 Kg Leaf', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/nikunj-dhaba-special-tea-1-kg-leaf/611686577677003ab08d3298' },
{ name: 'Kenstar Kmspi50y3s Des 500w Mixer Grinder Grey Yellow', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/kenstar-kmspi50y3s-des-500w-mixer-grinder-grey-yellow/611686587677003ab08d329b' },
{ name: 'Agaro 33228 Chapter One Portable Bluetooth Speaker With Mic In Built Aux Tf Card Slot Black', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/agaro-33228-chapter-one-portable-bluetooth-speaker-with-mic-in-built-aux-tf-card-slot-black/611686577677003ab08d3297' },
{ name: 'Sourbh Womens Beachwear Sarong Printed Wrap Pareo Swimsuit Body Coverups', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/sourbh-womens-beachwear-sarong-printed-wrap-pareo-swimsuit-body-coverups/611686567677003ab08d3294' },
{ name: 'Strontium Nitro 32gb Micro Sdhc Memory Card 85mbs Uhs I U1 Class 10 High Speed', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/strontium-nitro-32gb-micro-sdhc-memory-card-85mbs-uhs-i-u1-class-10-high-speed/611686567677003ab08d3296' },
{ name: 'Hp Neverstop Laser Mfp 1200nw Printer', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/hp-neverstop-laser-mfp-1200nw-printer/611686567677003ab08d3295' },
{ name: 'Zebronics Zeb Ir Blaster Smart Wifi Universal Remote With Built In Alexa', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/zebronics-zeb-ir-blaster-smart-wifi-universal-remote-with-built-in-alexa/611686557677003ab08d3291' },
{ name: 'Evereve Ultra Absorbent Heavy Flow Disposable Period Panties For Sanitary Protection M L Pack Of 5 10 Pcs', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/evereve-ultra-absorbent-heavy-flow-disposable-period-panties-for-sanitary-protection-m-l-pack-of-5-10-pcs/611686557677003ab08d3292' },
{ name: 'Fourwalls Polyurethane Artificial Real Touch Cymbedium Orchids Flower Bunches 28 Cm Tall 8 Flower White', category: 'cat1', url: 'https://shoppingdeals.in/loot-deal/fourwalls-polyurethane-artificial-real-touch-cymbedium-orchids-flower-bunches-28-cm-tall-8-flower-white/611686557677003ab08d3293' },
];

export default productList;