import React from "react";
import './card-style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faClock, faThumbsUp } from '@fortawesome/fontawesome-free-regular'

const LikeTimeComment = props => {
    return (props.props.time) ? (
        <>
            <div className="d-flex justify-content-between px-1 mt-1">
                <div className="d-flex">
                    <div> <FontAwesomeIcon icon={faComment} size="xs" /></div>
                    <div className="font-weight-light">{props.props.comment}</div>
                </div>
                <div className="d-flex">
                    <div> <FontAwesomeIcon icon={faClock} size="xs" /></div>
                    <div className="font-weight-light">{props.props.time}</div>
                </div>
                <div className="d-flex">
                    <div> <FontAwesomeIcon icon={faThumbsUp} size="xs" /></div>
                    <div className="font-weight-light">{props.props.like}</div>
                </div>
            </div>
        </>
    ) : "";

}


const Card = props => {
    return (
        <div className="card text-center shadow" style={{ height: "23rem" }}>
            <div className="overflow">
                <div className="mt-2">
                    <img src={props.imgsrc} alt="photo1" className="card-img-top" />
                </div>
            </div>
            <div className="card-body text-dark">
                <h4 className="card-title">{props.name}</h4>
                <p className="text-secondary">{props.description}</p>
                <div className="d-flex justify-content-between px-3 pb-0">
                    <p className="text-secondary"><s>&#8377;{props.mrp}</s></p>
                    <p className="text-dark bg-light ps-1 pe-1">{props.vendor}</p>
                    <p className="text-primary">&#8377;{props.op}</p>
                </div>
                <button onclick="window.location.href='#'" className="btn btn-primary btn-sm">
                    GET DEAL
                </button>
                {/* <a href="#" className="btn btn-primary btn-sm">GET DEAL</a> */}
                <LikeTimeComment props={props} />
            </div>
        </div>
    );
}

export default Card;