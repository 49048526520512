import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import * as FaIcons from 'react-icons/fa'
import * as HiIcons from 'react-icons/hi'
import * as BiIcons from 'react-icons/bi'
import { SidebarData } from './SidebarData'
import Submenu from './Submenu'
import { IconContext } from 'react-icons'
import './sidebar.css'
import { userContext } from "../../App";
import { Form, FormControl, Button } from 'react-bootstrap';
// import logo from '../../asset/Logo_no_text.png';
// import SeoContent from '../pages/Seo'

const Nav = styled.div`
    background: #232F3F;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 10;
    margin-bottom: 60px;
    position: fixed;
`;

const NavIcon2 = styled.nav`
    margin-left: 10px;
    font-size: 30px;
    transform: rotate(0deg);
    transition: 550ms;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavIcon1 = styled.nav`
    margin-left: 10px;
    font-size: 30px;
    transform: rotate(90deg);
    transition-delay: 1s;
    transition: transform 550ms;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;


const NotificationIcon = styled.nav`
    margin-top: 5px;
    margin-right: 8px;
    position: relative;
`;

const NotificationText = styled.nav`
    position: fixed;
    height: 20px;
    width: 20px;
    margin-left: 15px;
    font-size:13px;
    border-radius: 50%;
    background: #f42;
    color: #fff;
`;

const SidebarNav = styled.nav`
    background: #232F3F;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: flex;
    justify-content: flex-start;
    position: fixed;
    margin-top: 50px;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-200%')};
    transition: 50ms;
    z-index: 10;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const SidebarWrap = styled.nav`
   width: 100%
`;

const Sidebar = () => {
    const { state } = useContext(userContext);
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    function OutsideClick(ref) {
        const [isClicked, setIsClicked] = useState();
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsClicked(true);
                } else {
                    setIsClicked(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
        return isClicked;
    }
    const boxRef = useRef(null);
    // boxOutsideClick will be true on outside click
    const boxOutsideClick = OutsideClick(boxRef);
    if (sidebar && boxOutsideClick) {
        setSidebar(false);
    }
    return (
        <>
            <div ref={boxRef}>
                <IconContext.Provider value={{ color: '#fff' }}>
                    <Nav>
                        {sidebar ? (
                            <NavIcon1 to='#'>
                                <HiIcons.HiOutlineChevronDoubleDown onClick={showSidebar} />
                                {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                            </NavIcon1>
                        ) : (
                            <NavIcon2 to='#'>
                                <FaIcons.FaBars onClick={showSidebar} />
                            </NavIcon2>
                        )}
                        <div className="title">
                            <div className="pl-2" >Shopping Deals</div>
                            {/* <img src={logo} width="38" height="38" className="mt-1"/> */}
                            {/* <div>opping Deals</div> */}
                        </div>
                        {/* <div className="float-right">
                            <FaIcons.FaTwitterSquare    size={30}  opacity={0.9} />
                            <FaIcons.FaInstagramSquare  size={30}  opacity={0.9} />
                            <FaIcons.FaFacebookSquare   size={30}  opacity={0.9} />
                        </div> */}
                        {/* <SeoContent /> */}
                        <Form className="search-form">
                            <NotificationIcon>
                                {(state === 0) ? (
                                    <FaIcons.FaBell size={30} opacity={0.4} />
                                )
                                    : (
                                        <>
                                            <NotificationText>{state}</NotificationText>
                                            <FaIcons.FaBell size={30} onClick={() => window.location.reload()} />
                                        </>
                                    )
                                }
                            </NotificationIcon>

                            <FormControl
                                type="text"
                                placeholder="Search"
                                className="mb-2 mr-sm-2 me-2 "
                                name="search"
                                id="header-search" />
                            <Button type="submit"
                                variant="secondary"
                                className="mb-2"><BiIcons.BiSearchAlt2 size={15} /></Button>
                        </Form>
                    </Nav>

                    <SidebarNav sidebar={sidebar}>
                        <SidebarWrap>

                            {SidebarData.map((item, index) => {
                                return <Submenu item={item} key={index} />
                            })}
                        </SidebarWrap>
                    </SidebarNav>
                </IconContext.Provider>
            </div>

        </>

    );
}

export default Sidebar
