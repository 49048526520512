// ProductListPage.js
import React from 'react';
import './ProductListPage.css'; // Import CSS for styling
import productList from './productList';
// Assuming productList is an array of objects containing product names, categories, and URLs
// const productList = [
//   { name: 'Product 2', category: 'Category 1', url: '/product2' },
//   { name: 'Product 3', category: 'Category 2', url: '/product3' },
//   { name: 'Product 3', category: 'Category 3', url: '/product3' },
//   { name: 'Product 3', category: 'Category 4', url: '/product3' },
//   { name: 'Product 3', category: 'Category 5', url: '/product3' },
//   { name: 'Product 3', category: 'Category 6', url: '/product3' },
//   { name: 'Product 3', category: 'Category 7', url: '/product3' },
//   { name: 'Product 3', category: 'Category 8', url: '/product3' },
//   { name: 'Product 3', category: 'Category 9', url: '/product3' },
//   { name: 'Product 3', category: 'Category 2', url: '/product3' },
//   // Add more products as needed
// ];

function ProductListPage() {
  // Extract unique categories from productList
  const categories = [...new Set(productList.map(product => product.category))];

  return (
    <div className='top'>
    <div className="product-list-page">
      <h2>Product List</h2>
      <div className="category-columns">
        {categories.map((category, index) => (
          <div key={index} className="category-column">
            {/* <h3>{category}</h3> */}
            <ul>
              {productList.map((product, idx) => (
                product.category === category && (
                  <li key={idx} className="product-item">
                    <a href={product.url}>{product.name}</a>
                  </li>
                )
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default ProductListPage;
