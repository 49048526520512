import GetItemById from '../Apis/GetItemById';
import React, { useEffect} from 'react'
import { useParams, withRouter} from 'react-router-dom'
import ItemDetailsCard from "../Cards/ItemDetailsCard";
import "../Cards/card-style.css";

const ItemDetails = props => {
    const { id } = useParams();
    let { error, item} = GetItemById(id);
    useEffect(() => {
    //     setId(id);
    
    }, [id]);

    return (
        (item != null)?
        <div>
            <div className="home-page">
                <ItemDetailsCard item={item} />
            </div>
        </div> : <div>{error}</div>
    )
   
}

export default withRouter(ItemDetails)
