import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
// import EmptyItemCard from "../../Cards/EmptyItemCard";
import AdminItemCard from "../Card/AdminItemCard";
import GetAllItem from '../../Apis/GetAllItem';
import loadingImg from '../../../asset/loading.gif';
import NoItem from '../../pages/NoItem';
import '../admin.css';

const HomeItem = props => {
    const [pageNumber, setPageNumber] = useState(1);
    // const [searchTerm, setSearchTerm] = useState('');
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    var searchQuery = query.get('search');
    let loading, error, items, hasMore, noitem;
    if (searchQuery == null) {
        ({ loading, error, items, hasMore, noitem } = GetAllItem(pageNumber, ""));
    } else {
        ({ loading, error, items, hasMore, noitem } = GetAllItem(pageNumber, searchQuery));
    }
    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                // console.log('visible');
                setPageNumber(prevPageNumber => prevPageNumber + 1);
                // console.log(pageNumber);
            }
        })
        if (node) observer.current.observe(node)
        //console.log(node);
    }, [loading, hasMore]);

    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    const getUserId = () => {
        var userid = getCookie("_gau");
        if(userid === ""){
            let r = Math.random().toString(36).substring(7);
            let uc = "_gau=" + r;
            document.cookie = uc;
        }
    }

    useEffect(() => {
        setPageNumber(1);
        getUserId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="grid-container-update mt-5">
                {
                    items.map((items1) => (
                        items1
                            .map((item, index) => {
                                //console.log("item=>" + item.description + "<=item")
                                // console.log(items1.length);
                                if (items1.length === index + 1) {
                                    return <div ref={lastItemRef} key={item._id}>
                                        {/* <EmptyItemCard item={item} /> */}
                                        {/* <AmazonBanner/> */}
                                        <AdminItemCard item={item}/>
                                    </div>
                                } else {
                                    return <div key={item._id}>
                                        <AdminItemCard item={item}/>
                                        {/* <EmptyItemCard item={item} /> */}
                                    </div>
                                }
                            })
                    ))
                }
            </div>
            <div>{noitem && <NoItem/>}</div>
            {/* <div>{loading && "Loading..."}</div> */}
            <div>{loading && <img src={loadingImg} alt="Loading..." className="loading-img" />}</div>
            <div>{error && 'Error!!!'}</div>

        </div>
    )
}

export default HomeItem
