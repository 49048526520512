import React, { Component, useState, useEffect } from "react";
import './admin.css';
import Card from '../Cards/Card';
import Iframe from 'react-iframe'
import Errorpage from "../../components/pages/Errorpage";
// import AmazonBanner  from '../Ads/AmazonBanner'
let item = {};

function Preview(props) {
  return (props.trigger) ?(
    <>
    <div className="preview">
      <div className="preview-inner">
      <h4>Preview Item</h4>
      <div className="preview-card d-flex justify-content-around">
        <div className="card-inner">
        <Card
        description={props.description}
        imgsrc={props.imgsrc}
        mrp={props.mrp}
        op={props.op}
        vendor={props.vendor}
        like={props.like} 
        comment={props.comment}
        time={props.time}
        />
        </div>
        <div className="ml-2">
         <Iframe url={props.link}
        width="270px"
        height="370px"
        id="myId"
        className="card"
        display="initial"
        position="relative"/>
        </div>
      </div>
      <div className="save-btn">
        <button className="btn btn-danger" onClick={()=>props.setTrigger(false)} >CANCEL</button>
        <button className="btn btn-success" type="submit" name="additem" id="additem"
        value="AddItem" onClick={postData}>SUBMIT</button>
      </div>
      
      </div>
    </div>
    </>
  ) : "";
}

const postData = async (e) => {
  e.preventDefault();
  const { description,imgsrc,link,vendor,mrp,op,time,like,comment,note } = item;
  const res = await fetch(window.env.API + '/additem', {
    method: "POST",
    headers: {
      "Content-Type" : "application/json"
    },
    body: JSON.stringify({
      description,imgsrc,link,vendor,mrp,op,time,like,comment,note
    })
  });

  const data = await res.json();
  if(res.status === 422 || !data){
    window.alert("Invalid Data. res");
    console.log("Invalid Data. res");
  } else {
    // window.alert("Item added Sucessful");
    console.log(res.status );
    window.location.reload();
  }
}

const NewForm = () => {
  const [imgsrc, setImgsrc] = useState("");
  const [mrp, setMrp] = useState("");
  const [op, setOp] = useState("");
  const [vendor, setVendor] = useState("");
  const [comment, setComment] = useState("");
  const [note, setNote] = useState("");
  const [like, setLike] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [preview, setPreview] = useState(false);
  const [validToken, setValidToken] = useState(false);
  item = {description,imgsrc,link,vendor,mrp,op,time,like,comment,note};
  
  const validateToken = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(window.env.API + '/validateToken', {
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'Authorization': `Bearer ${token}` 
      }
    });
    if(res.status === 200){
      setValidToken(true);
    }
  }

  useEffect(() => {
    validateToken();
  },[]);

  if(!validToken) {
    return  <Errorpage/>;
  }
  // {/*imgsrc=vendor=mrp=op=comment=time=like=name,description*/}
  return (
    <>
      <div className="add_item_form">
        <div className="container-fluid">
            <div className="col-lg-11 offset-lg-1 ">
              <div className="add_item_form_container pt-1">
                <div className="add_item_form_title">ADD ITEM</div>
                <form id="add_item_form" method="POST">
                  <div className="form_content">
                    <textarea
                      type="text"
                      id="form_description"
                      className="add_item_form_input"
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Product Description"
                      rows="4"
                      required="true"
                      
                    />
                    <textarea
                      type="text"
                      id="form_imgsrc"
                      className="add_item_form_input"
                      onChange={(e) => setImgsrc(e.target.value)}
                      placeholder="Image Link"
                      required="true"
                      rows="4"
                    />
                    
                    <textarea
                      type="text"
                      id="form_link"
                      className="add_item_form_input"
                      onChange={(e) => setLink(e.target.value)}
                      placeholder="Product Link"
                      required="true"
                      rows="2"
                    />
                   
                   
                    <div className="small-inputs"></div>
                    <input
                      type="text"
                      id="form_vendor"
                      className="add_item_form_input_small"
                      onChange={(e) => setVendor(e.target.value)}
                      placeholder="Vendor"
                      required="true"
                    />
                    <input
                      type="number"
                      id="form_mrp"
                      className="add_item_form_input_small"
                      onChange={(e) => setMrp(e.target.value)}
                      placeholder="MRP"
                      required="true"
                    />

                    <input
                      type="number"
                      id="form_op"
                      className="add_item_form_input_small"
                      onChange={(e) => setOp(e.target.value)}
                      placeholder="Offer Price"
                      required="true"
                    />

                    <input
                      type="number"
                      id="form_time"
                      className="add_item_form_input_small"
                      onChange={(e) => setTime(e.target.value)}
                      placeholder="Time"
                      required="true"
                    />

                    <input
                      type="number"
                      id="form_like"
                      className="add_item_form_input_small"
                      onChange={(e) => setLike(e.target.value)}
                      placeholder="Like"
                      required="true"
                    />

                    <input
                      type="number"
                      id="form_comment"
                      className="add_item_form_input_small"
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Comment"
                      required="true"
                    />
                  </div>

                  <input
                      type="text"
                      id="form_note"
                      className="add_item_form_input_small"
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Note"
                      required="true"
                    />
                </form>
                 
                  <div className="add_item_form_button">
                    <button
                      type="submit"
                      className="button add_item_submit_button"
                      value="send message"
                      onClick={()=>setPreview(true)}
                    >
                      Preview Item
                    </button>
                  </div>
              </div>
            </div>
        </div>
        <Preview trigger={preview} setTrigger={setPreview} 
        description={description}
        imgsrc={imgsrc}
        mrp={mrp} 
        op={op}
        like={like}
        time={time}
        comment={comment}
        vendor={vendor}
        link={link}/>
      </div>
    </>
  );
};
class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="container-fluid">
          {/* <div className="card"> */}
            {/* <div className="card-body"> */}
              <NewForm />
             
            {/* </div> */}
          {/* </div> */}
        </div>
      </>
    );
  }
}

export default AddItem;
