import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as RiIcons from 'react-icons/ri'
import * as GiIcons from 'react-icons/gi'
import * as GoIcons from 'react-icons/go'
import * as MdIcons from 'react-icons/md'
export var SidebarData = [
    {
        title: "Home",
        icon: <AiIcons.AiFillHome />,
        path: '/',
    },
    {
        title: "Latest Deals",
        path: '/latest',
        icon: <FaIcons.FaRunning />,
    },
    {
        title: "Loot Deals",
        path: '/loot-deals',
        icon: <RiIcons.RiLightbulbFlashFill />,
    },
    {
        title: "About Us",
        path: '/aboutus',
        icon: <GiIcons.GiInfo />,
    },
    {
        title: "Sitemap",
        path: '/sitemap',
        icon: <FaIcons.FaSitemap />,
    }
]

if (process.env.NODE_ENV === 'development') {
    SidebarData = SidebarData.concat(
        {
            title: "Blogs",
            icon: <AiIcons.AiTwotonePrinter />,
            path: '/blogs',
        },
        {
            title: "Admin-tools",
            icon: <AiIcons.AiFillSetting />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: "Add Item",
                    path: '/Azscaledd',
                    icon: <FaIcons.FaAddressBook />,
                },
                {
                    title: "Update",
                    path: '/Uzscalepdate',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Search Term",
                    path: '/Szscalearchterm',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Item Clicked",
                    path: '/Izscaltemclicked',
                    icon: <RiIcons.RiLightbulbFlashFill />,
                },
                {
                    title: "Admin Log",
                    path: '/Azscaldminlog',
                    icon: <RiIcons.RiPagesLine />,
                },
                
            ]
        },
        {
            title: "Categories",
            icon: <RiIcons.RiStore2Fill />,
            iconClosed: <RiIcons.RiArrowDownSFill />,
            iconOpened: <RiIcons.RiArrowUpSFill />,
            subNav: [
                {
                    title: "Apparel",
                    path: '/category/apparel',
                    icon: <GiIcons.GiClothes />,
                },
                {
                    title: "Computers",
                    path: '/category/computers',
                    icon: <RiIcons.RiComputerFill />,
                },
                {
                    title: "Electronics",
                    path: '/category/electronics',
                    icon: <GoIcons.GoDeviceCamera />,
                }, {
                    title: "Industrial",
                    path: '/category/industrial',
                    icon: <FaIcons.FaIndustry />,
                }, {
                    title: "Kitchen",
                    path: '/category/kitchen',
                    icon: <MdIcons.MdKitchen />,
                }, {
                    title: "Software",
                    path: '/category/software',
                    icon: <RiIcons.RiUDiskFill />,
                }, {
                    title: "Watches",
                    path: '/category/watches',
                    icon: <GiIcons.GiWatch />,
                }, {
                    title: "Clothing",
                    path: '/category/clothing',
                    icon: <FaIcons.FaTshirt />,
                }, {
                    title: "E-accessories",
                    path: '/category/e-accessories',
                    icon: <FaIcons.FaHeadphones />,
                },
                {
                    title: "Footwear",
                    path: '/category/footwear',
                    icon: <GiIcons.GiRunningShoe />,
                },
                {
                    title: "Home",
                    path: '/category/home',
                    icon: <RiIcons.RiHomeGearFill />,
                },
                {
                    title: "Household",
                    path: '/category/household',
                    icon: <FaIcons.FaWarehouse />,
                },
                {
                    title: "Large-appliances",
                    path: '/category/large-appliances',
                    icon: <RiIcons.RiFridgeFill />,
                },
                {
                    title: "Mobiles",
                    path: '/category/mobiles',
                    icon: <FaIcons.FaMobile />,
                },
                {
                    title: "Personal-care",
                    path: '/category/personal-care',
                    icon: <GiIcons.GiGymBag />,
                },
            ]
        },
    )
} 
