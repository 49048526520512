import React, { useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import TrendingItemCard from "../Cards/TrendingItemCard";
// import EmptyItemCard from "../Cards/EmptyItemCard";
import GetTrendingItems from '../Apis/GetTrendingItem';
// import loadingImg from '../../asset/loading.gif';
import NoItem from './NoItem';
import {TrendingItemsSkeleton} from '../utils/CardSkeleton';

const HomeItem = props => {
    // console.log("called homeitem");
    const [pageNumber, setPageNumber] = useState(1);
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    var searchQuery = query.get('search');
    let loading, error, items, hasMore, noitem;
    /* eslint-disable no-unused-vars */
    if (searchQuery == null) {
        // var { loading, error, items, hasMore, noitem } = GetTrendingItems(pageNumber, "");
        ({ loading, error, items, hasMore, noitem } = GetTrendingItems(1, ""));
        if(pageNumber === 2){
            // items.push("special-item");
            //.log(items);
        }
    } else {
        ({ loading, error, items, hasMore, noitem } = GetTrendingItems(pageNumber, searchQuery));
    }
    /* eslint-enable no-unused-vars */
    // const observer = useRef();

    function getCookie(key) {
        var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }

    const getUserId = () => {
        var userid = getCookie("_gau");
        if(userid === ""){
            let r = Math.random().toString(36).substring(7);
            let uc = "_gau=" + r;
            document.cookie = uc;
        }
    }

    useEffect(() => {
        setPageNumber(1);
        getUserId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="trending-grid-container">
                {
                    items.map((items1) => (
                        items1
                            .map((item, index) => {
                                return <div key={item._id}>
                                    {/* <AdminItemCard item={item}/> */}
                                    <TrendingItemCard item={item} />
                                </div>
                            })
                    ))
                }
            </div>
            <div>{noitem && <NoItem/>}</div>
            {/* <div>{loading && "Loading..."}</div> */}
            <div>{loading && <TrendingItemsSkeleton/>}</div>
            {/* <div>{loading && <img src={loadingImg} alt="Loading..." className="loading-img" />}</div> */}
            <div>{error && 'Error!!!'}</div>

        </>
    )
}

export default HomeItem
