import React, { useEffect, useState} from "react";
import '../Cards/trending-card.css';
import imgvendor from '../../asset/amazon-logo.png';
import cart from '../../asset/Logo_no_text.png';
import ReactGa from 'react-ga4';

const TrendingItemCard = props => {
  const [item, setItem] = useState({});
  const [imgUrl, setImgUrl] = useState('');
  // const [like, setLike] = useState('');
  // const [dislike, setDislike] = useState('');
  // const [time, setTime] = useState('');
  // const [expired, setExpired] = useState('new');
  const [display, setDisplay] = useState(false);
  // const [id, setId] = useState("");
  // const [preview, setPreview] = useState(false);
  // const [dislikePreview, setDislikePreview] = useState(false);

  const callGetItem = async () => {
    var data = props.item;
    setItem(data);
    setImgUrl(data.imgsrc);
    // setId(data._id);
    // setLike(data.like);
    // setDislike(data.dislike);
    // setExpired(data.status);
    var curr_time = new Date().getTime() / 1000 | 0;
    var tmp_time = (curr_time - data.time) / 60 | 0; //mins
    if (tmp_time > 59) {
      tmp_time = tmp_time / 60 | 0; //hrs
      if (tmp_time > 23) {
        tmp_time = tmp_time / 24 | 0; //days
        if (tmp_time > 29) {
          tmp_time = tmp_time / 30 | 0; //months
          if (tmp_time > 11) {
            tmp_time = tmp_time / 12 | 0; //years
            tmp_time = tmp_time + " years";
          } else {
            tmp_time = tmp_time + " months";
          }
        } else {
          tmp_time = tmp_time + " days";
        }
      } else {
        tmp_time = tmp_time + " hours";
      }
    } else {
      tmp_time = tmp_time + " mins";
    }
    data.time = tmp_time;
    // setTime(tmp_time);
    setDisplay(true);
  }

  useEffect(() => {
    callGetItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OnClickGetDeal = async () => {
    ReactGa.event({
      category: 'Item clicked',
      action: item.description + '_id:' + item._id
    });
    window.open(item.link);
    var description = item.description;
    var id = item._id;
    try {
      const res = await fetch(window.env.API + '/itemclicked', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, description }),
      });
      // const data = await res.json();
      if (res.status === 201) {
        //console.log(data);
      } else {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const ItmeLoaded = () => {
    return (
      <>
          {/* <div className="card-item text-center" style={{ max_width: "15rem" }}> */}
          <div className="card-outer">
            <div className="card-text-area">
              <div className="card-mrp">
                <div className="card-price-text">MRP :</div>
                <div className="card-mrp-value"><s>&#8377;{item.mrp}</s></div>
              </div>
              <div className="card-mrp">
                <div className="card-price-text">Offer Price :</div>
                <div className="card-price-value">&#8377;{item.op}</div>
              </div>
              <div className="card-title">{item.description}</div>
              <div className="card-button" onClick={OnClickGetDeal}>
                <div className="card-button-text">Buy Now</div>
              </div>
            </div>
            <div className="card-image-area">
                <img src={imgUrl} onError={(e)=>{e.target.onerror = null; e.target.src=cart}} alt={props.description} className="card-image"/>
                <img src={imgvendor} alt={props.description} className="card-vendor-logo"/>
            </div>
          </div>
      </>
    );
  }

  return (display) ? (
    <>
      <ItmeLoaded />
    </>
  ) : "";
}


export default TrendingItemCard;