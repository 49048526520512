import React from "react";
import { NavLink } from "react-router-dom";
import "../../css/errorpage.css";
import cart from '../../asset/Logo_no_text.png'

const Errorpage = () => {
  return (
    <>
      <div className="home-page">
        <div>
          <div className="starsec"></div>
          <div className="starthird"></div>
          <div className="starfourth"></div>
          <div className="starfifth"></div>
        </div>
        <div className="lamp__wrap">
        <div className="lamp__wrap">
          <div className="lamp">
            <div className="cable"></div>
            {/* <div className="cover"></div> */}
            <div className="in-cover">
              {/* <div className="bulb"></div> */}
            </div>
            <img src={cart} alt="cart" width="100" height="100" />
            {/* <div className="light"></div> */}
          </div>
        </div>
        </div>
        <section className="error">
          <div className="error__content">
            <div className="error__message message">
              <h1 className="message__title">Page Not Found</h1>
              <p className="message__text">
                We're sorry, the page you were looking for isn't found here.
              </p>
            </div>
            <div className="error__nav e-nav">
              <div className="e-nav__link">
                {/* <div className="btn-home"> */}
                  <NavLink className="btn-home" to="/"> Back to Home </NavLink>
              </div>
            </div>
          </div>
        </section>

        {/* </a> */}
      </div>
    </>
  );
};

export default Errorpage;
