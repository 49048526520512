// Login.js

import React, { useState } from 'react';
import './Login.css'; // Import CSS file for styling

function SignUp() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password !== password2) {
      console.error('Password does not match');
    }
    try {
      const response = await fetch(window.env.API + '/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        throw new Error('Failed to signup');
      }
      console.log('Signup successful');
      // Redirect or perform other actions after successful login
    } catch (error) {
      console.error('Error signing up in:', error.message);
      // Handle error (e.g., display error message)
    }
  };

  return (
    <div className="login-container">
      <h2>SignUp</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input 
            type="text" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="password2">Repeat Password:</label>
          <input 
            type="password" 
            id="password2" 
            value={password2} 
            onChange={(e) => setPassword2(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="btn-login">Login</button>
      </form>
    </div>
  );
}

export default SignUp;