import React, { useEffect, useContext } from "react";
import HomeItem from "./HomeItem";
// import CategorySelector from "./CategorySelector";
import TrendingCards from "./TrendingCards";
// import SidePanel from "../SidePanel";
import "../Cards/card-style.css";
// import io from "socket.io-client";
import { userContext } from "../../App";
import SeoContent from '../pages/Seo'


import { ToastContainer} from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// socket.onAny((eventName, ...args) => {
// //  console.log("event: " + eventName + " val: " + args);
//  //toast("Wow so easy!");
//  //alert(args);
// });

const Home = () => {
  //console.log("Called Home.js");
  const { dispatch } = useContext(userContext);
  var newDealCount = 0;
  // const [newDealCount, setNewDealCount] = useState(0);
  // var connectionOptions = {
  //   "force new connection": false,
  //   reconnectionAttempts: "Infinity",
  //   timeout: 1000,
  //   //"transports" : ["http"]
  //   transports: ["websocket", "polling", "flashsocket"],
  // };
  // // const socket = io.connect("http://localhost:5000", connectionOptions);
  // const socket = io.connect("https://eashopping.herokuapp.com/", connectionOptions);
  // socket.on("new_item", (arg) => {
  //   // console.log(arg);
  //   setNewDealCount(newDealCount+1);
  //   toast("New Deal: \n" + arg, {
  //     position: "bottom-right",
  //     autoClose: 15000,
  //     hideProgressBar: true,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  //   //alert(arg)
  // });

  useEffect(() => {
    dispatch({ type: "Notification", payload: newDealCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDealCount]);

  return (
    <>
      {/* <div className="home-page"> */}
        <ToastContainer limit={1} />
        <div className="row d-flex" style={{ backgroundColor: "grey" }}>
          <div className="col-lg-2" style={{ backgroundColor: "#ffffff" }}>
            {/* Left side panel BurgerMenu
            Handaled in App.js*/}
          </div>
          <div className="col-lg-8 mt-5" style={{ backgroundColor: "white" }}>
            {/* <CategorySelector /> */}
            <TrendingCards />
            <a target="_blank"  rel="noreferrer" href="https://www.amazon.in/gp/bestsellers/computers?_encoding=UTF8&linkCode=ib1&tag=simplycheaper-21&linkId=a927b5ffe29803bab5d476a2099f3521&ref_=ihub_curatedcontent_f4fbaaa8-a059-47a9-b961-32aae09d16e8">Best Sellers in Computers & Accessories</a>
            {/* <div className="grid-cotntainer"> */}
              <HomeItem />
              {/* <HomeComponent/> */}
              <SeoContent />
            {/* </div> */}
          </div>
          <div className="col-lg-2 " style={{ backgroundColor: "#ffffff" }}>
            {/* Right side panel */}
            {/* <SidePanel/> */}
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default Home;
