import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import EmptyItemCard from "../Cards/EmptyItemCard";
import GetTrendingItem from '../Apis/GetTrendingItem';
import "../Cards/card-style.css";
import NoItem from './NoItem';
import CardSkeleton from '../utils/CardSkeleton';

const Trending = props => {
    const [pageNumber, setPageNumber] = useState(1);
   
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    var searchQuery = query.get('search');
    let loading, error, items, hasMore, noitem;
    if (searchQuery == null) {
        ({ loading, error, items, hasMore, noitem  } = GetTrendingItem( pageNumber, ""));
    } else {
        ({ loading, error, items, hasMore, noitem  } = GetTrendingItem( pageNumber, searchQuery));
    }

    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore]);

    useEffect(() => {
        setPageNumber(1);
    }, []);

    return (
        <div>
            <div className="home-page">
                <div className="grid-container mt-5">
                    {
                        items.map((items1) => (
                            items1
                                .map((item, index) => {
                                    if (items1.length === index + 1) {
                                        return <div ref={lastItemRef} key={item._id}>
                                            <EmptyItemCard item={item} />
                                        </div>
                                    } else {
                                        return <div key={item._id}>
                                            <EmptyItemCard item={item} />
                                        </div>
                                    }
                                })
                        ))
                    }
                </div>
                <div>{noitem && <NoItem/>}</div>
                <div>{loading && <CardSkeleton/>}</div>
                <div>{error && 'Error'}</div>
            </div>
        </div>
    )
}

export default Trending
// export default withRouter(Trending)
