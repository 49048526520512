import React, { useEffect, useState} from "react";
import '../Cards/card-style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faThumbsUp, faThumbsDown } from '@fortawesome/fontawesome-free-regular'
// import { faComment, faClock, faThumbsUp, faThumbsDown, faEye } from '@fortawesome/fontawesome-free-regular'
import imgexp from '../../asset/expired.jpg';
import cart from '../../asset/Logo_no_text.png';
import CommentPreview from './CommentsPreview';
import DislikePopup from './DislikePopup';
// import ReactGa from 'react-ga4';

const EmptyItemCard = props => {
  const [item, setItem] = useState({});
  const [imgUrl, setImgUrl] = useState('');
  const [like, setLike] = useState('');
  const [dislike, setDislike] = useState('');
  // const [time, setTime] = useState('');
  const [expired, setExpired] = useState('new');
  const [display, setDisplay] = useState(false);
  const [id, setId] = useState("");
  const [preview, setPreview] = useState(false);
  const [dislikePreview, setDislikePreview] = useState(false);
  const [viewcount, setViewCount] = useState('');
  // const [development, setDevelopment] = useState(false);


  const getEnvironment = async () => {
    // if (window.env.API.contains("localhost")) {
    // setDevelopment(true);
    // console.log(window.env.API);
    // }
  }

  const callGetItem = async () => {
    var data = props.item;
    setItem(data);
    setImgUrl(data.imgsrc);
    setId(data._id);
    setLike(data.like);
    setDislike(data.dislike);
    setExpired(data.status);
    // setViewCount(data.viewcount);
    var curr_time = new Date().getTime() / 1000 | 0;
    var tmp_time = (curr_time - data.time) / 60 | 0; //mins
    if (tmp_time > 59) {
      tmp_time = tmp_time / 60 | 0; //hrs
      if (tmp_time > 23) {
        tmp_time = tmp_time / 24 | 0; //days
        if (tmp_time > 29) {
          tmp_time = tmp_time / 30 | 0; //months
          if (tmp_time > 11) {
            tmp_time = tmp_time / 12 | 0; //years
            tmp_time = tmp_time + " years";
          } else {
            tmp_time = tmp_time + " months";
          }
        } else {
          tmp_time = tmp_time + " days";
        }
      } else {
        tmp_time = tmp_time + " hours";
      }
    } else {
      tmp_time = tmp_time + " mins";
    }
    data.time = tmp_time;
    // setTime(tmp_time);
    setDisplay(true);
  }

  const addLike = async () => {
    try {
      const res = await fetch(window.env.API + '/addlike', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id })
      });
      if (res.status === 201) {
        setLike(like + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleDislike = async () => {
    if (expired === "new") {
      setDislikePreview(true);
    }
    try {
      const res = await fetch(window.env.API + '/adddislike', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id })
      });
      if (res.status === 201) {
        setDislike(dislike + 1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    callGetItem();
    getEnvironment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function AddMessage(props) {
    return (props.trigger) ? (
      <>
        <CommentPreview item={item} setTrigger={setPreview} id={id} />
      </>
    ) : "";
  }
  function ExpirePopup(props) {
    return (props.trigger) ? (
      <>
        <DislikePopup item={item} setTrigger={setDislikePreview} id={id} />
      </>
    ) : "";
  }
  // const ExpImg = () => {
  //   return (expired === "expired") ?
  //     <>
  //       <img src={imgexp} alt={props.imgsrc} className="card-img-top" />
  //     </> : ""
  // }
  const OverlayImage = () => {
    return (expired === "expired") ?
      <>
        <div className="imgtop">
          <img src={imgexp} alt={props.description} className="imgOverlay" />
          <img src={imgUrl} onError={(e) => { e.target.onerror = null; e.target.src = cart }} alt={props.description} className="imgOriginalDiv" />
        </div>
      </> :
      <>
        <div className="imgtop">
          <img src={imgUrl} onError={(e) => { e.target.onerror = null; e.target.src = cart }} alt={props.description} className="imgOriginalDiv" />
        </div>
      </>

  }

  const OnClickItemDetails = async => {
    //If any change in below code,
    //please change in ../../SEO/gen_sitemap.js file as well
    var url_description = item.description.toLowerCase();
    url_description = url_description.replace(/[^a-z0-9\s-]/g, '');
    url_description = url_description.replace(/\s+/g, '-');
    url_description = url_description.replace(/-+/g, '-');
    // console.log("description=", item.description)
    // console.log("url_description=", url_description)
    var url_link = "/loot-deal/" + url_description + "/" + item._id
    console.log("url_link=", url_link)
    console.log("item=", item);
    window.open(url_link);
  }

  //Moved this api to ItemDetails page
  // const OnClickGetDeal = async () => {
  //   ReactGa.initialize("G-0TB1B3EK5X");
  //   ReactGa.event({
  //     category: 'Item clicked',
  //     action: item.description + '_id:' + item._id
  //   });
  //   window.open(item.link);
  //   var description = item.description;
  //   var id = item._id;
  //   try {
  //     const res = await fetch(window.env.API + '/itemclicked', {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({ id, description }),
  //     });
  //     // const data = await res.json();
  //     if (res.status === 201) {
  //       setViewCount(viewcount + 1);
  //       //console.log(data);
  //     } else {
  //       const error = new Error(res.error);
  //       throw error;
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const ItmeLoaded = () => {
    return (
      <>
        <div className='grid-item'
        >
          {/* <div className="card-item text-center" style={{ max_width: "15rem" }}> */}
          <div className="card-item text-center" style={{ max_width: "15rem" }}>
            <div className="overflow">
              <div className="mt-1">
                {/*<img src={imgUrl} alt={props.imgsrc} className="card-img-top"/>*/}
                <OverlayImage />
              </div>
            </div>
            <div className="card-body text-dark">
              {/* item added time */}
              {/* <div className="d-flex ">
                <div> <FontAwesomeIcon icon={faClock} size="xs" />&nbsp;&nbsp;</div>
                <div className="font-weight-light">{time} </div>
              </div> */}
              <h4 className="card-title">{props.name}</h4>
              <p className="text-description">{item.description}</p>
              <div className="d-flex justify-content-between px-3 pb-0">
                <p className="text-secondary"><s>&#8377;{item.mrp}</s></p>
                {/* <p className="text-dark bg-light ps-1 pe-1">{item.vendor}</p> */}
                <p className="text-primary">&#8377;{item.op}</p>
              </div>
              {/* <div className="btn-get-deal" onClick={() => window.open(item.link, "_blank")}> */}
              <div className="btn-get-deal" onClick={OnClickItemDetails}>
              {/* <div className="btn-get-deal" onClick={OnClickGetDeal}> */}
                GET DEAL @{item.vendor}
              </div>
              <div className="d-flex justify-content-between ps-3 pe-3 mt-0">
                <div className="d-flex" onClick={addLike}>
                  <div> <FontAwesomeIcon icon={faThumbsUp} size="xs" />&nbsp;</div>
                  {/* <div className="font-weight-light">{like}</div> */}
                </div>
                <div className="d-flex" onClick={() => setPreview(true)}>
                  <div> <FontAwesomeIcon icon={faComment} size="xs" />&nbsp;</div>
                  {/* <div className="font-weight-light">{item.comment}</div> */}
                </div>
                <div className="d-flex" onClick={handleDislike}>
                  <div> <FontAwesomeIcon icon={faThumbsDown} size="xs" />&nbsp;</div>
                  {/* <div className="font-weight-light">{dislike}</div> */}
                </div>
                {/* {(development) && (
                  // {(window.env.API.contains("localhost")) && (
                  // <div className="d-flex">
                  //   <div> <FontAwesomeIcon icon={faEye} size="xs" />&nbsp;</div>
                  //   <div className="font-weight-light">{viewcount}</div>
                  // </div>
                  <div>test</div>
                )
                } */}
              </div>
            </div>
          </div>
        </div>
        <AddMessage trigger={preview} setTrigger={setPreview} />
        <ExpirePopup trigger={dislikePreview} setTrigger={setDislikePreview} setExp={setExpired} />
      </>
    );
  }

  return (display) ? (
    <>
      <ItmeLoaded />
    </>
  ) : "";
}


export default EmptyItemCard;