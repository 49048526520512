import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../Cards/card-style.css';
import '../Cards/EmptyItemCard';

const CardSkeleton = () => {
    return (
      <>
        <div className='grid-item'
        >
          <div className="card-item text-center" style={{ max_width: "15rem" }}>
            <div className="overflow">
              <div className="mt-1">
                <div className="imgtop">
                        <Skeleton height='10rem' width='100%'/>
                </div>
              </div>
            </div>
            <div className="card-body text-dark">
              <h4 className="card-title"><Skeleton count={2}/></h4>
              <div className="d-flex justify-content-between px-3 pb-0">
                <Skeleton containerClassName="flex-1"/>
                <Skeleton containerClassName="flex-1"/>
              </div>
              <div className="btn-get-deal">
                <SkeletonTheme baseColor= "#25bb92" highlightColor=" #25bb90">
                    <Skeleton  />
                </SkeletonTheme>
              </div>
              <div className="d-flex justify-content-between ps-3 pe-3 mt-0">
                  <Skeleton containerClassName="flex-1"/>
                  <Skeleton containerClassName="flex-1"/>
                  <Skeleton containerClassName="flex-1"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

const TrendingItemSkeleton= () => {
    return (
        <>
            <div className="card-outer">
              <div className="card-text-area-skeleton">
              </div>
              {/* <div className="card-image-area-skeleton">
              </div> */}
            </div>
        </>
      );
}

const TrendingItemsSkeleton= () => {
    return (
        <div className="trending-grid-container">
            <TrendingItemSkeleton containerClassName="flex-1" />
            <TrendingItemSkeleton containerClassName="flex-1" />
            <TrendingItemSkeleton containerClassName="flex-1" />
            <TrendingItemSkeleton  containerClassName="flex-1"/>
        </div>
    )
}

const ItemsSkeleton= () => {
    return (
        <div className="grid-container">
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
            <CardSkeleton  containerClassName="flex-1"/>
        </div>
    )
}
export {TrendingItemsSkeleton};
export default ItemsSkeleton;
