import { useEffect, useState } from 'react'

export default function GetTrendingItem(pageNumber, searchTerm) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [noitem, setNoItem] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(false);
        void async function fetchData() {
            try {
                const res = await fetch(window.env.API + '/gettrendingitem', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ pageNumber, searchTerm }),
                });
                const data = await res.json();
                if (res.status === 201) {
                    setItems(prevItems => {
                        return [...prevItems, data.items]
                    });
                    setHasMore(data.hasMore);
                    setNoItem(data.noItem);
                    setLoading(false);
                    // console.log("from API: loading:" + loading);
                } else {
                    setError(true);
                    const error = new Error(res.error);
                    throw error;
                }
            } catch (err) {
                console.log(err);
            }
        }();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);


    return { loading, error, items, hasMore, noitem };
}
