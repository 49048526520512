import React, { useEffect, useState} from "react";
import '../Cards/card-style.css';
import cart from '../../asset/Logo_no_text.png';
import ReactGa from 'react-ga4';

const ItemDetailsCard = props => {
  const [item, setItem] = useState({});
  const [imgUrl, setImgUrl] = useState('');
  // const [like, setLike] = useState('');
  // const [dislike, setDislike] = useState('');
  // const [time, setTime] = useState('');
  const [display, setDisplay] = useState(false);
  const [viewcount, setViewCount] = useState('');
  // const [development, setDevelopment] = useState(false);


  const callGetItem = async () => {
    var data = props.item;
    setItem(data);
    setImgUrl(data.imgsrc);
    // setLike(data.like);
    // setDislike(data.dislike);
    // setViewCount(data.viewcount);
   
    // setTime(tmp_time);
    setDisplay(true);
  }

  useEffect(() => {
    callGetItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const OnClickGetDeal = async () => {
    ReactGa.initialize("G-0TB1B3EK5X");
    ReactGa.event({
      category: 'Item clicked',
      action: item.description + '_id:' + item._id
    });
    window.open(item.link);
    var description = item.description;
    var id = item._id;
    try {
      const res = await fetch(window.env.API + '/itemclicked', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, description }),
      });
      // const data = await res.json();
      if (res.status === 201) {
        setViewCount(viewcount + 1);
        //console.log(data);
      } else {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const ItmeLoaded = () => {
    const divTopPadding = {
      padding: '100px' // Adjust the value as needed
    };
    return (
      <div style={divTopPadding}>
        
          {/* <div className="card-item text-center" style={{ max_width: "15rem" }}> */}
          <h1 style={{ width: '100%', textAlign: 'center' }}>{item.description}</h1>
                <img src={imgUrl} onError={(e) => { e.target.onerror = null; e.target.src = cart }} alt={props.description} className="imgItemDetailsDiv" />
              
            <div className="overflow">
              <div className="mt-1">
               </div>
            </div>

            <div className="card-body text-dark">
              <div className="d-flex justify-content-between px-3 pb-0">
                <p className="text-secondary"><s>MRP: &#8377;{item.mrp}</s></p>
                {/* <p className="text-dark bg-light ps-1 pe-1">{item.vendor}</p> */}
                <p className="text-primary">Offer Price: &#8377;{item.op}</p>
              </div>
              {/* <div className="btn-get-deal" onClick={() => window.open(item.link, "_blank")}> */}
              <div className="btn-get-deal" onClick={OnClickGetDeal}>
              {/* <div className="btn-get-deal" onClick={OnClickGetDeal}> */}
                Buy from {item.vendor}
              </div>
              
            </div>
      </div>
    );
  }

  return (display) ? (
    <>
      <ItmeLoaded />
    </>
  ) : "";
}


export default ItemDetailsCard;