import React, { useEffect, useState, useRef } from "react";
// import Card from "./Card";
import profilepic from "../../asset/male.jpg";
import loading from '../../asset/loading.gif';

const OldComments = (props) => {
  
  return  (props.comments.length>0)?(
    <>
      <div>
        {props.comments.map((item, index) => (
          <Comment key={index} comment={item} divRef={props.divRef}/>
        ))}
      </div>
    </>
  ): 
  <>
  <div className="pt-5">
    <h2>No Comments so far...</h2>
  </div>
  
  </>;
};
const Comment = (props) => {
  
  useEffect(() => {
    props.divRef.current.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <>
      <div ref={props.divRef} className="comment-card">
        <div className="d-flex justify-content-start">
          <img src={profilepic} alt="profile" className="profile-img" />
          <p className="text-left">
          <b className="text-primary">{props.comment.uid} </b>
          <b> &nbsp;: </b>
            {props.comment.message}
          </p>
        </div>
        <div className="d-flex justify-content-end pe-1">
          <div className="custom-font-weight-light"> {props.comment.time}</div>
        </div>
      </div>
    </>
  );
};





const CommentsPreview = (props) => {
  const divRref = useRef(null);

  const [message, setMessage] = useState("");
  const [uid, setUid] = useState("");
  const [oldcomments, setOldcomments] = useState([{}]);
  // const [listitems, setListitems] = useState({});
  const [update, setUpdate] = useState(false);

  const getComment = async () => {
    const userid = getCookie("_gau");
    if(userid === ""){
      let r = Math.random().toString(36).substring(7);
      let uc = "_gau=" + r;
      document.cookie = uc;
    }
    setUid(userid);
    let itemid = props.id;
    try {
      const res = await fetch(window.env.API + "/getcomment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemid }),
      });
      if (res.status === 201) {
        const data = await res.json();
        if (data) {
          // var length = data.comments.comments.length;
          var i = 0;
          var comments = [];
          for (i = 0; i < data.comments.comments.length; i++) {
            comments.push(data.comments.comments[i]);
          }
          setOldcomments(comments);
          setUpdate(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  const addComment = async (e) => {
    e.preventDefault();
    const itemId = props.id;
    try {
      // setUid("_" + Math.random().toString(36).substr(2, 9));
      const res = await fetch(window.env.API + "/addcomment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ itemId, uid, message }),
      });
      if (res.status === 201) {
        //console.log("comment added");
        await getComment();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="comment-preview">
        <div className="comment-preview-inner">
          <h4>Comments</h4>
          {/*
             <Card
                description={props.item.description}
                imgsrc={props.item.imgsrc}
                mrp={props.item.mrp}
                op={props.item.op}
                vendor={props.item.vendor}  />
                <ul> {oldcomments} </ul>,   
             */}
          <div className="comment-box">
            {/*<OldComments comments={oldcomments} />*/}
         
          { update? <OldComments comments={oldcomments} divRef={divRref}/> : <> 
          
          <img src={loading} alt="photo1" className="card-img-top" />
          <div className="pt-5">
          <h2>Loading Comments...</h2>
          </div>
          </> }
          </div>
          <div className="comment-form-content">
            <form id="add_comment_form" method="POST">
              <textarea
                type="text"
                id="form_description"
                className="add_comment_input"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Add your comment"
                rows="4"
                required="true"
              />
            </form>
            <div className="save-btn">
              <button
                className="btn btn-success"
                type="submit"
                name="addcomment"
                id="addcomment"
                value="AddItem"
                onClick={addComment}
              >
                SEND
              </button>

              <button
                className="btn btn-danger"
                onClick={() => props.setTrigger(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentsPreview;
