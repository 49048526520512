import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, withRouter, useLocation } from 'react-router-dom'
import EmptyItemCard from "../Cards/EmptyItemCard";
import GetItemByCat from '../Apis/GetItemByCat';
// import loadingImg from '../../asset/loading.gif';
import NoItem from './NoItem';
import CardSkeleton from '../utils/CardSkeleton';
import "../Cards/card-style.css";

const Category = props => {
    const [category, setCategory] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    let { id } = useParams();
   
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    var searchQuery = query.get('search');
    let loading, error, items, hasMore, noitem;
    if(category != null){
        if (searchQuery == null) {
            ({ loading, error, items, hasMore, noitem } = GetItemByCat(category, pageNumber, ""));
        } else {
            ({ loading, error, items, hasMore, noitem } = GetItemByCat(category, pageNumber, searchQuery));
        }
    }
    //const { loading, error, items, hasMore } = GetItemByCat(category, pageNumber);
    const observer = useRef();
    const lastItemRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                //console.log('visible');
                setPageNumber(prevPageNumber => prevPageNumber + 1);
                //console.log(pageNumber);
            }
        })
        if (node) observer.current.observe(node)
        //console.log(node);
    }, [loading, hasMore]);

    useEffect(() => {
        setCategory(id);
        setPageNumber(1);
    }, [id]);

    return (
        (id != null)?
        <div>
            <div className="home-page">
                <div className="grid-container mt-5">
                    {
                        items.map((items1) => (
                            items1.map((item, index) => {
                                //console.log("item=>" + item.description + "<=item")
                                 //console.log(items1.length);
                                if (items1.length === index + 1) {
                                    return <div ref={lastItemRef} key={item._id}>
                                        <EmptyItemCard item={item} />
                                    </div>
                                } else {
                                    return <div key={item._id}>
                                        <EmptyItemCard item={item} />
                                    </div>
                                }
                            })
                        ))
                    }
                </div>
                <div>{noitem && <NoItem/>}</div>
                {/* <div>{loading && <img src={loadingImg} alt="Loading..." className="loading-img" />}</div> */}
                <div>{loading && <CardSkeleton/>}</div>
                <div>{error && 'Error'}</div>
            </div>
        </div> : ""
    )
}

export default withRouter(Category)
