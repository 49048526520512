import React, { createContext, useReducer, useEffect } from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./components/pages/Home";
import AdminHome from "./components/Admin/pages/AdminHome";
import Login from "./components/pages/Login";
import ProductListPage from "./components/pages/SiteMap";
import SignUp from "./components/pages/Signup";
import LogOut from "./components/pages/Logout";
import AddItem from "./components/Admin/AddItem";
import UpdateItem from "./components/Admin/UpdateItem";
import SearchTerm from "./components/Admin/SearchTerm";
import ItemClicked from "./components/Admin/ItemClicked";
import AdminLog from "./components/Admin/AdminLog";
import Category from "./components/pages/Category";
import UnderPrice from "./components/pages/UnderPrice";
import Trending from "./components/pages/Trending";
import AboutUs from "./components/pages/AboutUs";
import ItemDetalis from "./components/pages/ItemDetails";
import BlogHomePage from "./components/blogs/BlogHome";
import Admin from "./components/pages/Admin";
import Errorpage from "./components/pages/Errorpage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Route, Switch } from "react-router-dom";
import ReactGa from "react-ga";
import { initialState, reducer } from './reducer/UseReducer';

export const userContext = createContext();
function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // ReactGa.initialize("UA-180229272-3");
      ReactGa.initialize("G-0TB1B3EK5X");
      ReactGa.pageview(window.location.pathname + window.location.search);
    }
  });
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <userContext.Provider value={{ state, dispatch }}>
        <Sidebar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Azscaledd">
            <AddItem />
          </Route>
          {/* } */}

          <Route path="/update">
            <UpdateItem />
          </Route>

          <Route path="/category/:id">
            <Category />
          </Route>

          <Route path="/loot-deal/:description/:id">
            <ItemDetalis/>
          </Route> 

          <Route path="/price/:id">
            <UnderPrice />
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Szscalearchterm">
            <SearchTerm />
          </Route>
          {/* } */}

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Izscaltemclicked">
            <ItemClicked />
          </Route>
          {/* } */}

          <Route path="/Azscaldminlog">
            <AdminLog />
          </Route>

          {process.env.NODE_ENV === 'development' &&
            <Route path="/blogs">
              <BlogHomePage />
            </Route>
          }

          <Route path="/loot-deals">
            <Trending />
          </Route>

          <Route path="/trending">
            <Trending />
          </Route>

          <Route path="/aboutus">
            <AboutUs />
          </Route>

          <Route path="/project/shiva/admin">
            <Admin />
          </Route>

          <Route path="/latest">
            <Home />
          </Route>

          {/* {process.env.NODE_ENV === 'development' && */}
          <Route path="/Uzscalepdate">
            <AdminHome />
          </Route>
          {/* } */}

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/sitemap">
            <ProductListPage />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/logout">
            <LogOut />
          </Route>

          <Route>
            <Errorpage />
          </Route>
        </Switch>
      </userContext.Provider>
    </>
  );
}

export default App;
