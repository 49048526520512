import {useEffect, useState} from 'react'

export default function GetItemById(id) {
    const [item, setItem] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        setItem();
        // console.log("APIid", window.env)
    }, [id]);

    useEffect( () => {
        void async function fetchData() {
            if(id){
                try {
                    const res = await fetch(process.env.REACT_APP_API + '/getitembyid', {
                    // const res = await fetch('http://localhost:5010/getitembyid', {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({id }),
                    });
                    const data = await res.json();
                    if (res.status === 201) {
                        setItem(data.item);
                    } else {
                        setError(true);
                        const error = new Error(res.error);
                        throw error;
                    }
                } catch (err) {
                    console.log(err);
                }
        }
        }();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return {error, item};
}
