import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const SidebarLink = styled(Link)`
    display: flex;
    color: #f9f9f9;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        background: #6c757d;
        color: #001e38;
        border-left: 4px solid #f9f9f9;
        border-right: 4px solid #f9f9f9;
        border-radius: 15px;
        cursor: pointer;
    }
`;
const SidebarLabel = styled.span`
   margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;

    &:hover {
        background: #6c757d;
        color: #001e38;
        border-left: 5px solid #00d09f;
        border-right: 5px solid #00d09f;
        border-radius: 15px;
        cursor: pointer;
    }
`;


const Submenu = ({item}) => {
    const [subnav, setSubnav] = useState(false);
    if(!item.path){
        item.path = "#";
    }
    const showSubnav = () => setSubnav(!subnav);
    return(
        <>
            <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav 
                    ? item.iconOpened 
                    : item.subNav 
                    ? item.iconClosed 
                    : null}
                </div>
            </SidebarLink>
            {subnav && item.subNav.map((item,index) => {
                return(
                    <DropdownLink to={item.path} key={index}>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                )
            })}
        </>
    );
}

export default Submenu;