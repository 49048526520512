import React from "react";
import { useLocation } from 'react-router-dom'
import cart from '../../asset/Logo_no_text.png'
// import wheel from '../../asset/wheel.png'

// import { NavLink } from "react-router-dom";
import "../../css/errorpage.css";
import ReactGa from 'react-ga4';

const Errorpage = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);
  var searchQuery = query.get('search');
  // var ext_link = "https://www.amazon.in/gp/search?ie=UTF8&tag=simplycheaper-21&linkCode=ur2&linkId=829ec64345d75fe47e40ffec4fcffbef&camp=3638&creative=24630&index=aps&keywords=" + searchQuery;
  var ext_link = "https://www.amazon.in/gp/search?ie=UTF8&tag=shoppingd09fa-21&linkCode=ur2&linkId=2024a05fa37fe0437cce32e3e4d53c57&camp=3638&creative=24630&index=aps&keywords=" + searchQuery;
  const handleClick = async () => {
    window.open(ext_link);
    ReactGa.event({
      category: 'External Search Amazon',
      action: searchQuery
    });
    var description = searchQuery;
    var id = "External Search Amazon";
    try {
      const res = await fetch(window.env.API + '/itemclicked', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, description }),
      });
      if (res.status === 201) {
        //console.log(data);
      } else {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <div className="home-page">
        <div>
          <div className="starsec"></div>
          <div className="starthird"></div>
          <div className="starfourth"></div>
          <div className="starfifth"></div>
        </div>
        <div className="lamp__wrap">
          <div className="lamp">
            <div className="cable"></div>
            {/* <div className="cover"></div> */}
            <div className="in-cover">
              {/* <div className="bulb"></div> */}
            </div>
            <img src={cart} alt="cart" width="100" height="100" />
            {/* <div className="light"></div> */}
          </div>
        </div>
        <section className="error">
          <div className="error__content">
            <div className="error__message message">
              <h1 className="message__title">No Deals Found</h1>
              <p className="message__text">
                We're sorry, we could not find any deals.
              </p>
            </div>
            <div className="error__nav e-nav">
              {/* <div className="e-nav__link"> */}
              {/* <div className="btn-home"> */}
              {/* <NavLink className="btn-home" to="/"> Back To Home</NavLink> */}
              <button className="btn btn-primary" onClick={handleClick}> Check Store </button>
              {/* </div> */}
            </div>
          </div>
        </section>
        {/* </a> */}
      </div>
      <div >
        {/* <img className="all_cart" src={cart} width="500" height="500" /> */}
        {/* <img className="wheel" src={wheel} width="500" height="500" /> */}

      </div>
    </>
  );
};

export default Errorpage;
