import React, { useEffect, useState } from 'react';
import '../../css/AboutUs.css';

const Admin = () => {
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const userCookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith('user='));

        if (userCookie) {
            const userType = userCookie.split('=')[1];
            setUserType(userType);
        }

        window.google.accounts.id.initialize({
            client_id: 'developer.hbdcreation@gmail.com',
            callback: handleSignIn,
            auto_select: true,
        });
    }, []);

    const handleSignIn = (response) => {
        // Handle the sign-in response from Google
        console.log(response)
        if (response.credential) {
            // User is signed in successfully
            // const idToken = response.credential;
            // Perform necessary actions with the idToken
        } else {
            // Error occurred during sign-in
            console.log(response.error);
        }
    };

    const renderPersonalizedContent = () => {
        if (userType === 'new') {
            return <p>Welcome, new user! Here's a special offer for you.</p>;
        } else if (userType === 'returning') {
            return <p>Welcome back! Explore our latest products and discounts.</p>;
        } else {
            return <p>Welcome to our website! Learn more about what we do.</p>;
        }
    };

    return (
        <div className="about-us-container">
            <div className="about-us-content">
                <h1>Admin Page</h1>
                <button className="google-sign-in-button" id="googleSignInButton" onClick={() => window.google.accounts.id.prompt()}>
                    Sign in with Google
                </button>
                {renderPersonalizedContent()}
                <p className="disclaimer">Disclaimer: Please note that we are not responsible for any third-party websites that may be linked from our website.</p>
            </div>
        </div>
    );
}

export default Admin;
