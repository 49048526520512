import React from 'react';
import './Login.css'; // Import CSS file for styling

class LogoutPage extends React.Component {
  handleLogout = async () => {
    try {
      const response = await fetch(window.env.API + '/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        // Optionally, you can send additional data with the request if needed
        // body: JSON.stringify({ /* Additional data */ })
      });

      if (response.ok) {
        localStorage.removeItem('token');
        window.location.href = '/login'; // Redirect to the login page
      } else {
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  render() {
    return (
      <div className="login-container">
        <h1>Logout</h1>
        <button onClick={this.handleLogout}  className="btn-login">Logout</button>
      </div>
    );
  }
}

export default LogoutPage;
