import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import './admin.css'
import Errorpage from "../../components/pages/Errorpage";

const AdminLog = props => {
    const [item, setItem] = useState([{}]);
    const [pageNumber, setPageNumber] = useState(1);
    const [error, setError] = useState(null);

    const handleNext = () => {
        setPageNumber(pageNumber+1);
    }

    const handlePrevious = () => {
        if(pageNumber > 1){
            setPageNumber(pageNumber-1);
        }
    }

    const getData = async () => {
        const token = localStorage.getItem('token');
        try {
            const res = await fetch(window.env.API + '/getadminlog', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({pageNumber }),
            });
            if (!res.ok) {
                if (res.status === 401) {
                throw new Error('Unauthorized');
                } else {
                throw new Error('Failed to fetch data');
                }
            }
            const data = await res.json();

            if (res.status === 422) {
                console.log("Invalid Data. res");
            } else {
                setItem(data.item);
                // console.log("item=" + data.item[0]);
                // console.log("item=" + item[0].count);
            }
        } catch(error) {
            setError(error.message);
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    if (error) {
        return <Errorpage/>;
    }

    return (
        <>
            <h4 className="home-page">Recent Search Terms</h4>
                <div className="table-container">
                <div className="mt-1">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Index</th>
                                <th>Time</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>ID</th>
                                <th>IP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item && item.map((el, i) => {
                                return (
                                    <tr>
                                        <td>{ i+1 + ((pageNumber-1) * 30)}</td>
                                        <td>{el.time}</td>
                                        <td>{el.type}</td>
                                        <td>{el.adminLog}</td>
                                        <td>{el.id}</td>
                                        <td>{el.ip}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <div className="mx-2">
                        <button className="btn btn-primary me-4" onClick={handlePrevious}>Previous</button>
                        <button className="btn btn-primary" onClick={handleNext}>Next</button>
                    </div>
                </div>
                </div>
        </>
    );
}

export default AdminLog;