import React, { Component, useEffect, useState } from "react";
import './admin.css';
import Card from '../Cards/Card';
import Iframe from 'react-iframe';
import Errorpage from "../../components/pages/Errorpage";

let item = {};

function Preview(props) {
  return (props.trigger) ? (
    <>
      <div className="preview">
        <div className="preview-inner">
          <h4>Preview Item</h4>
          <div className="preview-card d-flex justify-content-around">
            <div className="card-inner">
              <Card
                description={props.description}
                imgsrc={props.imgsrc}
                mrp={props.mrp}
                op={props.op}
                vendor={props.vendor}
                like={props.like}
                comment={props.comment}
                time={props.time}
              />
            </div>
            <div className="ml-2">
              <Iframe url={""}
                width="270px"
                height="370px"
                id="myId"
                className="card"
                display="initial"
                position="relative" />
            </div>
          </div>
          <div className="save-btn">
            <button className="btn btn-danger" onClick={() => props.setTrigger(false)} >CANCEL</button>
            <button className="btn btn-success" type="submit" name="updateitem" id="updateitem"
              value="UpdateItem" onClick={postData}>SUBMIT</button>
          </div>

        </div>
      </div>
    </>
  ) : "";
}

const postData = async (e) => {
  e.preventDefault();
  const { id, description, imgsrc, link, vendor, mrp, op, status, like, comment } = item;
  const res = await fetch(window.env.API + '/updateitem', {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id, description, imgsrc, link, vendor, status, mrp, op, like, comment
    })
  });
  //const data = await res.message;
  if (res.status === 422) {
    window.alert("Invalid Data. res");
    console.log("Invalid Data. res");
  } else {

    window.alert("Item updated Sucessful");
    console.log(res.status);
  }
}


const NewForm = () => {
  const [imgsrc, setImgsrc] = useState("");
  const [mrp, setMrp] = useState("");
  const [op, setOp] = useState("");
  const [vendor, setVendor] = useState("");
  const [like, setLike] = useState("");
  const [dislike, setDislike] = useState(""); 
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [status, setStatus] = useState("");
  const [tags, setTags] = useState("");
  const [preview, setPreview] = useState(false);
  const [validToken, setValidToken] = useState(false);
  // const [comment, setComment] = useState("");
  // const [time, setTime] = useState(""); 
  let comment;
  let time;
  item = { id, description, imgsrc, link, vendor, mrp, op, status, like, dislike, tags };

  const getData = async (e) => {
    e.preventDefault();
    //const id = id;
    //const { description,imgsrc,link,vendor,mrp,op,time,like,comment } = item;
    const res = await fetch(window.env.API + '/getitembyid', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id
      })
    });

    const data = await res.json();
    if (res.status === 422 || !data) {
      window.alert("Invalid Data. res");
      console.log("Invalid Data. res");
    } else {
      setDescription(data.description);
      setLike(data.like);
      setDislike(data.dislike);
      setTags(data.tags);
      setMrp(data.mrp);
      setOp(data.op);
      setImgsrc(data.imgsrc);
      setLink(data.link);
      setVendor(data.vendor);
      setStatus(data.status);
      setId(data._id);
      //window.alert("Item get Sucessful");
      console.log(res.status);
    }
  }

  const validateToken = async () => {
    const token = localStorage.getItem('token');
    const res = await fetch(window.env.API + '/validateToken', {
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'Authorization': `Bearer ${token}` 
      }
    });
    if(res.status === 200){
      setValidToken(true);
    }
  }

  useEffect(() => {
    validateToken();
  },[]);

  if(!validToken) {
    return  <Errorpage/>;
  }
  return (
    <>
      <div className="add_item_form">
        <div className="container-fluid">
          <div className="col-lg-11 offset-lg-1 ">
            <div className="add_item_form_container pt-1">
              <div className="add_item_form_title">UPDATE ITEM</div>
              <form id="add_item_form" method="POST">
                <div className="form_content">
                  <textarea
                    type="text"
                    id="form_description"
                    className="add_item_form_input"
                    onChange={(e) => setId(e.target.value)}
                    placeholder="Product Id"
                    rows="1"
                    required="false"

                  />

                  <div className="add_item_form_button">
                    <button
                      type="submit"
                      className="button add_item_submit_button"
                      value="send message"
                      onClick={getData}
                    >
                      Get Item
                    </button>
                  </div>
                  <label> Description
                    <textarea
                      type="text"
                      id="form_description"
                      className="add_item_form_input"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      rows="4"
                      required="true"

                    />
                  </label>
                  <label> IMG
                    <textarea
                      type="text"
                      id="form_imgsrc"
                      className="add_item_form_input"
                      onChange={(e) => setImgsrc(e.target.value)}
                      value={imgsrc}
                      required="true"
                      rows="4"
                    />
                  </label>
                  <label> Link
                    <textarea
                      type="text"
                      id="form_link"
                      className="add_item_form_input"
                      onChange={(e) => setLink(e.target.value)}
                      value={link}
                      required="true"
                      rows="2"
                    />
                  </label>

                  <div className="small-inputs"></div>
                  <label> Vendor
                    <input
                      type="text"
                      id="form_vendor"
                      className="add_item_form_input_small"
                      onChange={(e) => setVendor(e.target.value)}
                      value={vendor}
                      required="true"
                    />
                  </label>
                  <label> MRP
                    <input
                      type="number"
                      id="form_mrp"
                      className="add_item_form_input_small"
                      onChange={(e) => setMrp(e.target.value)}
                      value={mrp}
                      required="true"
                    />
                  </label>
                  <label> OP
                    <input
                      type="number"
                      id="form_op"
                      className="add_item_form_input_small"
                      onChange={(e) => setOp(e.target.value)}
                      value={op}
                      required="true"
                    />
                  </label>

                  <label> Status
                    <input
                      type="string"
                      id="form_time"
                      className="add_item_form_input_small"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                      required="true"
                    />
                  </label>

                  <label> Like
                    <input
                      type="number"
                      id="form_like"
                      className="add_item_form_input_small"
                      onChange={(e) => setLike(e.target.value)}
                      value={like}
                      required="true"
                    />
                  </label>
                  <label> Dislike
                    <input
                      type="number"
                      id="form_comment"
                      className="add_item_form_input_small"
                      onChange={(e) => setDislike(e.target.value)}
                      value={dislike}
                      required="false"
                    />
                  </label>
                  <label> Category
                    <input
                      type="string"
                      id="form_comment"
                      className="add_item_form_input_small"
                      onChange={(e) => setTags(e.target.value)}
                      value={tags}
                      required="false"
                    />
                  </label>
                </div>
              </form>

              <div className="add_item_form_button">
                <button
                  type="submit"
                  className="button add_item_submit_button"
                  value="send message"
                  onClick={() => setPreview(true)}
                >
                  Preview Item
                </button>
              </div>
            </div>
          </div>
        </div>
        <Preview trigger={preview} setTrigger={setPreview}
          description={description}
          imgsrc={imgsrc}
          mrp={mrp}
          op={op}
          like={like}
          time={time}
          comment={comment}
          vendor={vendor}
          link={link} />
      </div>
    </>
  );
};
class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <NewForm />

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddItem;
